export const supplementHd =[
        { id:'supplementdate', label: 'Lease Supplement Date', sortOption: false },
        { id:'supplementno', label: 'Supplement Number', sortOption: false },
        { id:'executed', label: 'Executed By', sortOption: false },
        { id:'attachments', label: 'Attachments', sortOption: false },
        { id:'action', label: 'Action', sortOption: false },
]
export const amendmentHd =[
        { id:'supplementdate', label: 'Lease Amendment Date', sortOption: false },
        { id:'supplementno', label: 'Amendment Number', sortOption: false },
        { id:'executed', label: 'TSN', sortOption: false },
        { id:'executed', label: 'CSN', sortOption: false },
        { id:'executed', label: 'Description', sortOption: false },
        { id:'attachments', label: 'Attachments', sortOption: false },
        { id:'action', label: 'Action', sortOption: false },
]