import { globalGetService, globalPostService, globalPutService,globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { toastFlashMessage } from '../../../utils';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { errorCode } from './';
export function getObligationsApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/obligations/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getObligationApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/obligations/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ obligation:response.data.data, modal:true, mode: mode === 'V'?'view':'edit', error:{}})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditObligationApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/obligations/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/obligations/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}
export function getApprovedUsersApi(props, query){
  globalGetService('console/get-user-list/', query)
  .then(response => {
    if(checkApiStatus(response)) {
      this.setState({approvedUser: response.data.data.list.map(user => {return{name: user.first_name + (user.last_name ? ' '+user.last_name :''), id:user.id}})})
    }
  })
}

export function changeObligationStatusApi(props, id, data){
  return new Promise(
    function(resolve, reject){
      globalPutService(`contracts/${props.params.contract_slug}/obligation-approval/${id}/`,data)
      .then(response => {
          resolve(response);
        })
    }
  )
}

export function deleteObligationApi(props ,deleteItem) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/obligations/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          list: prevState.list.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, data:null}
        }))
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
      this.setState({pageLoader:false});
    })
}