import React, { Component, Fragment } from 'react';

class ReportAnIssue extends Component{

  render(){

    return(
      <Fragment>

      </Fragment>
    )
  }
}
export default ReportAnIssue;
