import React from 'react';
import {Tabs,Tab} from '@material-ui/core';
import UtilsTrendGraph from './UtilsTrendGraph';
const UtilizationTrends = ({primaryTab, secondaryTab, utlisAverage, utilsTrends, onTabChange}) => {
  return(
    <div>
      { primaryTab === 'airframe' && utilsTrends.airframe &&
        <div>
          <UtilsTrendGraph
            hour_plots={utilsTrends.airframe.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.airframe.name.toLowerCase()).hours}
            cycle_plots={utilsTrends.airframe.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name === utilsTrends.airframe.name).cycles}
          />
        </div>
      }
      { primaryTab === 'apu' && utilsTrends.apu &&
        <div>
          <UtilsTrendGraph
            utlisAverage={utlisAverage}
            hour_plots={utilsTrends.apu.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase().includes(utilsTrends.apu.name.toLowerCase())).hours}
            cycle_plots={utilsTrends.apu.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name.toLowerCase().includes(utilsTrends.apu.name.toLowerCase())).cycles}
          />
        </div>
      }
      { primaryTab === 'landing_gears' && utilsTrends.landing_gears &&
        <div>
          <Tabs
            value={secondaryTab}
            onChange={(event, newValue) => onTabChange(event, newValue, 'secondaryTab')}
            indicatorColor="primary"
            textColor="primary"
            variant={'fullWidth'}
          >
            {Object.keys(utilsTrends.landing_gears).map((key, index) =>
              <Tab label={utilsTrends.landing_gears[key].name} />
            )}
          </Tabs>
          {Object.keys(utilsTrends.landing_gears).map((key,index) =>
            index === secondaryTab ?
            <div>
              <UtilsTrendGraph
                hour_plots={utilsTrends.landing_gears[key].hour_plots}
                avgHours={utlisAverage.find(item => item.name === utilsTrends.landing_gears[key].name).hours}
                cycle_plots={utilsTrends.landing_gears[key].cycle_plots}
                avgCycles={utlisAverage.find(item => item.name === utilsTrends.landing_gears[key].name).cycles}
              />
            </div>:null
          )}
        </div>
      }
      { primaryTab === 'engines' && utilsTrends.engines &&
         <div>
           <Tabs
             value={secondaryTab}
             onChange={(event, newValue) => onTabChange(event, newValue, 'secondaryTab')}
             indicatorColor="primary"
             textColor="primary"
             variant={'fullWidth'}
           >
             {Object.keys(utilsTrends.engines).map((key, index) =>
               <Tab label={utilsTrends.engines[key].name} />
             )}
           </Tabs>
           { Object.keys(utilsTrends.engines).map((key, index) =>
             index === secondaryTab ?
             <div>
               <UtilsTrendGraph
                hour_plots={utilsTrends.engines[key].hour_plots}
                avgHours={utlisAverage.find(item => item.name === utilsTrends.engines[key].name).hours}
                cycle_plots={utilsTrends.engines[key].cycle_plots}
                avgCycles={utlisAverage.find(item => item.name === utilsTrends.engines[key].name).cycles}
              />
             </div>:null
           )}
         </div>
      }
      { primaryTab === 'propeller' && utilsTrends.propeller &&
        <div>
          <Tabs
            value={secondaryTab}
            onChange={(event, newValue) => onTabChange(event, newValue, 'secondaryTab')}
            indicatorColor="primary"
            textColor="primary"
            variant={'fullWidth'}
          >
            {Object.keys(utilsTrends.propeller).map((key, index) =>
              <Tab label={utilsTrends.propeller[key].name} />
            )}
          </Tabs>
          { Object.keys(utilsTrends.propeller).map((key, index) =>
            index === secondaryTab ?
            <UtilsTrendGraph
              hour_plots={utilsTrends.propeller[key].hour_plots}
              avgHours={utlisAverage.find(item => item.name === utilsTrends.propeller[key].name).hours}
              cycle_plots={utilsTrends.propeller[key].cycle_plots}
              avgCycles={utlisAverage.find(item => item.name === utilsTrends.propeller[key].name).cycles}
            />:null
          )}
        </div>
      }

      { primaryTab === 'fan' && utilsTrends.fan &&
        <div>
          <UtilsTrendGraph
            hour_plots={utilsTrends.fan.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.fan.name.toLowerCase()).hours}
            cycle_plots={utilsTrends.fan.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.fan.name.toLowerCase()).cycles}
          />
        </div>
      }
      { primaryTab === 'hpc' && utilsTrends.hpc &&
        <div>
          <UtilsTrendGraph
            hour_plots={utilsTrends.hpc.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.hpc.name.toLowerCase()).hours}
            cycle_plots={utilsTrends.hpc.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.hpc.name.toLowerCase()).cycles}
          />
        </div>
      }
      { primaryTab === 'hpt' && utilsTrends.hpt &&
        <div>
          <UtilsTrendGraph
            hour_plots={utilsTrends.hpt.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.hpt.name.toLowerCase()).hours}
            cycle_plots={utilsTrends.hpt.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.hpt.name.toLowerCase()).cycles}
          />
        </div>
      }
      { primaryTab === 'lpt' && utilsTrends.lpt &&
        <div>
          <UtilsTrendGraph
            hour_plots={utilsTrends.lpt.hour_plots}
            avgHours={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.lpt.name.toLowerCase()).hours}
            cycle_plots={utilsTrends.lpt.cycle_plots}
            avgCycles={utlisAverage.find(item => item.name.toLowerCase() === utilsTrends.lpt.name.toLowerCase()).cycles}
          />
        </div>
      }
    </div>
  )
}
export default UtilizationTrends;
