import React, { Fragment, useState } from 'react'
import { LabelValueCard, TableListComp } from '../../../shared_elements'
import { Grid, Paper, Tooltip } from '@material-ui/core'
import LessorInfo from './LessorInfo';
import LesseeInfo from './LesseeInfo';
import { Row } from 'reactstrap';
import { assemblyInvoiceHd, partialPymntListHd, creditDebitHd } from '..';
import NumericLabel from 'react-pretty-numbers';
import { History } from '@material-ui/icons';
import { showCurrencyFormat } from '../../../utils';
import EditAssemblyLeaseList from './EditAssemblyLeaseList';
import CreditDebitList from './CreditDebitList';
import BankingInfo from '../../../applications/contracts/Invoice/components/BankingInfo';
import moment from 'moment';
import PartialPaymentList from './PartialPaymentList';
import { checkPermission } from '../../../utils_v2';
export default function ViewInvoice({ invoiceCreatedDetails,leaseType, setChangeStatusModal, getInvoiceStatusTimeline }) {

    return (
        <Fragment className='add-edit-invoice-form view-block'>
            <Grid container spacing='3' style={{ width: 'auto', margin: '0px' }} >
                <LabelValueCard md={3} label='Invoice Number' value={invoiceCreatedDetails?.invoice_number ? invoiceCreatedDetails?.invoice_number : '--'} />
                <Grid item md={3} className='label-value-card'>
                    <p>Invoice Status
                        <span onClick={()=>getInvoiceStatusTimeline(invoiceCreatedDetails)} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                            <Tooltip title='Invoice Status timeline' >
                                <History fontSize="small" color='primary' />
                            </Tooltip>
                        </span>
                    </p>
                    <h6 style={{ display: 'inline-flex' }}>{invoiceCreatedDetails?.status?.label}</h6>
                    { [1,2,5].includes(invoiceCreatedDetails.status?.value) && checkPermission('assembly_lease','invoice','U') ?
                        <span style={{ color: "blue", cursor: 'pointer', textDecoration: 'underline', fontSize: '13px' }} onClick={() => setChangeStatusModal(true)}> Change Status</span> : null
                    }
                    { [3].includes(invoiceCreatedDetails.status?.value) && checkPermission('assembly_lease','invoice','STS') ?
                        <span style={{ color: "blue", cursor: 'pointer', textDecoration: 'underline', fontSize: '13px' }} onClick={() => setChangeStatusModal(true)}> Change Status</span> : null
                    }
                </Grid>
                <LabelValueCard md={3} label='Invoice Date' value={invoiceCreatedDetails?.date ? moment(invoiceCreatedDetails?.date).format('MMM DD-YYYY') : '--'} />
                <LabelValueCard md={3} label='Invoice Due Date' value={invoiceCreatedDetails?.due_date ? moment(invoiceCreatedDetails?.due_date).format('MMM DD-YYYY') : '--'} />
                <LabelValueCard md={3} label='Start Date' value={ leaseType == 0? 'N/A': invoiceCreatedDetails?.start_date ? moment(invoiceCreatedDetails?.start_date).format('MMM DD-YYYY') : '--'} />
                <LabelValueCard md={3} label='End Date' value={ leaseType == 0? 'N/A': invoiceCreatedDetails?.end_date ? moment(invoiceCreatedDetails?.end_date).format('MMM DD-YYYY') : '--'} />
            </Grid>
            <Row className="company-form-block" style={{ marginTop: '35px', marginLeft: '-2px', marginBottom: '20px' }}>
                <LessorInfo
                    invoiceInfo={invoiceCreatedDetails}
                    type="view"
                />
                <LesseeInfo
                    invoiceInfo={invoiceCreatedDetails}
                    type="view"
                    toLessor='true'
                />
            </Row>
            {
                (invoiceCreatedDetails.invoice_type?.value == 4 || invoiceCreatedDetails.invoice_type?.value == 5) ?
                <TableListComp
                heads={creditDebitHd}
                data={invoiceCreatedDetails.invoice_items && invoiceCreatedDetails.invoice_items.map((item, index) =>
                        <CreditDebitList
                        invoiceInfo={invoiceCreatedDetails}
                        mode="view"
                        invoice_item={item}
                    />
                )}
            />
            :
            <div className="">
                <TableListComp
                    heads={assemblyInvoiceHd}
                    data={invoiceCreatedDetails.invoice_items && invoiceCreatedDetails.invoice_items.map((item, index) =>
                        <EditAssemblyLeaseList
                        invoiceInfo={invoiceCreatedDetails}
                        mode="view"
                        invoice_item={item}
                        />
                        )}
                        />
            </div>
            }
            <div className="sub-total-block" style={{ marginTop: '10px' }}>
                <h5 style={{ textAlign: 'right' }} className="para-ui">Billable Amount :
                    <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                        {invoiceCreatedDetails.invoice_items.reduce((total, next) =>
                            total + next.billable_amount, 0
                        )}
                    </NumericLabel>

                </h5>
                {/* {
                    invoiceCreatedDetails.status?.value == '5' ?
                        <h5 style={{ textAlign: 'right' }} className="para-ui">Paid Amount :
                            <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                                {invoiceCreatedDetails.partial_paid_amount}
                            </NumericLabel>
                        </h5> : null
                } */}
                {/* <h5 style={{ textAlign: 'right' }} className="para-ui">Tax :
                    <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                        {invoiceCreatedDetails.invoice_items.reduce((total, next) =>
                            total + next.tax_amount, 0
                        )}
                    </NumericLabel>
                </h5> */}
                <h4 style={{ textAlign: 'right' }} className="para">Total:
                    <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                        {invoiceCreatedDetails.total_amount ? invoiceCreatedDetails.total_amount : invoiceCreatedDetails.sub_total !== undefined && invoiceCreatedDetails.sub_total !== null && invoiceCreatedDetails.sub_total !== '' ? invoiceCreatedDetails.sub_total : '--'}
                    </NumericLabel>
                </h4>
            </div>
            {
                invoiceCreatedDetails && invoiceCreatedDetails.partial_payments ?
                    <div className="partial-payment-block" style={{ margin: '10px 0px 20px 0px' }}>
                        <h5 style={{ marginBottom: "7px" }}>Partial-Payment Details</h5>
                        <TableListComp
                            heads={partialPymntListHd}
                            data={invoiceCreatedDetails?.partial_payments?.map((item, index) =>
                                <PartialPaymentList
                                    value={item}
                                />
                            )}
                        />
                    </div> : null
            }
            <h4 className="para invoice-heading heading-sans-bold" > Bank Detail {invoiceCreatedDetails.invoice_type == 2 ? 'For ' + invoiceCreatedDetails.currency + ' Rental Receivable' : ''}</h4>
            <BankingInfo
                type="view"
                assemblyInvoice='true'
                invoiceInfo={invoiceCreatedDetails}
            />
        </Fragment>
    )
}
