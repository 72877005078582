import { globalGetService, globalPostService, globalPutService,globalDeleteService } from '../../../utils_v2/globalApiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { checkApiStatus } from '../../../utils_v2';
import { toastFlashMessage } from '../../../utils';
import { errorCodeDeliveryCondition } from '../';
export function getDeliveryCondListApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/delivery-condition-items/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getDeliveryCondApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/delivery-condition-items/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ deliveryCond:response.data.data, error:{},modal:true, mode: mode === 'V'?'view':'edit'})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditDeliveryCondApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/delivery-condition-items/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/delivery-condition-items/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}

export function deleteDeliveryCondApi(props ,deleteItem) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/delivery-condition-items/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          list: prevState.list.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, data:null}
        }))
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
      this.setState({pageLoader:false});
    })
}