import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { displayDateFormatShort } from '../../../../constants';
import LeaseOptionCRU from './LeaseOptionCRU';
export default function LeaseOptionList({item, previewDetail, getResponseBack, pltConstants, contractInfo,deleteEolList}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.lease_option.label}</TableCell>
      <TableCell>{item.details ? item.details:'--'}</TableCell>
      <TableCell>
        {item.validity_start_date ? moment(item.validity_start_date).format(displayDateFormatShort): ''} - {item.validity_end_date ? moment(item.validity_end_date).format(displayDateFormatShort):''}
      </TableCell>
      <TableCell>
        {item.notice_period_start_date ? moment(item.notice_period_start_date).format(displayDateFormatShort): ''} - {item.notice_period_end_date ? moment(item.notice_period_end_date).format(displayDateFormatShort):''}
      </TableCell>
      <TableCell>
        <LeaseOptionCRU id={item.id} m="V" pltConstants={pltConstants} getResponseBack={getResponseBack}  contractInfo={contractInfo} />
        {checkPermission('contracts','eol_options', 'D') ?<DeleteIcon fontSize='small' color='secondary' onClick={()=>deleteEolList(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
