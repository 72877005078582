import React from 'react';
import { TableRow, TableCell,} from '@material-ui/core';
import MemberCRU from './MemberCRU';
export default function GroupUserList({item, member,groupId, getResponseBack, contractInfo}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{`${member.first_name} ${member.last_name}` }</TableCell>
      <TableCell>{member.designation ? member.designation:'--'}</TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>{member.mobile ? member.mobile:'--'}</TableCell>
      <TableCell>{member.remarks ? member.remarks:'--'}</TableCell>
      <TableCell> <MemberCRU id={member.id} groupId={groupId} getResponseBack={getResponseBack} m="V" contractInfo={contractInfo}/></TableCell>
    </TableRow>
  )
}
