import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, ContractSideBar, ContractBarLoader } from '../../Elements';
import { NoRecordFound, ListLoader, TableList } from '../../../../shared';
import { withRouter, browserHistory } from 'react-router';
import classnames from 'classnames';
import UtilsGraph from '../../Utilizations/components/UtilsGraph';
import { Row, Col, Modal, ModalHeader, ModalBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import LeaseHistory from '../components/LeaseHistory';
import MajorAssemblyCard from '../components/MajorAssemblyCard';
import FittedAssembly from '../components/FittedAssembly';
// import { utilsAvgAc } from '../../Utilizations/actionCreators';
import DashboardInvoiceInfo from '../components/DashboardInvoiceInfo';
import DashboardInvoiceHd from '../components/DashboardInvoiceHd';
import DashboardInvoiceRow from '../components/DashboardInvoiceRow';
import DashboardCashFlowWrap from '../components/DashboardCashFlowWrap';
import DashboardCashFlowList from '../components/DashboardCashFlowList';
import DashBoardFlaggedCond from '../components/DashBoardFlaggedCond';
import ReturnVsDeliveryPop from '../components/ReturnVsDeliveryPop';
import '../../assets/styles/Dashboard.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LEASE_HISTORIES, UPDATE_LEASE_CARD_POS, CONTR_MAJOR_ASSEMBLY_SLIDER, CLEAR_RETURN_COND } from '../actions';
import { loadAssetViewAc, contrDbFlaggedCondAc, exportReturnCondnAc, exportLeaseHistoryAc, contrDbCashFlowAc, contrDbInvoiceListAc, contrDbLeaseHistoryAc, contrDbUtilizationInfoAc, utilsTrendsAc , utilsAvgAc} from '../actionCreators';
import { sGlobalConstantAc, sExportContractsAc } from '../../../../shared/actionCreators';
import { UtilsAssetViewAc, editUtilsInfoAc, utilsListAc, exportUtilizationAc, exportRentalUtilizationAc, utilFnCrudAc, getRentalUtilizationAc } from '../../Utilizations/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import { cashFlowWidgetAc } from '../../CashFlows/actionCreators';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import exportIconWhite from '../../../../shared/assets/img/export_icon_white.svg';
import compareIcon from '../../../../shared/assets/img/compare_icon.svg';
import Switcher from '../../../../shared/components/Switcher';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import infoIcon from '../../../../shared/assets/img/info_icon.svg';
import UtilTrendsGraph from '../../shared';
import ReserveFundStatus from '../../shared/ReserveFundStatus';
import { ExportManu } from '../../../../shared_elements';
import CashFlowGraph from '../components/CashFlowGraph';
import { cashflowSummaryWidgetApi, getAssetlistApi, getAnnualUtilsApi, getAnnualUtilsListApi, exportAnnualUtils } from '../apiServices'
import AvgAnnualUtilization from '../components/AvgAnnualUtilization';
import { Paper } from '@material-ui/core';
class ContractDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashflowSummaryWidget: {},
      jspLessor: false,
      asset: [],
      annualUtlisData: {},
      annualUtlisList:[],
      filteredUtlisList:[],
      activeTabUtils:0,
    }
    this.cashflowSummaryWidgetApi = cashflowSummaryWidgetApi.bind(this)
    this.getAssetlistApi = getAssetlistApi.bind(this)
    this.getAnnualUtilsApi = getAnnualUtilsApi.bind(this)
    this.getAnnualUtilsListApi = getAnnualUtilsListApi.bind(this)
    this.exportAnnualUtils = exportAnnualUtils.bind(this)
  }

  componentDidMount() {
    this.getAssetlistApi(this.props)
    this.getAnnualUtilsApi(this.props)
    this.getAnnualUtilsListApi(this.props)
    let userInfo = getLocalStorageInfo()
    if (userInfo.defaultLessor.id == 442 || userInfo.defaultLessor.id == 463) {
      this.setState({ jspLessor: true })
    }
    this.props.cashFlowWidget();
    this.cashflowSummaryWidgetApi(this.props)
    this.props.fetchUtilsInfo('list');
    this.props.fetchAssetInfo();
    this.props.fetchTechConstant({ constant_types: ['lease_type_accounting', 'lease_type', 'insurance_type', 'lease_option_type', 'interval', 'escalation_interval', 'mr_rate_range_condition', 'party_role', 'security_deposit_type'] });
    this.props.contrDbInvoiceList();
    this.props.contrDbCashFlow();
    this.props.contrDbUtilizationInfo()
    this.props.compareReturnCond({ flag: 1 });
    // this.props.contrDbLeaseHistory();
    setTimeout(() => {
      this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_type == '1')})
    }, 300);
    this.props.utilsTrends();
    this.props.utilsAvg();
  }
  toggle = () => {
    this.props.closeReturnCondModal();
  }
  tabSwitcher=(value)=>{
    this.setState({activeTabUtils: value})
  }

  toggleAsset=()=>{
    if(this.state.activeTabUtils ==0){
      this.setState({filteredUtlisList: this.state.annualUtlisList?.filter( i=> i.asset_type == '1')})
  }
  if(this.state.activeTabUtils ==1){
      this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_type == '3')})
  }
  if(this.state.activeTabUtils ==2){
      this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_type == '4' && i.asset_id == this.state.annualUtlisData.lgSelected)})
  }
  if(this.state.activeTabUtils ==3){
      this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_type == '2' && i.asset_id == this.state.annualUtlisData.engineSelected)})
  }
  }
  updateUtilsGraph = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      annualUtlisData: {
        ...prevState.annualUtlisData,
        [key]: value
      }
    }))
    if(key =='lgSelected'){
      setTimeout(() => {
        this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_id == this.state.annualUtlisData.lgSelected)})
      }, 500);
    }
    else if(key == 'propellerSelected'){
      setTimeout(() => {
        this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_id == this.state.annualUtlisData.propellerSelected)})
      }, 500);
    }
    else if( key == 'engineSelected'){
      setTimeout(() => {
        this.setState({filteredUtlisList: this.state.annualUtlisList.filter( i=> i.asset_id == this.state.annualUtlisData.engineSelected)})
      }, 500);

    }
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { leaseHistories, leaseInfoCardSettings, majorAssemblyList, ctrShortInfo, returnCondFlagged, contrDbInvoices, contrDbCashFlows, returnCondCompare, invoiceWidgetLoader, cashFlowLoader, flaggedWidgetLoader, utilsTrendsInfo, utlisAverage, activeTab, contractMsn, msnCashflowSlug } = this.props;
    const { cashflowSummaryWidget, jspLessor, asset, annualUtlisData, annualUtlisList, filteredUtlisList, activeTabUtils } = this.state;
    const utilsTrends = utilsTrendsInfo.data;
    if (window.innerWidth <= 767.98) {
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    } else {
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: majorAssemblyList.length <= 2 ? majorAssemblyList.length : 4,
        slidesToScroll: 1,
      };
    }
    if (window.innerWidth <= 767.98) {
      var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    } else {
      var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: majorAssemblyList.length <= 2 ? majorAssemblyList.length : 4,
        slidesToScroll: 1,activeTab
      };
    }
    return (
      <div className="contract-container">
        {flaggedWidgetLoader ? <ListLoader /> : null}
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Lease Dashboard" type="brief" params={this.props.params} />
        <div className="contract-db-wrapper">
          <Row>
            {checkPermission('contracts','cashflow','R') ?
              <>
                <DashboardCashFlowWrap contrDbCashFlows={contrDbCashFlows} params={this.props.params}>
                  {
                    asset && asset[0] && asset[0].asset_name ?
                      <CashFlowGraph
                        graphType=''
                        graphTitle=''
                        graphxLabel=''
                        graphyLabel=''
                        cashflowSum={true}
                        jspLessor={jspLessor}
                        lessorDetail={contractMsn.filter(item => item.slug == msnCashflowSlug)[0]}
                        contractMsn={contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].serial_number : ''}
                        headingText={`Cash Flow Summary of ${asset[0].asset_name}`}
                        cashflowSummaryWidget={cashflowSummaryWidget}
                      />
                      :
                      null
                  }
                </DashboardCashFlowWrap>
                <Col md="12" style={{ paddingLeft: '0px', marginTop: '10px' }}>
                  <ReserveFundStatus />
                </Col>
              </>:null
            }
            { checkPermission('contracts','invoices','R') ? 
              <Col md="12" className="invoice-summary-spacing">
                <DashboardInvoiceInfo contrDbInvoices={contrDbInvoices} params={this.props.params}>
                  <Col md="12" className="db-invoice-table-block">
                    <p className="last-month-title">Latest 3 Month Invoices</p>
                    <table>
                      <DashboardInvoiceHd />
                      <tbody>
                        {
                          contrDbInvoices.list.map((invoice, index) => <DashboardInvoiceRow params={this.props.params} contract_slug={this.props.params.contract_slug} key={index} invoice={invoice} />)
                        }
                      </tbody>
                    </table>
                    {
                      !invoiceWidgetLoader && !contrDbInvoices.list.length ?
                        <NoRecordFound description="Get Started by creating invoices" /> : null
                    }
                  </Col>
                </DashboardInvoiceInfo>
              </Col>:null
            }
            { checkPermission('contracts','utilization','R') ?
              <Col md={12}>
                <UtilTrendsGraph />
              </Col>:null
            }
          </Row>

          { checkPermission('contracts','utilization','R') && majorAssemblyList.length ?
            <div style={{display:'none'}}>
              <h5 className="para avergare-monthly-heading" >Average Monthly Utilization
                <span className="as-of-date">
                  {majorAssemblyList[0].max_period && majorAssemblyList[0].min_period ? 'As of ' + majorAssemblyList[0].min_period + ' to ' + majorAssemblyList[0].max_period : ''}
                </span>
              </h5>
            </div> : null
          }

          { majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted === false).length ?
              <Slider {...settings} className="airframe-cards" trackClassName="slick-track" thumbClassName="slick-track"
              >
                { majorAssemblyList.map((majorAssembly, index) => !majorAssembly.is_fitted ? <MajorAssemblyCard key={index} majorAssembly={majorAssembly} /> : null)}
              </Slider> : null
          }
          { checkPermission('contracts','utilization','R') ?
            <Paper style={{marginBottom:'20px', paddingTop:'10px'}}>
              <li className="list-inline-item" style={{display:'flex', alignContent:'center', marginRight:'0px'}}>
                <h5 className="para avergare-monthly-heading" style={{margin:'5px 10px 0px 8px'}}>Annual Average Utilizations</h5>
                {annualUtlisData?.data?.aircraft && checkPermission('contracts','utilization','EXP') ?
                <ExportManu marginbottom='0px' title="Export" files={[{ title: 'Excel', extension: 'xls', key: '' }]} exportReportFn={(file) => this.exportAnnualUtils(this.props, { download: file.extension })} />
                : null}
              </li>
              { annualUtlisData?.data && Object.keys(annualUtlisData?.data).length  ?
                  <div>
                    <AvgAnnualUtilization  activeTab={activeTabUtils} filteredUtlisList={filteredUtlisList} annualUtlisList={annualUtlisList} annualUtlisData={annualUtlisData} updateUtilsGraph={this.updateUtilsGraph} exportAnnualUtils={this.exportAnnualUtils} toggleAsset={this.toggleAsset} tabSwitcher={(val)=>this.tabSwitcher(val)}/>
                  </div>
                  : <div> <NoRecordFound /></div>
              }
            </Paper>:null
          }
          
          {majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted).length ?
            <div>
              <h5 style={{marginLeft:'0'}} className="para avergare-monthly-heading" >Fitted Major Assembly</h5>
            </div> : null
          }
          {
            majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted).length ?
              <div style={{marginLeft:'0'}} className="airframe-cards flex-not-centered">
                {
                  majorAssemblyList.filter(item => item.is_fitted).map((majorAssembly, index) =>
                    <FittedAssembly key={index + 'titlte'} majorAssembly={majorAssembly} />
                  )
                }
              </div> : null
          }
          {
            leaseHistories.length ?
              <div className="timeline-block">
                <div className="timeline-heading-block">
                  <h5 className="para contract-timeline">
                    Lease History
                    { checkPermission('contracts','lease_details','EXP') ?
                      <span onClick={() => this.props.exportLeaseHistory(ctrShortInfo)} style={{ paddingLeft: '16px', cursor: 'pointer', fontSize: '14px', color: '#fff' }}><img style={{ marginRight: '4px', textTransform: 'uppercase' }} width="18" src={exportIconWhite} alt="export" />Export</span>:null
                    }
                  </h5>
                  <div className="nav-btn">
                    {
                      leaseInfoCardSettings.rightCount > 0 ?
                        <img src={imgStoragePath + "timeline_prev.png"} alt="icon" onClick={() => this.props.leaseCardPos('prev')} /> : <img style={{ opacity: '0.4' }} src={imgStoragePath + "timeline_prev.png"} alt="icon" />
                    }
                    {
                      parseInt(leaseInfoCardSettings.leftCount) > 0 ?
                        <img src={imgStoragePath + "timeline_next.png"} alt="icon" onClick={() => this.props.leaseCardPos('next')} /> : <img style={{ opacity: '0.4' }} src={imgStoragePath + "timeline_next.png"} alt="icon" />
                    }

                  </div>
                </div>
                <div className="timeline-inner-block flex-centered">
                  <ul className="timeline-bar list-inline" style={{ width: leaseInfoCardSettings.totalWidth, 'left': leaseInfoCardSettings.leftPos }}>
                    {
                      leaseHistories.map((leaseHistory, index) => <LeaseHistory leaseHistory={leaseHistory} key={index} />)
                    }
                  </ul>
                </div>
              </div> : null
          }
          { checkPermission('contracts','return_conditions','R') ?
            <div className="db-upcoming-event flagged-condition">
              <div className="cashflow-top-bar">
                <h5 className="para" style={{ position: "relative" }}>Flagged Return Conditions</h5>
                <span style={{ position: 'absolute', top: '12px', left: '207px', fontSize: '18px', color: '#808080' }} >
                  <img src={infoIcon} id='UncontrolledTooltipExample10' alt="Icon" className="info-icon" />
                  <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample10">
                    Summary of differences in delivery and return conditions
                  </UncontrolledTooltip>
                </span>

                <span style={{ color: '#3f51b5' }} onClick={() => this.props.compareReturnCond({})}>
                  <img src={compareIcon} alt="compare" /> Return Vs Delivery
                </span>
              </div>
              <div className="flagged-condition-wrapper">
                <table width="100%">
                  <thead>
                    <tr>
                      <th>Component</th>
                      <th>Return Conditions</th>
                      <th>Delivery Conditions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      returnCondFlagged.map((returnCond, index) => <DashBoardFlaggedCond key={index} returnCond={returnCond} />)
                    }
                  </tbody>
                </table>
                {
                  !flaggedWidgetLoader && !returnCondFlagged.length ?
                    <NoRecordFound description="Get Started by adding Return & Delivery conditions under contract" /> : null
                }
              </div>
            </div>:null
          }
          
          <Modal isOpen={returnCondCompare.flag} size="lg" toggle={this.toggle} className="return-condition-modal">
            <ModalHeader toggle={this.toggle}>Return Vs Delivery Conditions
              {returnCondCompare.returnConds.length && ctrShortInfo && ctrShortInfo.contract && Object.keys(ctrShortInfo.contract).length && checkPermission('contracts','lease_details','EXP') ?
                <span onClick={() => this.props.exportReturnCondn(ctrShortInfo)} style={{ paddingLeft: '16px', cursor: 'pointer', fontSize: '14px' }}><img style={{ marginRight: '4px' }} width="18" src={exportIcon} alt="export" />EXPORT</span>
                : null
              }

            </ModalHeader>
            <ModalBody>
              <ReturnVsDeliveryPop returnCondCompare={returnCondCompare} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  leaseInfoCardSettings: state.ContrDashboardReducer.leaseInfoCardSettings,
  leaseHistories: state.ContrDashboardReducer.leaseHistories,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  majorAssemblyList: state.ContrDashboardReducer.majorAssemblyList,
  returnCondFlagged: state.ContrDashboardReducer.returnCondFlagged,
  contrDbCashFlows: state.ContrDashboardReducer.contrDbCashFlows,
  contrDbInvoices: state.ContrDashboardReducer.contrDbInvoices,
  returnCondCompare: state.ContrDashboardReducer.returnCondCompare,
  invoiceWidgetLoader: state.ContrDashboardReducer.invoiceWidgetLoader,
  cashFlowLoader: state.ContrDashboardReducer.cashFlowLoader,
  flaggedWidgetLoader: state.ContrDashboardReducer.flaggedWidgetLoader,
  techSpecsLoader: state.TechSummaryReducer.techSpecsLoader,
  utilsTrendsInfo: state.UtilsReducer.utilsTrendsInfo,
  utlisAverage: state.UtilsReducer.utlisAverage,
  activeTab: state.UtilsReducer.activeTab,
  assetViewId: state.userAssetReducer.assetViewId,
  contractMsn: state.DashboardReducer.contractMsn,
  msnCashflowSlug: state.DashboardReducer.msnCashflowSlug,

});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params)),
    utilsTrends: () => dispatch(utilsTrendsAc(ownProps)),
    utilsAvg: () => dispatch(utilsAvgAc(ownProps)),
    fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
    utilsTrends: () => dispatch(utilsTrendsAc(ownProps)),
    compareReturnCond: (queryParams) => dispatch(contrDbFlaggedCondAc(ownProps, queryParams)),
    exportReturnCondn: (ctrShortInfo) => dispatch(exportReturnCondnAc(ownProps, ctrShortInfo)),
    exportLeaseHistory: (ctrShortInfo) => dispatch(exportLeaseHistoryAc(ctrShortInfo)),
    contrDbInvoiceList: () => dispatch(contrDbInvoiceListAc(ownProps)),
    contrDbCashFlow: () => dispatch(contrDbCashFlowAc(ownProps)),
    contrDbLeaseHistory: () => dispatch(contrDbLeaseHistoryAc(ownProps)),
    contrDbUtilizationInfo: () => dispatch(contrDbUtilizationInfoAc(ownProps)),
    cashFlowWidget: (filter) => dispatch(cashFlowWidgetAc(ownProps, filter)),
    closeReturnCondModal: () => dispatch({
      type: CLEAR_RETURN_COND,
      payload: ''
    }),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    leaseCardPos: (dir) => dispatch({
      type: UPDATE_LEASE_CARD_POS,
      payload: dir
    }),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractDashboard, ['contracts', 'statistics', 'R'])))
