import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {LogAddEnquiry, LogFilter, LogList, LogListHeading, LogsPagination}  from '../components'
import { SidebarMntcCalc } from '../../../../shared/'
import '../assets/styles/logListing.scss';
import { logsListingAc, engineTypeAc, aircraftTypeAc, downloadLogAc, deleteLogAc, getMRUsageAc } from '../actionCreators';
import { LOG_FILTER_UPDATE, LOG_SORT_UPDATE, LOGS_LOADER, CLEAR_LOG_FILTER} from '../actions';
import { removeEmptyKey, getLocalStorageInfo } from '../../../../utils';
import { NoRecordFound  } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import config from '../../../../config';
class LogListing extends Component{
  constructor(props){
    super(props);
  }
  componentDidMount() {
    if(getLocalStorageInfo().user.permission.mr_calculator && getLocalStorageInfo().user.permission.mr_calculator.is_saas){
      this.props.getMRUsage();
    }
    if( !this.props.engineTypeList || !this.props.engineTypeList.length){
      this.props.fetchEngineTypes();
    }
    if(!this.props.aircraftTypeList || !this.props.aircraftTypeList.length){
      this.props.fetchAircraftTypes();
    }
    this.props.setLoader();
    const filterParams = removeEmptyKey(this.props.location.query);
    this.props.fetchLogs(filterParams);
  }

  componentWillUnmount(){
    this.props.clearFilter();
  }

  componentWillMount(){
    window.location.assign(`${config.domain.subDomian}logs`)
  }

  updateLogsPageNo = (page) => {
    let queryFilter = this.props.logFilter;
    queryFilter = {
      ...queryFilter,
      page: page
    };
    const filterParams = removeEmptyKey(queryFilter);
    this.props.updateLogFilter(page, 'page');
    this.props.setLoader();
    this.props.fetchLogs(filterParams, false);
    this.updateLogQueryUrl(filterParams);
  }

  updateLogQueryUrl = (filterParams) => {
    // const location = Object.assign({}, browserHistory.getCurrentLocation());
    // location.query= filterParams;
    // browserHistory.push(location);
  }

  updateLogSort = (type) =>{
    let queryFilter = this.props.logFilter;
    let sortBy = 'asc'
    if(type === queryFilter.sort){
      sortBy = (queryFilter.sort_by == 'asc') ? 'desc' : 'asc';
    }
    queryFilter = {
      ...queryFilter,
      sort: type,
      sort_by: sortBy
    };
    const filterParams = removeEmptyKey(queryFilter);
    this.props.setLoader();
    this.props.updateLogSort(type);
    this.props.fetchLogs(filterParams, false);
    // this.updateLogQueryUrl(filterParams);
  }
  render(){
    const { logsInfo, engineTypeList, aircraftTypeList, logFilter, logListLoader } = this.props;
    return(
      <div className="contract-container mr-calculator-logs" style={{marginTop: '20px'}}>
        <div className="asset-listing-wrap log-listing-block">
          <SidebarMntcCalc path={this.props.route.path}/>
          <LogAddEnquiry  downLoadLogListing = {this.props.downLoadLogListing}/>
          <LogFilter
            filterData={logFilter}
            aircraftTypeList={aircraftTypeList}
            engineTypeList={engineTypeList}
            updateLogQueryUrl = {this.updateLogQueryUrl}

          />
          <div className="mobile-asset-list">
              <LogListHeading
              filterData={logFilter}
              updateLogSort = {this.updateLogSort}
              updateLogQueryUrl = {this.updateLogQueryUrl}
              />
              { logListLoader ?
                <h3 className="text-center" style={{padding: '20px 0' }} >
                <img width="100px" src={imgStoragePath+"page-loader.gif"} alt="Loading..."/></h3>: logsInfo.list && logsInfo.list.length ? logsInfo.list.map((log, index) => <LogList delLog={this.props.deleteLog} key={index} log={log} />):
                  <NoRecordFound description=" " />

              }
          </div>

          { logListLoader ?
            '': logsInfo.list && logsInfo.list.length ?  <LogsPagination updateLogsPageNo={this.updateLogsPageNo} paginationInfo={logsInfo.pagination} currentPage={logFilter.page} /> : null
          }

       </div>
      </div>


    )
  }
}
const mapStateToProps = state => ({
  logsInfo: state.MRFinanaceRptReducer.logsInfo,
  engineTypeList: state.MRFinanaceRptReducer.engineTypeList,
  aircraftTypeList: state.MRFinanaceRptReducer.aircraftTypeList,
  logFilter: state.MRFinanaceRptReducer.logFilter,
  logListLoader: state.MRFinanaceRptReducer.logListLoader,
  mrUsage: state.MRFinanaceRptReducer.mrUsage,
});
const mapDispatchToProps = dispatch => {
  return {
    setLoader: () => dispatch({
      type: LOGS_LOADER,
      payload: true
    }),
    fetchLogs: (params) => dispatch(logsListingAc(params)),
    fetchEngineTypes: () => dispatch(engineTypeAc()),
    fetchAircraftTypes: () => dispatch(aircraftTypeAc()),
    downLoadLogListing: (fileType) => dispatch(downloadLogAc(fileType)),
    deleteLog: (id) => dispatch(deleteLogAc(id)),
    updateLogSort : (type) => dispatch({
      type:LOG_SORT_UPDATE,
      payload: type
    }),
    updateLogFilter: (value, type) => dispatch({
      type: LOG_FILTER_UPDATE,
      payload: { value, type}
    }),
    clearFilter: () => dispatch({
      type: CLEAR_LOG_FILTER,
      payload: ''
    }),
    getMRUsage: () => dispatch(getMRUsageAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(LogListing, ['mr_calculator', 'dashboard','R']));
