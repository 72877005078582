
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const AssetSynopsys = ({ assetGrphSmryData, loader, graphSecTab }) => {
  const [toggle, setToggle]= useState(false)
  useEffect(()=>{
  setToggle(true)
  },[])
  let categories = graphSecTab == '1' ? assetGrphSmryData.categories[0]?.aircraft : graphSecTab == '2' ? assetGrphSmryData.categories[1]?.engine :graphSecTab == '3' ? assetGrphSmryData.categories[2]?.apu : graphSecTab == '4'? assetGrphSmryData.categories[3]?.lg :assetGrphSmryData.categories[4]?.propeller
  let series = graphSecTab == '1' ? assetGrphSmryData.series[0]?.aircraft : graphSecTab == '2' ? assetGrphSmryData.series[1]?.engine :graphSecTab == '3' ? assetGrphSmryData.series[2]?.apu :graphSecTab == '4' ? assetGrphSmryData.series[3]?.lg : assetGrphSmryData.series[4]?.propeller
  var colors = ['#beed87', '#ffb98d', '#729cf1','#d7d7d7','#89BFFF'];
  let width = categories?.length * 7 +'%' 
  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Asset Distribution Graph',
      align: 'left',
      style: {
        fontFamily: 'Conv_IBMPlexSans-Regular',
        fontSize: '16px'
      }
    },
    xAxis: {
      categories: categories,
    },
    plotOptions: {
      series: {
        pointWidth: 5
    }
  },
    colors: colors,
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Assets'
      }
    },
    series: series,
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
        contextButton: {
          text: '',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    },
    colors: colors,
  }
  return (
    <div style={{width:width, minWidth:'100%'}}>
      <HighchartsReact
      key={graphSecTab}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default AssetSynopsys;
