import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
export function getCapeTownConListApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/cape_town_convention/`, query)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        cape_town_clauses:response.data.data.cape_town_clauses
      })
    }
  })
}

export function editCapeTownConApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPutService(`contracts/${props.params.contract_slug}/cape_town_convention/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({
        flag:false
      })
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
