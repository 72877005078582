import { globalExportService, globalGetService } from "../../../globalServices"
import { assetId } from "../../../constants";
import {  toastFlashMessage } from "../../../utils";
import { downloadFileType } from "../../../utils_v2";
export function cashflowSummaryWidgetApi(props) {
  this.setState({pageLoader:true})
  let assetType = assetId[props.params.type]
  globalGetService(`/contracts/${props.params.contract_slug}/cashflow-statistics-summary/`)
    .then(response => {
      this.setState({pageLoader:false})
      if (response.data.statusCode == 200) {
        this.setState({
          cashflowSummaryWidget: response.data.data
        })
      }
    })
}
export function getAssetlistApi (props) {
  this.setState({pageLoader:true})
  globalGetService(`/console/list/?dropdown=true&all_assets=true`)
  .then(response => {
    this.setState({pageLoader:false})
    if(response.data.statusCode == 200){
      this.setState(prevState => ({
        ...prevState,
        asset:response.data.data.asset.filter( i => i.slug == props.params.aircraft_slug)
      }))
    }
  })
}
export function getAnnualUtilsApi(props){
  globalGetService(`contracts/v2/${props.params.contract_slug}/lease-annual-utilization/`)
  .then(response =>{
    if(response.data.statusCode==200){
      this.setState({annualUtlisData: {
          ...this.state.annualUtlisData,
          data: response.data.data,
          lgSelected: response.data.data.lg ? Object.keys(response.data.data.lg)[0] : '',
          engineSelected: response.data.data.engine ? Object.keys(response.data.data.engine)[0] : '',
          propellerSelected: response.data.data.propeller ? Object.keys(response.data.data.propeller)[0] : '',
        },
        activeTabUtils: response.data.data.aircraft ? '0' : (response.data.data.apu ? '1' : (response.data.data.lg ? '2' : (response.data.data.engine ? '3' : response.data.data.fan? '4': response.data.data.hpt? '5': response.data.data.lpt? '6' : response.data.data.hpc? '7': '8' )))
      }) }
    else{
      toastFlashMessage(response.data.message, 'error')
    }
  })
}
export function getAnnualUtilsListApi(props){
  globalGetService(`contracts/v2/${props.params.contract_slug}/lease-annual-utilization/?list-view=1`)
  .then(response =>{
    if(response.data.statusCode==200){
      this.setState({annualUtlisList: response.data.data.list})}
    else{
      toastFlashMessage(response.data.message, 'error')
    }
  })
}
export function exportAnnualUtils(props){
  globalExportService(`contracts/v2/${props.params.contract_slug}/lease-annual-utilization/?download=xlsx`)
  .then(response =>{
    downloadFileType(response, 'Annual_Utilization', 'xlsx');
  })
}
