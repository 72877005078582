import React, { Component } from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import editIcon from '../../../shared/assets/img/edit_icon.svg';
export default class OwnerCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
      const { owner, editOwnerMode, deleteOwnerMode, id } = this.props
        return (
            <Col md="3" style={{ cursor: 'default' }}>
                <div className="folder-card owner-card">
                  <span style={{float:'right'}}>
                    { id ?
                      getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('U') !=-1 ?
                    <img src={editIcon} onClick={() => editOwnerMode(owner)} style={{ width: '14px', marginRight: '5px', cursor: 'pointer'}} /> : null : null
                    }
                    { id ?
                      getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('D') !=-1 ?
                    <img src={imgStoragePath + 'cross_red.png'} onClick={() => deleteOwnerMode(owner)} style={{ width: '15px', cursor: 'pointer'}} /> : null : null
                    }
                    {
                      !id ?
                      <span>
                      <i class="fa fa-info-circle" aria-hidden="true" id="apu-field1" style={{paddingLeft: '4px'}}></i>
                        <UncontrolledTooltip placement="top" target="apu-field1">
                          Note: Base Lessor card cannot be edited or deleted. Please contact support@sparta.aero for further details.
                        </UncontrolledTooltip>
                      </span>
                        : null
                    }
                  </span>
                    <div className="folder-info owner-info">
                        <h4 >Name</h4>
                        <p>{owner.name ? owner.name : '--'}</p>
                        <h4 >Contact</h4>
                        <p>{owner.contact ? owner.contact : '--'}</p>
                        <h4 >Address</h4>
                        <p>{owner.address ? owner.address : '--'}</p>
                        <h4 >Jurisdiction</h4>
                        <p>{owner.jurisdiction ? owner.jurisdiction : '--'}</p>
                    </div>
                </div>
            </Col>
        )
    }
}
