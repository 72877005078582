import React, { Component } from 'react';
import moment from 'moment';
import { displayDateFormat } from '../../../constants';
const LeaseTimeline = ({leaseHistory}) => {
  return(
    <li className="list-inline-item">
      <span className="dot"></span >
      <span className="line"> </span>
      <div className="timeline-detail-block">
        <h4>{leaseHistory.type} </h4>
        <p className="caption-ui">{leaseHistory.lessor_name ? leaseHistory.lessor_name :leaseHistory.name}</p>
        {
          leaseHistory.type == 'Lessee' ?<h5>{moment(leaseHistory.start_date).format(displayDateFormat)} to {moment(leaseHistory.end_date).format(displayDateFormat)}</h5>:null
        }

        {
          leaseHistory.logo !== null && leaseHistory.logo !== undefined ?
            <img src={leaseHistory.logo} width="90" alt="img"/>
          :null
        }
      </div>
    </li>
  )
}
export default LeaseTimeline
