import React from 'react';
import { Grid } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../utils_v2';
const ReserveAccountFund = ({cashflowWidget}) => {
  return(
    <div className="reserve-account-section">
      <Grid container spacing={2}>
        { cashflowWidget.engines ?
          Object.keys(cashflowWidget.engines).map((key) =>
            <Grid item xs={12} md={2}>
              <div className="reserve-account-card">
                <h3>{cashflowWidget.engines[key].name}</h3>
                <div>
                  <h5>PRSV</h5>
                  <p>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.engines[key].prsv ? cashflowWidget.engines[key].prsv:0}</NumericLabel>
                  </p>
                </div>
                <div>
                  <h5>LLP Replacement</h5>
                  <p>
                    <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.engines[key].llp ? cashflowWidget.engines[key].llp:0}</NumericLabel>
                  </p>
                </div>
              </div>
            </Grid>
          ):null
        }
        <Grid item xs={12} md={2}>
          <div className="reserve-account-card">
            <h3>Airframe</h3>
            <p>Cost</p>
          </div>
        </Grid>
        { cashflowWidget.lg ?
          Object.keys(cashflowWidget.lg).map((key) =>
            <Grid item xs={12} md={2}>
              <div className="reserve-account-card">
                <h3>{cashflowWidget.lg[key].name}</h3>
                <p>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.lg[key].cost ? cashflowWidget.lg[key].cost:0}</NumericLabel>
                </p>
              </div>
            </Grid>
          ):null
        }
        { cashflowWidget.apu ?
          <Grid item xs={12} md={2}>
            <div className="reserve-account-card">
              <h3>APU</h3>
              <p>
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.apu ? cashflowWidget.apu:0}</NumericLabel>
              </p>
            </div>
          </Grid>:null
        }

        { cashflowWidget.propeller ?
          Object.keys(cashflowWidget.propeller).map((key) =>
            <Grid item xs={12} md={2}>
              <div className="reserve-account-card">
                <h3>{cashflowWidget.propeller[key].name}</h3>
                <p>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.propeller[key].cost ? cashflowWidget.propeller[key].cost:0}</NumericLabel>
                </p>
              </div>
            </Grid>
          ):null
        }
      </Grid>
    </div>
  )
}
export default ReserveAccountFund;
