import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader } from '../../../../shared_elements';
import { checkApiStatus } from '../../../../utils_v2';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { editFianceDetailApi } from '../apiServices';
import { errorCode } from '../';
import { regexConstants } from '../../../../constants/regEx';
import { withSnackbar } from 'notistack';
class DebtEquity extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader:false,
      finance:{},
      error:{}
    }
    this.editFianceDetailApi = editFianceDetailApi.bind(this);
  }
  onFieldChange = (event, keyParam, value) => {
    const { finance } = this.state;
    this.setState(prevState => ({
      ...prevState,
      finance:{
        ...prevState.finance,
        [keyParam]: value
      }
    }));
  }
  handleBlurEvent = (keyParam, value) => {
    const { finance } = this.state;
    let equityRatio= finance.equity_ratio, debitRatio = finance.debit_ratio, equityValue = finance.equity_value, debitValue = finance.debit_value;
    this.setState(prevState => ({
      ...prevState,
      finance:{
        ...prevState.finance,
        [keyParam]: value
      }
    }));
    switch (keyParam) {
      case 'equity_ratio':
        debitRatio = 100 - value;
        equityValue = 0;
        debitValue = 0;
        if(finance.purchase_value){
          equityValue = finance.purchase_value - finance.purchase_value*(100-value)/100;
          debitValue = finance.purchase_value - equityValue;
        }
        this.updateRatioAndRate(debitRatio, value, debitValue, equityValue);
        if(finance.tax_rate && finance.debit_ratio && finance.interest_rate && finance.return_equity){
          this.updateWACC(value, finance.debit_ratio, finance.tax_rate, finance.interest_rate, finance.return_equity);
        }
        break;
      case 'debit_ratio':
        equityRatio = 100 - value;
        equityValue = 0;
        debitValue = 0;
        if(finance.purchase_value){
          debitValue = finance.purchase_value - finance.purchase_value*(100-value)/100;
          equityValue = finance.purchase_value - debitValue;
        }
        this.updateRatioAndRate(value, equityRatio, debitValue, equityValue);
        if(finance.tax_rate && finance.equity_ratio && finance.interest_rate && finance.return_equity){
          this.updateWACC(finance.equity_ratio, value, finance.tax_rate, finance.interest_rate, finance.return_equity);
        }
        break;
      case 'equity_value':
        if(finance.purchase_value){
          if(parseFloat(value) <= parseFloat(finance.purchase_value)){
            debitValue = finance.purchase_value - value;
            equityRatio = value/finance.purchase_value*100;
            debitRatio = 100 - equityRatio;
            this.updateRatioAndRate(debitRatio, equityRatio, debitValue, value);
          }else{
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [keyParam]: 'Should be less or equal to Asset Value'
              }
            }))
          }
        }
        break;
      case 'debit_value':
        if(finance.purchase_value){
          if(parseFloat(value) <= parseFloat(finance.purchase_value)){
            equityValue = finance.purchase_value - value;
            debitRatio = value/finance.purchase_value*100;
            equityRatio = 100 - debitRatio;
            this.updateRatioAndRate(debitRatio, equityRatio, value, equityValue);
          }else{
            this.setState(prevState => ({
              ...prevState,
              error: {
                ...prevState.error,
                [keyParam]: 'Should be less or equal to Asset Value'
              }
            }))
          }
        }
        break;
      case 'purchase_value':
        if(finance.equity_ratio || finance.debit_ratio){
          if(finance.equity_ratio){
            debitRatio = 100 - finance.equity_ratio;
            equityValue = value - value*((100-finance.equity_ratio)/100);
            debitValue = value - equityValue;
            this.updateRatioAndRate(debitRatio, finance.equity_ratio, debitValue, equityValue);
          }else{
            equityRatio = 100 - finance.debit_ratio;
            debitValue = value - value*((100-finance.debit_ratio)/100);
            equityValue = value - debitValue;
            this.updateRatioAndRate(finance.debit_ratio, equityRatio, debitValue, equityValue);
          }
        }else if (finance.equity_value || finance.debit_value) {
          if(finance.equity_value){
            debitValue = value - finance.equity_value;
            equityRatio = finance.equity_value/value*100;
            debitRatio = 100 - equityRatio;
            this.updateRatioAndRate(debitRatio, equityRatio, debitValue, finance.equity_value);
          }else{
            equityValue = value - finance.debit_value;
            debitRatio = finance.debit_value/value*100;
            equityRatio = 100 - debitRatio;
            this.updateRatioAndRate(debitRatio, equityRatio, finance.debit_value, equityValue);
          }
        }
        break;
      case 'tax_rate':
        if(finance.equity_ratio && finance.debit_ratio && finance.interest_rate && finance.return_equity && value <= 100){
          this.updateWACC(finance.equity_ratio, finance.debit_ratio, value, finance.interest_rate, finance.return_equity);
        }
        break;
      case 'interest_rate':
        if(finance.equity_ratio && finance.debit_ratio && finance.tax_rate && finance.return_equity && value <= 100){
          this.updateWACC(finance.equity_ratio, finance.debit_ratio, finance.tax_rate, value, finance.return_equity);
        }
        break;
      case 'return_equity':
        if(finance.equity_ratio && finance.debit_ratio && finance.interest_rate && finance.tax_rate && value <= 100){
          this.updateWACC(finance.equity_ratio, finance.debit_ratio, finance.tax_rate, finance.interest_rate, value);
        }
        break;
      default:

    }
  }
  updateWACC = (equityRatio, debitRatio, taxRate, interestRate, returnEquity) => {
    let value = (equityRatio/100*returnEquity/100)+(debitRatio/100*interestRate/100)*(1-taxRate/100);
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        weighted_average_capital: parseFloat(value*100).toFixed(3)
      }
    }))
  }
  updateRatioAndRate = (debitRatio, equityRatio, debitValue, equityValue) => {
    this.setState(prevState => ({
      ...prevState,
      finance: {
        ...prevState.finance,
        equity_ratio: parseFloat(equityRatio).toFixed(2),
        debit_ratio:  parseFloat(debitRatio).toFixed(2),
        equity_value: equityValue ?  parseFloat(equityValue).toFixed(2):0,
        debit_value: debitValue ? parseFloat(debitValue).toFixed(2):0
      }
    }));
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  editFinanceDetail = () => {
    const { finance } = this.state;
    let validationInputs = {
      purchase_value:errorCode['purchase_value'][fieldValidation({...errorCode['purchase_valueObj'], fieldval: finance.purchase_value })],
      tax_rate:errorCode['tax_rate'][fieldValidation({...errorCode['tax_rateObj'], fieldval: finance.tax_rate })],
      debit_ratio:errorCode['debit_ratio'][fieldValidation({...errorCode['debit_ratioObj'], fieldval: finance.debit_ratio })],
      equity_ratio:errorCode['equity_ratio'][fieldValidation({...errorCode['equity_ratioObj'], fieldval: finance.equity_ratio })],
      debit_value:errorCode['debit_value'][fieldValidation({...errorCode['debit_valueObj'], fieldval: finance.debit_value })],
      equity_value:errorCode['equity_value'][fieldValidation({...errorCode['equity_valueObj'], fieldval: finance.equity_value })],
      interest_rate:errorCode['interest_rate'][fieldValidation({...errorCode['interest_rateObj'], fieldval: finance.interest_rate })],
      return_equity:errorCode['return_equity'][fieldValidation({...errorCode['return_equityObj'], fieldval: finance.return_equity })],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.editFianceDetailApi(this.props, finance)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, finance:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { modal, formSubmitLoader, finance, error,pageLoader } = this.state;
    const { financial } = this.props;
    return(
      <Fragment>
                       { pageLoader || formSubmitLoader ? <PageLoader />:null}
        <Tooltip title='Edit Debt / Equity'>
          <IconButton size="small" color="primary">
            <EditIcon fontSize="small" color="primary" onClick={() => this.setState({modal:true, finance:financial, error:{}})} />
          </IconButton>
        </Tooltip>
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, finance:{}, error:{}})}
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">
                 Edit Debt/Equity
              </div>
              <div className="drawer-body">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="purchase_value"
                      label="Purchase value of Asset"
                      fullWidth
                      margin="normal"
                      value={finance.purchase_value ? finance.purchase_value:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'purchase_value', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.purchase_value?true:false}
                      helperText={error.purchase_value ? error.purchase_value:''}
                      onFocus={() => this.updateErrorField('purchase_value', '')}
                      onBlur={(e) => this.handleBlurEvent('purchase_value', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="tax_rate"
                      label="Tax Rate"
                      fullWidth
                      margin="normal"
                      value={finance.tax_rate ? finance.tax_rate:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'tax_rate', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.tax_rate?true:false}
                      helperText={error.tax_rate ? error.tax_rate:''}
                      onFocus={() => this.updateErrorField('tax_rate', '')}
                      onBlur={(e) => this.handleBlurEvent('tax_rate', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="debit_ratio"
                      label="Debt Ratio"
                      fullWidth
                      margin="normal"
                      value={finance.debit_ratio ? finance.debit_ratio:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'debit_ratio', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.debit_ratio?true:false}
                      helperText={error.debit_ratio ? error.debit_ratio:''}
                      onFocus={() => this.updateErrorField('debit_ratio', '')}
                      onBlur={(e) => this.handleBlurEvent('debit_ratio', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="equity_ratio"
                      label="Equity Ratio"
                      fullWidth
                      margin="normal"
                      value={finance.equity_ratio ? finance.equity_ratio:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'equity_ratio', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.equity_ratio?true:false}
                      helperText={error.equity_ratio ? error.equity_ratio:''}
                      onFocus={() => this.updateErrorField('equity_ratio', '')}
                      onBlur={(e) => this.handleBlurEvent('equity_ratio', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="debit_value"
                      label="Debt Value"
                      fullWidth
                      margin="normal"
                      value={finance.debit_value ? finance.debit_value:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'debit_value', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.debit_value?true:false}
                      helperText={error.debit_value ? error.debit_value:''}
                      onFocus={() => this.updateErrorField('debit_value', '')}
                      onBlur={(e) => this.handleBlurEvent('debit_value', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="equity_value"
                      label="Equity Value"
                      fullWidth
                      margin="normal"
                      value={finance.equity_value ? finance.equity_value:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'equity_value', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.equity_value?true:false}
                      helperText={error.equity_value ? error.equity_value:''}
                      onFocus={() => this.updateErrorField('equity_value', '')}
                      onBlur={(e) => this.handleBlurEvent('equity_value', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="interest_rate"
                      label="Interest Rate on Debt p.a"
                      fullWidth
                      margin="normal"
                      value={finance.interest_rate ? finance.interest_rate:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'interest_rate', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.interest_rate?true:false}
                      helperText={error.interest_rate ? error.interest_rate:''}
                      onFocus={() => this.updateErrorField('interest_rate', '')}
                      onBlur={(e) => this.handleBlurEvent('interest_rate', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="return_equity"
                      label="Expected Return on Equity p.a"
                      fullWidth
                      margin="normal"
                      value={finance.return_equity ? finance.return_equity:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'return_equity', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      error={error.return_equity?true:false}
                      helperText={error.return_equity ? error.return_equity:''}
                      onFocus={() => this.updateErrorField('return_equity', '')}
                      onBlur={(e) => this.handleBlurEvent('return_equity', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="drawer-footer">
                <Button onClick={this.editFinanceDetail} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes'}
                </Button>
                <Button onClick={() => this.setState({modal:false, finance:{}, error:{}})} color="primary">Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(DebtEquity));
