import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { displayDateFormat, backendDateFormat, fieldDateFormat, intRegx, float2DecRegx } from '../../../../constants'
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class RentalAdhocInvoiceItem extends Component {
  constructor(props) {
    super(props)
  }
  render(){
    const { type, invoiceInfo, invoice_item, index, multiCurrency, isJac } = this.props
    if(type === 'view'){
      return(
        <tr>
          <td>
            <h6 className="para-ui">
              {invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.name ? invoice_item.invoice_item_type_id.name : invoice_item.is_additional_rate ? 'Additional Rent' : 'Basic Rent'}
              {invoice_item.is_additional_rate ?
                <span>
                  <p style={{ fontSize: '12px', marginTop: '10px' }}>Libor(%)</p>
                  <p style={{ marginTop: '5px' }}>{invoice_item.libor_value}</p>
                  <p style={{ fontSize: '12px', marginTop: '10px' }}>Libor Rate as of date</p>
                  <p style={{ marginTop: '5px' }}>{invoice_item.libor_date ? moment(invoice_item.libor_date).format(displayDateFormat) : '--'}</p>
                </span> : null}
            </h6>
          </td>
          <td className="invoice-description-block">
            <h6 className="para-ui"> {invoice_item.is_additional_rate ? invoice_item.payment_schedule ?
              <p>Dollar margin applied: {invoice_item.payment_schedule.margin}%, Rental Period: {invoice_item.payment_schedule.period}, Dollar Reference Value:
                <NumericLabel params={showCurrencyFormat('USD')}>
                  {invoice_item.payment_schedule.reference_value}
                </NumericLabel></p>
              : '' : invoice_item.description}</h6>
          </td>
          <td>
            <h6 className="para-ui"> {invoice_item.unit_type}</h6>
          </td>
          <td>
            <h6 className="para-ui">
              {invoice_item.no_of_unit}
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.rate_per_unit}
              </NumericLabel>
            </h6>
          </td>
          {
            isJac ?
            null :
          <td>
            {invoice_item.tax_rate }
          </td>}
          {
            isJac ?
            null :
          <td>
            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
              {parseFloat(invoice_item.tax_amount).toFixed(2)}
            </NumericLabel>
          </td>}
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.sub_total}
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td style={{ width: '125px' }}>
            <textarea onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'invoice_item_name', index)}>{invoice_item.invoice_item_name}</textarea>
            {invoice_item.is_additional_rate ?
              <span>
                <p style={{ fontSize: '12px', marginTop: '10px' }}>Libor(%)</p>
                <input type="text" value={invoice_item.libor_value}
                  onChange={(e) => multiCurrency ? this.props.updateChildrenItemField(e.target.value, 'libor_value') : this.props.updateInvoiceItemField(e.target.value, 'libor_value', index)}
                />
                {this.props.validationArray.length > 0 ?
                  this.props.validationArray[index].libor_value == false ?
                    <h6 className="error-msg">Please enter valid values</h6> : null : null}
                <p style={{ fontSize: '12px', marginTop: '10px' }}>Libor Rate as of date</p>
                <DatePicker
                  dateFormat={fieldDateFormat}
                  selected={invoice_item.libor_date && moment(invoice_item.libor_date).isValid() ? moment(invoice_item.libor_date) : moment()}
                  onChange={(e) => multiCurrency ? this.props.updateChildrenItemField(e.target.value, 'libor_date') : this.props.updateInvoiceItemField(moment(e).format(backendDateFormat), 'libor_date', index)}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                />

              </span> : null}
          </td>
          <td className="invoice-description-block">
            {invoice_item.is_additional_rate ? invoice_item.payment_schedule ?
              <h6 className="para-ui">
                <p>Dollar margin applied: {invoice_item.payment_schedule.margin}%, Rental Period: {invoice_item.payment_schedule.period}, Dollar Reference Value:
                  <NumericLabel params={showCurrencyFormat('USD')}>
                    {invoice_item.payment_schedule.reference_value}
                  </NumericLabel>
                </p></h6>
              : null : <textarea onChange={(e) => multiCurrency ? this.props.updateChildrenItemField(e.target.value, 'description') : this.props.updateInvoiceItemField(e.target.value, 'description', index)}>{invoice_item.description}</textarea>
            }
          </td>
          <td>
            <textarea onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'unit_type', index)}>{invoice_item.unit_type}</textarea>
          </td>
          <td>
            <input type="text" value={invoice_item.no_of_unit}
              onChange={(e) => float2DecRegx.test(e.target.value) ? this.props.updateInvoiceItemField(e.target.value, 'no_of_unit', index, invoice_item.invoice_item_type_id.name, invoice_item.unit_type) : null}
            />
            {this.props.validationArray.length > 0 ?
              this.props.validationArray[index].no_of_unit == false ?
                <h6 className="error-msg">Please enter valid values</h6> : null : null}
          </td>
          <td>
            {
              invoice_item.is_additional_rate ?
                <h6 className="para-ui"> {invoice_item.rate_per_unit}</h6> :
                <input type="text" value={invoice_item.rate_per_unit}
                  onChange={(e) => float2DecRegx.test(e.target.value) ? this.props.updateInvoiceItemField(e.target.value, 'rate_per_unit', index) : null}
                />
            }
            {this.props.validationArray.length > 0 ?
              this.props.validationArray[index].rate_per_unit == false ?
                <h6 className="error-msg">Please enter valid values</h6> : null : null}
          </td>
          {
            isJac ?
            null :
            <td className="invoice-usd-day">
            <input type="text"  value={invoice_item.tax_rate}
               onChange={(e) => float2DecRegx.test(e.target.value) ? this.props.updateInvoiceItemField(e.target.value, 'tax_rate', index) : null}
            />
             {this.props.validationArray.length>0 ?
             this.props.validationArray[index].tax_rate == false ?
             <h6 className="error-msg">Please enter vaild value</h6>:null:null}
          </td>}
          {
            isJac ?
            null :
          <td>
            <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>{invoice_item.tax_amount}</NumericLabel>
          </td> }
          <td>
            <input type="text" value={invoice_item.sub_total}
              onChange={(e) => float2DecRegx.test(e.target.value) ? this.props.updateInvoiceItemField(e.target.value, 'sub_total', index) : null}
            />
          </td>
        </tr>
      )
    }
  }
}
