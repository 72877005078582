import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'
import { globalGetService } from '../../../../globalServices'
import { Autocomplete } from '@material-ui/lab'
import { checkApiStatus } from '../../../../utils_v2'
import { useSnackbar } from 'notistack'
import { EmptyCollection, TableListComp } from '../../../../shared_elements'
import DeleteIcon from '@material-ui/icons/Delete';
import { regexConstants } from '../../../../constants/regEx'

export default function LLpSplit({ onClose, saveLLpSplit, data }) {
    const emptyObject = {
        engine_thrust_type: '',
        cycles: '',
        is_current_thrust: false
    }
    const llpSplitHd = [
        { id: 'engine_thrust_type', label: 'Engine Thrust Type', sortOption: false },
        { id: 'cycles', label: 'Cycles', sortOption: false },
        { id: 'is_current_thrust', label: 'Current Thrust', sortOption: false },
        { id: 'action', label: 'Action', sortOption: false, actionCell: true },
    ]
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [engineType, setEngineTypes] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [llpRowData, setLLpRowData] = useState(data?.csn_llp_split?.length ?data?.csn_llp_split:[{...emptyObject,is_current_thrust:true}])
    useEffect(() => {
        getEngineThurst()
    }, [])
    const getEngineThurst = () => {
        globalGetService('console/engine-types/', { engine_family_engine_type: data?.engine_type_id })
            .then(response => {
                if (checkApiStatus) {
                    setEngineTypes(response?.data?.data?.engineTypes)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
    }
    const onFieldChange = (key, value, index) => {
        let val = value
        if(key == 'engine_thrust_type' || key == 'cycles'){
            val = key == 'engine_thrust_type' ?{
                name:value.name,
                id: value.id,
                engine_thrust_name: value?.engine_thrust_name,
                engine_thrust_value: value?.engine_thrust_value
            }:value
            setLLpRowData(llpRowData.map((rowItem, rowIndex) => (
                rowIndex == index ? {
                    ...rowItem,
                    [key]: val,
                } : rowItem)
            ))
        }
        else{
            setLLpRowData(llpRowData.map((rowItem, rowIndex) => (
                rowIndex == index ? {
                    ...rowItem,
                    [key]: true
                } : {...rowItem, [key]:false})
            ))
        }
    }
    const deleteRowData = (index) => {
            setLLpRowData(llpRowData.filter((item, rowIndex) => index != rowIndex))
    }
    const onAddNewRow = () => {
        let data = [...llpRowData, llpRowData?.length ? emptyObject :{...emptyObject,is_current_thrust:true}]
        setLLpRowData(data)
    }
    const checkForErrors = (validateNewInput) => {
        let check = validateNewInput.map((item, index) => {
            if (item?.cycles || item?.engine_thrust_type) {
                return true
            }
            else {
                return false
            }
        })
        return check
    }
    const onSubmit = () => {
        let validateNewInput = llpRowData
        validateNewInput = validateNewInput.map((item, index) => ({
            engine_thrust_type: item?.engine_thrust_type ? '' : "Please select Thrust type",
            cycles: item?.cycles ? '' : "Please enter cycles",
        }))
        if(llpRowData?.length == 0){
            enqueueSnackbar('Please add atleast 1 row item to proceed.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        else if (checkForErrors(validateNewInput).filter(item => item == true)?.length == 0) {
            let totalCycles= llpRowData.map(item=>  parseFloat(item?.cycles)).reduce((prevVal, val)=> prevVal+val)
            saveLLpSplit(llpRowData, totalCycles)
        }
        else{
            setError(validateNewInput)
        }
    }
    const onFocus = (key, index) => {
        setError(error?.map((item, errIndex) => (
            index == errIndex ? { ...item, [key]: '' } : item
        )))
    }
    return (
        <Dialog open='true'
            maxWidth={'md'}
            className='mou-dialog'>
            <DialogTitle>LLp Split
                <Button style={{ marginRight: '10px', float: 'right' }} color='primary' variant='outlined' size='small' onClick={() => onAddNewRow()} >
                    +Add new Row
                </Button>

            </DialogTitle>
            <DialogContent dividers style={{ width: "950px", marginLeft:'0px' }} className='fleet-lease-management-sec'>
                <TableListComp
                    maxheight='500px'
                    heads={llpSplitHd}
                    data={llpRowData?.map((item, index) => (
                        <TableRow hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
                            <TableCell className="asset-info" style={{ cursor: 'pointer' }} >
                                <Autocomplete
                                    options={engineType}
                                    getOptionLabel={option => option?.name}
                                    value={llpRowData[index]?.engine_thrust_type || null}
                                    id="engine_thrust_type"
                                    style={{marginBottom:'4px', marginTop:'4px'}}
                                    onChange={(e, value) => onFieldChange('engine_thrust_type', value, index)}
                                    renderInput={params => <TextField onFocus={() => onFocus('engine_thrust_type', index)} error={error[index]?.engine_thrust_type ? true : false} helperText={error[index]?.engine_thrust_type || ''} style={{ padding: '0px' }} {...params} InputLabelProps={{ shrink: true }} variant="outlined" />}
                                />
                            </TableCell>
                            <TableCell className="asset-info" style={{ cursor: 'pointer' }} >
                                <TextField
                                    required
                                    style={{marginBottom:'4px', marginTop:'4px'}}
                                    id="cycles"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    margin="normal"
                                    error={error[index]?.cycles ? true : false}
                                    helperText={error[index]?.cycles || ''}
                                    value={llpRowData[index]?.cycles || ''}
                                    variant='outlined'
                                    onFocus={() => onFocus('cycles', index)}
                                    onChange={(e) => { regexConstants?.numberWithDot.test(e.target.value) ? onFieldChange('cycles', e.target.value, index) : e.preventDefault() }}
                                />
                            </TableCell>
                            <TableCell className="" style={{ cursor: 'pointer' }} >
                                <Checkbox
                                    style={{ marginLeft: "15px", fontSize: "12px" }}
                                    color="primary"
                                    size='small'
                                    checked={llpRowData[index]?.is_current_thrust || false}
                                    onChange={(e) => onFieldChange('is_current_thrust', e.target.checked, index)}
                                />
                            </TableCell>
                            <TableCell align='right' className="" style={{ cursor: 'pointer', width:'50px' }} >
                                <DeleteIcon fontSize='small' color='secondary' style={{ cursor: 'pointer' }} onClick={() => deleteRowData(index)} />
                            </TableCell>
                        </TableRow>
                    ))
                    }
                    noRecord={llpRowData?.length ? null :
                        isLoading ? null :
                            <EmptyCollection
                                title="No records found"
                            />
                    }

                />
            </DialogContent>
            <DialogActions>
                <Button style={{ marginRight: '10px' }} color='primary' variant='outlined' size='small' onClick={() => onClose()} >
                    Cancel
                </Button>
                <Button color='primary' variant='contained' size='small' onClick={() => onSubmit()} >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
