import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton, TextField } from '@material-ui/core';
import AddEditRentalUtils from './AddEditRentalUtils';
const RentalUtilList = ({item, rentalUtilis}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.month}</TableCell>
      <TableCell>{item.hours}</TableCell>
      <TableCell>{item.cycles}</TableCell>
      <TableCell>{item.block_hour}</TableCell>
      <TableCell>
        {item.remarks}
        { item.editable ?
          <AddEditRentalUtils id={item.id} m={'E'} rentalUtilis={rentalUtilis} item={item}  />:null
        }
      </TableCell>
    </TableRow>
  )
}
export default RentalUtilList;
