import { globalGetService, globalPostService, globalPutService,globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { toastFlashMessage } from '../../../utils';
export function getPartiesApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/parties/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getPartyApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/parties/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        party: response.data.data,
        error:{},
        modal:true,
        mode : mode === 'V'?'view':'edit'
      })
    }
    this.setState({pageLoader:false});
  })
}
export function addEditPartyApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/parties/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/parties/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}

export function getRolesListApi(){
  globalGetService('contracts/party-roles/')
  .then(response => {
    if(response.data.statusCode == 200){
      this.setState({
        rolesList:response.data.data
      })
    }
  })
}

 export function deletePartylistApi(props , deleteItem) {
  this.setState({pageLoader:true})
  globalDeleteService(`contracts/${props.params.contract_slug}/parties/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({pageLoader:false})
        this.setState(prevState => ({
          ...prevState,
          list: prevState.list.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, data:null}
        }))
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
    })
}
