import React, { Component, Fragment } from 'react'
import { Link } from 'react-router';
import { UncontrolledCollapse, Table, Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../constants';
import  PermissionModuleList  from './PermissionModuleList';
import { globalGetService } from '../../globalServices';
class PermissionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      arrowHeading:false,
      modalOpen:false,
      securityGrpUser: {}
    }
  }
  componentDidMount() {
    globalGetService(`console/get-sparta-group-users/?group_ids=${this.props.item.id}`)
      .then(response => {
        if (response.data.statusCode == 200) {
          this.setState({
            securityGrpUser: response.data.data[0]
          })
        }
      })
  }
  handleAccordian = () => {
    this.setState(prevState => ({
      arrowHeading:!prevState.arrowHeading
    }))
  }
  toggleModal=()=>{
    this.setState({ modalOpen: true })
  }
  
  render() {
    const { i , item } = this.props
    const { arrowHeading, modalOpen, securityGrpUser } = this.state
    const objectArray = Object.keys(item.permission);
    const permissionList = Object.keys(item.permission);
    const closeBtn = <button className="close" onClick={() => this.setState({ modalOpen: false })}>&times;</button>;
    return (
      <Fragment>
        <Modal isOpen={this.state.modalOpen} style={{ maxWidth: '400px' }}>
          <ModalHeader toggle={this.toggleModal} close={closeBtn}>{item.name}</ModalHeader>
          <ModalBody>
            <Row className="transfer-modal-body">
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: '100px' }}>Name</th>
                      <th style={{ width: '100px' }}>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(securityGrpUser).length && securityGrpUser.users.length ?
                        securityGrpUser.users.sort((a, b) => {
                          var nameA = a.name.toUpperCase(); 
                          var nameB = b.name.toUpperCase(); 
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                          return 1;
                          }
                          return 0;
                        }).map((user, index) =>
                          <tr> 
                            <td style={{ padding: '5px' }} key={index}>{user.name}</td>
                            <td style={{ padding: '5px' }}>{user.designation}</td>
                          </tr>)
                        : null
                    }
                  </tbody>
                </Table> 
              </Col>
            </Row>
            {/* <Row className="transfer-modal-footer">
              <Col md="12">
                <Button color="primary" style={{ float: 'right', backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} onClick={() => this.setState({ modalOpen: false })}>CLOSE</Button>
              </Col>
            </Row> */}
          </ModalBody>
        </Modal>
        <li>
          <div style={{ borderBottom: '1px solid #dbdbdb', padding: '10px', position: 'relative' }}>
            <h5
              id={`toggler${i}`}
              style={{ marginBottom: '1rem', color: '#000000' }}
              onClick={this.handleAccordian}
            >
              {arrowHeading ?
                <i className="fa fa-chevron-up" style={{ color: '#000000', marginRight: '5px' }}></i>
                : <i className="fa fa-chevron-down" style={{ color: '#000000', marginRight: '5px' }}></i>
              }
              {item.name}
            </h5>
            <UncontrolledCollapse toggler={`toggler${i}`} >
              {permissionList.length ? permissionList.map((module, index) =>
                <PermissionModuleList key={index} module={module} index={index} item={item} topIndex={i} />
              ) : null}
              
            </UncontrolledCollapse>
            {
              this.props.item.users_count === 0 ? null : <Button color="primary" style={{ position: 'absolute', top: '10px', right: '65px', width: '140px',  textAlign: 'center', backgroundColor: '#3f51b5', borderColor: '#3f51b5'}} onClick={this.toggleModal}>User List ({this.props.item.users_count})</Button>
            }
          </div>
        </li>
      </Fragment>
        )
        
  }
}
export default PermissionList;
