import React, { useEffect, useState } from 'react'
import { Header, ContractHeadertext, ContractSideBar } from '../../../applications/contracts/Elements';
import { ContractWrapper } from '../../Elements';
import { assetId, assetsType, backendDateFormat } from '../../../constants';
import { browserHistory } from 'react-router';
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus, toastFlashMessage, downloadFileType, getLocalStorageInfo, checkPermission } from '../../../utils_v2';
import { PageLoader, TableListComp, EmptyCollection } from '../../../shared_elements';
import { checkString, checkValidDate, checkDateDiffText } from '../../../formValidator';
import { FilterComponent } from '../../../shared_elements';
import moment from 'moment';
import { Clear } from '@material-ui/icons';
import { Row, Col, Modal, ModalHeader, ModalBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalFooter } from 'reactstrap';
import ChangeInvoiceStatus from '../../../applications/contracts/Invoice/components/ChangeInvoiceStatus';
import { Button, Grid, Paper } from '@material-ui/core';
import InvoiceCRU from '../components/InvoiceCRU';
import InvoiceList from '../components/InvoiceList';
import { InvoiceFilterOps, invoiceListHd } from '..';
import InvoiceDetails from '../components/InvoiceDetails';
import { DeleteModal } from '../../../applications/technicalInspection/Elements';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const AssmblyInvoices = (props) => {
    const [invoiceList, setInvoiceList] = useState([])
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [invoiceCreatedDetails, setInvoiceCreatedDetails] = useState({})
    const [invoiceTypeList, setInvoiceTypeList] = useState([])
    const [invoiceOtherInfo, setInvoiceOtherInfo] = useState({ bank_details: [] })
    const [invoiceValidateInfo, setInvoiceValidateInfo] = useState({ bank_details: [] })
    const [modal, setModal] = useState(false)
    const [sharedInfo, setSharedInfo] = useState({})
    const [validationMsgs, setValidationMsgs] = useState({})
    const [assemblyLeaseDetails, setAssemblyLeaseDetails] = useState({})
    const [invoiceValidationMsgs, setInvoiceValidationMsgs] = useState({})
    const [filter, setFilter] = useState({})
    const [leaseLoader, setLeaseLoader] = useState(false)
    const [pageLoader, setPageLoader] = useState(false)
    const [detailsPageLoader, setDetailsPageLoader] = useState(false)
    const [changeStatusModal, setChangeStatusModal] = useState(false)
    const [statusChange, setStatusChange] = useState(false)
    const [statusChangeConfirm, setStatusChangeConfirm] = useState(false)
    const [statusChangeList, setStatusChangeList] = useState(false)
    const [detailsPage, setDetailsPage] = useState(false)
    const [showStatusTimeLine, setshowStatusTimeLine] = useState({})
    const [deleteInvoice, setDeleteInvoice] = useState(false)
    useEffect(() => {
        getInvoiceListFn()
        getAssemblyLeaseDetailsFn()
        getInvoiceTypeDropdownList()
        if( props?.location?.query?.details){
            redirectToDetails({id:props?.location?.query?.details})
        }
    }, [])
    const getInvoiceTypeDropdownList = () => {
        globalGetService(`console/get-constants/?constant_type=assembly_lease_invoice&sequence=True'`)
            .then(response => {
                setInvoiceTypeList(response.data.data)
            })

    }
    const getAssemblyLeaseDetailsFn = (data) => {
        setLeaseLoader(true)
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/${props.params?.slug}`)
            .then(response => {
                setLeaseLoader(false)
                if (checkApiStatus(response)) {
                    setAssemblyLeaseDetails(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const onInvoiceChange = (key, value) => {
        setInvoiceDetails({ ...invoiceDetails, [key]: value })
        if (key == 'invoice_type' && value) {
            globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/initialize/?invoice_type=${value?.value}`)
                .then(response => {
                    setInvoiceOtherInfo(response.data.data)
                    setInvoiceValidateInfo(response.data.data)
                })
        }
    }
    const deleteInvoiceFn = (data) => {
        setPageLoader(true);
        globalDeleteService(`/contracts/assembly-lease/${props.params.slug}/assembly-invoice/${data?.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    toastFlashMessage(response.data.message, 'success');
                    let location = browserHistory.getCurrentLocation();
                    browserHistory.push(location.pathname);
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
                setPageLoader(false);
            })
    }
    const onInvoiceStatusChangeApi = (data, invoiceDetails, listview) => {
        setPageLoader(true)
        globalPutService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/${invoiceDetails?.id?invoiceDetails?.id:invoiceCreatedDetails?.id}/status-update/`, data)
            .then(response => {
                setPageLoader(false)
                setStatusChangeList({modal:false})
                setChangeStatusModal(false)
                if(!listview){
                    redirectToDetails(invoiceDetails)
                }
                getInvoiceListFn()
                if (checkApiStatus(response)) {
                }
            })
    }

    const getInvoiceStatusTimeline = (query) => {
        setPageLoader(true)
        globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/${query?.id}/assembly-invoice-transition/`)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setshowStatusTimeLine({ data: response.data.data.list, modal: true })
                }
            })
    }
    const getInvoiceListFn = (query) => {
        setPageLoader(true)
        globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/`, query)
            .then(response => {
                delete query?.page;
                delete query?.per_page;
                if (query) {
                    setFilter(query)
                }
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setInvoiceList(response.data.data)
                }
            })
    }
    const onChangeInvoiceDetails = (event, key, value, index) => {
        let subTotal = 0;
        let billableAmount = 0;
        let taxAmount = 0;
        setInvoiceValidationMsgs({ ...invoiceValidationMsgs, [key]: '' })
        if (event == 'invoice_items') {
            setInvoiceCreatedDetails({
                ...invoiceCreatedDetails, invoice_items: invoiceCreatedDetails.invoice_items.map((item, rowindex) =>
                    rowindex == index ?
                        {
                            ...item,
                            [key]: value,
                        } : item
                )
            })
            if (key == 'no_of_unit') {
                billableAmount = (parseFloat(invoiceCreatedDetails.invoice_items[index].rate_per_unit) * parseFloat(value));
                taxAmount = parseFloat(parseFloat(billableAmount) * (parseFloat(invoiceCreatedDetails.invoice_items[index].tax_rate) / 100));
                subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
                setInvoiceCreatedDetails({
                    ...invoiceCreatedDetails,
                    invoice_items: invoiceCreatedDetails.invoice_items.map((item, index) => index == index ? { ...item, [key]: value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount: taxAmount > 0 ? taxAmount : 0 } : item),
                    sub_total: subTotal > 0 ? subTotal : 0,
                    billable_amount: billableAmount > 0 ? billableAmount : 0,
                    tax_amount: taxAmount > 0 ? taxAmount : 0,
                    total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
                })
            }
            else if (key == 'rate_per_unit') {
                billableAmount = (parseFloat(value) * parseFloat(invoiceCreatedDetails.invoice_items[index].no_of_unit));
                taxAmount = parseFloat(parseFloat(billableAmount) * (parseFloat(invoiceCreatedDetails.invoice_items[index].tax_rate) / 100));
                subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
                setInvoiceCreatedDetails({
                    ...invoiceCreatedDetails,
                    invoice_items: invoiceCreatedDetails.invoice_items.map((item, index) => index == index ? { ...item, [key]: value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount: taxAmount > 0 ? taxAmount : 0 } : item),
                    sub_total: subTotal > 0 ? subTotal : 0,
                    billable_amount: billableAmount > 0 ? billableAmount : 0,
                    tax_amount: taxAmount > 0 ? taxAmount : 0,
                    total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
                })
            }
            else if (key == 'sub_total') {
                billableAmount = (parseFloat(value) * parseFloat(invoiceCreatedDetails.invoice_items[index].no_of_unit));
                taxAmount = parseFloat(parseFloat(billableAmount) * (parseFloat(invoiceCreatedDetails.invoice_items[index].tax_rate) / 100));
                subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2))).toFixed(2);
                setInvoiceCreatedDetails({
                    ...invoiceCreatedDetails,
                    total_amount: parseFloat(value) > 0 ? parseFloat(value) : 0,
                    invoice_items: invoiceCreatedDetails.invoice_items.map((item, index) => index == index ? { ...item, sub_total: value !== '' ? parseFloat(value) : '', billable_amount: value !== '' ? value : '' } : item)
                })
            }
        }
        else {
            setInvoiceCreatedDetails({ ...invoiceCreatedDetails, [key]: value })
        }
    }
    const onSubmitInvoiceDetails = (data) => {
        let payload = invoiceCreatedDetails
        let validateNewInput = {
            invoice_number: checkString({ value: payload.invoice_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Invoice Number ' }),
            date: checkValidDate({ value: payload.date, required: true, minLength: '', maxLength: '', message: 'Please Select Invoice Date ' }),
            due_date: checkValidDate({ value: payload.due_date, required: true, minLength: '', maxLength: '', message: 'Please Select Invoice Due Date' }),
            start_date: checkValidDate({ value: payload.start_date, required: true, minLength: '', maxLength: '', message: 'Please Select Start Date ' }),
            end_date: checkValidDate({ value: payload.end_date, required: true, minLength: '', maxLength: '', message: 'Please Select End Date ' }),
        }

        payload = {
            ...payload,
            status: invoiceCreatedDetails?.status?.value ? invoiceCreatedDetails?.status?.value : null,
            invoice_type: invoiceCreatedDetails?.invoice_type?.value ? invoiceCreatedDetails?.invoice_type?.value : null,
            start_date: invoiceCreatedDetails?.start_date ? moment(invoiceCreatedDetails?.start_date).format(backendDateFormat) : null,
            end_date: invoiceCreatedDetails?.end_date ? moment(invoiceCreatedDetails?.end_date).format(backendDateFormat) : null,
            date: invoiceCreatedDetails?.date ? moment(invoiceCreatedDetails?.date).format(backendDateFormat) : null,
            due_date: invoiceCreatedDetails?.due_date ? moment(invoiceCreatedDetails?.due_date).format(backendDateFormat) : null,
            invoice_for_date: invoiceCreatedDetails?.invoice_for_date ? moment(invoiceCreatedDetails?.invoice_for_date).format(backendDateFormat) : null,
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            setPageLoader(true)
            globalPutService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/${invoiceCreatedDetails?.id}/ `, payload)
                .then(response => {
                    setPageLoader(false)
                    if (checkApiStatus(response)) {
                        setDetailsPage({ type: 'view' })
                        getInvoiceListFn()
                        redirectToDetails(invoiceCreatedDetails)
                        toastFlashMessage(response.data.message, 'success')
                    }
                })
        }
        else {
            setInvoiceValidationMsgs(validateNewInput)
        }
    }
    const updateInvoiceErrorField = (key) => {
        setInvoiceValidationMsgs({ ...invoiceValidationMsgs, [key]: '' })
    }
   
    const exportInvoice = (data,) => {
        setPageLoader(true)
        globalExportService(`contracts/assembly-lease/${props?.params?.slug}/assembly-invoice/${data?.id}/?download=pdf`)
            .then(response => {
                setPageLoader(false)
                downloadFileType(response, 'Invoice', 'pdf')
            })
    }
    const exportInvoiceListFn = (query) => {
        setPageLoader(true)
        globalExportService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/?download=pdf`, query)
            .then(response => {
                setPageLoader(false)
                downloadFileType(response, 'Invoice_List', 'pdf')
            })

    }
    const redirectToDetails = (data) => {
        setDetailsPageLoader(true)
        let location = browserHistory.getCurrentLocation();
        location = {
            ...location,
            query: {
                ...location.query,
                'details': data?.id,
            }
        }
        globalGetService(`/contracts/assembly-lease/${props.params.slug}/assembly-invoice/${data?.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setTimeout(() => {
                        setDetailsPageLoader(false)
                    }, 400);
                    setTimeout(() => {
                        setDetailsPage({ type: 'view' })
                        browserHistory.push(location)
                    }, 10);
                    setInvoiceCreatedDetails(response.data.data)
                }
                else {
                    setDetailsPageLoader(false)
                    toastFlashMessage(response?.data?.message, 'error')
                }
            })
    }
    const addInvoiceDetails = (query) => {
        let payload = invoiceDetails
        let validateNewInput = {}
        validateNewInput = {
            ...validateNewInput,
            invoice_type: checkString({ value: payload.invoice_type, required: true, minLength: '', maxLength: '', message: 'Please Select Invoice Type ' }),
            bank_details: checkString({ value: payload.bank_details, required: true, minLength: '', maxLength: '', message: 'Please Select Bank Name ' }),
        }
        if (payload?.invoice_type?.value == 1) {
            validateNewInput = {
                ...validateNewInput,
                start_date: checkString({ value: payload.start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Invoice Start Date ' }),
                end_date: checkDateDiffText({ value: payload.start_date, required: true }, { value: payload.end_date, required: true }, 'Invoice Start Date', 'Invoice End Date'),
            }
        }
        else if (payload?.invoice_type?.value == 2) {
            validateNewInput = {
                ...validateNewInput,
                month: checkString({ value: payload.month, required: true, minLength: '', maxLength: '', message: 'Please select month ' }),
            }
        }
        else if ([4, 5].includes(payload?.invoice_type?.value)) {
            validateNewInput = {
                ...validateNewInput,
                info: checkString({ value: payload.info, required: true, minLength: '', maxLength: '', message: 'Please select Info ' }),
            }
        }
        payload = {
            ...payload,
            invoice_type: payload?.invoice_type?.value
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            setPageLoader(true)
            globalPostService(`/contracts/assembly-lease/${props.params.slug}/assembly-invoice/`, payload)
                .then(response => {
                    setPageLoader(false)
                    if (checkApiStatus(response)) {
                        setModal(false)
                        getInvoiceListFn()
                        toastFlashMessage(response?.data?.message, 'success')
                    }
                })
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    const updateErrorField = (key) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }
    let filterOptions = InvoiceFilterOps;
    filterOptions = {
        ...filterOptions,
        invoice_type: {
            ...filterOptions.invoice_type,
            options: invoiceTypeList.filter(item => item.value != 3)
        }
    }
    let location = browserHistory.getCurrentLocation()
    return (
        <div className="contract-container" style={{height:'auto'}}>
            {
                checkPermission('assembly_lease','invoice','U') && assemblyLeaseDetails?.assembly_lease_expired ?
                <p className="assembly-past-mode" style={{marginLeft:'56px', width:"94%"}}> Please unlink the major assembly as the assembly lease has been expired </p> : null
            }
            {
               leaseLoader || pageLoader || detailsPageLoader? <PageLoader /> : null
            }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} assemblytype={'assemblytype'} props={props} params={props.params} />
            <div className="contract-content-blk">
                {
                    detailsPage == false && !location?.query?.details?
                        <>
                        {
                            assemblyLeaseDetails?.assembly_swap?.value == 1 ?
                            <InvoiceCRU
                                setValidationMsgs={setValidationMsgs}
                                setInvoiceDetails={setInvoiceDetails}
                                updateErrorField={updateErrorField}
                                validationMsgs={validationMsgs}
                                addInvoiceDetails={addInvoiceDetails}
                                invoiceValidateInfo={invoiceValidateInfo}
                                invoiceOtherInfo={invoiceOtherInfo}
                                invoiceDetails={invoiceDetails}
                                onInvoiceChange={onInvoiceChange}
                                invoiceTypeList={invoiceTypeList}
                                setModal={setModal}
                                modal={modal}
                            />
                            : null
                        }
                            <Paper >
                                <Grid container spacing={2} style={{ padding: "4px 10px", marginTop: '3px' }}>
                                    <Grid item sm='11' >
                                        {
                                            assemblyLeaseDetails?.assembly_swap?.value == 0 ?
                                            null:
                                            <FilterComponent
                                            filter={filter}
                                            filterMenu={filterOptions}
                                            getResponseBack={(applyFilter) => { getInvoiceListFn({ ...applyFilter, page: 1, per_page: invoiceList.pagination.per_page }, 'pageLoader'); }}
                                            />
                                            }
                                    </Grid>
                                    <Grid item sm='1'>
                                        { checkPermission('assembly_lease','invoice','EXP') ?
                                            <Button disabled={invoiceList?.list?.length ? false : true} style={{ marginBottom: '8px' }} size='small' variant='contained' color='primary' onClick={() => exportInvoiceListFn(filter)}>
                                                Export
                                            </Button>:null
                                        }
                                    </Grid>
                                </Grid>

                                <TableListComp
                                    heads={invoiceListHd}
                                    data={invoiceList?.list?.map((item, index) =>
                                        <InvoiceList
                                            leaseType={assemblyLeaseDetails?.assembly_swap?.value}
                                            invoiceCreatedDetails={item}
                                            // setChangeStatusModal={setChangeStatusModal}
                                            setStatusChangeList={setStatusChangeList}
                                            statusChangeList={statusChangeList}
                                            redirectToDetails={redirectToDetails}
                                            item={item}
                                        />
                                    )}
                                    onChangePage={(event, newPage) => getInvoiceListFn({ ...filter, page: newPage + 1, per_page: invoiceList.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getInvoiceListFn({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                    pagination= {  assemblyLeaseDetails?.assembly_swap?.value == 0 ? null: invoiceList.pagination}
                                    noRecord={invoiceList?.list?.length ? null :
                                        pageLoader ? null :
                                            <EmptyCollection
                                                title="No records found"
                                            />
                                    }

                                />
                            </Paper>
                            {
                                statusChangeList?.modal ?
                                    <Modal isOpen={statusChangeList} toggle={() => setStatusChangeList(false)} className="invoice-status-modal">
                                        <ModalHeader toggle={!statusChangeList} >
                                            <span style={{ display: 'flex' }} >Change Invoice Status
                                                <Clear style={{ marginLeft: '340px', cursor: "pointer" }} onClick={() => setStatusChangeList(false)} />
                                            </span>
                                        </ModalHeader>
                                        <ModalBody>
                                            {
                                                statusChangeList ?
                                                    <ChangeInvoiceStatus
                                                        invoiceInfo={{partial_payments_applicable:statusChangeList?.data?.partial_payments_applicable,total_amount: statusChangeList?.data?.total_amount }}
                                                        status={statusChangeList?.data.status?.value}
                                                        updateInvStatus={(data) => setStatusChangeConfirm({ data: data, invoice: statusChangeList?.data,  modal: true })}
                                                        invoiceType={statusChangeList?.data.invoice_type?.value}
                                                        invoiceStartDate={statusChangeList?.data?.start_date}
                                                        invoiceEndDate={statusChangeList?.data?.end_date}
                                                        totalAmount={statusChangeList?.data?.total_amount}
                                                        diff={statusChangeList?.data?.total_amount - statusChangeList?.data?.partial_paid_amount}
                                                    /> : null
                                            }
                                        </ModalBody>
                                    </Modal> : null
                            }
                             {
                    statusChangeConfirm?.modal ?
                        <DeleteModal isOpen={statusChangeConfirm?.modal} toggle={() => setStatusChangeConfirm({})} title={`Change status`} className="modal-tform-delete-pictures">
                            <div style={{ textAlign: 'center' }}>
                                Are you sure you want to change the invoice status?
                                {/* to { invoiceTypeInfo[statusChange?.data?.status]} ?  */}

                            </div>
                            <ul className="list-inline" style={{ textAlign: 'center' }}>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-danger" onClick={() => { onInvoiceStatusChangeApi(statusChangeConfirm?.data ,statusChangeConfirm?.invoice, 'listview' ); setStatusChangeConfirm({ modal: false }) }}>Yes</button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-secondary" onClick={() => setStatusChangeConfirm({})}>Cancel</button>
                                </li>
                            </ul>
                        </DeleteModal>
                        : null
                }
                        </>
                        :
                        <InvoiceDetails
                        leaseType={assemblyLeaseDetails?.assembly_swap?.value}
                        detailsPageLoader={detailsPageLoader}
                            statusChange={statusChange}
                            setStatusChange={setStatusChange}
                            deleteInvoiceFn={deleteInvoiceFn}
                            setDeleteInvoice={setDeleteInvoice}
                            deleteInvoice={deleteInvoice}
                            setshowStatusTimeLine={setshowStatusTimeLine}
                            showStatusTimeLine={showStatusTimeLine}
                            getInvoiceStatusTimeline={getInvoiceStatusTimeline}
                            updateInvoiceErrorField={updateInvoiceErrorField}
                            invoiceValidationMsgs={invoiceValidationMsgs}
                            setChangeStatusModal={setChangeStatusModal}
                            changeStatusModal={changeStatusModal}
                            onInvoiceStatusChangeApi={onInvoiceStatusChangeApi}
                            onSubmitInvoiceDetails={onSubmitInvoiceDetails}
                            onChangeInvoiceDetails={onChangeInvoiceDetails}
                            exportInvoice={exportInvoice}
                            detailsPage={detailsPage}
                            setDetailsPage={setDetailsPage}
                            invoiceCreatedDetails={invoiceCreatedDetails}
                        />

                }
            </div>
        </div>
    )
}
export default RoleBasedAccess(AssmblyInvoices, ['assembly_lease','invoice','R']);
