import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { imgStoragePath } from '../../../constants';
import { Link } from 'react-router';
import { SignupForm, Prices, Footer,TermsCondition, LoginForm, DataPrivacy,SelectLessor, ForgotPassword, EmailVerification } from '../components';
import { authorizationAc } from '../../../User/actionCreators'
import { browserHistory } from 'react-router'
class Pricing extends Component{
  constructor(props){
    super(props);
    this.state = {}
  }

  render(){
    return(
      <div>
        <Row>
          <div className="onboarding-outer-wrapper pricing-wrapper" style={ this.state.verticalMiddle ? {height : '100vh'} : {}}>
            <div className="container">
              <div className="row flex-centered navbar-row">
                <Col md="4" xs="2">
                  <img className="logo" style={{cursor: 'pointer'}} src={imgStoragePath+'phase-2/sparta_logo.svg'} onClick={() => browserHistory.push('/signup')} alt="logo image"/>
                </Col>
                <Col md="8" xs="10">
                  <p className="text-right sign-in-btn">
                    <a href="/signup#features" className="pricing-button" style={{marginLeft: '5px'}}>Features</a>
                    { this.props.route.path != '/pricing' ?
                      <Link to="https://sparta.aero/signup" target="_blank" className="pricing-button" >Sign Up for free</Link>
                      :null
                    }
                    <Link to="/login" style={{marginRight:'20px', color: '#fff'}} >Sign In</Link> 
                    <Link to="https://sparta.aero/pricing" target="_blank" className="ternary-button" style={{borderRadius: '15px', border: '1.5px solid #fff'}}>Buy Now</Link>
                  </p>
                </Col>
              </div>
              <div className={this.state.verticalMiddle ? "flex-centered row": "row" } style={ this.state.verticalMiddle ? {height : '70vh'} : {}}>
                <Col md="12" className="hero-text-wrapper" style={this.state.verticalMiddle ? {marginTop: '0'} : null}>
                  <h3 style={{fontSize:'36px',fontWeight:'700'}}>
                    { this.props.route.path == '/pricing' ? 'Pricing Plans':this.props.route.path == '/termscondition' ?'Terms & Conditions':'Data Privacy Policy'}
                  </h3>
                </Col>
              </div>
            </div>
          </div>
        </Row>
        { this.props.route.path == '/pricing' ?
          <Prices/> :this.props.route.path == '/termscondition' ?<TermsCondition/>:<DataPrivacy/>
        }
      <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  loginLoader: state.userAssetReducer.loginLoader
});

export default connect(mapStateToProps, null)(Pricing);
