import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus } from '../../../utils_v2';
import { addEditRentalUtilsApi } from '../apiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { monthsObj } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { errorCode, addRentalUtils } from '../';
class AddEditRentalUtils extends Component {
  constructor(props){
    super(props);
    this.state = {
      years:[],
      months:[],
      modal:false,
      formSubmitLoader:false,
      mode:'',
      rentalUtilsCrud: {},
      error:{}
    }
    this.addEditRentalUtilsApi = addEditRentalUtilsApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    const { rentalUtilis } = this.props;
    this.setState(prevState => ({
      ...prevState,
      rentalUtilsCrud: {
        ...prevState.rentalUtilsCrud,
        [keyParam]:data
      }
    }));
    if(keyParam === 'year'){
      if(data){
        let months = rentalUtilis.rental_months.filter(y => y.slice(0,4) == data).map(y => y.slice(4,6)).map(m => {return{label:monthsObj[m], value:m}});
        this.setState(prevState => ({
          ...prevState,
          months:months,
          rentalUtilsCrud: {
            ...prevState.rentalUtilsCrud,
            month:null
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          months:[],
          rentalUtilsCrud: {
            ...prevState.rentalUtilsCrud,
            month:null
          }
        }))
      }
    }
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditRentalUtilsFn = () => {
    const { rentalUtilsCrud } = this.state;
    let validationInputs = {
      year:errorCode['year'][fieldValidation({...errorCode['yearObj'], fieldval: rentalUtilsCrud.year})],
      month:errorCode['month'][fieldValidation({...errorCode['monthObj'], fieldval: rentalUtilsCrud.month})],
      hours:errorCode['hours'][fieldValidation({...errorCode['hoursObj'], fieldval: rentalUtilsCrud.hours})],
      cycles:errorCode['cycles'][fieldValidation({...errorCode['cyclesObj'], fieldval: rentalUtilsCrud.cycles})],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.addEditRentalUtilsApi(this.props, rentalUtilsCrud)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', rentalUtilsCrud:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  openAddEditModal = (mode) => {
    const { rentalUtilis, item } = this.props;
    let years = rentalUtilis.rental_months.map((period) => period.slice(0,4)).filter((x, i, a) => a.indexOf(x) === i).map(y => {return{label:y, value:y}});
    if(mode === 'Add'){
      this.setState({modal:true, rentalUtilsCrud:addRentalUtils, mode:'add', years:years});
    }else{
      let rentalPayout = Object.assign({}, item);
      rentalPayout = {
        ...rentalPayout,
        year:item.month.slice(0,4),
        month:item.month.slice(4,6)
      }
      this.setState({modal:true, rentalUtilsCrud:rentalPayout, mode:'edit', years:years});
    }
  }
  render(){
    const { formSubmitLoader, modal, mode, rentalUtilsCrud, error, years, months } = this.state;
    const { id, m, item } = this.props;
    return(
      <Fragment>
        { id ?
          <EditIcon style={{float:'right', cursor:'pointer'}} fontSize="small" onClick={() => this.openAddEditModal('Edit')} color="primary" />:
          <Button onClick={() => this.openAddEditModal('Add')} variant="contained" color="primary">Add Rental Utilization</Button>
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, mode:'', rentalUtilsCrud:{}, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(mode)} Rental Utilization
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                { mode ===  'edit' ?
                  <LabelValueCard md={6} label='Year' value={rentalUtilsCrud.year ? rentalUtilsCrud.year:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options = {years}
                      getOptionLabel={option => option.label}
                      value={rentalUtilsCrud.year ? years.find(y => y.value == rentalUtilsCrud.year):null}
                      id="year"
                      onChange={(e, data) => {this.onFieldChange(e, 'year', data?data.value:null); }}
                      renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('year', '')} error={error.year ? true:false} helperText={error.year?error.year:''} {...params} label="Select year" placeholder="Select Year" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                }
                { mode ===  'edit' ?
                  <LabelValueCard md={6} label='Month' value={rentalUtilsCrud.month ? monthsObj[rentalUtilsCrud.month]:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options = {months}
                      getOptionLabel={option => option.label}
                      value={rentalUtilsCrud.month ? months.find(m => m.value == rentalUtilsCrud.month):null}
                      id="month"
                      onChange={(e, data) => {this.onFieldChange(e, 'month', data ? data.value:null); }}
                      renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('month', '')} error={error.month ? true:false} helperText={error.month?error.month:''} {...params} label="Select Month" placeholder="Select Month" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Hours' value={rentalUtilsCrud.hours ? rentalUtilsCrud.hours:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="hours"
                      label="Hours"
                      fullWidth
                      margin="normal"
                      value={rentalUtilsCrud.hours ? rentalUtilsCrud.hours:''}
                      error={error.hours ? true:false }
                      InputLabelProps={{shrink: true}}
                      helperText={error.hours ? error.hours: ''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'hours', e.target.value):e.preventDefault()}}
                      onFocus={(e) => this.updateErrorField('hours', '')}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Cycles' value={rentalUtilsCrud.cycles ? rentalUtilsCrud.cycles:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="cycles"
                      label="Cycles"
                      fullWidth
                      margin="normal"
                      value={rentalUtilsCrud.cycles ? rentalUtilsCrud.cycles:''}
                      error={error.cycles ? true:false }
                      helperText={error.cycles ? error.cycles: ''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'cycles', e.target.value):e.preventDefault()}}
                      onFocus={(e) => this.updateErrorField('cycles', '')}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Block Hours' value={rentalUtilsCrud.block_hour ? rentalUtilsCrud.block_hour:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="block_hour"
                      label="Block Hours"
                      fullWidth
                      margin="normal"
                      value={rentalUtilsCrud.block_hour ? rentalUtilsCrud.block_hour:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'block_hour', e.target.value):e.preventDefault()}}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={rentalUtilsCrud.remarks ? rentalUtilsCrud.remarks:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      inputProps={{ maxLength: 255 }}
                      value={rentalUtilsCrud.remarks ? rentalUtilsCrud.remarks:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                    />
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, mode:'', rentalUtilsCrud:{}, error:{}})} color="primary">Cancel</Button>
              { mode !== 'view' ?
                <Button onClick={this.addEditRentalUtilsFn} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
                </Button> :null
              }
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditRentalUtils));
