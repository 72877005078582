export const cashflowListHd=[
    { id: 'mont', label: 'Month', sortOption: false },
    { id: 'name', label: 'Name', sortOption: false },
    { id: 'credit', label: 'Credit', sortOption: false },
    { id: 'debit', label: 'Debit', sortOption: false },
    { id: 'balance', label: 'Balance', sortOption: false },
    { id: 'trasaction_details', label: 'Transaction Details', sortOption: false },
]
export const CashflowFilterOps = {
    startDate: {
        inputType: 'date',
        title: 'Cashflow Start Date',
    },
    endDate: {
        inputType: 'date',
        title: 'Cashflow End Date',
    },
    transaction_type:{
        inputType: 'dropdown',
        placeholder: 'Select Transaction Type',
        options: [],
        labelKey: 'label',
        valueKey: 'value',
        title: 'Transaction Type',
        multiple: false,
        showDropdown: true
    }
}