import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { globalGetService, globalPostService } from '../../../globalServices'
import { useSnackbar } from 'notistack'
import { EmptyCollection, PageLoader } from '../../../shared_elements'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { displayDateFormat, displayMonthYear, displayYearMonth, fieldDateFormat } from '../../../constants'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NumericLabel from 'react-pretty-numbers';
import { checkApiStatus,getCurrencyFormat } from '../../../utils_v2'


const GenerateSummaryDialog = ({ setGenerateSummary,generateSummary, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [summaries, setSummaries] = useState([]);
    const [invoices, setInvoice] = useState([]);    
    const [isSummaryDialogOpen, setSummaryDialogOpen] = useState(false);
    const [lessor, setLessors] = useState({});
    const [lessee, setLessees] = useState({});
    const [bank, setBank] = useState([]);
    const [error, setError] = useState({});


    useEffect(() => {
        getLessors()
        getLessees()
        getBankDetail()
        // trackActivity('Project Management', {
        //   event_type: 'Page Visited',
        //   page_title: 'T008 Form'
        // });
    }, []);
    const invoiceStatus = [{ label: 'Reserve', value: 1 }, { label: 'Rental', value: 2 }]
    const onGenrateSummary = () => {
        let validationInputs = {};
         validationInputs={
            invoice_for_date:summaries.invoice_for_date && !moment(summaries.invoice_for_date).isValid() ? 'Enter valid Invoice Period':''
         }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
        let payload = Object.assign({}, summaries);
        payload = {
            ...payload,
            lessee_ids: summaries?.lessee_ids?.map(item => item.id).join(',') || null,
            lessor_ids: summaries?.lessor_ids?.map(item => item.id).join(',') || null,
            bank_ids: summaries?.bank_ids?.map(item => item.id).join(',') || null,
            invoice_types: summaries?.invoice_types?.value || null,
            invoice_for_date: summaries?.invoice_for_date?.concat('-01') || null,
        }
        setLoading(true)
        globalGetService(`contracts/summary-invoice/preview/`, payload)
            .then(response => {
                setLoading(false)
                if (response) {
                    setInvoice(response.data.data)
                    setGenerateSummary(false);
                    setSummaryDialogOpen(true)                
                }
            })
    }else{
        setError(validationInputs)
    }
}
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }
    const getLessees = () => {
        globalGetService(`console/lessees/`,)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getBankDetail = () => {
        globalGetService(`console/bank-details/?dropdown=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBank(response.data.data)
                }
            })
    }
    const generateSumInvoiceApi = (payload) => {
        let invoice_ids = Array.isArray(invoices) && invoices.map(sum => sum.invoices.flatMap(inv => inv?.id))
        setLoading(true);
        globalPostService(`/contracts/summary-invoice/create/`, { grouped_ids: invoice_ids })
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    getResponseBack()
                } else {
                    // enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
    }
    const onFieldChange = (keyParam, value) => {
        setSummaries(prevState => ({ ...prevState, [keyParam]: value }));
    }
    
    const handleRemoveInvoice = (summaryIndex, invoiceIndex) => {
        const updatedSummaries = [...invoices];
        updatedSummaries[summaryIndex].invoices.splice(invoiceIndex, 1);
        if (updatedSummaries[summaryIndex].invoices.length === 0) {
            updatedSummaries.splice(summaryIndex, 1);
        }
        setInvoice(updatedSummaries);
    }
    
    return (
        <>
            {isLoading  ? <PageLoader /> :generateSummary &&
            <Dialog
                className='lease-management-modal'
                open={true}
            >
                <DialogTitle> Generate Summary Invoice's</DialogTitle>
                <DialogContent dividers='true' style={{ width: '400px' }}>
                    <Grid container spacing={2} >
                        <Grid item md={12}>
                            <Autocomplete
                                options={lessee}
                                multiple  
                                getOptionLabel={option => option?.name}
                                value={summaries.lessee_ids}
                                id="lessee_ids"
                                onChange={(e, value) => onFieldChange('lessee_ids', value)}
                                renderInput={params => <TextField {...params} label="Lessee" placeholder="Select Lessee" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Autocomplete
                                options={lessor}
                                multiple  
                                getOptionLabel={option => option.name}
                                value={summaries.lessor_ids}
                                id="lessor_ids"
                                onChange={(e, value) => onFieldChange('lessor_ids', value)}
                                renderInput={params => <TextField {...params} label="Lessor/Holdco" placeholder="Select Lessor/Holdco" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Autocomplete
                                options={bank}
                                multiple  
                                getOptionLabel={option => option?.name}
                                value={summaries.bank_ids}
                                id="bank_ids"
                                onChange={(e, value) => onFieldChange('bank_ids', value)}
                                renderInput={params => <TextField {...params} label="Bank Name" placeholder="Select Bank" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Autocomplete
                                options={invoiceStatus}
                                getOptionLabel={option => option?.label}
                                value={summaries.invoice_types}
                                id="invoice_types"
                                onChange={(e, value) => onFieldChange('invoice_types', value)}
                                renderInput={params => <TextField {...params} label="Invoice Type" placeholder="Select Transaction Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="invoice_for_date"
                                    label="Invoice Period"
                                    format={displayYearMonth}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(40, 'years')}
                                    value={summaries?.invoice_for_date ? summaries?.invoice_for_date : null}
                                    onChange={(data, value) => {
                                        onFieldChange('invoice_for_date', moment(data).format(displayYearMonth));
                                        setError({ ...error, invoice_for_date: '' })
                                    }}
                                    onFocus={() => setError({ ...error, 'invoice_for_date': '' })}
                                    placeholder='Select/Enter Invoice Period'
                                    inputVariant="outlined"
                                    views={['year', 'month']} 
                                    disableDay
                                    error={error.invoice_for_date ? true : false}
                                    helperText={error.invoice_for_date ? error.invoice_for_date : ''}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant="outlined" color="primary" onClick={() => { setGenerateSummary(false) ; setSummaries({}); setError({})}}>Cancel</Button>
                    <Button size='small' variant='contained' color='primary' disabled={isLoading} onClick={() => { onGenrateSummary();error.invoice_for_date || error.invoice_for_date === "" ? setSummaryDialogOpen(false): setSummaryDialogOpen(true) }}>Proceed</Button>
                </DialogActions>
            </Dialog>}
            {isSummaryDialogOpen ?<>
                {isLoading  ? <PageLoader />:
                <Dialog
                    className='lease-management-modal'
                    open={true}
                    maxWidth={'xl'}
                >
                    <DialogTitle style={{}}>
                            <div
                                edge="start"
                                color="inherit"
                                aria-label="back"
                                style={{ display: 'flex' }}
                            >
                                <ArrowBackIcon onClick={() => { setSummaryDialogOpen(false); setGenerateSummary(true); setSummaries({}); setInvoice({}) }} color='primary' style={{cursor:'pointer',marginRight:'4px'}}  />
                                Grouping of Summary Invoices
                            </div>
                    </DialogTitle>
                    <DialogContent dividers='true'>
                        
                    {!invoices.length? (
                            <EmptyCollection description='No Records Found'/>
                        ):Array.isArray(invoices) && invoices.map((summary, index) => (
                            <>
                                <TableContainer component={Paper} style={{ marginBottom: '10px', padding: '8px' }}>
                                {summary.invoices.length > 0 && (
                                    <Grid container spacing={2} style={{ marginBottom: '10px', width: '100%' }}>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Typography variant="subtitle2" style={{color:"#9439e4"}} >Lessee:</Typography>
                                            <Typography variant="body2">{summary.lessee_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Typography variant="subtitle2" style={{color:"#9439e4"}}>Lessor/Holdco:</Typography>
                                            <Typography variant="body2">{summary.lessor_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Typography variant="subtitle2" style={{color:"#9439e4"}}>Bank Name:</Typography>
                                            <Typography variant="body2">{summary.bank_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="subtitle2" style={{color:"#9439e4"}}>Invoice Type:</Typography>
                                            <Typography variant="body2">{summary.invoice_type?.label}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Typography variant="subtitle2" style={{color:"#9439e4"}}>Invoice Period:</Typography>
                                            <Typography variant="body2">{moment(summary.invoice_for_date).format(displayMonthYear)}</Typography>
                                        </Grid>
                                    </Grid>)}
                                    <Table className='mui-table-format'>
                                        {summary.invoices.map((invoice, invoiceIndex) => (
                                            <>
                                                <TableHead>
                                                    <TableRow key={invoiceIndex}>
                                                        <TableCell>Asset(s)</TableCell>
                                                        <TableCell>Invoice Number</TableCell>
                                                        <TableCell>Amount({summary.currency==='USD'?'US$':'€'})</TableCell>
                                                        <TableCell>Invoice date</TableCell>
                                                        <TableCell>Due Date</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={invoiceIndex}>
                                                        <TableCell>{invoice?.asset?.serial_number}</TableCell>
                                                        <TableCell>{invoice.invoice_number}</TableCell>
                                                        <TableCell><NumericLabel params={getCurrencyFormat({ currency: summary?.currency, shortFormat: false })}>{invoice.sub_total}</NumericLabel></TableCell>
                                                        <TableCell>{moment(invoice.date).format(displayDateFormat)}</TableCell>
                                                        <TableCell>{moment(invoice.due_date).format(displayDateFormat)}</TableCell>
                                                        <TableCell>

                                                            <Tooltip title='Remove'>
                                                                <CancelRoundedIcon fontSize='small' color="secondary" onClick={() => handleRemoveInvoice(index, invoiceIndex)} />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </>
                                        ))}
                                    </Table>
                                </TableContainer>
                            </>))}
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' variant='outlined' color='primary' onClick={() => {setSummaryDialogOpen(false);setSummaries({});setInvoice({})}}>Close</Button>
                        <Button size='small' variant='contained' color='primary' disabled={isLoading||!invoices.length} onClick={()=>{ generateSumInvoiceApi();setSummaryDialogOpen(false);setSummaries({})}} >{isLoading?<CircularProgress color="#ffffff" size={24} />:`Generate summary Invoice`}</Button>
                    </DialogActions>
                </Dialog>}</>: null}
        </>
    )
}
export default GenerateSummaryDialog;
