export const DB_SWITCHER = 'DB_SWITCHER';
export const CLEAR_TOAST = 'CLEAR_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const PAGE_LOADER_SET = 'PAGE_LOADER_SET';
export const S_REGION_LIST = 'S_REGION_LIST';
export const S_ARCT_TYPE_LIST = 'S_ARCT_TYPE_LIST';
export const S_MANUFACTURER_LIST = 'S_MANUFACTURER_LIST';
export const S_ENGINE_TYPE_LIST = 'S_ENGINE_TYPE_LIST';
export const S_LESSOR_LIST = 'S_LESSOR_LIST';
export const S_LESSEE_LIST = 'S_LESSEE_LIST';
export const S_OPERATOR_LIST = 'S_OPERATOR_LIST';
export const S_CLOSE_TOAST_NOTIFY = 'S_CLOSE_TOAST_NOTIFY';
export const S_GLOBAL_CONSTANTS = 'S_GLOBAL_CONSTANTS';
export const S_AUDITLOG_ADD_DEL_VIEW = 'S_AUDITLOG_ADD_DEL_VIEW';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const LOAD_MSN_SWITCHER = 'LOAD_MSN_SWITCHER';
export const LOAD_DATA_BUCKET = 'LOAD_DATA_BUCKET';
export const EMPTY_BUCKET = 'EMPTY_BUCKET';
export const GET_SHARED_VIEW = 'GET_SHARED_VIEW';
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const DOWNLOAD_LOADER='DOWNLOAD_LOADER';
export const PREVIEW_LOADER= 'PREVIEW_LOADER';
export const CLEAR_SHARED_PREVIEW = 'CLEAR_SHARED_PREVIEW';
export const FIXED_POSITIONS_VALUE = 'FIXED_POSITIONS_VALUE';
export const TECH_EXPORT_LOADER='TECH_EXPORT_LOADER';
export const BUG_REPORT='BUG_REPORT';
export const BUG_LOADER='BUG_LOADER';
export const HEADER_GLOBAL_CONSTANTS='HEADER_GLOBAL_CONSTANTS';
export const TECHNICAL_ASSET_RPT = 'TECHNICAL_ASSET_RPT';
export const CONTRACT_EXPORT_LOADER='CONTRACT_EXPORT_LOADER';
export const BUY_LICENCE = 'BUY_LICENCE'
export const PAYMENT_FAILED = 'PAYMENT_FAILED'
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT'
export const NOTIFICATIONS_COUNT_GIF='NOTIFICATIONS_COUNT_GIF';
export const NOTIFICATIONS_COUNT_UPDATE='NOTIFICATIONS_COUNT_UPDATE';
export const FAQ_DETAILS_LIST='FAQ_DETAILS_LIST';
export const NOTIFICATION_FLAG = 'NOTIFICATION_FLAG';
export const FEATURES_LIST = 'FEATURES_LIST';
export const FEATURE_MODAL = "FEATURE_MODAL";
export const OWNERS_LIST = "OWNERS_LIST";
