import React, { Component } from 'react';
import { withRouter, browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { ReturnCndList, ReturnCondCRU, ReturnVsDeliveryPop } from '../components';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../../../applications/contracts/Elements';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { assetsType, imgStoragePath } from '../../../../constants';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getReturnConListApi,deleteReturnConApi } from '../apiServices';
import { getContractClauseAc, sExportContractsAc, contrDbFlaggedCondAc, exportReturnCondnAc, returnCondListAc } from '../../../actionCreators';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { returnCondHd } from '../../';
import { CLEAR_RETURN_COND } from '../../../actions';
import backArrowIcon from '../../../../shared_elements/assets/img/back_arrow.svg';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import {contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class ReturnConditions extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      deleteItem:{modal:false, data:null}
    }
    this.getReturnConListApi = getReturnConListApi.bind(this);
    this.deleteReturnConApi = deleteReturnConApi.bind(this);
  }
  componentDidMount(){
    this.getReturnConListApi(this.props);
    this.props.getContractClause();
    this.props.fetchContractInfo();
    this.props.fetchReturnCondList();
    trackActivity('Page Visited', {
      page_title: 'Return Condition'
    })
  }

  getResponseBack = () => {
    this.getReturnConListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  toggle = () => {
    this.props.closeReturnCondModal();
  }
  render(){
    const { list, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { contractClauseList, params, contractSummaryLoader,returnCondList, contractCondn, returnCondLoader, returnCondCrud, returnCondCompare, assetViewId, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Return Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='return-condition' primaryTab='technical-details' secondaryTab='returnConditions' />
          <ContractWrapper
            title={<span className="return-cond-text hidden-xs" onClick={(evt) => this.props.compareReturnCond(this.props.params.contract_slug,{})}><img width="30" src={imgStoragePath+"compare_conditions.png"} alt="icon" /> Return Vs Delivery</span>}
            revision={true}
            url='audit/contracts/ctreturnconditionitems/'
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            params={params}
            buttonText={ checkPermission('contracts','return_conditions', 'C') ? <ReturnCondCRU contractInfo={contractInfo} contractClauseList={contractClauseList} getResponseBack={this.getResponseBack} />:null}
          >
            { skeletonLoader ?
              (<STableLoader count={4} />):
              (
                <TableListComp
                  heads={returnCondHd}
                  data={list.map((item,index) =>
                    <ReturnCndList
                      contractInfo={contractInfo}
                      contractClauseList={contractClauseList}
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item}
                      deleteReturnCon={() => this.setState({deleteItem:{modal:true, data:item}})} />
                    )
                  }
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>

        <Modal isOpen={returnCondCompare.flag} size="lg" toggle={this.toggle} className="return-condition-modal">
          <ModalHeader toggle={this.toggle}>Return Vs Delivery Conditions
          {
            returnCondList.length ?
            <span onClick={() => this.props.exportReturnCondn(assetViewId)} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px'}}><img style={{marginRight: '4px'}} width="18" src={exportIcon} alt="export" />EXPORT</span>
            : null
          }
          </ModalHeader>
          <ModalBody>
            <ReturnVsDeliveryPop returnCondCompare={returnCondCompare} />
          </ModalBody>
        </Modal>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteReturnConApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractClauseList: state.contractReducer.contractClauseList,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  returnCondCompare: state.ContrDashboardReducer.returnCondCompare,
  returnCondList: state.ContrReturnCondReducer.returnCondList,
  assetViewId: state.userAssetReducer.assetViewId,
  returnCondLoader: state.ContrReturnCondReducer.returnCondLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchReturnCondList: () => dispatch(returnCondListAc(ownProps.params)),
    getContractClause: () => dispatch(getContractClauseAc(ownProps)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    compareReturnCond:(contract_slug, queryParams) => dispatch(contrDbFlaggedCondAc(contract_slug, queryParams)),
    exportReturnCondn: (contract_slug) => dispatch(exportReturnCondnAc(contract_slug)),
    closeReturnCondModal: () => dispatch({
      type: CLEAR_RETURN_COND,
      payload: ''
    }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ReturnConditions,['contracts','return_conditions', 'R'])));
