import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, IconButton, Drawer, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { addEditObligationApi, getObligationApi } from '../apiServices';
import { errorCode, addObligation } from '../';
import { regexConstants } from '../../../../constants/regEx';
import { jetStreamInstance } from '../../../Leases';
class ObligationCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      mode:'',
      formSubmitLoader:false,
      pageLoader: false,
      obligation: {},
      error:{}
    }
    this.addEditObligationApi = addEditObligationApi.bind(this);
    this.getObligationApi = getObligationApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.updateFormField(keyParam, data);
    if(keyParam === 'sub_type' && data && data.value === 2){
      this.updateFormField('value', '');
      this.updateFormField('payment_due_date', null);
    }
  }
  updateFormField = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      obligation: {
        ...prevState.obligation,
        [key]:value
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditObligationFn = () => {
    const {obligation} = this.state;
    let validationInputs = {
      obligation_type:errorCode['obligation_type'][fieldValidation({...errorCode['obligation_typeObj'], fieldval: obligation.obligation_type && obligation.obligation_type.value ? obligation.obligation_type.value.toString():'' })],
      sub_type:errorCode['sub_type'][fieldValidation({...errorCode['sub_typeObj'], fieldval: obligation.sub_type && obligation.sub_type.value ? obligation.sub_type.value.toString():''})],
      value: obligation.sub_type && obligation.sub_type.value !== 2 ? errorCode['value'][fieldValidation({...errorCode['valueObj'], fieldval: obligation.value})]:'',
      details:errorCode['details'][fieldValidation({...errorCode['detailsObj'], fieldval: obligation.details})],
      applicable:errorCode['applicable'][fieldValidation({...errorCode['applicableObj'], fieldval: obligation.applicable})],
      to_be_approved_by:errorCode['to_be_approved_by'][fieldValidation({...errorCode['to_be_approved_byObj'], fieldval: obligation.to_be_approved_by})],
      payment_due_date: obligation.sub_type && obligation.sub_type.value !== 2 ? errorCode['payment_due_date'][fieldValidation({...errorCode['payment_due_dateObj'], fieldval: obligation.payment_due_date})]:'',
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, obligation);
      payload = {
        ...payload,
        obligation_type:obligation.obligation_type.value,
        sub_type:obligation.sub_type.value,
        to_be_approved_by:obligation.to_be_approved_by.id
      }
      if(obligation.created_by && obligation.created_by.id !==""){
        payload = {
          ...payload,
          created_by:obligation.created_by.id,
          status:obligation.status.value,
        }
      }
      this.addEditObligationApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', obligation:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs});
    }
  }
  render(){
    const { modal, mode, formSubmitLoader, obligation, error, pageLoader } = this.state;
    const { id, m, pltConstants, approvedUser, contractInfo, readmore } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null }
        { id && !readmore ?
          <span onClick={() => this.getObligationApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          : readmore ?<span style={{color:"#2a9ccc", cursor:'pointer'}} onClick={() => this.getObligationApi(this.props, id, m)}> ...more</span>:
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Button onClick={() => this.setState({modal:true, leaseOption:addObligation, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Obligation</Button>
          :null}
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', obligation:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Obligation`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctobligations/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','obligations', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Type' value={obligation.obligation_type && obligation.obligation_type.label ? obligation.obligation_type.label:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {pltConstants.filter(item => item.type === 'obligation_type')}
                        getOptionLabel={option => option.label}
                        id="obligation_type"
                        value={obligation.obligation_type ? obligation.obligation_type:null}
                        onChange={(e, value) => {this.onFieldChange(e, 'obligation_type', value);}}
                        renderInput={params => <TextField required error={error.obligation_type ? true:false} helperText={error.obligation_type?error.obligation_type:''} onFocus={(e) => this.updateErrorField('obligation_type')} {...params} label="Type" placeholder="Select Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Sub Type' value={obligation.sub_type && obligation.sub_type.label ? obligation.sub_type.label:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {pltConstants.filter(item => item.type === 'obligation_sub_type')}
                        getOptionLabel={option => option.label}
                        id="sub_type"
                        value={obligation.sub_type ? obligation.sub_type:null}
                        onChange={(e, value) => {this.onFieldChange(e, 'sub_type', value);}}
                        renderInput={params => <TextField required error={error.sub_type ? true:false} helperText={error.sub_type?error.sub_type:''} onFocus={(e) => this.updateErrorField('sub_type')} {...params} label="Sub Type" placeholder="Select Sub Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Commercial Value' value={obligation.value ? obligation.value:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="value"
                        label="Commercial Value"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        value={obligation.value ? obligation.value:''}
                        error={error.value ? true:false }
                        helperText={error.value ? error.value: ''}
                        InputLabelProps={{shrink: true}}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'value', e.target.value):e.preventDefault()}}
                        onFocus={(e) => this.updateErrorField('value')}
                        disabled={obligation.sub_type && obligation.sub_type.value === 2}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Details' value={obligation.details ? obligation.details:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="details"
                        label="Details"
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 500 }}
                        error={error.details ? true:false }
                        helperText={error.details ? error.details: ''}
                        value={obligation.details ? obligation.details:''}
                        onChange={(e, value) => this.onFieldChange(e, 'details', e.target.value)}
                        InputLabelProps={{shrink: true}}
                        onFocus={(e) => this.updateErrorField('details', '')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Applicable To' value={obligation.applicable ? obligation.applicable:'--'} /> :
                    <Grid item xs={12} md={6}>
                      {(() => {
                        let options = [{label: `${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,value: 'Lessor'}, {label: 'Lessee',value: 'Lessee'}, {label: 'Both',value: 'Both'}];
                        return(
                          <Autocomplete
                            options = {options}
                            getOptionLabel={option => option.label}
                            id="applicable"
                            value={obligation.applicable ? options.find((item) => item.value === obligation.applicable):null}
                            onChange={(e, value) => this.onFieldChange(e, 'applicable', value.value ? value.value:null)}
                            renderInput={params => <TextField required error={error.applicable ? true:false } helperText={error.applicable ? error.applicable: ''} onFocus={(e) => this.updateErrorField('applicable')} {...params} label="Applicable To" placeholder="Select Applicable To" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                          />
                        )
                      })()}
                      
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Approver' value={obligation.to_be_approved_by && obligation.to_be_approved_by.name ? obligation.to_be_approved_by.name:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {approvedUser}
                        getOptionLabel={option => option.name}
                        id="to_be_approved_by"
                        value={obligation.to_be_approved_by ? obligation.to_be_approved_by:null}
                        onChange={(e, value) => {this.onFieldChange(e, 'to_be_approved_by', value);}}
                        renderInput={params => <TextField required error={error.to_be_approved_by ? true:false } helperText={error.to_be_approved_by ? error.to_be_approved_by: ''} onFocus={(e) => this.updateErrorField('to_be_approved_by')} {...params} label="Approver" placeholder="Select Approver" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Due Date' value={obligation.payment_due_date ? moment(obligation.payment_due_date).format(displayDateFormat):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           required
                           margin="normal"
                           id="payment_due_date"
                           label="Due Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           inputProps={{readOnly: true}}
                           clearable={true}
                           value={obligation.payment_due_date ? obligation.payment_due_date:null}
                           error={error.payment_due_date ? true:false }
                           helperText={error.payment_due_date ? error.payment_due_date: ''}
                           onChange={(data, value) => {this.onFieldChange(value, 'payment_due_date', moment(data).isValid()? moment(data).format(backendDateFormat):null);this.updateErrorField('payment_due_date')}}
                           onFocus={(e) => this.updateErrorField('payment_due_date')}
                           disabled={obligation.sub_type && obligation.sub_type.value === 2}
                         />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={obligation.remarks ? obligation.remarks:'--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 500 }}
                        value={obligation.remarks ? obligation.remarks:''}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditObligationFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', obligation:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(ObligationCRU));
