import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { imgStoragePath } from '../../../../constants';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
export default class ExportUtilSummery extends Component{
  constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = {
        dropdownOpen: false
      };
    }
    toggle() {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      });
    }
  render(){
    const { contract, util_type,aircraft, ctrShortInfo } = this.props;
    return(
      <ul className="list-inline tab-right flex-centered" style={{display: 'inline'}}>
          <li className="list-inline-item download-block">
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle caret size="sm">
                     <span><img width="12" src={exportIcon}  alt="export"/> Export</span>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem  onClick={() => this.props.exportUtils(contract, 'pdf',util_type,aircraft, ctrShortInfo)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                      <DropdownItem onClick={() => this.props.exportUtils(contract, 'xls', util_type,aircraft,ctrShortInfo)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                      <DropdownItem onClick={() => this.props.exportUtils(contract, 'docx', util_type,aircraft, ctrShortInfo)} ><img src={imgStoragePath+"docs_icon.png"} alt="img"/>Doc</DropdownItem>
                      <DropdownItem onClick={() => this.props.exportUtils(contract, 'csv', util_type,aircraft,ctrShortInfo)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>CSV</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
          </li>
      </ul>
    )
  }
}
