import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import { checkString, checkEmail } from '../../formValidator'
import CKeditor from "../../shared/CKEditor";
import { imgStoragePath } from '../../constants';
import { DropDownField, TextInputField, GetInputField ,ContentDrop} from '../../applications/technicalInspection/Elements';
// import '../assets/styles/sidebar.scss'
export default class ReportBug extends Component{
  constructor(props){
    super(props);
    this.state = {
      dragOver: false,
      bugReport:{},
      error: {},
      type:'edit',
      filesToUpload: [],
    }
  }

  componentDidMount(){
    this.setState({
        bugReport:this.props.data
    });
  }

  updateForm = (key,value) => {
    this.setState(prevState => ({
      ...prevState,
      bugReport:{
        ...prevState.bugReport,
        [key]:value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
  }

  filesUpload = (files) => {

    this.setState({
      dragOver: false,
      filesToUpload: files
    });
    this.setState(prevState => ({
        ...prevState,
        bugReport:{
          ...prevState.bugReport,
          attachments:[files, ...prevState.bugReport.attachments]
        }
      }));
  }

  updateCkEditer = (evt) =>{
    var newContent = evt.editor.getData();
    this.updateForm('description', newContent)
}
sendInviteUser = (e) => {
    e.preventDefault();
    const validateNewInput = {
        title: checkString({value: this.state.bugReport.title, required: true, minLength: '', maxLength: '', message: 'Please enter Title'}),
        module: checkString({value: this.state.bugReport.module, required: true, minLength: '', maxLength: '', message: 'Please select the Module'}),
        severity: checkString({value: this.state.bugReport.severity, required: true, minLength: '', maxLength: '', message: 'Please select the Severity'}),
        classification: checkString({value: this.state.bugReport.classification, required: true, minLength: '', maxLength: '', message: 'Please select the Classification'}),

      };


      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.props.reportTheBug(this.state.bugReport);
      }else{
        this.setState({
          error: validateNewInput
        });
      }

}
onDragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver:true,
    });
  }

  onFocusOut = () =>{
    this.setState({
      dragOver: false,
    })
  }

  onDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      dragOver: false,
    })
  }

  deletepicture=(index)=>{
    this.setState(prevState => ({
      ...prevState,
      bugReport:{
        ...prevState.bugReport,
        attachments:[...prevState.bugReport.attachments].filter((attachments, imageIndex) =>  imageIndex != index)
      }
    }));

  }



  render(){
    const { type,error, bugReport } = this.state;
    const {techConstants } = this.props;
    let customHeight=document.body.contains(document.getElementById('sidebar-header')) ?document.getElementById('sidebar-header').clientHeight : 0;


    return(
      <form onSubmit={(e) => this.sendInviteUser(e)}>
        <div className="sidebar-form "  style={{ height: (window.innerHeight - customHeight-40) + 'px'}}onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()}>
          <div >
          <Row>
            <Col md="12" style={{marginBottom: '20px'}}>
               {
                  GetInputField(
                    'string',
                    <label className="for-label">Title {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                    { type: type,
                      value: bugReport.title,
                      error: this.state.error.title,
                      updateForm: (value) => this.updateForm('title', value)
                    }
                  )
                }
            </Col>
          </Row>
        <Row>
        <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
        <label className="for-label" style={{marginBottom:'5px',fontSize:'13px'}}>Description </label>
           <div style={{position:'relative',zIndex:'99'}}>
              <CKeditor
                events={{"change": this.updateCkEditer}}
                content={bugReport.description}
              />
            </div>
          <div>
          <p style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} >Loading...</p>
          </div>
            </Col>
        </Row>
            <Row>
              <Col md="12" style={{marginBottom: '20px'}}>
                <DropDownField
                    labelKey="label"
                    valueKey="value"
                    options={techConstants.filter(item => item.type=="bug_module" && item.label == 'Lease Management')}
                    value={bugReport.module}
                    updateForm={(value) => this.updateForm('module', value)}
                    required={true}
                    error={this.state.error.module}
                    placeholder="">Module
                 </DropDownField>
              </Col>
            </Row>
            <Row>
              <Col md="12" style={{marginBottom: '20px'}}>
                <DropDownField
                    labelKey="label"
                    valueKey="value"
                    options={techConstants.filter(item => item.type=="bug_severity")}
                    value={bugReport.severity}
                    required={true}
                    error={this.state.error.severity}
                    updateForm={(value) => this.updateForm('severity', value)}
                    placeholder="">Severity
                 </DropDownField>
              </Col>
            </Row>
            <Row>
              <Col md="12" style={{marginBottom: '20px'}}>
                <DropDownField
                    labelKey="label"
                    valueKey="value"
                    options={techConstants.filter(item => item.type=="bug_classification")}
                    value={bugReport.classification}
                    required={true}
                    error={this.state.error.classification}
                    updateForm={(value) => this.updateForm('classification', value)}
                    placeholder="">Classification
                 </DropDownField>
              </Col>
            </Row>
            <Row>
            <div className="sb-body" style={{overflow:'auto'}}>
                <div className="technical-form-images">
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item dropable-zone" onDrop={(evt) => this.filesUpload( evt.dataTransfer.files[0])} style={ this.state.dragOver ? { background: 'rgba(0,0,0,0.7)'} : { background: 'transparent'}} >
                      <ContentDrop screenshot={'screenshot'} mutlipleEnabled={true} acceptExtensions={'image/*'} filesUpload={(evt) => this.filesUpload( evt.target.files[0])} />
                    </li>
                    { bugReport.attachments?
                        bugReport.attachments.map((item, index) =>
                      <li
                        key={index}
                        className={'list-inline-item gap-report-images overlay'}
                        style={{  backgroundImage: "url(" + URL.createObjectURL(item) + ")",overflow:'hidden'}}>
                        <div style={{ backgroundImage: "url(" + imgStoragePath + "lazy_load.png)"}}></div>
                        <div className="storage-action-block">
                          <span className="image-card-action">
                            <span onClick={(evt) => {evt.preventDefault();evt.stopPropagation();this.deletepicture(index)}}>
                              <img src={imgStoragePath+"delete_img.png"} width="15" /> Remove
                            </span>
                        </span>
                      </div>
                      </li>
                    ):null}
                  </ul>
                </div>
              </div>
            </Row>
        </div>
        <div className="edit-sidebar-footer">
              <ul className="list-inline">
                <li className="list-inline-item">
                 {/*<button type="submit"  type="submit" className="btn btn-primary">{this.props.contactLoader ? 'Processing...':'Save Changes'}</button>*/}
                  <button type="submit" disabled={this.props.bugLoader}   className="btn btn-primary">{this.props.bugLoader?'Reporting...':'Report'}</button>
                </li>
                <li className="list-inline-item">
                  <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
                </li>
               </ul>
               </div>
            </div>
      </form>
    )
  }
}
