import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addLeaseOption = {
  lease_option: '',
  details: '',
  start_date: null,
	end_date: null,
	notice_period_start_date: null,
  notice_period_end_date: null,
	remarks: ''
}
export const leaseOptionHd = [
  { label: 'Option Type', sortOption: false },
  { label: 'Details', sortOption: false },
  { label: 'Validity Period', sortOption: false },
  { label: 'Notice Period', sortOption: false },
  { label: 'Action', sortOption: false }
]

export const errorCode = {
  lease_option:{
    0:'',
    1: 'Please select Lease Option'
  },
  lease_optionObj: {
    required: true
  },
  details: {
    0:'',
    1: 'Please enter Lease Details',
  },
  detailsObj: {
    required: true
  }
}
