import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router';
import { Button, Container, Grid, Tooltip, IconButton, TextField, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import UserDropDownMenu from './UserDropDownMenu';
import FAQsList from './FAQsList';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import ApplicationSwitcher from './ApplicationSwitcher';
import { getLocalStorageInfo } from '../../utils';
import { getWhatsNewFeaturesListApi, getFaqsListApi } from '../apiServices';
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      formSubmitLoader:false,
      whtsNewModal:false,
      whtsNewList:[],
      faqsModal:false,
      faqsList:[],
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);
  }
  render(){
    const { whtsNewModal, whtsNewList, faqsModal, faqsList, pageLoader, formSubmitLoader } = this.state;
    return(
      <Fragment>
        <div style={{width:'100%', height:'48px'}}></div>
        <header className="desktop-header">
          <Grid container  spacing={0} alignItems="center" justify="center">
            <Grid item xs={5}>
              <ul className="list-inline app-logo-search flex-centered">
                <li className="list-inline-item app-logo">
                  <Link to="/">
                    <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
                  </Link>
                </li>
                <li className="list-inline-item search-form">
                  <ApplicationSwitcher />
                </li>
              </ul>
            </Grid>
            <Grid item xs={7}>
              <ul className="list-inline header-right-nav flex-centered">
                <li className="list-inline-item">
                  <WhatsNewCompleteList />
                </li>
                <li className="list-inline-item">
                  <ReportAnIssue type="head" />
                </li>
                <li className="list-inline-item">
                    <FAQsList />
                </li>
                <li className="list-inline-item">
                  <Tooltip title="Notifications" arrow>
                    <IconButton size="small" color="primary">
                      <Link to="/notifications">
                        <NotificationsActiveIcon color="primary" fontSize="small" />
                      </Link>
                    </IconButton>
                  </Tooltip>
                </li>
                <li className="list-inline-item">
                  <UserDropDownMenu
                    logout={() => {localStorage.clear(); this.props.history.push('/login')}}
                  />
                </li>
              </ul>
            </Grid>
          </Grid>
        </header>
      </Fragment>
    )
  }
}
export default withRouter(DesktopHeader);
