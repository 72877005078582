import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { imgStoragePath } from '../../constants';
import exportIcon from '../../shared/assets/img/export_icon.svg';
export default class ExportFiles extends Component{
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleUpdate= (type)=> {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
    this.props.exportFile(type);
  }
  render() {
    const { files } = this.props;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} style={this.props.style}>
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
          className="export-dropdown"
        >
          <img className="export-icn" src={exportIcon} alt=""/> {this.props.exportName ? this.props.exportName : 'EXPORT ' }  &#9662;
        </DropdownToggle>
        <DropdownMenu className="export-dropdown-menu">
          { files.map((file,index) =>
            <div className="menu-item flex-centered" key={index} onClick={() => {this.toggleUpdate(file.type)}}><img src={imgStoragePath + file.img} /> {file.label}</div>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
