import { globalPostService, globalGetService, globalPutService,globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { toastFlashMessage } from '../../../utils';
export function getInsuranceListApi(props={}, query={}){
   
  globalGetService(`contracts/${props.params.contract_slug}/insurances/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        insurances: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getInsuranceApi(props={}, id, mode){
   
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/insurances/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ insurance: response.data.data, error:{}, modal:true, mode: mode === 'V' ? 'view':'edit'})
    }
    this.setState({pageLoader:false});
  })
}
export function getremainingIsuranceApi(id){
  let query = {};
  if(id){
    query = {insurance:id}
  }
  this.setState({pageLoader:true});
  globalGetService(`/contracts/${this.props.params.contract_slug}/insurance-list/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ remainingIsurance: response.data.data.insurance_list})
    }
    this.setState({pageLoader:false});
  })
}
export function getApproversApi(){
  this.setState({pageLoader:true});
  globalGetService(`/console/get-user-list/?group_ids=23&filter_type=security_group`)
  .then(response => {
    if(checkApiStatus(response)){
    this.setState({
      approvers: response.data.data.list
    })
  }
    this.setState({pageLoader:false});
  })
}
export function addEditInsuranceApi(props={}, data={}){
   
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/insurances/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/insurances/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}

export function getClaimListApi(props={}, query={}){
   
  globalGetService(`contracts/${props.params.contract_slug}/insurance-claims/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        claims: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getClaimApi(props={}, id, mode){
   
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/insurance-claims/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ claim:response.data.data, error:{}, modal:true, mode: mode === 'V'?'view':'edit'})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditClaimApi(props={}, data={}){
   
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/insurance-claims/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/insurance-claims/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}

export function deleteInsuranceApi(props ,deleteItem) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/insurances/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          insurances: prevState.insurances.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, type:'',data:null}
        }))
        this.setState({pageLoader:false});
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
    })
}

export function deleteClaimInsuranceApi(props ,deleteItem) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/insurance-claims/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          claims: prevState.claims.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, type:'',data:null}
        }))
        this.setState({pageLoader:false});
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
    })
}

