import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row } from 'reactstrap';
import { OverViewUtilization } from '../components';
import GenTabField from './GenTabField';
import { CtrTableHd,FieldDateIndex } from '../../../contracts/Elements/';
import { displayDateFormat, imgStoragePath,backendDateFormat } from '../../../../constants';
import MjrAssyList from './MjrAssyList';
import YetTobeSentList from './YetTobeSentList';
import InShopUnservList from './InShopUnservList';
import InShopServList from './InShopServList';
import { Creatable } from 'react-select';
import OffWingServList from './OffWingServList';
import OffWingUnservList from './OffWingUnservList';
import moment from 'moment';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames';
import { addEngine } from '../createTechSummary';
import MjrAssShopVisitHeader from './MjrAssShopVisitHeader';
import MjrAssShopVisitDetail from './MjrAssShopVisitDetail';
import { NoRecordFound  } from '../../../../shared';
import { trackActivity } from '../../../../utils/mixpanel'
import { checkString, checkValidDate, checkIntNumber, checkNumber } from '../../../../formValidator';
import '../assets/styles/techspec.scss'

import { getLocalStorageInfo } from '../../../../utils';
class EngineTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engineLLP: {},
      secondaryLLp: [],
      error: {},
      warnings: {},
      existingLLps: [],
      activeTab: 1
    }
  }


  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.engineList.llp));
    let secondaryLLp = JSON.parse(JSON.stringify(this.props.engineList.llp_secondary));
    if(this.props.assetType !== 'aircraft'){
      trackActivity('Page Visited', {
        application_module: 'Console',
        asset:  `ESN ${this.props.engineList.esn}`,
        page_title: 'Technical Specification'
      })
    }
    this.setState({
      engineLLP:data,
      secondaryLLp: secondaryLLp
    });

  }
  componentWillReceiveProps(nextProps){
    let data = JSON.parse(JSON.stringify(nextProps.engineList.llp));
    let secondaryLLp = JSON.parse(JSON.stringify(nextProps.engineList.llp_secondary));
    this.setState({
      engineLLP:data,
      secondaryLLp: secondaryLLp
    });
  }

  editLlp = (flag) => {
    this.props.editLlpFn(flag);
    this.setState({
      engineLLP:this.props.engineList.llp,
      secondaryLLp: this.props.engineList.llp_secondary,
      error:{}
    });
  }




  updateForm = (type, val, llpId, dropdown, description) => {
    let value=val;
     if(type=='sheet_date'){
      if(val=='Invalid date'){
        value = null;
      }
     }
    if(this.state.activeTab === 1){
      const indexLLpid = this.state.engineLLP.map(list =>  list.id).indexOf(llpId);
        if(type == 'csn'){
          this.setState(prevState => ({
            ...prevState,
            engineLLP: this.state.engineLLP.map(list => list.id == llpId ? {
              ...list,
              csn: value,
              'cycles_remaining' : value ? parseInt(list.cycles_max_limit) - parseInt(value) : 0
            } : list ),
            error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
              ...list,
              [type]: '',
              'cycles_remaining': ''
            } : list ) : {}
          }))
        }else if(type == 'cycles_max_limit'){
          this.setState(prevState => ({
            ...prevState,
            engineLLP: this.state.engineLLP.map(list => list.id == llpId ? {
              ...list,
              cycles_max_limit: value,
              'cycles_remaining' : value ? value - parseInt(list.csn) : 0
            } : list ),
            error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
              ...list,
              [type]: '',
              'cycles_remaining': ''
            } : list ) : {}
          }))
        }
        // else if(type == 'cycles_remaining'){
          // this.setState(prevState => ({
          //   ...prevState,
          //   engineLLP: this.state.engineLLP.map(list => list.id == llpId ? {
          //     ...list,
          //     cycles_remaining: value,
          //     'csn' : value ? parseInt(list.cycles_max_limit) - parseInt(value) : 0
          //   } : list ),
          //   error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
          //     ...list,
          //     [type]: '',
          //     'csn' : ''
          //   } : list ) : {}
          // }))
        // }
        else{
          this.setState(prevState => ({
          ...prevState,
          engineLLP: this.state.engineLLP.map(list => list.id == llpId ? {
            ...list,
            [type]: value
          } : list ),
          error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
            ...list,
            [type]: ''
          } : list ) : {}
        }))
      }
      if(type === 'part_number' && value){
        if(dropdown.filter(item => item.part_number == val).length > 0){
          if(dropdown.filter(item => item.part_number == val)[0].status === 2){
            this.setState({
              warnings: {...this.state.warnings, [llpId]: 'Note: LLP under approval process.'}
            })
          }else if(this.state.warnings[llpId]){
            let warnings = this.state.warnings
            delete warnings[llpId]
            this.setState({
              warnings: warnings
            })
          }
          let engineLLP = this.state.engineLLP.map(list => {
            if(list.id == llpId){
              list = {...list,
                cycles_remaining: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].cycles_remaining : 0,
                csn: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].csn : 0,
                tsn: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].tsn : 0,
                part_number: value,
                cycles_max_limit: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].cycles_limit : 0,}
            }
            return list
          })
          this.setState({
            engineLLP: engineLLP
          })
        }else{
          let engineLLP = this.state.engineLLP.map(list => {
            if(list.id == llpId){
              list = {...list,
                cycles_remaining: 0,
                cycles_max_limit: 0,
                part_number: value,
                csn: 0,
                tsn: 0,
                edit: true
              }
            }
            return list
          })
          this.setState({
            engineLLP: engineLLP
          })
        }
        let existingLLP = {}
        this.setState({
          existingLLps: this.state.existingLLps.length ? this.state.existingLLps.map(item => item.decription == description ? {...item, part_number: [...item.part_number, value]} : item) : this.props.engineList.llp_partnumber_list.map(item => item.decription == description ? {...item, part_number: [...item.part_number, value]} : item)
        })
        setTimeout(() => {
          if(this.state.existingLLps.filter(item => item.decription != description && item.part_number.includes(value)).length){
            existingLLP = this.state.existingLLps.filter(item => item.decription != description && item.part_number.includes(value))[0]
            this.setState({
              warnings: {...this.state.warnings, [llpId]: 'Part Number is Already Present or has been entered for creation under  ' + existingLLP.decription}
            })
          }
        }, 500)
      }else{
        if(this.state.warnings[llpId]){
          let warnings = this.state.warnings
          delete warnings[llpId]
          this.setState({
            warnings: warnings
          })
        }
      }
    }else{
      const indexLLpid = this.state.secondaryLLp.map(list =>  list.id).indexOf(llpId);
        if(type == 'csn'){
          this.setState(prevState => ({
            ...prevState,
            secondaryLLp: this.state.secondaryLLp.map(list => list.id == llpId ? {
              ...list,
              csn: value,
              'cycles_remaining' : value ? parseInt(list.cycles_max_limit) - parseInt(value) : 0
            } : list ),
            error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
              ...list,
              [type]: '',
              'cycles_remaining': ''
            } : list ) : {}
          }))
        }else if(type == 'cycles_max_limit'){
          this.setState(prevState => ({
            ...prevState,
            secondaryLLp: this.state.secondaryLLp.map(list => list.id == llpId ? {
              ...list,
              cycles_max_limit: value,
              'cycles_remaining' : value ? value - parseInt(list.csn) : 0
            } : list ),
            error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
              ...list,
              [type]: '',
              'cycles_remaining': ''
            } : list ) : {}
          }))
        }
        // else if(type == 'cycles_remaining'){
          // this.setState(prevState => ({
          //   ...prevState,
          //   engineLLP: this.state.engineLLP.map(list => list.id == llpId ? {
          //     ...list,
          //     cycles_remaining: value,
          //     'csn' : value ? parseInt(list.cycles_max_limit) - parseInt(value) : 0
          //   } : list ),
          //   error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
          //     ...list,
          //     [type]: '',
          //     'csn' : ''
          //   } : list ) : {}
          // }))
        // }
        else{
          this.setState(prevState => ({
          ...prevState,
          secondaryLLp: this.state.secondaryLLp.map(list => list.id == llpId ? {
            ...list,
            [type]: value
          } : list ),
          error: Object.keys(this.state.error).length ? this.state.error.map((list, index) => index == indexLLpid ? {
            ...list,
            [type]: ''
          } : list ) : {}
        }))
      }
      if(type === 'part_number' && value){
        if(dropdown.filter(item => item.part_number == val).length > 0){
          if(dropdown.filter(item => item.part_number == val)[0].status === 2){
            this.setState({
              warnings: {...this.state.warnings, [llpId]: 'Note: LLP under approval process.'}
            })
          }else if(this.state.warnings[llpId]){
            let warnings = this.state.warnings
            delete warnings[llpId]
            this.setState({
              warnings: warnings
            })
          }
          let engineLLP = this.state.secondaryLLp.map(list => {
            if(list.id == llpId){
              list = {...list,
                cycles_remaining: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].cycles_remaining : 0,
                csn: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].csn : 0,
                tsn: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].tsn : 0,
                part_number: value,
                cycles_max_limit: dropdown.filter(item => item.part_number == val).length ? dropdown.filter(item => item.part_number == val)[0].cycles_limit : 0,}
            }
            return list
          })
          this.setState({
            secondaryLLp: engineLLP
          })
        }else{
          let engineLLP = this.state.secondaryLLp.map(list => {
            if(list.id == llpId){
              list = {...list,
                cycles_remaining: 0,
                cycles_max_limit: 0,
                part_number: value,
                csn: 0,
                tsn: 0,
                edit: true
              }
            }
            return list
          })
          this.setState({
            secondaryLLp: engineLLP
          })
        }
        let existingLLP = {}
        this.setState({
          existingLLps: this.state.existingLLps.length ? this.state.existingLLps.map(item => item.decription == description ? {...item, part_number: [...item.part_number, value]} : item) : this.props.engineList.llp_partnumber_list.map(item => item.decription == description ? {...item, part_number: [...item.part_number, value]} : item)
        })
        setTimeout(() => {
          if(this.state.existingLLps.filter(item => item.decription != description && item.part_number.includes(value)).length){
            existingLLP = this.state.existingLLps.filter(item => item.decription != description && item.part_number.includes(value))[0]
            this.setState({
              warnings: {...this.state.warnings, [llpId]: 'Part Number is Already Present or has been entered for creation under  ' + existingLLP.decription}
            })
          }
        }, 500)
      }else{
        if(this.state.warnings[llpId]){
          let warnings = this.state.warnings
          delete warnings[llpId]
          this.setState({
            warnings: warnings
          })
        }
      }
    }
  }
  addEditEngineLLP = (e, data) => {
    e.preventDefault();
    if(this.state.activeTab === 1){
      let validateArry = [];
      let validateNewInput = {};
      this.state.engineLLP.map((list, key) => {
        validateArry.push(validateNewInput = {
          cycles_max_limit: checkNumber({value: list.cycles_max_limit, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Cycle Limit'}),
          tsn: checkNumber({value: list.tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid  TSN '}),
          csn: checkNumber({value: list.csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid CSN'}),
          part_number: checkString({value: list.part_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Part Number'}),
          cycles_remaining: checkIntNumber({value: list.csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Cycles Remaining' })
        });
        if(parseInt(list.cycles_max_limit) <= 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_max_limit: 'Please Enter Valid Cycle Limit'} : dataList)
        }else if(parseInt(list.tsn) < 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, tsn: 'Please Enter Valid TSN'} : dataList)
        }else if(parseInt(list.cycles_remaining) < 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_remaining: 'Please Enter Valid Cycles Remaining'} : dataList)
        }else if(parseInt(list.cycles_remaining)   > parseInt(list.cycles_max_limit)) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_remaining: 'Cycle Remaining is Exeeding Cycle Limit '} : dataList)
        }else if(parseInt(list.csn) > parseInt(list.cycles_max_limit)) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, csn: 'CSN is Exeeding Cycle Limit'} : dataList)
        }
      })
      let finaldata = validateArry.map(list => Object.keys(list).every((k) => { return list[k] === '' }));
      if (!validateArry.map(list => Object.keys(list).every((k) => { return list[k] === '' })).includes(false)) {
        this.setState(prevState => ({
          ...prevState,
          error: {}
        }))
          this.props.addEditEngineLLP(this.props.engineList.id, data, this.state.activeTab);
      }else{
        this.setState(prevState => ({
          ...prevState,
          error: validateArry
        }))
      }
    }else{
      let validateArry = [];
      let validateNewInput = {};
      this.state.secondaryLLp.map((list, key) => {
        validateArry.push(validateNewInput = {
          cycles_max_limit: checkNumber({value: list.cycles_max_limit, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Cycle Limit'}),
          tsn: checkNumber({value: list.tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid  TSN '}),
          csn: checkNumber({value: list.csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid CSN'}),
          part_number: checkString({value: list.part_number, required: true, minLength: '', maxLength: '', message: 'Please Enter Part Number'}),
          cycles_remaining: checkIntNumber({value: list.csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Cycles Remaining' })
        });
        if(parseInt(list.cycles_max_limit) <= 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_max_limit: 'Please Enter Valid Cycle Limit'} : dataList)
        }else if(parseInt(list.tsn) < 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, tsn: 'Please Enter Valid TSN'} : dataList)
        }else if(parseInt(list.cycles_remaining) < 0) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_remaining: 'Please Enter Valid Cycles Remaining'} : dataList)
        }else if(parseInt(list.cycles_remaining)   > parseInt(list.cycles_max_limit)) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, cycles_remaining: 'Cycle Remaining is Exeeding Cycle Limit '} : dataList)
        }else if(parseInt(list.csn) > parseInt(list.cycles_max_limit)) {
          validateArry = validateArry.map((dataList, index) => index == key ? { ...dataList, csn: 'CSN is Exeeding Cycle Limit'} : dataList)
        }
      })
      let finaldata = validateArry.map(list => Object.keys(list).every((k) => { return list[k] === '' }));
      if (!validateArry.map(list => Object.keys(list).every((k) => { return list[k] === '' })).includes(false)) {
        this.setState(prevState => ({
          ...prevState,
          error: {}
        }))
          this.props.addEditEngineLLP(this.props.engineList.id, data, this.state.activeTab);

      }else{
        this.setState(prevState => ({
          ...prevState,
          error: validateArry
        }))
      }
    }
  }

  render() {
    const {index, engineList, asset_slug, techSpecsLoader, techConstants, llpToggleState, assetType, standAlone } = this.props;
    const { engineLLP, error, warnings, secondaryLLp } = this.state;
    const moduleColor = {
      fan: '#4a99fe',
      hpc: '#97989c',
      hpt: '#5dad5e',
      lpt: '#fc9825'
    }
    return (
      <div>
        <div className="horizontal-card card-block" style={{marginBottom:'20px'}}>
					{getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1) ?
						<img onClick={() => this.props.viewEngine('edit', engineList, 'engineCrud',addEngine, this.props.assetType)} src={imgStoragePath+"icon_editblock.png"} alt="img" className="blue-img" width="16" />:null
					}
					{getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('D') != -1) && engineList.is_fitted && this.props.showTabs ?
          	<img onClick={() => this.props.deleteMsnEngine()} style={{color: 'red', marginRight: '10px'}} src={imgStoragePath+"delete_red.png"} alt="img" className="blue-img" width="16" />
						:null
					}
          <Row>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={ engineList.manufacturer && engineList.manufacturer.name ? engineList.manufacturer.name  : '--' }/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Engine Type" text={engineList.engine_type  && engineList.engine_type.name? engineList.engine_type.name :  '--'}/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={engineList.esn}/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Position" text={ engineList.position && engineList.position.label ? engineList.position.label  : '--' }/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Manufacture" text={ engineList.manufacturing_date ?  moment(engineList.manufacturing_date).format(displayDateFormat) : '--'}/>
            {
              this.props.standAlone ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Purchase" text={engineList.purchase_date ?  moment(engineList.purchase_date).format(displayDateFormat) : '--'}/> : null
            }
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Region of Operation" text={engineList.region ? engineList.region.name : '--'}/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Purchase Thrust (Lbs)" text={engineList.purchase_thrust}/>
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Operating Thrust (Lbs)" text={engineList.operating_thrust}/>
            {
              getLocalStorageInfo().defaultLessor.id !== 348 ?
                <GenTabField grid="3" classText = "horizontal-inner-block" label="Lease Status"   text={getLocalStorageInfo().defaultLessor.id === 348 ? engineList.status.value === 1 ? 'Operational' : 'AOG' : engineList.status && engineList.status.label ? engineList.status.label :  '--'}/>
              :null
            }
            {
              this.props.standAlone && (engineList.status == 2 || engineList.status && engineList.status.value == 2) ?
              <GenTabField grid="3" classText = "horizontal-inner-block" label="Off Lease Status"   text={engineList.off_lease_status && engineList.off_lease_status.label ? engineList.off_lease_status.label :  '--'}/>:null
            }
            {
              this.props.standAlone && engineList.status && parseInt(engineList.status.value) !== 4 ?
                <GenTabField grid="3" classText = "horizontal-inner-block" label="Lessor Name"   text={engineList.lessor_name && engineList.lessor_name.name  ? engineList.lessor_name.name :  getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--' }/>
              :null
            }
            {
              this.props.standAlone && engineList.status && parseInt(engineList.status.value) !== 4 ?
                <GenTabField grid="3" classText = "horizontal-inner-block" label="Owner"   text={engineList.owner && engineList.owner.name  ? engineList.owner.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
              :null
            }
          </Row>
          <Row className="seperator-line">
            { assetType == 'aircraft' ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Engine titled for this MSN ?" text={engineList.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
              : null
            }

            { assetType == 'aircraft' ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Engine fitted on this MSN ?" text={engineList.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
              : null
            }

           { assetType == 'aircraft' ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Engine On Wing ?"   text={engineList.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
              : null
            }
            {engineList.on_wing_msn && engineList.on_wing_status  ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="On Wing MSN"   text={engineList.on_wing_msn}/>
              :null
            }
            { assetType == 'aircraft' && engineList.on_wing_lessor && engineList.on_wing_status   ?
              <GenTabField grid="3" classText = "horizontal-inner-block " label="On Wing Engine Owner"   text={engineList.on_wing_lessor}/>
              : null
            }
            <GenTabField grid="3" classText = "horizontal-inner-block " label="Match Engine's TSN & CSN to LLP"   text={engineList.match_csn_to_llp ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
          </Row>
          {engineList.off_wing_status && engineList.off_wing_status.value ?
         		<MjrAssyList dataList={engineList} />
            : null
          }
          { engineList.off_wing_status && engineList.off_wing_status.value == 1 ?
          	<YetTobeSentList dataList={engineList} /> : null
          }
          { engineList.off_wing_status && engineList.off_wing_status.value == 2 ?
          	<InShopServList techConstants={techConstants} dataList={engineList} /> : null
          }
          {engineList.off_wing_status && engineList.off_wing_status.value == 3 ?
          	<InShopUnservList techConstants={techConstants} dataList={engineList} /> : null
          }
          {engineList.off_wing_status && engineList.off_wing_status.value == 4 ?
          	<OffWingServList techConstants={techConstants} dataList={engineList} /> : null
          }
          {engineList.off_wing_status && engineList.off_wing_status.value == 5 ?
          	<OffWingUnservList techConstants={techConstants} dataList={engineList} /> : null
          }
        </div>
        <OverViewUtilization index={index} utilization={engineList} toggleEditSideBar={() => this.toggleSideBar('landingGearEdit')} importAllowed={false} assembly="engine" slug={this.props.params.aircraft_slug} refresh={() => this.componentDidMount()} />
        <Nav tabs>
         <NavItem>
           <NavLink
             className={classnames({ active: this.state.activeTab === (1) })}
             onClick={() => this.setState({activeTab: 1, error: {}, warnings: {}}, () => this.editLlp(false))}
           >
             Primary LLP
           </NavLink>
         </NavItem>
         {
           engineList.asset_type === 2 ?
           <NavItem>
             <NavLink
               className={classnames({ active: this.state.activeTab === (2) })}
               onClick={() => this.setState({activeTab: 2, error: {}, warnings: {}}, () => this.editLlp(false))}
             >
               Secondary LLP
             </NavLink>
           </NavItem>
           :null
         }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId={1}>
        <div className="contr-table-blk">
          <h5 className="llp-list">Primary LLPs
            <ul className="list-inline export-import-list">

                {engineList.esn.includes('Module') ? null :
                  <li className="list-inline-item">
                    <Link className="import-link" style={{color: "#194886",cursor: "pointer"}}>
                      <img class="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/import_icon.png" />
                      Import
                      <input type="file"  onChange={(e) => this.props.importLLPs(e.target.files, engineList.slug, this.state.activeTab) } className="custom-file-input" style={{cursor:"pointer"}}  />
                    </Link>
                    &nbsp;|
                  </li>
                }

               <li className="list-inline-item">
                 <Link onClick={() => engineList.esn.includes('Module') ? this.props.exportModuleLLps(engineList) : this.props.exportLLPs(engineList, this.state.activeTab)} style={{width:"50px"}}>
               <img class="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/export_icon.png" />Export</Link> |
               </li>
               <li className="list-inline-item">
                     <Link className="edit-table-block" onClick = {() => this.editLlp(!llpToggleState)}>
                        { getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1) && Object.keys(engineLLP).length ?
                            <img width="13" src={llpToggleState ? imgStoragePath+ "view_icon_blue.png" : imgStoragePath+ "icon_editblock.png"} alt="img" style={{position:"relative",top:"3px"}}  />
                          : null
                        }
                       </Link>
               </li>
            </ul>

           </h5>
          <div className="table-container">
          <form style={{overflow: 'auto'}} onSubmit={(e) => this.addEditEngineLLP(e, engineLLP)}>
          <table className="common-table">
              <CtrTableHd theads={['Module', 'Description', 'Part Number','Serial Number','TSN','CSN', 'Cycle Limit', 'Remaining Cycles','LLPs as of date']}/>
              <tbody>
                { Object.keys(engineLLP).length ?
                this.state.engineLLP.map((llp, childIndex) =>
                  <tr key={childIndex} className={llp.cycles_remaining == 0 && !llpToggleState ? 'cycle-remaining-warning' : ''}>
                    <td style={{padding: '6px 15px', color: moduleColor[llp.module.toLowerCase()]}}> { llp.module  ? llp.module : '--' } </td>
                    <td style={{padding: '6px 15px'}}>  { llp.description  ? llp.description : '--'}</td>
                    <td style={{padding: '6px 15px', width: '200px'}}>
                    {llpToggleState ?
                      <div style={{width: '200px'}}>
                      <Creatable
                        value={llp.part_number ? llp.part_number : ''}
                        isClearable={true}
                        onChange={(e) => this.updateForm('part_number', e && e.part_number ? e.part_number : '' , llp.id, llp.part_numbers_dropdown, llp.description)}
                        className="custom-select-block"
                        placeholder="Select or Type Part Number"
                        valueKey="part_number"
                        labelKey="part_number"
                        options= {llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length ? llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length ? llp.part_numbers_dropdown : [...llp.part_numbers_dropdown, {part_number: llp.part_number}] : []}
                        multi={false}
                        noResultsText="No Part Number Found"
                     />
                      </div>
                       : llp.part_number  ? llp.part_number : '--' }
                       { llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number)[0].status === 2 ? <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px'}}>{warnings[llp.id] && warnings[llp.id] != '' ? warnings[llp.id] : 'Note: LLP under approval process.'}</span> : warnings[llp.id] && warnings[llp.id] != '' ? <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px'}}>{warnings[llp.id]}</span> : ''}
                       <h6 className="error-msg">{error[childIndex] ? error[childIndex].part_number : null}</h6>
                       {
                         ( llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && item.status == 3).length) ?
                           <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px', fontColor: 'red'}}>LLP Part number is rejected.</span>
                         :null
                       }
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.serial_number} onChange = {(e) => this.updateForm('serial_number', e.target.value, llp.id)} /> : llp.serial_number }
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.tsn} onChange = {(e) => this.updateForm('tsn', e.target.value, llp.id)} /> : llp.tsn }

                          <h6 className="error-msg">{error[childIndex] ? error[childIndex].tsn : null}</h6>

                     </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.csn} onChange = {(e) => this.updateForm('csn', e.target.value, llp.id)} /> : llp.csn }

                          <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].csn : null}</h6>

                    </td>
                    <td style={{padding: '6px 15px'}}>
                         {llpToggleState && (llp.part_numbers_dropdown && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && item.status == 2).length || (llp.part_numbers_dropdown && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length == 0))? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.cycles_max_limit} onChange = {(e) => this.updateForm('cycles_max_limit', e.target.value, llp.id)} /> : llp.cycles_max_limit }
                          <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].cycles_max_limit : null}</h6>
                    </td>
                    <td style={{padding: '6px 15px'}}>
                       {llpToggleState && ((warnings[llp.id] != undefined && warnings[llp.id] != '') ||(llp.part_numbers_dropdown && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && [2].includes(item.status)).length  ) || ( llp.part_numbers_dropdown && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length == 0)) ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.cycles_remaining} onChange = {(e) => this.updateForm('cycles_remaining', e.target.value, llp.id)} /> : llp.cycles_remaining }
                       <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].cycles_remaining : null}</h6>
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ?
                      <FieldDateIndex
                       value={llp.sheet_date}
                       type={'edit'}
                       keyParam="sheet_date"
                       updateField={this.updateForm}
                       popperPlacement={'left'}
                       index={llp.id}
                       maxDate={moment()}
                       color={'#ff0000'}
                       />:llp.sheet_date ? moment(llp.sheet_date).format(displayDateFormat):'--'}
                       <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].sheet_date : null}</h6>

                    </td>
                  </tr>
                  ) : null
                }
              </tbody>
            </table>
            {!techSpecsLoader && !Object.keys(engineLLP).length  ?
              <NoRecordFound description="" /> :  null
            }
            {
              llpToggleState ?
              <div className="tableSubmit-block">
              <input className="primary-btn" type="submit" value="Save Changes"/>
            <Link onClick = {() => this.editLlp(!llpToggleState)} >Cancel</Link>

            </div>: null

            }
          </form>
          </div>
        </div>
        </TabPane>
        <TabPane tabId={2}>
        <div className="contr-table-blk">
          <h5 className="llp-list">Secondary LLPs
            <ul className="list-inline export-import-list">
              <li className="list-inline-item">

                  <Link className="import-link" style={{color: "#194886",cursor: "pointer"}}>
                    <img class="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/import_icon.png" />
                    Import
                    <input type="file"  onChange={(e) => this.props.importLLPs(e.target.files, engineList.slug, this.state.activeTab) } className="custom-file-input" style={{cursor:"pointer"}}  />
                  </Link>
                &nbsp;|
               </li>
               <li className="list-inline-item">
                 <Link onClick={() => this.props.exportLLPs(engineList, this.state.activeTab)} style={{width:"50px"}}>
               <img class="export-icn" src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/export_icon.png" />Export</Link> |
               </li>
               <li className="list-inline-item">
                     <Link className="edit-table-block" onClick = {() => this.editLlp(!llpToggleState)}>
                        { getLocalStorageInfo().user.permission['technical_specs'] && (getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1) && Object.keys(engineLLP).length ?
                            <img width="13" src={llpToggleState ? imgStoragePath+ "view_icon_blue.png" : imgStoragePath+ "icon_editblock.png"} alt="img" style={{position:"relative",top:"3px"}}  />
                          : null
                        }
                       </Link>
               </li>
            </ul>

           </h5>
          <div className="table-container">
          <form style={{overflow: 'auto'}} onSubmit={(e) => this.addEditEngineLLP(e, engineLLP)}>
          <table className="common-table">
              <CtrTableHd theads={['Module', 'Description', 'Part Number','Serial Number','TSN','CSN', 'Cycle Limit', 'Remaining Cycles','LLPs as of date']}/>
              <tbody>
                { Object.keys(secondaryLLp).length ?
                this.state.secondaryLLp.map((llp, childIndex) =>
                  <tr key={childIndex} className={llp.cycles_remaining == 0 && !llpToggleState ? 'cycle-remaining-warning' : ''}>
                    <td style={{padding: '6px 15px', color: moduleColor[llp.module.toLowerCase()]}}> { llp.module  ? llp.module : '--' } </td>
                    <td style={{padding: '6px 15px'}}>  { llp.description  ? llp.description : '--'}</td>
                    <td style={{padding: '6px 15px', width: '200px'}}>
                    {llpToggleState ?
                      <div style={{width: '200px'}}>
                      <Creatable
                        value={llp.part_number ? llp.part_number : ''}
                        isClearable={true}
                        onChange={(e) => this.updateForm('part_number', e && e.part_number ? e.part_number : '' , llp.id, llp.part_numbers_dropdown, llp.description)}
                        className="custom-select-block"
                        placeholder="Select or Type Part Number"
                        valueKey="part_number"
                        labelKey="part_number"
                        options= {llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length ? llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length ? llp.part_numbers_dropdown : [...llp.part_numbers_dropdown, {part_number: llp.part_number}] : []}
                        multi={false}
                        noResultsText="No Part Number Found"
                     />
                      </div>
                       : llp.part_number  ? llp.part_number : '--' }
                       { llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number)[0].status === 2 ? <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px'}}>{warnings[llp.id] && warnings[llp.id] != '' ? warnings[llp.id] : 'Note: LLP under approval process.'}</span> : warnings[llp.id] && warnings[llp.id] != '' ? <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px'}}>{warnings[llp.id]}</span> : ''}
                       <h6 className="error-msg">{error[childIndex] ? error[childIndex].part_number : null}</h6>
                       {
                         ( llp.part_numbers_dropdown != undefined && llp.part_numbers_dropdown.length && llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && item.status == 3).length) ?
                           <span className="warning-info-text" style={{marginTop: '10px', fontSize: '12px', fontColor: 'red'}}>LLP Part number is rejected.</span>
                         :null
                       }
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.serial_number} onChange = {(e) => this.updateForm('serial_number', e.target.value, llp.id)} /> : llp.serial_number }
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.tsn} onChange = {(e) => this.updateForm('tsn', e.target.value, llp.id)} /> : llp.tsn }

                          <h6 className="error-msg">{error[childIndex] ? error[childIndex].tsn : null}</h6>

                     </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.csn} onChange = {(e) => this.updateForm('csn', e.target.value, llp.id)} /> : llp.csn }

                          <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].csn : null}</h6>

                    </td>
                    <td style={{padding: '6px 15px'}}>
                         {llpToggleState && (llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && item.status == 2).length || (llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length == 0))? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.cycles_max_limit} onChange = {(e) => this.updateForm('cycles_max_limit', e.target.value, llp.id)} /> : llp.cycles_max_limit }
                          <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].cycles_max_limit : null}</h6>
                    </td>
                    <td style={{padding: '6px 15px'}}>
                       {llpToggleState && ((warnings[llp.id] != undefined && warnings[llp.id] != '') || llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number && [2].includes(item.status)).length || llp.part_numbers_dropdown.filter(item => item.part_number == llp.part_number).length == 0) ? <input style={{borderBottom: '1px solid'}} type = "text" value={llp.cycles_remaining} onChange = {(e) => this.updateForm('cycles_remaining', e.target.value, llp.id)} /> : llp.cycles_remaining }
                       <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].cycles_remaining : null}</h6>
                    </td>
                    <td style={{padding: '6px 15px'}}>
                      {llpToggleState ?
                      <FieldDateIndex
                       value={llp.sheet_date}
                       type={'edit'}
                       keyParam="sheet_date"
                       updateField={this.updateForm}
                       popperPlacement={'left'}
                       index={llp.id}
                       maxDate={moment()}
                       color={'#ff0000'}
                       />:llp.sheet_date ? moment(llp.sheet_date).format(displayDateFormat):'--'}
                       <h6 className="error-msg">{ error[childIndex] ?  error[childIndex].sheet_date : null}</h6>

                    </td>
                  </tr>
                  ) : null
                }
              </tbody>
            </table>
            {!techSpecsLoader && !Object.keys(secondaryLLp).length  ?
              <NoRecordFound description="" /> :  null
            }
            {
              llpToggleState ?
              <div className="tableSubmit-block">
              <input className="primary-btn" type="submit" value="Save Changes"/>
            <Link onClick = {() => this.editLlp(!llpToggleState)} >Cancel</Link>

            </div>: null

            }
          </form>
          </div>
        </div>
        </TabPane>
        </TabContent>
        {
          engineList.asset_type === 2 ?
          <div className="contr-table-blk">
            <h5 style={{ borderBottom: '0'}}>Shop Visits History</h5>
            <div className="mobile-table-format">
              <MjrAssShopVisitHeader theads = {[{ label: 'For Shop Visit / Shop Visit Date', size: 2 }, { label: 'Shop Visit Cost', size: 2}, { label: 'Shop Visit Facility/ Location', size: 2}, { label: 'TSN at Shop Visit', size: 2}, { label: 'CSN at Shop Visit', size: 2},  {label: 'Status', size: 2}]}/>
              {Object.keys(engineList.shopvisit).length ?
                engineList.shopvisit.map((item,index) =>
                  <MjrAssShopVisitDetail shopvisit = {item} key={index}/>
                ) : null
             }
            </div>
            {!techSpecsLoader && !Object.keys(engineList.shopvisit).length ? <NoRecordFound description="" /> :  null}
          </div>
          :null
        }
      </div>
    )
  }
}
export default withRouter(EngineTab);
