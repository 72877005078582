import React from 'react';
import { withRouter } from 'react-router';
import { Grid,Paper,Button } from '@material-ui/core';
import { TableListComp, LabelValueCard, EmptyCollection } from '../../../shared_elements';
import InvoiceList from './InvoiceList';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
import { invoiceDbHd } from '../'
const InvoiceSummary = ({invoiceSummary}) => {
  return(
    <Paper className="invoice-summary">
      <div className="widget-amount">
        <h2>Invoice Summary</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div>
              <p>Total Invoiced</p>
              { invoiceSummary.total_amount_new.map((item) =>
                <h4>
                  <NumericLabel params={getCurrencyFormat({currency:item.currency,shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                </h4>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <p>Total Pending</p>
              { invoiceSummary.open_amount_new.map((item) =>
                <h4>
                  <NumericLabel params={getCurrencyFormat({currency:item.currency,shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                </h4>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <p>Total Overdue</p>
              { invoiceSummary.due_amount_new.map((item) =>
                <h4>
                  <NumericLabel params={getCurrencyFormat({currency:item.currency,shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                </h4>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <h3 className="flex-centered">Last 3 Months Invoices <Button size="small" color="primary">View All</Button></h3>
      <div className="invoice-list">
        <TableListComp
          heads={invoiceDbHd}
          data={invoiceSummary.list.map((item,index) =>
            <InvoiceList
              index={index}
              item={item} />
            )
          }
          noRecord={ invoiceSummary.list.length ? null:
            <EmptyCollection
              title="No records found"
            />
          }
        />
      </div>
    </Paper>
  )
}
export default InvoiceSummary;
