import React, { useState, useEffect, Fragment} from "react";

export default function ApplicationSwitcher(){
    
    return(
        <Fragment>

        </Fragment>

    )
}
