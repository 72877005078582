import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { monthsObj, utilizationFormat, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { Button as MuiButton, Chip, Tooltip } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { addRentalUtilsAttachmentApi, deleteRentalAttachmentApi } from '../apiServices'
import { ListLoader, NoRecordFound } from '../../../../shared';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { toastFlashMessage } from '../../../../utils';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default class RentalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAttachment: 'false',
      attatchedDoc: [],
      pageLoader: false,
      showFullList: false,
      deleteAttachmentmodal: false,
      previewDocs: '',
    }
    this.addRentalUtilsAttachmentApi = addRentalUtilsAttachmentApi.bind(this)
    this.deleteRentalAttachmentApi = deleteRentalAttachmentApi.bind(this)
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      [keyParam]: value
    }))
  }
  previewDoc(item) {
    this.setState({ previewDocs: item })
  }
  handleDelete = (item) => {
    this.setState({ deleteAttachmentmodal: !this.state.deleteAttachmentmodal, deleteItem: item })
  }
  deleteAttachment = (item) => {
    const { props } = this.props
    let contractSlug = props.params.contract_slug
    this.setState({ pageLoader: true, deleteAttachmentmodal: false })
    this.deleteRentalAttachmentApi(this.state.deleteItem)
      .then(response => {
        this.setState({ pageLoader: false })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          this.props.getRentalUtilsAttachmentApi(contractSlug)
        }
        else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
  }
  addAttachment(e) {
    const { props, rental } = this.props
    const { attatchedDoc } = this.state
    let contractSlug = props.params.contract_slug
    let formdata = new FormData()
    attatchedDoc.map(item => {
      formdata.append('files', item)
    })
    formdata.append('asset_type', props.assetViewId.asset_type)
    formdata.append('asset_slug', props.params.aircraft_slug)
    formdata.append('contract_slug', props.params.contract_slug)
    formdata.append('model_type', '2')
    formdata.append('object_id', rental.id)
    this.setState({pageLoader:true})
    this.addRentalUtilsAttachmentApi(formdata, contractSlug)
      .then(response => {
        this.setState({ pageLoader: false, addAttachment: false })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          this.props.getRentalUtilsAttachmentApi(contractSlug)
        }
        else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
  }
  render() {
    const { index, rental, attachedRentalDocList, contractInfo } = this.props;
    const { addAttachment, attatchedDoc, previewDocs } = this.state;
    return (
      <div className="asset-list">
        {this.state.pageLoader ? <ListLoader /> : null}
        <Row key={index} className="flex-centered cashflow-row" style={{ alignItems: 'inherit' }}>
          <Col className="cash-blk cash-info para-ui border-col">
            {monthsObj[rental.month.slice(4, 6)]} {rental.month.slice(0, 4)}
            { checkPermission('contracts','utilization','U') && contractInfo?.hdlineInfo?.archived_asset ==false ?
                <span className="hidden-xs" style={{ cursor:attachedRentalDocList.filter(i => i.object_id == rental.id).length >=5?'': 'pointer'}} onClick={() => attachedRentalDocList.filter(i => i.object_id == rental.id).length >=5 ?null :this.setState({ addAttachment: true })}>
                  <Tooltip title={attachedRentalDocList.filter(i => i.object_id == rental.id).length >=5?"": "Add Attachment"}arrow placement='top' >
                    <AttachFileIcon variant='contained' color={attachedRentalDocList.filter(i => i.object_id == rental.id).length < 6 ?'primary': ''} style={{ width: '22px', float: 'right',  color:attachedRentalDocList.filter(i => i.object_id == rental.id).length >=5 ?'#d6d6d6':'' }} />
                  </Tooltip>
                </span> : null
            }

            {
              attachedRentalDocList ?
                attachedRentalDocList.filter(i => i.object_id == rental.id).length > 3 && !this.state.showFullList ?
                  <>{
                    attachedRentalDocList.filter(i => i.object_id == rental.id).slice(0, 2).map(i =>
                      i.name.length > 17 ?
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                          <Chip
                            variant="outlined"
                            size="small"
                            label={i.name.substring(0, 14) + '...'}
                            clickable
                            onDelete={ checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                            color="primary"
                            onClick={() => this.previewDoc(i)}

                          />
                        </Tooltip>
                        :
                        <Chip
                          variant="outlined"
                          size="small"
                          onClick={() => this.previewDoc(i)}
                          label={i.name}
                          onDelete={ checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                          clickable
                          color="primary"
                        />
                    )
                  }
                    <Chip
                      style={{ marginTop: '4px' }}
                      size="small"
                      label={'Show all'}
                      clickable
                      color="primary"
                      deleteIcon={<ExpandMoreIcon />}
                      onDelete={() => { this.setState({ showFullList: true }) }}
                      onClick={(e) => this.setState({ showFullList: true })}
                    >
                    </Chip>
                  </>
                  :
                  <>
                 { attachedRentalDocList.filter(i => i.object_id == rental.id).map(i =>
                    i.name.length > 17 ?
                      <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                        <Chip
                          style={{ clear: 'both' }}
                          variant="outlined"
                          size="small"
                          onDelete={ checkPermission('contracts', 'utilization', 'U') ?  () => this.handleDelete(i):false}
                          label={i.name.substring(0, 14) + '...'}
                          clickable
                          color="primary"
                          onClick={() => this.previewDoc(i)}
                        />
                      </Tooltip>
                      :
                      <Chip
                        variant="outlined"
                        size="small"
                        label={i.name}
                        clickable
                        onDelete={ checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                        onClick={() => this.previewDoc(i)}
                        color="primary"
                      />
                  ) }
                  {
                      this.state.showFullList && attachedRentalDocList.filter(i => i.object_id == rental.id).length > 3 ?
                        <Chip
                          style={{ marginTop: '4px' }}
                          size="small"
                          label={'Show Less'}
                          clickable
                          color="primary"
                          deleteIcon={<KeyboardArrowUpIcon />}
                          onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                        >
                        </Chip>
                        : null
                    }
                  </>
                  
                  : null
            }
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.hours}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.cycles}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.block_hour}
            </NumericLabel>

          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {rental.remarks}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col" sm={1}>
            { checkPermission('contracts', 'utilization', 'U') && contractInfo?.hdlineInfo?.archived_asset ==false ?
                <span className="hidden-xs" style={{ cursor: "pointer", display: 'block' }}>
                  <img onClick={() => this.props.editUtil()} style={{ width: '16px', position: 'absolute', right: '10px', top: "10px" }} src={editIcon} alt="" />
                </span> : null
            }
          </Col>
        </Row>
        {
          this.state.deleteAttachmentmodal ?
            <DeleteModal isOpen={this.state.deleteAttachmentmodal} className="modal-dialog-centered"  >
              <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this Attachment
                <Tooltip title={this.state.deleteItem.name} arrow placement='top' style={{ marginTop: '3px' }}>
                  <div>
                    <Chip variant="outlined" size="small" label={this.state.deleteItem.name.substring(0, 30) + '...'} color="primary" />
                  </div>
                </Tooltip>
              </p>
              <ul className="list-inline" style={{ textAlign: 'center' }}>
                <li className="list-inline-item">
                  <button className="btn btn-primary btn-sm add-new" onClick={this.deleteAttachment}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({ deleteAttachmentmodal: false })} >No</button>
                </li>
              </ul>
            </DeleteModal> :
            null
        }
         {
          previewDocs ?
          <Modal isOpen='open' style={{ width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight:'95vh', height:'95vh', marginTop:'0px'  }}>
           <ModalHeader>{previewDocs.name}</ModalHeader>
          <ModalBody style={{height: '91vh', maxHeight:'83vh'}}>
              {previewDocs.extension == 'csv' ?
                  <object data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ height: '78vh', width:'-webkit-fill-available' }} >
                  </object>
                  :
                  ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ?
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.state.previewDocs.url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                    :
                    ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ?
                    <div style={{ backgroundImage: "url(" + previewDocs.url +")", height: '100%',backgroundRepeat:'no-repeat',backgroundSize:'contain' ,backgroudPosition: 'center'}}> </div>
                      :
                      previewDocs.extension == 'pdf' ?
                        <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                          <param name="type" value="text/html" />
                        </object> :
                        null
                }
              </ModalBody>
              <ModalFooter>
                <MuiButton variant='contained' color='primary' onClick={(e) => window.open(previewDocs.url)}> Download</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.setState({ previewDocs: '' })}>Okay</MuiButton>
              </ModalFooter>
            </Modal> : null
        }
        {
          addAttachment === true ?
            <Modal isOpen='open'>
              <ModalHeader> Attach Utilization documents</ModalHeader>
              <ModalBody>
                <DropzoneArea
                  filesLimit={5}
                  acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                  showPreviewsInDropzone={false}
                  showPreviews={true}
                  useChipsForPreview
                  dropzoneText={<p>Drag & Drop Pictures<br /> OR <br /> Click Here</p>}
                  dropzoneClass="drag-drop-cnt"
                  maxWidth="sm"
                  showAlerts={['error', 'info']}
                  onChange={(files) => this.onFieldChange('', 'attatchedDoc', files)}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                >
                </DropzoneArea>
                <h6 style={{marginTop:'5px', fontSize:'13px'}}> <b>Note:</b> Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Max Upto 5 Files )</h6>

              </ModalBody>
              <ModalFooter>
                <MuiButton color='primary' onClick={() => { this.setState({ addAttachment: false, attatchedDoc: [] }) }}>Cancel</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.addAttachment(e)} disabled={!attatchedDoc.length}>Okay</MuiButton>
              </ModalFooter>
            </Modal>
            : null
        }
      </div>
    )
  }
}
