import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Button, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader } from '../../../../shared_elements';
import { checkApiStatus } from '../../../../utils_v2';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { editFianceDetailApi } from '../apiServices';
import { errorCode } from '../';
import { regexConstants } from '../../../../constants/regEx';
import { withSnackbar } from 'notistack';
class OtherFees extends Component{
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      modal:false,
      finance:{},
      pageLoader:false,
      error:{}
    }
    this.editFianceDetailApi = editFianceDetailApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      finance:{
        ...prevState.finance,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  editFinanceDetail = () => {
    const { finance } = this.state;
    let validationInputs = {
      lease_service_fee:errorCode['lease_service_fee'][fieldValidation({...errorCode['lease_service_feeObj'], fieldval: finance.lease_service_fee })],
      administrator_fee:errorCode['administrator_fee'][fieldValidation({...errorCode['administrator_feeObj'], fieldval: finance.administrator_fee })],
      re_marketing_cost:errorCode['re_marketing_cost'][fieldValidation({...errorCode['re_marketing_costObj'], fieldval: finance.re_marketing_cost })],
      asset_management_cost:errorCode['asset_management_cost'][fieldValidation({...errorCode['asset_management_costObj'], fieldval: finance.asset_management_cost })],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.editFianceDetailApi(this.props, finance)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, finance:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs})
    }
  }
  render(){
    const { modal, formSubmitLoader, finance, error,pageLoader } = this.state;
    const { financial } = this.props;
    return(
      <Fragment>
               { pageLoader || formSubmitLoader ? <PageLoader />:null}
        <Tooltip title='Edit Other Fees / Costs'>
          <IconButton size="small" color="primary">
            <EditIcon fontSize="small" color="primary" onClick={() => this.setState({modal:true, finance:financial, error:{}})} />
          </IconButton>
        </Tooltip>

        {modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, finance:{}, error:{}})}
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">
                 Edit Other Fees / Costs
              </div>
              <div className="drawer-body">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="lease_service_fee"
                      label="Lease Servicer Fee p.a"
                      fullWidth
                      margin="normal"
                      value={finance.lease_service_fee ? finance.lease_service_fee:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'lease_service_fee', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 13}}
                      error={error.lease_service_fee ? true:false}
                      helperText={error.lease_service_fee ? error.lease_service_fee:''}
                      onFocus={() => this.updateErrorField('lease_service_fee', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="administrator_fee"
                      label="Administrator Fee p.a"
                      fullWidth
                      margin="normal"
                      value={finance.administrator_fee ? finance.administrator_fee:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'administrator_fee', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 13}}
                      error={error.administrator_fee ? true:false}
                      helperText={error.administrator_fee ? error.administrator_fee:''}
                      onFocus={() => this.updateErrorField('administrator_fee', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="re_marketing_cost"
                      label="Remarketing Cost"
                      fullWidth
                      margin="normal"
                      value={finance.re_marketing_cost ? finance.re_marketing_cost:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 're_marketing_cost', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 13}}
                      error={error.re_marketing_cost ? true:false}
                      helperText={error.re_marketing_cost ? error.re_marketing_cost:''}
                      onFocus={() => this.updateErrorField('re_marketing_cost', '')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="asset_management_cost"
                      label="Asset Management Cost p.a"
                      fullWidth
                      margin="normal"
                      value={finance.asset_management_cost ? finance.asset_management_cost:''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'asset_management_cost', e.target.value):e.preventDefault()}}
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 13}}
                      error={error.asset_management_cost ? true:false}
                      helperText={error.asset_management_cost ? error.asset_management_cost:''}
                      onFocus={() => this.updateErrorField('asset_management_cost', '')}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="drawer-footer">
                <Button onClick={this.editFinanceDetail} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                </Button>
                <Button onClick={() => this.setState({modal:false, finance:{}, error:{}})} color="primary">Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(OtherFees));
