import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import FilterDropdown from './FilterDropdown';
import AssetFilterField from '../containers/AssetFilterField';
import AssetAppliedFilter from '../containers/AssetAppliedFilter';
import { creditClassification, imgStoragePath } from '../../constants';
import { getLocalStorageInfo } from '../../utils';
import {leaseStatus} from '../../shared/components';
import { Link } from 'react-router';
import { jetStreamInstance } from '../../lease_management/Leases';
export default class AssetsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: true,
      dropDownOpen: false,
      filterSelected: false,
      selectedFilterTitle: ''
    };

    this.displayFilter = {
      'display': 'block'
    };
    this.hideFilter = {
      'display': 'none'
    };
    this.displaySelectedBlock = {
      'display': 'block'
    };
    this.hideSlelectedBlock = {
      'display': 'none'
    };
    this.changeFilter = this.changeFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.updateSlectedItem = this.updateSlectedItem.bind(this);
  }
  handleDownClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    } else {
    }

  }
  componentWillMount() {
    document.addEventListener('mousedown', this.handleDownClick, false);
  }
  componentWillReceiveProps(nextProps){
    // if(this.state.currTab !== nextProps.activeTab && (this.state.filterSelected || this.state.selectedFilterTitle)){
    if(this.state.currTab !== nextProps.activeTab){
      this.setState({selectedFilterTitle:'', filterSelected:false})
    }
    this.setState({currTab:this.props.activeTab })
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDownClick, false);
  }

  changeFilter(selectionOption, type, valueKey) {
    this.props.updateFilter(selectionOption ? selectionOption[valueKey] : '', type);
  }

  toggleFilter() {
    this.setState(prevState => ({
      ...prevState,
      dropDownOpen: !prevState.dropDownOpen
    }));
  }
  updateSlectedItem(value) {
    if (value) {
      this.setState(prevState => ({
        ...prevState,
        selectedFilterTitle: value,
        filterSelected: !prevState.filterSelected,
        dropDownOpen: !prevState.dropDownOpen
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        filterSelected: !prevState.filterSelected
      }));
    }
  }
  onChange = date => this.setState({ date })
  render() {
    const { operators, filterData, lessorList, moduleTypes, aircraftTypeList, lesseeList, engTypeList, apuTypeList, techConstants } = this.props;
    const {jacLeaseStatus,defaultLeaseStatus,airAsiaLeaseStatus} = leaseStatus;
    let userInfo= getLocalStorageInfo();
    var filterMenu = {
      'Lessee': {
        'inputType': 'dropdown',
        'keyParam': 'lessee',
        'listType': 'lessee',
        'placeholder': 'Select Lessee',
        'labelKey': 'name',
        'valueKey': 'slug',
        'options': lesseeList
      },
      // 'Apu Type': {
      //   'inputType': 'dropdown',
      //   'keyParam': 'apu_types',
      //   'placeholder': 'Select Apu Type',
      //   'labelKey': 'name',
      //   'valueKey': 'id',
      //   'options': apuTypeList'Lessor': {
      //   'inputType': 'text',
      //   'keyParam': 'lessor_name'
      // },
      // },
      // 
      'Ownership': {
        'inputType': 'dropdown',
        'keyParam': 'ownership_type',
        'placeholder': 'Select Ownership Type',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': [{ label: 'Owned', value: '1' }, { label: 'Managed', value: '2' },{label: 'Third Party', value:'3'}]
      },
      'Portfolio': {
        'inputType': 'text',
        'keyParam': 'portfolio'
      },
      'Registration Number': {
        'inputType': 'text',
        'keyParam': 'registration'
      },
      'Serial Number': {
        'inputType': 'text',
        'keyParam': 'serial_number'
      },
      'Status': {
        'inputType': 'dropdown',
        'keyParam': 'status',
        'placeholder': 'Select Status',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus :defaultLeaseStatus },
      'Sub Status': {
        'inputType': 'dropdown',
        'keyParam': 'sub_status',
        'placeholder': 'Select Sub Status',
        'labelKey': 'label',
        'valueKey': 'value',
        'options': techConstants.filter(i=> i.type == 'asset_sub_status')
      },
    };
    if(jetStreamInstance){
      filterMenu={
      'Owner/SPV': {
        'inputType': 'text',
        'keyParam': 'owner'
      },
      ...filterMenu
    }
    }else{
      filterMenu={
      'Owner': {
        'inputType': 'text',
        'keyParam': 'owner'
      },
        ...filterMenu
      }
    }
    if(jetStreamInstance){
      filterMenu={
        'Lessor/Holdco': {
          'inputType': 'text',
          'keyParam': 'lessor_name'
        },
        ...filterMenu
      }
    }else{
      filterMenu={
        'Lessor': {
          'inputType': 'text',
          'keyParam': 'lessor_name'
        },
        ...filterMenu
      }
    }
    if (this.props.activeTab == 'all' || this.props.activeTab == '3') {
      filterMenu = {
        'APU Type': {
          'inputType': 'dropdown',
          'keyParam': 'apu_type',
          'placeholder': 'Select APU Type',
          'options': apuTypeList,
          'labelKey': 'name',
          'valueKey': 'id',
        },
        ...filterMenu
      }
    }
    if (this.props.activeTab == 'all' || this.props.activeTab == '2') {
      filterMenu = {
        'Engine Type': {
          'inputType': 'dropdown',
          'keyParam': 'engine_type',
          'placeholder': 'Select Engine Type',
          'options': engTypeList,
          'labelKey': 'name',
          'valueKey': 'id',
        },
        ...filterMenu
      }
    }
    if (this.props.activeTab == 'all' || this.props.activeTab == '1') {
      filterMenu = {
        'Aircraft Type': {
          'inputType': 'dropdown',
          'keyParam': 'aircraft_type',
          'placeholder': 'Select Aircraft Type',
          'options': aircraftTypeList,
          'labelKey': 'name',
          'valueKey': 'id',
        },
        ...filterMenu
      }
    }
    // if(userInfo?.defaultLessor?.id == 442){
    //        delete filterMenu['Sub Status']
    //    }
    return (
      <div className="asset-list-filter-sort">
        <Row className="add-filter-type">
          <Col md="12">
            <div className="add-filter">
              <p onClick={this.toggleFilter} style={{ fontSize: "12px", padding: "4px 17px", border: "1px dashed" }}>
                <span>
                  {this.state.dropDownOpen ? <img className="close-block" src={imgStoragePath + "black_close.png"} alt="close" /> : <img className="plus-icon" src={imgStoragePath + "black_plus.png"} alt="img" width="10" />}
                  {this.state.dropDownOpen ? ' Close Filter' : ' Add a Filter'}
                </span>
              </p>
              {filterData && Object.keys(filterData).length ? <AssetAppliedFilter techConstants={techConstants} /> : null}
              <ul ref={node => this.node = node} className="list-unstyled filter-list" style={this.state.dropDownOpen ? this.displayFilter : this.hideFilter}>
                {filterMenu && Object.keys(filterMenu).map((menu, index) =>
                  <li onClick={() => { this.updateSlectedItem(menu) }} key={index} className="para-ui th-highlight">{menu}</li>
                )}
              </ul>
              <div className="match-block" style={this.state.filterSelected ? this.displaySelectedBlock : this.hideSlelectedBlock}>
                <div className="match-header">
                  <h4>
                    {this.state.selectedFilterTitle}
                    <span onClick={() => { this.updateSlectedItem('') }}><img width="13" src={imgStoragePath + "white_close.png"} alt="img" /></span>
                  </h4>
                </div>
                {this.state.selectedFilterTitle ? <AssetFilterField updateSlectedItem={this.updateSlectedItem} filterData={filterData} fieldObj={filterMenu[this.state.selectedFilterTitle]} /> : null}
              </div>
            </div>
          </Col>
        </Row>

        {
          getLocalStorageInfo().defaultLessor.id===442 ?
          <Row className="filter-block">
            <Col xs="1" md="2" >
              <h6>
                <span onClick={() => this.props.updateAssetSort('asset_name')}>
                Asset
                <sup className="asc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
              </span>
            </h6>
          </Col>
          <Col xs="1" md="1">
          
            <h6 style={{width:'120px',cursor:'pointer'}}>          
              <span onClick={() => this.props.updateAssetSort('lessor_name')} style={{ cursor: 'pointer' }}>
                {jetStreamInstance?'Lessor/Holdco':'Lessor'}
                <sup className="asc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'asc') ? { color: '#000' ,cursor:'pointer'} : { color: '#dfdfdf' }}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'desc') ? { color: '#000',cursor:'pointer' } : { color: '#dfdfdf' }}>&#9660;</sub>
              </span>
            </h6>

          </Col>
          <Col xs="1" md="1">
            <h6>
              <span onClick={() => this.props.updateAssetSort('lessee')}>
                Lessee
                <sup className="asc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                <sub className="desc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
              </span>
            </h6>
          </Col>
            <Col xs="1" md="1">
              <h6>
                <span onClick={() => this.props.updateAssetSort('next_use')}>
                  Next Use
                  <sup className="asc-sort" style={(filterData.sort == 'next_use' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                  <sub className="desc-sort" style={(filterData.sort == 'next_use' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                </span>
              </h6>
            </Col>
          {
            this.props.activeTab == 'all' || this.props.activeTab == '1' ?
            <Col xs="1" md="1" >
              <h6 style={{width:"100px"}}>
                <span onClick={() => this.props.updateAssetSort('registration')}>
                  Registration
                  <sup className="asc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                  <sub className="desc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                </span>
              </h6>
            </Col> : null
          }

          {
              this.props.activeTab == 'all' || this.props.activeTab == '1' ?
                <Col xs="1" md="1">
                  <h6 >
                    <span onClick={() => this.props.updateAssetSort('location')}>
                       Location
                      <sup className="asc-sort" style={(filterData.sort == 'location' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                      <sub className="desc-sort" style={(filterData.sort == 'location' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                    </span>
                  </h6>
                </Col>:
                <Col xs="1" md="2">
                  <h6 >
                    <span onClick={() => this.props.updateAssetSort('location')}>
                       Location
                      <sup className="asc-sort" style={(filterData.sort == 'location' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                      <sub className="desc-sort" style={(filterData.sort == 'location' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                    </span>
                  </h6>
                </Col>
              }
              {
                  this.props.activeTab == 'all' || this.props.activeTab == '1' ?
                  <Col xs="4" md="2">
                    <h6>
                      <span onClick={() => this.props.updateAssetSort('status')}>
                        Status
                        <sup className="asc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                        <sub className="desc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                      </span>
                    </h6>
                  </Col>:
                  <Col xs="4" md="2">
                    <h6>
                      <span onClick={() => this.props.updateAssetSort('status')}>
                        Status
                        <sup className="asc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                        <sub className="desc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                      </span>
                    </h6>
                  </Col>
              }
                <Col xs="1" md="1">
                  <h6 style={{width:"100px"}}>
                    <span> Basic rent</span>
                  </h6>
                </Col>
              <Col xs="1" md="2">
                <h6 style={{textAlign:'center'}}>
                  <span onClick={() => this.props.updateAssetSort('action')}>
                    Action
                    <sup className="asc-sort" style={(filterData.sort == 'action' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'action' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
            </Col>
          </Row>
          :
          <Row className="filter-block">
            <Col xs="1" md="2" >
              <h6>
                <span onClick={() => this.props.updateAssetSort('asset_name')}>
                  Asset
                  <sup className="asc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                  <sub className="desc-sort" style={(filterData.sort == 'asset_name' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                </span>
              </h6>
            </Col>
            {
              this.props.activeTab == 'all' || this.props.activeTab == '1' ?
              <Col xs="1" md="1">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('lessor_name')}>
                  {jetStreamInstance?'Lessor/Holdco':'Lessor'}
                    <sup className="asc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col> :
              <Col xs="1" md="2">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('lessor_name')}>
                  {jetStreamInstance?'Lessor/Holdco':'Lessor'}
                    <sup className="asc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'lessor_name' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col>
            }
            <Col xs="1" md="2">
              <h6>
                <span onClick={() => this.props.updateAssetSort('owner')}>
                 {jetStreamInstance?'Owner/SPV':'Owner'}
                  <sup className="asc-sort" style={(filterData.sort == 'owner' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                  <sub className="desc-sort" style={(filterData.sort == 'owner' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                </span>
              </h6>
            </Col>
            {
              this.props.activeTab == 'all' || this.props.activeTab == '1' ?
              <Col xs="1" md="1">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('lessee')}>
                    Lessee
                    <sup className="asc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col> :
              <Col xs="1" md="2">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('lessee')}>
                    Lessee
                    <sup className="asc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'lessee' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col>
            }
            {
              this.props.activeTab == 'all' || this.props.activeTab == '1' ?
              <Col xs="1" md="2">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('registration')}>
                    Registration
                    <sup className="asc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'registration' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col> : null
            }
            {
              this.props.activeTab == 'all' || this.props.activeTab == '1' ?
              <Col xs="4" md="2">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('status')}>
                    Status
                    <sup className="asc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col>:
              <Col xs="4" md="2">
                <h6>
                  <span onClick={() => this.props.updateAssetSort('status')}>
                    Status
                    <sup className="asc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                    <sub className="desc-sort" style={(filterData.sort == 'status' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                  </span>
                </h6>
              </Col>
            }
                <Col xs="1" md="2">
                  <h6 style={{textAlign:'center'}}>
                    <span onClick={() => this.props.updateAssetSort('action')}>
                      Action
                      <sup className="asc-sort" style={(filterData.sort == 'action' && filterData.sort_by == 'asc') ? { color: '#000' } : { color: '#dfdfdf' }}> &#9650; </sup>
                      <sub className="desc-sort" style={(filterData.sort == 'action' && filterData.sort_by == 'desc') ? { color: '#000' } : { color: '#dfdfdf' }}>&#9660;</sub>
                    </span>
                  </h6>
              </Col>
          </Row>
        }
      </div>
    );
  }
}
