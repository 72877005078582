import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory, Link } from 'react-router';
import { Header, ContractHeadertext, EditSidebar, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader,NoRecordFound } from '../../../../shared';
import { contrPartiesAc, partyCrudFnAc, contrAddEditPartiesAc } from '../actionCreators';
import { TOGGLE_VIEW_EDIT, PARTY_CRUD } from '../actions';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { sExportContractsAc, sAuditLogAc, sGlobalConstantAc }  from '../../../../shared/actionCreators';
import AddEditParty from '../components/AddEditParty';
import PartiesRow from '../components/PartiesRow';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
import '../../assets/styles/contract.scss';

class Party extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount(){
    this.props.fetchParties(this.props.params.id);
    this.props.fetchTechConstant({constant_types:['party_role']});
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  render() {
    const { partyList, assetViewId, partyCrud, partiesLoader, techConstants, auditLogView, params} = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} pageTitle="Parties" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`Parties`} type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          <ContractHeadertext
              title="Parties"
              desc="Section holds all Parties involved in the Lease"
              addNew = {getLocalStorageInfo().user.permission['contracts']['parties'].indexOf('C') != -1 ? '+ Add Party':''}
              addAudit= "Revision History"
              toggleAddEvent = {() => this.props.partyCrudFn('add', {})}
              toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctparties', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Parties', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Party'})}}
           />
          <div className="list-container">
            {partiesLoader ? <ListLoader />:null}
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Name', 'Role', 'Contact Address', 'Concerned Person', 'Start Date', 'End Date']}/>
                <tbody>
                  {partyList.map((party, index) =>
                    <PartiesRow key={index} party={party} viewParty={() => this.props.partyCrudFn('view', party.id)}/>
                  )}
                </tbody>
              </table>
              {!partiesLoader && !partyList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Party' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            partyCrud.type != '' ?
            (partyCrud.type == 'add') ? 'Add Lease Party':
            (partyCrud.type == 'edit') ? 'Edit Lease Party': 'View Lease Party' : ''
            }
          editSideBar={partyCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
        >
          {Object.keys(partyCrud.data).length && partyCrud.flag ?
            <div>
                <FormModeType
                  auditLog={true}
                  auditLogMode= {()=>this.props.toggleAuditlogFn('contracts', 'ctparties', 1, partyCrud.data.id, 'update')}
                  type={partyCrud.type}
                  changeViewMode={()=>this.props.changeViewMode(partyCrud.type)}
                  editAble = {getLocalStorageInfo().user.permission['contracts']['parties'].indexOf('U') != -1 ? true:false}
                  />
                <AddEditParty
                  type={partyCrud.type}
                  techConstants = {techConstants}
                  partyObj={partyCrud.data}
                  addEditParty={(data,type)=>this.props.addEditParty(data, type, assetViewId)}
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                  partiesLoader={partiesLoader}
                />
              </div>:null
            }
            {Object.keys(auditLogView.data).length && auditLogView.flag ?
              auditLogView.data.map((item, index) =>
                <UpdateAuditLog auditLogView={item} key={index}/>
              ) : null
            }
        </EditSidebar>
        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => {this.props.closeAuditSidebar({type: 'add', data: {}, flag: false}); trackActivity('Revision History', { page_title: 'Parties', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Party', item_id:partyCrud.data.id})}}
        >
          {Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
              <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
                {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index}/>
                  ) :
                  auditLogView.data.map((item, index) =>
                  <UpdateAuditLog auditLogView={item} key={index}/>
                  )
                }
              </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  partyList: state.ContrPartiesReducer.partyList,
  partyCrud: state.ContrPartiesReducer.partyCrud,
  partiesLoader: state.ContrPartiesReducer.partiesLoader,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchParties : () => dispatch(contrPartiesAc(ownProps.params)),
    addEditParty: (data, type, assetViewId) => dispatch(contrAddEditPartiesAc(ownProps.params,data, type, assetViewId)),
    partyCrudFn: (type, id) => dispatch(partyCrudFnAc(ownProps.params, type, id)),
    toggleAuditlogFn: (application, content_type, action, objectId, type, paramValue=null, paramKey=null ) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramValue, paramKey)),
    toggleEditBar: (data) => dispatch({
      type: PARTY_CRUD,
      payload: data
    }),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Party, ['contracts', 'parties', 'R'])));
