
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TableRow, TableCell, Paper } from '@material-ui/core';
import '../../assets/styles/Dashboard.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UtilsAssetViewAc } from '../../Utilizations/actionCreators';
import { CHANGE_UTIL_GRAPH, TOGGLE_TAB } from '../../Utilizations/actions';
import { getLocalStorageInfo } from '../../../../utils';
import UtilsGraph from '../../Utilizations/components/UtilsGraph';
import AvgAnnualUtilsGraph from './AvgAnnualUtilsGraph';
import { ExportManu, TableListComp } from '../../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import { showGenericFormat, showGenericFormatWD } from '../../../../utils';

class UtilTrendsGraph extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.fetchUtilsInfo('list');
    // setTimeout(() => { this.props.toggleAsset() }, 500);
  }
  render() {
    const { ctrShortInfo, annualUtlisData, activeTab, filteredUtlisList } = this.props;
    const utilsTrends = annualUtlisData?.data;
    return (
      <>
        {
          getLocalStorageInfo().user.user_role != 'lessee' ?
            <div className="util-heading-block" style={{ marginLeft: '13px', marginTop: '20px' }}>
              <div className="edit-view-tech-spec" style={{ margin: 0 }}>
                <Nav tabs >
                  {
                    utilsTrends.aircraft ?
                      <NavItem>
                        <NavLink
                          style={{paddingLeft:'0px'}}
                          className={classnames({ active: activeTab === '0' })}
                          onClick={() => { this.props.tabSwitcher('0'); setTimeout(() => { this.props.toggleAsset() }, 500); }}
                        >

                          Airframe
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.apu ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => { this.props.tabSwitcher('1'); setTimeout(() => { this.props.toggleAsset() }, 500); }}
                        >

                          APU
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.lg ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '2' })}
                          onClick={() => { this.props.tabSwitcher('2'); setTimeout(() => { this.props.toggleAsset() }, 500); }}
                        >
                          Landing Gears
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.engine ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.props.activeTab === '3' })}
                          onClick={() => { this.props.tabSwitcher('3'); setTimeout(() => { this.props.toggleAsset() }, 500); }}
                        >
                          Engines
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.propeller ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '8' })}
                          onClick={() => this.props.tabSwitcher('8')}
                        >

                          Propeller
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.fan ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => this.props.tabSwitcher('4')}
                        >

                          Fan
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.hpt ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '5' })}
                          onClick={() => this.props.tabSwitcher('5')}
                        >

                          Hpt
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.lpt ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '6' })}
                          onClick={() => this.props.tabSwitcher('6')}
                        >

                          Lpt
                        </NavLink>
                      </NavItem> : null
                  }
                  {
                    utilsTrends.hpc ?
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '7' })}
                          onClick={() => this.props.tabSwitcher('7')}
                        >

                          Hpc
                        </NavLink>
                      </NavItem> : null
                  }
                </Nav>
                <TabContent activeTab={activeTab}>
                  {
                    utilsTrends.aircraft ?
                      <TabPane tabId='0'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.aircraft.hour_plots} cycle_plots={utilsTrends.aircraft.cycle_plots} />
                      </TabPane> : null
                  }
                  {
                    utilsTrends.apu ?
                      <TabPane tabId='1'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.apu.hour_plots} cycle_plots={utilsTrends.apu.cycle_plots} />
                      </TabPane> : null
                  }
                  {
                    utilsTrends.lg ?
                      <TabPane tabId='2'>
                        <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                          {
                            Object.keys(utilsTrends.lg).map((label, index) =>
                              <li className="list-inline-item" key={index}>
                                <label>
                                  <input
                                    type="radio"
                                    name="lg"
                                    checked={label == annualUtlisData.lgSelected ? true : false}
                                    onChange={(e, val) => { this.props.updateUtilsGraph('lgSelected', label) }}
                                  />
                                  <span>{utilsTrends.lg[label].name} </span></label>
                              </li>
                            )
                          }
                        </ul>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.lg[annualUtlisData.lgSelected].hour_plots} cycle_plots={utilsTrends.lg[annualUtlisData.lgSelected].cycle_plots} />
                      </TabPane> : null
                  }
                  {
                    utilsTrends.propeller ?
                      <TabPane tabId='8'>
                        <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                          {
                            Object.keys(utilsTrends.propeller).map((label, index) =>
                              <li className="list-inline-item" key={index}>
                                <label>
                                  <input
                                    type="radio"
                                    name="propeller"
                                    checked={label == annualUtlisData.propellerSelected ? true : false}
                                    onChange={() => this.props.updateUtilsGraph('propellerSelected', label)}
                                  />
                                  <span>{utilsTrends.propeller[label].name}</span></label>
                              </li>
                            )
                          }
                        </ul>
                        { }
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.propeller[annualUtlisData.propellerSelected].hour_plots} cycle_plots={utilsTrends.propeller[annualUtlisData.propellerSelected].cycle_plots} />
                      </TabPane> : null
                  }
                  {
                    utilsTrends.engine ?
                      <TabPane tabId='3'>
                        <ul className="list-inline lg-checkbox" style={{ marginBottom: '10px' }}>
                          {
                            Object.keys(utilsTrends.engine).map((label, index) =>
                              <li className="list-inline-item" key={index}>
                                <label>
                                  <input
                                    type="radio"
                                    name="engine"
                                    checked={label == annualUtlisData.engineSelected ? true : false}
                                    onChange={() => this.props.updateUtilsGraph('engineSelected', label)}
                                  />
                                  <span>{utilsTrends.engine[label].name}</span></label>
                              </li>
                            )
                          }
                        </ul>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.engine[annualUtlisData.engineSelected].hour_plots} cycle_plots={utilsTrends.engine[annualUtlisData.engineSelected].cycle_plots} />
                      </TabPane> : null
                  }
                   {
                    utilsTrends.fan ?
                      <TabPane tabId='4'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.fan.hour_plots} cycle_plots={utilsTrends.fan.cycle_plots} />
                      </TabPane> : null
                  }
                   {
                    utilsTrends.hpt ?
                      <TabPane tabId='5'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.hpt.hour_plots} cycle_plots={utilsTrends.hpt.cycle_plots} />
                      </TabPane> : null
                  }
                   {
                    utilsTrends.hpc ?
                      <TabPane tabId='7'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.hpc.hour_plots} cycle_plots={utilsTrends.hpc.cycle_plots} />
                      </TabPane> : null
                  }
                   {
                    utilsTrends.lpt ?
                      <TabPane tabId='6'>
                        <AvgAnnualUtilsGraph ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.lpt.hour_plots} cycle_plots={utilsTrends.lpt.cycle_plots} />
                      </TabPane> : null
                  }
                </TabContent>
              </div>

              {/* Commenting this Annual Average Utilization Listing if in future it is required */}


              {/* <Paper style={{marginBottom:'20px', paddingTop:'10px'}}>
                <li className="list-inline-item" style={{display:'flex', alignContent:'center', marginRight:'0px', borderBottom:'1px solid #e7e7e7'}}>
                  <h5 className="para avergare-monthly-heading" style={{margin:'5px 10px 0px 8px'}}>Annual Average Utilization Listing</h5>
                  <ExportManu title="Export" files={[{ title: 'Excel', extension: 'xls', key: '' }]} exportReportFn={(file) => this.props.exportAnnualUtils(this.props, { download: file.extension })} />
                </li>
                <TableListComp
                  maxheight='400px'
                  heads={[{ label: 'Asset(s)' }, { label: 'Year' }, { label: 'Average FH' }, { label: 'Average FC' }]}
                  data={
                    filteredUtlisList.map(i =>
                      <TableRow hover tabIndex={-1}>
                        <TableCell className="asset-info" style={{paddingLeft:'9px'}} >
                          {i.name}
                        </TableCell>
                        <TableCell sm='3' className="asset-info" style={{paddingLeft:'9px', width: '24%'}} >
                          {i.year}
                        </TableCell>
                        <TableCell sm='3' className="asset-info" style={{paddingLeft:'9px', width: '24%'}} >
                          <div style={{width:'max-content'}}>
                        <NumericLabel style={{float:'left'}} params={showGenericFormat()}>{i.hours}</NumericLabel>
                        </div>
                        </TableCell>
                        <TableCell sm='3' className="asset-info" style={{paddingLeft:'9px', width: '24%'}} >
                          <div style={{width:'max-content'}}>
                        <NumericLabel style={{float:'left'}} params={showGenericFormatWD()}>{Math.floor(i.cycles)}</NumericLabel>  
                        </div>
                        </TableCell>
                      </TableRow>
                    )}
                />
              </Paper> */}
            </div>
            : null
        }
      </>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  techSpecsLoader: state.TechSummaryReducer.techSpecsLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
    // toggle: (data) => dispatch({
    //   type: TOGGLE_TAB,
    //   payload: data
    // })
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UtilTrendsGraph))
