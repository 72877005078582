import React from 'react';
import {  browserHistory } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsDB, displayDateFormat, imgStoragePath } from '../../../constants';
import moment from 'moment';
import { dateTransform,showCurrencyFormat } from '../../../utils';
import { checkPermission } from '../../../utils_v2';
const InvoiceWidgetList = (props) => {
  let assetType = '';
  if(props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 1){
    assetType = 'aircraft'
  }else if(props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 2){
    assetType = 'engine'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 3) {
    assetType = 'apu'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 4) {
    assetType = 'lg'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 5) {
    assetType = 'propeller'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 6) {
    assetType = 'fan'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 7) {
    assetType = 'hpc'
  }else if (props.invoice && props.invoice.asset && props.invoice.asset.asset_type == 8) {
    assetType = 'hpt'
  }else {
    assetType ="lpt"
  }
  return(
    <tr  style={{ cursor:'pointer'}} onClick={()=> { browserHistory.push('/'+ assetType +'/'+ props.invoice.asset.slug +'/contract/'+props.invoice.contract_slug+'/invoice/view/' + props.invoice.id)}}  >
      <td>{props.invoice && props.invoice.asset ? props.invoice.asset.asset : '--'}</td>
      <td>{props.invoice && props.invoice.asset ? props.invoice.asset.type : '--'}</td>
      <td>{props.invoice && props.invoice.lessee ? props.invoice.lessee.name : '--'}</td>
      <td>{props.invoice ? props.invoice.invoice_number : '--'}</td>
      <td>{props.invoice && props.invoice.invoice_type ? props.invoice.invoice_type.label : '--'}</td>
      <td className={props.invoice && props.invoice.in_due ? 'in-due' : '' }>
      {
        props.invoice && props.invoice.total_amount ?
          props.invoice.invoice_type.value==2?
            props.invoice.children && props.invoice.children.length>0?
              <div>
                <NumericLabel params={showCurrencyFormat(props.invoice.currency)}>{props.invoice.total_amount}</NumericLabel>
                <div className={props.invoice.children[0].in_due ? 'in-due' : 'not-due'}>
                  <NumericLabel params={showCurrencyFormat(props.invoice.children[0].currency)}>{props.invoice.children[0].sub_total}</NumericLabel>
                </div>
              </div>
            :
              <NumericLabel params={showCurrencyFormat(props.invoice.currency)}>{props.invoice.total_amount}</NumericLabel>
          :
            <NumericLabel params={showCurrencyFormat(props.invoice.currency)}>{props.invoice.total_amount}</NumericLabel>
          : props.invoice.total_amount == 0 ?
          <NumericLabel params={showCurrencyFormat(props.invoice.currency)}>{props.invoice.total_amount}</NumericLabel> : '--'
       }


      </td>
      <td>{props.invoice && props.invoice.due_date ? moment(props.invoice.due_date ).format(displayDateFormat) :  null}</td>
      <td>{props.invoice && props.invoice.date  ? moment(props.invoice.date ).format(displayDateFormat) :  null}</td>

      <td className={props.invoice && props.invoice.in_due ? 'in-due status-block' : 'status-block' }>
        {
          props.invoice && props.invoice.in_due ?
          <span style={{color: 'red', fontWeight: '700'}}> Overdue By { dateTransform(props.invoice.due_date) } </span>:
           <span className={props.invoice && props.invoice.status ? props.invoice.status.label +' invoice-status' : 'invoice-status'}>{props.invoice && props.invoice.status ? props.invoice.status.label : '--'}</span>
        }
      </td>
      <td style={{color: '#1b72e3', cursor: 'pointer'}}>
        { checkPermission('contracts','invoices','EXP') ?
          <span style={{display: 'flex'}} onClick={(evt) => {evt.preventDefault(); evt.stopPropagation(); props.exportInvoice()}}>
            <img style={{marginRight: '5px'}} width="12" src={imgStoragePath + 'pdf_icon.png'}/> PDF
          </span>:null
        }
      </td>
    </tr>
  )
}

export default InvoiceWidgetList;
