import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { capitalizeFirstLetter, checkApiStatus, getCurrencyFormat, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditSecurityDepositTxnApi, getTxnDetailApi } from '../apiServices';
import { errorCode, addCashTx } from '../';
import { regexConstants } from '../../../../constants/regEx';
class transactionCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader:false,
      mode:'',
      transaction:{},
      error:{}
    }
    this.addEditSecurityDepositTxnApi = addEditSecurityDepositTxnApi.bind(this);
    this.getTxnDetailApi = getTxnDetailApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      transaction: {
        ...prevState.transaction,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEdittransactionFn = () => {
    const { transaction } = this.state;
    let validationInputs = {
      reference_number:errorCode['reference_number'][fieldValidation({...errorCode['reference_numberObj'], fieldval: transaction.reference_number})],
      transaction_date:errorCode['transaction_date'][fieldValidation({...errorCode['transaction_dateObj'], fieldval: transaction.transaction_date})],
      amount:errorCode['amount'][fieldValidation({...errorCode['amountObj'], fieldval: transaction.amount})],
    };
    if(transaction.retention_period_start_date && transaction.retention_period_end_date){
      validationInputs = {
        ...validationInputs,
        retention_period_start_date: dateRangeValidation(transaction.retention_period_start_date, transaction.retention_period_end_date, 30)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.addEditSecurityDepositTxnApi(this.props, this.props.securityDepositId, transaction)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', transaction:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs})
    }
  }
  render(){
    const { modal, mode, formSubmitLoader, transaction, error, pageLoader } = this.state;
    const { id, m, securityDepositId, contractInfo } = this.props;
    return(
      <Fragment>
        {pageLoader ? <PageLoader/> : null}
        { id ?
          <span onClick={() => this.getTxnDetailApi(this.props, securityDepositId, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Tooltip title='Add Cash Transaction'>
            <AddCircleOutlineIcon color="primary" onClick={() => this.setState({modal:true, transaction:addCashTx, mode:'add'})} />
          </Tooltip>:null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'',transaction:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header">
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Cash Transaction`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctsecuritydeposittransactions/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','security_deposit', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Reference Number' value={transaction.reference_number ? transaction.reference_number:'--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="reference_number"
                        label="Reference Number"
                        fullWidth
                        margin="normal"
                        value={transaction.reference_number?transaction.reference_number:''}
                        onChange={(e, value) => this.onFieldChange(e, 'reference_number', e.target.value)}
                        error={error.reference_number ? true:false }
                        helperText={error.reference_number ? error.reference_number:'' }
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 30}}
                        onFocus={() => this.updateErrorField('reference_number', '')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Transaction Date' value={transaction.transaction_date? moment(transaction.transaction_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                           margin="normal"
                           id="transaction_date"
                           label="Transaction Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={transaction.transaction_date ? transaction.transaction_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'transaction_date', moment(data).format(backendDateFormat))}
                           error={error.transaction_date ? true:false }
                           helperText={error.transaction_date ? error.transaction_date:'' }
                           onFocus={() => this.updateErrorField('transaction_date', '')}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Amount' value={transaction.amount ? <NumericLabel params={getCurrencyFormat()}>{transaction.amount}</NumericLabel> :'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="amount"
                        label="Amount"
                        fullWidth
                        margin="normal"
                        value={transaction.amount ? transaction.amount:''}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'amount', e.target.value):e.preventDefault()}}
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 10}}
                        error={error.amount ? true:false }
                        helperText={error.amount ? error.amount:'' }
                        onFocus={() => this.updateErrorField('amount', '')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Validity Period Start Date' value={transaction.retention_period_start_date ? moment(transaction.retention_period_start_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="retention_period_start_date"
                           label="Validity Period Start Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={transaction.retention_period_start_date ? transaction.retention_period_start_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'retention_period_start_date', moment(data).format(backendDateFormat))}
                           error={error.retention_period_start_date ? true:false }
                           helperText={error.retention_period_start_date ? error.retention_period_start_date:'' }
                           onFocus={() => this.updateErrorField('retention_period_start_date', '')}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Validity Period End Date' value={transaction.retention_period_end_date ? moment(transaction.retention_period_end_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="retention_period_end_date"
                           label="Validity Period End Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={transaction.retention_period_end_date ? transaction.retention_period_end_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'retention_period_end_date', moment(data).format(backendDateFormat))}
                         />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEdittransactionFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', transaction:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(transactionCRU));
