import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import WorkingGrpToolTip from './WorkingGrpToolTip';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import addIcon from '../../../../shared/assets/img/add_icon.svg';
import revisionHistoryIcon from '../../../../shared/assets/img/revision_history_icon.svg';
export default class WorkingGrpWrapper extends Component{
  constructor(props){
    super(props);
    this.state = { collapse: false };
  }
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }
  render(){
    const { workingGroup, index } = this.props;
    return(
      <div className="working-group-wrapper">
        <h5 className="working-grp-title">
          <p onClick={this.toggle}>{workingGroup.name}</p>
          <ul className="list-inline flex-centered">
            <li className="list-inline-item hidden-xs" onClick={() => this.props.viewTeamInfo( workingGroup.id, index)}>
              <WorkingGrpToolTip imgPath={editIcon} toolTipText="Edit Working Group Name" tooltipId="editWorkingGrp" />
            </li>
            {
              getLocalStorageInfo().user.permission['contracts']['working_group'].indexOf('C') != -1 ?
              <li className="list-inline-item hidden-xs" onClick={() => this.props.addMember(workingGroup.id,index)}>
                <WorkingGrpToolTip imgPath={addIcon} toolTipText="Add Member" tooltipId="addTeam" />
              </li>:null
            }
            <li className="list-inline-item">
              <span className="wg-audit-history" onClick={() => this.props.toggleAuditlog()}> <img src={revisionHistoryIcon} alt="img"/> Revision History</span>
            </li>
            <li onClick={this.toggle} className="list-inline-item" style={{paddingLeft: '50px'}}>
              <img style={ !this.state.collapse ? {transform: 'rotate(180deg)'}:{transform: 'rotate(0deg)'}} src={imgStoragePath + "collapse_arw.png"} alt="icon" />
            </li>
          </ul>
        </h5>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
              {this.props.children}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    )
  }
}
