import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { jetStreamInstance, leasesStatusGraphFilterOps } from '../';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const OwnerGraph = ({ ownerGraphData, ownerOptions, skeletonLoader, filter, getResponseBack,  callExternalFunction, ownerOption }) => {
    let filterOptions = Object.assign({}, leasesStatusGraphFilterOps);
    const options = {
        chart: {
            type: 'column',
            zoomType: 'xy',
            panning: true,
            panKey: 'shift',
            styledMode: true,
            height: 700
        },
        title: {
            text: `Based on ${jetStreamInstance?"Owner/SPV":"Owner"}`,
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${ownerGraphData.title ? ownerGraphData.title : ''}`,
            align: 'left'
        },
        xAxis: {
            categories: ownerGraphData && ownerGraphData.lease_status_categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Leases'
            },
            gridLineDashStyle: 'longdash',
            stackLabels: {
                enabled: true,
                style: {
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'black',
                    textOutline: 'none',
                    fontFamily: 'Conv_IBMPlexSans-SemiBold',
                    fontSize:'13px'
                }
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                },
                point: {
                    events: {
                      click: function() {
                        callExternalFunction(this.series.name, ownerOptions.find(item => item.name == this.category), 'basedOnOwner');
                      }
                    }
                  }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> <br/>'
        },
        legend: {
            enabled: true
        },
        series: ownerGraphData && ownerGraphData.lease_status_owners,
        credits: false,
        exporting: {
            enabled: true,
            allowHTML: true,
            chartOptions: {
                chart: {
                    height: 500,
                    width: 800
                }
            },
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                }
            },
            filename: 'Lease(s)-Based on Owner Chart',
        }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={700} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default OwnerGraph
