import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addObligation = {
  obligation_type: null,
  sub_type: null,
  applicable: null,
  details: '',
  payment_due_date: '',
  value: '',
  status: '0',
  remarks: '',
  to_be_approved_by: null
};
export const obligationHd = [
  { label: 'Type', sortOption: false },
  { label: 'Detail', sortOption: false },
  { label: 'Commercial Value', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Created By', sortOption: false },
  { label: 'Approver', sortOption: false },
  { label: 'Action', sortOption: false }
];
export const errorCode = {
  obligation_type:{
    0:'',
    1: 'Please select Obligation Type'
  },
  obligation_typeObj: {
    required: true
  },
  sub_type: {
    0:'',
    1: 'Please select Obligation Sub Type'
  },
  sub_typeObj: {
    required: true
  },
  value: {
    0:'',
    1: 'Please enter Commercial Value'
  },
  valueObj: {
    required: true
  },
  details: {
    0:'',
    4:errorMsg1
  },
  detailsObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  applicable: {
    0:'',
    1: 'Please select Applicable To'
  },
  applicableObj: {
    required: true
  },
  to_be_approved_by: {
    0:'',
    1: 'Please select Approver'
  },
  to_be_approved_byObj: {
    required: true
  },
  payment_due_date: {
    0:'',
    1: 'Please enter Due Date'
  },
  payment_due_dateObj: {
    required: true
  },
}
