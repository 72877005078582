import React from 'react';
import { Grid, TextField, Paper } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { jetStreamInstance } from '../../Leases';
const LessorInfo = ({invoiceInfo, mode, onFieldChange, updateErrorField, error}) => {
  return(
    <Grid item xs={12} md={6}>
      <Paper className={`lessor-lessee-info ${mode === 'view' ? 'lessor-lessee-view':'lessor-lessee-edit'}`}>
        <h4>From ({jetStreamInstance?'Lessor/Holdco':'Lessor'})</h4>
        <Grid container spacing={2}>
          { mode === 'view' ?
            <LabelValueCard md={12} label='Name' value={invoiceInfo.from_company_name ? invoiceInfo.from_company_name:'--'} />
            :
            <Grid item xs={12}>
              <TextField
                id="from_company_name"
                label="Name"
                fullWidth
                margin="normal"
                value={invoiceInfo.from_company_name}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 50 }}
                error={error.from_company_name ? true:false}
                helperText={error.from_company_name ? error.from_company_name:''}
                onChange={(e) => onFieldChange(e, 'from_company_name', e.target.value)}
                onFocus={() => updateErrorField('from_company_name','')}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={12} label='Address' value={invoiceInfo.from_company_address ? invoiceInfo.from_company_address:'--'} />
            :
            <Grid item xs={12}>
              <TextField
                id="from_company_address"
                label="Address"
                fullWidth
                margin="normal"
                multiline
                rows="4"
                value={invoiceInfo.from_company_address}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'from_company_address', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={12} label='Contact' value={invoiceInfo.from_company_contact ? invoiceInfo.from_company_contact:'--'} />
            :
            <Grid item xs={12}>
              <TextField
                id="from_company_contact"
                label="Contact (Email and Phone)"
                fullWidth
                margin="normal"
                value={invoiceInfo.from_company_contact}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'from_company_contact', e.target.value)}
              />
            </Grid>
          }
        </Grid>
      </Paper>
    </Grid>
  )
}
export default LessorInfo;
