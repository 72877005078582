import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton, TextField } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx';
const AddMRUtilRow = ({item, onFieldChange, updateErrorField, error, index}) => {
  return(
    <TableRow hover tabIndex={-1} style={{position:'relative'}}>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        { item.initial_utilization ?
          <TextField
            id={`tsn_as_of_last_month_${index}`}
            fullWidth
            margin="none"
            value={item.tsn_as_of_last_month}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_as_of_last_month', e.target.value, index):e.preventDefault()}}
            onFocus={(e) => updateErrorField('tsn_as_of_last_month','', index)}
            error={error.tsn_as_of_last_month ? true:false}
            helperText={error.tsn_as_of_last_month ? error.tsn_as_of_last_month:''}
          />:
          item.tsn_as_of_last_month.toFixed(2)
        }
      </TableCell>
      <TableCell>
        { item.initial_utilization ?
          <TextField
            id={`csn_as_of_last_month_${index}`}
            fullWidth
            margin="none"
            value={item.csn_as_of_last_month}
            InputLabelProps={{shrink: true}}
            onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_as_of_last_month', e.target.value, index):e.preventDefault()}}
            onFocus={(e) => updateErrorField('csn_as_of_last_month','', index)}
            error={error.csn_as_of_last_month ? true:false}
            helperText={error.csn_as_of_last_month ? error.csn_as_of_last_month:''}
          />:
          item.csn_as_of_last_month.toFixed(2)
        }
      </TableCell>
      <TableCell>
        <TextField
          id={`hours_${index}`}
          fullWidth
          margin="none"
          value={item.hours}
          InputLabelProps={{shrink: true}}
          onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hours', e.target.value, index):e.preventDefault()}}
          onFocus={(e) => updateErrorField('hours','', index)}
          error={error.hours ? true:false}
          helperText={error.hours ? error.hours:''}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`cycles_${index}`}
          fullWidth
          margin="none"
          value={item.cycles}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'cycles', e.target.value, index):e.preventDefault()}}
          onFocus={(e) => updateErrorField('cycles','', index)}
          error={error.cycles ? true:false}
          helperText={error.cycles ? error.cycles:''}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`tsn_${index}`}
          fullWidth
          margin="none"
          value={item.tsn?item.tsn:''}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value, index):e.preventDefault()}}
          onFocus={(e) => updateErrorField('tsn','', index)}
          error={error.tsn ? true:false}
          helperText={error.tsn ? error.tsn:''}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`csn_${index}`}
          fullWidth
          margin="none"
          value={item.csn?item.csn:''}
          InputLabelProps={{shrink: true}}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value, index):e.preventDefault()}}
          onFocus={(e) => updateErrorField('csn','', index)}
          error={error.csn ? true:false}
          helperText={error.csn ? error.csn:''}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`asset_location_${index}`}
          fullWidth
          margin="none"
          value={item.asset_location}
          InputLabelProps={{shrink: true}}
          onChange={(e) => onFieldChange(e, 'asset_location', e.target.value, index)}
          onFocus={(e) => updateErrorField('asset_location','', index)}
          error={error.asset_location ? true:false}
          helperText={error.asset_location ? error.asset_location:''}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`remarks_${index}`}
          fullWidth
          margin="none"
          value={item.remarks}
          InputLabelProps={{shrink: true}}
          onChange={(e) => onFieldChange(e, 'remarks', e.target.value, index)}
          onFocus={(e) => updateErrorField('remarks','', index)}
          error={error.remarks ? true:false}
          helperText={error.remarks ? error.remarks:''}
        />
      </TableCell>
    </TableRow>
  )
}
export default AddMRUtilRow;
