import * as actions from '../actions';
import { numberRegx } from '../../../../constants';
import moment from 'moment';
const decimalRegex= /^[0-9\.\/]+$/;
export default function( state = {
  invoiceList: [],
  invoicePagination: {},
  invoiceAssetBasicInfo: {},
  invoiceView: {},
  invoiceWidget: {},
  shareInvoiceCrud: {
    flag: false,
    data: {},
    loader: false
  },
  genInvoice: {
    flag: false,
    invoiceType: '',
    selectedDate: '',
    dates: []
  },
  invoiceLoader: false,
  invoiceExportLoader: false,
  invStrLoader: false
}, action){
switch (action.type) {
  case actions.INVOICE_ASSET_INFO:
    return {
      ...state,
      invoiceAssetBasicInfo: action.payload
    }
  case actions.INVOICE_LIST:
    return {
      ...state,
      invoiceList: action.payload.invoice_list,
      invoicePagination: action.payload.pagination
    }
  case actions.INVOICE_VIEW_SUCCESS:
    return {
      ...state,
      invoiceView:action.payload
    }
  case actions.INVOICE_EDIT_SUCCESS:
    return {
      ...state,
      invoiceView:action.payload
    }
  case actions.UPDATE_CHILDREN_ITEM_FIELD:
    if( action.payload.type == 'invoice_item_type_id' || action.payload.type == 'description' || action.payload.type == 'sub_total'){
      return {
        ...state,
        invoiceView: {
          ...state.invoiceView,
          children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item,
          invoice_items: item.invoice_items.map((invoiceItem,invoiceIndex) => invoiceIndex == action.payload.index ? {...invoiceItem,
            [action.payload.type]: action.payload.value } : invoiceItem)}:item)
        }
      }
    }else if(['bank_note','bank_sort_code','bank_iban','bank_swift_code','bank_account_number','bank_account_name','bank_name','footer_note', 'aba_number'].includes(action.payload.type)){
      return {
        ...state,
        invoiceView: {
          ...state.invoiceView,
          children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item, [action.payload.type]: action.payload.value } : item)
        }
      }
    }else{
      let subTotal = 0;
      let billableAmount = 0;
      let taxAmount = 0;
      if( numberRegx.test(action.payload.value) || action.payload.value == ''){
        let value = (action.payload.value ? action.payload.value:0)
        if(action.payload.type == 'no_of_unit'){
          billableAmount =  parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].rate_per_unit*value);
          taxAmount = parseFloat(billableAmount*(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].tax_rate/100));
          subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item,
              invoice_items: item.invoice_items.map((invoiceItem,invoiceIndex) => invoiceIndex == action.payload.index ? {...invoiceItem,
                [action.payload.type]: action.payload.value,sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0   } : invoiceItem)}:item)
            }
          }
        }
      }
      if(decimalRegex.test(action.payload.value) || action.payload.value == ''){
        let value = (action.payload.value ? action.payload.value:0)
        if(action.payload.type == 'rate_per_unit'){
          billableAmount =  (value*state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].no_of_unit);
          taxAmount = parseFloat(billableAmount*(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].tax_rate/100));
          subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
        }
        if(action.payload.type == 'tax_rate'){
          billableAmount =  (state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].rate_per_unit*state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].no_of_unit);
          taxAmount = parseFloat(billableAmount*(value/100));
          subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
        }
        if(action.payload.type == 'libor_value'){
          let a= (parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].payment_schedule.margin)+parseFloat(value))/100;
          let b= parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].payment_schedule.reference_value) * a *parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].payment_schedule.period);
          let total_amount =  b /360;
          billableAmount =  (parseFloat(total_amount)*parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].no_of_unit));
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(state.invoiceView.children[action.payload.childIndex].invoice_items[action.payload.index].tax_rate)/100));
          subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item,
              invoice_items: item.invoice_items.map((invoiceItem,invoiceIndex) => invoiceIndex == action.payload.index ? {...invoiceItem,
              [action.payload.type]: action.payload.value,rate_per_unit: total_amount.toFixed(2),sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0  } : invoiceItem)}:item)
            }
          }
        }
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item,
            invoice_items: item.invoice_items.map((invoiceItem,invoiceIndex) => invoiceIndex == action.payload.index ? {...invoiceItem,
              [action.payload.type]: action.payload.value,sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0   } : invoiceItem)}:item)
          }
        }
      }else{
        if(action.payload.type != 'no_of_unit'){
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              children: state.invoiceView.children.map((item,index) => index == action.payload.childIndex ? { ...item,
              invoice_items: item.invoice_items.map((invoiceItem,invoiceIndex) => invoiceIndex == action.payload.index ? {...invoiceItem,
                [action.payload.type]: action.payload.value  } : invoiceItem)}:item)
            }
          }
        }
      }
    }
  case actions.UPDATE_INSURANCE_INVOICE_ITEM_FIELD:
  return {
    ...state,
    invoiceView: {
      ...state.invoiceView,
      invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, sub_total: action.payload.value !== '' ? parseFloat(action.payload.value) : '', billable_amount: action.payload.value !== '' ? parseFloat(action.payload.value) : '' } : item)
    }
  }
  case actions.UPDATE_INVOICE_ITEM_FIELD:
    if(action.payload.type === 'invoice_item_name'){
      return {
        ...state,
        invoiceView: {
          ...state.invoiceView,
          invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value} : item),
        }
      }
    }else if(action.payload.type === 'unit_type'){
      return {
        ...state,
        invoiceView: {
          ...state.invoiceView,
          invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value} : item),
        }
      }
    }else if( action.payload.type == 'invoice_item_type_id' || action.payload.type == 'description' || action.payload.type == 'sub_total'){
      if(action.payload.type == 'sub_total'){
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, sub_total: action.payload.value !== '' ? parseFloat(action.payload.value) : '', billable_amount: action.payload.value !== '' ? action.payload.value : '' } : item)
          }
        }
      }else{
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value } : item)
          }
        }
      }
    }else{
      let subTotal = 0;
      let billableAmount = 0;
      let taxAmount = 0;
      if( numberRegx.test(action.payload.value) || action.payload.value == ''){
        let value = (action.payload.value ? action.payload.value:0)
        if(action.payload.type == 'no_of_unit'){
          billableAmount =  (parseFloat(state.invoiceView.invoice_items[action.payload.index].rate_per_unit)*parseFloat(value));
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(state.invoiceView.invoice_items[action.payload.index].tax_rate)/100));
          subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0  } : item),
              sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0,total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
            }
          }
        }
      }
      if( decimalRegex.test(action.payload.value) || action.payload.value == ''){
        let value = (action.payload.value ? action.payload.value:0)
        if(action.payload.type == 'rate_per_unit'){
          billableAmount =  ( parseFloat(value)* parseFloat(state.invoiceView.invoice_items[action.payload.index].no_of_unit));
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(state.invoiceView.invoice_items[action.payload.index].tax_rate)/100));
          subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0  } : item),
              sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0,total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
            }
          }
        }
        if(action.payload.type == 'tax_rate'){
          let a=parseFloat(state.invoiceView.invoice_items[action.payload.index].rate_per_unit);
          let b=parseFloat(state.invoiceView.invoice_items[action.payload.index].no_of_unit);
          billableAmount =  a*b;
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(value)/100));
          subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount: taxAmount > 0 ? taxAmount : 0  } : item),
              sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0,total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
            }
          }
        }
        if(action.payload.type == 'libor_value'){
          let a= (parseFloat(state.invoiceView.invoice_items[action.payload.index].payment_schedule.margin)+parseFloat(value))/100;
          let b= parseFloat(state.invoiceView.invoice_items[action.payload.index].payment_schedule.reference_value) * a *parseFloat(state.invoiceView.invoice_items[action.payload.index].payment_schedule.period);
          let total_amount =  b /360;
          billableAmount =  (parseFloat(total_amount)*parseFloat(state.invoiceView.invoice_items[action.payload.index].no_of_unit));
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(state.invoiceView.invoice_items[action.payload.index].tax_rate)/100));
          subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
          return {
            ...state,
            invoiceView: {
              ...state.invoiceView,
              invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value, rate_per_unit: total_amount.toFixed(2) , sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0 } : item),
              sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0,total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
            }
          }
        }
      }else{
        if(action.payload.type != 'no_of_unit'){
          billableAmount =  ( parseFloat(state.invoiceView.invoice_items[action.payload.index].rate_per_unit)* parseFloat(action.payload.value));
          taxAmount = parseFloat(parseFloat(billableAmount)*(parseFloat(state.invoiceView.invoice_items[action.payload.index].tax_rate)/100));
          subTotal = parseFloat(parseFloat(parseFloat(billableAmount).toFixed(2)) + parseFloat(taxAmount)).toFixed(2);
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            invoice_items: state.invoiceView.invoice_items.map((item,index) => index == action.payload.index ? { ...item, [action.payload.type]: action.payload.value, sub_total: subTotal > 0 ? subTotal : 0, billable_amount:billableAmount > 0 ? billableAmount : 0 , tax_amount:taxAmount > 0 ? taxAmount : 0 } : item),
            sub_total: subTotal > 0 ? subTotal : 0, billable_amount: billableAmount > 0 ? billableAmount : 0, tax_amount:taxAmount > 0 ? taxAmount : 0,total_amount: parseFloat(subTotal + taxAmount) > 0 ? parseFloat(subTotal + taxAmount) : 0
          }
        }
      }
      }
    }
    case actions.UPDATE_PENALETY_INVOICE_FIELD:
      if(decimalRegex.test(action.payload.value) || action.payload.value == ''){
        let aggregateRate =state.invoiceView.penalty_interest_rate;
        let overDueAmount =state.invoiceView.overdue_interest_amount;
        let diffDays= moment(state.invoiceView.parent_paid_date).diff(moment(state.invoiceView.penalty_due_date),'days')>0 ? moment(state.invoiceView.parent_paid_date).diff(moment(state.invoiceView.penalty_due_date),'days'):0;
        let days = moment().isLeapYear() ? 366 : 365;
        let value = action.payload.value ? action.payload.value : 0;
        switch(action.payload.type){
        case 'additional_rate':
          aggregateRate = parseFloat(state.invoiceView.libor_value ? state.invoiceView.libor_value : 0) + parseFloat(value);
          overDueAmount = ((((aggregateRate / 100 ) / days ) * state.invoiceView.parent_amount) * diffDays);
          break
        case 'libor_value':
          aggregateRate = parseFloat(state.invoiceView.additional_rate ? state.invoiceView.additional_rate :0) + parseFloat(value);
          overDueAmount = ((((aggregateRate / 100 ) / days ) * state.invoiceView.parent_amount) * diffDays);

          break
        case 'over_due_days':
          aggregateRate = parseFloat(state.invoiceView.libor_value ? state.invoiceView.libor_value : 0) + parseFloat(state.invoiceView.additional_rate ? state.invoiceView.additional_rate :0);
          overDueAmount = ((((aggregateRate / 100 ) / days ) * state.invoiceView.parent_amount) * value);          break

        }
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            [action.payload.type] : action.payload.value,
            'penalty_interest_rate' :  aggregateRate,
            'total_amount' :  overDueAmount
          }
        }
      }else{
        return {
          ...state,
          invoiceView: {
            ...state.invoiceView,
            [action.payload.type] : action.payload.value
          }
        }
      }
  case actions.UPDATE_INVOICE_FIELD:
    if(action.payload.type == '***') {
      let invoiceNumber = state.invoiceView.invoice_number;
      if(action.payload.value != 'Invalid date'){
        invoiceNumber = invoiceNumber.substring(0, invoiceNumber.length - 8);
        invoiceNumber = invoiceNumber + moment(action.payload.value).format('DDMMYYYY');
      }
      return {
         ...state,
         invoiceView: {
           ...state.invoiceView,
           [action.payload.type] : action.payload.value,
           'invoice_number': invoiceNumber
         }
       }
    }else{
      return {
         ...state,
         invoiceView: {
           ...state.invoiceView,
           [action.payload.type] : action.payload.value
         }
       }
    }


  case actions.ADD_INVOICE_ITEM_FIELD:
    return {
      ...state,
      invoiceView: {
        ...state.invoiceView,
        invoice_items: [ ...state.invoiceView.invoice_items, action.payload ]
      }
    }
  case actions.GEN_INVOICE:
    if(action.payload.type == 'flag' && !action.payload.value){
      return {
        ...state,
        genInvoice: {
          flag: false,
          invoiceType: '',
          selectedDate: '',
          dates: []
        }
      }

    }else{
      return {
        ...state,
        genInvoice: {
          ...state.genInvoice,
          [action.payload.type]: action.payload.value
        }
      }
    }

  case actions.INVOICE_WIDGET:
    return {
      ...state,
      invoiceWidget: action.payload
    }
  case actions.INVOICE_LOADER:
    return {
      ...state,
      invoiceLoader: action.payload
    }
    case actions.INVOICE_EXPORT_LOADER:
    return {
      ...state,
      invoiceExportLoader: action.payload
    }
  case actions.INV_STR_LOADER:
    return {
      ...state,
      invStrLoader: action.payload
    }
    case actions.UPDATE_LIST_STATUS:
    return {
      ...state,
      invoiceList: state.invoiceList.map((item,index) => item.id == action.payload.id ? action.payload : item)
    }

    case actions.INVOICE_DELETE_SUCCESS:
    return {
      ...state,
      invoiceList: state.invoiceList.filter(item => item.id != action.payload)
    }

  case actions.INVOICE_SHARE:
    return {
      ...state,
      shareInvoiceCrud: action.payload
    }
  default:
    return state;
  }
}
