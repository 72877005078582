import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory, withRouter } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalHeader, ModalBody} from 'reactstrap';
import classnames from 'classnames';
import OverviewContnt from './OverviewContnt';
import OverviewInfo from './OverviewInfo';
import EngineContent from './EngineContent';
import RemarksCont from './RemarksCont';
import STCMods from './STCMods';
import APUDetails from './APUDetails';
import LGDetails from './LGDetails';
import APUContent from './APUContent';
import LgContent from './LgContent';
import AvionicContent from './AvionicContent';
import InteriorContent from './InteriorContent';
import PropellersContent from './PropellersContent';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { exportTechSummeryAc,exportMarkeTechSummeryAc, overviewListAc, getAssetTranferUsageAc, transferAssetAc, getTechspecRevisionHistoryAc, exportRevisionHistoryAc } from '../actionCreators';
import { ListLoader, LicenceModal,NoRecordFound } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';
import ContractLeaseInfo from '../../../contracts/Elements/ContractLeaseInfo';
import EngineDetails from './EngineDetails';
import '../assets/styles/techspec.scss';
import '../../../../shared/assets/styles/CommonTable.scss';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils'
import { EditSidebar } from '../../../../applications/contracts/Elements';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { BUY_LICENCE } from '../../../../shared/actions';
import { OVERVIEW_TECH_INFO, ASSET_TRANSFER_MODAL } from '../actions';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
class AircraftTechSummary extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1',
			transferAssetObj: {
				is_contract_and_maintenance: false,
				is_records: false,
				is_technical: false,
				is_all: false,
			},
			addRevHistory: false
		};
	}
	componentDidMount() {
		this.props.fetchTechConstant({constant_types:["asset_status","interior_location","credit_classification","engine_position","state","off_wing_status","galley_configuration","weight_unit","aircraft_operation","lg_position","galley_type","pax_seating_convertable_or_fixed","crew_seat_type","pax_seat_type", "shop_visit_type", "workscope_status", "shop_visit_type", "sv_report_status", "sv_requirement","utilization_unit", "off_lease_status"]});
		if(this.props.params.type == 'aircraft'){
			this.props.fetchOverviewList(this.props.params.aircraft_slug);
		}else{
			this.props.emptyLeaseInfo()
		}
		if( getLocalStorageInfo().user.permission.technical_specs && getLocalStorageInfo().user.permission.technical_specs.is_saas){
			this.props.getAssetTranferUsage()
		}
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
	RevHistory = () => {
		const { apuDetail, overviewTechInfo, engineDetails } = this.props
		const assetId = overviewTechInfo.id ? overviewTechInfo.id : engineDetails.id ? engineDetails.id: apuDetail.id ? apuDetail.id :''
		this.props.fetchRevisionHistory(assetId)
		this.setState({
			addRevHistory:true,
			assetId:assetId
		})
	}

	updateTransferObj = (key, flag) =>{
		this.setState((prevState) => ({
			...prevState,
			transferAssetObj: {...prevState.transferAssetObj, [key]: flag}
		}))
		if(this.state.transferAssetObj.is_contract_and_maintenance == true && this.state.transferAssetObj.is_records == true && this.state.transferAssetObj.is_technical == true){
			this.setState((prevState) => ({
				...prevState,
				transferAssetObj: {...prevState.transferAssetObj, is_all: true}
			}))
		}else{
			this.setState((prevState) => ({
				...prevState,
				transferAssetObj: {...prevState.transferAssetObj, is_all: false}
			}))
		}
	}

	toggleTransfer = (flag) =>{
		if(getLocalStorageInfo().user.permission.technical_specs && getLocalStorageInfo().user.permission.technical_specs.is_saas){
      if(this.props.assetTransferUsage.available == this.props.assetTransferUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/asset-data-transfer',
          content: "You don't have license to transfer asset. Please buy new license to transfer asset."
        })
        return;
      }
    }
		this.props.toggleTransfer(flag)
	}

	render() {
		const { apuDetail, assetTransferModal, manufacturerList, overviewTechInfo, existEsn, techSpecsLoader, sAircarftOperatn, engineDetails, revisionHistoryList } = this.props;
		const { assetId } = this.state
		const tabsInfo = overviewTechInfo.propellers_exist ?
		[
			{tabTitle: 'Overview',  component: <OverviewInfo toggleTransfer={() => this.toggleTransfer(true)} techSpecsLoader={techSpecsLoader} sAircarftOperatn= {sAircarftOperatn} techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Aircraft',  component: <OverviewContnt toggleTransfer={() => this.toggleTransfer(true)} techSpecsLoader={techSpecsLoader} sAircarftOperatn= {sAircarftOperatn} techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Engines',  component: <EngineContent  techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Propellers',  component: <PropellersContent overview={overviewTechInfo} techSpecsLoader={techSpecsLoader} manufacturerList={manufacturerList} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },
			{tabTitle: 'APU',  component: <APUContent techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} manufacturerList={manufacturerList} /> },
			{tabTitle: 'Landing Gears',  component: <LgContent techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Avionics', component: <AvionicContent techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug}/> },
			{tabTitle: 'Interior',  component: <InteriorContent techSpecsLoader={techSpecsLoader} manufacturerList={manufacturerList} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },
			{tabTitle: "STCs & Mods",  component: <STCMods techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },
			{tabTitle: 'Additional Remarks',  component: <RemarksCont techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },

		]
		:[
			{tabTitle: 'Overview',  component: <OverviewInfo toggleTransfer={() => this.toggleTransfer(true)} techSpecsLoader={techSpecsLoader} sAircarftOperatn= {sAircarftOperatn} techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Aircraft',  component: <OverviewContnt toggleTransfer={() => this.toggleTransfer(true)} techSpecsLoader={techSpecsLoader} sAircarftOperatn= {sAircarftOperatn} techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Engines',  component: <EngineContent  techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} existEsn={existEsn} manufacturerList={manufacturerList}/> },
			{tabTitle: 'APU',  component: <APUContent techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} manufacturerList={manufacturerList} /> },
			{tabTitle: 'Landing Gears',  component: <LgContent techSpecsLoader={techSpecsLoader} overviewTechInfo={this.props.overviewTechInfo} asset_slug = {this.props.params.aircraft_slug} manufacturerList={manufacturerList}/> },
			{tabTitle: 'Avionics', component: <AvionicContent techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug}/> },
			{tabTitle: 'Interior',  component: <InteriorContent techSpecsLoader={techSpecsLoader} manufacturerList={manufacturerList} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },
			{tabTitle: "STCs & Mods",  component: <STCMods techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },
			{tabTitle: 'Additional Remarks',   component: <RemarksCont techSpecsLoader={techSpecsLoader} asset_slug = {this.props.params.aircraft_slug} assetId={this.props.assetId}/> },

		]
		return (
			<div>
				<Row className="generate-finance-heading flex-centered tech-summary-heading" style={{marginLeft: '40px', marginBottom:'40px'}}>
					{techSpecsLoader ? <ListLoader />:null}
					{
							<Col md="6">
									<div style={{width:'30px',float:'left'}}>
										<Link onClick={() => browserHistory.goBack()}><img src={imgStoragePath+"back_arw.png"} width="20" alt="img" /></Link>
									</div>
									<h5 className="para-ui">Technical Specification</h5>

								 	{this.props.params.type=='aircraft' && Object.keys(overviewTechInfo).length ?
									<h2 className="beta"> <span style={{ color : '#000000' }}>{Object.keys(overviewTechInfo).length  ? 'MSN ' + overviewTechInfo.msn :'--' }</span>
										<span className="ser-reg-number">
											{Object.keys(overviewTechInfo).length && overviewTechInfo.aircraft_type ?
												overviewTechInfo.aircraft_type.name + (overviewTechInfo.model ? '-'+overviewTechInfo.model:'')
												:'--'
											}
										</span>
										<span onClick={() => this.props.exportTechSummery(overviewTechInfo,'aircraft')} style={{paddingLeft: '16px', cursor: 'pointer', color: '#3f51b5', fontSize: '12px'}}><img width="15" src={exportIcon} alt="export" />Export</span>
										<span onClick={() => this.props.exportMarkTechSummery(overviewTechInfo)} style={{paddingLeft: '16px', cursor: 'pointer', color: '#3f51b5', fontSize: '12px'}}><img width="15" src={exportIcon} alt="export" />Marketing Summary</span>

										</h2> : ['engine','hpt','lpt','hpc','fan'].includes(this.props.params.type) && Object.keys(engineDetails).length ?
										<h2 className="beta" > <span style={{ color : '#000000' }}>{Object.keys(engineDetails).length  ? 'ESN ' + engineDetails.esn :'--' } </span>
										<span className="ser-reg-number">
											{Object.keys(engineDetails).length && engineDetails.engine_type ?
												engineDetails.engine_type.name
												:'--'
											}
										</span>
										<span onClick={() => this.props.exportTechSummery(engineDetails,this.props.params.type)} style={{paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '12px'}}><img width="15" src={imgStoragePath+"export_icon.png"} alt="export" />Export</span>

									</h2> : Object.keys(apuDetail).length ?
										<h2 className="beta" > <span style={{ color : '#000000' }}>{Object.keys(apuDetail).length  ? `${apuDetail.asset_type == 3 ? 'APU ' : apuDetail.asset_type == 4 ? 'LG ' : 'PROPELLER '} ${apuDetail.serial_number}` :'--' } </span>
										<span className="ser-reg-number">
											{Object.keys(apuDetail).length && apuDetail.apu_type ?
												apuDetail.apu_type.name
												:''
											}
										</span>
										<span onClick={() => this.props.exportTechSummery(apuDetail,apuDetail.asset_type == 3 ? 'apu' : apuDetail.asset_type == 4 ? 'lg' : 'propeller')} style={{paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '12px'}}><img width="15" src={imgStoragePath+"export_icon.png"} alt="export" />Export</span>

								</h2> : null }




							</Col>

					}
					<Col md="6" className="hidden-xs">
						{Object.keys(overviewTechInfo).length && Object.keys(overviewTechInfo.contract).length ?
							<ContractLeaseInfo leaseInfo={overviewTechInfo.contract} />
							:null
						}
					</Col>
				</Row>
				{
					this.props.params.type == 'aircraft'  ?
					<div className="edit-view-tech-spec" style={{marginRight: '15px', marginLeft: '70px'}}>
					<Nav tabs>
						{tabsInfo.map((tab,index) =>
							<NavItem key={index}>
								<NavLink
									className={classnames({ active: this.state.activeTab === (index+1).toString() })}
									onClick={() => { this.toggle((index+1).toString()); }}
									>
									{tab.tabTitle}{tab.newFeature}
									{
					          tab.newFeature && <sup style={{fontWeight: 'bold'}}> NEW</sup>
					        }
								</NavLink>
							</NavItem>
						)}
						<span onClick={this.RevHistory} style={{ paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '14px', position:'absolute', right:'40px' }}><img width="15" src={imgStoragePath + "audit_icon.png"} alt="export"  style={{marginRight:'3px'}}/>Revision History</span>
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						{tabsInfo.map((tab,index) =>
							<TabPane key={index} tabId={(index+1).toString()} className="general-block">
								{tab.component}
							</TabPane>
						)}
					</TabContent>
				</div>
				:['engine','hpt','lpt','hpc','fan'].includes(this.props.params.type) ?
				<div className="edit-view-tech-spec" style={{marginLeft: '70px',}}>
					<span onClick={this.RevHistory} style={{ paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '14px',position:'absolute',top:"130px",right:'36px'}}><img width="15" src={imgStoragePath + "audit_icon.png"} alt="export"  style={{marginRight:'3px'}}/>Revision History</span>
						<EngineDetails toggleTransfer={() => this.toggleTransfer(true)}/>
				</div>
				:this.props.params.type == 'apu' ?
				<Fragment>
					<span onClick={this.RevHistory} style={{ paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '14px', position:'absolute',top:'130px' ,right:'36px' }}><img width="15" src={imgStoragePath + "audit_icon.png"} alt="export"  style={{marginRight:'3px'}}/>Revision History</span>
					<APUDetails techSpecsLoader={this.props.techSpecsLoader}/>
				</Fragment>
				 :
				 <Fragment>
					 <span onClick={this.RevHistory} style={{ paddingLeft: '16px', cursor: 'pointer', color: '#2f8cff', fontSize: '14px', position:'absolute',top:'130px', right:'36px' }}><img width="15" src={imgStoragePath + "audit_icon.png"} alt="export"  style={{marginRight:'3px'}}/>Revision History</span>
					 <LGDetails techSpecsLoader={this.props.techSpecsLoader} type={this.props.params.type}/>
				 </Fragment>

				}
				<Modal isOpen={assetTransferModal} toggle={() => this.props.toggleTransfer(false)} className="transfer-modal">
					<ModalBody>
						<Row className="transfer-modal-body">
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item"><img src={`${imgStoragePath}warning.png`}/></li>
									<li className="list-inline-item"><h2>Transfer {overviewTechInfo.msn ? `MSN ${overviewTechInfo.msn}` : `ESN ${overviewTechInfo.esn}`}</h2></li>
								</ul>
							</Col>
							<Row className="apps-container">
								<Col md="12">
									<h4>Select Applications To Transfer</h4>
								</Col>
								{
									overviewTechInfo.is_contract_enabled || overviewTechInfo.is_maintenance_enabled ?
									<Col md="5" className="apps-name" onClick={() => this.updateTransferObj('is_contract_and_maintenance', !this.state.transferAssetObj.is_contract_and_maintenance)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_contract_and_maintenance}/> <span>Contracts & Maintenance</span>
									</Col>
									:null
								}
								{
									overviewTechInfo.is_records_enabled ?
									<Col md="2" className="apps-name" onClick={() => this.updateTransferObj('is_records', !this.state.transferAssetObj.is_records)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_records}/> <span>Records</span>
									</Col>
									:null
								}
								{
									overviewTechInfo.is_technical_enabled ?
									<Col md="3" className="apps-name" onClick={() => this.updateTransferObj('is_technical', !this.state.transferAssetObj.is_technical)}>
										<input type="checkbox" checked={this.state.transferAssetObj.is_technical}/> <span>Technical</span>
									</Col>
									:null
								}
								<Col md="12">
									<p>
										You can transfer complete or partial asset data to other lessor account on SPARTA platform. Get started by downloading transfer key.
									</p>
								</Col>
							</Row>
						</Row>
						<Row className="transfer-modal-footer">
							<Col md="12">
								<ul className="list-inline">
									<li className="list-inline-item">
										<button type="button" disabled={this.state.transferAssetObj.is_contract_and_maintenance == false && this.state.transferAssetObj.is_records == false && this.state.transferAssetObj.is_technical == false ? true : false} onClick={() => this.props.transferAsset(this.state.transferAssetObj, overviewTechInfo)} className="btn-secondary">Download Transfer Key</button>
									</li>
									<li className="list-inline-item">
										<Link onClick={() => this.props.toggleTransfer(false)}>Cancel</Link>
									</li>
								</ul>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
				{
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="8"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
				{
					techSpecsLoader ? <ListLoader /> :
					<EditSidebar
						title={
								<h6>Revision History
									<span style={{fontSize:'12px',marginLeft: "20px",color: "#4a90e2", cursor:'pointer'}} onClick={() =>this.props.exportRevisionHistory(assetId) }><img src={imgStoragePath+"export_icon.png"} alt="export" style={{width:'12px',position:'absolute', left:'108px',}} />Export</span>
								</h6>
						}
						editSideBar={this.state.addRevHistory}
						toggleEditSideBar={() => this.setState({ addRevHistory: false })}
						isOpen={this.state.addRevHistory}

					>
						<div>
							<div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '20px', padding: '5px 22px 5px 25px' }}>
								{
									revisionHistoryList.length ? revisionHistoryList.map((revision, index) =>
										<UpdateAuditLog auditLogView={revision} key={index} />
								 ): <NoRecordFound />
								}
							</div>
						</div>
					</EditSidebar>
				}

			</div>
		);
	}
}
const mapStateToProps = state => ({
	overviewTechInfo: state.TechSummaryReducer.overviewTechInfo,
	apuDetail: state.TechSummaryReducer.apuDetail,
	techConstants: state.sharedReducers.techConstants,
	techSpecsLoader: state.TechSummaryReducer.techSpecsLoader,
	assetTransferModal: state.TechSummaryReducer.assetTransferModal,
  engineDetails: state.TechSummaryReducer.engineDetails,
  assetTransferUsage: state.TechSummaryReducer.assetTransferUsage,
	buyLicenceModal: state.sharedReducers.buyLicenceModal,
	revisionHistoryList: state.TechSummaryReducer.revisionHistoryList
});
const mapDispatchToProps = (dispatch, ownProps)  => {
	return {
		exportTechSummery: (details,type) => dispatch(exportTechSummeryAc(ownProps,details,type)),
		exportMarkTechSummery: (aircraft) => dispatch(exportMarkeTechSummeryAc(ownProps,aircraft)),
		fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
		fetchOverviewList: (asset_slug) => dispatch(overviewListAc(asset_slug)),
		fetchRevisionHistory:(asset_id) => dispatch(getTechspecRevisionHistoryAc(ownProps, asset_id)),
		exportRevisionHistory:(asset_id) => dispatch(exportRevisionHistoryAc(ownProps, asset_id)),
		emptyLeaseInfo:() => dispatch({
			type: OVERVIEW_TECH_INFO,
			payload:{}
		}),
		toggleTransfer: (flag) => dispatch({
			type: ASSET_TRANSFER_MODAL,
			payload: flag
		}),
		getAssetTranferUsage: () => dispatch(getAssetTranferUsageAc(ownProps.params)),
		buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
		transferAsset: (applications, asset) => dispatch(transferAssetAc(ownProps.params, applications, asset))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(AircraftTechSummary, ['technical_specs','overview','R']));


// revisionHistoryList.data.map((item, index) =>
// 	<UpdateAuditLog auditLogView={item} key={index} />
// )
