import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addMemberObj = {
  first_name: '',
  last_name: '',
  email:'',
  mobile: '',
  designation: '',
  remarks: ''
}
export const memberHd = [
  { label: 'Name(First & Last)', sortOption: false },
  { label: 'Designation', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Mobile', sortOption: false },
  { label: 'Remarks', sortOption: false },
  { label: 'Action', sortOption: false },
];
export const errorCode = {
  name: {
    0: '',
    1: 'Please enter Group Name',
    4: errorMsg1
  },
  nameObj: {
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  first_name: {
    0: '',
    1: 'Pease enter First Name',
    4: errorMsg1
  },
  first_nameObj: {
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  last_name: {
    0: '',
    4: errorMsg1
  },
  last_nameObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  email: {
    0: '',
    1: 'Pease enter Email Address',
    4: 'Please enter valid Email Address'
  },
  emailObj: {
    required: true,
    regexPattern: regexConstants.email
  },
  mobile: {
    0: '',
    4: 'Please enter valid Mobile No.'
  },
  mobileObj: {
    regexPattern: regexConstants.phoneNumber
  },
  designation: {
    0: '',
    4: errorMsg1
  },
  designationObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
}
