import React, { Component } from 'react';
class StorageListing extends Component{
    render(){
        return(
            <div className="billing-container storage-container">
                
            </div>
        )
    }
}



export default StorageListing;
