import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router'
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {Tabs, Tab, Paper, Button, TextField, Grid} from '@material-ui/core';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { UtilizationSummary, UtilsTrendsTab, UtilizationTrends, MRUtilList, RentalUtilList, AddEditRentalUtils } from '../components'
import { TableListComp, EmptyCollection, PageLoader, ExportManu, FilterUIComp } from '../../../shared_elements';
import { removeEmptyKey, convertFilterObject } from '../../../utils_v2';
import { fieldDateFormat, assetsType } from '../../../constants';
import { sExportContractsAc } from '../../actionCreators';
import { getMajorAssembliesApi, getUtilizationTrendsApi, getAvgUtilizationApi, getMRUtilizationListApi, getRentalUtilizationListApi, exportRentalUtilizationApi, exportMrUtilizationApi, exportAvgUtilizationApi } from '../apiServices';
import { rentalUtilsHd, mrUtilsHd, mrFilterOption, rentalFilterOption } from '../'
class AssetUtilization extends Component {
  constructor(props){
    super(props);
    this.state = {
      utilType:'mr',
      primaryTab:this.props.params.type === 'aircraft'?'airframe':this.props.params.type,
      secondaryTab:0,
      majorAssemblies:[],
      utlisAverage:[],
      utilsTrends:{},
      mrUtils:{
        pagination:{},
        utilization:[]
      },
      rentalUtilis:{
        pagination:{},
        rental_months:[],
        utilization:[]
      },
      mrFilter:{},
      mrapplyingFilter:{},
      rentalFilter:{},
      rentalapplyingFilter:{}
    }
    this.getMajorAssembliesApi = getMajorAssembliesApi.bind(this);
    this.getUtilizationTrendsApi = getUtilizationTrendsApi.bind(this);
    this.getAvgUtilizationApi = getAvgUtilizationApi.bind(this);
    this.getMRUtilizationListApi = getMRUtilizationListApi.bind(this);
    this.getRentalUtilizationListApi = getRentalUtilizationListApi.bind(this);
    this.exportRentalUtilizationApi = exportRentalUtilizationApi.bind(this);
    this.exportMrUtilizationApi = exportMrUtilizationApi.bind(this);
    this.exportAvgUtilizationApi = exportAvgUtilizationApi.bind(this);
  }
  componentDidMount(){
    this.getMajorAssembliesApi(this.props);
    this.getUtilizationTrendsApi(this.props);
    this.getAvgUtilizationApi(this.props);
    this.getMRUtilizationListApi(this.props, {});
    this.getRentalUtilizationListApi(this.props, {});
  }
  onTabChange = (event, newValue, tabType) => {
    if(tabType === 'secondaryTab'){
      this.setState({secondaryTab:newValue});
    }else{
      this.setState({primaryTab:newValue});
    }
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  mrremoveFilter = (keyParam) => {
    const { mrFilter, mrapplyingFilter } = this.state;
    let query = Object.assign({}, mrFilter);
    delete query[keyParam];
    this.getMRUtilizationListApi(this.props, {...query}, 'pageLoader');
    this.setState({mrapplyingFilter: delete mrapplyingFilter[keyParam]})
  }
  mrapplyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        mrapplyingFilter: {
          ...prevState.mrapplyingFilter,
          [keyParam]: {
            ...prevState.mrapplyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        mrapplyingFilter: {
          ...prevState.mrapplyingFilter,
          [keyParam]: value
        }
      }))
    }

  }
  rentalremoveFilter = (keyParam) => {
    const { rentalFilter, rentalapplyingFilter } = this.state;
    let query = Object.assign({}, rentalFilter);
    delete query[keyParam];
    this.getRentalUtilizationListApi(this.props, {...query}, 'pageLoader');
    this.setState({rentalapplyingFilter: delete rentalapplyingFilter[keyParam]})
  }
  rentalapplyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        rentalapplyingFilter: {
          ...prevState.rentalapplyingFilter,
          [keyParam]: {
            ...prevState.rentalapplyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        rentalapplyingFilter: {
          ...prevState.rentalapplyingFilter,
          [keyParam]: value
        }
      }))
    }

  }
  render(){
    const { utilType, primaryTab, secondaryTab, mrFilter, rentalFilter, mrapplyingFilter, rentalapplyingFilter, majorAssemblies, utlisAverage, utilsTrends, rentalUtilis, mrUtils } = this.state;
    const { params } = this.props;
    let filterOptions = Object.assign({}, mrFilterOption);
    filterOptions = {
      ...filterOptions,
      unique_asset: {
        ...filterOptions.unique_asset,
        options: majorAssemblies.map(asset => {return{...asset, unique_asset:`${asset.asset_id}_${asset.asset_type}`}})
      }
    }
    return(
      <div className="contract-container">
        <ContractSideBar application={`Utilization`} type="brief" params={this.props.params} />
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <div className="contract-content-blk">
          <h4 className="utils-trend-head">Utilization Trends</h4>
          { Object.keys(utilsTrends).length && utlisAverage.length &&
            <Paper className="utils-trend-card">
              <UtilsTrendsTab primaryTab={primaryTab} onTabChange={this.onTabChange} utilsTrends={utilsTrends} />
              <UtilizationTrends utlisAverage={utlisAverage} primaryTab={primaryTab} secondaryTab={secondaryTab} onTabChange={this.onTabChange}  utilsTrends={utilsTrends} />
            </Paper>
          }
          <div className="utils-trend-card">
            <ul className="list-inline">
              <li className="list-inline-item utils-trend-head">Utilization Summary</li>
              <li className="list-inline-item">
                <ExportManu
                  title="Export"
                  files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'DOC', extension: 'doc', key:''},{title:'CSV', extension: 'csv', key:''}]}
                  exportReportFn={(file) => this.exportAvgUtilizationApi(this.props, {download:file.extension,data:'utilization_summary'})}
                />
              </li>
            </ul>
            <UtilizationSummary secondaryTab={secondaryTab} utlisAverage={utlisAverage} />
          </div>
          <div className="mr-rental-ulilization-tabs" style={{paddingBottom:'20px'}}>
            <Tabs
              value={utilType}
              onChange={(event, newValue) => this.setState({utilType:newValue})}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant={'fullWidth'}
            >
              <Tab label="MR Utilization" value="mr" />
              <Tab label="Rental Utilization" value="rental" />
            </Tabs>
            { utilType === 'mr' &&
              <div>
                <ul className="list-inline" style={{float:'right', padding:'10px 0'}}>
                  <li className="list-inline-item">
                    <ExportManu
                      title="Export"
                      files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'DOC', extension: 'doc', key:''},{title:'CSV', extension: 'csv', key:''}]}
                      exportReportFn={(file) => this.exportMrUtilizationApi(this.props, {download:file.extension,data:'utilization_history', ...mrFilter})}
                    />
                  </li>
                  <li className="list-inline-item">
                    <Button onClick={() => browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/utilization/add`)} color="primary" variant="contained">Add Utilization</Button>
                  </li>
                </ul>
                <div>
                  <FilterUIComp
                    filter={mrFilter}
                    applyingFilter={mrapplyingFilter}
                    removeFilter={this.mrremoveFilter}
                    removeAllFilter={() => { this.setState({ mrapplyingFilter: {} }); this.getMRUtilizationListApi(this.props, { per_page: mrUtils.pagination.per_page }, 'pageLoader') }}
                    submitFilter={() => this.getMRUtilizationListApi(this.props, { ...convertFilterObject(removeEmptyKey(mrapplyingFilter), filterOptions), per_page: mrUtils.pagination.per_page }, 'pageLoader')}
                    applyFilter={this.mrapplyFilter}
                    filterMenu={filterOptions}
                  />
                </div>
                <TableListComp
                  heads={mrUtilsHd}
                  data={mrUtils.utilization.map((item,index) =>
                    <MRUtilList
                      key={index}
                      item={item}
                    />
                  )}
                  noRecord={ mrUtils.utilization.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                  pagination={mrUtils.pagination}
                  onChangePage={(event, newPage) => {this.getMRUtilizationListApi(this.props, {page:newPage+1, per_page: mrUtils.pagination.per_page, ...removeEmptyKey(mrFilter)}, 'pageLoader')}}
                  onChangeRowsPerPage={(event) => this.getMRUtilizationListApi(this.props, {page:1, per_page: event.target.value, ...removeEmptyKey(mrFilter)}, 'pageLoader')}
                />
              </div>
            }
            { utilType === 'rental' &&
              <div>
                <ul className="list-inline" style={{float:'right', padding:'10px 0'}}>
                  <li className="list-inline-item">
                    <ExportManu
                      title="Export"
                      files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'DOC', extension: 'doc', key:''},{title:'CSV', extension: 'csv', key:''}]}
                      exportReportFn={(file) => this.exportRentalUtilizationApi(this.props, {download:file.extension,data:'utilization_history', ...rentalFilter})}
                    />
                  </li>
                  <li className="list-inline-item">
                    <AddEditRentalUtils m="A" rentalUtilis={rentalUtilis} />
                  </li>
                </ul>
                <div>
                  <FilterUIComp
                    filter={rentalFilter}
                    applyingFilter={rentalapplyingFilter}
                    removeFilter={this.rentalremoveFilter}
                    removeAllFilter={() => { this.setState({ rentalapplyingFilter: {} }); this.getRentalUtilizationListApi(this.props, { per_page: rentalUtilis.pagination.per_page }, 'pageLoader') }}
                    submitFilter={() => this.getRentalUtilizationListApi(this.props, { ...convertFilterObject(removeEmptyKey(rentalapplyingFilter), rentalFilterOption), per_page: rentalUtilis.pagination.per_page }, 'pageLoader')}
                    applyFilter={this.rentalapplyFilter}
                    filterMenu={rentalFilterOption}
                  />
                </div>
                <TableListComp
                  heads={rentalUtilsHd}
                  data={rentalUtilis.utilization.map((item,index) =>
                    <RentalUtilList
                      key={index}
                      item={item}
                      rentalUtilis={rentalUtilis}
                    />
                  )}
                  noRecord={ rentalUtilis.utilization.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                  pagination={rentalUtilis.pagination}
                  onChangePage={(event, newPage) => {this.getRentalUtilizationListApi(this.props, {page:newPage+1, per_page: rentalUtilis.pagination.per_page, ...removeEmptyKey(rentalFilter)}, 'pageLoader')}}
                  onChangeRowsPerPage={(event) => this.getRentalUtilizationListApi(this.props, {page:1, per_page: event.target.value, ...removeEmptyKey(rentalFilter)}, 'pageLoader')}
                />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.contractReducer.contractSummaryLoader
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(AssetUtilization));
