import React, { useEffect, useState } from 'react'
import { Header, ContractHeadertext, ContractSideBar } from '../../../applications/contracts/Elements';
import { ContractWrapper } from '../../Elements';
import { assetId, assetsType } from '../../../constants';
import { browserHistory } from 'react-router';
import { globalExportService, globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus, toastFlashMessage, downloadFileType, getLocalStorageInfo, checkPermission } from '../../../utils_v2';
import { PageLoader, TableListComp, EmptyCollection } from '../../../shared_elements';
import { assemblyListHd, utilsFilterOps, utlisListHd } from '..';
import { checkString, checkValidDate } from '../../../formValidator';
import UtilizationListing from '../components/UtilizationListing';
import { FilterComponent } from '../../../shared_elements';
import { Button, Grid, Paper } from '@material-ui/core';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const AssmblyUtilization = (props) => {
    const [pageLoader, setPageLoader] = useState(false)
    const [leaseLoader, setLeaseLoader] = useState(false)
    const [utilsList, setUtilsList] = useState([])
    const [filter, setFilter] = useState({})
    const [assemblyLeaseDetails, setAssemblyLeaseDetails] = useState({})
    useEffect(() => {
        getUtilsListFn()
        getAssemblyLeaseDetailsFn()
    }, [])

    const exporyUtilsListFn = (query) => {
        setPageLoader(true)
        globalExportService(`contracts/assembly-lease/${props.params.slug}/assembly-utilization/`, { ...query, download: 'xlsx' })
        .then(response => {
                setPageLoader(false)
                downloadFileType(response, `Utlization_List`, 'xlsx')
            })
    }
    const getAssemblyLeaseDetailsFn = (data) => {
        setLeaseLoader(true)
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/${props.params?.slug}`)
            .then(response => {
                setLeaseLoader(false)
                if (checkApiStatus(response)) {
                    setAssemblyLeaseDetails(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getUtilsListFn = (query) => {
        setPageLoader(true)
        globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-utilization/`, query)
            .then(response => {
                delete query?.page;
                delete query?.per_page;
                if (query) {
                    setFilter(query)
                }
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setUtilsList(response.data.data)
                }
            })
    }
    const redirectToDetails = (data) => {

        // browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/assembly-details/${data.slug}`)
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }
    let filterOptions = utilsFilterOps;
    let unlinkPermission = checkPermission('assembly_lease', 'utilization','U');
    return (
        <div className="contract-container" style={{height:'auto'}}>
             { unlinkPermission && assemblyLeaseDetails?.assembly_lease_expired ?
                <p className="assembly-past-mode" style={{marginLeft:'56px', width:"94%", marginBottom:'20px'}}> Please unlink the major assembly as the assembly lease has been expired </p> : null
            }
            { leaseLoader|| pageLoader ? <PageLoader /> : null }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} assemblytype={'assemblytype'} params={props.params} />
            <div className="contract-content-blk">
                <Paper >
                    <Grid container spacing={2} style={{ padding: "4px 10px" }}>
                        <Grid item sm='11' >
                            <FilterComponent
                                filter={filter}
                                filterMenu={filterOptions}
                                getResponseBack={(applyFilter) => { getUtilsListFn({ ...applyFilter, page: 1, per_page: utilsList.pagination.per_page }, 'pageLoader'); }}
                            />
                        </Grid>
                        <Grid item sm='1'>
                            { checkPermission('assembly_lease','utilization','EXP') ?
                                <Button disabled={utilsList?.list?.length ? false : true} style={{ marginBottom: '8px' }} size='small' variant='contained' color='primary' onClick={() => exporyUtilsListFn(filter)}>
                                    Export
                                </Button>:null
                            }
                        </Grid>
                    </Grid>
                    <TableListComp
                        heads={utlisListHd}
                        data={utilsList?.list?.map((item, index) =>
                            <UtilizationListing
                                redirectToDetails={redirectToDetails}
                                item={item}
                            />
                        )}
                        onChangePage={(event, newPage) => getUtilsListFn({ ...filter, page: newPage + 1, per_page: utilsList.pagination.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getUtilsListFn({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                        pagination={utilsList.pagination}
                        noRecord={utilsList?.list?.length ? null :
                            pageLoader ? null :
                                <EmptyCollection
                                    title="No records found"
                                />
                        }
                    />
                </Paper>
            </div>
        </div>
    )
}
export default RoleBasedAccess(AssmblyUtilization, ['assembly_lease','utilization','R']);