
import React from 'react'
import { TableRow, TableCell, Tooltip, Chip } from '@material-ui/core';
import moment from 'moment';
import { Delete, Edit } from '@material-ui/icons';
import { checkPermission } from '../../../../utils_v2';

export default function SupplementList({ item, setEditModal, setDeleteDetailsmodal, handleDelete, setDeleteModal, previewDoc }) {
    return (
        <TableRow style={{ padding: '4px 0px' }}  >
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.date ? moment(item?.date).format('MMM DD, YYYY') : '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.unique_identifier || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.executed_by || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.attachments?.length && item?.attachments?.map(i =>
                    checkPermission('contracts', 'lease_details', 'U') ?
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                            <Chip
                                style={{ marginRight: '4px', marginTop: '7px' }}
                                variant="outlined"
                                size="small"
                                onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                label={i.name.substring(0, 14) + '...'}
                                clickable
                                color="primary"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                            />
                        </Tooltip>
                        :
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                            <Chip
                                style={{ marginRight: '4px', marginTop: '7px' }}
                                variant="outlined"
                                size="small"
                                // onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                label={i.name.substring(0, 14) + '...'}
                                clickable
                                color="primary"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                            />
                        </Tooltip>
                )}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }}>
                {
                    checkPermission('contracts', 'lease_details', 'U') ?
                        <Edit color='primary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setEditModal(item)} />
                        : null}
                {
                    checkPermission('contracts', 'lease_details', 'D') ?
                        <Delete color='secondary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setDeleteDetailsmodal({ flag: true, data: item, type: 'supplement' })} />
                        : null}
            </TableCell>
        </TableRow>
    )
}