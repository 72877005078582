import React from 'react';
import { Grid } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
const CashflowProjection = ({}) => {
  return(
    <div></div>
  )
}
export default CashflowProjection;
