import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router';
import { Grid, Container } from '@material-ui/core';
import { RevisionHistory } from '../../shared_elements';
import backArrowIcon from '../../shared_elements/assets/img/back_arrow.svg';
import config from '../../config';
const ContractWrapper = ({ title, description, revision, url, queryParams, buttonText, handleClickEventFn, children, match, addPermissionFlag, params, hideArrowBack, linkToRecords, records_uuid, disabled_contracts}) => {
  return (
    <Fragment>
      {(title || description || revision || buttonText) ?
        <Container maxWidth={false} style={{ padding: '0px 0 15px 0px' }}>
          <Grid container spacing={0} alignItems="center">
           { <Grid item xs={12} md={6}>
              {!disabled_contracts ?
                <div className="apps-title-cta">
                  {title ? <h4>{title}</h4> : null}
                  {description ? <p>{description}</p> : null}
                  {linkToRecords  ? <Link href={`${config.domain.records}${params.type}/${params.aircraft_slug}/data-room/contract/${records_uuid}`} target="_blank">Link to View Lease Documents</Link> : null}
                </div> : null
              }
            </Grid>}
            <Grid item xs={12} md={6}>

              <div className="apps-title-cta-list">
                <ul className="list-inline flex-centered">
                  {revision ?
                    <li className="list-inline-item revision-history">
                      <RevisionHistory
                        url={url}
                        queryParams={queryParams}
                        buttonType={false}
                      />
                    </li> : null
                  }
                  {buttonText ?
                    <li className="list-inline-item">
                      {buttonText}
                    </li> : null
                  }
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container> : null
      }
      <div className="contracts-wrapper-section">{children}</div>
    </Fragment>
  )
}
export default withRouter(ContractWrapper)
