import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { TableRow, TableCell } from '@material-ui/core';
import { invoiceTypes, invoiceStatusDrpdwn, displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat, dateTransform } from '../../../utils_v2';
const InvoiceList = ({item}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.invoice_number}</TableCell>
      <TableCell>{item.invoice_type}</TableCell>
      <TableCell>{ moment(item.start_date).format(displayDateFormatShort)} to {moment(item.end_date).format(displayDateFormatShort)}</TableCell>
      <TableCell>{ moment(item.date).format(displayDateFormatShort) }</TableCell>
      <TableCell>{ moment(item.due_date).format(displayDateFormatShort) }</TableCell>
      <TableCell className={item.in_due && item.status === 2 ? 'in-due':''}>
        {item.total_amount ?
          item.invoice_type === 2 ?
          item.children.length > 0 ?
          <div>
            <div>
              <NumericLabel params={getCurrencyFormat(item.currency)}>{item.total_amount}</NumericLabel>
            </div>
            <NumericLabel params={getCurrencyFormat(item.children[0].currency)}>{item.children[0].sub_total}</NumericLabel>
          </div>:
          <NumericLabel params={getCurrencyFormat(item.currency)}>{item.total_amount}</NumericLabel>:
          <NumericLabel params={getCurrencyFormat(item.currency)}>{item.total_amount}</NumericLabel>:
          '--'
        }
      </TableCell>
      <TableCell>
        {

        }
      </TableCell>
    </TableRow>
  )
}
export default InvoiceList;
