import * as actions from './actions';
export default function( state = {
  contractClauseList: [],
  reserveAccounts: [],
  returnCondList: [],
  sLesseeList: [],
  contractSummaryLoader:false,
}, action){
  switch (action.type) {
    case actions.CLAUSE_LIST:
      return {
        ...state,
        contractClauseList: action.payload
      }
      case actions.LESSEE_LIST:
      return {
        ...state,
        sLesseeList: action.payload
      }
    case actions.RESERVE_ACCOUNTS:
      return {
        ...state,
        reserveAccounts: action.payload
      }
    case actions.CONTRACT_EXPORT_LOADER:
      return {
        ...state,
        contractSummaryLoader: action.payload
      }
      case actions.RETURN_COND_LIST:
        return {
          ...state,
          returnCondList: action.payload
        }
    default:
      return state;
  }
}
