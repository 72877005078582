import React, { Fragment, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip } from '@material-ui/core';
import { checkPermission, getLocalStorageInfo } from '../../../utils_v2';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
export default function InvoiceCRU({ modal, setModal, invoiceTypeList, validationMsgs, onInvoiceChange,setValidationMsgs, invoiceDetails,setInvoiceDetails, invoiceOtherInfo, invoiceValidateInfo, addInvoiceDetails, updateErrorField }) {
    return (
        <Fragment>

            <div style={{ textAlign: 'right', marginBottom: '10px', marginTop:'10px' }}>
                {checkPermission('assembly_lease','invoice','C') ?
                    <Button variant="contained" onClick={() => { setModal(true); setInvoiceDetails({}); setValidationMsgs({}) }} color="primary"><AddIcon fontSize='small' />Generate Invoice</Button> : null
                }
            </div>
            {
                modal ?
                    <Dialog
                        open='true'
                        maxWidth='lg'
                        className='assembly-lease-modal'
                        onClose={() => setModal(false)}
                    >
                        <DialogTitle dividers={true} >
                            Add Assembly Lease Invoice
                        </DialogTitle>
                        <DialogContent dividers={true} style={ invoiceDetails?.invoice_type ? { minWidth: "1100px" } :{ minWidth: "900px" }}>
                            <Grid container spacing='2'>
                                <Grid item sm='3' xs='12'>
                                    <Autocomplete
                                        style={{ marginTop: '16px' }}
                                        options={invoiceTypeList.filter(item => item.value != 3)}
                                        getOptionLabel={option => option.label}
                                        value={invoiceDetails?.invoice_type}
                                        id="invoice_type"
                                        onChange={(e, value) => onInvoiceChange('invoice_type', value)}
                                        renderInput={params => <TextField required error={validationMsgs.invoice_type ? true : false} helperText={validationMsgs?.invoice_type ? validationMsgs?.invoice_type : ''} onFocus={() => updateErrorField('invoice_type')} {...params} label="Invoice Type" placeholder="Select Invoice Type" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                    />
                                </Grid>
                                {
                                    invoiceDetails?.invoice_type?.value == 1 ?
                                        <>
                                            <Grid item sm='3' xs='12'>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        required
                                                        placeholder='DD-MM-YYYY'
                                                        style={{ position: "relative", top: '16px' }}
                                                        inputVariant="outlined"
                                                        variant='outlined'
                                                        margin="none"
                                                        id="start_date"
                                                        error={validationMsgs.start_date ? true : false}
                                                        helperText={validationMsgs.start_date ? validationMsgs.start_date : ''}
                                                        onFocus={() => updateErrorField('start_date')}
                                                        maxDate={invoiceValidateInfo?.end_date ? invoiceValidateInfo?.end_date : ''}
                                                        minDate={invoiceValidateInfo?.start_date ? invoiceValidateInfo?.start_date : ''}
                                                        label=" Invoice Start Date"
                                                        value={invoiceDetails?.start_date ? invoiceDetails?.start_date : null}
                                                        format={fieldDateFormat}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(data, value) => { onInvoiceChange('start_date', moment(data).format(backendDateFormat)) }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item sm='3' xs='12'>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <KeyboardDatePicker
                                                        required
                                                        placeholder='DD-MM-YYYY'
                                                        style={{ position: "relative", top: '16px' }}
                                                        inputVariant="outlined"
                                                        variant='outlined'
                                                        margin="none"
                                                        id="end_date"
                                                        error={validationMsgs.end_date ? true : false}
                                                        helperText={validationMsgs.end_date ? validationMsgs.end_date : ''}
                                                        onFocus={() => updateErrorField('end_date')}
                                                        minDate={invoiceDetails?.start_date? invoiceDetails?.start_date : invoiceValidateInfo?.start_date ? invoiceValidateInfo?.start_date : ''}
                                                        maxDate={invoiceValidateInfo?.end_date ? invoiceValidateInfo?.end_date : ''}
                                                        label="Invoice End Date"
                                                        value={invoiceDetails?.end_date ? invoiceDetails?.end_date : null}
                                                        format={fieldDateFormat}
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={(data, value) => { onInvoiceChange('end_date', moment(data).format(backendDateFormat)) }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </>
                                        :
                                        invoiceDetails?.invoice_type?.value == 2 ?
                                            <>
                                                <Grid item sm='3' xs='12'>
                                                    <Autocomplete
                                                        style={{ marginTop: '16px' }}
                                                        options={invoiceOtherInfo?.info}
                                                        getOptionLabel={option => moment(option).format('MMM YYYY')}
                                                        value={invoiceDetails?.month}
                                                        id="month"
                                                        onChange={(e, value) => onInvoiceChange('month', value)}
                                                        renderInput={params => <TextField required error={validationMsgs.month ? true : false} helperText={validationMsgs?.month ? validationMsgs?.month : ''} onFocus={() => updateErrorField('month')} {...params} label="Month" placeholder="Select Month" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                                    />
                                                </Grid>
                                            </>


                                            :

                                            invoiceDetails?.invoice_type?.value == 4 || invoiceDetails?.invoice_type?.value == 5 ?
                                                <>
                                                    <Grid item sm='3' xs='12'>
                                                        <Autocomplete
                                                            style={{ marginTop: '16px' }}
                                                            options={invoiceOtherInfo?.info}
                                                            getOptionLabel={option => option.invoice_number}
                                                            value={invoiceDetails?.month}
                                                            id="info"
                                                            onChange={(e, value) => onInvoiceChange('info', value)}
                                                            renderInput={params => <TextField required error={validationMsgs.info ? true : false} helperText={validationMsgs?.info ? validationMsgs?.info : ''} onFocus={() => updateErrorField('info')} {...params} label="Invoice Number" placeholder="Select Invoice Number" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                                        />
                                                    </Grid>
                                                </> :
                                                null}
                                {invoiceDetails?.invoice_type?.value ?
                                    <Grid item sm='3' xs='12'>
                                        <Autocomplete
                                            style={{ marginTop: '16px' }}
                                            options={invoiceOtherInfo?.bank_details}
                                            getOptionLabel={option => option.name + ' (' + option?.account_number + ')'}
                                            value={invoiceDetails?.bank_details}
                                            id="bank_details"
                                            onChange={(e, value) => onInvoiceChange('bank_details', value)}
                                            renderInput={params => <TextField required error={validationMsgs.bank_details ? true : false} helperText={validationMsgs?.bank_details ? validationMsgs?.bank_details : ''} onFocus={() => updateErrorField('bank_details')} {...params} label="Select Bank" placeholder="Select Bank name" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                        />
                                    </Grid> : null
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='primary' variant='outlined' size='small' onClick={() => setModal(false)} >
                                Cancel
                            </Button>
                            <Button color='primary' variant='contained' size='small' onClick={() => addInvoiceDetails()} >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null}
        </Fragment>
    )
}
