
import React, { useEffect, useState } from 'react'
import { Header } from '../../../applications/contracts/Elements';
import { assetId, assetsType, backendDateFormat } from '../../../constants';
import { browserHistory } from 'react-router';
import { ContractSideBar } from '../../../applications/contracts/Elements';
import { FilterComponent } from '../../../shared_elements';
import { PageLoader, TableListComp, EmptyCollection } from '../../../shared_elements';
import { Button, Grid, Paper } from '@material-ui/core';
import { globalGetService, globalPutService, globalPostService, globalExportService } from '../../../globalServices';
import { checkString, checkValidDate } from '../../../formValidator';
import { checkApiStatus, checkPermission, downloadFileType, getLocalStorageInfo, toastFlashMessage } from '../../../utils_v2';
import moment from 'moment';
import { cashflowListHd, CashflowFilterOps } from '..';
import CashflowList from '../components/CashflowList';
import CashflowTopCards from '../components/CashflowTopCards';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const AssemblyCashFlow = (props) => {
    const [pageLoader, setPageLoader] = useState(false)
    const [leaseLoader, setLeaseLoader] = useState(false)
    const [cashFlowList, setCashFlowList] = useState([])
    const [cashflowTransactiontypeList, setCashflowTransactiontypeList] = useState([])
    const [cashFlowWidgetList, setCashFlowWidgetList] = useState([])
    const [filter, setFilter] = useState({})
    const [assemblyLeaseDetails, setAssemblyLeaseDetails] = useState({})
    useEffect(() => {
        getCashflowTransactiontypeList()
        getCashflowListingApi()
        getAssemblyLeaseDetailsFn()
        getCashflowWidgetApi()
    }, [])
    const getCashflowListingApi = (query) => {
        setPageLoader(true)
        globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-cashflow/`, query)
            .then(response => {
                delete query?.page;
                delete query?.per_page;
                if (query) {
                    setFilter(query)
                }
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setCashFlowList(response.data.data)
                }
            })
    }
    const getAssemblyLeaseDetailsFn = (data) => {
        setLeaseLoader(true)
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/${props.params?.slug}`)
            .then(response => {
                setLeaseLoader(false)
                if (checkApiStatus(response)) {
                    setAssemblyLeaseDetails(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getCashflowTransactiontypeList = () => {
        setPageLoader(true)
        globalGetService(`console/get-constants/?constant_type=assembly_lease_invoice`)
            .then(response => {
                setPageLoader(false)
                setCashflowTransactiontypeList(response.data.data)
            })

    }
    const exportCashflowListingApi = (query) => {
        setPageLoader(true)
        globalExportService(`contracts/assembly-lease/${props.params.slug}/assembly-cashflow/?download=pdf`, query)
            .then(response => {
                setPageLoader(false)
                downloadFileType(response, `Cashflow_List`, 'pdf')
            })
    }
    const getCashflowWidgetApi = () => {
        globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-cashflow/financial-widget/`)
            .then(response => {
                // if (checkApiStatus(response)) {
                setCashFlowWidgetList(response.data.data)
                // }
            })
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }
    let filterOptions = CashflowFilterOps
    filterOptions = {
        ...filterOptions,
        transaction_type: {
            ...filterOptions.transaction_type,
            options: cashflowTransactiontypeList
        }
    }
    return (
        <div className="contract-container" style={{height:'auto'}}>
             {
               checkPermission('assembly_lease','cashflow','U') && assemblyLeaseDetails?.assembly_lease_expired ?
                <p className="assembly-past-mode" style={{marginLeft:'56px', width:"94%", marginBottom:"11px"}}> Please unlink the major assembly as the assembly lease has been expired </p> : null
            }
            {
                pageLoader || leaseLoader ? <PageLoader /> : null
            }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} assemblytype={'assemblytype'} params={props.params} />
            <div className="contract-content-blk">
                {
                     assemblyLeaseDetails?.assembly_swap?.value == 1 ?
                     <CashflowTopCards
                     cashflowWidget={cashFlowWidgetList}
                     />
                     :null
            }


                <Paper >
                    <Grid container spacing={2} style={{ padding: "4px 10px", marginTop: '3px' }}>
                        <Grid item sm='11' >
                            {
                                 assemblyLeaseDetails?.assembly_swap?.value == 0 ?
                                 null:
                            <FilterComponent
                            filter={filter}
                            filterMenu={filterOptions}
                            getResponseBack={(applyFilter) => { getCashflowListingApi({ ...applyFilter, page: 1, per_page: cashFlowList.pagination.per_page }, 'pageLoader'); }}
                            />
                        }
                        </Grid>
                        <Grid item sm='1'>
                            {checkPermission('assembly_lease','cashflow','EXP') ?
                                <Button disabled={cashFlowList?.list?.length ? false : true} style={{ marginBottom: '8px' }} size='small' variant='contained' color='primary' onClick={() => exportCashflowListingApi(filter)}>
                                    Export
                                </Button>:null
                            }
                            
                        </Grid>
                    </Grid>

                    <TableListComp
                        heads={cashflowListHd}
                        data={cashFlowList?.list?.map((item, index) =>
                            <CashflowList
                            props={props}
                                // redirectToDetails={redirectToDetails}
                                item={item}
                            />
                        )}
                        onChangePage={(event, newPage) => getCashflowListingApi({ ...filter, page: newPage + 1, per_page: cashFlowList.pagination.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getCashflowListingApi({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                        pagination={ assemblyLeaseDetails?.assembly_swap?.value == 0 ? null: cashFlowList.pagination}
                        noRecord={cashFlowList?.list?.length ? null :
                            pageLoader ? null :
                                <EmptyCollection
                                    title="No records found"
                                />
                        }

                    />
                </Paper>
            </div>
        </div>
    )
}

export default RoleBasedAccess(AssemblyCashFlow,['assembly_lease','cashflow','R']);