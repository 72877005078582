import React from 'react';
import { Grid, Button, Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const AppCard = ({item}) => {
    return(
        <Grid item md={4}>
            <div className={`application-card ${!item.access ? 'no-access-app':''}`} onClick={(e) => item.access ? window.open(item.url, '_blank').focus():e.preventDefault()}>
                {!item.access ? <Tooltip title='' arrow><LockIcon className='lock-icon' fontSize='small' color='primary' /></Tooltip>:null}
                <div className='app-title flex-centered'>
                    <img src={item.logo} alt={item.label}/>
                    <h4>
                        {item.label}
                        {item.isBeta ? <sup>Beta</sup>:null}
                    </h4>
                </div>
                <div className='app-desc'>
                    <p>{item.description}</p>
                </div>
                {item.access ? <Button size='small' endIcon={<ChevronRightIcon fontSize='small'/>}>Access</Button>:<span className='no-access-content'>Please contact support@sparta.aero to access this application</span>}
            </div>
        </Grid>
    )
}
export default AppCard;
