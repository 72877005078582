import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter, browserHistory, Link } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { Header, ContractHeadertext, EditSidebar, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader } from '../../../../shared';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { EditDebitEquity, EditOthers } from '../components';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { imgStoragePath, prettyNumberParamsL, assetsType } from '../../../../constants';
import { getFinanceDetailsAc,editFinanceAc } from '../actionCreators';
import { TOGGLE_VIEW_EDIT } from '../actions';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class FinanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount(){
     this.props.fetchFinanceDetails();
  }
  toggleEditSideBar = (type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { financeLoader, financeDetails, financeCrud, contractSummaryLoader, assetViewId, params } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params = {this.props.params} pageTitle="Financing Details" changeMsn={this.changeMsn}  exportContract={this.props.exportContract} />
        <ContractSideBar application="Financial Details" type="brief" params = {this.props.params} />
        { financeLoader || contractSummaryLoader ? <ListLoader /> : null }
        <div className="contract-content-blk" style={{position:"relative"}}>
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          <div className="contract-headline-block">
            <h4 style={{marginBottom:'6px'}} className="gama">Financing Details</h4>
            <p style={{fontSize: '14px', color: '#8b8d91', marginBottom: '24px'}}>Section holds vital financing information related to asset</p>
            <Row>
              <Col md="6">
                <div className="vertical-card card-block">
                  <h5 style={{paddingBottom: '30px'}}>Debt / Equity Mix
                    {
                      getLocalStorageInfo().user.permission['contracts'].financing_details && (getLocalStorageInfo().user.permission['contracts']['financing_details'].indexOf('C') != -1 || getLocalStorageInfo().user.permission['contracts']['financing_details'].indexOf('U') != -1) ?
                        <img onClick={() => this.props.toggleEditBar({flag: true, otherFlag:false, data: financeDetails})} width="16" className="editIcon" src={editIcon} alt="img"/>
                      :null
                    }
                  </h5>
                  <Row style={{marginBottom: '20px'}}>
                    <Col md="4">
                      <h6>Purchase value of asset</h6>
                      <span>{ financeDetails.purchase_value != '' && financeDetails.purchase_value != null && financeDetails.purchase_value != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.purchase_value}</NumericLabel> :'--' }</span>
                    </Col>
                    <Col md="8">
                      <h6>Tax Rate</h6>
                      <span>{ financeDetails.tax_rate ? financeDetails.tax_rate+'%'  :'--' }</span>
                    </Col>
                  </Row>
                  <Row style={{marginBottom: '20px'}}>
                    <Col md="4">
                      <h6>Debt / Equity Ratio</h6>
                      <span>
                        { financeDetails.debit_ratio != '' && financeDetails.debit_ratio != null && financeDetails.debit_ratio != undefined ? financeDetails.debit_ratio:'--' } :
                        { financeDetails.equity_ratio != '' && financeDetails.equity_ratio != null && financeDetails.equity_ratio != undefined ? financeDetails.equity_ratio:'--' }

                      </span>
                    </Col>
                    <Col md="4">
                      <h6>Debt Value</h6>
                      <span>{ financeDetails.debit_value != '' && financeDetails.debit_value != null && financeDetails.debit_value != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.debit_value}</NumericLabel> :'--' }</span>
                    </Col>
                    <Col md="4">
                      <h6>Equity Value</h6>
                      <span>{ financeDetails.equity_value != '' && financeDetails.equity_value != null && financeDetails.equity_value != undefined  ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.equity_value}</NumericLabel> :'--' }</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <h6>Interest Rate on Debt p.a</h6>
                      <span>{ financeDetails.interest_rate != '' && financeDetails.interest_rate != null && financeDetails.interest_rate != undefined ? financeDetails.interest_rate+'%':'--' }</span>
                    </Col>
                    <Col md="4">
                      <h6>Expected Return on Equity p.a</h6>
                      <span>{ financeDetails.return_equity != '' && financeDetails.return_equity != null && financeDetails.return_equity != undefined ? financeDetails.return_equity+'%' : '--' }</span>
                    </Col>
                    <Col md="4">
                      <h6>Weighted Average Cost of Capital</h6>
                      <span>{ financeDetails.weighted_average_capital ? financeDetails.weighted_average_capital+'%':'--' }</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="6">
                <div className="vertical-card card-block">
                  <h5 style={{paddingBottom: '30px'}}>Other Fees / Costs
                    {
                      getLocalStorageInfo().user.permission['contracts'].financing_details && (getLocalStorageInfo().user.permission['contracts']['financing_details'].indexOf('C') != -1 || getLocalStorageInfo().user.permission['contracts']['financing_details'].indexOf('U') != -1) ?
                        <img onClick={() => this.props.toggleEditBar({flag: false, otherFlag:true, data: financeDetails})} width="16" className="editIcon" src={editIcon} alt="img"/>
                      :null
                    }
                  </h5>
                  <Row style={{marginBottom: '20px'}}>
                    <Col md="6">
                      <h6>Lease Servicer Fee p.a</h6>
                      <span>{ financeDetails.lease_service_fee != '' && financeDetails.lease_service_fee != null && financeDetails.lease_service_fee != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.lease_service_fee}</NumericLabel> :'--' }</span>
                    </Col>
                    <Col md="6">
                      <h6>Administrator Fee p.a</h6>
                      <span>{ financeDetails.administrator_fee != '' && financeDetails.administrator_fee != null && financeDetails.administrator_fee != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.administrator_fee}</NumericLabel> :'--' }</span>
                    </Col>
                  </Row>
                  <Row>

                    <Col md="6">
                      <h6>Remarketing Cost</h6>
                      <span>{ financeDetails.re_marketing_cost != '' && financeDetails.re_marketing_cost != null && financeDetails.re_marketing_cost != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.re_marketing_cost}</NumericLabel>:'--' }</span>
                    </Col>
                    <Col md="6">
                      <h6>Asset Management Cost p.a</h6>
                      <span>{ financeDetails.asset_management_cost != '' && financeDetails.asset_management_cost != null && financeDetails.asset_management_cost != undefined ? <NumericLabel params={prettyNumberParamsL}>{financeDetails.asset_management_cost}</NumericLabel>:'--' }</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

        </div>
        <EditSidebar
          title="Edit Debt / Equity Mix"
          editSideBar={financeCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({ data: {}, flag: false, otherFlag:false})}
        >
          { financeCrud.flag ?
            <EditDebitEquity
              financeObj={financeCrud.data}
              toggleEditSideBar={() => this.props.toggleEditBar({ data: {}, flag: false})}
              editFinanceFn={(data) => this.props.editFinanceFn(data, 'Debt / Equity Mix', assetViewId)}
              financeLoader={financeLoader}
              type="edit"
            />:null
          }
        </EditSidebar>
        <EditSidebar
          title="Edit Others"
          editSideBar={financeCrud.otherFlag}
          toggleEditSideBar={() => this.props.toggleEditBar({ data: {}, flag: false, otherFlag:false})}
        >
          { financeCrud.otherFlag ?
            <EditOthers
              financeObj={financeCrud.data}
              toggleEditSideBar={() => this.props.toggleEditBar({ data: {}, flag: false, otherFlag:false})}
              editFinanceFn={(data) => this.props.editFinanceFn(data, 'Fees / Costs', assetViewId)}
              financeLoader={financeLoader}
              type="edit"
            />:null
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  financeLoader: state.ContrFinanceReducer.financeLoader,
  financeDetails: state.ContrFinanceReducer.financeDetails,
  financeCrud: state.ContrFinanceReducer.financeCrud,
  contractSummaryLoader:state.sharedReducers.contractSummaryLoader,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchFinanceDetails: () => dispatch(getFinanceDetailsAc(ownProps.params)),
    editFinanceFn: (data, type, assetViewId) => dispatch(editFinanceAc(ownProps.params, data, type, assetViewId)),
    toggleEditBar: (data) => dispatch({type: TOGGLE_VIEW_EDIT, payload: data}),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(FinanceDetail, ['contracts', 'headlines' ,'R'])));
