import * as actions from '../actions';
export default function( state = {
  leaseInfoCardSettings: {
    totalWidth: window.innerWidth,
    leftCount: 0,
    rightCount: 0,
    pageCount: 0,
    leftPos: 0
  },
  leaseHistories: [],
  majorAssemblyList: [],
  returnCondFlagged: [],
  returnCondCompare: {
    flag: false,
    returnConds: []
  },
  contrDbInvoices : {
    list: [],
    total_amount: 0,
    open_amount: 0
  },
  contrDbCashFlows: {},
  contrAssetBasicInfo: {},
  flaggedWidgetLoader:false,
  invoiceWidgetLoader:false,
  cashFlowLoader:true
}, action ){
  switch (action.type) {
    case actions.CONTR_ASSET_BASIC_INFO:
      return {
        ...state,
        contrAssetBasicInfo: action.payload
      }
    case actions.LEASE_HISTORIES:
      const list = action.payload;
      let winowWidth = window.innerWidth - 285;
      let totalWidth = 400*list.length;
      let leftCount = Math.round((totalWidth - winowWidth)/400);
      return {
        ...state,
        leaseHistories: list,
        leaseInfoCardSettings: {
          ...state.leaseInfoCardSettings,
          totalWidth: totalWidth,
          leftCount: (leftCount > 0) ? leftCount : 0
        }
      }
    case actions.UPDATE_LEASE_CARD_POS:
      let newLeftCount = 0, newRightCount = 0, newLeftPos = 0;
      if(action.payload == 'next'){
        newLeftCount = state.leaseInfoCardSettings.leftCount - 1;
        newRightCount = state.leaseInfoCardSettings.rightCount + 1;
        newLeftPos = state.leaseInfoCardSettings.leftPos - 400;
      }else{
        newLeftCount = state.leaseInfoCardSettings.leftCount + 1;
        newRightCount = state.leaseInfoCardSettings.rightCount - 1;
        newLeftPos = state.leaseInfoCardSettings.leftPos + 400;
      }
      return {
        ...state,
        leaseInfoCardSettings: {
          ...state.leaseInfoCardSettings,
          leftPos:newLeftPos,
          leftCount: newLeftCount,
          rightCount: newRightCount
        }
      }
    case actions.CONTR_MAJOR_ASSEMBLY_SLIDER:
      return {
        ...state,
        majorAssemblyList: action.payload
      }
      case actions.UTILS_AVERAGE:
        return {
          ...state,
          utlisAverage: action.payload
        }
    case actions.CONTR_DB_FLAGGED_COND:
      return {
        ...state,
        returnCondFlagged: action.payload
      }
    case actions.CONTR_DB_CASH_FLOW_LIST:
      return {
        ...state,
        contrDbCashFlows: action.payload
      }
    case actions.CASHFLOW_WIDGET_LOADER:
      return {
        ...state,
        cashFlowLoader: action.payload
      }
    case actions.CONTR_DB_INVOICE_LIST:
      return {
        ...state,
        contrDbInvoices: action.payload
      }
    case actions.COMPARE_RETURN_COND:
      return {
        ...state,
        returnCondCompare:{
          ...state.returnCondCompare,
          flag: true,
          returnConds: action.payload
        }
      }
    case actions.CLEAR_RETURN_COND:
      return {
        ...state,
        returnCondCompare: {
          ...state.returnCondCompare,
          flag: false,
          returnConds: []
        }
      }
    case actions.CONTRACT_WIDGET_LOADER:
      return {
        ...state,
        [action.loaderType]: action.payload
      }
      case actions.UTILS_TRENDS:
        return {
          ...state,
          utilsTrendsInfo: {
            ...state.utilsTrendsInfo,
            data: action.payload,
            lgSelected: action.payload.landing_gears ? Object.keys(action.payload.landing_gears)[0] : '',
            engineSelected: action.payload.engines ? Object.keys(action.payload.engines)[0] : '',
            propellerSelected: action.payload.propeller ? Object.keys(action.payload.propeller)[0] : '',
          },
          activeTab: action.payload.airframe ? '0' : (action.payload.apu ? '1' : (action.payload.landing_gears ? '2' : (action.payload.engines ? '3' : action.payload.lpt ? '7' : action.payload.hpc ? '5' : action.payload.hpt ? '6' : '4')))
        }
    default:
      return state;
  }
}
