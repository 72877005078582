import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, TextField, Paper } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import ChangeInvoiceStatus from './ChangeInvoiceStatus';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort, invoiceStatusDrpdwn } from '../../../constants';
import { getCurrencyFormat } from '../../../utils_v2';
const BasicDetail = ({invoiceInfo, mode, onFieldChange, updateErrorField, params, error, getResponseBack}) => {
  const getDateLabel = (invoiceInfo) => {
    let label = ''
    if(invoiceInfo.invoice_type == 6){
      label = 'Validity '
    }
    if(invoiceInfo.invoice_type == 7 || invoiceInfo.invoice_type == 8){
      label = 'Policy '
    }
    if(invoiceInfo.invoice_type == 1 || invoiceInfo.parent_invoice_type == 1){
      label = 'Utilization Period '
    }
    if(invoiceInfo.invoice_type == 2 || invoiceInfo.parent_invoice_type == 2){
      label = 'Rental Period '
    }
    return label;
  }
  const getDateMinMax = (dateType) => {
    let dateObj = {}
    if(invoiceInfo.invoice_type === 1){
      if(dateType === 'start'){
        dateObj = { minDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01'), maxDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01').add(1, 'months').subtract(1, 'days')}
      }else{
        dateObj = { minDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM') + '-01'), maxDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM')).add(2, 'months').subtract(1, "days")}
      }
    }else if(invoiceInfo.invoice_type === 9){
      if(dateType === 'start'){
        dateObj = { minDate:moment(invoiceInfo.parent_invoice_start_date), maxDate:moment(invoiceInfo.parent_invoice_end_date)}
      }else{
        dateObj = { minDate:moment(invoiceInfo.start_date), maxDate:moment(invoiceInfo.parent_invoice_end_date)}
      }
    }else if(invoiceInfo.invoice_type === 2 && invoiceInfo.interval === 5){
      if(dateType === 'start'){
        dateObj = { minDate:moment(invoiceInfo.rental_start_date), maxDate:moment(invoiceInfo.end_date)}
      }else{
        dateObj = { minDate:moment(invoiceInfo.start_date), maxDate:moment(invoiceInfo.rental_end_date)}
      }
    }else if(invoiceInfo.invoice_type === 2){
      if(dateType === 'start'){
        dateObj = { minDate:moment(invoiceInfo.rental_start_date), maxDate:moment(invoiceInfo.end_date)}
      }else{
        dateObj = { minDate:moment(invoiceInfo.start_date), maxDate:moment(invoiceInfo.rental_end_date)}
      }
    }else{
      if(dateType === 'start'){
        dateObj = { minDate:moment(invoiceInfo.start_date), maxDate:moment(invoiceInfo.start_date)}
      }else{
        dateObj =  { minDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM')), maxDate:moment(moment(invoiceInfo.start_date).format('YYYY') + '-' + moment(invoiceInfo.start_date).format('MM')).add(2, 'months').subtract(1, "days")}
      }
    }
    return dateObj;
  }
  return(
    <Paper className="invoice-basic-detail">
      <Grid container spacing={4}>
        { invoiceInfo.status === 1 && mode !== 'view' ?
          <Grid item xs={12} md={3}>
            <TextField
              id="invoice_number"
              label="Invoice Number"
              fullWidth
              margin="none"
              value={invoiceInfo.invoice_number}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 50 }}
              error={error.invoice_number ? true:false}
              helperText={error.invoice_number ? error.invoice_number:''}
              onChange={(e) => onFieldChange(e, 'invoice_number', e.target.value)}
              onFocus={() => updateErrorField('invoice_number','')}
            />
          </Grid>:
          <LabelValueCard md={3} label='Invoice Number' value={invoiceInfo.invoice_number} />
        }
        <LabelValueCard
          md={3}
          label='Invoice Status'
          value={
            <Fragment>
              { invoiceInfo.status ? invoiceStatusDrpdwn.find(item => item.value === invoiceInfo.status).label:''}
              { (invoiceInfo.status === 1 || invoiceInfo.status === 2 || invoiceInfo.status === 5) && mode == 'view' ?
                <ChangeInvoiceStatus getResponseBack={getResponseBack} statusValue={invoiceInfo.status} type="view" />:null
              }
            </Fragment>
          }
        />
        { invoiceInfo.status === 5 ?
          <Fragment>
            <LabelValueCard md={3} label='Partial Paid Amount' value={invoiceInfo.partial_paid_amount ? <NumericLabel params={getCurrencyFormat()}>{invoiceInfo.partial_paid_amount}</NumericLabel> :'--'} />
            <LabelValueCard md={3} label='Partial Payment Date' value={invoiceInfo.partial_paid_date ? moment(invoiceInfo.partial_paid_date).format(displayDateFormatShort):'--'} />
          </Fragment>:null
        }

        { mode === 'view' ?
          <LabelValueCard md={3} label='Invoice Date' value={invoiceInfo.date ? moment(invoiceInfo.date).format(displayDateFormatShort):'--'} />
          :
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="none"
                 id="date"
                 label="Invoice Date"
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 minDate={moment(invoiceInfo.contract_start_date)}
                 maxDate={moment(invoiceInfo.contract_end_date).subtract(15, 'days')}
                 error={error.date ? true:false}
                 helperText={error.date ? error.date:''}
                 value={invoiceInfo.date ? invoiceInfo.date:null}
                 onChange={(data, value) => {onFieldChange(value, 'date', moment(data).format(backendDateFormat)); updateErrorField('date','')}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        }
        { mode === 'view' ?
          <LabelValueCard md={3} label='Invoice Due Date' value={invoiceInfo.due_date ? moment(invoiceInfo.due_date).format(displayDateFormatShort):'--'} />
          :
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="none"
                 id="due_date"
                 label="Invoice Due Date"
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 minDate={moment(invoiceInfo.date)}
                 maxDate={moment(invoiceInfo.contract_end_date).subtract(15, 'days')}
                 error={error.due_date ? true:false}
                 helperText={error.due_date ? error.due_date:''}
                 value={invoiceInfo.due_date ? invoiceInfo.due_date:null}
                 onChange={(data, value) => {onFieldChange(value, 'due_date', moment(data).format(backendDateFormat)); updateErrorField('due_date','')}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        }
        { mode === 'view' ?
          <LabelValueCard md={3} label={`${getDateLabel(invoiceInfo)} Start Date`} value={invoiceInfo.start_date ? moment(invoiceInfo.start_date).format(displayDateFormatShort):'--'} />
          :
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="none"
                 id="start_date"
                 label={`${getDateLabel(invoiceInfo)} Start Date` }
                 format={fieldDateFormat}
                 fullWidth
                 minDate={getDateMinMax('start').minDate}
                 maxDate={getDateMinMax('start').maxDate}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 error={error.start_date ? true:false}
                 helperText={error.start_date ? error.start_date:''}
                 value={invoiceInfo.start_date ? invoiceInfo.start_date:null}
                 onChange={(data, value) => {onFieldChange(value, 'start_date', moment(data).format(backendDateFormat)); updateErrorField('start_date','')}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        }
        { mode === 'view' ?
          <LabelValueCard md={3} label={`${getDateLabel(invoiceInfo)} End Date`} value={invoiceInfo.end_date ? moment(invoiceInfo.end_date).format(displayDateFormatShort):'--'} />:
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="none"
                 id="end_date"
                 label={`${getDateLabel(invoiceInfo)} End Date`}
                 format={fieldDateFormat}
                 fullWidth
                 minDate={getDateMinMax('end').minDate}
                 maxDate={getDateMinMax('end').maxDate}
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 error={error.end_date ? true:false}
                 helperText={error.end_date ? error.end_date:''}
                 value={invoiceInfo.end_date ? invoiceInfo.end_date:null}
                 onChange={(data, value) => {onFieldChange(value, 'end_date', moment(data).format(backendDateFormat)); updateErrorField('end_date','')}}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        }
        { invoiceInfo.status === 3 && mode === 'view' ? <LabelValueCard md={3} label='Invoice Payment Date' value={invoiceInfo.paid_date ? moment(invoiceInfo.paid_date).format(displayDateFormatShort):'--'} />:null}
        { invoiceInfo.invoice_type === 7 || invoiceInfo.invoice_type === 8  ?
          <Fragment>
            <LabelValueCard md={3} label='Insurance Type' value={invoiceInfo.insurance_type && invoiceInfo.insurance_type.label ? invoiceInfo.insurance_type.label : '--'} />
            <LabelValueCard md={3} label='Insurance Number' value={invoiceInfo.insurance_number ? invoiceInfo.insurance_number : '--'} />
            <LabelValueCard md={3} label='Insurer' value={invoiceInfo.insurer ? invoiceInfo.insurer : '--'} />
            <LabelValueCard md={3} label='Insuree' value={invoiceInfo.insuree ? invoiceInfo.insuree : '--'} />
            { invoiceInfo.invoice_type === 7 && <LabelValueCard md={3} label='Effective Date' value={invoiceInfo.effective_date ? invoiceInfo.effective_date : '--'} /> }
            { invoiceInfo.invoice_type === 8 && <LabelValueCard md={3} label='Claim Details' value={invoiceInfo.claim_summary ? invoiceInfo.claim_summary : '--'} /> }
          </Fragment>:null
        }
        { invoiceInfo.invoice_type === 3 && invoiceInfo.libor_date ?
          <Fragment>
            { mode === 'view' ?  <LabelValueCard md={3} label='6 Months USD LIBOR as of' value={invoiceInfo.libor_date ? moment(invoiceInfo.libor_date).format(displayDateFormatShort):'--'} />:
              <Grid item xs={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                     required
                     margin="none"
                     id="libor_date"
                     label="6 Months USD LIBOR as of"
                     format={fieldDateFormat}
                     fullWidth
                     InputLabelProps={{shrink: true}}
                     inputProps={{readOnly: true}}
                     error={error.libor_date ? true:false}
                     helperText={error.libor_date ? error.libor_date:''}
                     value={invoiceInfo.libor_date ? invoiceInfo.libor_date:null}
                     onChange={(data, value) => {onFieldChange(value, 'libor_date', moment(data).format(backendDateFormat)); updateErrorField('libor_date','')}}
                   />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Fragment>:null
        }
      </Grid>
    </Paper>
  )
}
export default withRouter(BasicDetail);
