import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HistoryIcon from '@material-ui/icons/History';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader, RevisionHistory } from '../../../../shared_elements';
import { ClaimList, InsuranceList, InsuranceCRU, ClaimCRU } from '../components';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { assetsType } from '../../../../constants';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { sExportContractsAc } from '../../../actionCreators';
import { getInsuranceListApi, getClaimListApi, getApproversApi, getremainingIsuranceApi,deleteInsuranceApi,deleteClaimInsuranceApi} from '../apiServices';
import { getRevisionHistoryApi } from '../../../../shared_elements/apiServices';
import { insuranceHd, claimHd } from '../';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class Insurances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      approvers: [],
      insurances: [],
      insurance: [],
      claims: [],
      deleteItem:{modal:false, type:'',data:null}
    }
    this.getInsuranceListApi = getInsuranceListApi.bind(this);
    this.getClaimListApi = getClaimListApi.bind(this);
    this.getRevisionHistoryApi = getRevisionHistoryApi.bind(this);
    this.getApproversApi = getApproversApi.bind(this);
    this.deleteInsuranceApi = deleteInsuranceApi.bind(this);
    this.deleteClaimInsuranceApi = deleteClaimInsuranceApi.bind(this);
  }
  componentDidMount() {
    this.props.fetchContractInfo();
    this.getInsuranceListApi(this.props);
    this.getApproversApi();
    this.getClaimListApi(this.props);
    this.props.getPlatformConstants();
  }
  getResponseBack = () => {
    this.getInsuranceListApi(this.props);
    this.getClaimListApi(this.props);
  }
  changeMsn = (evt) => {
    let url = `/${assetsType[evt.asset_type]}/${evt.slug}/contracts`;
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { insurances, claims, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { url, queryParams, pltConstants, contractInfo } = this.props;

    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />

        <ContractSideBar application="Induction Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='insurance' primaryTab='basic-betails' secondaryTab='insurance' />
          <ContractWrapper
            revision={false}
            url='audit/contracts/ctinsurances/'
            queryParams={{ action: 0, contract: this.props.params.contract_slug }}
          >
            <div className="insurance_container" style={{ marginBottom: '40px' }}>
              <div className="insurance-claim-title flex-centered" style={{ justifyContent: 'space-between' }}>
                <h4>Insurance</h4>
                <ul className="list-inline flex-centered" >
                  { checkPermission('contracts','insurance', 'C') ?
                    <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                      <InsuranceCRU pltConstants={pltConstants} getResponseBack={this.getResponseBack} contractInfo={contractInfo} />
                    </li > : null}
                  <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                    <RevisionHistory
                      url='audit/contracts/ctinsurances/'
                      queryParams={{ action: 0, contract: this.props.params.contract_slug }}
                      buttonType={false}
                    />
                  </li>
                </ul>
              </div>

              {skeletonLoader ? (<STableLoader />) :
                (<TableListComp
                  heads={insuranceHd}
                  data={insurances.map((item, index) =>
                    <InsuranceList
                      key={index}
                      pltConstants={pltConstants}
                      getResponseBack={this.getResponseBack}
                      item={item} 
                      contractInfo={contractInfo}
                      deleteInsurance={() => this.setState({deleteItem:{modal:true, type:'insurance', data:item}})}
                    />
                  )
                  }
                  noRecord={insurances.length ? null :
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />)
              }
            </div>
            <div className="insurance_claim_container">
              <div className="insurance-claim-title flex-centered" style={{ justifyContent: 'space-between' }}>
                <h4>Insurance Claim History</h4>
                <ul className="list-inline flex-centered">
                  {
                    insurances.length ?
                      <> {checkPermission('contracts','insurance', 'C') ?
                        <li className="list-inline-item" style={{ cursor: 'pointer', marginRight: '15px' }}>
                          <ClaimCRU pltConstants={pltConstants} approvers={this.state.approvers} insurances={this.state.insurances} getResponseBack={this.getResponseBack} contractInfo={contractInfo}/>

                        </li> : null}
                        <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                          <RevisionHistory
                            url='audit/contracts/ctinsuranceclaim/'
                            queryParams={{ action: 0, contract: this.props.params.contract_slug }}
                            buttonType={false}
                          />
                        </li>
                      </>
                      : null
                  }

                </ul>
              </div>
              {skeletonLoader ? (<STableLoader />) :
                (<TableListComp
                  heads={claimHd}
                  data={claims.map((item, index) =>
                    <ClaimList
                      key={index}
                      approvers={this.state.approvers}
                      pltConstants={pltConstants}
                      getResponseBack={this.getResponseBack}
                      item={item} 
                      contractInfo={contractInfo}
                      deleteClaimInsurance={() => this.setState({deleteItem:{modal:true, type:'claim', data:item}})}
                    />  
                  )}
                  noRecord={claims.length ? null :
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />)
              }
            </div>
            {pageLoader || this.props.contractSummaryLoader ? <PageLoader /> : null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, type:'', data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={ deleteItem.type === 'claim' ? () => this.deleteClaimInsuranceApi(this.props,deleteItem):() => this.deleteInsuranceApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ["insurance_type"] })),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Insurances,['contracts','insurance', 'R'])))
