import React, {Component} from 'react';
import { connect } from 'react-redux';
import { TabPane } from 'reactstrap';
import { OverViewUtilization, MjrAssShopVisitHeader, MjrAssShopVisitDetail } from '../components';
import { CtrTableHd } from '../../../contracts/Elements';
import GenTabField from '../components/GenTabField';
import { CLOSE_AVIONIC_SIDE_BAR, TECHSPEC_LOADER } from '../actions';
import { overviewListAc,  overviewEditAc,  aircraftMaintenanceAc, fetchTechSummaryListAc } from '../actionCreators';
import { sLessorListAc, sLesseeListAc, sOperatorListAc, sRegionListAc, sArctTypesAc   } from '../../../../shared/actionCreators'
import { sManufacturerAc } from '../../../../shared/actionCreators';
import { NoRecordFound  } from '../../../../shared';
import { displayDateFormat, imgStoragePath } from '../../../../constants'
import { getLocalStorageInfo } from '../../../../utils'
import { getApuTypesAc } from '../../../../User/actionCreators'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router'
import moment from 'moment'
class OverviewInfo extends Component{
	constructor(props) {
		super(props);
		this.state = {
			overviewStatus: false
		};
	}
	toggleSideBar = (type) => {
		this.setState(prevState => ({
			...prevState,
			[type]: !prevState[type]
		}))
	}
	componentDidMount() {
		if(!this.props.aircraftManufacturerList.length){
			this.props.fetchManufacturer('aircraft','aircraftManufacturerList');
			this.props.fetchManufacturer('engine','engineManufacturerList');
		}
    if(!this.props.apucraftManufacturerList.length){
      this.props.fetchManufacturer('apu','apucraftManufacturerList');
    }
    if(!this.props.lgManufacturerList.length){
      this.props.fetchManufacturer('landing_gear', 'lgManufacturerList');
    }
    this.props.fetchTechSummaryList(this.props.asset_slug, 'lgList');
    this.props.getApuTypes()
		this.props.fetchOverviewList(this.props.asset_slug);
		this.props.fetchLessor();
		this.props.fetchLessee();
		this.props.fetchOperator();
		this.props.fetchRegion();
		this.props.fetchArchType();
  	this.props.fetchTechSummaryList(this.props.asset_slug, 'engineList');
    this.props.fetchTechSummaryList(this.props.asset_slug, 'apuList');
	}

	render(){
		const {
			overviewTechInfo,
			assetId,
			aircraftManufacturerList,
			aircraftTypes,
			maintenanceProgGrp,
			techConstants,
			sAircarftOperatn,
			techSpecsLoader,
      engineList,
      apuList,
      lgList
		} = this.props;
		const userInfo = getLocalStorageInfo()
    let maintanenceList = {};
    let aircraftOperation = []
    techConstants.filter(item => item.type == "aircraft_operation")
    let maintanenceData =  this.props.overviewTechInfo.maintenance_event_program_group;
    if(maintanenceData){
      Object.keys(maintanenceData).map((item,index) => {
        maintanenceList = {label: maintanenceData[item], value: this.props.overviewTechInfo.maintenance_event_program_group[item]}
      });
    }
			return(
				<TabPane tabId="1" className="general-block">
					{Object.keys(overviewTechInfo).length ?
						<div>
                <div className="horizontal-card card-block">
                <Row>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number (MSN)" text={overviewTechInfo.msn}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={overviewTechInfo.manufacturer && overviewTechInfo.manufacturer.name ? overviewTechInfo.manufacturer.name : '--' }/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Aircraft Type" text={overviewTechInfo.aircraft_type && overviewTechInfo.aircraft_type.name ? overviewTechInfo.aircraft_type.name :'--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Aircraft Model" text={overviewTechInfo.model ? overviewTechInfo.model :'--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Maintenance Program Group" text={maintanenceList.value ? maintanenceList.value :'--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Manufacture" text={overviewTechInfo.date_of_manufacture ? moment(overviewTechInfo.date_of_manufacture).format(displayDateFormat):'--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Registration Number" text={overviewTechInfo.current_registration_number? overviewTechInfo.current_registration_number : '--'}/>
										{
					            overviewTechInfo.status !== 4?
					            <GenTabField grid="3" classText = "horizontal-inner-block" label="Lessor Name" text={overviewTechInfo.lessor_name && overviewTechInfo.lessor_name.name ? overviewTechInfo.lessor_name.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
					            :null
					          }
										{
					            overviewTechInfo.status !== 4?
					            <GenTabField grid="3" classText = "horizontal-inner-block " label="Owner" text={overviewTechInfo.owner && overviewTechInfo.owner.name ? overviewTechInfo.owner.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
					            :null
					          }
                  <GenTabField grid="3" classText = "horizontal-inner-block" label="Aircraft Operation" text={overviewTechInfo.aircraft_operation &&  overviewTechInfo.aircraft_operation.label ? overviewTechInfo.aircraft_operation.label : ''}/>
									<GenTabField grid="3" classText = "horizontal-inner-block " label="Landing Capability" text={overviewTechInfo.landing_capability ? overviewTechInfo.landing_capability :'--'}/>
                </Row>
                </div>
							<OverViewUtilization timeUnit={this.props.techConstants.filter(item => item.type == 'utilization_unit')} utilization={overviewTechInfo} />
						</div> : null
					}

          {engineList.length ? <h2 style={{fontWeight: 'bold', marginTop: '30px', fontSize: '22px'}}>Engines</h2> : null}
          	{engineList.length ? engineList.map((engineList, index) =>
                <div style={{marginTop: '15px'}}>
                  <h3 style={{ marginBottom: '10px', fontSize: '16px'}}>Engine {'#' + engineList.position.value } ( {(engineList.esn ? engineList.esn : null )} ) </h3>
                  <div className="horizontal-card card-block" style={{marginBottom:'20px'}}>
                  <Row>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={ engineList.manufacturer && engineList.manufacturer.name ? engineList.manufacturer.name  : '--' }/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Engine Type" text={engineList.engine_type  && engineList.engine_type.name? engineList.engine_type.name :  '--'}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={engineList.esn}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label={engineList.esn} text={ engineList.position && engineList.position.label ? engineList.position.label  : '--' }/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Manufacture" text={ moment(engineList.manufacturing_date).format(displayDateFormat)}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Region of Operation" text={engineList.region ? engineList.region.name : '--'}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Purchase Thrust (Lbs)" text={engineList.purchase_thrust}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Operating Thrust (Lbs)" text={engineList.operating_thrust}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block" label="Lease Status"   text={engineList.status && engineList.status.label ? engineList.status.label :  '--'}/>
                    <GenTabField grid="3" classText = "horizontal-inner-block" label="On Wing MSN"   text={engineList.on_wing_msn ? engineList.on_wing_msn :  '--'}/>
                  </Row>
                  </div>
                  <OverViewUtilization index={index} utilization={engineList} toggleEditSideBar={() => this.toggleSideBar('landingGearEdit')} />
                </div>
              ):null
            }

          {apuList.length ?<h2 style={{fontWeight: 'bold', marginTop: '30px', fontSize: '22px'}}>APU</h2> : null}
          	{apuList.length ? apuList.map((apu, index) =>
                <div style={{marginTop: '15px'}}>
                  <h3 style={{ marginBottom: '10px', fontSize: '16px'}}>APU {(apu.serial_number ? apu.serial_number : null )}</h3>
                  <div className="horizontal-card card-block" style={{marginBottom:'20px'}}>
                  <Row>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={apu.manufacturer}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={apu.serial_number}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Part Number" text={apu.part_number}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU titled for this MSN ?" text={apu.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU fitted for this MSN ?" text={apu.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is APU on MSN ?"   text={apu.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                  {
                    apu.on_wing_lessor  ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="APU MSN"   text={apu.on_wing_msn}/>
                    : null
                  }
                    {
                    apu.on_wing_msn   ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="On MSN APU Owner"   text={apu.on_wing_lessor}/>
                    : null
                  }

                    {
                    apu.apu_type   ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="APU Type"   text={this.props.apuTypesList.filter(item => item.id == apu.apu_type).length ? this.props.apuTypesList.filter(item => item.id == apu.apu_type)[0].name : '--'}/>
                    : null
                  }
                  </Row>
                  </div>
                  <OverViewUtilization index={index} utilization={apu} toggleEditSideBar={() => this.toggleSideBar('landingGearEdit')} />
                </div>
              ):null
            }

          {lgList.length ? <h2 style={{fontWeight: 'bold', marginTop: '30px', fontSize: '22px'}}>LG</h2> : null}
          	{lgList.length ? lgList.map((landingGear, index) =>
                <div style={{marginTop: '15px'}}>
                  <h3 style={{ marginBottom: '10px', fontSize: '16px'}}>LG {(landingGear.serial_number ? landingGear.serial_number : null )}</h3>
                  <div className="horizontal-card card-block" style={{marginBottom:'20px'}}>
                  <Row>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={landingGear.manufacturer}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={landingGear.serial_number}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Part Number" text={landingGear.part_number}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Position" text={landingGear.position && landingGear.position.label ? landingGear.position.label :'--'}/>

                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear titled for this MSN ?" text={landingGear.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear fitted on this MSN ?" text={landingGear.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Is Landing Gear on MSN ?"   text={landingGear.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17"/>: <img src={imgStoragePath+"cross_red.png"} width="17" alt="img"/>}/>
                    {
                      landingGear.on_wing_lessor  ?
                      <GenTabField grid="3" classText = "horizontal-inner-block " label="Landing Gear MSN"   text={landingGear.on_wing_msn}/>
                      : null
                    }
                    {
                      landingGear.on_wing_msn   ?
                      <GenTabField grid="3" classText = "horizontal-inner-block " label="MSN Landing Gear Owner"   text={landingGear.on_wing_lessor}/>
                      : null
                    }
                  </Row>
                  </div>
                  <OverViewUtilization index={index} utilization={landingGear} toggleEditSideBar={() => this.toggleSideBar('landingGearEdit')} />
                </div>
              ):null
            }
				</TabPane>
			)
		}
}
const mapStateToProps = state => ({
	aircraftManufacturerList: state.sharedReducers.aircraftManufacturerList,
	engineManufacturerList: state.sharedReducers.engineManufacturerList,
	overviewTechInfo: state.TechSummaryReducer.overviewTechInfo,
	maintenanceProgGrp: state.TechSummaryReducer.maintenanceProgGrp,
	sEngineTypes: state.sharedReducers.sEngineTypes,
	sLessorList: state.sharedReducers.sLessorList,
	sLesseeList: state.sharedReducers.sLesseeList,
	sOperatorList: state.sharedReducers.sOperatorList,
	sRegionList: state.sharedReducers.sRegionList,
	aircraftTypes: state.sharedReducers.aircraftTypes,
	techConstants: state.sharedReducers.techConstants,
	clients: state.userAssetReducer.clients,
	operators: state.userAssetReducer.operators,
	portfolioList: state.FleetAnalysisReducer.portfolioList,
  engineList: state.TechSummaryReducer.engineList,
  apuList: state.TechSummaryReducer.apuList,
  apuTypesList: state.userAssetReducer.apuTypesList,
  apucraftManufacturerList: state.sharedReducers.apucraftManufacturerList,
  lgManufacturerList: state.sharedReducers.lgManufacturerList,
	lgList: state.TechSummaryReducer.lgList,
})
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchManufacturer: (group_type, reducer_type) => dispatch(sManufacturerAc(group_type, reducer_type)),
		fetchOverviewList: (asset_slug) => dispatch(overviewListAc(asset_slug)),
		fetchLessor:() => dispatch(sLessorListAc()),
		fetchLessee:() => dispatch(sLesseeListAc()),
		fetchOperator:() => dispatch(sOperatorListAc()),
		fetchRegion: () => dispatch(sRegionListAc()),
		fetchArchType: () => dispatch(sArctTypesAc()),
		fetchMaintenanceGrp: (asset_type) => dispatch(aircraftMaintenanceAc(asset_type)),
		editOverview: (data) => dispatch(overviewEditAc(data)),
    fetchTechSummaryList: (asset_slug, type) => dispatch(fetchTechSummaryListAc(asset_slug, type)),
		closeEditViewSideBar: (type, data) => dispatch({
			type: CLOSE_AVIONIC_SIDE_BAR,
			viewType: type,
			payload: data
		}),
		toggleLoader: (flag) => dispatch({
			type: TECHSPEC_LOADER,
			payload: flag
		}),
    getApuTypes: () => dispatch(getApuTypesAc())
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewInfo);
