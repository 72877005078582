import React, { Component } from 'react';
import { Row, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkFloat, checkValidDate } from '../../../../formValidator';
import {getLocalStorageInfo, toastFlashMessage} from '../../../../utils'
import { globalGetService} from '../../../../globalServices/'
import moment from 'moment';
export default class AddEditObligation extends Component {
  constructor(props){
    super(props);
    this.state = {
      obligation:{},
      error: {},
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.obligationObj));
    if (data.id) {
      data = {
        ...data,
        to_be_approved_by: Object.keys(this.props.obligationObj.to_be_approved_by).length ? this.props.obligationObj.to_be_approved_by.id:'',
        obligation_type: data.obligation_type.value,
        sub_type: data.sub_type.value,
        status: data.status.value,
        created_by: data.created_by.id
      }
    }
    this.setState({
      obligation: data,
    });
  }
  updateForm = (type, value) => {
    if(type === 'to_be_approved_by'){
      if(this.props.approvedByUsers.list.filter(user => user.id === value).length){
        this.setState(prevState => ({
          ...prevState,
          obligation: {
            ...prevState.obligation,
            [type]: (value == 'Invalid date' ? null:value),
          },
          error: {
            ...prevState.error,
            [type]: ''
          }
        }))
      }else {
        toastFlashMessage('The user is deactivated, you can not assign it as approver', 'error')
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        obligation: {
          ...prevState.obligation,
          [type]: (value == 'Invalid date' ? null:value),
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))

      if(type == 'sub_type' && value == '2'){
        this.setState(prevState => ({
          ...prevState,
          obligation: {
            ...prevState.obligation,
            value: '',
            payment_due_date:null
          }
        }))
      }
    }
  }
  addEditObligation = (e) => {
    e.preventDefault();
    var validateNewInput = {
      obligation_type: checkString({value: this.state.obligation.obligation_type, required: true, minLength: '', maxLength: '', message: 'Please select Type'}),
      sub_type: checkString({value: this.state.obligation.sub_type, required: true, minLength: '', maxLength: '', message: 'Please select Sub Type'}),
      applicable: checkString({value:this.state.obligation.applicable,required:true, message: 'Please select Applicable To'}),
      status: checkString({value:this.state.obligation.status, required:true, message: 'Please select Status'}),
      value: this.state.obligation.sub_type != '2' ? checkFloat({value:this.state.obligation.value,required:true, message: 'Please enter Commercial Value'}):'',
      to_be_approved_by: checkString({value:this.state.obligation.to_be_approved_by,required:true, message: 'Please select Approver'}),
      payment_due_date: this.state.obligation.sub_type != '2' ? checkValidDate({value:this.state.obligation.payment_due_date,required:true, message: 'Please select Due date'}):''
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditObligation(this.state.obligation, this.props.type);
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }
  render() {
    const { obligation, error } = this.state;
    const { type, techConstants, approvedByUsers } = this.props;
    if(!Object.keys(obligation).length){
      return null
    }
    return (
      <form onSubmit={(e) => this.addEditObligation(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Type {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Type'
                  keyParam="obligation_type"
                  value={obligation.obligation_type ? obligation.obligation_type:''}
                  type={type}
                  options={techConstants.filter(item => item.type == 'obligation_type')}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {obligation.obligation_type ? techConstants.find(item => item.type == 'obligation_type' && item.value == obligation.obligation_type).label : null}
                />
                <h6 className="error-msg">{error.obligation_type}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Sub Type {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Sub Type'
                  keyParam="sub_type"
                  value={obligation.sub_type}
                  type={type}
                  options={techConstants.filter(item => item.type == 'obligation_sub_type')}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                  name = {obligation.sub_type ? techConstants.find(item => item.type == 'obligation_sub_type' && item.value == obligation.sub_type).label : null}
                />
              <h6 className="error-msg">{error.sub_type}</h6>
              </FieldGroup>
            </FieldCol>
            { obligation.sub_type != '2' ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Commercial Value {type != 'view'?<sup className="reqStar"> * </sup>:null}</FieldLabel>
                  <FieldInput
                    value={obligation.value}
                    type={type}
                    keyParam="value"
                    updateField={this.updateForm}
                    currency={true}
                  />
                  <h6 className="error-msg">{error.value}</h6>
                </FieldGroup>
              </FieldCol>:null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Details
                  <i style={{paddingLeft: '4px'}} className="fa fa-info-circle" id="details-field"></i>
                  <UncontrolledTooltip placement="top" target="details-field">
                    Describe details of obligation / exception / CP. Example: Parties agree to waive off installation of EFB.
                  </UncontrolledTooltip>
                </FieldLabel>
                <FieldTextarea
                  value={obligation.details}
                  type={type}
                  keyParam="details"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Applicable To
                  {type != 'view'?<sup className="reqStar"> * </sup>:null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Applicable To'
                  keyParam="applicable"
                  value={obligation.applicable}
                  type={type}
                  options={[{label: 'Lessor',value: 'Lessor'}, {label: 'Lessee',value: 'Lessee'}, {label: 'Both',value: 'Both'}]}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
              <h6 className="error-msg">{error.applicable}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Approver {type != 'view'?<sup className="reqStar"> * </sup>:null}

                </FieldLabel>
                <FieldSelect
                  placeholder='Select Approver'
                  keyParam="to_be_approved_by"
                  value={obligation.to_be_approved_by}
                  type={type}
                  options = {obligation.to_be_approved_by === '' || approvedByUsers.list.map(user => {return {value: user.id, label: user.first_name +' '+ user.last_name}}).filter(user => user.value === obligation.to_be_approved_by).length ? approvedByUsers.list.map(user => {return {value: user.id, label: user.first_name +' '+ user.last_name}}) : [...approvedByUsers.list.map(user => {return {value: user.id, label: user.first_name +' '+ user.last_name}}), {value: this.props.obligationObj.to_be_approved_by.id, label: this.props.obligationObj.to_be_approved_by.name}]}
                  updateField={this.updateForm}
                  name = {this.props.obligationObj && this.props.obligationObj.to_be_approved_by ? this.props.obligationObj.to_be_approved_by.name:'--'}
                  labelKey= 'label'
                  valueKey='value'
                />
                <h6 className="error-msg">{error.to_be_approved_by}</h6>
              </FieldGroup>
            </FieldCol>
            { obligation.sub_type != '2' ?
              <FieldCol md="6">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Due Date {type != 'view'?<sup className="reqStar"> * </sup>:null}
                  </FieldLabel>
                  <FieldDate
                    value={obligation.payment_due_date}
                    type={type}
                    keyParam="payment_due_date"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.payment_due_date}</h6>
                </FieldGroup>
              </FieldCol>:null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={obligation.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
            { obligation.suggestion && obligation.status == '2' ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Reason of Rejection</FieldLabel>
                  <FieldTextarea
                    value={obligation.suggestion}
                    type={'view'}
                    keyParam="suggestion"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>:null
            }


          </Row>
        </div>
        { type != 'view' ?
          <div className="submit-block">
             <input type="submit" className="primary-btn" value="Save Changes"/>
             <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
     </form>
   )
 }
}
