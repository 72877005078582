import React, { useEffect, useState } from 'react';
import { Modal as MuiModal, Button as MuiButton, TextField, Chip, CircularProgress } from '@material-ui/core'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Autocomplete } from '@material-ui/lab';
import { globalPostService, globalGetService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { useSnackbar } from 'notistack';
import { browserHistory } from 'react-router';

const GenerateInvoice = ({ isOpen, handleClose, slug, month, year, params, submit, utilsLoader, okpress }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [banks, setBanks] = useState([])
  const [selectedBank, setSelectedBank] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (isOpen) {
      getUtilizationInvoices();
    }
  }, [isOpen]);

  const handleCancel = () => {
    handleClose();
  };
  const getUtilizationInvoices = () => {
    const formattedDate = `01-${month}-${year}`;
    globalGetService(`/contracts/${slug}/utilization/invoices/initialize/1/?utilization_date=${formattedDate}`)
      .then(response => {
        if (response.data.statusCode === 200) {
          setBanks(response.data.data.bank_details);
          setHasError(false);
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          setHasError(true);
          handleClose()
        }
      })
  }
  const handleBankChange = (newBank) => {
    setSelectedBank(newBank);
  };
  const generateInvoice = (e) => {
    let payload = {
      invoice_type: '1',
      month: `${year}-${month}-01`,
      bank_details_id: selectedBank.id
    }
    setLoading(true)
    globalPostService(`/contracts/${slug}/invoices/structure/`, payload)
      .then(response => {
        if (checkApiStatus(response)) {
          setLoading(false)
          enqueueSnackbar('Utilization & Reserve Invoice Generated Successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          handleClose()
          browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${slug}/invoice/view/${response?.data?.data?.id}`);
        } else {
          enqueueSnackbar(`Utilization is generated successfully but Reserve Invoice is not generated due to :${response.data.message}.If you want to generate Reserve Invoice, Kindly genetate it in invoice page`, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          setLoading(false)
          handleClose()
          browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${slug}/invoice`);
        }
      })
  }
  const invoice = (e,) => {
    submit(e, '', true, generateInvoice)
  }
  return (
    <>
      <Modal
        isOpen={!hasError ? isOpen : ''}
        className="modal-container-low-utils"
      >
        <ModalHeader>
          <h6 style={{ fontSize: '16px' }}>Generate Reserve Invoice</h6>
        </ModalHeader>
        <ModalBody>
          <Autocomplete
            value={selectedBank}
            onChange={(event, newValue) => handleBankChange(newValue)}
            options={banks}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select Bank" variant="outlined" fullWidth />}
          />
        </ModalBody>
        <ModalFooter>
          <MuiButton disabled={utilsLoader || isLoading} color="primary" size='small' variant="outlined" onClick={handleCancel}>
            Cancel
          </MuiButton>
          <MuiButton disabled={!selectedBank || utilsLoader || isLoading} color="primary" size='small' variant="contained" onClick={invoice}>
            {utilsLoader || isLoading ? <CircularProgress size={24} /> : 'Generate Invoice'}
          </MuiButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GenerateInvoice;
