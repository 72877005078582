import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { addParty } from '../../contractAddObject';
export const contrPartiesAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.PARTIES_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/parties/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_PARTIES_LIST,
          payload: response.data.data
        });
      }
      dispatch({
        type: actions.PARTIES_LOADER,
        payload: false
      });
    });
  }
}
export const contrAddEditPartiesAc = (params, data, type, assetViewId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PARTIES_LOADER,
      payload: true
    });
    if(type == 'add'){
      globalPostService('contracts/'+params.contract_slug+'/parties/', data)
      .then(response => {
        dispatch({
          type: actions.PARTIES_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.ADD_PARTY_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.PARTY_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Added', {
            page_title: `Parties`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Party`,
            item_id: response.data.data.id
          })
        }
      })
    }else{
      globalPutService('contracts/'+params.contract_slug+'/parties/' + data.id + '/',data)
      .then(response => {
        dispatch({
          type: actions.PARTIES_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.EDIT_PARTY_SUCCESS,
            payload: response.data.data
          });
          dispatch({
            type: actions.PARTY_CRUD,
            payload: {type: '', data: {}, flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          trackActivity('Item Edited', {
            page_title: `Parties`,
            application_module: `Contracts`,
            asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
            contract_slug: params.contract_slug,
            item_type: `Party`,
            item_id: data.id
          })
        }
      })
    }
  }
}

export const partyCrudFnAc = (params, type, id) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.PARTY_CRUD,
        payload: {type: type, data:addParty , flag: true }
      });
    }else{
      dispatch({
        type: actions.PARTIES_LOADER,
        payload: true
      });
      globalGetService('contracts/'+ params.contract_slug +'/parties/'+id +'/',{})
      .then(response => {
        dispatch({
          type: actions.PARTIES_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.PARTY_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}
