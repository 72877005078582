import React, { Component } from 'react';
import { Link } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { DropzoneArea } from 'material-ui-dropzone';
import { toastFlashMessage } from "../../../../utils_v2";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import InvCol from './InvCol';
import ChangeInvoiceStatus from './ChangeInvoiceStatus';
import { dateTransform, showCurrencyFormat, getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { invoiceStatusList, invoiceDescList, imgStoragePath, displayDateFormatShort } from '../../../../constants';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Button as MuiButton, Chip, Tooltip } from '@material-ui/core'
import { addInvoiceAttachmentApi, deleteInvoiceAttachmentApi } from '../apiServices'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListLoader } from '../../../../shared';

export default class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAttachment: 'false',
      modal: false,
      delModal: false,
      data: {},
      pageLoader: false,
      attatchedDoc: [],
      showFullList: false,
      deleteAttachmentmodal: false,
      deleteItem: '',
      previewDocs: '',
    };
    this.addInvoiceAttachmentApi = addInvoiceAttachmentApi.bind(this)
    this.deleteInvoiceAttachmentApi = deleteInvoiceAttachmentApi.bind(this)
  }
  changeStatus = (e) => {
    if (this.props.contractInfo.hdlineInfo?.archived_asset) {
      e.preventDefault();
    }
    else {
      e.preventDefault();
      e.stopPropagation();
      this.toggle();
    }
  }
  previewDoc(item) {
    this.setState({ previewDocs: item })
  }
  openDropzone = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ addAttachment: true });
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,

    });
  }
  handleDelete = (item) => {
    if (checkPermission('contracts', 'invoices', 'U')) {
      this.setState({ deleteAttachmentmodal: !this.state.deleteAttachmentmodal, deleteItem: item })
    }

  }
  deleteAttachment = (item) => {
    const { props, invoice } = this.props
    let contractSlug = props.params.contract_slug
    this.setState({ pageLoader: true, deleteAttachmentmodal: false })
    this.deleteInvoiceAttachmentApi(this.state.deleteItem)
      .then(response => {
        this.setState({ pageLoader: false })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          this.props.getInvoiceAttachment(contractSlug)
        }
        else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
  }

  addAttachment(e) {
    const { props, invoice } = this.props
    const { attatchedDoc } = this.state
    let contractSlug = props.params.contract_slug
    let formdata = new FormData()
    attatchedDoc.map(item => {
      formdata.append('files', item)
    })
    formdata.append('asset_type', props.assetViewId.asset_type)
    formdata.append('asset_slug', props.params.aircraft_slug)
    formdata.append('contract_slug', props.params.contract_slug)
    formdata.append('model_type', '3')
    formdata.append('object_id', invoice.id)
    this.setState({ pageLoader: true })
    this.addInvoiceAttachmentApi(formdata, contractSlug)
      .then(response => {
        this.setState({ pageLoader: false, addAttachment: false })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          this.props.getInvoiceAttachment(contractSlug)
        }
        else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
  }
  toggleDelModal(data) {
    this.setState({
      delModal: !this.state.delModal,
      data: data,
    });
  }

  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      [keyParam]: value
    }))
  }
  updateInvStatus = (data) => {
    this.toggle();
    this.toggle();
    if (data.status == 3 || data.status == 4) {
      this.toggleDelModal(data);
    } else {
      this.props.updateListInvStatus(data, this.props.contract_slug, this.props.invoice.id, 'list');
    }
  }
  render() {
    const { invoice, params, contract_slug, ctrShortInfo, attachedDocList, contractInfo } = this.props;
    const { attatchedDoc, addAttachment, previewDocs } = this.state;
    const invoiceType = {
      1: 'Maintenance',
      2: 'Rental',
      3: 'Penalty',
      4: 'Debit Note',
      5: 'Credit Note'
    }
    return (
      <Link className="asset-list" to={'/' + params.type + '/' + params.aircraft_slug + '/contract/' + contract_slug + '/invoice/view/' + this.props.invoice.id}>
        <Row>
          {this.state.pageLoader ? <ListLoader /> : null}
          <Col xs={2} style={{ wordBreak: 'break-word', width: '166px' }}  >
            <h6 style={{ display: 'flex', justifyContent: 'space-between' }} >{invoice.invoice_number ? invoice.invoice_number : '--'}
              {checkPermission('contracts', 'invoices', 'U') && contractInfo?.hdlineInfo?.archived_asset == false ?
                <Tooltip title={attachedDocList.filter(i => i.object_id == invoice.id).length >= 5 ? "" : "Add Attachment"} arrow placement='top' >
                  <AttachFileIcon variant='contained' color={attachedDocList.filter(i => i.object_id == invoice.id).length < 6 ? 'primary' : ''} style={{ marginLeft: '3px', cursor: attachedDocList.filter(i => i.object_id == invoice.id).length >= 5 ? '' : 'pointer', marginBottom: '10px', color: attachedDocList.filter(i => i.object_id == invoice.id).length >= 5 ? '#d6d6d6' : '' }} onClick={(e) => attachedDocList.filter(i => i.object_id == invoice.id).length >= 5 ? null : this.openDropzone(e)} disabled={true}></AttachFileIcon>
                </Tooltip> : null
              }
            </h6>
            {
              attachedDocList ?
                attachedDocList.filter(i => i.object_id == invoice.id).length > 3 && !this.state.showFullList ?
                  <>{
                    attachedDocList.filter(i => i.object_id == invoice.id).slice(0, 2).map(i =>
                      i.name.length > 17 ?
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                          <Chip
                            style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                            variant="outlined"
                            size="small"
                            onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDelete(i) }}
                            label={i.name.substring(0, 14) + '...'}
                            clickable
                            color="primary"
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(i) }}
                          />
                        </Tooltip>
                        :
                        <Chip
                          style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                          variant="outlined"
                          size="small"
                          label={i.name}
                          onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDelete(i) }}
                          clickable
                          color="primary"
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(i) }}
                        />
                    )
                  }
                    <Chip
                      style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                      size="small"
                      label={'Show all'}
                      clickable
                      color="primary"
                      deleteIcon={<ExpandMoreIcon />}
                      onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: true }) }}
                      onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: true }) }}
                    >
                    </Chip>
                  </>
                  :
                  <>{attachedDocList.filter(i => i.object_id == invoice.id).map(i =>
                    i.name.length > 17 ?
                      <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                        <Chip
                          style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                          variant="outlined"
                          size="small"
                          label={i.name.substring(0, 14) + '...'}
                          clickable
                          onDelete={ getLocalStorageInfo()?.user?.permission?.contracts?.invoices?.indexOf('C') !== -1 ? (e) => { e.preventDefault(); e.stopPropagation(); this.handleDelete(i) } :''}
                          color="primary"
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(i) }}
                        />
                      </Tooltip>
                      :
                      <Chip
                        style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                        variant="outlined"
                        size="small"
                        label={i.name}
                        onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDelete(i) }}
                        clickable
                        color="primary"
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(i) }}

                      />
                  )
                  }
                    {
                      this.state.showFullList && attachedDocList.filter(i => i.object_id == invoice.id).length > 3 ?
                        <Chip
                          style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                          size="small"
                          label={'Show Less'}
                          clickable
                          color="primary"
                          deleteIcon={<KeyboardArrowUpIcon />}
                          onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                        >
                        </Chip>
                        : null
                    } 
                    </>
                : null
            }
          </Col>
          <InvCol size="1" sixeXs="1" value={invoiceDescList[invoice.invoice_type] ? invoiceDescList[invoice.invoice_type] : '--'} />
          <InvCol size="2" sixeXs="2" value={(invoice.start_date ? moment(invoice.start_date).format(displayDateFormatShort) : '--') + ' to ' + (invoice.end_date ? moment(invoice.end_date).format(displayDateFormatShort) : '--')} />
          <InvCol size="1" sixeXs="1" value={invoice.date ? moment(invoice.date).format(displayDateFormatShort) : '--'} />
          <InvCol size="2" sixeXs="2 " value={invoice.due_date ? moment(invoice.due_date).format(displayDateFormatShort) : '--'} />
          <InvCol size="2" sixeXs="2" customClass={(invoice.in_due && invoice.status == 2) ? 'in-due' : null}
            value={invoice.total_amount ?
              invoice.invoice_type == 2 ?
                invoice.children.length > 0 ?
                  <div>
                    <div>
                      <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
                    </div>
                    <NumericLabel params={showCurrencyFormat(invoice.children[0].currency)}>{invoice.children[0].sub_total}</NumericLabel>
                  </div>
                  :
                  <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
                :
                <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
              : '--'}
          />
          <InvCol size="1" sixeXs="1"
            value={
              <div class="flex-not-centered">
                <div>
                  <p>
                    {invoice.status == '3' || invoice.status == '4' ?
                      <span
                        className={invoice.status ? invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
                        {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
                        {invoice.status == 1 || invoice.status == 2 || invoice.status == 5 ?
                          <i style={{ float: 'right' }} className="fa fa-caret-down" onClick={(e) => {
                            if (checkPermission('contracts', 'invoices', 'U')) {
                              this.changeStatus(e)
                            }
                          }}></i> : null
                        }
                      </span> :
                      checkPermission('contracts', 'invoices', 'U') ?
                        <span
                          onClick={(e) => {
                            if (checkPermission('contracts', 'invoices', 'U')) {
                              this.changeStatus(e)
                            }
                          }}
                          className={invoice.status ? invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
                          {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
                          {invoice.status == 1 || invoice.status == 2 || invoice.status == 5 ?
                            <i style={{ float: 'right' }} className="fa fa-caret-down" onClick={(e) => {
                              if (checkPermission('contracts', 'invoices', 'U')) {
                                this.changeStatus(e)
                              }
                            }}></i> : null
                          }
                        </span> : <span
                          className={invoice.status ? invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
                          {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
                          {invoice.status == 1 || invoice.status == 2 || invoice.status == 5 ?
                            <i style={{ float: 'right' }} className="fa fa-caret-down" onClick={(e) => {
                              if (checkPermission('contracts', 'invoices', 'U')) {
                                this.changeStatus(e)
                              }
                            }}></i> : null
                          }
                        </span>
                    }
                    <br />
                    {
                      invoice?.qb_last_sync_time && invoice?.qb_last_sync_by?.name ?
                        <span style={{ display: 'block', marginTop: '4px', textAlign: 'left', textTransform: 'none' }}>Sync Status: {invoice?.qb_last_sync_time ? moment(invoice?.qb_last_sync_time).format('MMM DD, YYYY') : '--'}{' by '}{invoice?.qb_last_sync_by?.name || '--'}
                          <br /></span>
                        : null}
                    <span className="overdue-text" style={{ display: 'block', textAlign: 'left', marginTop: '4px' }}>
                      {((invoice.status == 2) && invoice.in_due) ? 'Overdue By ' + dateTransform(invoice.due_date) : ''}
                    </span>
                    {
                      invoice.status == 5 && invoice.partial_paid_date ?
                        <span style={{ display: 'block', marginTop: '4px', textAlign: 'left', textTransform: 'none' }}>
                          Payment Date: {moment(invoice.partial_paid_date).format('MMM DD,YYYY')}
                          {moment(invoice.partial_paid_date).diff(moment(invoice.due_date), 'days') > 0 ? <span style={{ display: 'block', textAlign: 'left', marginTop: '4px', color: '#ff0000', fontSize: '9px', textTransform: 'none' }} >{"Payment late by " + dateTransform(invoice.due_date)}</span> : ''}
                        </span> : null
                    }
                    {
                      invoice.status == 3 && invoice.paid_date ?
                        <span style={{ display: 'block', marginTop: '4px', textAlign: 'left', textTransform: 'none' }}>
                          Payment Date: {moment(invoice.paid_date).format(displayDateFormatShort)}
                          {moment(invoice.paid_date).diff(moment(invoice.due_date), 'days') > 0 ? <span style={{ display: 'block', textAlign: 'left', marginTop: '4px', color: '#ff0000', fontSize: '9px', textTransform: 'none' }}>{"Payment late by " + dateTransform(invoice.due_date, invoice.paid_date)}</span> : ''}
                        </span> :
                        null
                    }
                  </p>
                </div>
              </div>
            }
          />

        </Row>
        {
          this.state.deleteAttachmentmodal ?
            <DeleteModal isOpen={this.state.deleteAttachmentmodal} className="modal-dialog-centered"  >
              <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this Attachment
                <Tooltip title={this.state.deleteItem.name} arrow placement='top' style={{ marginTop: '3px' }}>
                  <div>
                    <Chip variant="outlined" size="small" label={this.state.deleteItem.name.substring(0, 30) + '...'} color="primary" />
                  </div>
                </Tooltip>
              </p>
              <ul className="list-inline" style={{ textAlign: 'center' }}>
                <li className="list-inline-item">
                  <button className="btn btn-primary btn-sm add-new" onClick={this.deleteAttachment}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({ deleteAttachmentmodal: false })} >No</button>
                </li>
              </ul>
            </DeleteModal> :
            null
        }
        {
          previewDocs ?
            <Modal isOpen='open' style={{ width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight: '95vh', height: '95vh', marginTop: '0px' }}>
              <ModalHeader>{previewDocs.name}</ModalHeader>
              <ModalBody style={{ height: '91vh', maxHeight: '83vh' }}>
                {previewDocs.extension == 'csv' ?
                  <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ height: '78vh', width: '-webkit-fill-available' }} >
                  </object>
                  :
                  ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ?
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.state.previewDocs.url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                    :
                    ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ?
                      <div style={{ backgroundImage: "url(" + previewDocs.url + ")", height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroudPosition: 'center' }}> </div>
                      :
                      previewDocs.extension == 'pdf' ?
                        <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                          <param name="type" value="text/html" />
                        </object> :
                        null
                }
              </ModalBody>
              <ModalFooter>
                <MuiButton variant='contained' color='primary' onClick={(e) => window.open(previewDocs.url)}> Download</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.setState({ previewDocs: '' })}>Okay</MuiButton>
              </ModalFooter>
            </Modal> : null
        }
        {
          addAttachment === true ?
            <Modal isOpen='open'>
              <ModalHeader>Attach Invoice documents</ModalHeader>
              <ModalBody>
                <DropzoneArea
                  filesLimit={5}
                  acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                  showPreviewsInDropzone={false}
                  showPreviews={true}
                  useChipsForPreview
                  dropzoneText={<p>Drag & Drop Pictures<br /> OR <br /> Click Here</p>}
                  dropzoneClass="drag-drop-cnt"
                  maxWidth="sm"
                  showAlerts={['error', 'info']}
                  onChange={(files) => this.onFieldChange('', 'attatchedDoc', files)}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                >
                </DropzoneArea>
                <h6 style={{ marginTop: '5px', fontSize: '13px' }}> <b>Note:</b> Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Max Upto 5 Files )</h6>
              </ModalBody>
              <ModalFooter>
                <MuiButton color='primary' onClick={() => { this.setState({ addAttachment: false, attatchedDoc: [] }) }}>Cancel</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.addAttachment(e)} disabled={!attatchedDoc.length}>Okay</MuiButton>
              </ModalFooter>
            </Modal>
            : null
        }
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={"Change Status"} className="modal-tform-delete-pictures modal-dialog-centered">
          <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to mark this invoice as {this.state.data && this.state.data.status == 3 ? "'paid' ?" : "'cancelled' ?"}</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.toggleDelModal(''); this.props.updateListInvStatus(this.state.data, this.props.contract_slug, this.props.invoice.id, 'list'); }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="invoice-status-modal">
          <ModalHeader toggle={this.toggle}>Change Invoice Status</ModalHeader>
          <ModalBody>
            {this.state.modal ?
              <ChangeInvoiceStatus
                invoice={invoice}
                status={invoice.status}
                updateInvStatus={this.updateInvStatus}
                invoiceType={invoice.invoice_type}
                invoiceStartDate={invoice.start_date ? invoice.start_date : null}
                invoiceEndDate={ctrShortInfo.contract.contractual_end_date}
                // totalAmount={invoice.sub_total}
                totalAmountInv={invoice.sub_total}
              /> : null
            }
          </ModalBody>
        </Modal>
      </Link>
    )
  }
}
