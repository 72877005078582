import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { monthsObj } from '../../../constants';
require('highcharts/modules/exporting')(Highcharts);
const UtilsTrendGraph = ({hour_plots, cycle_plots, avgHours, avgCycles}) => {
  let categories = [], hours = [], cycles = [];
  Object.keys(hour_plots).map((year,index) => {
    categories.push(monthsObj[year.slice(4,6)] + ' ' + year.slice(0,4));
    hours.push(hour_plots[year]);
    cycles.push(cycle_plots[year]);
  });
  const options = {
    chart: {
      type: 'line',
      marginTop: 60,
    },
    title: { text: ''},
    xAxis: {
      categories: categories
    },
    yAxis: {
      title: {
          text: ''
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      }
    },
    series:[
      {
        name: 'Hours',
        data: hours
      },
      {
        name: 'Avg. Hours',
        data: Array(hours.length).fill(parseFloat(avgHours))
      },
      {
        name: 'Cycles',
        data: cycles
      },
      {
        name: 'Avg. Cycles',
        data: Array(hours.length).fill(parseFloat(avgCycles))
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
              }
            }
          ]
        }
      }
    }
  }
  return(
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}
export default UtilsTrendGraph;
