import React, { Fragment, Component } from 'react';
import { Grid } from '@material-ui/core';
import LabelValueCard from './LabelValueCard';
import moment from 'moment';
import {  displayDateTimeFormatShort } from '../../constants';
class RevisionEditCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      tableStatus:false,
    }
  }
  showData = () => {
    this.setState(prevState => ({
      ...prevState,
      tableStatus: !this.state.tableStatus
    }))
  }
  render(){
    const { item } = this.props;
    return(
      <Fragment>
        <Grid container spacing={3}>
          <LabelValueCard md={6} label='Updated at' value={item.timestamp ? moment(item.timestamp).format(displayDateTimeFormatShort) : '--'} />
          <LabelValueCard md={6} label='Updated by' value={item.user && item.user.name ? item.user.name:'--'} />
        </Grid>
        <div className="show-data">
          <span onClick={this.showData}>Show Data <i style={ this.state.tableStatus ? { transform: 'rotate(180deg)' }:  { transform: 'rotate(0)' } }>&#9660;</i></span>
        </div>
        <div className="edit-detail-table" style={this.state.tableStatus ? {display: 'block'} :  {display: 'none' }} >
          <table>
            <thead>
              <tr>
                <th style={{width: '33%'}}>Label</th>
                <th style={{width: '33%'}}>Old Value</th>
                <th style={{width: '33%'}}>New Value</th>
              </tr>
            </thead>
            <tbody>
              { Object.keys(item.changes).map(label =>
                <tr>
                  <td>{label}</td>
                  <td>{item.changes[label][0]}</td>
                  <td>{item.changes[label][1]}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  }
}
export default RevisionEditCard;
