import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { AutocompleteCreatable, PageLoader } from '../../../shared_elements';
import { getSharedInvoiceInfoApi, sendInvoiceMailApi } from '../apiServices';
import emailIcon from '../../../shared/assets/img/send_email_icon.svg';
class ShareAssemblyInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      modal:false,
      sharedInfo:{to_address:[], subject:'',content:''},
      error:{}
    }
    this.getSharedInvoiceInfoApi = getSharedInvoiceInfoApi.bind(this);
    this.sendInvoiceMailApi = sendInvoiceMailApi.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      sharedInfo:{
        ...prevState.sharedInfo,
        [keyParam]:value
      }
    }))
  }
  updateErrorField = (key) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:''
      }
    }))
  }
  sendInvoiceMailFn = () => {
    const { sharedInfo } = this.state;
    let validationInputs = {
      to_address: sharedInfo.to_address.length ? '':'Please enter Email',
      subject: sharedInfo.subject && sharedInfo.subject.trim().length ? '':'Please enter Subject',
      content:sharedInfo.content && sharedInfo.content.trim().length ? '':'Please enter Content'
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.sendInvoiceMailApi(this.props, sharedInfo);
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { modal, sharedInfo, error, pageLoader } = this.state;
    return(
      <Fragment>
        {
          pageLoader ?
          <PageLoader />: null
        }
        <span onClick={() => this.getSharedInvoiceInfoApi(this.props, this.props.invoiceCreatedDetails)} style={{color:'#3f51b5', cursor: 'pointer', fontSize:'14px',borderRight:'1px solid #3f51b5',padding:'0 10px'}}><img style={{width:"16px",paddingBottom:'3px'}} src={emailIcon} alt="export" /> Send Email</span>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Share Invoice
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AutocompleteCreatable
                    options = {[]}
                    paramsKey="to_address"
                    value={sharedInfo.to_address}
                    onFieldChange={this.onFieldChange}
                    resetErrorKey={() => this.updateErrorField('to_address')}
                    optionKey='label'
                    label="Emails"
                    required={true}
                    error={error.to_address}
                    multiple={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="subject"
                    label="Subject"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 255 }}
                    error={error.subject ? true:false}
                    helperText={error.subject ? error.subject:''}
                    value={sharedInfo.subject}
                    onChange={(e, value) => this.onFieldChange(e, 'subject', e.target.value)}
                    onFocus={() => this.updateErrorField('subject')}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="content"
                    label="Content"
                    fullWidth
                    margin="normal"
                    multiline
                    rows="4"
                    inputProps={{ maxLength: 255 }}
                    value={sharedInfo.content}
                    error={error.content ? true:false}
                    helperText={error.content ? error.content:''}
                    onChange={(e, value) => this.onFieldChange(e, 'content', e.target.value)}
                    onFocus={() => this.updateErrorField('content')}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={this.sendInvoiceMailFn}>Share</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ShareAssemblyInvoice));
