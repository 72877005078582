import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
const InvoiceWidget = ({invoiceWidget}) => {
  return(
    <div className="reserve-account-section">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div className="reserve-account-card">
            <h3>Total Paid Invoices</h3>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <p>Reserve Invoice</p>
                <h6>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoiceWidget.mr_total ? invoiceWidget.mr_total:0}</NumericLabel>
                </h6>
                {invoiceWidget.mr_detail_as_of_date ? <span className="date">As of {invoiceWidget.mr_detail_as_of_date}</span>:null }
              </Grid>
              <Grid item xs={12} md={6}>
                <p>Monthly Rentals</p>
                { invoiceWidget.rental_total_new.map((item, index) =>
                  <h6>
                    <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                  </h6>
                )}
                {invoiceWidget.rental_detail_as_of_date ? <span className="date">As of {invoiceWidget.rental_detail_as_of_date}</span> : null }
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="reserve-account-card">
            <h3>Pending Reserve Invoices</h3>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <p>Not Yet Due</p>
                <h6>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoiceWidget.mr_not_due ? invoiceWidget.mr_not_due:0}</NumericLabel>
                </h6>
              </Grid>
              <Grid item xs={12} md={6}>
                <p>Overdue</p>
                <h6>
                  <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoiceWidget.mr_overdue ? invoiceWidget.mr_overdue:0}</NumericLabel>
                </h6>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="reserve-account-card">
            <h3>Pending Rental Invoices</h3>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <p>Not Yet Due</p>
                { invoiceWidget.rental_not_due_new.map((item, index) =>
                  <h6>
                    <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                  </h6>
                )}

              </Grid>
              <Grid item xs={12} md={6}>
                <p>Overdue</p>
                { invoiceWidget.rental_overdue_new.map((item, index) =>
                  <h6>
                    <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
                  </h6>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default InvoiceWidget;
