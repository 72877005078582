import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Paper, Table, TableBody, TableHead, TableCell,  TableRow } from '@material-ui/core';
import { useTableStyle } from '../../../utils_v2/materialStyles';
const STableLoader = ({count, graphcount}) => {
  const classes = useTableStyle();
  return(
    <Paper style={graphcount ? {height:'400px'} : {height:'auto'}}>
      <Table
        className="makeStyles-table"
        aria-labelledby="tableTitle"
        stickyHeader={true}
        size='medium'
      >
        <TableHead className="mui-table">
          <TableRow>
            {Array(graphcount ? graphcount : count).fill(0).map((item, index) =>
              <TableCell align="left">
                <Skeleton variant="rect" animation='wave' height={24} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
        {Array(graphcount ? 5 : 6).fill(0).map((row, index) =>
          <TableRow key={index}>
            {Array(graphcount ? graphcount : count).fill(0).map((item, index) =>
              <TableCell align="left">
                <Skeleton variant="rect" animation='wave' height={34} />
              </TableCell>
            )}
          </TableRow>
        )}
        </TableBody>
      </Table>
    </Paper>
  )
}
export default STableLoader;
