import React, { Component } from 'react';
import { Collapse, CardBody, Card, TabContent, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import { imgStoragePath } from '../../../../constants';
import { withRouter } from 'react-router'
class MRFundProjection extends Component{
  constructor(props){
    super(props);
    this.state={
      collapse: true,
      activeTab: '1'
    };
    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  componentDidMount(){

    this.props.mrFinanceGraph.data.airframe?
    this.setState({
      activeTab: '2'
    }) : (this.props.mrFinanceGraph.data.engines?
    this.setState({
      activeTab: '3'
    }) :(this.props.mrFinanceGraph.data.landing_gear || this.props.mrFinanceGraph.data.landing_gears ?
    this.setState({
      activeTab: '4'
    }) :(this.props.mrFinanceGraph.data.apu ?
    this.setState({
      activeTab: '5'
    }):this.setState({
      activeTab: '1'
    }))))


  if(!this.props.params.type){
    this.setState({
     activeTab: '1'
    })
  }


    if(this.props.params.type == 'aircraft'){
      this.setState({
        activeTab: '1'
      })
     }


    if(this.props.params.type == 'engine'){
        this.setState({
          activeTab: '3'
        })
    }

  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
     this.props.tabIndex(tab);
     this.setState({
       activeTab: tab
     });
    }
  }
  render() {
    const { airframe, engines, apu, landing_gear, landing_gears } = this.props.mrFinanceGraph.data;

    return(
      <div style={{position:'relative'}}>

      <div className="mr-fund-rejection">

        <h5 className="para-ui" onClick={this.toggle}>
          Funds Projection <img style={this.state.collapse ? {transform: 'rotate(0deg)'}: {transform: 'rotate(180deg)'}} src={imgStoragePath+"collapse_arw.png"} alt="icon" />
        </h5>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
              <Nav tabs>
                { this.props.params.type == 'engine' ? null :
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggleTab('1'); }}
                      >
                      Summary
                    </NavLink>
                  </NavItem>
                }
                { airframe ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggleTab('2'); }}
                      >
                      Airframe
                    </NavLink>
                  </NavItem>:null
                }
                { engines ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggleTab('3'); }}
                      >
                      Engines
                    </NavLink>
                  </NavItem>:null
                }
                { landing_gear || landing_gears ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggleTab('4'); }}
                      >
                      Landing Gears
                    </NavLink>
                  </NavItem>:null
                }
                { apu ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => { this.toggleTab('5'); }}
                      >
                      APU
                    </NavLink>
                  </NavItem>:null
                }
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {this.props.children}
              </TabContent>
            </CardBody>
          </Card>
        </Collapse>
      </div>
      </div>
    )
  }
}

export default withRouter(MRFundProjection)
