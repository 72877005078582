import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import { invStatusGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const InvoiceStatusGraph = ({ invoiceStatGraph, skeletonLoader, filter, getResponseBack, callExternalFunction }) => {
    var colors = invoiceStatGraph && invoiceStatGraph.color;
    let filterOptions = Object.assign({}, invStatusGraphFilterOps);
    const options = {
        chart: {
            type: 'column',
            zoomType: 'xy',
            panning: true,
            panKey: 'shift'
        },
        title: {
            text: 'Based on Type',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${invoiceStatGraph.title ? invoiceStatGraph.title : ''}`,
            align: 'left'
        },
        xAxis: {
            categories: invoiceStatGraph && invoiceStatGraph.invoice_status && invoiceStatGraph.invoice_status.x_axis && invoiceStatGraph.invoice_status.x_axis.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Invoices'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series:{
                point: {
                    events: {
                      click: function() {
                        callExternalFunction(this.category, '', 'basedOnType');
                      }
                    }
                },
            }
        },
        colors: colors,
        legend: {
            enabled: false
        },
        series: [{
            name:'Invoice',
            data: invoiceStatGraph && invoiceStatGraph.invoice_status && invoiceStatGraph.invoice_status.y_axis,
            colorByPoint: true,
        }],
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                }
            },
            filename: 'Invoice(s)-Based on Type Chart',
         }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
            
        </Paper>
    )
}
export default InvoiceStatusGraph
