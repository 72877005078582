import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { displayDateFormat, fieldDateFormat, backendDateFormat } from '../../../constants';
export default class FieldDate extends Component{
  constructor(props){
    super(props);
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }

  updateField  = (key, value) => {
    this.props.updateField(key, value == "Invalid date" ? null : value);
  }
  render(){
    const { type, value, keyParam, maxDate, minDate, focus, blur } = this.props;
    if(type != 'view'){
      return(
        <DatePicker
          dateFormat = {fieldDateFormat}
          selected={ value && value != "Invalid date" && moment(value).isValid() ? moment(value): null }
          onChange={(e) => {this.updateField(keyParam, moment(e).format(backendDateFormat))}}
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          onFocus={focus ? () => focus() : () =>  this.handleFocus()}
          onBlur={blur ? () =>  blur() : () =>  this.handleBlur()}
          maxDate = {maxDate}
          minDate = {minDate }
          disabled={this.props.disable? true:false}
        />
      )
    }else{
      return(
        <h6>{value ? moment(value).format(displayDateFormat):'--'}</h6>
      )
    }

  }
}
