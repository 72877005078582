import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
const AccountFunds = ({cashflowWidget}) => {
  return(
    <div className="reserve-account-section">
      <Grid container spacing={2}>
        { cashflowWidget.mr_reserve_account ?
          <Grid item xs={12} md={2}>
            <div className="reserve-account-card">
              <h3>Total MR Fund</h3>
              <p>
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.mr_reserve_account.mr_val ? cashflowWidget.mr_reserve_account.mr_val:0}</NumericLabel>
              </p>
              {cashflowWidget.mr_reserve_account.mr_as_of_date ? <span className="date">As of {cashflowWidget.mr_reserve_account.mr_as_of_date}</span>:null}
            </div>
          </Grid>:null
        }

        <Grid item xs={12} md={2}>
          <div className="reserve-account-card">
            <h3>Total Monthly Rental Fund</h3>
            { cashflowWidget.rental_new.map((rental) =>
              <p>
                <NumericLabel params={getCurrencyFormat({currency: rental.currency, shortFormat:false})}>{rental.total_amount ? rental.total_amount:0}</NumericLabel>
              </p>
            )}
            { cashflowWidget.rental_detail_as_of_date ? <span className="date">As of { cashflowWidget.rental_detail_as_of_date }</span>:null}
          </div>
        </Grid>
        { cashflowWidget.esp_reserve_account && cashflowWidget.mr_reserve_account.esp_val ?
          <Grid item xs={12} md={2}>
            <div className="reserve-account-card">
              <h3>Total ESP Fund</h3>
              <p>
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.esp_reserve_account.esp_val ? cashflowWidget.esp_reserve_account.esp_val:0}</NumericLabel>
              </p>
              {cashflowWidget.esp_reserve_account.esp_as_of_date ? <span className="date">As of {cashflowWidget.esp_reserve_account.esp_as_of_date}</span>:null}
            </div>
          </Grid>:null
        }
        { cashflowWidget.lsp_reserve_account && cashflowWidget.lsp_reserve_account.lsp_val ?
          <Grid item xs={12} md={2}>
            <div className="reserve-account-card">
              <h3>Total LSP Fund</h3>
              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.lsp_reserve_account.lsp_val ? cashflowWidget.lsp_reserve_account.lsp_val:0}</NumericLabel>
              {cashflowWidget.lsp_reserve_account.lsp_as_of_date ? <span className="date">As of {cashflowWidget.lsp_reserve_account.lsp_as_of_date}</span>:null}
            </div>
          </Grid>:null
        }
        { cashflowWidget.jsp_reserve_account && cashflowWidget.jsp_reserve_account.jsp_val ?
          <Grid item xs={12} md={2}>
            <div className="reserve-account-card">
              <h3>Total JSP Fund</h3>
              <p>
                <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowWidget.jsp_reserve_account.jsp_val ? cashflowWidget.jsp_reserve_account.jsp_val:0}</NumericLabel>
              </p>

              {cashflowWidget.jsp_reserve_account.jsp_as_of_date ? <span className="date">As of {cashflowWidget.jsp_reserve_account.jsp_as_of_date}</span>:null}
            </div>
          </Grid>:null
        }
      </Grid>
    </div>
  )
}
export default AccountFunds;
