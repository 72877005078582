
import * as actions from '../actions';
import { browserHistory } from 'react-router';
import produce from 'immer';
export default function( state = {
  dashSw: false,
  bugReport: {
    flag: false,
    data: {
      title: '',
      description: '',
      severity: '',
      module: '',
      classification:'',
      attachments: []
    }
  },
  featuresModal:false,
  techConstantsHeader:[],
  techConstants:[],
  toastLists: [],
  sRegionList: [],
  filepreviewLoader: false,
  techExportLoader: false,
  bugLoader:false,
  contractSummaryLoader:false,
  aircraftTypes: [],
  engineManufacturerList: [],
  lgManufacturerList: [],
  aircraftManufacturerList: [],
  apucraftManufacturerList: [],
  sEngineTypes: [],
  sLessorList: [],
  sManufacruerList: [],
  sLesseeList: [],
  sOperatorList: [],
  sIntervals: [],
  sEscaltionInterval: [],
  sOffWingStatus: [],
  sMrRangeCondn: [],
  sPartyRole: [],
  sInsuranceType: [],
  sLeaseOptions: [],
  sSecurityDepositeType: [],
  sLeaseTypeAccounts: [],
  sLeaseType: [],
  sLeaseStatus: [],
  sAircarftOperatn: [],
  sShopVisitStatus: [],
  auditLogView: {
    flag: false,
    type: '',
    data: {}
  },
  msnSwitcher:[],
  dataBucket: [],
  sharedView: [],
  faqsList:[],
  featuresList:[],
  sidebarFlag: false,
  downloadLoader:false,
  closeMaintenance: true,
  maintenanceBarHeight: 0,
  subHeaderTop: document.body.contains(document.getElementById('header')) ?document.getElementById('header').clientHeight : 0,
  recordNavTop: 0,
  technicalAssetRptInfo: {},
  notificationsGif:false,
  notificationsGifClick:false,
  buyLicenceModal: {
    flag: false,
    action: '',
    content: ''
  },
  failedPayment: false,
  notifications:{
    card_count: 0,
    notification_count: 0,
  },
  notificationOpen: false
}, action){
  switch (action.type) {
    case actions.NOTIFICATIONS_COUNT:
      // if(!action.payload.hasOwnProperty('show')){
      //   action.payload = { ...action.payload, show: true }
      // }
      return {
        ...state,
        notifications: action.payload,
      }
    case actions.NOTIFICATION_FLAG:
      return {
        ...state,
        notificationOpen: action.payload,
      }
    case actions.NOTIFICATIONS_COUNT_GIF:
      return {
        ...state,
        notificationsGif:state.notificationsGifClick?true:true
      }
    case actions.NOTIFICATIONS_COUNT_UPDATE:
      return {
        ...state,
        notificationsGif:false,
        notificationsGifClick:true
     }
    case actions.FAQ_DETAILS_LIST:
      return {
        ...state,
        faqsList: action.payload
      }
      case actions.FEATURES_LIST:
        return {
          ...state,
          featuresList: action.payload
        }
    case actions.BUY_LICENCE:
      return {
        ...state,
        buyLicenceModal: action.payload
      }
    case actions.PAYMENT_FAILED:
      return {
        ...state,
        failedPayment: action.payload
      }
    case actions.CLEAR_TOAST:
      return {
        ...state,
        toastLists: []
      }
    case actions.SHOW_TOAST:
      if(browserHistory.getCurrentLocation().pathname.includes('/user/activate/')
        || browserHistory.getCurrentLocation().pathname.includes('/forgot-password')){
        return state;
      }
      if(action.payload.toastType == 'error'){
        if(state.toastLists.filter(toast => toast.toastType == 'error').length < 1){
          return {
            ...state,
            toastLists: produce(state.toastLists, draft => {
              draft.push(action.payload)
            })
          }
        }else{
          return state;
        }
      }else{
        return {
          ...state,
          toastLists: produce(state.toastLists, draft => {
            draft.push(action.payload)
          })
        }
      }

    case actions.HIDE_TOAST:

      if(state.toastLists){
        return {
          ...state,
          toastLists: produce(state.toastLists, draft => {
            draft.splice(0,1);
          })
        }
      }else{
        return state;
      }

    case actions.S_CLOSE_TOAST_NOTIFY:
      return {
        ...state,
        toastLists: produce(state.toastLists, draft => {
          draft.splice(0,action.payload);
        })
      }
    case actions.S_REGION_LIST:
      return {
        ...state,
        sRegionList: action.payload
      }
    case actions.S_ARCT_TYPE_LIST:
      return {
        ...state,
        aircraftTypes: action.payload
      }
    case actions.S_MANUFACTURER_LIST:
      return {
        ...state,
        sManufacruerList: action.payload
      }
    case actions.S_ENGINE_TYPE_LIST:
      return {
        ...state,
        sEngineTypes: action.payload
      }
    case actions.S_LESSOR_LIST:
      return {
        ...state,
        sLessorList: action.payload
      }
    case actions.S_LESSEE_LIST:
      return {
        ...state,
        sLesseeList: action.payload
      }
    case actions.S_OPERATOR_LIST:
      return {
        ...state,
        sOperatorList: action.payload
      }
    case actions.S_GLOBAL_CONSTANTS:
    return {
      ...state,
      techConstants:action.payload.res,

    }
    case actions.HEADER_GLOBAL_CONSTANTS:
    return {
      ...state,
      techConstantsHeader:action.payload.res
    }
    case actions.S_AUDITLOG_ADD_DEL_VIEW:
    return {
      ...state,
      auditLogView: action.payload
    }
    case actions.TECH_EXPORT_LOADER:
    return {
      ...state,
      techExportLoader: action.payload
    }

    case actions.LOAD_MSN_SWITCHER:
      return{
        ...state,
        msnSwitcher: action.payload
      }
    case actions.LOAD_DATA_BUCKET:
      return{
        ...state,
        dataBucket: action.payload.result.filter(item => item.slug == action.payload.slug)
      }
    case actions.EMPTY_BUCKET:
      return{
        ...state,
        dataBucket: action.payload
      }
    case actions.GET_SHARED_VIEW:
      return{
        ...state,
        sharedView: action.payload
      }
    case actions.BUG_REPORT:
      return{
        ...state,
        bugReport:{...state.bugReport,
          flag:!state.bugReport.flag
        }

      }
    case actions.BUG_LOADER:
      return{
        ...state,
        bugLoader:!state.bugLoader
      }
    case actions.SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarFlag: action.payload
      }
    case actions.DOWNLOAD_LOADER:
      return {
        ...state,
        downloadLoader: action.payload
      }
    case actions.PREVIEW_LOADER:
      return {
        ...state,
        filepreviewLoader: action.payload
      }
    case actions.FIXED_POSITIONS_VALUE:
      return{
        ...state,
        [action.payload.type]: action.payload.position
      }
    case actions.TECHNICAL_ASSET_RPT:
      return {
        ...state,
        technicalAssetRptInfo: action.payload
      }
    case actions.CONTRACT_EXPORT_LOADER:
      return {
        ...state,
        contractSummaryLoader: action.payload
      }
      case actions.FEATURE_MODAL:
        return {
          ...state,
          featuresModal:action.payload
        }
  case actions.DB_SWITCHER:
    return {
      ...state,
      dashSw: !state.dashSw
    }
    case actions.OWNERS_LIST:
      return {
        ...state,
        ownersList: action.payload
      }
  default:
      return state;
  }
}
