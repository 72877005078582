import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../constants';
import { getAccessToken, getLocalStorageInfo } from '../../utils';
import { jetStreamInstance } from '../../lease_management/Leases';
class AssetViewInfo extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { assetView, params } = this.props;
    const userInfo = getLocalStorageInfo()
    if(Object.keys(assetView).length){

      return(
        <div className={params.type == 'engine'  ? "asset-detail-info esn" : params.type == 'aircraft' ? "asset-detail-info msn" : "asset-detail-info apu" }   style={
          params.type == 'fan' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/engine_asset.png)' }:
            params.type == 'hpc' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/engine_asset.png)' } :
            params.type == 'hpt' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/engine_asset.png)' } :
            params.type == 'lpt' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/engine_asset.png)' } :
            params.type == 'engine'  ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/engine_asset.png)'} :
            params.type == 'aircraft' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/background_plane.png)'}:
            params.type == 'apu' ? { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/background_apu.png)'}:
            params.type == 'lg' ?{ background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/icon_lg.png)'} :
            { background: 'url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/background_propeller.png)'}
          }>
          <div className="back-navigation">
              <ul>
                <li style={{marginBottom: '5px'}}></li>
                <li><Link to="/assets-listing">Listing</Link> / Asset View</li>
                <li className="gama asset-view-heading">Asset View</li>
              </ul>
          </div>
          <div className="aircraft-engine-type">
            <span>
              {
                assetView.aircraft_type && assetView.aircraft_type.name ? assetView.aircraft_type.name + (assetView.model ? '-'+assetView.model:'' ) :
                assetView.engine_type && assetView.engine_type.name ? assetView.engine_type.name : assetView.apu_type && assetView.apu_type.name ? assetView.apu_type.name : ''
               }

            </span>
            <h1>
              {params.type == 'engine' ? 'ESN ' + assetView.esn : params.type == 'aircraft' ? 'MSN ' + assetView.msn : this.props.params.type == 'apu' ? 'APU ' + assetView.serial_number : this.props.params.type == 'lg' ? 'LG ' + assetView.serial_number : this.props.params.type == 'propeller' ? 'PROPELLER ' + assetView.serial_number : this.props.params.type == 'fan' ? 'FAN' + assetView.esn : this.props.params.type == 'hpc' ? 'HPC' + assetView.esn : this.props.params.type == 'hpt' ? 'HPT' + assetView.esn : 'LPT' + assetView.esn }
            </h1>
            {
              getLocalStorageInfo().user.permission['technical_specs'] ?
              <Link target="_blank" to={"https://sparta.aero/technical-specification/"+this.props.params.type+"/" +assetView.slug}>
                <img width="20" src={ params.type == 'engine' ?imgStoragePath+"icon_engine_filled.png":imgStoragePath+"aircraft.png"} alt="tech summery" /> Technical Specification
              </Link>:null
            }
          </div>
          <div className="">
            <Row>
              <Col xs="6" className="parts-info">
                <span className="label-title">{jetStreamInstance?'Lessor/Holdco':'Lessor'}</span>
               { assetView.lessor_name && assetView.lessor_name.name?
                assetView.lessor_name.name : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.name :'--'}
              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">{ getLocalStorageInfo().defaultLessor.id === 442?'Owner/SPV':'Owner'}</span>
                <h6>{assetView.owner && assetView.owner.name ? assetView.owner.name : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.name :'--'}</h6>
              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">Lessee</span>
                {assetView.lessee ? <img style={{width: '120px'}} src={assetView.lessee.logo} alt={assetView.lessee.name} />:'--' }
              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">Credit Classification</span>
                {
                  assetView.credit_classification ?
                  <span className={ assetView.credit_classification == 1 ? 'flag-label green' :  assetView.credit_classification == 3 ? 'flag-label red' : 'flag-label yellow' }>
                    <img src={ assetView.credit_classification == 1 ? imgStoragePath+"green_flag.png" :  assetView.credit_classification == 3 ? imgStoragePath + "red_flag.png"  : assetView.credit_classification == 2 ? imgStoragePath + "yellow_flag.png" : null } alt="img" width="10"/>
                    {
                      assetView.credit_classification == 1 ?
                       'Green' :  assetView.credit_classification == 3 ?
                       'Red' : assetView.credit_classification == 2 ? 'Yellow' : '--'
                    }
                </span>
                : '--'
                }

              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">Date of Manufacture</span>
                {params.type == 'engine' ?
                <h6>{assetView.manufacturing_date ? moment(assetView.manufacturing_date).format(displayDateFormat):'--'}</h6>:
                <h6>{assetView.date_of_manufacture ? moment(assetView.date_of_manufacture).format(displayDateFormat):'--'}</h6>}
              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">{assetView.asset_type == 1 ? 'Country' : 'Region of Operation'}</span>
              <h6>{assetView.asset_type == 1 ? assetView.country ? assetView.country : '--' : assetView.region && assetView.region.name ? assetView.region.name : '--'}</h6>
              </Col>
               {
                params.type == 'engine' || this.props.params.type == 'fan' || this.props.params.type == 'hpc' || this.props.params.type == 'hpt' || this.props.params.type == 'lpt' ? null :
                  <Col xs="6" className="parts-info">
                    <span className="label-title">Reg. No.</span>
                    <h6>{assetView.current_registration_number ? assetView.current_registration_number : '--'}</h6>
                  </Col>
               }

                {
                 params.type == 'engine' ?
                  <Col xs="6" className="parts-info">
                    <span className="label-title">Serial No.</span>
                    <h6>{assetView.esn ? assetView.esn : '--'}</h6>
                  </Col> : null
               }
              <Col xs="6" className="parts-info">
                <span className="label-title">TSN</span>
                <h6>{assetView.tsn?assetView.tsn:"--"}</h6>
              </Col>
              <Col xs="6" className="parts-info">
                <span className="label-title">CSN</span>
                <h6>{assetView.csn?assetView.csn:"--"}</h6>
              </Col>
              {
                 params.type == 'engine' ?
                 <Col xs="6" className="parts-info">
                 <span className="label-title">Engine Model</span>
                 <h6>{assetView.engine_type && assetView.engine_type.name ?  assetView.engine_type.name :"--"}</h6>
               </Col>: null
             }
               {
                 params.type == 'engine' ?
                 <Col xs="6" className="parts-info">
                 <span className="label-title">Lease Expiry</span>
                 <h6>{assetView.contract && assetView.contract.contractual_end_date ? moment(assetView.contract.contractual_end_date).format(displayDateFormat) :"--"}</h6>
               </Col>: null
             }

            </Row>


            {
              params.type == 'aircraft' && assetView.engines && assetView.engines.length ?
              <div className="engines-info">
                {
                  assetView.engines.filter(engine => engine.is_titled ==true).length ?
                  <h5>Titled Engines</h5>:null
                }

                {
                  assetView.engines.filter(engine => engine.is_titled ==true).map(engine =>
                    <Row key={engine.position}>
                      <Col xs="6" className="parts-info">
                        <span className="label-title">Engine {'#'+engine.position} Model</span>
                        <h6>{engine.engine_type.name}</h6>
                      </Col>
                      <Col xs="6" className="parts-info">
                        <span className="label-title">Engine {'#'+engine.position} ESN</span>
                        <h6>{engine.esn}</h6>
                      </Col>
                    </Row>
                  )
                }

                {
                  assetView.engines.filter(engine => engine.is_titled ==false && engine.is_fitted ==true).length ?
                  <h5>Fitted Engines</h5>:null
                }
                {
                  assetView.engines.filter(engine => engine.is_titled ==false && engine.is_fitted ==true).map(engine =>
                    <Row>
                      <Col xs="6" className="parts-info">
                        <span className="label-title">Engine {'#'+engine.position} Model</span>
                        <h6>{engine.engine_type.name}</h6>
                      </Col>
                      <Col xs="6" className="parts-info">
                        <span className="label-title">Engine {'#'+engine.position} ESN</span>
                        <h6>{engine.esn}</h6>
                      </Col>
                    </Row>
                  )
                }
              </div>:null
            }
            {
              userInfo.defaultLessor.lessor_type != 3 && userInfo.user.permission.technical_specs && userInfo.user.permission.technical_specs.asset && userInfo.user.permission.technical_specs.asset.indexOf('TFR') != -1 && assetView.data_transfer && assetView.data_transfer.value != 2 ?
              <div className="engines-info">
                <h5>Asset Transfer</h5>
              <Row>
                <Col xs="12" className="parts-info">
                  <span className="label-title">
                    Sold or transferred an asset to another lessor? Here is an easy way to transfer the data to the
next { getLocalStorageInfo().defaultLessor.id === 442?'owne/SPV':'owner'} seamlessly. <Link to={`/asset-view/${this.props.params.type}/${this.props.params.aircraft_slug}/faqs`}>Read more </Link> or click  <button onClick={() => this.props.toggleTransfer(true)} className="aims-primary-button">{(assetView.data_transfer == undefined || assetView.data_transfer == null || Object.keys(assetView.data_transfer).length < 1) ? 'Initiate' : ''} Transfer</button>
                  </span>
                </Col>
              </Row>
              </div>
              :null
            }
          </div>
        </div>
      )
    }else{
      return (<div className="asset-detail-info"><h5>Loading...</h5></div>)
    }
  }
}

export default withRouter(AssetViewInfo)
