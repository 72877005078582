import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addInsurance = {
  insurance_type: '',
  insurance_number: '',
  insuree: '',
  insurer: '',
  policy_start_date:null,
  policy_end_date:null,
  geo_limit: '',
  insurance_amount: '',
  currency	: 'USD',
  effective_date: null,
  primary_contact: '',
  remarks: ''
}
export const insuranceHd = [
  { label: 'Type', sortOption: false },
  { label: 'Insurance Number', sortOption: false },
  { label: 'Insurer', sortOption: false },
  { label: 'Insuree', sortOption: false },
  { label: 'Policy Period', sortOption: false },
  { label: 'Amount (USD)', sortOption: false },
  { label: 'Action', sortOption: false },
];
export const addClaim = {
  insurance_number: '',
  claim_summary: '',
  date: null,
  status: '',
  amount: '',
  currency: 'USD',
  primary_contact: '',
  remarks: '',
  insurer: '',
  insuree : ''
}
export const claimHd = [
  { label: 'Insurance Number', sortOption: false },
  { label: 'Insurer', sortOption: false },
  { label: 'Claim Details', sortOption: false },
  { label: 'Date of Claim Closure', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'Amount (USD)', sortOption: false },
  { label: 'Action', sortOption: false },
];
export const errorCode = {

  insurance_type:{
    0:'',
    1: 'Please select Insurance Type'
  },
  insurance_typeObj: {
    required: true
  },
  insurance_number:{
    0:'',
    1: 'Please enter Insurance Number'
  },
  insurance_numberObj: {
    required: true
  },
  insurer: {
    0:'',
    1: 'Please enter Insurer'
  },
  insurerObj: {
    required: true
  },
  insuree: {
    0:'',
    1: 'Please enter Insuree'
  },
  insureeObj: {
    required: true
  },
  date: {
    0:'',
    1: 'Please enter Date of Claim Closure '
  },
  dateObj: {
    required: true
  },
  claim_number: {
    0:'',
    1: 'Please enter Claim Number'
  },
  claim_numberObj: {
    required: true
  },
  rejection_reason: {
    0:'',
    1: 'Please enter Reason of Rejection'
  },
  rejection_reasonObj: {
    required: true
  },
  approver_id: {
    0:'',
    1: 'Please select Approver'
  },
  approver_idObj: {
    required: true
  },
  claim_summary: {
    0:'',
    1: 'Please enter Claim Details'
  },
  claim_summaryObj: {
    required: true
  },
  status: {
    0:'',
    1: 'Please select Claim Status'
  },
  statusObj: {
    required: true
  },
  amount: {
    0:'',
    1: 'Please enter Amount'
  },
  amountObj: {
    required: true
  },
  insurance_amount: {
    0:'',
    1: 'Please enter Amount'
  },
  insurance_amountObj: {
    required: true
  },
  policy_start_date: {
    0:'',
    1: 'Please enter Policy Start Date'
  },
  policy_start_dateObj: {
    required: true
  },
  policy_end_date: {
    0:'',
    1: 'Please enter Policy End Date'
  },
  policy_end_dateObj: {
    required: true
  },
}
