import React, { Component } from 'react';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
import { getAccessToken } from '../../../../utils';
import { jetStreamInstance } from '../../../../lease_management/Leases';
export default class LeaseHistory extends Component{
  render(){
    const { leaseHistory } = this.props;
    return(
      <li className="list-inline-item">
        <span className="dot"></span >
        <span className="line"> </span>
        <div className="timeline-detail-block">
          <h4>{jetStreamInstance && leaseHistory.type==='Lessor'?"Lessor/Holdco":leaseHistory.type} </h4>
          <p className="caption-ui">{leaseHistory.lessor_name ? leaseHistory.lessor_name :leaseHistory.name}</p>
          {
            leaseHistory.type == 'Lessee' ?<h5>{moment(leaseHistory.start_date).format(displayDateFormat)} to {moment(leaseHistory.end_date).format(displayDateFormat)}</h5>:null
          }

          {
            leaseHistory.logo !== null && leaseHistory.logo !== undefined ?
              <img src={leaseHistory.logo} width="90" alt="img"/>
            :null
          }
        </div>
      </li>
    )
  }
}
