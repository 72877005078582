import React from 'react';
import { withRouter, browserHistory } from 'react-router';
import { Button } from '@material-ui/core';
const EditInvoiceCTA = ({onDelete, onSave, params}) => {
  return(
    <ul className="list-inline" style={{padding:'10px 0'}}>
      <li className="list-inline-item">
        <Button onClick={onSave} color="primary" variant="contained">SAVE</Button>
      </li>
      <li className="list-inline-item">
        <Button onClick={() => browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/invoice/view/${params.id}`)} color="primary" variant="outlined">CANCEL</Button>
      </li>
      <li className="list-inline-item">
        <Button onClick={onDelete} color="secondary" variant="contained">Delete</Button>
      </li>
    </ul>
  )
}
export default withRouter(EditInvoiceCTA);
