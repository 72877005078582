import React, { Fragment, useState } from 'react'
import { ArrowLeft, Cancel, Clear, Edit, Close, Info, KeyboardArrowDown } from '@material-ui/icons'
import { invoiceDescList } from '../../../constants';
import { Row, Col, Modal, ModalHeader, ModalBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalFooter } from 'reactstrap';
import exportIcon from '../../../shared/assets/img/export_icon.svg';
import ChangeInvoiceStatus from '../../../applications/contracts/Invoice/components/ChangeInvoiceStatus';
import InvoiceInfo from '../../../applications/contracts/Invoice/components/InvoiceInfo';
import ViewInvoice from './ViewInvoice';
import backArrowIcon from '../../../shared_elements/assets/img/back_arrow.svg';
import EditInvoice from './EditInvoice';
import { imgStoragePath } from '../../../constants';
import ShareAssemblyInvoice from './ShareAssemblyInvoice';
import { checkPermission, getLocalStorageInfo } from '../../../utils_v2';
import { Dialog, DialogContent, DialogTitle, Paper, Tooltip, Typography, Button, Grid } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import { DeleteModal } from '../../../applications/technicalInspection/Elements';
import { browserHistory } from 'react-router';
import { PageLoader } from '../../../shared_elements';
export default function InvoiceDetails({ invoiceCreatedDetails,leaseType,  detailsPageLoader, setStatusChange, statusChange, showStatusTimeLine, setshowStatusTimeLine, deleteInvoiceFn, deleteInvoice, setDeleteInvoice, getInvoiceStatusTimeline, setDetailsPage, detailsPage, exportInvoice, onChangeInvoiceDetails, onSubmitInvoiceDetails, onInvoiceStatusChangeApi, changeStatusModal, setChangeStatusModal, invoiceValidationMsgs, updateInvoiceErrorField, getSharedInvoiceInfoApi }) {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggleExport = () => {
        setDropdownOpen(!dropdownOpen)
    }
    const invoiceTypeInfo = {
        1: 'Draft',
        2: 'Sent',
        3: 'Partially-Paid',
        4: 'Paid',
        5: 'Cancelled',
    }
    const diff = invoiceCreatedDetails?.total_amount - invoiceCreatedDetails?.partial_paid_amount
    const redirectTolist =()=>{
          let location = browserHistory.getCurrentLocation();
          location = {
              ...location,
              query: {}
            }
            browserHistory.push(location)
            setTimeout(() => {
                window.location.reload()  
            }, 100);
      }
    return (
        <Fragment>
            <Paper style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', marginBottom: '10px', cursor: 'pointer' }} onClick={() => detailsPage?.type == 'edit' ? setDetailsPage({ type: 'view' }) :  redirectTolist()  }>
                        <img width="16" src={backArrowIcon} alt="Back Icon" style={{ marginRight: '3px' }} />
                       {
                        invoiceCreatedDetails?.invoice_type ?
                        <div className="inv-back-info">
                            <span className='invoice-details-heading-type'>{invoiceCreatedDetails?.invoice_type?.label + ' Invoice'}</span><br />
                            <h4 className='invoice-number-heading'>Invoice {'#' + invoiceCreatedDetails?.invoice_number}</h4>
                        </div>
                        : null}
                    </div>
                    <div>
                        { detailsPage.type == 'view' ?
                            <>
                                {(invoiceCreatedDetails?.status?.value == '1' || invoiceCreatedDetails?.status?.value == '2') && checkPermission('assembly_lease','invoice','U') ?
                                    <span style={{ color: '#3f51b5', borderRight: '1px solid', padding: '0px 10px', cursor: 'pointer' }} onClick={() => setDetailsPage({ type: 'edit' })} > <Edit style={{ fontSize: '14px' }} /> Edit</span>
                                    : null
                                }
                                {(invoiceCreatedDetails.status?.value == '1' || invoiceCreatedDetails.status?.value == '2') && checkPermission('assembly_lease','invoice','U') ?
                                    <ShareAssemblyInvoice invoiceCreatedDetails={invoiceCreatedDetails} /> : null
                                }
                                { (invoiceCreatedDetails.status?.value == '1' || invoiceCreatedDetails.status?.value == '2') && checkPermission('assembly_lease','invoice','D') ?
                                    <span style={{color:'#ff0000', fontSize:'14px'}} onClick={() => setDeleteInvoice({ modal: true, data: invoiceCreatedDetails })}>  Delete</span>:null
                                }
                                {checkPermission('assembly_lease','invoice','EXP') ?
                                    <div className="download-block" style={{ paddingRight: '15px', float: 'right', marginTop: '-2px' }}>
                                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleExport}>
                                            <DropdownToggle caret size="sm">
                                                <span><img width="12" src={exportIcon} alt="export" /> Export </span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => exportInvoice(invoiceCreatedDetails)}><img src={imgStoragePath + "pdf_icon.png"} alt="img" />PDF</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </div>:null
                                }
                                
                            </>:
                            detailsPage.type == 'edit' ?
                            <>
                                <Button style={{ marginRight: '10px' }} size='small' color='primary' onClick={() => setDetailsPage({ type: 'view' })}>Cancel</Button>
                                <Button style={{ marginRight: '10px' }} size='small' color='primary' variant='contained' onClick={() => onSubmitInvoiceDetails()}>Save</Button>
                            </> : null
                        }
                    </div>
                </div>
                {
                    detailsPage.type == 'view' ?
                        <ViewInvoice
                        leaseType={leaseType}
                            getInvoiceStatusTimeline={getInvoiceStatusTimeline}
                            // getSharedInvoiceInfoApi={getSharedInvoiceInfoApi}
                            setChangeStatusModal={setChangeStatusModal}
                            onInvoiceStatusChangeApi={onInvoiceStatusChangeApi}
                            setDetailsPage={setDetailsPage}
                            invoiceCreatedDetails={invoiceCreatedDetails}
                        />
                        :
                        detailsPage.type == 'edit' ?
                            <EditInvoice
                                updateErrorField={updateInvoiceErrorField}
                                validationMsgs={invoiceValidationMsgs}
                                onSubmitInvoiceDetails={onSubmitInvoiceDetails}
                                onChangeInvoiceDetails={onChangeInvoiceDetails}
                                setDetailsPage={setDetailsPage}
                                invoiceCreatedDetails={invoiceCreatedDetails}
                            />
                            : null
                }
                {
                    changeStatusModal ?
                        <Modal isOpen={changeStatusModal} toggle={() => setChangeStatusModal(false)} className="invoice-status-modal">
                            <ModalHeader toggle={!changeStatusModal} >
                                <span style={{ display: 'flex' }} >Change Invoice Status
                                    <Clear style={{ marginLeft: '340px', cursor: "pointer" }} onClick={() => setChangeStatusModal(false)} />
                                </span>
                            </ModalHeader>
                            <ModalBody>
                                {
                                    changeStatusModal ?
                                        <ChangeInvoiceStatus
                                            invoiceInfo={invoiceCreatedDetails}
                                            status={invoiceCreatedDetails.status?.value}
                                            updateInvStatus={(data) => setStatusChange({ data: data, modal: true })}
                                            invoiceType={invoiceCreatedDetails.invoice_type?.value}
                                            invoiceStartDate={invoiceCreatedDetails?.start_date}
                                            invoiceEndDate={invoiceCreatedDetails?.end_date}
                                            totalAmount={invoiceCreatedDetails?.total_amount}
                                            diff={diff}
                                        /> : null
                                }
                            </ModalBody>
                        </Modal> : null
                }
                {
                    statusChange?.modal ?
                        <DeleteModal isOpen={statusChange?.modal} toggle={() => setStatusChange({})} title={`Change status`} className="modal-tform-delete-pictures">
                            <div style={{ textAlign: 'center' }}>
                                Are you sure you want to change the invoice status?
                                {/* to { invoiceTypeInfo[statusChange?.data?.status]} ?  */}

                            </div>
                            <ul className="list-inline" style={{ textAlign: 'center' }}>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-danger" onClick={() => { onInvoiceStatusChangeApi(statusChange?.data, invoiceCreatedDetails); setStatusChange({ modal: false }) }}>Yes</button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-secondary" onClick={() => setStatusChange({})}>Cancel</button>
                                </li>
                            </ul>
                        </DeleteModal>
                        : null
                }
                {
                    deleteInvoice?.modal ?
                        <DeleteModal isOpen={deleteInvoice?.modal} toggle={() => setDeleteInvoice({})} title={`Delete Invoice`} className="modal-tform-delete-pictures">
                            <div style={{ textAlign: 'center' }}>
                                Are you sure you want to delete this Invoice?
                            </div>
                            <ul className="list-inline" style={{ textAlign: 'center' }}>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-danger" onClick={() => deleteInvoiceFn(invoiceCreatedDetails)}>Yes</button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-secondary" onClick={() => setDeleteInvoice({})}>Cancel</button>
                                </li>
                            </ul>
                        </DeleteModal>
                        : null
                }
                {
                    showStatusTimeLine.modal ?
                        <Dialog
                            maxWidth='md'
                            style={{ height: '600px' }}
                            open={true}
                            onClose={() => setshowStatusTimeLine({})}
                            aria-labelledby="scroll-dialog-title"
                            className='invoice-timeline-modal'
                        >
                            <DialogTitle id="scroll-dialog-title">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h5>
                                        Invoice Status Timeline
                                    </h5>
                                    <span style={{ cursor: 'pointer' }}>
                                        <Close onClick={() => setshowStatusTimeLine({})} />
                                    </span>
                                </div>
                            </DialogTitle>
                            <DialogContent dividers={true} style={{ height: '600px' }}>
                                <div>
                                    <Timeline align="left" >
                                        {showStatusTimeLine?.data.map((item, index) =>
                                            <TimelineItem>
                                                <TimelineOppositeContent > </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot className={item.current_status} />
                                                    {
                                                        (showStatusTimeLine?.data?.length - 1) > index ?
                                                            <>
                                                                <TimelineConnector />
                                                                <span className='arrow-corner-block-div' > {'>'}</span>
                                                            </>
                                                            : null}
                                                </TimelineSeparator>
                                                <TimelineContent>

                                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                                        <span
                                                            className={item.current_status}
                                                            style={{ padding: '1px 20px', width: 'max-content' }}
                                                        >
                                                            {item.current_status}
                                                        </span>
                                                        {
                                                            item?.status_change_reason ?
                                                                <Tooltip style={{ maxWidth: '10px' }} title={`Reason: ${item?.status_change_reason ? item?.status_change_reason : '--'}`} arrow placement='right'>
                                                                    <img style={{ width: '13px', marginLeft: '4px', cursor: 'pointer' }} src={imgStoragePath + "inv_info_icon.png"} alt="info" />
                                                                </Tooltip>
                                                                : null
                                                        }
                                                    </div>
                                                    <div style={{ marginTop: '2px' }}> {item.user} ({item.date}) </div></TimelineContent>
                                            </TimelineItem>
                                        )}
                                    </Timeline>
                                </div>
                            </DialogContent>
                        </Dialog>
                        : null
                }
            </Paper>
            {detailsPageLoader ? 
            <PageLoader />
            :null}
        </Fragment>
    )
}
