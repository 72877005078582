import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldSelect, FieldInput, FieldDate } from '../../../contracts/Elements';
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import moment from 'moment';
import {leaseStatus} from '../../../../shared/components'
import { ToolTipHover, InfoIcon } from '../../../../shared'
import OffWingServiceable from './OffWingServiceable';
import InShopUnServiceable from './InShopUnServiceable';
import InShopServiceble from './InShopServiceble';
import YetToBeSentFields from './YetToBeSentFields';
import OffWingUnserviceble from './OffWingUnserviceble';
import AddEditMjrAssyfields from './AddEditMjrAssyfields';
import PlacesAutocomplete from 'react-places-autocomplete';
import Select , { Creatable } from 'react-select';
import {geocodeByAddress,geocodeByPlaceId,getLatLng} from 'react-places-autocomplete';
import { imgStoragePath, displayDateFormat } from '../../../../constants';
import { getLocalStorageInfo, toastFlashMessage } from '../../../../utils'
import { uploadLesseeLogoAc } from '../../../../shared/actionCreators';
export default class EditEngine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      engine: {},
      error: {},
      off_wing_status: false,
      on_wing_status: false,
      newLessee:false,
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }
  componentDidMount() {
    let data = JSON.parse(JSON.stringify(this.props.engineObj));
    delete data['lessor_name'];
    delete data['owner'];
    if (this.props.type == 'edit' || this.props.type == 'view') {
      delete data['position'];
      delete data['off_wing_status'];
      delete data['region'];
      if (data.aircraft) {
        delete data['aircraft'];
      }
      if (data.lessor) {
        delete data['lessor'];
      }
      if (data.lessee) {
        delete data['lessee'];
      }
      if (data.operator) {
        delete data['operator'];
      }
      if (data.manufacturer) {
        delete data['manufacturer'];
      }
      if (data.engine_type) {
        delete data['engine_type'];
      }
      data = {
        ...data,
        aircraft: this.props.engineObj.aircraft ? this.props.engineObj.aircraft.id : '',
        aircraft_name: this.props.engineObj.aircraft && this.props.engineObj.aircraft.msn ? this.props.engineObj.aircraft.msn : '',
        manufacturer: this.props.engineObj.manufacturer && this.props.engineObj.manufacturer.id ? this.props.engineObj.manufacturer.id : '',
        manufacturer_name: this.props.engineObj.manufacturer && this.props.engineObj.manufacturer.name ? this.props.engineObj.manufacturer.name : '',
        engine_type: this.props.engineObj.engine_type ? this.props.engineObj.engine_type.id : '',
        engine_name: this.props.engineObj.engine_type ? this.props.engineObj.engine_type.name : '',
        status: this.props.engineObj.status ?  this.props.engineObj.status.value : '',
        status_label: this.props.engineObj.status ?  this.props.engineObj.status.label : '',
        off_lease_status: this.props.engineObj.off_lease_status ? this.props.engineObj.off_lease_status.value : null,
        position: this.props.engineObj.position ? this.props.engineObj.position.value : '',
        position_name: this.props.engineObj.position ? this.props.engineObj.position.label : '',
        owner_id:this.props.engineObj.owner && this.props.engineObj.owner.id ? this.props.engineObj.owner.id : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '',
        owner_level: this.props.engineObj.owner && this.props.engineObj.owner.lessor_level? this.props.engineObj.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
        lessor_name_id:this.props.engineObj.lessor_name && this.props.engineObj.lessor_name.id ? this.props.engineObj.lessor_name.id: getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '' ,
        lessor_level: this.props.engineObj.lessor_name && this.props.engineObj.lessor_name.lessor_level ? this.props.engineObj.lessor_name.lessor_level : 1,
        lessee:this.props.engineObj.lessee && this.props.engineObj.lessee.id ? this.props.engineObj.lessee.id :'',
        region: this.props.engineObj.region && this.props.engineObj.region.id ? this.props.engineObj.region.id : '',
        region_label: this.props.engineObj.region && this.props.engineObj.region.name ? this.props.engineObj.region.name : '',
        credit_classification: this.props.engineObj.credit_classification && this.props.engineObj.credit_classification.value ? this.props.engineObj.credit_classification.value : '',
        credit_classification_name: this.props.engineObj.credit_classification && this.props.engineObj.credit_classification.label ? this.props.engineObj.credit_classification.label : '',
        off_wing_status: this.props.engineObj.off_wing_status && this.props.engineObj.off_wing_status.value ? this.props.engineObj.off_wing_status.value : '',
        off_wing_status_label: this.props.engineObj.off_wing_status && this.props.engineObj.off_wing_status.label ? this.props.engineObj.off_wing_status.label : '',
        on_wing_lessor: (this.props.engineObj && this.props.engineObj.on_wing_lessor) ? this.props.engineObj.on_wing_lessor : (this.props.overview && this.props.overview.lessor && this.props.overview.lessor.name) ? this.props.overview.lessor.name : null,
        on_wing_msn: (this.props.engineObj && this.props.engineObj.on_wing_msn) ? this.props.engineObj.on_wing_msn : (this.props.overview && this.props.overview.msn) ? this.props.overview.msn : null,
        ownership_type: this.props.engineObj.ownership_type ? this.props.engineObj.ownership_type.value : 1,
        portfolio: this.props.engineObj.portfolio && this.props.engineObj.portfolio.id ? this.props.engineObj.portfolio : {}
      }
      setTimeout(() => this.setState({
        engine: data
      }), 100)
    } else {
      data = {
        ...data,
        on_wing_lessor: (this.props.overview && this.props.overview.lessor && this.props.overview.lessor.name) ? this.props.overview.lessor.name : null,
        on_wing_msn: this.props.overview && this.props.overview.msn ? this.props.overview.msn  : null,
      }
      setTimeout(() => this.setState({
        engine: data
      }), 100)
    }
    if (data.on_wing_status == true) {
      this.setState({
        on_wing_status: true
      })
    }

    if (data.off_wing_status) {
      this.setState({
        off_wing_status: true
      })
    }
  }
  updateForm = (type, value) => {
    if(type == "status" && value!=2){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            off_lease_status:null
          }
        }))
    }
    if(type == "lessor_name_id") {
      let data = this.props.ownerList.find(item => item.id == value)
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          lessor_name_id: data && data.id ? data.id : '',
          lessor_level: data && data.lessor_level ? data.lessor_level : ''
        }
      }))
    }
    if(type == "owner_id") {
      let data= this.props.ownerList.find(item => item.id == value )
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          owner_id:data && data.id ? data.id :'',
          owner_level:data && data.lessor_level ?  data.lessor_level :''
        }
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))


    if (type == 'on_wing_status' && !value) {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          on_wing_msn: '',
          on_wing_lessor: ''
        }
      }))
    } else if (type == 'on_wing_status' && value) {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          off_wing_status: {},
          on_wing_msn: this.state.engine.on_wing_msn,
          on_wing_lessor: this.state.engine.on_wing_lessor,
        },
        off_wing_status: false

      }))
    } else if (type == 'off_wing_status') {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
        },
        off_wing_status: false
      }))
    }else if(type == 'portfolio'){
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          portfolio: typeof value == 'string' && value != '' ? {id: value, new: true} : {id: value},
        },
      }))
    }

    if (value) {
      this.setState({
        [type]: true
      })
    } else {
      this.setState({
        [type]: false
      })
    }
  }


  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        location : address
      }
    }));
  };
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          lessee:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      engine:{
        ...prevState.engine,
        lessee:{
          ...prevState.engine.lessee,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee:{
              ...prevState.engine.lessee,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
        })))
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
						engine: {
              ...prevState.engine,
                country: place.length ? place[0]['long_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));

        location.then(results =>
          results[0]
          ).then(country => {
            let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
            this.setState(prevState => ({
              ...prevState,
              engine: {
                ...prevState.engine,
                  country_code: place.length ? place[0]['short_name']:address
                }
              }))
          }

          )
          .catch(error => console.error('Error', error));
    };



  updateDetailData = (type, value) => {
    if(type == 'date_of_removal'){
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          off_wing_detail: {
            ...prevState.engine.off_wing_detail,
              [type]: value == 'Invalid date' ? null: value,
            }
        }
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          off_wing_detail: {
            ...prevState.engine.off_wing_detail,
              [type]: value
            }
        }
      }));
    }

  }
  addEditEngine = (e) => {
    e.preventDefault();
    let validateNewInput = {
      engine_type: checkString({value: this.state.engine.engine_type, required: true, minLength: '', maxLength: '', message: 'Please select Engine type'}),
      manufacturing_date: checkValidDate({value: this.state.engine.manufacturing_date, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
      esn: checkString({value: this.state.engine.esn, required: true, minLength: '', maxLength: '', message: 'Please enter Engine Serial Number'}),
      position: checkString({value: this.state.engine.position, required: this.state.engine.status==2 ||this.state.engine.status==3 ? false: true, minLength: '', maxLength: '', message: 'Please select engine position'}),
      region: checkString({value: this.state.engine.region, required:this.state.engine.status==2 ||this.state.engine.status==3 ? false: true,  minLength: '', maxLength: '', message: 'Please select Region of Operation'}),
      operating_thrust: checkNumber({value: this.state.engine.operating_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Thrust in numbers'}),
      purchase_thrust: checkNumber({value: this.state.engine.purchase_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Thrust in numbers'}),
      tsn: checkNumber({value: this.state.engine.tsn, required: '', minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.engine.csn, required: '', minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      average_monthly_hours: checkNumber({value: this.state.engine.average_monthly_hours, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.engine.average_monthly_cycles, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly cycles in numbers'}),
      tslsv: checkNumber({value: this.state.engine.tslsv, required: '', minLength: '', maxLength: '', message: 'Please enter TSLSV in numbers'}),
      cslsv: checkNumber({value: this.state.engine.cslsv, required: '', minLength: '', maxLength: '', message: 'Please enter CSLSV in numbers'}),
      lessor_name_id: checkString({value: this.state.engine.lessor_name_id, required: this.props.standAlone && parseInt(this.state.engine.status) !== 4 ? true : false, minLength:'', maxLength:'',message:'Please select Lessor'}),
      last_utilization_updated:this.state.engine.last_utilization_updated? moment(this.state.engine.last_utilization_updated).diff(moment(this.state.engine.manufacturing_date),'days')<0 ?"Utilization date can't be before Manufacturing date":'' :'',
      lessee: checkString({ value: this.state.engine.lessee, required:this.state.engine.status == 1 ? true : '' , minLength: '', maxLength: '', message: 'Please select Lessee' }),
    };

    if(this.state.engine.ownership_type == 2 && ( this.state.engine.portfolio == undefined || this.state.engine.portfolio == null || this.state.engine.portfolio.id == undefined || this.state.engine.portfolio.id == null || this.state.engine.portfolio.id == '' )){
      validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    }

    if(this.state.engine.is_titled || this.state.engine.is_fitted){
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: '',
      }
    }else{
      validateNewInput = {
        ...validateNewInput,
        titled_fitted: 'Please Specify Engine is Titled or Fitted',
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      let payload = Object.assign({}, this.state.engine)
      if(this.state.engine.off_lease_status == "" || this.state.engine.off_lease_status == {}){
        payload = {
          ...payload,
          off_lease_status:null
        }
      }
      this.props.addEditTechSummary(payload, this.props.type, 'engineList','engineCrud');
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render() {
    const { engine, error, newLessee } = this.state;
    const {type,sEngineTypes,sRegionList,manufacturerList,sLesseeList,techConstants,showAdvanced,standAlone,ownerList} = this.props;
    const {jacLeaseStatus,defaultLeaseStatus,airAsiaLeaseStatus} = leaseStatus

    if (!Object.keys(engine).length) {
      return null
    }
    const userInfo = getLocalStorageInfo()
    var showTabs = true
    // Object.keys(userInfo.user.permission).map(key => {
    //   showTabs = userInfo.user.permission[key].is_saas ? false : showTabs
    //   return key
    // })
    return (
      <form onSubmit={(e) => this.addEditEngine(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
          <Row>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Engine Type
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Engine Type'
                  keyParam="engine_type"
                  value={engine.engine_type}
                  type={type}
                  options={sEngineTypes}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                  name={engine.engine_name}
                />
              <h6 className="error-msg">{error.engine_type}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  ESN
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={engine.esn}
                  type={type}
                  keyParam="esn"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.esn}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Date of Manufacture
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldDate
                  value={engine.manufacturing_date}
                  type={type}
                  maxDate={moment()}
                  keyParam="manufacturing_date"
                  updateField={this.updateForm}
                  readOnly={true}
                />
              <h6 className="error-msg">{error.manufacturing_date}</h6>
              </FieldGroup>
            </FieldCol>
            {
              standAlone ?
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Date of Purchase
                  </FieldLabel>
                  <FieldDate
                    value={engine.purchase_date}
                    type={type}
                    maxDate={moment()}
                    minDate={moment(engine.manufacturing_date)}
                    keyParam="purchase_date"
                    updateField={this.updateForm}
                    readOnly={true}
                  />
                <h6 className="error-msg">{error.purchase_date}</h6>
                </FieldGroup>
              </FieldCol> : null
            }

            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Position
                  { engine.status==2 ||engine.status==3 ? null:  type != 'view' ? <sup className="reqStar"> * </sup> : null}

                </FieldLabel>
                <FieldSelect
                  placeholder='Select position'
                  keyParam="position"
                  value={engine.position}
                  type={type}
                  options={techConstants.filter(item => item.type=="engine_position")}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={engine.position_name}
                />
              <h6 className="error-msg">{error.position}</h6>
              </FieldGroup>
            </FieldCol>
            {
                this.props.assetType != 'engine' ? null:
            <FieldCol md="8">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Lessee {type != 'view' && engine.status == 1 ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <Creatable
                  value={engine.lessee}
                  options={sLesseeList}
                  isClearable={true}
                  onChange={this.updateLesseeForm}
                  className="custom-select-block"
                  placeholder='Add or Select Lessee'
                  valueKey="id"
                  labelKey="name"
                  type={type}
                  disabled={engine && engine.status != 1 ? true : false}

                />
                <h6 className="error-msg">{error.lessee}</h6>
              </FieldGroup>
            </FieldCol>
            }

            {
              newLessee ?
              <Fragment>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
            {
                this.props.assetType != 'engine' ? null:
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                Lease Status
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Status'
                  keyParam="status"
                  value={engine.status}
									type={type}
                  options = {getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={engine.position_name}
                />
              <h6 className="error-msg">{error.status}</h6>
              </FieldGroup>
            </FieldCol>
            }
            {
              engine.status == 2 ?
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Off Lease Status
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Off Lease Status'
                    keyParam="off_lease_status"
                    value={engine.off_lease_status}
                    type={type}
                    options={techConstants.filter(item => item.type=="off_lease_status")}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    name={engine.position_name}
                  />
                </FieldGroup>
              </FieldCol>: null
            }
            {
              standAlone && parseInt(this.state.engine.status) !== 4 ?
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Lessor {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Lessor'
                    keyParam="lessor_name_id"
                    value={engine.lessor_name_id}
                    type={type}
                    options = {ownerList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='id'
                  />
                <h6 className="error-msg">{error.lessor_name_id}</h6>
                </FieldGroup>
              </FieldCol> : null
            }
            {
              standAlone && parseInt(this.state.engine.status) !== 4 ?
              <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Owner
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Owner'
                    keyParam="owner_id"
                    value={engine.owner_id}
                    type={type}
                    options = {ownerList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='id'
                  />
                </FieldGroup>
              </FieldCol> : null
            }

            </Row>

            <div className="tech-spec-utilization-edit-block">
              <h6 style={{marginTop:'0'}}>Engine Utilization Details</h6>
              <Row>
                <FieldCol md="4">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Utilization as of date
                  </FieldLabel>
                  <FieldDate
                    keyParam="last_utilization_updated"
                    value={engine.last_utilization_updated}
                    type={type}
                    updateField={this.updateForm}
                    maxDate ={ moment()}
                  />
                  <h6 className="error-msg">{error.last_utilization_updated}</h6>
                </FieldGroup>
              </FieldCol>
              </Row>

              <Row>
                <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    TSN
                  </FieldLabel>
                  <FieldInput
                    value={engine.tsn}
                    type={type}
                    keyParam="tsn"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.tsn}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    CSN
                  </FieldLabel>
                  <FieldInput
                    value={engine.csn}
                    type={type}
                    keyParam="csn"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.csn}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Avg. Monthly Hours
                  </FieldLabel>
                  <FieldInput
                    value={engine.average_monthly_hours}
                    type={type}
                    keyParam="average_monthly_hours"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.average_monthly_hours}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md="3">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Avg. Monthly Cycles
                  </FieldLabel>
                  <FieldInput
                    value={engine.average_monthly_cycles}
                    type={type}
                    keyParam="average_monthly_cycles"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                </FieldGroup>
              </FieldCol>
              </Row>
            </div>
            <Row>
              {
                this.props.assetType != 'engine' ?
                <Col md="5">
                {type == 'view' ?
                   <div className="form-group" >
                   <label className="label">
                      Is this engine titled for this MSN?
                    </label>
                   {engine.is_titled ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17" /> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img" />}
                 </div>
                  :
                  <div className="form-group" style={{ margin: "25px 0px" }}>
                    <label className="label">
                    {
                      showAdvanced ?
                      <input
                        type="checkbox"
                        checked={engine.is_titled}
                        onChange={(e) => {this.updateForm('is_titled', true);this.updateForm('is_fitted', false);this.updateForm('on_wing_status', false);}}
                      />
                      :<input
                        type="checkbox"
                        checked={engine.is_titled}
                        onChange={(e) => this.updateForm('is_titled', e.target.checked)}
                        />
                    }
                      <span>
                        Is Engine titled for this MSN?
                        <i class="fa fa-info-circle" aria-hidden="true" id="engine-field1" style={{paddingLeft: '4px'}}></i>
                        <UncontrolledTooltip placement="right" target="engine-field1">
                          Tick if the major assembly is Titled
                        </UncontrolledTooltip>
                      </span>
                    </label>

                  </div>
                }
              </Col> : null
              }

             {
                this.props.assetType != 'engine' ?
                <Col md="5">
                  {type == 'view' ?
                  <div className="form-group">
                    <label className="label">Is this engine fitted on this MSN</label>
                    {engine.is_fitted ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17" /> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img" />}
                  </div>
                :
                <div className="form-group" style={{display: "block",margin: "25px 0px"}}>
                  <label className="label">
                  {
                    showAdvanced ?
                    <input
                      type="checkbox"
                      checked={engine.is_fitted}
                      onChange={(e) => {this.updateForm('is_fitted', true);this.updateForm('is_titled', false);this.updateForm('on_wing_status', true);this.updateForm('on_wing_msn',  this.props.overview.msn);this.updateForm('on_wing_lessor',  this.props.overview.lessor &&  this.props.overview.lessor.name ? this.props.overview.lessor.name : '')}}
                    />
                    :<input
                      type="checkbox"
                      checked={engine.is_fitted}
                      onChange={(e) => this.updateForm('is_fitted', e.target.checked)}
                      />
                  }
                    <span>
                      Is Engine fitted on this MSN ?
                      <i class="fa fa-info-circle" aria-hidden="true" id="engine-field2" style={{paddingLeft: '4px'}}></i>
                      <UncontrolledTooltip placement="right" target="engine-field2">
                        Tick if the major assembly is temporarily installed
                      </UncontrolledTooltip>
                    </span>
                  </label>

                </div>
              }
            </Col> : null }

            <Col md="12" style={{ margin: '-26px 27px 15px'}}>
               <h6 className="error-msg">{error.titled_fitted}</h6>
             </Col>
            </Row>
            <div className="tech-spec-off-wing-block">
              <Row>
                <Col md="12">
                  {type == 'view' ?
                    <div className="form-group">
                      <label className="label">Is Engine On Wing?</label>
                      {engine.on_wing_status ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17" /> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img" />}
                    </div>
                    :
                    <div className="form-group" style={{ margin: "20px 0px" }}>
                      <label className="label">
                      { showAdvanced && engine.is_fitted ?
                        <input
                          type="checkbox"
                          checked={engine.on_wing_status}
                        />
                        :<input
                          type="checkbox"
                          checked={engine.on_wing_status}
                          onChange={(e) => this.updateForm('on_wing_status', e.target.checked)}
                        />
                      }
                        <span>
                          Is Engine On wing?
                          <i class="fa fa-info-circle" aria-hidden="true" id="engine-field3" style={{paddingLeft: '4px'}}></i>
                          <UncontrolledTooltip placement="right" target="engine-field3">
                            Tick if the Titled major assembly is installed on  aircraft
                          </UncontrolledTooltip>
                        </span>
                      </label>
                    </div>
                  }
                </Col>

                <FieldCol md="12" style={!this.state.on_wing_status ? { display: 'block' } : { display: 'none' }}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Engine Off Wing Status
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Off Wing Status'
                      keyParam="off_wing_status"
                      value={engine.off_wing_status}
                      type={type}
                      options={techConstants.filter(item => item.type=="off_wing_status")}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                      on_wing_status={this.state.on_wing_status}
                      name={engine.off_wing_status_label}
                    />
                  </FieldGroup>
                </FieldCol>

              </Row>

              { this.state.off_wing_status && engine.off_wing_detail  ?
                  <AddEditMjrAssyfields assemblyName="engine" updateDetailData={this.updateDetailData} mjrAssy = {engine.off_wing_detail} type={type}/>
                 : null
              }
              {
                engine.off_wing_status == 1 ?
                 <YetToBeSentFields assemblyName="Engine" updateDetailData={this.updateDetailData} yetToBeSend = {engine.off_wing_detail} type={type}/> : null
              }
              {
               engine.off_wing_status == 2 ?
                 <InShopServiceble assemblyName="Engine" techConstants={techConstants} updateDetailData={this.updateDetailData} inShopserv = {engine.off_wing_detail} type={type}/> : null
              }
              {
               engine.off_wing_status == 3 ?
                <InShopUnServiceable assemblyName="Engine" techConstants={techConstants} updateDetailData={this.updateDetailData} inShopUnserv = {engine.off_wing_detail} type={type}/> : null
              }
              {
               engine.off_wing_status == 4 ?
                <OffWingServiceable assemblyName="Engine" techConstants={techConstants} updateDetailData={this.updateDetailData} offWingServ = {engine.off_wing_detail} type={type}/> : null
              }
              {
                engine.off_wing_detail && engine.off_wing_status == 5 ?
                <OffWingUnserviceble assemblyName="Engine" updateDetailData={this.updateDetailData} techConstants={techConstants} offWingUnServ = {engine.off_wing_detail} type={type}/> : null
              }

              <Row>
                <FieldCol md="4" style={this.state.on_wing_status ? { display: 'block' } : { display: 'none' }}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      On Wing MSN
                    </FieldLabel>
                    <FieldInput
                      value={engine.on_wing_msn}
                      type={type}
                      keyParam="on_wing_msn"
                      disable={showAdvanced && engine.is_fitted}
                      updateField={this.updateForm}
                      style={{background: 'transparent'}}
                    />
                  </FieldGroup>
                </FieldCol>


                <FieldCol md="4" style={this.state.on_wing_status ? { display: 'block' } : { display: 'none' }}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      On Wing Engine Owner
                    </FieldLabel>
                    <FieldInput
                      value={engine.on_wing_lessor}
                      type={type}
                      keyParam="on_wing_lessor"
                      updateField={this.updateForm}
                      style={{background: 'transparent'}}
                      disable={showAdvanced && engine.is_fitted}
                    />
                  </FieldGroup>
                </FieldCol>
              </Row>
            </div>
            <Row>
            <FieldCol md="4" >
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Region of Operation
                  { engine.status==2 ||engine.status==3 ? null:  type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select region'
                  keyParam="region"
                  value={engine.region}
                  type={type}
                  options={sRegionList}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                  name={engine.region_label}
                />
                 <h6 className="error-msg">{error.region}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Purchased Thrust (Lbs)
                </FieldLabel>
                <FieldInput
                  value={engine.purchase_thrust}
                  type={type}
                  keyParam="purchase_thrust"
                  updateField={this.updateForm}
                />
               <h6 className="error-msg">{error.purchase_thrust}</h6>

              </FieldGroup>
            </FieldCol>

            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Operating Thrust (Lbs)
                </FieldLabel>
                <FieldInput
                  value={engine.operating_thrust}
                  type={type}
                  keyParam="operating_thrust"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.operating_thrust}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Location
                  <i class="fa fa-info-circle" aria-hidden="true" id="engine-field4" style={{paddingLeft: '4px'}}></i>
                  <UncontrolledTooltip placement="right" target="engine-field4" >
                    Input country of operation
                  </UncontrolledTooltip>
                </FieldLabel>
                {
                  window.google !== undefined && window.google !== null ?
                  <PlacesAutocomplete
                    value={engine.location}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{position: 'relative'}}>
                      <input
                        {...getInputProps({
                          placeholder: 'Search Location',
                          className: 'location-search-input',
                        })}
                      />
                      {
                        engine.location ?
                        <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div> :  null
                      }

                    </div>
                  )}
                </PlacesAutocomplete>
                  :<FieldInput
                    value={engine.country}
                    type={type}
                    keyParam="country"
                    updateField={this.updateForm}
                  />
                }
            </FieldGroup>
          </FieldCol>
          {
            showTabs ?
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Ownership</FieldLabel>
                <div>
                  <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                     <input type="radio"
                      value={engine.ownership_type}
                      onChange = {(e) => this.updateForm('ownership_type', 1)}
                      style={{ marginRight: '4px'}}
                      checked = {engine.ownership_type === 1 ? true : false}
                     />
                   <span>Owned</span>
                  </label>
                  <label className="label" style={{ cursor: 'pointer'}}>
                     <input type="radio"
                      value={engine.ownership_type}
                      onChange = {(e) => this.updateForm('ownership_type', 2)}
                      style={{ marginRight: '4px'}}
                      checked = {engine.ownership_type === 2 ? true : false}
                     />
                   <span>Managed</span>
                  </label>
                </div>
              </FieldGroup>
            </FieldCol>
            :null
          }
          {
            engine.ownership_type == 2 ?
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                  <FieldSelect
                    placeholder='Create/Select Portfolio'
                    keyParam="portfolio"
                    value={engine.portfolio ? engine.portfolio.id : ''}
                    type={type}
                    options = {this.props.portfolioList}
                    updateField={this.updateForm}
                    labelKey= 'name'
                    valueKey='id'
                    creatable={true}
                  />
                <h6 className="error-msg">{error.portfolio}</h6>
              </FieldGroup>
            </FieldCol>
            :null
          }
          </Row>

          <div className="tech-spec-utilization-edit-block">
            <Row>
              <Col md="12">
              {type == 'view' ?
                  <div className="form-group">
                    <label className="label">Match Engine's TSN & CSN to LLP</label>
                    {engine.match_csn_to_llp ? <img src={imgStoragePath+"green-tick.png"} alt="img" width="17" /> : <img src={imgStoragePath+"cross_red.png"} width="17" alt="img" />}
                  </div>
                  :
                  <div className="form-group" style={{ margin: "0px 0px 20px 0px" }}>
                    <label className="label">
                      <input
                        type="checkbox"
                        checked={engine.match_csn_to_llp}
                        onChange={(e) => this.updateForm('match_csn_to_llp', e.target.checked)}
                      />
                      <span>
                        Match Engine's TSN & CSN to LLP
                        <i class="fa fa-info-circle" aria-hidden="true" id="engine-field5" style={{paddingLeft: '4px'}}></i>
                        <UncontrolledTooltip placement="right" target="engine-field5">
                          Tick if all LLPs have same TSN / CSN as that of engine
                        </UncontrolledTooltip>
                      </span>
                    </label>
                </div>
                }
              </Col>
            </Row>
          </div>

        </div>
        {type != 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes" />
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
        : null }
      </form>
    )
  }
}
