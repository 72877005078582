import React from 'react';
import LeaseTimeline from './LeaseTimeline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const LeaseHistory = ({leaseHistories, leaseInfoCardSettings}) => {
  return(
    <div className="timeline-block">
      <div className="timeline-heading-block">
        <h5 className="para contract-timeline">Lease History</h5>
        <div className="nav-btn">
          <ArrowBackIosIcon style={{opacity: leaseInfoCardSettings.rightCount > 0?1:0.4}} fontSize="small" onClick={(e) => { leaseInfoCardSettings.rightCount > 0 ? this.props.leaseCardPos('prev'):e.preventDefault()}} />
          <ArrowForwardIosIcon style={{opacity: leaseInfoCardSettings.leftCount > 0?1:0.4}} fontSize="small" onClick={(e) => { leaseInfoCardSettings.leftCount > 0 ? this.props.leaseCardPos('prev'):e.preventDefault()}} />
        </div>
      </div>
      <div className="timeline-inner-block flex-centered">
        <ul className="timeline-bar list-inline" style={{ width: leaseInfoCardSettings.totalWidth, 'left': leaseInfoCardSettings.leftPos }}>
          { leaseHistories.map((leaseHistory, index) =>
            <LeaseTimeline leaseHistory={leaseHistory} key={index} />)
          }
        </ul>
      </div>
    </div>
  )
}
export default LeaseHistory;
