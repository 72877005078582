import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { capitalizeFirstLetter, checkApiStatus, getCurrencyFormat, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditSecurityDepositTxnApi, getTxnDetailApi } from '../apiServices';
import { errorCode, addNonCashTx } from '../';
class transactionCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader:false,
      mode:'',
      transaction:{},
      error:{}
    }
    this.addEditSecurityDepositTxnApi = addEditSecurityDepositTxnApi.bind(this);
    this.getTxnDetailApi = getTxnDetailApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      transaction: {
        ...prevState.transaction,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEdittransactionFn = () => {
    const { transaction } = this.state;
    let validationInputs = {
      sub_type_of_transaction:errorCode['sub_type_of_transaction'][fieldValidation({...errorCode['sub_type_of_transactionObj'], fieldval: transaction.sub_type_of_transaction})],
      on_behalf_of:errorCode['on_behalf_of'][fieldValidation({...errorCode['on_behalf_ofObj'], fieldval: transaction.on_behalf_of})],
      issuing_party:errorCode['issuing_party'][fieldValidation({...errorCode['issuing_partyObj'], fieldval: transaction.issuing_party})],
      confirming_party:errorCode['confirming_party'][fieldValidation({...errorCode['confirming_partyObj'], fieldval: transaction.confirming_party})],
      purpose:errorCode['purpose'][fieldValidation({...errorCode['purposeObj'], fieldval: transaction.purpose})],
      amount:errorCode['amount'][fieldValidation({...errorCode['amountObj'], fieldval: transaction.amount})],
    };
    if(transaction.retention_period_start_date && transaction.retention_period_end_date){
      validationInputs = {
        ...validationInputs,
        retention_period_start_date: dateRangeValidation(transaction.retention_period_start_date, transaction.retention_period_end_date, 30)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.addEditSecurityDepositTxnApi(this.props, this.props.securityDepositId, transaction)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', transaction:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { modal, mode, formSubmitLoader, transaction, error, pageLoader } = this.state;
    const { id, m, securityDepositId, contractInfo } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader /> : null }
        { id ?
          <span onClick={() => this.getTxnDetailApi(this.props, securityDepositId, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Tooltip title='Add Non-Cash Transaction'>
            <AddCircleOutlineIcon color="primary" onClick={() => this.setState({modal:true, transaction:addNonCashTx, mode:'add'})} />
          </Tooltip>
          : null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', transaction:{}, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Non Cash Transaction`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctsecuritydeposittransactions/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','security_deposit', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Type of Financial Instrument' value={transaction.sub_type_of_transaction ? transaction.sub_type_of_transaction:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="sub_type_of_transaction"
                        label="Type of Financial Instrument"
                        fullWidth
                        margin="normal"
                        value={transaction.sub_type_of_transaction ? transaction.sub_type_of_transaction:''}
                        onChange={(e, value) => this.onFieldChange(e, 'sub_type_of_transaction', e.target.value)}
                        error={error.sub_type_of_transaction ? true:false }
                        helperText={error.sub_type_of_transaction ? error.sub_type_of_transaction:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('sub_type_of_transaction','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='On Behalf of' value={transaction.on_behalf_of ? transaction.on_behalf_of:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="on_behalf_of"
                        label="On Behalf of"
                        fullWidth
                        margin="normal"
                        value={transaction.on_behalf_of ? transaction.on_behalf_of:''}
                        onChange={(e, value) => this.onFieldChange(e, 'on_behalf_of', e.target.value)}
                        error={error.on_behalf_of ? true:false }
                        helperText={error.on_behalf_of ? error.on_behalf_of:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('on_behalf_of','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Issuing Party' value={transaction.issuing_party ? transaction.issuing_party:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="issuing_party"
                        label="Issuing Party`"
                        fullWidth
                        margin="normal"
                        value={transaction.issuing_party ? transaction.issuing_party:''}
                        onChange={(e, value) => this.onFieldChange(e, 'issuing_party', e.target.value)}
                        error={error.issuing_party ? true:false }
                        helperText={error.issuing_party ? error.issuing_party:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('issuing_party','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Confirming Party' value={transaction.confirming_party} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="confirming_party"
                        label="Confirming Party"
                        fullWidth
                        margin="normal"
                        value={transaction.confirming_party ? transaction.confirming_party:''}
                        onChange={(e, value) => this.onFieldChange(e, 'confirming_party', e.target.value)}
                        error={error.confirming_party ? true:false }
                        helperText={error.confirming_party ? error.confirming_party:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('confirming_party','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Amount' value={transaction.amount ?  <NumericLabel params={getCurrencyFormat()}>{transaction.amount}</NumericLabel>:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="amount"
                        label="Amount"
                        fullWidth
                        margin="normal"
                        value={transaction.amount ? transaction.amount:''}
                        onChange={(e, value) => this.onFieldChange(e, 'amount', e.target.value)}
                        error={error.amount ? true:false }
                        helperText={error.amount ? error.amount:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('amount','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Validity Start Date' value={transaction.retention_period_start_date ? moment(transaction.retention_period_start_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="retention_period_start_date"
                           label="Validity Start Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={transaction.retention_period_start_date ? transaction.retention_period_start_date:null}
                           error={error.retention_period_start_date ? true:false }
                           helperText={error.retention_period_start_date ? error.retention_period_start_date:'' }
                           onChange={(data, value) => this.onFieldChange(value, 'retention_period_start_date', moment(data).format(backendDateFormat))}
                           onFocus={() => this.updateErrorField('retention_period_start_date','')}
                         />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Validity End Date' value={transaction.retention_period_end_date ? moment(transaction.retention_period_end_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="retention_period_end_date"
                           label="Validity End Date"
                           format={fieldDateFormat}
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={transaction.retention_period_end_date ? transaction.retention_period_end_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'retention_period_end_date', moment(data).format(backendDateFormat))}
                         />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Purpose of Instrument' value={transaction.purpose ? transaction.purpose:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="purpose"
                        label="Purpose of Instrument"
                        fullWidth
                        margin="normal"
                        value={transaction.purpose ? transaction.purpose:''}
                        onChange={(e, value) => this.onFieldChange(e, 'purpose', e.target.value)}
                        error={error.purpose ? true:false }
                        helperText={error.purpose ? error.purpose:'' }
                        InputLabelProps={{shrink: true}}
                        onFocus={() => this.updateErrorField('purpose','')}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEdittransactionFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', transaction:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(transactionCRU));
