export const addInductionCondition = {
  terms: '',
  remarks: '',
  asset_conditions_for_contract_id: ''
}
export const  addDeliveryCondition = {
  asset_conditions_for_contract_id: '',
  actual: '',
  terms: '',
  remarks: '',
  exceptions_done: ''
}
export const addReturnCondition = {
  asset_conditions_for_contract_id: '',
  exceptions_done: '',
  terms: '',
  flags: false,
  remarks: ''
}
export const addQfdMntEvent = {
  event: '',
  qualified_items: '',
  start_date: null,
  end_date: null,
  remarks:'',
  reserve_account: ''
}
export const addSubLease = {
  sublease_lessee: '',
  details: '',
  start_date: null,
  end_date: null,
  remarks: ''
}

export const addParty = {
  role: '',
  name: '',
  contact_address: '',
  jurisdiction: '',
  concerned_person_name: '',
  concerned_person_designation: '',
  start_date: null,
  end_date: null,
  remarks: ''
}
export const addObligation = {
  obligation_type: '',
  sub_type: '',
  applicable: '',
  details: '',
  payment_due_date: '',
  value: '',
  status: '0',
  remarks: '',
  to_be_approved_by:''
};
export const addLeaseOption = {
  lease_option: '',
  details: '',
  start_date: null,
	end_date: null,
	notice_period_start_date: null,
  notice_period_end_date: null,
	remarks: ''
}
export const addInsurance = {
  insurance_type: '',
  insurance_number: '',
  insuree: '',
  insurer: '',
  policy_start_date:null,
  policy_end_date:null,
  geo_limit: '',
  insurance_amount: '',
  currency	: 'USD',
  effective_date: null,
  primary_contact: '',
  remarks: ''
}
export const addClaim = {
  insurance_number: '',
  claim_summary: '',
  date: null,
  status: '',
  amount: '',
  currency: 'USD',
  primary_contact: '',
  remarks: '',
  insurer: '',
  insuree : ''
}
export const addSecurityDeposit = {
  security_deposit_type: '',
  security_against: '',
  amount: '',
  currency: 'USD',
  is_refundable: false,
  is_interest_refundable: false,
  interest_rate: '',
  remarks: ''
};
export const addCashTx = {
  type_of_transaction: '1',
  reference_number: '',
  transaction_date: null,
  retention_period_start_date: null,
  retention_period_end_date: null,
  amount: '',
  currency: 'USD',
  on_behalf_of: null,
  issuing_party: null,
  confirming_party: null
};
export const addNonCashTx ={
  type_of_transaction: '2',
  reference_number: '1',
  transaction_date: null,
  retention_period_start_date: null,
  retention_period_end_date: null,
  amount: '',
  currency: 'USD',
  on_behalf_of: '',
  issuing_party: '',
  confirming_party: '',
  sub_type_of_transaction : '',
  purpose : ''
};

export const workingGrpTeam = {
  name: ''
};

export const  addMember = {
  first_name: '',
  last_name: '',
  mobile: '',
  designation: '',
  email: '',
  remarks: '',
  working_group_id: ''
}

export const mrAccounts = {
  interval_condition: [],
  adjustment_factor:[],
  name: '',
  asset_type: '',
  asset_id: '',
  status: 1,
  interval: {},
  interval_condition_status: false,
  is_adjustment_factor:false,
  is_escalation_mucip:false,
  currency: 'USD',
  reserve_rate_per_interval: '',
  rate_escalation_percentage: 0,
  rate_escalation_interval: null,
  rate_escalation_start_date: null,
  rate_start_date: null,
  rate_end_date: null,
  starting_fund_date: null,
  starting_fund_amount: '',
  cashflow_initiated: '',
  remarks: '',
  cost_escalation_percentage: 0
}

export const addLeaseRental = {
  rental_type:'',
  interval: '',
  amount: '',
  escalation_percentage: '',
  escalation_interval: '',
  escalation_start_date: null,
  start_date: null,
  end_date: null,
  currency: 'USD',
  bank_name:'',
  bank_account_number:'',
  bank_iban:'',
  aba_number :'',
  bank_swift_code:''
}
