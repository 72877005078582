import React, { Fragment} from 'react';
import { Link, withRouter } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { getCurrencyFormat } from '../../../utils_v2';
import { monthsObj } from '../../../constants';
const Cashflow = ({item, cashflows, index, params}) => {
  function invLink(cashDetail, id){
    if(cashDetail.split(/[\[\]]+/).length > 1){
      let str = cashDetail.split(/[\[\]]+/);
      return (
        <span>
          {str[0]}
          <Link to={`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/invoice/view/${id}`}>{str[1]}</Link>
          {str[2]}
        </span>
      )
    }else{
      return cashDetail;
    }
  }
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { index != 0 ? cashflows[index].for_month != cashflows[index - 1].for_month ? monthsObj[item.for_month.slice(4,6)] + ' ' + item.for_month.slice(0,4):'':monthsObj[item.for_month.slice(4,6)] + ' ' + item.for_month.slice(0,4) }

        { index != 0 ? cashflows[index].for_month != cashflows[index - 1].for_month ?
          <p className="cashflow-monthly-total">
            <span className="cashflow-monthly-total-label">Month Total Cashflow</span>
            {item.monthly_total.length?
              item.monthly_total.map((cashflow, index) =>
              <NumericLabel params={getCurrencyFormat({currency:cashflow.currency, shortFormat:false})}>{cashflow.total_amount}</NumericLabel> )
              :
              <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>0.00</NumericLabel>
            }
          </p>:'':
          <p className="cashflow-monthly-total">
            <span className="cashflow-monthly-total-label">Month Total Cashflow</span>
            {item.monthly_total.length?
            item.monthly_total.map((cashflow, index) =>
            <NumericLabel params={getCurrencyFormat({currency:cashflow.currency, shortFormat:false})}>{cashflow.total_amount}</NumericLabel> )
            :
            <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>0.00</NumericLabel>
            }
          </p>
        }
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        { item.credit_or_debit ?
          <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.amount}</NumericLabel>
          :'--'
        }
      </TableCell>
      <TableCell>
        { !item.credit_or_debit ?
          <Fragment>-<NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.amount}</NumericLabel></Fragment>
          :'--'
        }
      </TableCell>
      <TableCell>
        <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.balance}</NumericLabel>
      </TableCell>
      <TableCell style={{width:'320px'}}>{item.transaction_details ? invLink(item.transaction_details, item.invoice_id):'--'}</TableCell>
    </TableRow>
  )
}
export default withRouter(Cashflow);
