import { userAssetReducer } from '../User';
import {
  MRFinanaceRptReducer,
  MRFinanceGraphReducer,
  AirframeAssemblyReducer,
  ApuAssemblyReducer,
  EngineAssemblyReducer,
  LangingGearAssemblyReducer,
  MrClaimsReducer
} from '../applications/maintenance';
import {
  ContractListReducer,
  ContrHdlineReducer,
  ContrMRRateReducer,
  ContrFinanceReducer,
  ContrPartiesReducer,
  ContrObligationsReducer,
  ContrLesRentalReducer,
  ContrSubLeaseReducer,
  ContrDeliCondReducer,
  ContrInducCondReducer,
  ContrReturnCondReducer,
  ContrLeaseOptReducer,
  ContrInsuraneReducer,
  ContrQfdMntReducer,
  // ContrUtilsReducer,
  ContrDashboardReducer,
  ContrCashFlowReducer,
  SecurityDepositsReducer,
  ContrWorkingGrpReducer,
  InvoiceReducer,
  UtilsReducer,
  MRAccuralReducer,
  HalfLifeReducer,
  MLFileReducer,
  // CapeTownConventionReducer
 } from '../applications/contracts';
 import contractReducer from '../lease_management/reducers';
 import shareReducer from '../shared_elements/reducers';
 import { DashboardReducer } from '../applications/dashboards';

import { LicenseReducer } from '../phase2/licenses/';
import { BillingReducer } from '../phase2/billing/';
import { confReducer } from '../phase2/ConfigureSettings/';
import { StorageListingReducer } from '../phase2/storage/';
import { NotificationReducer } from '../phase2/notification';
import { sharedReducers } from '../shared';
import globalSearchReducer  from '../shared/GlobalSearch/reducer';
import { TechSummaryReducer } from '../applications/console';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
  contractReducer,
  shareReducer,
  userAssetReducer,
  MRFinanaceRptReducer,
  MRFinanceGraphReducer,
  AirframeAssemblyReducer,
  ApuAssemblyReducer,
  EngineAssemblyReducer,
  LangingGearAssemblyReducer,
  MrClaimsReducer,
  ContractListReducer,
  ContrHdlineReducer,
  ContrMRRateReducer,
  ContrFinanceReducer,
  ContrPartiesReducer,
  ContrObligationsReducer,
  ContrLesRentalReducer,
  ContrSubLeaseReducer,
  ContrDeliCondReducer,
  ContrInducCondReducer,
  ContrReturnCondReducer,
  ContrLeaseOptReducer,
  ContrInsuraneReducer,
  ContrQfdMntReducer,
  MLFileReducer,
  // ContrUtilsReducer,
  ContrDashboardReducer,
  ContrCashFlowReducer,
  SecurityDepositsReducer,
  ContrWorkingGrpReducer,
  sharedReducers,
  InvoiceReducer,
  UtilsReducer,
  MRAccuralReducer,
  TechSummaryReducer,
  HalfLifeReducer,
  globalSearchReducer,
  DashboardReducer,
  LicenseReducer,
  BillingReducer,
  confReducer,
  StorageListingReducer,
  NotificationReducer,
});
export default rootReducer;
