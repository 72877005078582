export const invoiceListHd = [
    { id: 'invoice_number', label: 'Invoice Number', sortOption: false },
    { id: 'invoice_type', label: 'Invoice Type', sortOption: false },
    { id: 'invoice_period', label: 'Invoice Period', sortOption: false },
    { id: 'invoice_for_date', label: 'Invoice Date', sortOption: false },
    { id: 'payment_due_date', label: 'Invoice Due Date', sortOption: false },
    { id: 'total_amount', label: 'Amount', sortOption: false },
    { id: 'status', label: 'Status', sortOption: false },
]
export const assemblyInvoiceHd =[
    {label:'Item', sortOption:false},
    {label:'Description', sortOption:false},
    {label:'Unit Description', sortOption:false},
    {label:'No. Of Units', sortOption:false},
    {label:'Fixed/Per Unit Rate', sortOption:false},
    {label:'Sub Total (US$)', sortOption:false},
]
export const creditDebitHd =[
    {label:'Description', sortOption:false},
    {label:'Amount', sortOption:false},
    {label:'Total', sortOption:false},
]
export const partialPymntListHd =[
    {label:'Description', sortOption:false},
    {label:'Amount Paid	', sortOption:false},
    {label:'Date of Payment	', sortOption:false},
    {label:'Remaining Amount', sortOption:false},
    {label:'User', sortOption:false},
    {label:'Last Updated At', sortOption:false},
]
export const InvoiceFilterOps = {
    invoice_type: {
        inputType: 'dropdown',
        placeholder: 'Select Invoice Type',
        options: [],
        labelKey: 'label',
        valueKey: 'value',
        title: 'Invoice Type',
        multiple: false,
        showDropdown: true
    },
    status: {
        inputType: 'dropdown',
        placeholder: 'Select Invoice Status',
        options: [{ label: 'Draft', value: '1' }, { label: 'Sent', value: '2' }, { label: 'Partially-Paid', value: '5' }, { label: 'Paid', value: '3' }, { label: 'Cancelled', value: '4' }],
        labelKey: 'label',
        valueKey: 'value',
        title: 'Invoice Status',
        multiple: false,
        showDropdown: true
    },
    invoice_number: {
        inputType: 'text',
        placeholder: 'Invoice Number',
        title: 'Invoice Number'
    },
    startDate: {
        inputType: 'date',
        title: 'Invoice Start Date',
    },
    endDate: {
        inputType: 'date',
        title: 'Invoice End Date',
    },
    dueStartDate: {
        inputType: 'date',
        title: 'Invoice Due Start Date',
    },
    dueEndDate: {
        inputType: 'date',
        title: 'Invoice Due End Date',
    },
}
