import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { utilsGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const UtilsYearWiseGraph = ({ yearWiseGraphData, skeletonLoader, filter, getResponseBack, callExternalFunction }) => {
    let filterOptions = Object.assign({}, utilsGraphFilterOps);
    filterOptions = {
        ...filterOptions,
        utils_from:{
            ...filterOptions.utils_from,
            format:'YYYY',
            views:['year']
        },
        utils_till:{
            ...filterOptions.utils_till,
            format:'YYYY',
            views:['year']
        }
    }
    const options = {
        title: {
            text: 'Yearly Trend',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        plotOptions:{
            series:{
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category, 'basedOnYear');
                    }
                }
            }
        },
        subtitle: {
            text: `${yearWiseGraphData.title ? yearWiseGraphData.title : ''}`,
            align: 'left'
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Utilization'
            }
        },
        xAxis: {
            categories: yearWiseGraphData && yearWiseGraphData.yearly_categories && yearWiseGraphData.yearly_categories.x_axis && yearWiseGraphData.yearly_categories.x_axis.categories
        },
        legend: {
            enabled: true
        },
        series: yearWiseGraphData && yearWiseGraphData.utilization_annual,
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                }
            },
            filename: 'Utilization(s)-Yearly Trend Chart',
         }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider/>
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default UtilsYearWiseGraph
