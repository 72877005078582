import moment from 'moment';
import { browserHistory } from 'react-router';
import { globalGetService, globalExportService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../utils_v2';


// Cashflow Summary API
export function getCashflowSummaryApi(props, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/cashflow-dashboard-widget/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({cashflowSummary:response.data.data});
    }
  })
}

// Need to discuss with Nikhil & Tanuj
export function getCashflowProjectionApi(props, query={}){
  globalGetService(`contracts/dashboard/cash-flow-summary/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}


export function getCashflowsWidgetApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/cashflow/widget/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({cashflowWidget: response.data.data});
    }
  })
}
export function getReserveAccountsApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/cashflow/widget/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({cashflowWidget:response.data.data})
    }
  })
}
export function getInvoiceSummaryApi(props, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/invoice-dashboard-widget/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({invoiceSummary:response.data.data});
    }
  })
}
export function getAvgUtilizationApi(props, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/utilizations/average/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({avgUtilization:response.data.data});
    }
  })
}
export function getLeaseHistoryApi(props, id){
  globalGetService(`console/asset-timeline/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}
