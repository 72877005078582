export const utlisListHd = [
        { id: 'month', label: 'Month', sortOption: false },
        { id: 'major_assembly', label: 'Major Assembly', sortOption: false },
        { id: 'hours', label: 'Hours', sortOption: false },
        { id: 'cycles', label: 'Cycles', sortOption: false },
        { id: 'tsn', label: 'TSN (Hours)', sortOption: false },
        { id: 'csn', label: 'CSN (Cycles)', sortOption: false },

]
export const utilsFilterOps = {
        utilsFrom: {
                inputType: 'date',
                title: 'Utilization From'
        },
        utilsTill: {
                inputType: 'date',
                title: 'Utilization Till'
        },
}