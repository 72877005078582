import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';

export default class CreditDebitNote extends Component {
  constructor(props) {
    super(props)
  }
  render(){
    const { type, invoiceInfo, invoice_item, index, error } = this.props
    if(type === 'view'){
      return(
        <tr>
          <td className="invoice-penalty-description-block">
              <h6 className="para-ui"> {invoice_item?.description||'--' }</h6>
            </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.rate_per_unit ? invoice_item.rate_per_unit : '0'}
              </NumericLabel>
            </h6>
          </td>
          <td>
            <h6 className="para-ui"> {invoice_item.tax_rate ? invoice_item.tax_rate : '0'}%</h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.tax_amount ? invoice_item.tax_amount : '0'}
              </NumericLabel>
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat(invoiceInfo.currency)}>
                {invoice_item.sub_total ? invoice_item.sub_total : '0'}
              </NumericLabel>
            </h6>
          </td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td className="invoice-penalty-description-block">
            <input type="text" value={invoice_item.description}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'description', index)}
            />
          </td>
          <td>
            <input type="text" value={invoice_item.rate_per_unit ? invoice_item.rate_per_unit : ''}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'rate_per_unit', index)}
            />
            <h6 className="error-msg">{error.rate_per_unit}</h6>
          </td>
          <td className="invoice-usd-day">
            <input type="text" value={invoice_item.tax_rate ? invoice_item.tax_rate : ''}
              onChange={(e) => this.props.updateInvoiceItemField(e.target.value, 'tax_rate', index)}
            />
            <h6 className="error-msg">{error.tax_rate}</h6>
          </td>
          <td>
            <h6 className="para-ui">
              <NumericLabel params={showCurrencyFormat()}>
                {invoice_item.tax_amount}
              </NumericLabel>
            </h6>
          </td>
          <td>
            <h6 className="para-ui">
              {
                invoice_item.sub_total && invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.id === 5 && invoice_item.sub_total > 0 ?
                  <NumericLabel params={showCurrencyFormat()}>
                    {0 - invoice_item.sub_total}
                  </NumericLabel>
                  : <NumericLabel params={showCurrencyFormat()}>
                    {invoice_item.sub_total}
                  </NumericLabel>
              }
            </h6>
          </td>
        </tr>
      )
    }
  }
}
