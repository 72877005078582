import React, { Component } from 'react';
export default class  SecondaryTechHeaderRH extends Component {
  render(){
    return(
      <ul className="list-inline flex-centered">
        
      </ul>
    )
  }
}
