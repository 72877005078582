import React, { Component } from 'react';
import { Link } from 'react-router';
import { UserSideBar } from '../components';
import { Row, Col } from 'reactstrap';
import { globalPostService } from '../../globalServices';
import { browserHistory } from 'react-router';
import { toastFlashMessage } from '../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';

export default class ChangePassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      passInfo : {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      processing: false,
      error: {

      }
    }
  }

  updateForm = (e) => {
    e.preventDefault();
    let objError = {
      oldPassword: this.state.passInfo.oldPassword == '' ? 'Please enter Old Password':'',
      newPassword: this.state.passInfo.newPassword == '' ? 'Please enter New Password':'',
      confirmPassword: this.state.passInfo.confirmPassword == '' ?  'Please enter Confirm Password' : this.state.passInfo.confirmPassword != this.state.passInfo.newPassword ? 'Passwords do not match. Try Again':''
    }

    if(!objError.newPassword){
      let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\^w\s]).{8,}$/;
      if(!passwordRegex.test(this.state.passInfo.newPassword)){
        objError = {
              ...objError,
              newPassword: 'Password does not meet the policy requirements'
          }
      }
     }

    if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
      this.setState({
        processing: true
      })
      globalPostService('/api/password-change/', {old_password: this.state.passInfo.oldPassword,password: this.state.passInfo.newPassword,confirm_password: this.state.passInfo.confirmPassword})
      .then(response => {
        this.setState({
          processing: false
        })
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success');
          browserHistory.push('user-profile');
        }
      })
    }else{
      this.setState({
        error: objError,
        processing: false
      });
    }
  }

  updateField = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      passInfo: {
        ...prevState.passInfo,
        [type] : value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }));
  }

  createContract = () => {
    this.props.changePassword(this.state.passInfo)
  }
  render(){
    const { passInfo, error  } = this.state
    return(
      <div className="contract-container user-profile-blk">
        <UserSideBar/>
        {<h4 className="gama">Change Password</h4> }
          <div className="user-profile">
            <form onSubmit={this.updateForm}>
              <Row className="user-detail">
                <Col md="8">
                    <FieldCol md="12">
                      <FieldGroup className="form-group">
                       <FieldLabel className="label">
                         Old Password
                          <sup className="reqStar"> * </sup>
                        </FieldLabel>
                        <input
                        className=""
                        value ={this.state.passInfo.oldPassword}
                        type="password"
                        name="oldPassword"
                        onChange = {(e) => this.updateField('oldPassword',e.target.value) }
                        />
                      <h6 className="error-msg">{error.oldPassword}</h6>
                      </FieldGroup>
                    </FieldCol>
                    <FieldCol md="12">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">
                        New Password
                          <sup className="reqStar"> * </sup>
                        </FieldLabel>
                        <input
                        className=""
                        value ={this.state.passInfo.newPassword}
                        type="password"
                        name="newPassword"
                        onChange = {(e) => this.updateField('newPassword',e.target.value) }
                        />
                        <h6 className="error-msg">{error.newPassword}</h6>
                      </FieldGroup>
                    </FieldCol>
                    <FieldCol md="12">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">
                        Confirm Password
                          <sup className="reqStar"> * </sup>
                        </FieldLabel>
                        <input
                          className=""
                          type="password"
                          value ={this.state.passInfo.confirmPassword}
                          name="confirmPassword"
                          onChange = {(e) => this.updateField('confirmPassword',e.target.value) }
                        />
                        <h6 className="error-msg">{error.confirmPassword}</h6>
                      </FieldGroup>
                    </FieldCol>
                </Col>

              </Row>
              <div className="submit-block">
                <input type="submit" className="primary-btn" disabled={this.state.processing ? 'disabled' : ''} value={this.state.processing ? 'Processing...' : 'Change Password'}/>
              </div>
            </form>
          </div>
      </div>
    )
  }
}
