import { Grid, TextField } from '@material-ui/core'
import React, { Fragment } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { LabelValueCard, TableListComp } from '../../../shared_elements';
import LessorInfo from './LessorInfo';
import LesseeInfo from './LesseeInfo';
import { Row } from 'reactstrap';
import { assemblyInvoiceHd, creditDebitHd } from '..';
import NumericLabel from 'react-pretty-numbers';
import { showCurrencyFormat } from '../../../utils';
import CreditDebitList from './CreditDebitList';
import EditAssemblyLeaseList from './EditAssemblyLeaseList';
import BankingInfo from '../../../applications/contracts/Invoice/components/BankingInfo';
import { regexConstants } from '../../../constants/regEx';
export default function EditInvoice({ setDetailsPage, invoiceCreatedDetails, onChangeInvoiceDetails, onSubmitInvoiceDetails, validationMsgs, updateErrorField }) {
    return (
        <Fragment>
            <Grid container spacing='3' style={{ width: 'auto', margin: '0px' }} >
                <Grid item sm='3' xs='12'>
                    <TextField
                        required
                        label='Invoice Number'
                        id="removaltsn"
                        placeholder='Please Enter Invoice Number'
                        fullWidth
                        margin="normal"
                        value={invoiceCreatedDetails?.invoice_number ? invoiceCreatedDetails?.invoice_number : ''}
                        variant='outlined'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 255 }}
                        error={validationMsgs.invoice_number ? true : false}
                        onFocus={() => updateErrorField('invoice_number')}
                        helperText={validationMsgs.invoice_number ? validationMsgs.invoice_number : ''}
                        onChange={(e) => onChangeInvoiceDetails(e, 'invoice_number', e.target.value)}
                    />
                </Grid>
                <LabelValueCard md={3} label='Invoice Status' value={invoiceCreatedDetails?.status?.label ? invoiceCreatedDetails?.status?.label : '--'} />
                <Grid item sm='3'>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            placeholder='DD-MM-YYYY'
                            style={{ position: "relative", top: '16px' }}
                            inputVariant="outlined"
                            variant='outlined'
                            margin="none"
                            id="date"
                            error={validationMsgs.date ? true : false}
                            helperText={validationMsgs.date ? validationMsgs.date : ''}
                            onFocus={() => updateErrorField('date')}
                            label="Invoice Date"
                            value={invoiceCreatedDetails?.date ? invoiceCreatedDetails?.date : null}
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => { onChangeInvoiceDetails(data, 'date', moment(data).format(backendDateFormat)) }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm='3'>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            placeholder='DD-MM-YYYY'
                            style={{ position: "relative", top: '16px' }}
                            inputVariant="outlined"
                            variant='outlined'
                            margin="none"
                            id="due_date"
                            error={validationMsgs.due_date ? true : false}
                            helperText={validationMsgs.due_date ? validationMsgs.due_date : ''}
                            onFocus={() => updateErrorField('due_date')}
                            label="Invoice Due Date"
                            value={invoiceCreatedDetails?.due_date ? invoiceCreatedDetails?.due_date : null}
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => { onChangeInvoiceDetails(data, 'due_date', moment(data).format(backendDateFormat)) }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm='3'>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            placeholder='DD-MM-YYYY'
                            style={{ position: "relative", top: '16px' }}
                            inputVariant="outlined"
                            variant='outlined'
                            margin="none"
                            id="start_date"
                            error={validationMsgs.start_date ? true : false}
                            helperText={validationMsgs.start_date ? validationMsgs.start_date : ''}
                            onFocus={() => updateErrorField('start_date')}
                            // maxDate={invoiceValidateInfo?.end_date ? invoiceValidateInfo?.end_date : ''}
                            label="Start Date"
                            value={invoiceCreatedDetails?.start_date ? invoiceCreatedDetails?.start_date : null}
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => { onChangeInvoiceDetails(data, 'start_date', moment(data).format(backendDateFormat)) }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm='3'>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            placeholder='DD-MM-YYYY'
                            style={{ position: "relative", top: '16px' }}
                            inputVariant="outlined"
                            variant='outlined'
                            margin="none"
                            id="end_date"
                            error={validationMsgs.end_date ? true : false}
                            helperText={validationMsgs.end_date ? validationMsgs.end_date : ''}
                            onFocus={() => updateErrorField('end_date')}
                            label="End Date"
                            value={invoiceCreatedDetails?.end_date ? invoiceCreatedDetails?.end_date : null}
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => { onChangeInvoiceDetails(data, 'end_date', moment(data).format(backendDateFormat)) }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <div className="assembly-invoice">
                <form>
                    <Row className="row" style={{ marginTop: '35px', marginLeft: '15px', marginBottom: '20px' }}>
                        <LessorInfo
                            invoiceInfo={invoiceCreatedDetails}
                            type="edit"
                            updateInvoiceField={(value, key) => onChangeInvoiceDetails('', key, value)}
                        />
                        <LesseeInfo
                            invoiceInfo={invoiceCreatedDetails}
                            type="edit"
                            toLessor='true'
                            updateInvoiceField={(value, key) => onChangeInvoiceDetails('', key, value)}
                        />
                    </Row>
                </form>
            </div>
            {
                (invoiceCreatedDetails.invoice_type?.value == 4 || invoiceCreatedDetails.invoice_type?.value == 5) ?
                <TableListComp
                heads={creditDebitHd}
                data={invoiceCreatedDetails.invoice_items && invoiceCreatedDetails.invoice_items.map((item, index) =>
                        <CreditDebitList
                        invoiceInfo={invoiceCreatedDetails}
                        mode="edit"
                        invoice_item={item}
                        onFieldChange={(event, key, value) => onChangeInvoiceDetails('invoice_items', key, value, index)}
                    />
                )}
            />
            :
            <div style={{ padding: '0px 15px' }}>
                <TableListComp
                    heads={assemblyInvoiceHd}
                    data={invoiceCreatedDetails.invoice_items && invoiceCreatedDetails.invoice_items.map((item, index) =>
                        <EditAssemblyLeaseList
                            invoiceInfo={invoiceCreatedDetails}
                            mode="edit"
                            invoice_item={item}
                            onFieldChange={(event, key, value) => onChangeInvoiceDetails('invoice_items', key, value, index)}
                        />
                    )}
                />
            </div>
}
            <div className="sub-total-block" style={{ marginTop: '10px', paddingRight: '15px' }}>
                <h5 style={{ textAlign: 'right' }} className="para-ui">Billable Amount :
                    <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                        {invoiceCreatedDetails.invoice_items.reduce((total, next) =>
                            total + next.billable_amount, 0
                        )}
                    </NumericLabel>

                </h5>
                {/* {
                    invoiceCreatedDetails.status?.value == '5' ?
                        <h5 style={{ textAlign: 'right' }} className="para-ui">Paid Amount :
                            <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                                {invoiceCreatedDetails.partial_paid_amount}
                            </NumericLabel>
                        </h5> : null
                } */}
                <h4 style={{ textAlign: 'right' }} className="para">Total:
                    <NumericLabel params={showCurrencyFormat(invoiceCreatedDetails.currency)}>
                        {invoiceCreatedDetails.total_amount ? invoiceCreatedDetails.total_amount : invoiceCreatedDetails.sub_total !== undefined && invoiceCreatedDetails.sub_total !== null && invoiceCreatedDetails.sub_total !== '' ? invoiceCreatedDetails.sub_total : '--'}
                    </NumericLabel>
                </h4>
            </div>
            <div style={{ margin: '20px 15px 10px 15px' }}>
                <h4 className="para invoice-heading heading-sans-bold">Bank Detail {invoiceCreatedDetails.invoice_type == 2 ? 'For ' + invoiceCreatedDetails.currency + ' Rental Receivable' : ''}</h4>
                <BankingInfo
                    assemblyInvoice='true'
                    type="edit"
                    error={''}
                    updateInvoiceField={(value, key) => onChangeInvoiceDetails('', key, value)}
                    invoiceInfo={invoiceCreatedDetails}
                />
            </div>
        </Fragment>
    )
}
