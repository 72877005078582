import React from 'react';
import { Link } from 'react-router'
import editIcon from '../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../shared/assets/img/view_icon.svg';
import revisionHistoryIcon from '../../../shared/assets/img/revision_history_icon.svg';
import techSpecIcon from '../../../shared/assets/img/tech_specs_icon.svg';
import config from '../../../config';
const FormModeType = (props) => {
  if (props.type != 'add') {
    return (
      <div>
        <ul className='list-inline edit-audit-history'>
          <li className='list-inline-item'>
            {props.showTechnicalSpec ?
              <span>
                <Link
                    to={`${config.domain.subDomian}technical-specification/${props.assetType}/${props.aircraftSlug}`}
                    target='_blank'
                    style={{ color: '#3f51b5' }}
                  >
                  <img width="15" src={techSpecIcon} alt="tech summery" />
                  Technical Specification
                </Link>
              </span> : null
            }
          </li>
          <li className='list-inline-item'>
            {props.pendingReview ?
              <span className="" style={{ right: '225px', color: '#999999' }} >{props.rentalView.requested_data && Object.keys(props.rentalView.requested_data).length ? '( Pending Review )' : ''}</span>
              : null
            }
          </li>
          <li className='list-inline-item'>

            <span onClick={props.auditLogMode} className="">{props.auditLog ? <img style={{ marginRight: '5px' }} src={revisionHistoryIcon} width="13" alt="img" /> : ' '} {props.auditLog ? 'Revision History' : ' '}</span>
          </li>
          <li className='list-inline-item'>
            {
              props.editAble == undefined || props.editAble ?
                <span onClick={props.changeViewMode}>
                  {props.type == 'view' ?
                    <img className="blue-icon" src={editIcon} width="12" alt="icon" /> :
                    <img className="blue-icon" src={viewIcon} width="16" alt="icon" />
                  }
                  <span className="blue-action">{props.type == 'view' ? ' EDIT' : 'VIEW'}</span>
                </span> : null
            }
          </li>
        </ul>

      </div>
    )
  } else {
    return (
      <div>
        {props.showTechnicalSpec ?
          <span className='edit-audit-history' >
            <Link
                to={`${config.domain.subDomian}technical-specification/${props.assetType}/${props.aircraftSlug}`}
                target='_blank'
                style={{ color: '#3f51b5' }}
              >
                <img width="15" src={techSpecIcon} alt="tech summery" />
                Technical Specification
            </Link>
          </span> : null
        }
      </div>
    )
  }
}

export default FormModeType;
