import React, {Component} from 'react';
import Pagination from "react-js-pagination";
export default class AssetsPagination extends Component{
    constructor(props) {
      super(props);
    }
    handlePageChange = (pageNumber) => {
      this.props.updateAssetPageNo(pageNumber);
    }
    render(){
      if( this.props.pagintionInfo && Object.keys(this.props.pagintionInfo).length){
        return(
          <div className="pagination" style={{marginTop:'-7px'}}>
                <Pagination
                 activePage={this.props.currentPage ? parseInt(this.props.currentPage):1}
                 itemsCountPerPage={this.props.pagintionInfo.per_page}
                 totalItemsCount={this.props.pagintionInfo.total}
                 pageRangeDisplayed={3}
                 onChange={this.handlePageChange}
               />
          </div>
        )
      }else{
        return null;
      }

    }
}
