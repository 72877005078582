import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addSecurityDeposit = {
  security_deposit_type: '',
  security_against: '',
  amount: '',
  currency: 'USD',
  is_refundable: false,
  is_interest_refundable: false,
  interest_rate: '',
  remarks: ''
};
export const addCashTx = {
  type_of_transaction: '1',
  reference_number: '',
  transaction_date: null,
  retention_period_start_date: null,
  retention_period_end_date: null,
  amount: '',
  currency: 'USD',
  on_behalf_of: null,
  issuing_party: null,
  confirming_party: null
};
export const cashTxHd = [
  { label: 'Reference Number', sortOption: false },
  { label: 'Transaction Date', sortOption: false },
  { label: 'Validity Period', sortOption: false },
  { label: 'Amount (USD)', sortOption: false },
  { label: 'Action', sortOption: false }
];
export const addNonCashTx ={
  type_of_transaction: '2',
  reference_number: '1',
  transaction_date: null,
  retention_period_start_date: null,
  retention_period_end_date: null,
  amount: '',
  currency: 'USD',
  on_behalf_of: '',
  issuing_party: '',
  confirming_party: '',
  sub_type_of_transaction : '',
  purpose : ''
};
export const nonCashTxHd = [
  { label: 'Financial Instrument Type', sortOption: false },
  { label: 'Validity Period', sortOption: false },
  { label: 'Amount (USD)', sortOption: false },
  { label: 'Purpose', sortOption: false },
  { label: 'Action', sortOption: false }
]
export const errorCode = {
  security_deposit_type:{
    0:'',
    1: 'Please select Security Deposit Type'
  },
  security_deposit_typeObj: {
    required: true
  },
  security_against: {
    0:'',
    1:'Please enter Security Against',
    4:errorMsg1
  },
  security_againstObj: {
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  amount: {
    0:'',
    1: 'Please enter Amount'
  },
  amountObj: {
    required: true
  },
  reference_number: {
    0:'',
    1: 'Please enter Reference Number',
    4:errorMsg1
  },
  reference_numberObj: {
    required: true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  transaction_date: {
    0:'',
    1: 'Please enter Transaction Date'
  },
  transaction_dateObj: {
    required: true
  },
  sub_type_of_transaction: {
    0:'',
    4:errorMsg1
  },
  sub_type_of_transactionObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  on_behalf_of: {
    0:'',
    4:errorMsg1
  },
  on_behalf_ofObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  issuing_party: {
    0:'',
    4:errorMsg1
  },
  issuing_partyObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  confirming_party: {
    0:'',
    4:errorMsg1
  },
  confirming_partyObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  purpose: {
    0:'',
    4:errorMsg1
  },
  purposeObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
}
