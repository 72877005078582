import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, Drawer } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../../../utils_v2';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { addEditWorkingGroupsApi, getWorkingGroupsApi } from '../apiServices';
import { errorCode } from '../';
class TeamCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader: false,
      team:{},
      error:{}
    }
    this.addEditWorkingGroupsApi = addEditWorkingGroupsApi.bind(this);
    this.getWorkingGroupsApi = getWorkingGroupsApi.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      team: {
        ...prevState.team,
        [keyParam]:value
      }
    }))
  }
  addEditTeamFn = () => {
    const { team } = this.state;
    const { m } = this.props;
    let validationInputs = {
      name:errorCode['name'][fieldValidation({...errorCode['nameObj'], fieldval: team.name})],
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.addEditWorkingGroupsApi(this.props, team)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({
            modal:false,
            team:{}
          });
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          // this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      });
    }else{
      this.setState({error: validationInputs})
    }
  }
  render(){
    const { modal, formSubmitLoader, team, error} = this.state;
    const { id, contractInfo } = this.props;
    return(
      <Fragment>
        { contractInfo?.archived_asset == false ?id ? 
          <Tooltip title="Edit Working Group Name">
           <span> { checkPermission('contracts', 'working_groups', 'U') ? <EditIcon style={{width:'18px', position:'relative', top:'7px'}} onClick={() => this.getWorkingGroupsApi(this.props, id)} color="primary" /> : null }</span>
          </Tooltip>:
          <Button onClick={() => this.setState({modal:true, team:{name:''}})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Working Group</Button>
        :null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, team:{}, error:{}})}
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">
                { team.id ? 'Edit ':'Add '}Working Group
              </div>
              <div className="drawer-body">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="name"
                      label="Name"
                      fullWidth
                      margin="normal"
                      value={team.name}
                      error={error.name ? true:false }
                      helperText={error.name ? error.name:''}
                      onChange={(e, value) => this.onFieldChange(e, 'name', e.target.value)}
                      onFocus={(e, value) => this.setState(prevState => ({...prevState, error: {...prevState.error, name:''}}))}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="drawer-footer">

                <Button onClick={this.addEditTeamFn} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                </Button>
                <Button onClick={() => this.setState({modal:false, team:{}, error:{}})} color="primary">Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(TeamCRU));
