import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router'
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Paper, Grid, TextField } from '@material-ui/core';
import { TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { AddMRUtilRow } from '../components';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { getUtilsInitiateApi, addMRUtilizationApi } from '../apiServices';
import { getContractClauseAc, sExportContractsAc } from '../../actionCreators';
import { assetsType } from '../../../constants';
import { addMRUtilsHd } from '../'
const monthArray = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' }
];
class AddMRUtilization extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      months: [],
      utilsInitiate:{
        initial_utilization: false,
        assets: [],
        utilization: {},
        rental_utilization: {},
      },
      utilsError:[],
      error:{},
      year: null,
      month:null,
    }
    this.getUtilsInitiateApi = getUtilsInitiateApi.bind(this);
    this.addMRUtilizationApi = addMRUtilizationApi.bind(this);
  }
  componentDidMount(){
    this.getUtilsInitiateApi(this.props);
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  onChangeYearMonth = (keyParam, value) => {
    const { utilsInitiate } = this.state;
    this.setState({error:{}});
    if(keyParam === 'year'){
      if(value){
        if(value == parseInt(moment().format('YYYY')) && moment().format('YYYY') == moment(utilsInitiate.utilization.start_month).format('YYYY')){
          let currentDate = false;
          let mt = [];
          if(moment(utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM')){
            if(utilsInitiate.utilization.is_contract_start_date){
              currentDate = true;
            }
          }
          if(utilsInitiate.utilization.is_contract_start_date){
            currentDate = true;
          }
          if(currentDate){
            mt = monthArray.filter((month,index) => parseInt(moment(utilsInitiate.utilization.start_month).format('M')) <= (index+1) && (index+1) <= parseInt(moment().format('M')));
          }else{
            mt = monthArray.filter((month,index) => parseInt(moment(utilsInitiate.utilization.start_month).format('M')) < (index+1) && (index+1) <= parseInt(moment().format('M')));
          }
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months:mt
          }));
        }else if (value == parseInt(moment().format('YYYY'))) {
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: monthArray.filter((month,index) => (index+1) <= parseInt(moment().format('M')))
          }));
        }else if (value == moment(utilsInitiate.utilization.start_month).format('YYYY')) {
          let sameMonth = false, monthlist = [];
          if(utilsInitiate.utilization.is_contract_start_date){
            sameMonth = true;
          }
          if(sameMonth){
            monthlist = monthArray.filter((month,index) => (index+1) >= parseInt(moment(utilsInitiate.utilization.start_month).format('M')))
          }else{
            monthlist = monthArray.filter((month,index) => (index+1) > parseInt(moment(utilsInitiate.utilization.start_month).format('M')))
          }
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months:monthlist
          }));
        }else{
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: monthArray
          }));
        }
      }else{
        this.setState({
          year:value,
          month:null,
          months:[],
        })
      }
    }else{
      this.setState({month:value})
    }
  }
  onFieldChange = (e, keyParam, value, index) => {
    const { utilsInitiate } = this.state;
    if(keyParam === 'asset_location' || keyParam === 'remarks'){
      this.setState(prevState => ({
        ...prevState,
        utilsInitiate:{
          ...prevState.utilsInitiate,
          assets: prevState.utilsInitiate.assets.map((item, itemIndex) => itemIndex === index ? {
            ...item,
            [keyParam]:value
          } :item)
        }
      }))
    }else{
      let asset = utilsInitiate.assets[index];
      switch (keyParam) {
        case 'tsn_as_of_last_month':
          asset = {
            ...asset,
            tsn_as_of_last_month: value,
            tsn: parseFloat(utilsInitiate.assets[index].hours)+parseFloat(value.trim() ? value:0)
          }
          break;
        case 'csn_as_of_last_month':
          asset = {
            ...asset,
            csn_as_of_last_month: value,
            csn: parseFloat(utilsInitiate.assets[index].cycles)+parseFloat(value.trim() ? value:0)
          }
          break;
        case 'hours':
          asset = {
            ...asset,
            hours: value,
            tsn: parseFloat(utilsInitiate.assets[index].tsn_as_of_last_month)+parseFloat(value.trim() ? value:0)
          }
          break;
        case 'cycles':
          asset = {
            ...asset,
            cycles: value,
            csn: parseFloat(utilsInitiate.assets[index].csn_as_of_last_month)+parseFloat(value.trim() ? value:0)
          }
          break;
        case 'tsn':
          asset = {
            ...asset,
            hours: parseFloat(value.trim() ? value:0) - parseFloat(utilsInitiate.assets[index].tsn_as_of_last_month),
            tsn: value
          }
          break;
        case 'csn':
          asset = {
            ...asset,
              cycles: parseFloat(value.trim() ? value:0) - parseFloat(utilsInitiate.assets[index].csn_as_of_last_month),
              csn: value
            }
          break;
        default:
          break;
      }
      this.setState(prevState => ({
        ...prevState,
        utilsInitiate: {
          ...prevState.utilsInitiate,
          assets:prevState.utilsInitiate.assets.map((item, itemIndex) => itemIndex === index ? asset:item)
        }
      }))
    }
  }
  updateErrorField = (key, message, index) => {
    this.setState(previous => ({
      ...previous,
      utilsError: previous.utilsError.map((item, itemIndex) => itemIndex === index ? {...item, [key]:''} :item)
    }))
  }
  checkTSNValid = (asset) => {
    if(asset.tsn_as_of_last_month >= 0 && ( asset.tsn === undefined || asset.tsn <= 0 )){
      return 'Please enter TSN'
    }else if (asset.tsn < asset.tsn_as_of_last_month) {
      return 'Can not be lesser than previous TSN'
    }else{
      return ''
    }
  }
  checkCSNValid = (asset) => {
    if(asset.csn_as_of_last_month >= 0 && ( asset.csn === undefined || asset.csn <= 0 )){
      return 'Please enter CSN'
    }else if (asset.csn < asset.csn_as_of_last_month) {
      return 'Can not be lesser than previous CSN'
    }else{
      return ''
    }
  }
  checkRemarksCond = (asset) => {
    if(asset.hours && asset.cycles && asset.tsn && asset.tsn){
      if((asset.hours < asset.avg_hours) || (asset.cycles < asset.avg_cycles)){
        if(asset.remarks){
          return ''
        }else{
          return 'Remarks is required since the current month utilization is less than 15% of previous month'
        }
      }else{
        return ''
      }
    }else{
      if(asset.remarks){
        return '';
      }else{
        return 'Remarks is required since the current month utilization is less than 15% of previous month'
      }
    }
  }
  addMRUtilizationFn = () => {
    const { utilsInitiate, month, year } = this.state;
    let utilsFlag = true, utilsError = [];
    utilsError = utilsInitiate.assets.map((asset, index) => {
      return {
        tsn:this.checkTSNValid(asset),
        csn:this.checkCSNValid(asset),
        hours:asset.hours > 0 ? '':'Please enter Hours',
        cycles:asset.cycles > 0 ? '':'Please enter Cycles',
        remarks:this.checkRemarksCond(asset)
      }
    });
    utilsError.map(data => {
      if(!Object.keys(data).every((k) => { return data[k] === ''})){
        utilsFlag = false
      }
    });
    let validationInputs = {
      year: year ? '':'Please select Year',
      month: month ? '':'Please select Month'
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) && utilsFlag){
      this.addMRUtilizationApi(this.props, {assets:utilsInitiate.assets, month: month,year:year});
    }else{
      this.setState({
        error:validationInputs,
        utilsError:utilsError
      });
    }
  }
  render(){
    const { pageLoader, utilsInitiate, utilsError, error, month, year, months } = this.state;
    const { params } = this.props;
    if(!utilsInitiate.assets.length){return null}
    if(moment(utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM')){
      if(!utilsInitiate.utilization.is_contract_start_date){
        return <h3 className="text-center">Cant Add the Utilization</h3>
      }
    }
    const years = [];
    const currentYear = parseInt(moment().format('YYYY'));
    let contractStart = parseInt(moment(utilsInitiate.utilization.start_month).format('YYYY'));
    if(moment(utilsInitiate.utilization.start_month).format('M') == '12' && !utilsInitiate.utilization.is_contract_start_date){
      contractStart++;
    }
    while(contractStart <= currentYear){
      years.push({label:contractStart.toString(), value: contractStart})
      contractStart += 1
    }
    return(
      <div className="contract-container">
        <ContractSideBar application={`Edit Utilization`} type="brief" params={this.props.params} />
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <div className="contract-content-blk">
          <Link to={`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/utilization`}>
              <KeyboardBackspaceIcon color="primary" fontSize="small" /> Add Monthly Utilization
          </Link>
          <div style={{background:'#FFFBD8', border:'1px solid #FFEBD8', borderRadius:'3px'}}>
            <div></div>
            <div>
              <p style={{fontSize:'13px'}}>
                Present location of original assemblies pertains to the airframe on which it is currently installed. Ensure to review and upate as applicable.
                In case of low or NIL utilization, please ensure to add appropriate remarks for the assembly
              </p>
            </div>
          </div>
          <Paper>
            <div style={{padding:'15px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {years}
                    getOptionLabel={option => option.label}
                    id="year"
                    value={year && years.length ? years.find(item => item.value === year):null}
                    onChange={(e, data) => this.onChangeYearMonth('year', data?data.value:data)}

                    renderInput={params => <TextField required error={error.year ? true:false} helperText={error.year ? error.year:''} {...params} label="Select Year" placeholder="Select Year" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {months}
                    getOptionLabel={option => option.label}
                    id="month"
                    value={month && months.length ? months.find(item => item.value === month):null}
                    onChange={(e, data) => this.onChangeYearMonth('month', data?data.value:data)}
                    renderInput={params => <TextField required error={error.month ? true:false} helperText={error.month ? error.month:''} {...params} label="Select Month" placeholder="Select Month" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
              </Grid>
            </div>
            <TableListComp
              heads={addMRUtilsHd}
              data={utilsInitiate.assets.map((item,index) =>
                <AddMRUtilRow
                  key={index}
                  index={index}
                  item={item}
                  onFieldChange={this.onFieldChange}
                  updateErrorField={this.updateErrorField}
                  error={utilsError.length && utilsError[index] ? utilsError[index]:{}}
                />
              )}
              noRecord={ utilsInitiate.assets.length ? null:
                <EmptyCollection
                  title="No records found"
                />
              }
            />
            <Button style={{margin:'15px'}} color="primary" variant="contained" onClick={this.addMRUtilizationFn}>Submit</Button>
          </Paper>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.contractReducer.contractSummaryLoader
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(AddMRUtilization));
