import * as actions from './actions';
export default function( state = {
  pltConstants: [],
  pltConstantsLeaseType:[],
  pltConstantsLeaseStatus:[],
  apuTypes: [],
  aircraftTypes: [],
  engineTypes:[],
  lessee: [],
  lessor: [],
  operator: [],
  regions: [],
  portfolios:[],
  manufacturers:[],
  aircraftMntGrp:[],
  assetsSwicher:[],
  pageloader:false,
}, action){
  switch (action.type) { 
    case actions.PLATFORM_CONTANTS:
      return {
        ...state,
        pltConstants: action.payload
      }
      case actions.PLATFORM_CONTANTS_LEASE_TYPE:
      return {
        ...state,
        pltConstantsLeaseType: action.payload
      }
      case actions.PLATFORM_CONTANTS_LEASE_STATUS:
      return {
        ...state,
        pltConstantsLeaseStatus: action.payload
      }
    case actions.LESSOR_LIST:
      return {
        ...state,
        lessor: action.payload
      }
    case actions.LESSEE_LIST:
      return {
        ...state,
        lessee: action.payload
      }
    case actions.OPERATOR_LIST:
      return {
        ...state,
        operator: action.payload
      }
    case actions.APU_TYPES:
      return {
        ...state,
        apuTypes: action.payload
      }
    case actions.REGION_LIST:
      return {
        ...state,
        regions: action.payload
      }
    case actions.AIRCRAFT_TYPES:
      return {
        ...state,
        aircraftTypes: action.payload
      }
    case actions.ENGINE_TYPES:
      return {
        ...state,
        engineTypes: action.payload
      }
    case actions.PORTFOLIO_LIST:
      return {
        ...state,
        portfolios: action.payload
      }
    case actions.MANUFACTURER_LIST:
      return {
        ...state,
        manufacturers: action.payload
      }
    case actions.ASSETS_SWITCHER:
      return {
        ...state,
        assetsSwicher: action.payload
      }
      case actions.PAGE_LOADER:
        return{
          ...state,
          pageloader: action.payload
        }
    default:
      return state;
  }
}
