import React, { Component } from 'react';
class RecordsFleetDb extends Component {
  render(){
    return(
      <div className="fleet-records-db">
        
      </div>
    )
  }
}

export default RecordsFleetDb;
