import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router'
import { globalPutService } from '../../../globalServices';
import { Row, Col,Modal,ModalBody,ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FleetDashboardCard from '../components/FleetDashboardCard';
import { fleetSummeryWidgetAc, assetsInfoWidgetAc,  fetchAppsCardWidgetAc, aircraftDistributionWidgetAc, getOperatorRatingAc } from '../actionCreators';
import { FleetSumTableHeading, AircraftDistribution, AssetWidget, FleetSummary, OperatorRatingCard, ApplicationDetail } from '../components';
import { NoRecordFound, InfoIcon, ToolTipHover }  from '../../../shared';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import { DB_SWITCHER } from '../../../shared/actions';
import '../assets/styles/dashboard.scss';
class FleetDashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      dashboard: false,
      loader: false,
      dropdownOpen: false,
      asset_type: 'aircraft_types',
      searchText: ''
    }
  }
  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.fetchFleetSummary();
    ['technical', 'contacts', 'maintenance', 'records'].map(type => {
      this.props.fetchAppsCardWidget(type);
    });
    this.props.fetchDistributedWidget();
    this.props.fetchOperatorRating();
    trackActivity('Page Visited',{
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Fleet Dashboard'
    })
  }

  toggleModel=()=>{
    this.setState({
      modal: !this.state.modal,
    });
  }
  resetDasboard = (data, flag) => {
    if(flag){
      this.setState({dashboard: false});
    }else{
      this.setState({loader: true});
    }
    globalPutService('console/user/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        let userInfo = getLocalStorageInfo();
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this.props.dbSwitcherFn();
        if(flag){
          this.setState({dashboard: false});
        }else{
          this.setState({loader: false});
        }
      }
    })
  }


  searchResult = (e) => {
    e.preventDefault();
    if(this.state.searchText.trim() != ''){
      browserHistory.push(`/assets/fleet/search/?application=&level=Fleet&searchText=${this.state.searchText}`)
    }
  }

  render(){
    const { fleetSummaryWidget, assetsInfoWidget, technicalAppWiget, contractsAppWidget, maintenanceAppWidget, recordsAppWidget,  aircraftDisWidget, dbAssetLoader, dbFleetSummLoader, dbAircraftDisLoader, oprRatingWidget, dbAppModLoader } = this.props;

    let assetWidgetCard
    if(getLocalStorageInfo().defaultLessor.id === 348){
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'OPERATIONAL',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'AOG', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
      ]
    }else{
      assetWidgetCard = [
       { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
       { title: 'ON LEASE',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
       { title: 'OFF LEASE', ownedUrl: '/assets-listing?status=2&ownership_type=1',managedUrl: '/assets-listing?status=2&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 2).length, url: '/assets-listing?status=2', classes: 'widget-cards marginRight marginLeft flex-centered off-lease'},
       { title: 'ON GROUND', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
       { title: 'WRITTEN OFF / SOLD', ownedUrl: '/assets-listing?status=4&ownership_type=1',managedUrl: '/assets-listing?status=4&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing?status=4', classes: 'widget-cards marginLeft flex-centered written-off'}
     ]
    }
    var showSplitTabs = false
    assetWidgetCard.map(item => {
      showSplitTabs = (item.managed > 0) ? true : showSplitTabs
      return item
    })
    return(
      <div className="fleet-dashboard">

          <div className="fleet-db-news-feed" style={{marginBottom: '60px', marginTop: '80px'}}>
            <div className="" style={{width: '50%', margin: '0 auto'}}>
              <h1 className="text-center">
                Welcome to SPARTA, your Digital Asset Manager<br/>
                What can we help you with today?
              </h1>
              { getLocalStorageInfo('userInfo').user.permission.records ?
                <form onSubmit={(e) => this.searchResult(e)} style={{position: 'relative'}}>
                  <input ref="global_search" value={this.state.searchText} name="global_search" onChange={(evt) => this.setState({searchText: evt.target.value})} placeholder="Search the records database. For example HPT Blisk" type="text" />
                  <img src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/search_icn.png"
                    style={{
                      position: 'absolute',
                      right: '12px',
                      width: '26px',
                      top: '7px',
                      cursor: 'pointer',
                      zIndex: '99'
                    }}
                    onClick={(e) => this.searchResult(e)}
                  />
                  <span
                    style={{position: 'absolute', top: '3px', right: '-65px', fontSize: '24px', color:'#808080'}}
                    >
                    <i class="fa fa-info-circle" id='search-tool-info' aria-hidden="true"></i>
                    <i class="fa fa-question-circle" style={{marginLeft: '5px'}} id='search-accuracy-info' aria-hidden="true"></i>
                    <ToolTipHover placement="top" tagetId='search-tool-info'>
                      Type in the keyword which you are interested in and SPARTA will search the Records database for you. The search is focussed on delivering the files and documents which contain the keyword from the complete repository of records in the platform. Note: The best search results are delivered when the keyword is unique. For example unique serial numbers of parts. For example HPT1234UM01
                      <br/>
                      <br/>
                    </ToolTipHover>
                    <ToolTipHover placement="top" tagetId='search-accuracy-info'>
                      <b>Note:</b> The accuracy of search results will depend on the extent of files OCR’d in the system. The search feature will continue to be optimised further.
                    </ToolTipHover>
                  </span>

                </form>:null
              }

              <div className="text-center upcoming-features" style={{display: 'none'}}>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}))}>
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                  >
                    Features Coming soon... <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="list-unstyled">
                      <li style={{display:'none'}}>
                        Not sure what you are looking for? Just ask a question.
                        <i class="fa fa-info-circle" id='ask-ques' aria-hidden="true"></i>
                        <ToolTipHover placement="top" tagetId='ask-ques'>
                          SPARTA has a wide range of questions which user can use to quickly and efficiently navigate through the platform. Just typing the keyword will filter the questions and allow selection of the desired question.
                        </ToolTipHover>
                      </li>
                      <li>
                        Interested in industry updates? Click to search latest news.
                        <i class="fa fa-info-circle" id='latest-feeds' aria-hidden="true"></i>
                        <ToolTipHover placement="top" tagetId='latest-feeds'>
                          SPARTA will feature a News Feed section containing the latest updates from the industry, powered through collaboration with a trusted industry partner.
                        </ToolTipHover>
                      </li>
                    </ul>
                  </DropdownMenu>
                </Dropdown>

              </div>
            </div>
          </div>
        <div style={{borderTop: '1px solid #908f8f', paddingTop: '40px', position: 'relative'}}>


            <h4 style={{fontSize: '18px', marginBottom: '10px', paddingLeft: '5px'}}>Modules:</h4>

          <ApplicationDetail
             dbAppModLoader={dbAppModLoader}
             technicalAppWiget={technicalAppWiget}
             contractsAppWidget={contractsAppWidget}
             maintenanceAppWidget={maintenanceAppWidget}
             recordsAppWidget={recordsAppWidget}
           />
        </div>

        <Modal isOpen={this.state.dashboard} toggle={() => this.resetDasboard({accept_old_dashboard:false, default_dashboard_url: getLocalStorageInfo('userInfo').user.default_dashboard_url}, 'dbModal')} size="lg" >
           <ModalBody>
             <div className="fleet-dashboard" style={{ margin: '0', padding: '60px 40px'}}>
              <h1 style={{fontSize: '24px'}}>
                Welcome to the new Home page of SPARTA. In case you are missing the old dashboard, just click on the "Reset to Dashboard view". For any assistance, please contact <a mailto="support@sparta.aero" style={{color: 'blue'}}>support@sparta.aero</a>.
              </h1>
              <div className="text-center">
                <button
                  className="primary-btn"
                  onClick={() => this.resetDasboard({accept_old_dashboard:false, default_dashboard_url: getLocalStorageInfo('userInfo').user.default_dashboard_url}, 'dbModal')}
                  style={{marginTop:'40px', padding: '6px 40px'}}>{this.state.loader ?'Processing...':'Ok'}</button>
              </div>
             </div>
           </ModalBody>
         </Modal>
     </div>
    )
  }
}
const mapStateToProps = state => ({
  fleetSummaryWidget: state.DashboardReducer.fleetSummaryWidget,
  assetsInfoWidget: state.DashboardReducer.assetsInfoWidget,
  technicalAppWiget: state.DashboardReducer.technicalAppWiget,
  contractsAppWidget: state.DashboardReducer.contractsAppWidget,
  maintenanceAppWidget: state.DashboardReducer.maintenanceAppWidget,
  recordsAppWidget: state.DashboardReducer.recordsAppWidget,

  aircraftDisWidget: state.DashboardReducer.aircraftDisWidget,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
  dbFleetSummLoader: state.DashboardReducer.dbFleetSummLoader,
  dbAircraftDisLoader: state.DashboardReducer.dbAircraftDisLoader,
  oprRatingWidget: state.DashboardReducer.oprRatingWidget,
  dbAppModLoader: state.DashboardReducer.dbAppModLoader
})
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    fetchAssetInfo :() => dispatch(assetsInfoWidgetAc()),
    dbSwitcherFn: () => dispatch({
      type: DB_SWITCHER,
      payload: ''
    }),
    fetchFleetSummary: () => dispatch(fleetSummeryWidgetAc()),
    fetchAppsCardWidget: (type) => dispatch(fetchAppsCardWidgetAc(type)),
    fetchDistributedWidget: () => dispatch(aircraftDistributionWidgetAc()),
    fetchOperatorRating: () => dispatch(getOperatorRatingAc()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FleetDashboard,['console','dashboard','R']));
