import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import { Header, ContractSideBar, ContractBarLoader, EditSidebar } from '../../Elements';
import { extractFileInfoAc, dataPointToggleStatusAc, submitDataPointAc, editDataPointAc } from '../actionCreators';
import { getAccessToken } from '../../../../utils';
import { ListLoader, NoRecordFound } from '../../../../shared/';
import '../../assets/styles/mlUpload.scss';
import ExtractPointInfo from '../components/ExtractPointInfo';
import EditDataPointInfo from '../components/EditDataPointInfo';
import { UPDATE_DATA_IDS, SELECT_ALL_FILE, CLOSE_DATALOG_SIDE_BAR, TOGGLE_DATA_EXT_BAR } from '../actions';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import { FIXED_POSITIONS_VALUE } from '../../../../shared/actions';
import { getCookie } from '../../../../utils';
class DataLogView extends Component{
  constructor(props){
    super(props);
    this.state = {
      draftStatusHeight: 0
    }
  }
  componentDidMount(){
    this.props.extractFileInfoFn(this.props.params);
		let cookieVal = getCookie();
		let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
    let contractHeaderHeight = document.body.contains(document.getElementById('contract-header')) ? document.getElementById('contract-header').clientHeight : 0;
    if(cookieVal){
			this.props.fixedPositionFn('closeMaintenance',false);
      this.props.fixedPositionFn('maintenanceBarHeight',0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);
    }else{
			this.props.fixedPositionFn('closeMaintenance',true);
      this.props.fixedPositionFn('maintenanceBarHeight', maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop', maintenanceHeight + headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);
    }
  }
  changeStatus = (type, ids) => {
    this.props.dataPointToggleFn(this.props.params.id,type, ids);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  draftHeight = (height) => {
    this.setState(prevState => ({
      ...prevState,
      draftStatusHeight : height
    }));
  }
  render(){
    const { extractFileInfo, dataIds, mlLoader, checkboxStatus, dataLogFlag, dataPointCrud, subHeaderTop } = this.props;
    return(
      <div style={{marginTop: 144 + (this.state.draftStatusHeight ? this.state.draftStatusHeight+20:this.state.draftStatusHeight)}}>
        <Header
          dataId={this.props.params.id}
          draftHeight={this.draftHeight}
          type="brief"
          params={this.props.params}
          changeMsn={this.changeMsn}
          exportContract={this.props.exportContract}
        />
        <ContractSideBar
          dataId={this.props.params.id}
          type="brief"
          application="Automated Content Extractor Detail View"
          params={this.props.params}
        />
        { mlLoader ? <ListLoader/> : null}
        { extractFileInfo.file_type == 1 ?
          <div className="lg-view-block">
            { extractFileInfo.response_text && extractFileInfo.response_text.issues ?
              <span className="encountered-btn">
                We encountered some unidentified information
                <Link onClick={()=>this.props.closeIssueSidebar(!dataLogFlag)}>View</Link>
              </span>
              : null
            }
            {extractFileInfo.response_text && extractFileInfo.response_text.aircraft ?
              <div className="data-log-view-block">
                <h5 className="para">Aircraft</h5>
                { extractFileInfo.response_text.aircraft.map((item,index) =>
                  <ExtractPointInfo
                    key={index}
                    dataPoint={item}
                    updateIds={() => this.props.updateIds(item)}
                    changeStatus={this.changeStatus}
                    dataIds = {dataIds}
                    fileType = {extractFileInfo.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                  />
                )}
              </div>:null
            }
            {extractFileInfo.response_text && extractFileInfo.response_text.landing_gear ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.landing_gear).map(label =>
                  <div key={label}>
                    <h5 className="para">Landing Gears <span>({label})</span></h5>
                      { extractFileInfo.response_text.landing_gear[label].map((item,index) =>
                        <ExtractPointInfo
                          key={index}
                          dataPoint={item}
                          updateIds={() => this.props.updateIds(item)}
                          changeStatus={this.changeStatus}
                          dataIds = {dataIds}
                          fileType = {extractFileInfo.file_type}
                          toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                        />
                      )}
                  </div>
                )}
              </div>:null
            }
            {extractFileInfo.response_text && extractFileInfo.response_text.apu ?
              <div className="data-log-view-block">
                <h5 className="para">APU</h5>
                { extractFileInfo.response_text.apu.map((item,index) =>
                    <ExtractPointInfo
                    key={index}
                    dataPoint={item}
                    updateIds={() => this.props.updateIds(item)}
                    changeStatus={this.changeStatus}
                    dataIds = {dataIds}
                    fileType = {extractFileInfo.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                  />
                )}
              </div>:null
            }
            {extractFileInfo.response_text && extractFileInfo.response_text.engine && extractFileInfo.response_text.engine.general_info ?
              <div className="data-log-view-block">
                <h5 className="para">General Info</h5>
                {extractFileInfo.response_text.engine.general_info.map((item,index) =>
                  <ExtractPointInfo
                    key={index}
                    dataPoint={item}
                    updateIds={() => this.props.updateIds(item)}
                    changeStatus={this.changeStatus}
                    dataIds = {dataIds}
                    fileType = {extractFileInfo.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                  />
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.engine && extractFileInfo.response_text.engine.engine_list ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.engine.engine_list).map(label =>
                  <div key={label}>
                    <h5 className="para">Engine <span>({label})</span></h5>
                    { extractFileInfo.response_text.engine.engine_list[label].map((item,index) =>
                      <ExtractPointInfo
                        key={index}
                        dataPoint={item}
                        updateIds={() => this.props.updateIds(item)}
                        changeStatus={this.changeStatus}
                        dataIds = {dataIds}
                        fileType = {extractFileInfo.file_type}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )}
                  </div>
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.interior && extractFileInfo.response_text.interior.galley ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.interior.galley).map(label =>
                  <div key={label}>
                    <h5 className="para">Galley <span>({label})</span></h5>
                    { extractFileInfo.response_text.interior.galley[label].map((item,index) =>
                      <ExtractPointInfo
                        key={index}
                        dataPoint={item}
                        updateIds={() => this.props.updateIds(item)}
                        changeStatus={this.changeStatus}
                        dataIds = {dataIds}
                        fileType = {extractFileInfo.file_type}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )}
                  </div>
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.interior && extractFileInfo.response_text.interior.lavatory ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.interior.lavatory).map(label =>
                  <div key={label}>
                    <h5 className="para">Lavatory <span>({label})</span></h5>
                    { extractFileInfo.response_text.interior.lavatory[label].map((item,index) =>
                      <ExtractPointInfo
                        key={index}
                        dataPoint={item}
                        updateIds={() => this.props.updateIds(item)}
                        changeStatus={this.changeStatus}
                        dataIds = {dataIds}
                        fileType = {extractFileInfo.file_type}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )}
                </div>
              )}
            </div>:null
          }
          </div>
            :
            <div className="lg-view-block">
              {extractFileInfo.response_text && extractFileInfo.response_text.contract ?
                <div className="data-log-view-block">
                  <h5 className="para">Contract</h5>
                  { extractFileInfo.response_text.contract.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}

                    />
                  )}
                </div>:null
              }

              {extractFileInfo.response_text && extractFileInfo.response_text.rental_rate ?
                <div className="data-log-view-block">
                  <h5 className="para">Rental Rate</h5>
                  { extractFileInfo.response_text.rental_rate.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }

              {extractFileInfo.response_text && extractFileInfo.response_text.lessor ?
                <div className="data-log-view-block">
                  <h5 className="para">Lessor</h5>
                  { extractFileInfo.response_text.lessor.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }
              {extractFileInfo.response_text && extractFileInfo.response_text.lessee ?
                <div className="data-log-view-block">
                  <h5 className="para">Lessee</h5>
                  { extractFileInfo.response_text.lessee.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }
              {extractFileInfo.response_text && extractFileInfo.response_text.delivery_condition_item ?
                <div className="data-log-view-block">
                  <h5 className="para">Delivery Condition Item</h5>
                  { extractFileInfo.response_text.delivery_condition_item.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }
              {extractFileInfo.response_text && extractFileInfo.response_text.return_condition_item ?
                <div className="data-log-view-block">
                  <h5 className="para">Return Condition Item</h5>
                  { extractFileInfo.response_text.return_condition_item.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }

              {extractFileInfo.response_text && extractFileInfo.response_text.security_deposit ?
                <div className="data-log-view-block">
                  <h5 className="para">Security Deposit</h5>
                  { extractFileInfo.response_text.security_deposit.map((item,index) =>
                    <ExtractPointInfo
                      key={index}
                      dataPoint={item}
                      updateIds={() => this.props.updateIds(item)}
                      changeStatus={this.changeStatus}
                      dataIds = {dataIds}
                      fileType = {extractFileInfo.file_type}
                      toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                    />
                  )}
                </div>:null
              }


             { extractFileInfo.response_text && extractFileInfo.response_text.reserve_account ?
              <h3 className="reserve-acnt">Reserve Account</h3>
              : null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.reserve_account && extractFileInfo.response_text.reserve_account.airframe ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.reserve_account.airframe).map(label =>
                  <div key={label}>
                    <h5 className="para">Airframe </h5>
                    { extractFileInfo.response_text.reserve_account.airframe[label].map((item,index) =>
                      <ExtractPointInfo
                        key={index}
                        dataPoint={item}
                        updateIds={() => this.props.updateIds(item)}
                        changeStatus={this.changeStatus}
                        dataIds = {dataIds}
                        fileType = {extractFileInfo.file_type}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )}
                  </div>
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.reserve_account && extractFileInfo.response_text.reserve_account.apu ?
              <div className="data-log-view-block">
                <h5 className="para">APU</h5>
                { extractFileInfo.response_text.reserve_account.apu.map((item,index) =>
                  <ExtractPointInfo
                    key={index}
                    dataPoint={item}
                    updateIds={() => this.props.updateIds(item)}
                    changeStatus={this.changeStatus}
                    dataIds = {dataIds}
                    fileType = {extractFileInfo.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                  />
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.reserve_account && extractFileInfo.response_text.reserve_account.landing_gear ?
              <div className="data-log-view-block">
                <h5 className="para">Landing Gear</h5>
                { extractFileInfo.response_text.reserve_account.landing_gear.map((item,index) =>
                  <ExtractPointInfo
                    key={index}
                    dataPoint={item}
                    updateIds={() => this.props.updateIds(item)}
                    changeStatus={this.changeStatus}
                    dataIds = {dataIds}
                    fileType = {extractFileInfo.file_type}
                    toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                  />
                )}
              </div>:null
            }
            { extractFileInfo.response_text && extractFileInfo.response_text.reserve_account && extractFileInfo.response_text.reserve_account.engine ?
              <div className="data-log-view-block">
                { Object.keys(extractFileInfo.response_text.reserve_account.engine).map(label =>
                  <div key={label}>
                    <h5 className="para">Engine ({label}) </h5>
                    { extractFileInfo.response_text.reserve_account.engine[label].map((item,index) =>
                      <ExtractPointInfo
                        key={index}
                        dataPoint={item}
                        updateIds={() => this.props.updateIds(item)}
                        changeStatus={this.changeStatus}
                        dataIds = {dataIds}
                        fileType = {extractFileInfo.file_type}
                        toggleEditSideBar={() => this.props.dataPointSideBar({type: 'edit', data:item, flag: true})}
                      />
                    )}
                  </div>
                )}
              </div>:null
            }
          </div>
        }
        <div className="lg-view-block">
          <div className="approve-data-point flex-center" style= {{ top: ( this.state.draftStatusHeight  + subHeaderTop + this.props.recordNavTop ) + 'px', marginLeft: '30px'}}>
            <Row className="back-lg-block" style={{padding: '4px 0'}}>
              <Col md="4" className="flex-centered">
                <Link to={"/"+this.props.params.type + "/" + this.props.params.aircraft_slug + "/contract/" + this.props.params.contract_slug+"/extract-file-list"}  className="back-nav"><img src={imgStoragePath + "back_arrow.png"} alt="img"/></Link>
                  {
                  extractFileInfo.pending_items &&  extractFileInfo.pending_items.length ?
                    <h5 className="select-all-block"><label><input type="checkbox" checked={(dataIds.length ? dataIds.length == extractFileInfo.pending_items.length ? true : false : false)} onChange={() => this.props.selectAllFn(!checkboxStatus)}/> Select All Datapoints</label>
                      <br />
                      {
                        dataIds.length ?
                        <span style={{ marginLeft: '17px'}} className="count">{dataIds.length} Data Point(s) Selected</span>
                      : null }
                    </h5>
                    : null
                  }
              </Col>
              <Col md="8" className="right-blk ">
                <ul className="list-inine flex-centered">
                  { dataIds.length ?
                    <li className="list-inline-item">
                      <Link onClick={() => this.changeStatus('3', dataIds)} className="ignore-log caption-ui"> Ignore</Link>
                    </li>:null
                  }
                  { dataIds.length ?
                    <li className="list-inline-item">
                      <Link onClick={() => this.changeStatus('2', dataIds)} className="reject-log caption-ui">  Reject</Link>
                    </li>:null
                  }
                  { dataIds.length ?
                    <li className="list-inline-item">
                      <Link onClick={() => this.changeStatus('1', dataIds)} className="caption-ui approve-btn">  Approve</Link>
                    </li>:null
                  }
                  <li className="list-inline-item">
                    <a className="downlaod caption-ui" href={extractFileInfo.file_name}  target="blank"><img width="15" src={imgStoragePath+"pdf.png"} alt="img"/>Download File</a>
                  </li>
                  <li className="list-inline-item">
                      <button className="primary-btn" disabled={!extractFileInfo.approved_data_points} onClick={() => this.props.submitDataPointFn(this.props.params)}>Submit</button>
                      {
                        extractFileInfo.approved_data_points > 0 ?
                        extractFileInfo.file_type == 1 ?
                        <span className="count">{extractFileInfo.approved_data_points + ' Data Point(s) are cleared for Submission'}</span>:
                        !extractFileInfo.technical_applied ? <span style={{color: '#ff0000'}} className="count">Technical Acceptance not yet Submitted</span>:
                        <span className="count">{extractFileInfo.approved_data_points + ' Data Point(s) are cleared for Submission'}</span>
                        :null
                      }
                    </li>
                </ul>
              </Col>
            </Row>
          </div>



     <EditSidebar
        title="Unidentified Info"
        editSideBar={dataLogFlag}
        toggleEditSideBar={() => this.props.closeIssueSidebar(!dataLogFlag)}
        >
        {
        extractFileInfo.response_text && extractFileInfo.response_text.issues && Object.keys(extractFileInfo.response_text.issues).length && dataLogFlag ?
        <div>
            <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
            {extractFileInfo.response_text && extractFileInfo.response_text.issues  && Object.keys(extractFileInfo.response_text.issues).length?
              <div className="data-log-sidebar">
                <div className="data-log-view-block">
                  { Object.keys(extractFileInfo.response_text.issues).map(label =>
                    <div key={label}>
                      <h5 className="para" style={{ textTransform: 'capitalize'}}>{label}</h5>
                        { extractFileInfo.response_text.issues[label].map((item,index) =>
                          <ExtractPointInfo
                            key={index}
                            dataPoint={item}
                            updateIds={() => this.props.updateIds(item)}
                            changeStatus={this.changeStatus}
                            dataIds = {dataIds}
                            issueContent = {true}
                            fileType = {extractFileInfo.file_type}
                          />
                        )}
                    </div>
                  )}
                </div>
              </div>:null
            }
        </div>
        </div>
        : <NoRecordFound/>
        }
        </EditSidebar>
        </div>

        <EditSidebar
          title="Edit Data Point"
          editSideBar={dataPointCrud.flag}
          toggleEditSideBar={() => this.props.dataPointSideBar({flag: false, data:{}, type: 'edit'})}
          >
          { dataPointCrud.flag ?
            <EditDataPointInfo
              editDataPoint={(data) => this.props.editDataPoint(this.props.params.id, data)}
              dataPoint={dataPointCrud.data}
              toggleEditSideBar={() => this.props.dataPointSideBar({flag: false, data:{}, type: 'edit'})}
            />:null
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  extractFileInfo: state.MLFileReducer.extractFileInfo,
  dataIds : state.MLFileReducer.dataIds,
  mlLoader: state.MLFileReducer.mlLoader,
  checkboxStatus: state.MLFileReducer.checkboxStatus,
  dataLogFlag: state.MLFileReducer.dataLogFlag,
  dataPointCrud: state.MLFileReducer.dataPointCrud,
  closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
  subHeaderTop: state.sharedReducers.subHeaderTop,
  recordNavTop: state.sharedReducers.recordNavTop
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    extractFileInfoFn: (params) => dispatch(extractFileInfoAc(params)),
    dataPointToggleFn: (id, type, ids) => dispatch(dataPointToggleStatusAc(id, type, ids)),
    submitDataPointFn: (params) => dispatch(submitDataPointAc(params)),
    editDataPoint: (id,data) => dispatch(editDataPointAc(id, data)),
    updateIds: (item) => dispatch({
      type: UPDATE_DATA_IDS,
      payload: item
    }),
    selectAllFn: (status) => dispatch({
      type: SELECT_ALL_FILE,
      payload: status
    }),
    dataPointSideBar: (data) => dispatch({
      type: TOGGLE_DATA_EXT_BAR,
      payload: data
    }),
    closeIssueSidebar: (flag) => dispatch({
      type: CLOSE_DATALOG_SIDE_BAR,
      payload: flag
    }),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DataLogView, ['contracts', 'data_extract', 'R']));
