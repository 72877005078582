
import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';
import { invoiceStatusList } from '../../../constants';
import { showCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import { browserHistory } from 'react-router';
export default function CashflowList({ item, redirectToDetails, props }) {
    let location = browserHistory.getCurrentLocation();
    location = {
        ...location,
        pathname: `/${props.params.type}/${props.params.aircraft_slug}/assembly-invoices/${props.params.slug}`,
        query: {
            ...location.query,
            'details': item?.assembly_invoice_id,
        }
    }

    return (
        <TableRow hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.for_month ? moment(item?.for_month).format('MMM YYYY') : '--'}
                <p className="cashflow-monthly-total">
                    <span className="cashflow-monthly-total-label">Month Total Cashflow</span>
                    {item.monthly_total.length ?
                        item.monthly_total.map((cashflow, index) =>
                            <NumericLabel params={showCurrencyFormat(cashflow.currency)}>{cashflow.total_amount}</NumericLabel>)
                        :
                        <NumericLabel params={showCurrencyFormat(item.currency)}>0.00</NumericLabel>
                    }
                </p>
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.name ? item?.name : '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item.credit_or_debit ?
                    <NumericLabel params={showCurrencyFormat(item.currency)}>
                        {item.amount}
                    </NumericLabel>
                    :
                    'US$0.00'
                }
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item.credit_or_debit ? 'US$0.00' :
                    <NumericLabel params={showCurrencyFormat(item.currency)}>
                        {item.amount}
                    </NumericLabel>
                }
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item.balance ?
                    <NumericLabel params={showCurrencyFormat(item.currency)}>
                        {item.balance}
                    </NumericLabel>
                    :
                    'US$0.00'
                }
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {
                    item?.transaction_details ? <>
                        <span > {item?.transaction_details ? item?.transaction_details.split('[')[0] : '--'}</span>
                        <span onClick={() => browserHistory.push(location)} style={{ color: '#007bff' }}> {item?.transaction_details.split('[')[1].split(']')[0]} </span>
                        <span> {item?.transaction_details.split('[')[1].split(']')[1]} </span> </>
                        :
                        '--'
                }
            </TableCell>
        </TableRow>
    )
}
