import React, { Component } from 'react';
import { Link } from 'react-router';
import { imgStoragePath } from '../../../constants';
import revisionHistoryIcon from '../../../shared/assets/img/revision_history_icon.svg';
export default class ContractHeadertext extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { title, desc, addNew, addAudit ,contractInfo} = this.props;
    return (
      <div className="contract-header-info rental-block">
        <h3 className="gama">
          {title}
          {!contractInfo?.is_reserve_rate ? addNew ?
            <Link  onClick = {() => this.props.toggleAddEvent()}  className="primary-btn add-new caption-ui">{addNew}</Link> : null
          : null}
          {addAudit ?
            <Link  className=" caption-ui auditlog-btn" onClick = {() => this.props.toggleAuditlog()} >
            <img className="audit-img" src={revisionHistoryIcon} alt="img"/>
            {addAudit}
            </Link> : null
          }
        </h3>
        <p>{desc}</p>
      </div>
    )
  }
}
