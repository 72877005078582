import { globalGetService, globalPostService } from "../../globalServices";
import { checkApiStatus } from "../../utils_v2";

export function getSharedInvoiceInfoApi(props = {}, query = {}) {
    globalGetService(`contracts/assembly-lease/${props.params.slug}/assembly-invoice/${query?.id}/craft-mail/`)
        .then(response => {
            if (checkApiStatus(response)) {
                let payload = Object.assign({}, response.data.data);
                payload = {
                    ...payload,
                    to_address: response.data.data.to_address && response.data.data.to_address.length ? response.data.data.to_address.map(email => { return { label: email, inputValue: email } }) : []
                }
                this.setState({ sharedInfo: payload, modal: true });
            }
        })
}

export function sendInvoiceMailApi(props, data) {
    let payload = Object.assign({}, data);
    payload = {
        ...payload,
        to_address: data.to_address.map(item => item.inputValue)
    }
    this.setState({ pageLoader: true });
    this.setState({
        modal: false,
        sharedInfo: { to_address: [], subject: '', content: '' },
        error: {}
    });
    globalPostService(`/contracts/assembly-lease/${props.params.slug}/assembly-invoice/${props.invoiceCreatedDetails?.id}/share-invoice-mail/`, payload)
        .then(response => {
            this.setState({ pageLoader: false });
            if (checkApiStatus(response)) {
                window.location.reload()
                this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            } else {
                this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        })
}
