import React from 'react';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
export default function PenaltyInvoiceList({invoiceInfo, mode, onFieldChange, updateErrorField, error}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{invoiceInfo.parent_amount ? <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoiceInfo.parent_amount}</NumericLabel>:''}</TableCell>
      <TableCell>
        { mode === 'view' ? invoiceInfo.description:
          <TextField
            id="description"
            fullWidth
            margin="normal"
            multiline
            rows="4"
            value={invoiceInfo.description ? invoiceInfo.description:''}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 255 }}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoiceInfo.penalty_due_date ? moment(invoiceInfo.penalty_due_date).format(displayDateFormatShort):'':
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               required
               margin="normal"
               id="penalty_due_date"
               format={fieldDateFormat}
               fullWidth
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               clearable={false}
               value={invoiceInfo.penalty_due_date ? invoiceInfo.penalty_due_date:null}
               onChange={(data, value) => onFieldChange(value, 'penalty_due_date', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoiceInfo.parent_paid_date ? moment(invoiceInfo.parent_paid_date).format(displayDateFormatShort):'':
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               required
               margin="normal"
               id="parent_paid_date"
               format={fieldDateFormat}
               fullWidth
               InputLabelProps={{shrink: true}}
               inputProps={{readOnly: true}}
               value={invoiceInfo.parent_paid_date ? invoiceInfo.parent_paid_date:null}
               onChange={(data, value) => onFieldChange(value, 'parent_paid_date', moment(data).format(backendDateFormat))}
             />
          </MuiPickersUtilsProvider>
        }
      </TableCell>
      <TableCell>
        {invoiceInfo.over_due_days ? invoiceInfo.over_due_days:moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days'):0}
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoiceInfo.libor_value ? invoiceInfo.libor_value:'0%':
          <TextField
            id="libor_value"
            fullWidth
            margin="normal"
            value={invoiceInfo.libor_value ? invoiceInfo.libor_value:''}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 6 }}
            error={error.libor_value?true:false}
            helperText={error.libor_value ? error.libor_value:''}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'libor_value', e.target.value): e.preventDefault()}}
            onFocus={() => updateErrorField('libor_value','')}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoiceInfo.additional_rate ? invoiceInfo.additional_rate:'0%':
          <TextField
            id="additional_rate"
            fullWidth
            margin="normal"
            value={invoiceInfo.additional_rate ? invoiceInfo.additional_rate:''}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 6 }}
            error={error.additional_rate?true:false}
            helperText={error.additional_rate ? error.additional_rate:''}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'additional_rate', e.target.value): e.preventDefault()}}
            onFocus={() => updateErrorField('additional_rate','')}
          />
        }
      </TableCell>
      <TableCell>
        {invoiceInfo.penalty_interest_rate ? invoiceInfo.penalty_interest_rate:'0'}%
      </TableCell>
      <TableCell>
        {invoiceInfo.total_amount ? <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoiceInfo.total_amount}</NumericLabel>: '0'}
      </TableCell>
    </TableRow>
  )
}
