import React, { Component } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Header, ContractHeadertext, ContractSideBar } from '../../../applications/contracts/Elements';
import { TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { ContractList, ContractCRU } from '../components';
import { ContractWrapper } from '../../Elements';
import { assetsType } from '../../../constants';
import { getLesseeListAc, getOperatorListAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { getContractListApi, addContractApi, getContractApi,changeStatusApi } from '../apiServices';
import { contactHd,jscLeaseListHd } from '../';
import {getLocalStorageInfo, checkPermission } from '../../../utils_v2';
class ContractListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      formSubmitLoader: false,
      list: [],
      records_uuid:'',
      archived_asset:false,
      disabled_contracts:null
    }
    this.getContractListApi = getContractListApi.bind(this);
    this.changeStatusApi=changeStatusApi.bind(this);
  }
  componentDidMount(){
    this.getContractListApi(this.props, {}, 'skeletonLoader');
    this.props.getLesseeList();
    this.props.getOperatorList();
    this.props.getPlatformConstants();
  }
  getResponseBack = (contract) => {
    this.getContractListApi(this.props);
    this.props.getLesseeList();
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    this.setState({skeletonLoader: true})
    browserHistory.push(url);
    window.location.reload(false)
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, records_uuid, formSubmitLoader, archived_asset,disabled_contracts } = this.state;
    const { lessee, operator, pltConstants, pageloader } = this.props;
    return(
      <div className="contract-container">
        {
          pageloader? <PageLoader />: null
        }
        <Header headingText="Lease Management" type="short" changeMsn={this.changeMsn} params= {this.props.params} />
        <ContractSideBar application={`Lease History`} type="shorter" params={this.props.params} />
        <div className="contract-content-blk">
          <ContractWrapper
            linkToRecords = "link"
            records_uuid={records_uuid}
            buttonText={ checkPermission('contracts','lease_details','C') && archived_asset === false ? <ContractCRU list={list} archived_asset={archived_asset} lessee={lessee} operator={operator} pltConstants={pltConstants} getResponseBack = {this.getResponseBack} getLesseeList={this.props.getLesseeList} />:null}
            hideArrowBack="hide"
            skeletonLoader={skeletonLoader}
            handleClickEventFn={this.toggleModal}
            disabled_contracts={disabled_contracts}
          >
            { skeletonLoader ? <STableLoader count={4} />:
              <TableContainer component={Paper}>
                <Table aria-label="leases table" className='mui-styled-table'>
                  <TableHead>
                  {
                    getLocalStorageInfo().defaultLessor.id === 442 ?
                    <>
                    <TableRow className="jac-parent-header">
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{textAlign:'right'}}>Due Date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Lease Type</TableCell>
                      <TableCell>Lessee</TableCell>
                      <TableCell>Lease Duration / Term</TableCell>
                      <TableCell>Rent</TableCell>
                      <TableCell>Utilization</TableCell>
                      <TableCell>Lease Status</TableCell>
                    </TableRow> </> :
                    <TableRow>
                      <TableCell>Lease Type</TableCell>
                      <TableCell>Lessee</TableCell>
                      <TableCell>Lease Duration</TableCell>
                      <TableCell> Lease Status</TableCell>
                    </TableRow>
                  }
                  </TableHead>
                  <TableBody>
                    {list.map((contract,index) => (
                      <ContractList
                        key={index}
                        contract={contract}
                        viewContract={() => browserHistory.push(getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ?`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${contract.slug}/utilization/`:`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/list/${contract.slug}`)}
                        changeStatusApi = {( contract, value) => this.changeStatusApi(contract, value, this.props)}
                        formSubmitLoader={formSubmitLoader}
                      />
                    ))}
                    {
                        getLocalStorageInfo().defaultLessor.id === 442 ?
                        <TableRow>
                        <TableCell colspan='6'>
                        { list.length ? null:
                          <EmptyCollection
                            title="No records found"
                          />
                        }
                        </TableCell>
                        </TableRow> :
                        <TableRow>
                        <TableCell colspan='4'>
                        { list.length ? null:
                          <EmptyCollection
                            title="No records found"
                          />
                        }
                        </TableCell>
                        </TableRow>

                    }

                  </TableBody>
                </Table>
              </TableContainer>
            }
          </ContractWrapper>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  lessee: state.shareReducer.lessee,
  pageloader: state.shareReducer.pageloader,
  operator: state.shareReducer.operator,
  pltConstants: state.shareReducer.pltConstants
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLesseeList: () => dispatch(getLesseeListAc()),
    getOperatorList: () => dispatch(getOperatorListAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['lease_type']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractListing))
