import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FieldCol, FieldGroup, FieldLabel,  ShortHead, ContractSideBar, ContractBarLoader } from '../../Elements';
import EventInfoCard from '../components/EventInfoCard';
import EventUtilsInfo from '../components/EventUtilsInfo';
import LifeAdjustInfo from '../components/LifeAdjustInfo';
import { halfLifeListAc, halfLifeApiAc, halfLifeExportAc } from '../actionCreators';
import { EVENT_COND_CRUD, EVENT_VIEW } from '../actions';
import '../../assets/styles/halfLifeAdjustment.scss';
import { NoRecordFound, ListLoader } from '../../../../shared';
import { backendDateFormat, fieldDateFormat, imgStoragePath, assetsType } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
class HalfLifeAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projection_date: '',
      dropdownOpen: false
    }
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.props.fetchHalfLife();
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  updateField = (type, value) => {
    this.setState({
      [type]: value
    })
    this.props.fetchHalfLifeFilter(this.props.ctrShortInfo.contract.slug, {projection_date:value});
  }

  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
  }
  handleBlur = () => {
    this.setState({
      fieldFocus: false,
    });
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    const { halfLifeList, ctrShortInfo, halfLifeLoader, minStartDate } = this.props;
    return (
      <div className="contract-container padding-bottom-15" style={{ minHeight: '100vh' }}>
        {halfLifeLoader ? <ListLoader /> : null}
        {Object.keys(ctrShortInfo).length ?
          <ShortHead
            contrAssetBasicInfo={ctrShortInfo}
            changeMsn={this.changeMsn}
            headingText="Lease Management"
          /> :
          <ContractBarLoader />
        }
        <div className="hl-filter-block" style={{marginLeft: '60px'}}>
          <Row >
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  View as of Date
                </FieldLabel>
                <DatePicker
                  dateFormat = {fieldDateFormat}
                  selected={ (this.state.as_of_date != '') &&  moment(this.state.as_of_date).isValid() ? moment(this.state.as_of_date) : halfLifeList.as_of_date && halfLifeList.as_of_date.length ? moment(halfLifeList.half_life_adjustments[0].as_of_date):null}
                  onChange={(e) => this.updateField('as_of_date', moment(e).format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onFocus={() => this.handleFocus()}
                  onBlur={() => this.handleBlur()}
                  minDate= {halfLifeList.as_of_date ? moment(halfLifeList.as_of_date) : moment()}
               />
              </FieldGroup>
            </FieldCol>
            <FieldCol md="8" className="text-right">
              <p>
                <ul className="list-inline tab-right flex-centered" style={{display: 'inline-block', borderRight: '1px solid #f1f1f1', marginRight: '15px', paddingRight: '10px'}}>
                  <li className="list-inline-item download-block">
                    {
                      halfLifeList.half_life_adjustments && halfLifeList.half_life_adjustments.length ?
                      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret size="sm">
                         <span style={{fontSize: '16px'}}><img width="12" src={exportIcon}  alt="export"/>Export</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => this.props.exportHalfLife(ctrShortInfo, 'pdf', this.state.projection_date)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                          <DropdownItem onClick={() => this.props.exportHalfLife(ctrShortInfo, 'xls', this.state.projection_date)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                      :null
                    }
                  </li>
                </ul>
                Total Half Life Maintenance Adjustment
              </p>
              <span>
                {halfLifeList.total_half_life_adjustment ?
                  <NumericLabel params={showCurrencyFormat()}>
                    {halfLifeList.total_half_life_adjustment}
                  </NumericLabel> : '--'
                }
              </span>
            </FieldCol>
          </Row>
        </div>
        <ContractSideBar  application="Half Life Adjustment" type="shorter" params={this.props.params} />
        {
          halfLifeLoader ? null  :
           (halfLifeList.half_life_adjustments  && halfLifeList.half_life_adjustments.length) ? null :  <NoRecordFound style={{marginLeft: '60px'}}/>
        }
        {
          halfLifeList.half_life_adjustments ?
            halfLifeList.half_life_adjustments.map((item, index) =>
              <Row className="half-life-maintenance margin-bottom-15" key={index}>
                <Col md="9" className="padding-0">
                  <EventInfoCard
                    halfLifeItm={item}
                    viewEvent={() => this.props.viewEvent('view', { type: '', data: { country: 'india' }, flag: true })}
                  />
                  <EventUtilsInfo halfLifeItm={item} />
                </Col>
                <Col md="3" className="life-adj-block flex-centered">
                  <LifeAdjustInfo halfLifeItm={item} />
                </Col>
              </Row>
            ) : null}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  halfLifeList: state.HalfLifeReducer.halfLifeList,
  eventDetail: state.HalfLifeReducer.eventDetail,
  halfLifeLoader: state.HalfLifeReducer.halfLifeLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchHalfLife: () => dispatch(halfLifeListAc(ownProps.params)),
    fetchHalfLifeFilter: (params, queryParms) => dispatch(halfLifeApiAc(params, queryParms)),
    toggleEditBar: (data) => dispatch({
      type: EVENT_COND_CRUD,
      payload: data
    }),
    viewEvent: (type, data) => dispatch({
      type: EVENT_VIEW,
      payload: data
    }),
    exportHalfLife : (contractInfo, fileType, queryParam) => dispatch(halfLifeExportAc(contractInfo, fileType, queryParam))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(HalfLifeAdjustment,['contracts', 'half_life_adjustment', 'R'])))
