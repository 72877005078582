import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { globalPostService } from '../../globalServices'
import PopoverNote from './PopoverNote';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';
import moment from "moment";
import {leaseStatus} from '../../shared/components';
import { checkString, checkValidDate, checkIntNumber, checkNumber } from '../../formValidator';
import { getLocalStorageInfo,toastFlashMessage } from '../../utils'
import Select , { Creatable } from 'react-select';
import { uploadLesseeLogoAc } from '../../shared/actionCreators';
import { imgStoragePath } from '../../constants';
import { ToolTipHover, InfoIcon } from '../../shared';
const moduleTypes = [
  {  name:'Fan'},
  {  name:'HPC'},
  {  name:'HPT'},
  {  name:'LPT'},
]
class AddEditModule extends Component {
  constructor(props){
    super(props);
    this.state = {
      engine: {ownership_type: 1},
      error:{},
      disabled: false,
      assetTransferModal: false,
      owner: {},
      newLessee:false
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }


  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.engineObj));

    delete data['lessor_name']
    delete data['owner']
    delete data['lessor']
    this.setState({
      engine: {
        ...data,
        engine_type_id:data.engine_type ? data.engine_type.id :'',
        credit_classification:data.credit_classification ? data.credit_classification.value : '',
        position:data.position ? data.position.value : '',
        operator_id: data.operator ? data.operator.id : '',
        operator:data.operator ? data.operator.id:'',
        region: data.region ? data.region.id : '',
        ownership_type: data.ownership_type ? data.ownership_type.value : 1 ,
        owner_portfolio: data.owner_portfolio ? data.owner_portfolio : '',
        status:data.status ? data.status.value:'',
        lessee_id: data.lessee ? data.lessee.id : '',
        owner_id: this.props.engineObj.owner && this.props.engineObj.owner.id ? this.props.engineObj.owner.id : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.id : '',
        owner_level: this.props.engineObj.owner && this.props.engineObj.owner.lessor_level ? this.props.engineObj.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
        lessor_name_id: this.props.engineObj.lessor_name && this.props.engineObj.lessor_name.id ? this.props.engineObj.lessor_name.id  :getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '',
        lessor_level:this.props.engineObj.lessor_name && this.props.engineObj.lessor_name.lessor_level ? this.props.engineObj.lessor_name.lessor_level:1,
        off_lease_status: this.props.engineObj.off_lease_status && this.props.engineObj.off_lease_status.value ? this.props.engineObj.off_lease_status.value : null
       }
    })
    if(getLocalStorageInfo().defaultLessor.lessor_type != 3){
      //engine: {...this.state.engine, lessor_name: getLocalStorageInfo().defaultLessor.name, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain}
      this.setState({
        disabled: true,
      })
    }
  }

  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        location : address
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
        })))
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
						engine: {
              ...prevState.engine,
                country: place.length ? place[0]['long_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));

        location.then(results =>
          results[0]
          ).then(country => {
            let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
            this.setState(prevState => ({
              ...prevState,
              engine: {
                ...prevState.engine,
                  country_code: place.length ? place[0]['short_name']:address
                }
              }))
          }

          )
          .catch(error => console.error('Error', error));
    };

  updateForm = (type, value) => {
    if(type == "status" && value != 2){
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          off_lease_status:null
        }
      }))
    }
    if(type == 'lessor_name_id'){
      let data = this.props.ownerList.find(item =>  item.id == value)
      let error = this.state.error
      delete error.lessor_name_id
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          lessor_name_id: data && data.id ? data.id : '',
          lessor_level: data && data.lessor_level ? data.lessor_level : ''
        },
        error: error
      }))
    }
    if(type == 'owner_id'){
      let data= this.props.ownerList.find(item => item.id == value )
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          owner_id:data && data.id ? data.id : '',
          owner_level: data && data.id && data.id === getLocalStorageInfo().defaultLessor.id ? 1 : data && data.lessor_level != null ? data.lessor_level : ''
        }
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        [type]: value
      },
      error:{
        ...prevState.error,
        [type]:''
      }

    }));
    // setTimeout(() => {
    //     if(this.state.engine.esn){
    //       globalPostService('/console/check-aircraft/engine/',{esn: this.state.engine.esn,engine_type: this.state.engine.engine_type_id ? this.state.engine.engine_type_id : null})
    //       .then(response => {
    //           if(response.data.data && response.data.data.exist_lessor_slug && getLocalStorageInfo().defaultLessor.slug != response.data.data.exist_lessor_slug){
    //             this.setState({
    //               disabled: true,
    //               engine: {...this.state.engine,lessor_type: response.data.data.lessor_type.value, exist_lessor_slug: response.data.data.exist_lessor_slug, lessor_name: response.data.data.lessor_name, lessor_domain: response.data.data.lessor_domain},
    //               owner: response.data.data
    //             })
    //           }else if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
    //             this.setState({
    //               disabled: this.state.disabled,
    //               engine: {...this.state.engine, lessor_type: null, lessor_name: getLocalStorageInfo().defaultLessor.name, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain, exist_lessor_slug: getLocalStorageInfo().defaultLessor.slug},
    //               owner: {}
    //             })
    //           }else{
    //             this.setState({
    //               disabled: false,
    //               engine: {...this.state.engine, lessor_type: null},
    //               owner: {}
    //             })
    //           }
    //       })
    //     }
    // }, 300)
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      engine:{
        ...prevState.engine,
        lessee:{
          ...prevState.engine.lessee,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee:{
              ...prevState.engine.lessee,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  createEngine = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.engine);
    delete data.lessee
    if(!data.location){
      data = {
        ...data,
        latitude: '',
        longitude: '',
        country: ''
      }
    }


		let validateNewInput = {
      engine_type_id: checkString({value: this.state.engine.engine_type_id, required: true, minLength: '', maxLength: '', message: 'Please select Engine Type'}),
      engine_module_type_id:checkString({value: this.state.engine.engine_module_type_id, required: this.props.type == "edit" ?'':true, minLength:'', maxLength:'', message:'Please select Module Type'}),
      status: checkString({value: this.state.engine.status, required: true, minLength: '', maxLength: '', message: 'Please select Engine Lease status'}),
      manufacturing_date: checkValidDate({value: this.state.engine.manufacturing_date, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
			date_of_installation: checkValidDate({value: this.state.engine.date_of_installation, required: '', minLength: '', maxLength: '', message: 'Please enter Date of Installation'}),
			esn: checkString({value: this.state.engine.esn, required: true, minLength: '', maxLength: '', message: 'Please enter Engine Serial Number'}),
      position: checkString({value: this.state.engine.position, required: this.state.engine.status!=1 ? false: true, minLength: '', maxLength: '', message: 'Please select Engine Position'}),
      operating_thrust: checkNumber({value: this.state.engine.operating_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Purchase Thrust in numbers'}),
      purchase_thrust: checkNumber({value: this.state.engine.purchase_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Operating Thrust in numbers'}),
      tsn: checkNumber({value: this.state.engine.tsn, required: '', minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.engine.csn, required: '', minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      average_monthly_hours: checkNumber({value: this.state.engine.average_monthly_hours, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly Hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.engine.average_monthly_cycles, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly Cycles in numbers'}),
      region: checkString({value: this.state.engine.region, required: this.state.engine.status!= 1 ? false: true, minLength: '', maxLength: '', message: 'Please select Region of Operation'}),
      lessor_name_id:checkString({value: this.state.engine.lessor_name_id, required:this.props.location.pathname.includes('technical-specification') ? true : false, minLength: '', maxLength: '', message: 'Please select Lessor'}),
    };

    if(this.props.type && this.props.type === 'edit'){
      data = {
        ...data,
        lessee: data.lessee_id
      }
    }

    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.exist_lessor_slug == undefined || this.state.engine.exist_lessor_slug == null || this.state.engine.exist_lessor_slug == '')){
      validateNewInput = {...validateNewInput, exist_lessor_slug: 'Lessor is required'}
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.lessor_domain == undefined || this.state.engine.lessor_domain == null || this.state.engine.lessor_domain == '') && !this.state.disabled && this.state.engine.exist_lessor_slug && this.state.engine.exist_lessor_slug == 'newlessor'){
      validateNewInput = {...validateNewInput, lessor_domain: 'Lessor email is required'}
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.lessor_name == undefined || this.state.engine.lessor_name == null || this.state.engine.lessor_name == '') && !this.state.disabled && this.state.engine.exist_lessor_slug && this.state.engine.exist_lessor_slug == 'newlessor'){
      validateNewInput = {...validateNewInput, lessor_domain: 'Lessor name is required'}
    }
    if(this.state.engine.ownership_type == 2) {
      validateNewInput = {
        ...validateNewInput,
        portfolio:checkString({value: this.state.engine.owner_portfolio, required: true, minLength: '', maxLength: '', message: 'Portfolio is required'})
      }
    }
    // if(this.state.engine.ownership_type == 2 && ( this.state.engine.portfolio == undefined || this.state.engine.portfolio == null || this.state.engine.portfolio.id == '' || this.state.engine.portfolio.id == undefined || this.state.engine.portfolio.id == null)){
    //   validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    // }

    if(this.state.engine.date_of_installation && this.state.engine.date_of_installation.trim() != '' && parseInt(moment(this.state.engine.date_of_installation).diff(moment(this.state.engine.manufacturing_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        date_of_installation: 'Date of installation can not be before date of manufacture'
      }
    }

    if(data.same_operator_lessee){
      data = {
        ...data,
        operator_id: data.lessee_id
      };
    }
    data = {
      ...data,
      asset_slug: this.props.slug,
    }
    if(this.state.engine.off_lease_status == "" || this.state.engine.off_lease_status == {}){
      data = {
        ...data,
        off_lease_status: null
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.state.engine.own_asset != undefined && !this.state.engine.own_asset && this.state.disabled){
        this.setState({
          assetTransferModal: true
        })
        return
      }
      this.props.toggleEditSideBar('addModule');
      this.props.redirectToAll()
      const userInfo = getLocalStorageInfo()
      if(data.exist_lessor_slug == 'newlessor'){
        delete data.exist_lessor_slug
      }
      if(userInfo.defaultLessor.lessor_type != 1){
        this.props.createEngine(data.own_asset ? {...data,from_scratch: true} : data.exist_lessor_slug ? {...data,request_for_access: true} : data);
      }else{
        this.props.createEngine(data);
      }
    }else{

      this.setState({
        error: validateNewInput
      });
    }
  }

  addNewAndSave = () =>{
    this.props.toggleEditSideBar();
    let data = this.state.engine
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    this.props.createEngine({...data,from_scratch: true});
  }

  requesteAccess = () => {
    this.props.toggleEditSideBar();
    let data = this.state.engine
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
      this.props.createEngine({...data, notify: true});
    }else{
      this.props.createEngine({...data, request_for_access: true});
    }

  }
  render(){
    const { lessorList, clients, techConstants, type, sRegionList, operators, engineTypes, standAlone, ownerList } = this.props;
		const { engine, error, newLessee } =  this.state;
    const {jacLeaseStatus,defaultLeaseStatus,airAsiaLeaseStatus} = leaseStatus
    const userInfo = getLocalStorageInfo()
    var showTabs = true
    // Object.keys(userInfo.user.permission).map(key => {
    //   showTabs = userInfo.user.permission[key].is_saas ? false : showTabs
    //   return key
    // })
    return(
      <form onSubmit={(e) => this.createEngine(e)}>
         <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
				 		<Row>
              {this.props.location.pathname.includes('technical-specification') ?
                <FieldCol md="4">
                 <FieldGroup className="form-group">
   							<FieldLabel className="label">
                     Engine Type
                     {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                   </FieldLabel>
                   <FieldSelect
                     placeholder='Select Engine Type'
                     keyParam="engine_type_id"
                     value={engine.engine_type_id}
                     type={type}
                     options={engineTypes}
                     updateField={this.updateForm}
                     labelKey='name'
                     valueKey='id'
                     name={engine.engine_name}
                   />
                 <h6 className="error-msg">{error.engine_type_id}</h6>
                 </FieldGroup>
               </FieldCol> : null
             }
						 <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" } >
              <FieldGroup className="form-group">
                <FieldLabel className="label">ESN<sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={engine.esn}
                  type={type}
                  keyParam="esn"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.esn}</h6>
              </FieldGroup>
            </FieldCol>
            {this.props.location.pathname.includes('technical-specification') ? null :
              <FieldCol md="12">
               <FieldGroup className="form-group">
 							<FieldLabel className="label">
                   Engine Type
                   {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                 </FieldLabel>
                 <FieldSelect
                   placeholder='Select Engine Type'
                   keyParam="engine_type_id"
                   value={engine.engine_type_id}
                   type={type}
                   options={engineTypes}
                   updateField={this.updateForm}
                   labelKey='name'
                   valueKey='id'
                   name={engine.engine_name}
                 />
               <h6 className="error-msg">{error.engine_type_id}</h6>
               </FieldGroup>
             </FieldCol>
            }

            {type != 'edit' ?
              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
               <FieldGroup className="form-group">
 							<FieldLabel className="label">
                   Module
                   {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                 </FieldLabel>
                 <FieldSelect
                   placeholder='Select Module Type'
                   keyParam="engine_module_type_id"
                   value={engine.engine_module_type_id}
                   type={type}
                   options={moduleTypes}
                   updateField={this.updateForm}
                   labelKey='name'
                   valueKey='name'
                   name={engine.engine_name}
                 />
               <h6 className="error-msg">{error.engine_module_type_id}</h6>
               </FieldGroup>
             </FieldCol> : null
            }
						 <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
              <FieldGroup className="form-group">
                <FieldLabel className="label">Date of Manufacture<sup className="reqStar"> * </sup>
                <PopoverNote
                     placement= 'top'
                     tagetId={'Popover-asset-aircraft'}
                    />
                </FieldLabel>
								<FieldDate
                  value={engine.manufacturing_date}
                  type={type}
                  keyParam="manufacturing_date"
									updateField={this.updateForm}
                  maxDate={moment().subtract(1, 'day')}
                  readOnly={true}

                />
              <h6 className="error-msg">{error.manufacturing_date}</h6>
              </FieldGroup>
            </FieldCol>
            {
              this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md={4}>
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Date of Purchase
                 </FieldLabel>
 								  <FieldDate
                    value={engine.purchase_date}
                    type={type}
                    keyParam="purchase_date"
	                  updateField={this.updateForm}
                    maxDate={moment()}
                    minDate={moment(engine.manufacturing_date)}
                    readOnly={true}
                  />
               <h6 className="error-msg">{error.purchase_date}</h6>
               </FieldGroup>
             </FieldCol> : null
            }

              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
               <FieldGroup className="form-group">
                 <FieldLabel className="label">Position
                 { engine.status!=1? null:  <sup className="reqStar"> * </sup>}
                 </FieldLabel>
                <FieldSelect
                   placeholder='Select position'
                   keyParam="position"
                   value={engine.position}
                  type={type}
                  options = {[]}
                   options={techConstants.filter(item => item.type=="engine_position")}
                   updateField={this.updateForm}
                   labelKey='label'
                   valueKey='value'
                   name={engine.position_name}
                 />
               <h6 className="error-msg">{error.position}</h6>
               </FieldGroup>
             </FieldCol>
            {
              this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="8">
                <FieldGroup className="form-group">
                    <FieldLabel className="label">
                       Lessee
                       { type != 'view' && engine.status== 1 ? <sup className="reqStar"> * </sup>:null}
                    </FieldLabel>
                    <Creatable
                      value={engine.lessee_id}
                      options={clients}
                      isClearable={true}
                      onChange={this.updateLesseeForm}
                      className="custom-select-block"
                      placeholder='Add or Select Lessee'
                      valueKey='id'
                      labelKey='name'
                      type={type}
                      disabled={engine && engine.status != 1 ? true : false}

                    />
                  <h6 className="error-msg">{error.lessee_id}</h6>
                  </FieldGroup>
                </FieldCol>
              :null
            }
            {
              newLessee && this.props.location.pathname.includes('technical-specification') ?
              <Fragment>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Status<sup className="reqStar"> * </sup></FieldLabel>
                  <FieldSelect
                    placeholder='Select Status'
                    keyParam="status"
                    value={engine.status}
                    type={type}
                    options = {getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    name={engine.position_name}
                  />
                <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
              </FieldCol>
            {
              engine.status == 2 ?
              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Off Lease Status</FieldLabel>
                  <FieldSelect
                    placeholder='Select Off Lease Status'
                    keyParam="off_lease_status"
                    value={engine.off_lease_status}
                    type={type}
                    options={techConstants.filter(item => item.type=="off_lease_status")}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    name={engine.position_name}
                  />
                </FieldGroup>
              </FieldCol>
              :null
            }
              {
                this.props.location.pathname.includes('technical-specification') ?
                <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4" : "12"}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Lessor {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Lessor'
                      keyParam="lessor_name_id"
                      value={engine.lessor_name_id}
                      type={type}
                      options = {ownerList}
                      updateField={this.updateForm}
                      labelKey='name'
                      valueKey='id'
                    />
                  <h6 className="error-msg">{error.lessor_name_id}</h6>
                  </FieldGroup>
                </FieldCol> : null
              }
              {
                this.props.location.pathname.includes('technical-specification') ?
                <FieldCol md="4">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Owner
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Owner'
                      keyParam="owner_id"
                      value={engine.owner_id}
                      type={type}
                      options = {ownerList}
                      updateField={this.updateForm}
                      labelKey='name'
                      valueKey='id'
                    />
                    <h6 className="error-msg">{error.owner}</h6>
                  </FieldGroup>
                </FieldCol> : null
              }





            {
              this.props.location.pathname.includes('technical-specification') ?
              <div className="tech-spec-utilization-edit-block" style={{marginLeft:'15px'}}>
                <h6 style={{marginTop:'0'}}>{Object.keys(this.props.params).length ?  this.props.params.type.toUpperCase() : 'Module'} Utilization Details</h6>
                <Row>
                  <FieldCol md="4">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Utilization as of date
                    </FieldLabel>
                    <FieldDate
                      keyParam="last_utilization_updated"
                      value={engine.last_utilization_updated}
                      type={type}
                      updateField={this.updateForm}
                      maxDate ={ moment()}
                      minDate={moment(engine.manufacturing_date)}
                    />
                    <h6 className="error-msg">{error.last_utilization_updated}</h6>
                  </FieldGroup>
                </FieldCol>
                </Row>

                <Row>
                  <FieldCol md="3">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      TSN
                    </FieldLabel>
                    <FieldInput
                      value={engine.tsn}
                      type={type}
                      keyParam="tsn"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.tsn}</h6>
                  </FieldGroup>
                </FieldCol>

                <FieldCol md="3">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      CSN
                    </FieldLabel>
                    <FieldInput
                      value={engine.csn}
                      type={type}
                      keyParam="csn"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.csn}</h6>
                  </FieldGroup>
                </FieldCol>

                <FieldCol md="3">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Avg. Monthly Hours
                    </FieldLabel>
                    <FieldInput
                      value={engine.average_monthly_hours}
                      type={type}
                      keyParam="average_monthly_hours"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.average_monthly_hours}</h6>
                  </FieldGroup>
                </FieldCol>

                <FieldCol md="3">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Avg. Monthly Cycles
                    </FieldLabel>
                    <FieldInput
                      value={engine.average_monthly_cycles}
                      type={type}
                      keyParam="average_monthly_cycles"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                  </FieldGroup>
                </FieldCol>
                </Row>
              </div> : null
            }


              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Region of Operation
                 { engine.status!=1? null:  <sup className="reqStar"> * </sup>}
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select region'
                    keyParam="region"
                    value={engine.region}
                    type={type}
                    options={sRegionList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='id'
                    name={engine.region_label}
                  />

                <h6 className="error-msg">{error.region}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Purchased Thrust (Lbs)
                  </FieldLabel>
                  <FieldInput
                    value={engine.purchase_thrust}
                    type={type}
                    keyParam="purchase_thrust"
                    updateField={this.updateForm}
                  />
                 <h6 className="error-msg">{error.purchase_thrust}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Operating Thrust (Lbs)
                 </FieldLabel>
                 <FieldInput
                   value={engine.operating_thrust}
                   type={type}
                   keyParam="operating_thrust"
                   updateField={this.updateForm}
                 />
               <h6 className="error-msg">{error.operating_thrust}</h6>

               </FieldGroup>
             </FieldCol>

              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Location
                    <i class="fa fa-info-circle" aria-hidden="true" id="engine-field4" style={{paddingLeft: '4px'}}></i>
                    <UncontrolledTooltip placement="right" target="engine-field4" >
                      Input country of operation
                    </UncontrolledTooltip>
                  </FieldLabel>
                  {
   								 window.google !== undefined && window.google !== null ?
                   <PlacesAutocomplete
                     value={engine.location}
                     onChange={this.handleChange}
                     onSelect={this.handleSelect}
                   >
                   {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                     <div style={{position: 'relative'}}>
                       <input
                         {...getInputProps({
                           placeholder: 'Search Location',
                           className: 'location-search-input',
                         })}
                       />
                       {
                         engine.location ?
                         <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                         {loading && <div>Loading...</div>}
                         {suggestions.map(suggestion => {
                           const className = suggestion.active
                             ? 'suggestion-item--active'
                             : 'suggestion-item';
                           // inline style for demonstration purpose
                           const style = suggestion.active
                             ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                             : { backgroundColor: '#ffffff', cursor: 'pointer' };
                           return (
                             <div
                               {...getSuggestionItemProps(suggestion, {
                                 className,
                                 style,
                               })}
                             >
                               <span>{suggestion.description}</span>
                             </div>
                           );
                         })}
                       </div> :  null
                       }

                     </div>
                   )}
                 </PlacesAutocomplete>
   								 :<FieldInput
                     value={engine.location}
                     type={type}
                     keyParam="location"
                     updateField={this.updateForm}
                   />
   							 }
              </FieldGroup>
            </FieldCol>
            {
              showTabs ?
              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Ownership</FieldLabel>
                  <div>
                    <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                       <input type="radio"
                        value={engine.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 1)}
                        style={{ marginRight: '4px'}}
                        checked = {engine.ownership_type === 1 ? true : false}
                       />
                     <span>Owned</span>
                    </label>
                    <label className="label" style={{ cursor: 'pointer'}}>
                       <input type="radio"
                        value={engine.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 2)}
                        style={{ marginRight: '4px'}}
                        checked = {engine.ownership_type === 2 ? true : false}
                       />
                     <span>Managed</span>
                    </label>
                  </div>
                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              engine.ownership_type == 2 ?
              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                    <FieldSelect
                      placeholder='Select Portfolio'
                      keyParam="owner_portfolio"
                      value={engine.owner_portfolio}
                      type={type}
                      options = {this.props.ownersList}
                      updateField={this.updateForm}
                      labelKey= 'name'
                      valueKey='id'
                    />
                  <h6 className="error-msg">{error.portfolio}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }

            <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                   Credit Classification
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Credit Classification'
                    keyParam="credit_classification"
                    value={engine.credit_classification}
                    type={type}
                    options = {techConstants.filter(item => item.type == "credit_classification")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
              <FieldGroup className="form-group">

                <FieldLabel className="label">  Date of Installation</FieldLabel>
                <FieldDate
                  value={engine.date_of_installation}
                  type={type}
                  minDate={moment(engine.manufacturing_date)}
                  keyParam="date_of_installation"
                  updateField={this.updateForm}
                />

              <h6 className="error-msg">{error.date_of_installation	}</h6>
              </FieldGroup>
            </FieldCol>

            {
              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">  TSN</FieldLabel>
                  <FieldInput
                    value={engine.tsn}
                    type={type}
                    keyParam="tsn"
                    updateField={this.updateForm}

                  />
                <h6 className="error-msg">{error.tsn	}</h6>

                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">  CSN</FieldLabel>
                  <FieldInput
                    value={engine.csn}
                    type={type}
                    keyParam="csn"
                    updateField={this.updateForm}
                  />
            <h6 className="error-msg">{error.csn	}</h6>

                </FieldGroup>
              </FieldCol>
              :null
            }

            {
              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Avg. Monthly Hours
                    </FieldLabel>
                    <FieldInput
                      value={engine.average_monthly_hours}
                      type={type}
                      keyParam="average_monthly_hours"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.average_monthly_hours}</h6>
                  </FieldGroup>
              </FieldCol>
              :null
            }

            {
              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Avg. Monthly Cycles
                  </FieldLabel>

                  <FieldInput
                    value={engine.average_monthly_cycles}
                    type={type}
                    keyParam="average_monthly_cycles"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }
            <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>
            {
              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                    <FieldLabel className="label">
                       Lessee
                       { type != 'view' && engine.status== 1 ? <sup className="reqStar"> * </sup>:null}
                    </FieldLabel>
                    <Creatable
                      value={engine.lessee_id}
                      options={clients}
                      isClearable={true}
                      onChange={this.updateLesseeForm}
                      className="custom-select-block"
                      placeholder='Add or Select Lessee'
                      valueKey='id'
                      labelKey='name'
                      type={type}
                      disabled={engine && engine.status != 1 ? true : false}

                    />
                  <h6 className="error-msg">{error.lessee}</h6>
                  </FieldGroup>
                </FieldCol>
              :null
            }
            {
              newLessee ?
              <Fragment>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee ? engine.lessee.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
          </div>
            {
              !this.props.location.pathname.includes('technical-specification') ?
              <Col md="12">
                <div className="form-group">
                 <label className="label" style={{ cursor: 'pointer'}}>
                    <input type="checkbox"
                     value={engine.same_operator_lessee}
                     onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                     style={{ marginRight: '4px'}}
                     disabled= {engine.lessee_id ? false : true}
                     checked = {engine.same_operator_lessee}
                    />
                  <span>Operator is same as Lessee</span>
                 </label>
                </div>
              </Col>
              :null
            }
         { !engine.same_operator_lessee ?
					<FieldCol md={this.props.location.pathname.includes('technical-specification') ? "4":"12" }>
						<FieldGroup className="form-group">
                <FieldLabel className="label">
              		Operator
                </FieldLabel>

								<FieldSelect
                  placeholder='Select Operator'
                  keyParam="operator_id"
                  value={engine.operator_id}
                  type={type}
                  options={operators}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                />
              </FieldGroup>
            </FieldCol> : null }


            {

              <Col md="12">
                 <div className="form-group" style={{ margin: "0px 0px 20px 0px" }}>
                     <label className="label">
                       <input
                         type="checkbox"
                         checked={engine.match_csn_to_llp}
                         onChange={(e) => this.updateForm('match_csn_to_llp', e.target.checked)}
                       />
                     <span>Match  {`${Object.keys(this.props.params).length ?  this.props.params.type.toUpperCase() : 'Module'}'s`} TSN & CSN to LLP</span>
                     </label>
                 </div>

               </Col>

            }
            {

              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Operating Thrust (Lbs)
                 </FieldLabel>
                 <FieldInput
                   value={engine.operating_thrust}
                   type={type}
                   keyParam="operating_thrust"
                   updateField={this.updateForm}
                 />
               <h6 className="error-msg">{error.operating_thrust}</h6>

               </FieldGroup>
             </FieldCol>
              :null
            }
            {

              !this.props.location.pathname.includes('technical-specification') ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Purchased Thrust (Lbs)
                  </FieldLabel>
                  <FieldInput
                    value={engine.purchase_thrust}
                    type={type}
                    keyParam="purchase_thrust"
                    updateField={this.updateForm}
                  />
                 <h6 className="error-msg">{error.purchase_thrust}</h6>

                </FieldGroup>
              </FieldCol>
              :null
            }
            { this.state.engine.esn && this.state.engine.engine_type_id ?
              Object.keys(this.state.owner).length ?
              null:
              userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled ?
              <div style={{padding: '0 40px', width: '100%'}}>
                <div style={{width: '100%', padding: '10px 20px', background: '#f2f2f2', marginBottom: '50px'}}>
                  {
                    userInfo.defaultLessor.lessor_type != 1 ?
                    <Col md="12" style={{borderBottom: '1px solid #d3d3d3', marginBottom: '20px',paddingLeft:'0px'}}>
                      <div className="form-group" style={{marginBottom: '0px',padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                        <span style={{color:'#9439e4',display:'block',padding:'5px 0px'}}>Ownership Details</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {
                  userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled ?
                    <Col md="12" style={{padding:'0px'}}>
                      <div className="form-group" style={{padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                          <input type="checkbox"
                           value={engine.own_asset}
                           onChange = {(e) => this.updateForm('own_asset', e.target.checked)}
                           style={{ marginRight: '4px'}}
                           checked = {engine.own_asset}
                           disabled={this.state.disabled}
                          />
                        <span>I would like to continue without notifying/inviting the lessor</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {!engine.own_asset && !this.state.disabled && userInfo.defaultLessor.lessor_type == 3 ? <p className="text-center"><span>Or, Select the lessor by entering details below</span></p>:null}

                  { !engine.own_asset ?
                    <div>
                      {!engine.own_asset && userInfo.defaultLessor.lessor_type == 3 ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor<sup className="reqStar"> * </sup></FieldLabel>
                            {
                              this.state.disabled ?
                              <FieldInput
                                value={engine.lessor_name}
                                type={type}
                                keyParam="lessor_name"
                                updateField={this.updateForm}
                                disable={this.state.disabled}
                              />
                              :<FieldSelect
                                placeholder='Select Lessor'
                                keyParam="exist_lessor_slug"
                                value={engine.exist_lessor_slug}
                                type={type}
                                options={lessorList.filter(item => item.lessor_type.value != 3)}
                                updateField={this.updateForm}
                                labelKey="name"
                                valueKey="slug"
                                styleName="custom-select-block custom-select-block-white"
                              />
                            }
                          <h6 className="error-msg">{error.exist_lessor_slug}</h6>
                          </FieldGroup>
                        </FieldCol> : null
                      }
                      { !engine.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && !this.state.disabled && engine.exist_lessor_slug && engine.exist_lessor_slug == 'newlessor' ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Name<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={engine.lessor_name}
                              type={type}
                              keyParam="lessor_name"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                          <h6 className="error-msg">{error.lessor_name}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                      { !engine.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && engine.exist_lessor_slug && engine.exist_lessor_slug == 'newlessor' ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Email<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={engine.lessor_domain}
                              type={type}
                              keyParam="lessor_domain"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                          <h6 className="error-msg">{error.lessor_domain}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                    </div>:null
                  }

                </div>
              </div>
              :null
              :null
            }
					</Row>


        </div>

        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar('addModule')} >Cancel</Link>
        </div>
      </form>
    )
  }
}
export default withRouter(AddEditModule);
