export const errorCode = {
  lease_service_fee:{
    0:'',
    1: 'Please enter Lease Service Cost'
  },
  lease_service_feeObj: {
    required: true
  },
  administrator_fee: {
    0:'',
    1: 'Please enter Administrator Fee'
  },
  administrator_feeObj: {
    required: true
  },
  re_marketing_cost: {
    0:'',
    1: 'Please enter Re-marketing Cost'
  },
  re_marketing_costObj: {
    required: true
  },
  asset_management_cost: {
    0:'',
    1: 'Please enter Asset Management Cost'
  },
  asset_management_costObj: {
    required: true
  },
  purchase_value: {
    0:'',
    1: 'Please enter Purchase value of Asset'
  },
  purchase_valueObj: {
    required: true
  },
  tax_rate: {
    0:'',
    1: 'Please enter Tax Rate'
  },
  tax_rateObj: {
    required: true
  },
  debit_ratio: {
    0:'',
    1: 'Please enter Debt Ratio'
  },
  debit_ratioObj: {
    required: true
  },
  equity_ratio: {
    0:'',
    1: 'Please enter Equity Ratio'
  },
  equity_ratioObj: {
    required: true
  },
  debit_value: {
    0:'',
    1: 'Please enter Debt Value'
  },
  debit_valueObj: {
    required: true
  },
  equity_value: {
    0:'',
    1: 'Please enter Equity Value'
  },
  equity_valueObj: {
    required: true
  },
  interest_rate: {
    0:'',
    1: 'Please enter Interest Rate'
  },
  interest_rateObj: {
    required: true
  },
  return_equity: {
    0:'',
    1: 'Please enter Expected Return'
  },
  return_equityObj: {
    required: true
  },
}
