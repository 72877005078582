import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, browserHistory } from 'react-router';
import {Button, Paper} from '@material-ui/core';
import { TableListComp, LabelValueCard, PageLoader, ExportManu, FilterUIComp, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { AvgUtilsCard, CashflowWidget, CashflowProjection, InvoiceSummary, LeaseHistory } from '../components';
import { ReserveAccountFund } from '../../Elements';
import { sExportContractsAc } from '../../actionCreators';
import { getLocalStorageInfo } from '../../../utils_v2';
import { getCashflowSummaryApi, getCashflowProjectionApi, getReserveAccountsApi, getInvoiceSummaryApi, getAvgUtilizationApi, getLeaseHistoryApi } from '../apiServices';
import { assetsType } from '../../../constants';
class Statistics extends Component {
  constructor(props){
    super(props);
    this.state = {
      cashflowSummary:{},
      cashflowWidget:{},
      cashflowProjection:{},
      invoiceSummary:{},
      avgUtilization:[],
      leaseHistory:[]
    };
    this.getCashflowSummaryApi = getCashflowSummaryApi.bind(this);
    this.getCashflowProjectionApi = getCashflowProjectionApi.bind(this);
    this.getReserveAccountsApi = getReserveAccountsApi.bind(this);
    this.getInvoiceSummaryApi = getInvoiceSummaryApi.bind(this);
    this.getAvgUtilizationApi = getAvgUtilizationApi.bind(this);
    this.getLeaseHistoryApi = getLeaseHistoryApi.bind(this);
  }
  componentDidMount(){
    this.getCashflowSummaryApi(this.props);
    // this.getCashflowProjectionApi(this.props);
    this.getInvoiceSummaryApi(this.props);
    this.getReserveAccountsApi(this.props);
    this.getAvgUtilizationApi(this.props);
    // this.getLeaseHistoryApi(this.props);
  }
  render(){
    const { cashflowSummary, cashflowProjection,cashflowWidget,  invoiceSummary, avgUtilization, leaseHistory } = this.state;
    return(
      <section className="lease-management-section lease-statistics-section">
      <div className="mr-rate-wrapper  mr-rate-spacing">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`Statistics`} type="brief" params={this.props.params} />
        <div className="view-block" style={{background:'none'}}>
          <Paper className="cashflow-summary-section">
            <h2>Cash Flow Summary</h2>
            { Object.keys(cashflowSummary).length ?
              <CashflowWidget cashflowSummary={cashflowSummary} />:null
            }
            <CashflowProjection />
          </Paper>
          { Object.keys(cashflowWidget).length ?
            <ReserveAccountFund cashflowWidget={cashflowWidget} />: null
          }
          { Object.keys(invoiceSummary).length ?
            <InvoiceSummary invoiceSummary={invoiceSummary} />:null
          }
          { avgUtilization.length ?
            <div>
              <AvgUtilsCard avgUtilization={avgUtilization} />
            </div>:null
          }
          { leaseHistory.length ?
            <div>
              <LeaseHistory />
            </div>:null
          }
        </div>
      </div>
      </section>
    )
  }
}
export default Statistics;
