import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { utilsGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const UtilsLesseeGraph = ({lesseeGraphData, skeletonLoader, filter, getResponseBack, callExternalFunction}) => {
    let filterOptions = Object.assign({}, utilsGraphFilterOps);
    const options = {
        chart: {
            type: 'column',
            zoomType: 'xy',
            panning: true,
            panKey: 'shift'
        },
        title: {
            text: 'Utilization by Lessee',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${lesseeGraphData.title ? lesseeGraphData.title : ''}`,
            align: 'left'
        },
        xAxis: {
            categories: lesseeGraphData && lesseeGraphData.lessee_categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Utilization'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series:{
                point: {
                    events: {
                      click: function() {
                        callExternalFunction(this.series.name, this.category, 'basedOnLessee');
                      }
                    }
                },
            }
        },
        legend: {
            enabled: true
        },
        series: lesseeGraphData && lesseeGraphData.utilization_lessee_categories,
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                }
            },
            filename: 'Utilization(s)-Utilization by Lessee Chart',
         }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider/>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </Paper>
    )
}
export default UtilsLesseeGraph
