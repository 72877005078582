import React, { Component } from 'react';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';

export default class PartialPaymentsList extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { value } = this.props;
      return (
        <tr>
            <td>
                <h6 className="para-ui"> {value.description ? value.description : '--'}</h6>
            </td>
            <td>
                <h6 className="para-ui"> 
                    <NumericLabel params={showCurrencyFormat(value.currency)}>
                        {value.amount ? value.amount : '--'}
                    </NumericLabel>
                </h6>
            </td>
            <td>
                <h6 className="para-ui"> {value.payment_date ? moment(value.payment_date).format(displayDateFormat) : '--'}</h6>
            </td>
            <td>
            {
                value.remaining_amount == 0 ?
                <h6 className="para-ui">US$0.00</h6> :
                <h6 className="para-ui">
                    <NumericLabel params={showCurrencyFormat(value.currency)}>
                        {value.remaining_amount ? value.remaining_amount : '--'}
                    </NumericLabel>
                </h6>
            }
                
            </td>
            <td>
                <h6 className="para-ui">{value.created_by ? value.created_by.name : '--'}</h6>
            </td>
            <td>
                <h6 className="para-ui">{value.created_at ? moment(value.created_at).format(displayDateFormat) : '--'}</h6>
            </td>
        </tr>
      )
  }
}
