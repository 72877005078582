import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { showCurrencyFormat } from '../../../../utils';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import config from '../../../../config';
export default class DashboardInvoiceInfo extends Component{
  constructor(props){
    super(props);
    this.state = {
      

    }
  }

  render(){
    const { contrDbInvoices, params } = this.props;
    return(
      <div className="db-invoice">
        <div className="cashflow-top-bar invoice-summary">
          <Row className="db-invoice-heading">
            <Col md="12" className="">
              <h5 className="para">Invoice Summary
                <Link to={`${config.domain.subDomian}${params.type}/${params.aircraft_slug}/contract/${this.props.params.contract_slug}/invoice`}>View All</Link>
              </h5>
            </Col>
            <Col md="4" className="margin-bottom-xs">
              <p>Total Invoiced
              <InfoIcon id="total-invoice-info" /></p>
              <UncontrolledTooltip placement="top" target="total-invoice-info">
                Sum of invoices sent and paid
              </UncontrolledTooltip>
              { contrDbInvoices.total_amount_new ?
                contrDbInvoices.total_amount_new.length?
                contrDbInvoices.total_amount_new.map((contrDbInvoice, index) =>
                <div>
                <NumericLabel params={showCurrencyFormat(contrDbInvoice.currency)}>{contrDbInvoice.total_amount}</NumericLabel>
                </div>
                ): '$0.00': '$0.00'
              }

            </Col>
            <Col md="4" className="margin-bottom-xs">
              <p>Total Pending <InfoIcon id="pending-invoice-info" /></p>
              <ToolTipHover placement="top" tagetId="pending-invoice-info">
                Sum of invoices sent and yet to be paid
              </ToolTipHover>
              { contrDbInvoices.open_amount_new ?
                contrDbInvoices.open_amount_new.length?
                contrDbInvoices.open_amount_new.map((contrDbInvoice, index) =>
                <div>
                <NumericLabel params={showCurrencyFormat(contrDbInvoice.currency)}>{contrDbInvoice.total_amount}</NumericLabel>
                </div>
                ): '$0.00': '$0.00'
              }
            </Col>
            <Col md="4" className="margin-bottom-xs">
              <p>Total Overdue<InfoIcon id="overdue-invoice-info" /></p>
              <ToolTipHover placement="top" tagetId="overdue-invoice-info">
                Sum of invoices yet to be paid and is overdue
              </ToolTipHover>
              <span style={{color: '#ff0000'}}>
              { contrDbInvoices.due_amount_new ?
                contrDbInvoices.due_amount_new.length?
                contrDbInvoices.due_amount_new.map((contrDbInvoice, index) =>
                <div>
                <NumericLabel params={showCurrencyFormat(contrDbInvoice.currency)}>{contrDbInvoice.total_amount}</NumericLabel>
                </div>
                ): '$0.00': '$0.00'
              }
              </span>

            </Col>
          </Row>
        </div>
        {this.props.children}
      </div>
    )
  }
}
