import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';

export default function UtilizationListing({item, redirectToDetails}) {
  return (
    <TableRow onClick={()=>redirectToDetails(item)} hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.month_year ? item?.month_year : '--'}
    </TableCell>
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.asset?.unique_name}
    </TableCell>
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.hours ? item?.hours : '--'}
    </TableCell>
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.cycles ? item?.cycles : '--'}
    </TableCell>
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.tsn ? item?.tsn : '--'}
    </TableCell>
    <TableCell className="" style={{ cursor: 'pointer' }} >
        {item?.csn ? item?.csn : '--'}
    </TableCell>
</TableRow>
  )
}
