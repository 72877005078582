import React, { Component } from 'react';
import { withRouter, browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { DeliveryCndList, DeliveryCondCRU } from '../components';
import { Header, ContractHeadertext, ContractSideBar } from '../../../../applications/contracts/Elements';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { assetsType } from '../../../../constants';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getDeliveryCondListApi,deleteDeliveryCondApi } from '../apiServices';
import { getContractClauseAc, sExportContractsAc } from '../../../actionCreators';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { deliveryConditionHd } from '../../';
import backArrowIcon from '../../../../shared_elements/assets/img/back_arrow.svg';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import {contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class DeliveryConditions extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      deleteItem:{modal:false, data:null}
    }
    this.getDeliveryCondListApi = getDeliveryCondListApi.bind(this);
    this.deleteDeliveryCondApi = deleteDeliveryCondApi.bind(this);
  }
  componentDidMount(){
    this.getDeliveryCondListApi(this.props);
    this.props.getContractClause();
    this.props.fetchContractInfo();
    trackActivity('Page Visited', {
      page_title: 'Delivery Condition'
    })
  }
  getResponseBack = () => {
    this.getDeliveryCondListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { contractClauseList, params, contractSummaryLoader, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Delivery Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='delivery-condition' primaryTab='technical-details' secondaryTab='deliveryConditions' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctdeliveryconditionitems/'
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            params={params}
            buttonText={ checkPermission('contracts','delivery_conditions', 'C') ? <DeliveryCondCRU contractInfo={contractInfo} contractClauseList={contractClauseList} getResponseBack={this.getResponseBack} />:null}
          >
            { skeletonLoader ?
              (<STableLoader count={4} />):
              (
                <TableListComp
                  heads={deliveryConditionHd}
                  data={list.map((item,index) =>
                    <DeliveryCndList
                      contractInfo={contractInfo}
                      contractClauseList={contractClauseList}
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item} 
                      deleteDeliveryCond={() => this.setState({deleteItem:{modal:true, data:item}})}/>
                    )
                  }
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteDeliveryCondApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractClauseList: state.contractReducer.contractClauseList,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getContractClause: () => dispatch(getContractClauseAc(ownProps)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DeliveryConditions,['contracts','delivery_conditions', 'R'])));
