export const CLAUSE_LIST = '/contract/clause/list';
export const RESERVE_ACCOUNTS = '/contract/reserve_account/list';
export const CONTRACT_EXPORT_LOADER='CONTRACT_EXPORT_LOADER';
export const CONTR_DB_FLAGGED_COND = 'CONTR_DB_FLAGGED_COND';
export const COMPARE_RETURN_COND = 'COMPARE_RETURN_COND';
export const CONTRACT_WIDGET_LOADER = 'CONTRACT_WIDGET_LOADER';
export const CLEAR_RETURN_COND = 'CLEAR_RETURN_COND';
export const RETURN_COND_LIST = 'RETURN_COND_LIST';
export const RETURN_COND_LOADER = 'RETURN_COND_LOADER';
export const LESSEE_LIST = 'platform/lessee';
