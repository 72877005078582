import React from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { browserHistory } from 'react-router';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { invoiceTypes, invoiceStatusDrpdwn, displayDateFormatShort,invoiceStatusList } from '../../../constants';
import { getCurrencyFormat, dateTransform } from '../../../utils_v2';
import { CheckBox } from '@material-ui/icons';
const FleetInvoice = ({item, getResponseBack, toggleBulkOps, bulkIds}) => {
  let assetTypeNames = {
    1: 'aircraft',
    2: 'engine',
    3: 'apu',
    4: 'lg',
    5: 'propeller',
    6: 'fan',
    7: 'hpc',
    8: 'hpt',
    9: 'lpt'
  }
  const redirectUrl = () =>{
    if(item.asset.linked_aircraft_slug){
      window.open(`/aircraft/${item.asset.linked_aircraft_slug}/contract/${item.lease_slug}/invoice/view/${item.id}`,'_blank')
    }else{
      window.open(`/${assetTypeNames[item.asset.asset_type]}/${item.asset.slug}/contract/${item.lease_slug}/invoice/view/${item.id}`,'_blank')
    }
  }
  return(
    <TableRow hover tabIndex={-1} style={{cursor:'pointer'}}>
      <TableCell className="asset-info" onClick = {()=>redirectUrl()}>
          <div className="flex-centered asset-wrapper" style={{flexWrap:"nowrap"}}>
            <div className="assetIcon">
                <img style={{ height: '25px', objectFit: 'contain' }} src={"images/" + (item.asset.asset_type == 1 ? "icon_Aircraft.png" : (item.asset.asset_type == 2) ? "icon_engine.png" : item.asset.asset_type == 3 ? "icon_apu.svg" : item.asset.asset_type == 4 ? 'icon_lg.svg' : item.asset.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
            </div>
            <div className="asset">
              {
                (item.asset.asset_type === 1 || item.asset.asset_type === 2  || item.asset.asset_type === 3 || item.asset.asset_type === 5) ?
                <p>
                    {item.asset.asset_type === 1 ? item.asset.name ? item.asset.name : item.asset.name : ''}{item.asset.asset_type === 1 ? item.asset.model && ' / ' + item.asset.model: ''}{item.asset.asset_type === 2 ? item.asset.name : ''}{item.asset.asset_type === 3 ? item.asset.name : ''}{item.asset.asset_type === 5 ? item.asset?.model ?item.asset.model: null : null}
                </p> : null
              }
                <h6>{item.asset.unique_name}</h6>
            </div>
          </div>
      </TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name:'--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.owner && item.owner.name ? item.owner.name:'--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>
        {item.lessee && item.lessee.logo ? <img style={{width:"60px"}} src={item.lessee && item.lessee.logo} alt="Lessee Logo"/> : <span>{item.lessee && item.lessee.name ? item.lessee.name :'--'}</span>}
      </TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.invoice_number}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.invoice_type && item.invoice_type.label ? item.invoice_type.label:'--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>
        <p className={item.invoice_status.value && invoiceStatusList[item.invoice_status.value] + ' invoice-status'}>{item.invoice_status && item.invoice_status.label ? item.invoice_status.label.toUpperCase():'--'}</p>
        <span className="payment-text">
          { ((item.invoice_status.value == 2) && item.in_due) ?  <span className="invoice-status-payment" >{"Overdue By " + dateTransform(item.due_date)}</span> : ''}
        </span>
        {
          item?.qb_last_sync_time && item?.qb_last_sync_by?.name ?
            <span className="payment-text">Sync Status:
              {item?.qb_last_sync_time ? moment(item?.qb_last_sync_time).format('MMM DD, YYYY') : '--'}{' by '}{item?.qb_last_sync_by?.name || '--'}
              <br /></span>
            : null}
        {
          item.invoice_status.value == 5 && item.partial_paid_date ?
          <span className="payment-text">
            <span>Partial Payment: <NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.partial_paid_amount  ?  item.partial_paid_amount : '--'}</NumericLabel><br/></span>
            <span>Payment Date: {moment(item.partial_paid_date).format('MMM DD,YYYY')}</span>
            {moment(item.partial_paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date)}</span>:''}
          </span> : null
        }
        {
          item.invoice_status.value == 3 && item.paid_date ?
          <span className="payment-text">
            Payment Date: {moment(item.paid_date).format(displayDateFormatShort)}
            {moment(item.paid_date).diff(moment(item.due_date), 'days') > 0 ? <span  className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date, item.paid_date)}</span> :''}
          </span>:
          null
        }
      </TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item?.bank_name?item?.bank_name:'--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}><NumericLabel params={getCurrencyFormat({currency:item.currency, shortFormat:false})}>{item.sub_total}</NumericLabel></TableCell>
      <TableCell onClick = {()=>redirectUrl()} style={{textAlign:" -webkit-center",width:"170px"}}>{item.start_date ? item.start_date : '--'} <br/><span>to</span><br/> {item.end_date ? item.end_date : '--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.date ? item.date : '--'}</TableCell>
      <TableCell onClick = {()=>redirectUrl()}>{item.due_date ? item.due_date : '--'}</TableCell>
    </TableRow>
  )
}
export default FleetInvoice;
