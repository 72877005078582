import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';

export default function AssemblyLeaseList({ item, redirectToDetails }) {
    if (item?.assembly_swap?.value == 1) {
        return (
            <TableRow onClick={()=>redirectToDetails(item)} hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.major_assembly?.unique_name}
                    &nbsp; ({item?.major_assembly?.lessor?.name ? item?.major_assembly?.lessor?.name : '--'})
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.assignment_msn?.unique_name ? item?.assignment_msn?.unique_name : '--'}
                   &nbsp; ({item?.assignment_msn?.lessor_name?.name ? item?.assignment_msn?.lessor_name?.name : '--'})
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.lease_start_date ? item?.lease_start_date : '--'}
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.lease_end_date ? item?.lease_end_date : '--'}
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }}>
                    <span className={`status-label status-${item?.lease_status?.value}`} style={{ padding: '4px 10px' }}>
                        {item?.lease_status?.label}
                    </span>
                </TableCell>
            </TableRow>
        )
    }
    else {
        return (
            <TableRow onClick={()=>redirectToDetails(item)} hover tabIndex={-1} style={{ padding: '4px 0px' }}>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.major_assembly?.unique_name}
                &nbsp; ({item?.major_assembly?.lessor?.name ? item?.major_assembly?.lessor?.name : '--'})
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.assignment_msn?.unique_name ? item?.assignment_msn?.unique_name : '--'}
                &nbsp; ({item?.assignment_msn?.lessor_name?.name ? item?.assignment_msn?.lessor_name?.name : '--'})
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    {item?.sale_date ? item?.sale_date : '--'} (Sold date)
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }} >
                    N/A
                </TableCell>
                <TableCell className="" style={{ cursor: 'pointer' }}>
                    <span className={`status-label status-4`} style={{ padding: '4px 10px' }}>
                        SOLD
                    </span>
                </TableCell>
            </TableRow>
        )
    }
}
