import { globalGetService, globalPostService, globalPutService} from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
export function getWorkingGroupsListApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/working-group-master-list/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        workingGroups: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getWorkingGroupsApi(props={}, id, mode){
  this.setState({pageLoader:true})
  globalGetService(`contracts/${props.params.contract_slug}/working-group/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        team: response.data.data,
        modal:true
      })
    }
    this.setState({pageLoader:false})
  })
}
export function addEditWorkingGroupsApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/working-group/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/working-group/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}

export function getWorkingGroupMemberApi(props={}, groupId, memberId, m){
  this.setState({pageLoader:true})
  globalGetService(`contracts/${props.params.contract_slug}/working-group/${groupId}/member/${memberId}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState(prevState => ({
        ...prevState,
        member: response.data.data,
        modal: true,
        mode: m === 'V' ? 'view':'edit'
      }));
    }
    this.setState({pageLoader:false})
  })
}
export function addEditWorkingGroupMemberApi(props={}, groupId, data){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/working-group/${data.working_group_id}/member/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/working-group/${groupId}/member/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}
