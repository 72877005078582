import React, { Fragment, Component } from 'react';
import { Grid } from '@material-ui/core';
import JSONViewer from 'react-json-viewer';
import LabelValueCard from './LabelValueCard';
import moment from 'moment';
import {  displayDateTimeFormatShort } from '../../constants';
class RevisionAddCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      tableStatus:false,
    }
  }
  showData = () => {
    this.setState(prevState => ({
      ...prevState,
      tableStatus: !this.state.tableStatus
    }))
  }
  render(){
    const { item } = this.props;
    return(
      <Fragment>
        <Grid container spacing={3}>
          <LabelValueCard md={6} label='Created at' value={item.timestamp ? moment(item.timestamp).format(displayDateTimeFormatShort) : '--'} />
          <LabelValueCard md={6} label='Created by' value={item.user && item.user.name ? item.user.name:'--'} />
        </Grid>
        <div className="show-data">
          <span onClick={this.showData}>Show Data <i style={ this.state.tableStatus ? { transform: 'rotate(180deg)' }:  { transform: 'rotate(0)' } }>&#9660;</i></span>
        </div>
        <div className="edit-detail-table" style={this.state.tableStatus ? {display: 'block'} :  {display: 'none' }}>
          <JSONViewer json={item.changes}></JSONViewer>
        </div>
      </Fragment>
    )
  }
}
export default RevisionAddCard;
