import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { fieldDateFormat, backendDateFormat, invoiceStatusDrpdwn } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { PageLoader, DeletePopUp } from '../../../shared_elements';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { checkApiStatus } from '../../../utils_v2';
import { toggleInvoiceStatusApi } from '../apiServices';
import { statusErrorCode } from '../';
class ChangeInvoiceStatus extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      deleteModal:false,
      modal:false,
      status:null,
      paid_date:moment().format(backendDateFormat),
      partial_paid_date:moment().format(backendDateFormat),
      partial_paid_amount:'',
      error:{}
    }
    this.toggleInvoiceStatusApi = toggleInvoiceStatusApi.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState({[keyParam]:value});
  }
  updateErrorField = (key) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:''
      }
    }))
  }
  changeInvoiceStatus = () => {
    const { status, paid_date, partial_paid_date, partial_paid_amount } = this.state;

    if(status && status.value && status.value === 4){
      this.setState({deleteModal:true});
    }else{
      let validationInputs = {
        status:statusErrorCode['status'][fieldValidation({...statusErrorCode['statusObj'], fieldval: status})],
        paid_date: status && status.value && status.value === 3 ? statusErrorCode['paid_date'][fieldValidation({...statusErrorCode['paid_dateObj'], fieldval: paid_date})]:'',
        partial_paid_date: status && status.value && status.value === 5 ? statusErrorCode['partial_paid_date'][fieldValidation({...statusErrorCode['partial_paid_dateObj'], fieldval: partial_paid_date})]:'',
        partial_paid_amount: status && status.value && status.value === 5 ? statusErrorCode['partial_paid_amount'][fieldValidation({...statusErrorCode['partial_paid_amountObj'], fieldval: partial_paid_amount})]:'',
      }
      if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
        this.updateTheInvoice();
      }else{
        this.setState({error:validationInputs});
      }
    }
  }
  updateTheInvoice = () => {
    const { status, paid_date, partial_paid_date, partial_paid_amount } = this.state;
    this.setState({pageLoader:true});
    let payload = {status: status.value}
    if(status.value === 3){
      payload = {
        ...payload,
        paid_date
      }
    }
    if(status.value === 5){
      payload = {
        ...payload,
        partial_paid_date,
        partial_paid_amount
      }
    }
    this.toggleInvoiceStatusApi(this.props, payload)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState({modal:false, status:null, paid_date:moment().format(backendDateFormat), error:{}});
        this.props.getResponseBack();
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  render(){
    const { modal, paid_date, partial_paid_date, partial_paid_amount, status, error, pageLoader, deleteModal } = this.state;
    const { type, statusValue } = this.props;
    return(
      <Fragment>
        { type === 'list' ?
        <span onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modal:true})}} className={`invoice-status status-${statusValue} flex-centered`}>
          {statusValue === 1 ? 'Draft':'Sent'}
          <ArrowDropDownIcon />
        </span>:<span onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modal:true})}} style={{color:'#0000ee', textDecoration:'underline', cursor:'pointer', paddingLeft:'4px'}}>Change</span>
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, status:null, paid_date:null, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
          <DialogTitle id="scroll-dialog-title">
            Change Invoice Status
          </DialogTitle>
          <DialogContent dividers={true}>
            <div style={{width: isMobile ? 'auto':'380px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    options = {statusValue === 1 ? invoiceStatusDrpdwn.filter(item => [2,3,4,5].includes(item.value)): statusValue === 5 ? invoiceStatusDrpdwn.filter(item => [3].includes(item.value)):invoiceStatusDrpdwn.filter(item => [3,4,5].includes(item.value))}
                    getOptionLabel={option => option.label}
                    id="status"
                    value={status}
                    onChange={(e, value) => {this.onFieldChange(e, 'status', value);this.updateErrorField('status')}}
                    renderInput={params => <TextField required error={error.status ? true:false} helperText={error.status ? error.status:''} {...params} label="Status" placeholder="Select Invoice Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                { status && status.value === 3 ?
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         required
                         margin="normal"
                         id="paid_date"
                         label="Payment Date"
                         format={fieldDateFormat}
                         fullWidth
                         disableFuture
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={paid_date}
                         error={error.paid_date ? true:false}
                         helperText={error.paid_date ? error.paid_date:''}
                         onChange={(data, value) => {this.onFieldChange(value, 'paid_date', data ? moment(data).format(backendDateFormat):data); this.updateErrorField('paid_date')}}
                       />
                     </MuiPickersUtilsProvider>
                  </Grid>:null
                }
                { status && status.value === 5?
                  <Fragment>
                    <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           required
                           margin="normal"
                           id="partial_paid_date"
                           label="Partial Payment Date"
                           format={fieldDateFormat}
                           fullWidth
                           disableFuture
                           InputLabelProps={{shrink: true}}
                           inputProps={{readOnly: true}}
                           clearable={true}
                           value={partial_paid_date}
                           error={error.partial_paid_date ? true:false}
                           helperText={error.partial_paid_date ? error.partial_paid_date:''}
                           onChange={(data, value) => {this.onFieldChange(value, 'partial_paid_date', data ? moment(data).format(backendDateFormat):data); this.updateErrorField('partial_paid_date')}}
                         />
                       </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="partial_paid_amount"
                        label="Partial Payment Amount"
                        fullWidth
                        margin="normal"
                        value={partial_paid_amount}
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'partial_paid_amount', e.target.value): e.preventDefault()}}
                        onFocus={() => this.updateErrorField('partial_paid_amount')}
                        error={error.partial_paid_amount ? true:false}
                        helperText={error.partial_paid_amount ? error.partial_paid_amount:''}
                      />
                    </Grid>
                  </Fragment>:null
                }

              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({modal:false, status:null, paid_date:null, error:{}})}>Cancel</Button>
            <Button onClick={this.changeInvoiceStatus} color="primary" variant="contained">Save</Button>
          </DialogActions>
          </Dialog>:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false})}
          title="Change Status"
          content={<h4>{`Are you sure, you want to mark this invoice as ${status && status.value === 3?'paid':'cancelled'} ?`}</h4>}
          deleteRecordFn={() => {this.updateTheInvoice(); this.setState({deleteModal:false})}}
          confirmText="Yes"
        />
        { pageLoader ? <PageLoader/>:null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ChangeInvoiceStatus));
