
import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import DeliveryCondCRU from './DeliveryCondCRU';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
export default function DeliveryCndList({item, previewDetail, getResponseBack, contractClauseList, contractInfo,deleteDeliveryCond}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>{item.terms ? item.terms:'--'}</TableCell>
      <TableCell>{item.actual ? item.actual:'--'}</TableCell>
      <TableCell>{item.exceptions_done ? item.exceptions_done:'--'}</TableCell>
      <TableCell>
        <DeliveryCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','delivery_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteDeliveryCond(item.id)}/>: null}
        </TableCell>
    </TableRow>
  )
}
