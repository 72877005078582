import { globalGetService } from "../../../globalServices";
import { getLocalStorageInfo } from "../../../utils";

export function getAccountTypeApi() {
    globalGetService(`console/get-constants/?constant_type=reserve_account_type`)
        .then(response => {
            if (response.data.statusCode === 200) {
                let info = getLocalStorageInfo()
                if(info.defaultLessor.id == 442 || info.defaultLessor.id == 463){
                    
                    this.setState({
                        accType: response.data.data
                    })
                }
                else{
                    this.setState({
                        accType: response.data.data.filter(i=> i.value !== 4)
                    })
                }
            }
        })
}

