import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link, withRouter } from 'react-router';
import { getLocalStorageInfo } from './';
import { ContractSideBar } from '../applications/contracts/Elements';
import { AssemblySideBar } from '../applications/maintenance/Elements';
import {lockImgPath, imgStoragePath} from '../constants';
function RoleBasedAccess(ComposedComponent, extraInfo) {
  class authorizedUser extends Component {
    
    render() {
      let redirectUrl = '';
      if(this.props.params.aircraft_slug && this.props.params.contract_slug){
        redirectUrl = `/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/list/${this.props.params.contract_slug}`
      }else if(this.props.params.aircraft_slug){
        redirectUrl = `/${this.props.params.type}/${this.props.params.aircraft_slug}/contracts`
      }else{
        redirectUrl = '/leases';
      }
      let permissionInfo1 =  getLocalStorageInfo()?.user?.permission || {};
      if(permissionInfo1 && permissionInfo1[extraInfo[0]] && permissionInfo1[extraInfo[0]][extraInfo[1]] && permissionInfo1[extraInfo[0]][extraInfo[1]].indexOf(extraInfo[2]) != -1) {
        return(<ComposedComponent {...this.props}/>)
      }else {
        return (
          <div style={{minHeight:'100vh', position:'relative'}}>
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#000000', textAlign: 'center'}}>
              <p>
                Sorry, You do not have access to the Page.<br/>
                Please contact to Admin for access
              </p>

              <Link to={redirectUrl} style={{color:'#0723ff'}}>Back</Link>
            </div>
          </div>
        )
      }
    }
  }
  return withRouter(authorizedUser);
}
export default RoleBasedAccess;
