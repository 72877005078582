import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { TableRowActions } from '../../../../shared_elements';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import NonCashTxCRU from './NonCashTxCRU';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkPermission } from '../../../../utils_v2';
import { displayDateFormatShort } from '../../../../constants';
export default function NonCashTxList({item, previewDetail, getResponseBack, contractInfo,deleteCashAndNonCash}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell >{item.sub_type_of_transaction}</TableCell>
      <TableCell >{item.retention_period_start_date ? moment(item.retention_period_start_date).format(displayDateFormatShort):'--'} - {item.retention_period_end_date ? moment(item.retention_period_end_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell >{item.amount ? <NumericLabel params={getCurrencyFormat()}>{item.amount}</NumericLabel>:'--'}</TableCell>
      <TableCell >{item.purpose ? item.purpose:'--'}</TableCell>
      <TableCell><NonCashTxCRU id={item.id} securityDepositId={item.security_deposit_id} m="V" getResponseBack={getResponseBack} contractInfo={contractInfo}/>
      {checkPermission('contracts','security_deposit', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteCashAndNonCash(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
