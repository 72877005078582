import React, { Component } from 'react';
import moment from 'moment';
import {  displayDateTimeFormatShort, imgStoragePath } from '../../constants';
export default class ViewAuditLog extends Component{
  constructor(props) {
    super(props);

    this.state = {
      tableStatus:  false
    };
  }
  dataShow = () => {
    this.setState(prevState => ({
      ...prevState,
      tableStatus: !this.state.tableStatus
    }))
  }
  render(){
    const { auditLogView } = this.props;

    return(
    <div className="flex-not-centered outer-wrapper">
        <div className="first-block">
          <div className="height-block">
              <img src={imgStoragePath+"edit_audit_icn.png"} alt="img"/>
              <div className="vl"></div>
          </div>
        </div>
        <div className="scnd-block">
          <div className="shadow-block">
          <div className="flex-not-centered">
            <div className="sec-inner-frst-block">
               {auditLogView.action == 1 ?
                <h6>Updated at</h6> : <h6>Created at</h6>
               }
                <p>{ auditLogView.timestamp ?  moment(auditLogView.timestamp).format(displayDateTimeFormatShort)   : '--' }</p>
            </div>
            <div className="sec-inner-sec-block">
            {auditLogView.action == 1 ?
                <h6>Updated by</h6> : <h6>Created by</h6>
            }
                <p>{ auditLogView.user &&  auditLogView.user.name ? auditLogView.user.name : '--' }</p>
            </div>
          </div>
          <div className="show-data">
            <span onClick={this.dataShow}>Show Data 	<i style={ this.state.tableStatus ? { transform: 'rotate(180deg)' }:  { transform: 'rotate(0)' } }>&#9660;</i></span>
            <div className="edit-detail-table" style={ this.state.tableStatus ? {display: 'block'} :  {display: 'none' } }>
            <table>
              <thead>
              {auditLogView.action == 1 ?
                <tr>
                  <th style={{width: '33%'}}>Label</th>
                  <th style={{width: '33%'}}>Old Value</th>
                  <th style={{width: '33%'}}>New Value</th>
                </tr>:
                <tr>
                  <th>Label</th>
                  <th>Value</th>
                </tr>
              }

              </thead>
            { Object.keys(auditLogView.changes).map((item,index) =>
                 <tbody>
                    {auditLogView.action == 1 ?
                   <tr>
                     <td style={{textTransform: 'capitalize'}}>{item.replace(/_/g,' ')}</td>
                     <td>{auditLogView.changes[item][0]}</td>
                     <td>{auditLogView.changes[item][1]}</td>
                   </tr>:
                   <tr>
                   <td style={{textTransform: 'capitalize'}}>{item.replace(/_/g,' ')}</td>
                   <td>{auditLogView.changes[item]}</td>
                   </tr>
                    }
                 </tbody>
                )}
            </table>
            </div>
          </div>
        </div>
      </div>
  </div>

    )
  }
}
