import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const InvoiceTypeSummary = ({ invoiceTypeSummary }) => {
  var colors = ['#6395cf', '#ee7c26', '#beed87', '#00b3e6', '#999eff', '#000000', '#002acc', '#ee82ee', '#ffd700'];
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Invoice Type Summary',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        xAxis: {
            categories: invoiceTypeSummary && invoiceTypeSummary.invoice_status && invoiceTypeSummary.invoice_status.x_axis && invoiceTypeSummary.invoice_status.x_axis.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Invoices'
            }
        },
        colors: colors,
        legend: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> <br/>'
        },
        series: {
            name: 'Invoices',
            data: invoiceTypeSummary && invoiceTypeSummary.invoice_status && invoiceTypeSummary.invoice_status.y_axis,
            colorByPoint: true,
        },
        credits: {
            enabled: false
        },
        exporting: {
            buttons: {
                contextButton: { menuItems: ['downloadPNG', 'downloadPDF'],
                },
            },
            filename: 'Dashboard-Invoice Type Summary Chart',
        }
    }
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )

}
export default InvoiceTypeSummary
