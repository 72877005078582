import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { displayDateFormat } from '../../../constants'
import HighchartsReact from 'highcharts-react-official';
import { numberWithCommas, getLocalStorageInfo } from '../../../utils';
import moment from 'moment';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

function customToolTip (points){
  let tlTip = '';
  points.map(point => {
    tlTip += '<b>' + (point.series && point.series.name ? point.series.name : '') + '</b>:<br/>';
    tlTip += '<b>Date</b>: ' + moment(point.x).format(displayDateFormat) + '<br/>';
    tlTip += '<br/><b>Fund</b>: $' +  numberWithCommas(parseFloat(point.y).toFixed(2)) + '<br/>'
  });
  return tlTip
}

function utilCustomToolTip (points){
  let tlTip = '';
  tlTip += '<b>Date</b>: ' + moment(points[0].x).format(displayDateFormat) + '<br/>';
  points.map(point => {
    tlTip += '<br/><b>'+(point.series && point.series.name ? point.series.name : '')+'</b>:' +  parseFloat(point.y) + '<br/>'
  });
  return tlTip
}

class DbGraphCard extends Component{
  constructor(props){
    super(props)
  }

  componentWillReceiveProps(nextProps){
    if(this.props.activeLabel){
      if(nextProps.headingText != this.props.headingText){
        document.querySelectorAll('.highcharts-label')[document.querySelectorAll('.highcharts-label').length - 1].innerHTML = '<rect fill="none" class="highcharts-label-box" x="0.5" y="0.5" width="380" height="33" stroke="silver" stroke-width="1" rx="2" ry="2"></rect><text x="5" style="font-size:9px;" y="15"><tspan style="padding-right: 5px">' + nextProps.headingText + " of " + this.props.contractMsn + '</tspan><tspan x="5" dy="12">Lessor: ' + (getLocalStorageInfo().defaultLessor &&getLocalStorageInfo().defaultLessor.name ? getLocalStorageInfo().defaultLessor.name : '') + '</tspan></text>'
      }else{
        if(document.querySelectorAll('.highcharts-label')[5]){
          document.querySelectorAll('.highcharts-label')[5].innerHTML = '<rect fill="none" class="highcharts-label-box" x="0.5" y="0.5" width="380" height="33" stroke="silver" stroke-width="1" rx="2" ry="2"></rect><text x="5" style="font-size:9px;" y="15"><tspan style="padding-right: 5px">' +this.props.headingText + " of " + this.props.contractMsn + '</tspan><tspan x="5" dy="12">Lessor: ' + (getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.name ? getLocalStorageInfo().defaultLessor.name : '') + '</tspan></text>'
        }
      }
    }
  }

  render(){
    var color = [ '#cdd149']
    let tooltip = {};
    if(this.props.cashflowSum){
      tooltip =  {
        formatter: function () {
          return customToolTip(this.points)
        },
        shared: true
       }
    }
    if(this.props.utilSum){
      tooltip =  {
        formatter: function () {
          return utilCustomToolTip(this.points)
        },
        shared: true
       }
    }
    var headingText = this.props.headingText
    var contractMsn = this.props.contractMsn
    
    var lessorDetail = this.props.lessorDetail
    const options = {
      chart: {
        type: this.props.graphType,
        zoomType: 'x',
        height: '300',
        marginTop: 60,
        events: {
          load: function() {
            if(lessorDetail && lessorDetail.lessor_name ){
              var label = this.renderer.label( headingText + " of " + contractMsn + "<br/>Lessor: " + lessorDetail.lessor_name)
            }else{
              var label = this.renderer.label( headingText + " of " + contractMsn + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
            }
              label.css({
                width: '400px',
                fontSize: '9px'
              })
              .attr({
                'stroke': 'silver',
                'stroke-width': 1,
                'r': 2,
                'padding': 5
              })
              .add();

            label.align(Highcharts.extend(label.getBBox(), {
              align: 'left',
              x: 0, // offset
              verticalAlign: 'bottom',
              y: -250 // offset
            }), null, 'spacingBox');

          }
        }
      },
      colors:color,
      title: {
        text: this.props.graphTitle
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: this.props.graphxLabel
        }
      },
      tooltip: tooltip,
      lang: {
        noData: 'No Records Found'
      },
      noData: {
        style: {
            fontWeight: 'normal',
            fontSize: '20px',
            color: '#000',
            fontFamily: 'Conv_IBMPlexSans-Regular'
        }
      },

      yAxis: {
        title: {
          text: this.props.graphyLabel
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        },
        gridLineDashStyle: 'longdash'
      },
      series: this.props.series,
      credits: {
        enabled: false
      },
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart({ type: 'image/png' });
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    )
  }
}
export default DbGraphCard;
