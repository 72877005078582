import React, { useEffect, useState } from 'react'
import { Header, ContractHeadertext, ContractSideBar } from '../../../applications/contracts/Elements';
import { assetId, assetsType, backendDateFormat } from '../../../constants';
import { browserHistory } from 'react-router';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import { checkApiStatus, toastFlashMessage } from '../../../utils_v2';
import { PageLoader, TableListComp, EmptyCollection } from '../../../shared_elements';
import { checkString, checkValidDate, checkDateDiffText } from '../../../formValidator';
import moment from 'moment';
import { mouHeads } from '..';
import MouAddEdit from '../components/MouAddEdit';
import MouList from '../components/MouList';
import { DeleteModal } from '../../../applications/technicalInspection/Elements';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const MouListing = (props) => {
    const [modal, setModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [lessorList, setLessorList] = useState([])
    const [lesseeList, setLesseeList] = useState([])
    const [mouDetails, setMouDetails] = useState({})
    const [mouList, setMouList] = useState({})
    const [validationMsgs, setValidationMsgs] = useState({})
    const [pageLoader, setPageLoader] = useState(false)
    const [dropDownLoader, setDropDownLoader] = useState(false)
    useEffect(() => {
        getMouListingFn()
        getLesseeList()
        getLessorList()
    }, [])
    const getLesseeList = () => {
        setDropDownLoader(true)
        globalGetService(`console/lessees`)
            .then(response => {
                setDropDownLoader(false)
                setLesseeList(response.data.data)
            })
    }
    const setEditModal = (data) => {
        setModal(true)
        setValidationMsgs({})
        setMouDetails(data)
    }
    const deleteMouDetailsFn = (data) => {
        setPageLoader(true)
        globalDeleteService(`contracts/${props.params.type}/${props.params.aircraft_slug}/mou/${data?.slug}/`)
        .then(response => {
                setPageLoader(false)
                getMouListingFn()
                if (checkApiStatus(response)) {
                    toastFlashMessage(response.data.message, 'success')
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getLessorList = () => {
        setDropDownLoader(true)
        globalGetService(`console/owner/?dropdown=true`)
            .then(response => {
                setDropDownLoader(false)
                setLessorList(response.data.data)
            })
    }
    const getMouListingFn = () => {
        setPageLoader(true)
        globalGetService(`contracts/${props?.params?.type}/${props?.params?.aircraft_slug}/mou/`)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setMouList(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const updateErrorField = (key) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
    }
    const onChangeMouDetails = (key, value) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
        setMouDetails({ ...mouDetails, [key]: value })
    }
    const addEditMou = () => {
        let data = mouDetails
        let validateNewInput = {
            lessor_name: checkString({ value: data.lessor_name, required: true, minLength: '', maxLength: '', message: 'Please Select Lessor ' }),
            lessee: checkString({ value: data.lessee, required: true, minLength: '', maxLength: '', message: 'Please Select Lessee ' }),
            managed: checkString({ value: data.managed, required: true, minLength: '', maxLength: '', message: 'Please Enter Managed Information' }),
            tied_asset: checkString({ value: data.tied_asset, required: true, minLength: '', maxLength: '', message: 'Please Enter Linked Asset ' }),
            mou_date: checkValidDate({ value: data.mou_date, required: true, minLength: '', maxLength: '', message: 'Please Select MOU Date ' }),
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            if (data?.id) {
                setPageLoader(true)
                globalPutService(`contracts/${props.params.type}/${props.params.aircraft_slug}/mou/${data?.slug}/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                            setModal(false)
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                        getMouListingFn()
                    })
            } else {
                setPageLoader(true)
                globalPostService(`contracts/${props.params.type}/${props.params.aircraft_slug}/mou/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                            setModal(false)
                            toastFlashMessage(response.data.message, 'success')
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                        getMouListingFn()
                    })
            }
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }

    return (
        <div className="contract-container">
            {
                pageLoader || dropDownLoader ? <PageLoader /> : null
            }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} type="shorter" params={props.params} />
            <div className="contract-content-blk">
                <MouAddEdit
                    setValidationMsgs={setValidationMsgs}
                    updateErrorField={updateErrorField}
                    validationMsgs={validationMsgs}
                    lessorList={lessorList}
                    lesseeList={lesseeList?.lessees}
                    onChangeMouDetails={onChangeMouDetails}
                    mouDetails={mouDetails}
                    setModal={setModal}
                    modal={modal}
                    setEditModal={setEditModal}
                    addEditMou={addEditMou}
                />
                <TableListComp
                    heads={mouHeads}
                    data={mouList?.list?.map((item, index) =>
                        <MouList
                            setDeleteModal={setDeleteModal}
                            setEditModal={setEditModal}
                            item={item}
                        />
                    )}
                    noRecord={mouList?.list?.length ? null :
                        pageLoader ? null :
                            <EmptyCollection
                                title="No records found"
                            />
                    }

                />
                {
                    deleteModal?.flag ?
                        <DeleteModal isOpen={true} toggle={() => setDeleteModal(false)} title={"Delete MOU details "} className="modal-tform-delete-pictures modal-dialog-centered">
                            <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this MOU ? </p>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-danger" onClick={() => { setDeleteModal(false); deleteMouDetailsFn(deleteModal?.data); }}>Yes</button>
                                </li>
                                <li className="list-inline-item">
                                    <button type="button" className="btn btn-primary" onClick={() => setDeleteModal(false)}>No</button>
                                </li>
                            </ul>
                        </DeleteModal>
                        : null}
            </div>
        </div>
    )
}
export default RoleBasedAccess(MouListing,['contracts','mou_list','R']);