import React from 'react';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap'
import ContractHdEl from './ContractHdEl';
import { getAccessToken } from '../../../utils';
import { displayDateFormat } from '../../../constants';
const ContractLeaseInfo = (props) => {
  return (
    <ul className="list-inline generate-select-boxes flex-centered">
      {!props.leaseInfo.same_operator_lessee ?
        <ContractHdEl title="Operator">
          {props.leaseInfo.operator.logo ?
            <img style={{ height: '28px', maxWidth: '102px' }} src={props.leaseInfo.operator.logo} alt={props.leaseInfo.operator.name} />
            : props.leaseInfo.operator.name
          }
        </ContractHdEl> : null
      }
      <ContractHdEl title="Lessee">
        <span>
          {props.leaseInfo.lessee.logo ?
            <img style={{ height: '28px', maxWidth: '102px', fontSize: '12px' }} src={props.leaseInfo.lessee.logo} alt={props.leaseInfo.lessee.name} />
            : props.leaseInfo.lessee.name.length >= 20 ?
              <span>
                <p id="lesseeName">
                  {props.leaseInfo.lessee.name.slice(0, 10)}...
                </p>
                <UncontrolledTooltip placement="top" target="lesseeName" >
                  {props.leaseInfo.lessee.name}
                </UncontrolledTooltip>

              </span> : props.leaseInfo.lessee.name
          }
        </span>
      </ContractHdEl>
      <ContractHdEl title="Lease Start Date">
        <span>
          {props.leaseInfo.contractual_start_date ?
            moment(props.leaseInfo.contractual_start_date).format(displayDateFormat)
            : '--'
          }
        </span>
      </ContractHdEl>
      {
         props.leaseInfo.lease_duration_type?.value != 3 ?
         <ContractHdEl title="Lease End Date">
        <span>
          {props.leaseInfo.contractual_end_date ?
            moment(props.leaseInfo.contractual_end_date).format(displayDateFormat)
            : '--'
          }
        </span>
      </ContractHdEl>: null
         }
      {
        props.leaseInfo.lease_duration_type?.value == 3 ?<>
          <ContractHdEl title="Lease Hours">
            <span>
              {props.leaseInfo?.utilized_lease_hours ? parseFloat(props.leaseInfo.utilized_lease_hours).toFixed(2) : '--'}
            </span>
          </ContractHdEl>
          <ContractHdEl title="Lease Remaining Hours">
            <span>
              {props.leaseInfo?.utilized_lease_hours_remaining ? props.leaseInfo.utilized_lease_hours_remaining : '--'}
            </span>
          </ContractHdEl>
          </>
          :
          null
      }
          {
         props.leaseInfo.lease_duration_type?.value != 3 ?
      <ContractHdEl title="Lease Term">
        <span>{props.leaseInfo.lease_term ? props.leaseInfo.lease_term + ' Months' : '--'}</span>
      </ContractHdEl>
      :null}
    </ul>
  )
}
export default ContractLeaseInfo
