import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'react-select/dist/react-select.css';
import { withSnackbar } from 'notistack';
import { Grid, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FleetGenericFilter } from '../../Elements'
import { TableListComp, PageLoader, ExportManu, FilterComponent, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Switcher, FleetUtil, AssetTypesGraph, UtilsLesseeGraph, UtilsMonthWiseGraph, UtilsYearWiseGraph, AnnualAvgUtilsGraph } from '../components';
import { getAssetIdentifierApi, getFleetMRUtilsApi, exportFleetUtilsApi, getAssetTypeGraphApi, getMonthWiseGraphApi, getYearWiseGraphApi, getLesseeGraphApi, getAvgUtilsGraphApi, getLesseeFilterApi } from '../apiServices';
import { globalFilterTypes, mrFleetUtilsHd, fleetUtilsFilterOps } from '../';
import { assetsType, monthsRevObj } from '../../../constants'
import { checkPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
class FleetUtilization extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      skeletonLoaderAssetType:false,
      skeletonLoaderLessee:false,
      skeletonLoaderMonthWise:false,
      skeletonLoaderYearWise:false,
      skeletonLoaderAvgUtils:false,
      pageLoader:false,
      utils:{ list:[], pagination:{} },
      sort: '',
      sort_by: '',
      mode:'list',
      open:false,
      filter: {},
      genericFilter:{},
      filterAssetType:{},
      filterMonthWise:{},
      filterYearWise:{},
      filterUtilsLessee:{},
      filterAvgUtils:{},
      assetTypeGraphData:{},
      lesseeGraphData:{},
      monthWiseGraphData:{},
      yearWiseGraphData:{},
      avgUtilsGraphData:{},
      assetTypeOptions:[],
      assetModelOptions:[],
      assetsOptions:[],
      lesseeOptions:[],
      lessorOptions:[],
      ownerOptions:[],
      graphHeading:'',
      lesseeFilter:[]
    };
    this.getFleetMRUtilsApi = getFleetMRUtilsApi.bind(this);
    this.exportFleetUtilsApi = exportFleetUtilsApi.bind(this);
    this.getAssetTypeGraphApi = getAssetTypeGraphApi.bind(this);
    this.getMonthWiseGraphApi = getMonthWiseGraphApi.bind(this);
    this.getYearWiseGraphApi = getYearWiseGraphApi.bind(this);
    this.getLesseeGraphApi = getLesseeGraphApi.bind(this);
    this.getLesseeFilterApi = getLesseeFilterApi.bind(this);
    this.getAvgUtilsGraphApi = getAvgUtilsGraphApi.bind(this);
    this.getAssetIdentifierApi = getAssetIdentifierApi.bind(this);
  }
  componentDidMount(){
    const { genericFilter, filterAssetType, filterMonthWise, filterYearWise, filterUtilsLessee, filterAvgUtils } = this.state;
    this.getFleetMRUtilsApi(this.props, {per_page:50}, 'skeletonLoader');
    this.getAssetTypeGraphApi(this.props, genericFilter, filterAssetType, 'skeletonLoaderAssetType');
    this.getMonthWiseGraphApi(this.props, genericFilter, filterMonthWise, 'skeletonLoaderMonthWise');
    this.getYearWiseGraphApi(this.props, genericFilter, filterYearWise, 'skeletonLoaderYearWise');
    this.getLesseeGraphApi(this.props, genericFilter, filterUtilsLessee, 'skeletonLoaderLessee');
    this.getAvgUtilsGraphApi(this.props, genericFilter, {...filterAvgUtils}, 'skeletonLoaderAvgUtils');
    globalFilterTypes.map((item) => 
      this.getAssetIdentifierApi(item.label, item.type)
    )
    trackActivity('Page Visited',{
      application_module: 'Lease Management',
      page_title: 'Fleet Utilization List'
    })
    this.getLesseeFilterApi()
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getFleetMRUtilsApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page:50 }, 'pageLoader');
      } else {
        this.getFleetMRUtilsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
      }
    } else {
      this.getFleetMRUtilsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
    }
  }
  changeGenericFilter = (e, keyParam, value) => {
    let query = Object.assign({},this.state.genericFilter);
    if(value){
      query = {
        ...query,
        [keyParam]:value.id
      }
    }else{
      delete query[keyParam];
    }
    this.setState({genericFilter: query});
    this.applyGenericFilter(query);
  }

  applyGenericFilter = (genericFilter) => {
    const { filterAssetType, filterMonthWise, filterYearWise, filterUtilsLessee, filterAvgUtils } = this.state;
    this.getAssetTypeGraphApi(this.props, genericFilter,filterAssetType, 'skeletonLoaderAssetType');
    this.getMonthWiseGraphApi(this.props, genericFilter, filterMonthWise, 'skeletonLoaderMonthWise');
    this.getYearWiseGraphApi(this.props, genericFilter, filterYearWise, 'skeletonLoaderYearWise');
    this.getLesseeGraphApi(this.props, genericFilter, filterUtilsLessee, 'skeletonLoaderLessee');
    this.getAvgUtilsGraphApi(this.props, genericFilter, filterAvgUtils, 'skeletonLoaderAvgUtils');
  }

  onClickDataPointFn = (data, category, type) => {
    const { genericFilter, filterAssetType, filterAvgUtils, filterYearWise, filterMonthWise, filterUtilsLessee, assetTypeOptions, lesseeOptions, monthWiseGraphData } = this.state;
    if(type === 'basedOnAssetType'){
      this.setState({graphHeading:'Based on Asset Type'})
      this.getFleetMRUtilsApi(this.props, {...genericFilter,...filterAssetType, per_page:50, asset_type:assetTypeOptions.find(item => item.name === category).id}, 'pageLoader', 'underlyingData');
    }
    if(type === 'basedOnLessee'){
      this.setState({graphHeading:'Utilization by Lessee'})
      this.getFleetMRUtilsApi(this.props, {...genericFilter,...filterUtilsLessee, per_page:50, lessee_id:lesseeOptions.find(item => item.name === category).id}, 'pageLoader', 'underlyingData');
    }
    if(type === 'basedOnYear'){
      this.setState({graphHeading:'Yearly Trend'})
      this.getFleetMRUtilsApi(this.props, {...genericFilter,...filterYearWise, per_page:50, utils_from:`${category}-01-01`,utils_till:`${category}-12-31`}, 'pageLoader', 'underlyingData');
    }
    if(type === 'basedOnAnnual'){
      this.setState({graphHeading:'Annual Average Utilizations'})
      this.getFleetMRUtilsApi(this.props, {...genericFilter,...filterAvgUtils, per_page:50, utils_from:`${category}-01-01`,utils_till:`${category}-12-31`, lease_status:1}, 'pageLoader', 'underlyingData');
    }
    if(type === 'basedOnMonthly'){
      this.setState({graphHeading:monthWiseGraphData.start_year && monthWiseGraphData.end_year ? `Monthly Trend from ${monthWiseGraphData.start_year ? monthWiseGraphData.start_year : ''} to ${monthWiseGraphData.end_year ? monthWiseGraphData.end_year : ''}` : 'Monthly Trend'})
      this.getFleetMRUtilsApi(this.props, {...genericFilter,...filterMonthWise, per_page:50, monthInsight:monthsRevObj[category]}, 'pageLoader', 'underlyingData');
    }
  }

  render(){
    const { skeletonLoader, pageLoader, filter, genericFilter, filterAssetType, filterMonthWise, filterYearWise, filterUtilsLessee, filterAvgUtils, utils, sort, sort_by, mode, open, assetTypeGraphData, lesseeGraphData, monthWiseGraphData, yearWiseGraphData, avgUtilsGraphData, skeletonLoaderAssetType, skeletonLoaderMonthWise, skeletonLoaderYearWise, skeletonLoaderLessee, skeletonLoaderAvgUtils, lessorOptions, lesseeOptions, ownerOptions, assetModelOptions, assetTypeOptions, assetsOptions, graphHeading, lesseeFilter  } = this.state;
    let filterOptions = fleetUtilsFilterOps;
    filterOptions = {
      ...filterOptions,
      lessee_id: {
        ...filterOptions.lessee_id,
        options:lesseeOptions
      },
      lessor_id: {
        ...filterOptions.lessor_id,
        options: lessorOptions
      },
      owner_id: {
        ...filterOptions.owner_id,
        options: ownerOptions
      },
      asset_type: {
        ...filterOptions.asset_type,
        options: assetTypeOptions
      },
      asset_model: {
        ...filterOptions.asset_model,
        options: assetModelOptions
      },
      serial_number: {
        ...filterOptions.serial_number,
        options: assetsOptions
      }
    }
    return(
      <section className="fleet-lease-management-sec">
        <DeploymentMessage/>
        { mode == 'list' ?
          <Fragment>
            { skeletonLoader ? <STableLoader count={8} />:
              <Fragment>
                <Paper square style={{padding:'6px 10px'}}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item md={9}>
                      <FilterComponent 
                        filter={filter}
                        filterMenu={filterOptions}
                        getResponseBack={(applyFilter) => {this.getFleetMRUtilsApi(this.props, {...applyFilter, page:1, per_page:utils.pagination.per_page}, 'pageLoader');}}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <ul className='list-inline'  style={{float:'right', display:'flex'}}>
                        {utils.list.length > 0 && checkPermission('contracts', 'utilization','EXP') &&
                          <li className="list-inline-item">
                            <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetUtilsApi(this.props, {...filter, download:file.extension})} />
                          </li>
                        }
                        { checkPermission('contracts', 'utilization','ANA') ?
                          <li className='list-inline-item'>
                            <Switcher mode={mode} onChangeMode={(mode) => this.setState({mode})} />
                          </li>:null
                        }
                        
                      </ul>
                    </Grid>
                  </Grid>
                </Paper>
                <TableListComp
                  heads={mrFleetUtilsHd}
                  sort={sort}
                  sort_by={sort_by}
                  data={utils.list.map((item,index) =>
                    <FleetUtil
                      index={index}
                      item={item} />
                    )
                  }
                  createSortHandler={this.createSortHandler}
                  onChangePage={(event, newPage) => this.getFleetMRUtilsApi(this.props, {...filter, page:newPage+1, per_page:utils.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  onChangeRowsPerPage={(event) => this.getFleetMRUtilsApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                  pagination={utils.pagination}
                  noRecord={ utils.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </Fragment>
            }
          </Fragment> :
          <Fragment>
            <section className="fleet-lease-analytics-sec">
              <Paper className="generic-filter-block">
                <Grid container spacing={1}>
                  <Grid item xs={11} md={11}>
                    <FleetGenericFilter 
                      genericFilter={genericFilter}
                      lesseeOptions={lesseeOptions}
                      lessorOptions={lessorOptions}
                      assetTypeOptions={assetTypeOptions}
                      assetModelOptions={assetModelOptions}
                      assetsOptions={assetsOptions}
                      ownerOptions={ownerOptions}
                      changeGenericFilter={this.changeGenericFilter}
                      processingLoader={skeletonLoaderAssetType || skeletonLoaderLessee || skeletonLoaderMonthWise || skeletonLoaderYearWise || skeletonLoaderAvgUtils}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <Switcher mode={mode} onChangeMode={(mode) => this.setState({mode})} />
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <AssetTypesGraph 
                    assetTypeGraphData={assetTypeGraphData} 
                    filter={filterAssetType} 
                    skeletonLoader={skeletonLoaderAssetType}
                    getResponseBack={(applyFilter)=>this.getAssetTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderAssetType')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UtilsLesseeGraph 
                    lesseeGraphData={lesseeGraphData} 
                    filter={filterUtilsLessee}
                    skeletonLoader={skeletonLoaderLessee}
                    getResponseBack={(applyFilter)=>this.getLesseeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLessee')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UtilsYearWiseGraph 
                    yearWiseGraphData={yearWiseGraphData} 
                    filter={filterYearWise}
                    skeletonLoader={skeletonLoaderYearWise}
                    getResponseBack={(applyFilter)=>this.getYearWiseGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderYearWise')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UtilsMonthWiseGraph 
                    monthWiseGraphData={monthWiseGraphData} 
                    filter={filterMonthWise}
                    skeletonLoader={skeletonLoaderMonthWise}
                    getResponseBack={(applyFilter)=>this.getMonthWiseGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderMonthWise')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <AnnualAvgUtilsGraph 
                    avgUtilsGraphData={avgUtilsGraphData} 
                    filter={filterAvgUtils}
                    skeletonLoader={skeletonLoaderAvgUtils}
                    getResponseBack={(applyFilter)=>this.getAvgUtilsGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderAvgUtils')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
              </Grid>
            </section>
          </Fragment>
        }
        { open ? 
          <Dialog
            open={open}
            anchor="right"
            onClose={() => {this.setState({open:false});this.getFleetContractsApi(this.props, {per_page:50}, 'pageLoader');}}
            fullScreen
          >
            <DialogTitle id="form-dialog-title">
            {graphHeading ? graphHeading :'Underlying Data' }
              <ul className='list-inline' style={{float:'right'}}>
                <li className='list-inline-item'>
                  <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetUtilsApi(this.props, {...filter, download:file.extension})} />
                </li>
              </ul>
            </DialogTitle>
            <DialogContent dividers={true}>
                <TableListComp
                    heads={mrFleetUtilsHd}
                    data={utils.list.map((item,index) =>
                    <FleetUtil
                        index={index}
                        item={item} />
                    )
                    }
                    pagination={utils.pagination}
                    onChangePage={(event, newPage) => this.getFleetMRUtilsApi(this.props, {...filter, page:newPage+1, per_page:utils.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    onChangeRowsPerPage={(event) => this.getFleetMRUtilsApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    noRecord={ utils.list.length ? null:
                      <EmptyCollection
                          title="No records found"
                      />
                    }
                />
            </DialogContent>
            <DialogActions style={{paddingLeft:'24px',paddingRight:'24px'}}>
                <Button onClick={() => {this.setState({open:false});this.getFleetMRUtilsApi(this.props, {per_page:50}, 'pageLoader');}} color='primary' variant='outlined' size='small'>Close</Button>
            </DialogActions>
          </Dialog>:null    
        }
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}
export default withSnackbar(connect(null, null)(RoleBasedAccess(FleetUtilization,['contracts', 'utilization','R'])))
