import * as actions from '../actions';
const applicationWidget = {
  technical: 'technicalAppWiget',
  contacts: 'contractsAppWidget',
  maintenance: 'maintenanceAppWidget',
  records: 'recordsAppWidget'
}
export default function( state = {
  msnCashflowSlug: '',
  utilSumLoader: false,
  msnUtilSlug: '',
  msnUtilType: '',
  dbAssetLoader: false,
  dbContractLoader: false,
  dbFleetSummLoader: false,
  dbAircraftDisLoader: false,
  dbfleetGraphLoader: true,
  dbcashFLowTotalLoader: false,
  dbAppModLoader: false,
  upcomingEventLoader: false,
  dbInvoiceLoader: false,
  exportLoader:false,
  fileCountLoader:false,
  utilSummaryLoader: true,
  contractMsn: [],
  fleetSummaryWidget: [],
  aircraftDisWidget: [],
  technicalAppWiget: {
    loader: false,
    data: {}
  },
  contractsAppWidget: {
    loader: false,
    data: {}
  },
  maintenanceAppWidget: {
    loader: false,
    data: {}
  },
  recordsAppWidget: {
    loader: false,
    data: {}
  },
  assetsInfoWidget: [],
  oprRatingWidget: [],
  contractCardWidget: [],
  cashflowSummaryWidget: {},
  cashflowSummarydbWidget: {},
  cashflowPayment: [],
  invoiceSummaryWidget: {},
  contractExpiryWidget: [],
  utilsSummaryWidget: {},
  claimsTrackerWidget: [],
  majorAssmeblyWidget: [],
  actionDockWidget: [],
  fleetActivityList: {},
  actionActivityList: {},
  sharedContentWidget: [],
  lastActivityWidget: [],
  loactionsList: [],
  airCraftTypeList: [],
  msnActivityWidget: [],
  technicalProjectWidget: {},
  inspectionData:{},
  technicalProjectLocation:[],
  technicalInspectionData:[],
  technicalProjectAssets: []
}, action){
  switch (action.type) {
    case actions.FLEET_SUMM_WIDGET:
     return {
      ...state,
      fleetSummaryWidget: action.payload
    }
    case actions.UTIL_SUM_LOADER:
     return {
      ...state,
      utilSumLoader: action.payload
    }
    case actions.AIRCRAFT_DIS_WIDGET:
      return {
        ...state,
        aircraftDisWidget: action.payload
      }
    case actions.UTIL_DATA_UPDATE:
      return {
        ...state,
        msnUtilSlug: action.payload.msnUtilSlug ? action.payload.msnUtilSlug : '',
        msnUtilType: action.payload.msnUtilType ? action.payload.msnUtilType : '',
      }
    case actions.APPS_MOD_WIDGET:
      if(action.flag){
        return {
          ...state,
          [applicationWidget[action.appType]]: {
            ...state[applicationWidget[action.appType]],
            data: action.payload,
            loader: false
          }
        }
      }else{
        return {
          ...state,
          [applicationWidget[action.appType]]: {
            ...state[applicationWidget[action.appType]],
            data: {},
            loader: true
          }
        }
      }

    case actions.ASSETS_INFO_WIDGET:
      return {
        ...state,
        assetsInfoWidget: action.payload
      }
    case actions.OPR_RATING_WIDGET:
      return {
        ...state,
        oprRatingWidget: action.payload
      }
    case actions.CONTRACT_CARD_WIDGET:
      return {
        ...state,
        contractCardWidget: action.payload
      }
    case actions.EXPORT_DASH_LOADER:
      return {
        ...state,
        exportLoader: action.payload
      }

    case actions.CASHFLOW_SUMM_WIDGET:

      return {
        ...state,
        cashflowSummaryWidget: action.payload.response,
        msnCashflowSlug: action.payload.queryParam.slug
      }
    case actions.CASHFLOW_SUMM_DB_WIDGET:

      return {
        ...state,
        cashflowSummarydbWidget: action.payload.response,
        msnCashflowSlug: action.payload.queryParam.slug
      }
    case actions.CASHFLOW_PAYMENT_WIDGET:
      return {
        ...state,
        cashflowPayment: action.payload
      }
    case actions.INVOICE_SUMM_WIDGET:
      return {
        ...state,
        invoiceSummaryWidget: action.payload.response,

      }
    case actions.CONTRACT_EXPIRY_WIDGET:
      return {
        ...state,
        contractExpiryWidget: action.payload
      }
    case actions.UTIL_SUMMARY_WIDGET:
      return {
         ...state,
         utilsSummaryWidget: action.payload.response,
      }
    case actions.UTIL_TREND_WIDGET:
      return state
    case actions.CLAIM_TRACKER_WIDGET:
      return {
        ...state,
        claimsTrackerWidget: action.payload
      }
    case actions.MAJOR_ASSEMBLY_WIDGET:
      return {
        ...state,
        majorAssmeblyWidget: action.payload
      }
    case actions.ACTION_DOCK_WIDGET:
      return {
        ...state,
        actionDockWidget: action.payload
      }
    case actions.FILE_COUNT_LOADER:
      return {
        ...state,
        fileCountLoader: action.payload
      }
      case actions.FLEET_ACTIVITY_LIST:
      return {
        ...state,
        fleetActivityList: action.payload
      }
      case actions.ACTION_ACTIVITY_LIST:
      return {
        ...state,
        actionActivityList: action.payload
      }

    case actions.SHARED_CONTENT_WIDGET:
      return {
        ...state,
        sharedContentWidget: action.payload
      }
    case actions.LAST_ACTIVITY_WIDGET:
      return state
    case actions.MSN_ACTIVITY_WIDGET:
      return state
    case actions.CONTRACT_MSN:
      return {
        ...state,
        contractMsn: action.payload
      }
    case actions.UPDATE_DB_LOADER:
      return {
        ...state,
        [action.loaderType]: action.payload
      }
      case actions.MSN_UPDATE_FILTER:
      return {
        ...state,
        [action.payload.type]: action.payload.slug
      }
      case actions.TECHNICAL_AIRCRAFT:
      return{
        ...state,
        airCraftTypeList:action.payload
      }
      case actions.TECHNICAL_LOCATION:
      let list = action.payload;
      list.unshift({value: "All", label: "All Locations"});
      return{
        ...state,
        loactionsList:list
      }
      case actions.TECHNICAL_INSPECTION_FINDINGS:
        return{
          ...state,
          inspectionData:action.payload
        }
      case actions.TECHNICAL_PROJECT_WIDGET:
        return {
          ...state,
          technicalProjectWidget: action.payload
        }
      case actions.TECHNICAL_PROJECT_LOCATION:
        return {
          ...state,
          technicalProjectLocation: action.payload
        }
      case actions.TECHNICAL_INSPECTION_TYPE:
        return {
          ...state,
          technicalInspectionData: action.payload
        }
      case actions.TECHNICAL_PROJECT_ASSETS:
        return {
          ...state,
          technicalProjectAssets: action.payload
        }

    default:
      return state;
  }
}
