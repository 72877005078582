import React, { Component } from 'react';
import { withRouter, browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip } from '@material-ui/core';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader, RevisionHistory } from '../../../../shared_elements';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { GroupUserList, MemberCRU, TeamCRU } from '../components';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { sExportContractsAc } from '../../../actionCreators';
import { getWorkingGroupsListApi} from '../apiServices';
import { assetsType } from '../../../../constants';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import { memberHd } from '../';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class WorkingGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      workingGroups: [],
      deleteIds:[],
    }
    this.getWorkingGroupsListApi = getWorkingGroupsListApi.bind(this);

  }
  componentDidMount() {
    this.getWorkingGroupsListApi(this.props);
    this.props.fetchContractInfo();
    trackActivity('Page Visited', {
      page_title: 'Working Groups'
    })
  }
  getResponseBack = () => {
    this.getWorkingGroupsListApi(this.props);
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { workingGroups, skeletonLoader, pageLoader } = this.state;
    const { contractSummaryLoader, params, contractInfo } = this.props;
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Induction Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='working-group' primaryTab='basic-betails' secondaryTab='workingGroups' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctworkinggroups/'
            params={params}
            buttonText={ checkPermission('contracts','working_groups', 'C') ? <TeamCRU getResponseBack={this.getResponseBack} contractInfo={contractInfo?.hdlineInfo}/> : null}
            handleClickEventFn={() => this.togglingModal('teamModal')}
            queryParams={{ action: '0, 1', contract: this.props.params.contract_slug }}
          >
            {skeletonLoader ? (<STableLoader count={1} />) :
              workingGroups.length ?
                workingGroups.map((group, index) =>
                  <ExpansionPanel className="working-groups-section" key={index}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={`group${group.id}`}
                    >
                      {group.name}
                      <ul className="list-inline" style={{ marginLeft: 'auto' }} onClick={event => { event.stopPropagation(); event.preventDefault() }}>
                        {checkPermission('contracts','working_groups', 'U') ?
                          <li className="list-inline-item">
                            <TeamCRU m="E" id={group.id} contractInfo={contractInfo?.hdlineInfo} getResponseBack={this.getResponseBack} />
                          </li> : null}
                        {checkPermission('contracts','working_groups', 'C') ?
                          <li className="list-inline-item">
                            <MemberCRU contractInfo={contractInfo} m="A" groupId={group.id} getResponseBack={this.getResponseBack} />
                          </li> : null}
                        <li className="list-inline-item" onClick={event => event.stopPropagation()}>
                          <RevisionHistory
                            url='audit/contracts/ctworkinggroupsmembers/'
                            queryParams={{ action: '0, 1', working_group: group.id }}
                            buttonType={true}
                          />
                        </li>
                      </ul>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <TableListComp
                        heads={memberHd}
                        noRecord={group.working_group_member.length ? null : <h4>No Team Member</h4>}
                        data={group.working_group_member.map((member, index) =>
                          <GroupUserList
                            key={index}
                            groupId={group.id}
                            getResponseBack={this.getResponseBack}
                            member={member}
                            contractInfo={contractInfo}
                          />
                        )}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : <EmptyCollection title="No records found" />}
            {pageLoader || contractSummaryLoader ? <PageLoader /> : null}
          </ContractWrapper>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(WorkingGroups,['contracts','working_groups', 'R'])));
