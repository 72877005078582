import React, { Component, Fragment } from 'react';
import { Drawer, IconButton, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import EmptyCollection from './EmptyCollection';
import PageLoader from './PageLoader';
import RevisionEditCard from './RevisionEditCard';
import RevisionAddCard from './RevisionAddCard';
import { imgStoragePath } from '../../constants';
import { getRevisionHistoryApi } from '../apiServices';
import revisionHistoryIcon from '../../shared_elements/assets/img/revision_history_icon.svg';
import CloseIcon from '@material-ui/icons/Close';
class RevisionHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      open:false,
      pageLoader:false,
      revisionHistories:[]
    }
    this.getRevisionHistoryApi = getRevisionHistoryApi.bind(this);
  }
  render(){
    const { open, pageLoader, revisionHistories } = this.state;
    const { url, queryParams, buttonType } = this.props;
    return(
      <Fragment>
        { buttonType ?
          <Tooltip title="Revision History" arrow>
            <IconButton size="small" color="primary" onClick={() => this.getRevisionHistoryApi(url, queryParams)}>
              <HistoryIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>:
          <span className="rev-history-title"  onClick={() => this.getRevisionHistoryApi(url, queryParams)}><img className="audit-img" src={revisionHistoryIcon} alt="img"/>Revision History</span>
        }
        <Drawer anchor="right" open={open} onClose={() => this.setState({open:false, revisionHistories:[]})}>
          <div className="edit-revision-history" style={{width:'670px'}}>
            <h4 className="rev-history-header">Revision History
              <CloseIcon className="close-icon" onClick={()=> this.setState({open:false})}/>
            </h4>
            <ul className="list-unstyled">
              { revisionHistories.map((item, index) =>
                <li>
                  <span className="timeline-icon"><img src={`${imgStoragePath}${item.action === 1 ? 'edit_audit_icn.png':'add_audit_icn.png'}`} alt="icon" /></span>
                  { item.action === 0 ?
                    <RevisionAddCard item={item} />: <RevisionEditCard item={item} />
                  }
                </li>
              )}
            </ul>
            { !revisionHistories.length ? <EmptyCollection title="No records found" description="" />:null }
          </div>
        </Drawer>
        { pageLoader ? <PageLoader />:null}
      </Fragment>
    )
  }
}
export default RevisionHistory;
