import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import moment from 'moment';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import { displayDateFormatShort } from '../../../../constants';
import SubleaseCRU from './SubleaseCRU';
export default function SubleaseList({item, previewDetail, getResponseBack, contractInfo,deleteSubLease}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.sublease_lessee}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>{item.details}</TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.end_date ? moment(item.end_date).format(displayDateFormatShort): '--'}</TableCell>
      <TableCell>
        <SubleaseCRU getResponseBack={getResponseBack} m="V" id={item.id} contractInfo={contractInfo}/>
        {checkPermission('contracts','sublease', 'D') ? <DeleteIcon fontSize='small' color='secondary' style= {{cursor:'pointer'}} onClick={()=>deleteSubLease(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
