import React from 'react';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
const InvoiceGross = ({invoiceInfo}) => {
  return(
    <div className="invoice-gross-total">
      <h5>Billable Amount:
        <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency, shortFormat:false})}>
          {invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.billable_amount),0)}
        </NumericLabel>
      </h5>
      <h5>Tax:
        <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency, shortFormat:false})}>
          {invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.tax_amount),0)}
        </NumericLabel>
      </h5>
      <h4>Total:
        <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency, shortFormat:false})}>
          {invoiceInfo.invoice_items.reduce((total, next) => total + parseFloat(next.sub_total),0)}
        </NumericLabel>
      </h4>
    </div>
  )
}
export default InvoiceGross;
