import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { browserHistory } from 'react-router'
import { displayDateFormat } from '../../../constants';
import { dateTransform } from '../../../utils';
const ContractExpiryCard = (props) => {
  const goToContracts = () =>{
    var assetRedirectTypes = {
      1: 'aircraft',
      2: 'engine',
      3: 'apu',
      4: 'lg',
      5: 'propeller',
      6: 'fan',
      7: 'hpc',
      8: 'hpt',
      9: 'lpt'
    }
    browserHistory.push(`/${assetRedirectTypes[props.contract.asset.asset_type]}/${props.contract.asset.slug}/contracts`)
  }

  const moduleTypes = {
    6: 'Fan',
    7: 'HPC',
    8: 'HPT',
    9: 'LPT'
  }

  return (
    <div className="expiry-card" style={{cursor: 'pointer'}} onClick={() => goToContracts()}>
      <div className="asset-info">
        <h4>
          {props.contract.asset.asset_type === 1 ?
            "MSN"
            :props.contract.asset.asset_type === 3 ?
            "APU"
            :props.contract.asset.asset_type === 4 ?
            "LG"
            :props.contract.asset.asset_type === 5 ?
            "PROPELLER"
            :"ESN"
          }
          &nbsp;{props.contract.asset.msn ? props.contract.asset.msn : props.contract.asset.esn ? props.contract.asset.esn : props.contract.asset.serial_number} {props.contract.asset.asset_type > 5 ? `(${moduleTypes[props.contract.asset.asset_type]})` : ''}</h4>
        <h5>{dateTransform(moment(),props.contract.contractual_end_date)}  remaining</h5>
      </div>
      <div className="contract-info">
        <div className="lessee-info">
          <h6>Lessee</h6>
          <p>{props.contract && props.contract.lessee && props.contract.lessee.name ? props.contract.lessee.name : ''}</p>
        </div>
        <Row>
          <Col md="6">
            <h6>Start Date</h6>
            <p>{moment(props.contract.contractual_start_date).format(displayDateFormat)}</p>
          </Col>
          <Col md="6">
            <h6>End Date</h6>
            <p>{moment(props.contract.contractual_end_date).format(displayDateFormat)}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default ContractExpiryCard;
