import React, { Component } from 'react';
export default class PrerequisiteWrapper extends Component {
render() {
  return(
    <div className="prerequisite">
     
    </div>
    );
  }
}
