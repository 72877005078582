import React from 'react';
import { TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import UtilsSummaryList from './UtilsSummaryList';
import { mrUtilsSummaryHd } from '../'
const UtilizationSummary = ({utlisAverage}) => {
  return(
    <div>
      <div>
        <TableListComp
          heads={mrUtilsSummaryHd}
          data={utlisAverage.map((item,index) =>
            <UtilsSummaryList
              key={index}
              item={item}
            />
          )}
          noRecord={ utlisAverage.length ? null:
            <EmptyCollection
              title="No records found"
            />
          }
        />
      </div>
    </div>
  )
}
export default UtilizationSummary;
