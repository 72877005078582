import React, {Component}  from 'react';
class FormHeader extends Component{  
  render(){
    
    return(
      <div className="form-header" id="form-header">
      
      </div>
    )
  }
}


  export default FormHeader;
