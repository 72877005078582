import React, { Component, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import { Link, browserHistory } from 'react-router';
import GetInputField from '../../../applications/technicalInspection/Elements/GetInputField';
import { checkLoginForm } from '../../../formValidator/loginForm';
import { globalSignUpPostService } from '../../../globalServices';
import { toastFlashMessage, setGlobalCookie, checkEnvironment,eraseGlobalCookie} from '../../../utils'
import config from '../../../config';
class LoginForm extends Component {
  constructor(props) {
    setTimeout(() => {
      window.location.href = `${config.domain.subDomian}login?apps=lease`;
    }, 2000);
    super(props);
    this.state = {
      userInfo: {
        email: '',
        password: '',
      },
      error: {},
      loader: false,
      showPassword: false,
      showMaintenancePopup: false
    };
  }

  handleUserChange = (key, value) => {
    value = value.trim();
    this.setState(prevState => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));
    if (key === 'email') {
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          emailMsg: ''
        }
      }));
    }
    if (key === 'password') {
      this.setState(prevState => ({
        ...prevState,
        error: {
          ...prevState.error,
          passwordMsg: ''
        }
      }));
    }
  }
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  loginSubmit = (e) => {
    e.preventDefault();
    const { userInfo } = this.state;
    const validateNewInput = checkLoginForm({ email: userInfo.email, password: userInfo.password });
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.setState(prevState => ({
        ...prevState,
        loader: true,
      }));
      globalSignUpPostService('/api/login/', userInfo)
        .then(response => {
          this.setState(prevState => ({
            ...prevState,
            loader: false,
          }));
          if (response.data.statusCode == 200) {
            localStorage.setItem('userInfo', JSON.stringify(response.data.data));
            if (response.data.data.lessor_list.length) {
              let baseDomain = '.sparta.aero';
              let expireAfter = new Date();
              expireAfter.setDate(expireAfter.getDate() + 3);
              let cookieData = JSON.stringify({ access: response.data.data.access, environment: checkEnvironment() })
              setGlobalCookie("lessorAccess", cookieData, 3)
              setGlobalCookie("domain", baseDomain, 3)
              localStorage.setItem('userInfo', JSON.stringify(response.data.data));
            }
            if (response.data.data.lessor_list.length > 1) {
              browserHistory.push('/select-lessor')
            } else {
              this.props.authorization(response.data.data.lessor_list[0])
            }
          } else if (response.data.statusCode == 1012) {
            toastFlashMessage(response.data.message, 'success');
            browserHistory.push('/otp-verification/' + response.data.data.token)
            localStorage.setItem('userEmail', userInfo.email)
          } else if (response.data.statusCode == 1008) {
            this.setState({
              error: {
                emailMsg: response.data.validation_error.email ? response.data.validation_error.email : "",
                passwordMsg: response.data.validation_error.password ? response.data.validation_error.password : "",
              }
            });
          }
        });
    } else {
      this.setState({
        error: validateNewInput
      });
    }
  }

  confirmMaintenanceNotice = () => {
    this.setState({ showMaintenancePopup: false })
  }

  render() {
    const { userInfo, error } = this.state;
    return (
      <div className="login-form"> 
        <span className="warning-info-text" style={{ fontSize: '16px', display: 'none' }}>We are doing scheduled maintenance, will be back in 30 minutes.</span>
        <h2 className="form-title">Login to your account</h2>
        <div>
          <form onSubmit={this.loginSubmit}>
            <TextField
              className="email-textfield"
              type="add"
              id="email"
              label="Registered Email Address"
              placeholder="johndoe@doe.com"
              fullWidth
              margin="normal"
              value={userInfo.email}
              error={error.emailMsg ? true : false}
              helperText={error.emailMsg ? error.emailMsg : ''}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => { this.handleUserChange('email', e.target.value) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="password"
              placeholder="Enter Your Password"
              type={this.state.showPassword ? 'text' : 'password'}
              label={
                <p>Password</p>
              }
              fullWidth
              margin="normal"
              value={userInfo.password}
              error={error.passwordMsg ? true : false}
              helperText={error.passwordMsg ? error.passwordMsg : ''}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => { this.setState({ showPassword: !this.state.showPassword }) }}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
              }}
              onChange={(e) => { this.handleUserChange('password', e.target.value) }}
            />
            <Fragment>
              <Link href="/forgot-password" target="_blank" className="forgot-password">Forgot password ? </Link>
            </Fragment>
            <div className="text-center-link">
              <p className="terms-and-conditions">
                By clicking Sign In, you agree to our
                <a href="/termscondition" target="_blank" > Terms, </a>
                <a href="/data-policy" target="_blank">Data Policy </a> <br /> and
                <a href="https://www.acumen.aero/policy" target="_blank"> Privacy Policy</a>.
              </p>
              <input type="submit" style={this.state.loader ? { pointerEvents: 'none' } : { pointerEvents: 'unset' }} value={this.state.loader ? "Processing..." : "Login"} />
            </div>
          </form>
        </div>
        <Modal isOpen={this.state.showMaintenancePopup} toggle={() => this.setState({ showMaintenancePopup: true })}>
          <ModalHeader>Attention</ModalHeader>
          <ModalBody>
            <p>We acknowledge the inconvenience caused by the sudden disruption of the SPARTA platform from 30 May - 31 May 2021. It was caused due to an outage of a critical service component at the service provider’s data centre. As of today, the issue has been resolved and SPARTA system functions are running as smoothly as before. However, the technical team is continuing its efforts to ensure that the issue does not repeat, While this is not expected to cause complete disruption to the SPARTA System, you may experience intermittency for brief period. We appreciate your kind support and patience in this regard. </p>
          </ModalBody>
          <ModalFooter>
            <button style={{ float: 'right' }} onClick={() => this.confirmMaintenanceNotice()} type="button" className="btn btn-primary">Okay</button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default LoginForm
