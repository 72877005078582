import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, browserHistory } from 'react-router';
import {Grid} from '@material-ui/core';
import { TableListComp, LabelValueCard, PageLoader } from '../../../shared_elements';
import { BasicDetail, LessorInfo, LesseeInfo,  BankDetail, EditMRInvoiceList, EditRentalInvoiceList, EditCreditDebitList, PenaltyInvoiceList, SecurityDespoitInvoiceItems, InsuranceInvoiceItems, InvoiceGross, InvoiceBackNav, AdhocInvoiceItem } from '../components';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { sExportContractsAc } from '../../actionCreators';
import { getLocalStorageInfo } from '../../../utils_v2';
import { getInvoiceApi, exportInvoiceApi } from '../apiServices';
import { assetsType } from '../../../constants';
import { creditDebitHd, penaltyHd, mntRentalHd } from '../';
class InvoiceView extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      invoiceInfo:{}
    }
    this.getInvoiceApi = getInvoiceApi.bind(this);
    this.exportInvoiceApi = exportInvoiceApi.bind(this);
  }
  componentDidMount(){
    this.getInvoiceApi(this.props);
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { invoiceInfo, pageLoader } = this.state;
    return(
      <section className="invoice-detail-section">
        <div className="mr-rate-wrapper  mr-rate-spacing">
          <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          {
            invoiceInfo && Object.keys(invoiceInfo).length ?
              <ContractSideBar application={`Invoice View - ${invoiceInfo.invoice_number}`} type="brief" params={this.props.params} />
            :null
          }
          <div className="view-block" style={{background:'none'}}>
            <InvoiceBackNav mode="view" invoiceInfo={invoiceInfo} exportInvoiceFn={(file) => this.exportInvoiceApi(this.props, invoiceInfo.invoice_number, {download:file.extension})} />
            <div>
              <BasicDetail getResponseBack={() => this.getInvoiceApi(this.props)} mode="view" invoiceInfo={invoiceInfo} />
              <Grid container spacing={3} style={{marginBottom:'10px'}}>
                <LessorInfo mode="view" invoiceInfo={invoiceInfo} />
                <LesseeInfo mode="view" invoiceInfo={invoiceInfo} />
              </Grid>
              { invoiceInfo.invoice_type === 2 && invoiceInfo.rental_description && invoiceInfo.rental_description !=='' ?
                <Grid container spacing={3}>
                  <LabelValueCard md={3} label='Description' value={invoiceInfo.rental_description} />
                </Grid>:null
              }
            </div>
            { Object.keys(invoiceInfo).length ?
              <div>
                { invoiceInfo.invoice_type === 1 ?
                  <div className="">
                    <TableListComp
                      heads={mntRentalHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <EditMRInvoiceList mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 2 ?
                  <div className="">
                    { invoiceInfo.invoice_type === 2 && <h5 style={{fontSize:'16px', color:'#9439e4', marginBottom:'8px', marginTop:'10px'}}>{invoiceInfo.currency?invoiceInfo.currency:'USD'} Rental Receivable</h5> }
                    <div className="">
                      <TableListComp
                        heads={mntRentalHd}
                        data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                          <EditRentalInvoiceList
                            invoiceInfo={invoiceInfo}
                            mode="view"
                            invoice_item={item}
                          />
                        )}
                      />
                    </div>
                  </div>:null
                }
                { Object.keys(invoiceInfo).length && invoiceInfo.invoice_type === 3 ?
                  <div style={{marginBottom:'15px'}}>
                    <TableListComp
                      heads={penaltyHd}
                      data={
                        <PenaltyInvoiceList
                          mode="view"
                          invoiceInfo={invoiceInfo}
                        />
                      }
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 4 || invoiceInfo.invoice_type === 5 ?
                  <div>
                    <TableListComp
                      heads={creditDebitHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <EditCreditDebitList mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 6 ?
                  <div>
                    <TableListComp
                      heads={[
                        {label:'Item', sortOption:false},
                        {label:'Security Against', sortOption:false},
                        {label:'Security Deposit Type', sortOption:false},
                        {label:'Security Deposit Amount', sortOption:false},
                        {label:'Sub Total', sortOption:false},
                      ]}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <SecurityDespoitInvoiceItems invoiceInfo={invoiceInfo} mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 7 || invoiceInfo.invoice_type === 8 ?
                  <div>
                    <TableListComp
                      heads={[
                        {label:'Item', sortOption:false},
                        {label:'Description', sortOption:false},
                        {label:'Insurance Type', sortOption:false},
                        {label:'Insurance Amount', sortOption:false},
                        {label:'Sub Total', sortOption:false},
                      ]}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <InsuranceInvoiceItems invoiceInfo={invoiceInfo} mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 9 ?
                  <div className="">
                    <TableListComp
                      heads={mntRentalHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <AdhocInvoiceItem mode="view" invoice_item={item} />
                      )}
                    />
                  </div>
                  :null
                }
                { Object.keys(invoiceInfo).length && invoiceInfo.invoice_type !== 3 ?
                  <InvoiceGross invoiceInfo={invoiceInfo} />:null
                }
              </div>:null
            }

            <div>
              <BankDetail mode="view" bankDetail={invoiceInfo} />
            </div>
            { invoiceInfo.invoice_type === 2 && invoiceInfo.children && invoiceInfo.children.length ?
              <div className="">
                { invoiceInfo.children.map((children, childIndex) =>
                  <div className="">
                    <h5 style={{fontSize:'16px', color:'#9439e4', marginBottom:'8px', marginTop:'10px'}} className="para currency-break">{children.currency} Rental Receivable</h5>
                    <TableListComp
                      heads={mntRentalHd}
                      data={children.invoice_items.map((item, index) =>
                        <EditRentalInvoiceList invoiceInfo={invoiceInfo} mode="view" invoice_item={item} />
                      )}
                    />
                    <InvoiceGross invoiceInfo={children} />
                    <BankDetail mode="view" bankDetail={children} />
                  </div>
                )}
              </div>:null
            }
          </div>
        </div>
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceExportLoader:state.InvoiceReducer.invoiceExportLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(InvoiceView));
