import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
const UtilsSummaryList = ({item}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.hours}</TableCell>
      <TableCell>{item.cycles}</TableCell>
      <TableCell>{item.tsn}</TableCell>
      <TableCell>{item.csn}</TableCell>
    </TableRow>
  )
}
export default UtilsSummaryList;
