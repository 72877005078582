import React, { Fragment } from 'react';
import { TableRow, TableCell, TextField, IconButton, Tooltip } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { getCurrencyFormat } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
const AdhocInvoiceItem = ({invoice_item, mode, onFieldChange}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        {invoice_item.invoice_item_type_id.name}
      </TableCell>
      <TableCell style={{width:'180px'}}>
        { mode === 'view' ? invoice_item.description ? invoice_item.description:'--':
          <TextField
            id="description"
            fullWidth
            margin="normal"
            value={invoice_item.description}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 255 }}
            multiline
            rows="4"
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.unit_type?invoice_item.unit_type:'--':
          <TextField
            id="unit_type"
            fullWidth
            margin="normal"
            value={invoice_item.unit_type}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => { onFieldChange(e, 'unit_type', e.target.value)}}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.no_of_unit?invoice_item.no_of_unit:'--':
          <TextField
            id="no_of_unit"
            fullWidth
            margin="normal"
            value={invoice_item.no_of_unit}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'no_of_unit', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.rate_per_unit ? <NumericLabel params={getCurrencyFormat()}>{invoice_item.rate_per_unit}</NumericLabel>:'--':
          <TextField
            id="rate_per_unit"
            fullWidth
            margin="normal"
            value={invoice_item.rate_per_unit}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'rate_per_unit', e.target.value):e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell style={{width:'110px'}}>
        { mode === 'view' ? invoice_item.tax_rate ? <NumericLabel params={getCurrencyFormat()}>{invoice_item.tax_rate}</NumericLabel>:'0':
          <TextField
            id="tax_rate"
            fullWidth
            margin="normal"
            value={invoice_item.tax_rate}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 6 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'tax_rate', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell>
        <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.tax_amount ? invoice_item.tax_amount:0}</NumericLabel>
      </TableCell>
      <TableCell>
        { mode === 'view' ? <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.sub_total?invoice_item.sub_total:0}</NumericLabel>:
          <TextField
            id="sub_total"
            fullWidth
            margin="normal"
            value={invoice_item.sub_total}
            InputLabelProps={{shrink: true}}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'sub_total', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
    </TableRow>
  )
}
export default AdhocInvoiceItem;
