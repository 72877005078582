import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditSidebar, Header, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import InsuranceRow from '../components/InsuranceRow';
import AddEditInsurance from '../components/AddEditInsurance';
import AddEditClaim from '../components/AddEditClaim';
import ClaimRow from '../components/ClaimRow';
import { insuranceListAc, claimListAc, insuranceCrudFnAc, claimCrudFnAc, addEditInsuranceAc, addEditClaimAc } from '../actionCreators';
import { INSURANCE_CRUD, TOGGLE_VIEW_EDIT, INSURANCE_CLAIM_LOADER } from '../actions';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { NoRecordFound, ListLoader } from '../../../../shared';
import { withRouter, browserHistory, Link } from 'react-router';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { sAuditLogAc, sGlobalConstantAc } from '../../../../shared/actionCreators';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { imgStoragePath, assetsType } from '../../../../constants';
import { globalGetService } from '../../../../globalServices';
import addIcon from '../../../../shared/assets/img/add_icon.svg';
import revisionHistoryIcon from '../../../../shared/assets/img/revision_history_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class Insurance extends Component {
  constructor(props){
    super(props);
    this.state = {
      insurance: [],
      approvers: []
    }
  }
  componentDidMount() {
    this.props.fetchInsuranceList();
    this.props.fetchClaimList();
    this.fetchInsuranceDropdown()
    this.fetchApproverDropdown()
    this.props.fetchTechConstant({constant_types:['insurance_type', 'claim_status']});
  }

  fetchApproverDropdown = () => {
    globalGetService(`/console/get-user-list/?group_ids=23&filter_type=security_group`)
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState({
          approvers: response.data.data.list
        })
      }
    })
  }

  fetchInsuranceDropdown = () =>{
    this.props.toggleLoader(true)
    globalGetService(`/contracts/${this.props.params.contract_slug}/insurance-list/`)
    .then(response => {
      this.props.toggleLoader(false)
      if(response.data.statusCode === 200){
        this.setState({
          insurance: response.data.data.insurance_list
        })
      }
    })
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { insuraneList, assetViewId, claimList, insuranceCrud, claimCrud, techConstants, insuranceLoader, auditLogView, params} = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} pageTitle="Insurance & Claims" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar  application="Insurance" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          <ContractHeadertext
              title="Insurance"
              desc="Section holds Insurance & Insurance Claims of the asset under this Lease"
              addNew = ""
           />
           { insuranceLoader ?
           <ListLoader/>: null
           }
           <div className="list-container">
             <div className="table-container">
               <h5>
                 Insurance
                 {
                   insuraneList.length ?
                     <span className="in-audit-history" onClick = {() => {this.props.toggleAuditlogFn('contracts', 'ctinsurances', 0, null, 'add',this.props.params.contract_slug,'contract'); trackActivity('Revision History', { page_title: 'Insurance', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Insurance'})}} >
                       <img style={{marginBottom:'10px'}} src={revisionHistoryIcon} alt="img"/><span style={{marginLeft:'5px', textTransform:'uppercase'}}>Revision History </span>
                     </span>
                   :null
                 }
                 {
                   getLocalStorageInfo().user.permission['contracts']['insurance'].indexOf('C') != -1 ?
                   <span className="flex-centered" onClick={() => this.props.insuranceCrudFn('add', {}, 'insuranceCrud')}>
                     <img  src={addIcon} alt="icon" /> <span className="add">ADD </span>
                   </span>:null
                 }
               </h5>
               <table>
                 <CtrTableHd theads={['Type', 'Insurance Number', 'Insurer', 'Insuree', 'Policy Period', 'Amount (USD)']}/>
                 <tbody>
                   { insuraneList && insuraneList.length ?  insuraneList.map((insurance,index) => <InsuranceRow key={index} insurance={insurance} insuranceView={() => this.props.insuranceCrudFn('view', insurance.id, 'insuranceCrud')} />):null}
                 </tbody>
               </table>
               {
                 !insuranceLoader && !insuraneList.length ?
                 <NoRecordFound description="Get Started by clicking '+ Add' button above" />:null
               }
             </div>
             <div className="table-container">
               <h5>
                 Insurance Claim History
                 {
                   insuraneList.length ?
                     <span className="in-audit-history" onClick = {() => {this.props.toggleAuditlogFn('contracts', 'ctinsuranceclaim', 0, null, 'add',this.props.params.contract_slug,'contract'); trackActivity('Revision History', { page_title: 'Claims', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Insurance Claims'})}} >
                       <img style={{marginBottom:'10px'}} src={revisionHistoryIcon} alt="img"/><span style={{marginLeft:'5px', textTransform:'uppercase'}}>Revision History </span>
                     </span>
                   :null
                 }
                 {
                   getLocalStorageInfo().user.permission['contracts']['insurance'].indexOf('C') != -1 && insuraneList.length ?
                   <span className="flex-centered" onClick = {() => {this.props.insuranceCrudFn('add', {}, 'claimCrud');this.fetchInsuranceDropdown();}}>
                     <img  src={addIcon} alt="icon" /> <span className="add">ADD</span>
                   </span>:null
                 }

               </h5>
               <table>
                 <CtrTableHd theads={['Insurance Number	','Claim Number', 'Insurer', 'Claim Details', 'Date of Claim Closure', 'Status', 'Amount (USD)']}/>
                 <tbody>
                   {claimList && claimList.length ? claimList.map((claim,index) => <ClaimRow key={index} claim={claim} claimView={() => this.props.insuranceCrudFn('view', claim.id, 'claimCrud')} />): null}
                 </tbody>
               </table>
               {
                 !insuranceLoader && !claimList.length ?
                 <NoRecordFound description="Get Started by clicking '+ Add' button above" />:null
               }
             </div>
           </div>
        </div>
        <EditSidebar width="560px" right="-560px"
          title={
            insuranceCrud.type != '' ?
            (insuranceCrud.type == 'add') ? 'Add Insurance':
            (insuranceCrud.type == 'edit') ? 'Edit Insurance': 'View Insurance' : ''
            }
          editSideBar={insuranceCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'insuranceCrud')}
          >
          {
            Object.keys(insuranceCrud.data).length && insuranceCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctinsurances', 1, insuranceCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Insurance', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Insurance', item_id:insuranceCrud.data.id})}}
                type={insuranceCrud.type}
                changeViewMode={()=>this.props.changeViewMode(insuranceCrud.type, 'insuranceCrud')}
                editAble = {getLocalStorageInfo().user.permission['contracts']['insurance'].indexOf('U') != -1 && insuranceCrud.data.is_editable === true ? true:false}
              />
            {
              !insuranceCrud.data.is_editable && insuranceCrud.type !== 'add' ? <span style={{marginLeft: '25px'}} className="warning-info-text">Please note Insurance Number is non editable post approved claim or raised invoice..</span> : null
            }
              <AddEditInsurance
                type={insuranceCrud.type}
                contract={this.props.assetViewId}
                techConstants={techConstants}
                insuranceObj={insuranceCrud.data}
                addEditInsurance={(data, type)=>this.props.addEditInsurance(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'insuranceCrud')}
              />
            </div>:null
          }
        </EditSidebar>
        <EditSidebar width="560px" right="-560px"
          title={
            claimCrud.type != '' ?
            (claimCrud.type == 'add') ? 'Add Claim':
            (claimCrud.type == 'edit') ? 'Edit Claim': 'View Claim' : ''
            }
          editSideBar={claimCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'claimCrud')}
          >
          {
            Object.keys(claimCrud.data).length && claimCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctinsuranceclaim', 1, claimCrud.data.id, 'update' ); trackActivity('Revision History', { page_title: 'Claims', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Claim', item_id:claimCrud.data.id})}}
                type={claimCrud.type}
                changeViewMode={()=>this.props.changeViewMode(claimCrud.type, 'claimCrud')}
                editAble = {claimCrud.data.status && (claimCrud.data.status.value !== 3 && claimCrud.data.status.value !== 4) }
              />
              {
                (claimCrud.data.status.value === 3 || claimCrud.data.status.value === 4) && claimCrud.type === 'view'  ? <span style={{marginLeft: '25px'}} className="warning-info-text">Please note Claim's is editable only when in review.</span> : null
              }
              <AddEditClaim
                techConstants={techConstants}
                contract={this.props.assetViewId.contract}
                type={claimCrud.type}
                approvers={this.state.approvers}
                insurance={this.state.insurance}
                claimObj={claimCrud.data}
                addEditClaim={(data, type)=>this.props.addEditClaim(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'claimCrud')}
              />
            </div>:null
          }
        </EditSidebar>

          <EditSidebar width="560px" right="-560px"
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  insuraneList: state.ContrInsuraneReducer.insuraneList,
  insuranceCrud: state.ContrInsuraneReducer.insuranceCrud,
  claimList: state.ContrInsuraneReducer.claimList,
  claimCrud: state.ContrInsuraneReducer.claimCrud,
  insuranceLoader: state.ContrInsuraneReducer.insuranceLoader,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchInsuranceList: () => dispatch(insuranceListAc(ownProps.params)),
    fetchClaimList: () => dispatch(claimListAc(ownProps.params)),
    addEditInsurance: (data, type, assetViewId) => dispatch(addEditInsuranceAc(ownProps.params, data, type, assetViewId)),
    addEditClaim: (data, type, assetViewId) => dispatch(addEditClaimAc(ownProps.params, data,type, assetViewId)),
    insuranceCrudFn: (type, id, actionType) => dispatch(insuranceCrudFnAc(ownProps.params, type, id, actionType)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    changeViewMode: (type, actionType) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      actionType:actionType,
      payload: type
    }),
    toggleEditBar: (data,actionType) => dispatch({
      type: INSURANCE_CRUD,
      actionType:actionType,
      payload: data
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    toggleLoader: (flag) => dispatch({
      type: INSURANCE_CLAIM_LOADER,
      payload: flag
    }),
    toggleAuditlogFn: (application, content_type, action, objectId, type,pramKey=null,paramValue=null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type,pramKey,paramValue)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(Insurance, ['contracts', 'insurance' ,'R'])));
