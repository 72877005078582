import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { getLocalStorageInfo } from '../../../../utils'
import InvFields from './InvFields'
import { jetStreamInstance } from '../../../../lease_management/Leases'

export default class LesseeInfo extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { type, invoiceInfo, toLessor } = this.props
    if(type === 'view'){
      return(
        <Col md="6" >
          <Row className="margin-0 inner-wrap"
            style={{background: '#fafafa', padding: '0 15px', marginRight: '15px'}}
            >
            <Col md="12" className="heading-block">
              {
                toLessor == 'true' ?  
                <h4 className="para-ui">To ({jetStreamInstance?'Lessor/Holdco':'Lessor'})</h4> : 
              <h4 className="para-ui">To (Lessee)</h4>
              }
            </Col>
            <InvFields label = "Name" colSize = "12" value={invoiceInfo.to_company_name?invoiceInfo.to_company_name:'--'}  />
            <InvFields label = "Address" colSize = "12" value={invoiceInfo.to_company_address?invoiceInfo.to_company_address:'--'}  />
            {![14, 242].includes(getLocalStorageInfo().defaultLessor.id)?
              <InvFields label = "Contact (Email and Phone)" colSize = "12" value={invoiceInfo.to_company_contact?invoiceInfo.to_company_contact:'--'}  />: null
            }

          </Row>
        </Col>
      )
    }else{
      return(
        <Col md={{ size: 5,  offset: 2 }} >
        <Row className="margin-0 inner-wrap">
          <Col md="12" className="heading-block">
              <h4 className="para-ui">To (Lessee)</h4>
            </Col>
          <Col md="12"  >
                <div className="form-group">
                  <label className="label"> Name</label>
                  <input type="text" value={invoiceInfo.to_company_name}
                    onChange={(e) => this.props.updateInvoiceField(e.target.value, 'to_company_name')}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="label"> Address</label>
                  <textarea type="text" value={invoiceInfo.to_company_address}
                      onChange={(e) => this.props.updateInvoiceField(e.target.value, 'to_company_address')}
                  ></textarea>
                </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="label"> Contact (Email and Phone)</label>
                <input type="text" value={invoiceInfo.to_company_contact}
                         onChange={(e) => this.props.updateInvoiceField(e.target.value, 'to_company_contact')}
                />
              </div>
            </Col>
          </Row>
        </Col>
      )
    }
  }
}
