import React, { Fragment, useEffect, useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, Tooltip, Chip } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort, invoiceStatusDrpdwn } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { LabelValueCard } from '../../../shared_elements';
import { Cancel, Check, Edit, Info, } from '@material-ui/icons';
import { DeleteModal } from '../../../applications/technicalInspection/Elements';
import { browserHistory, withRouter, Link } from 'react-router';
import backArrowIcon from '../../../shared_elements/assets/img/back_arrow.svg';
import { globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkPermission, getLocalStorageInfo, toastFlashMessage } from '../../../utils_v2';
export default function AssemblyLeaseDetails({ props, assemblyLeaseDetails, assignmentmsnList, updateErrorField, validationMsgs, majorAssembliesList, onLeaseDetailsChange, editAssemblyLeaseDetailsFn, setEditFlag, editFlag, getAssemblyLeaseDetailsFn, setAttatchedDoc, attachedDocList, getAssemblyLeaseAttachment }) {
    const [toggle, setToggle] = useState(true)
    const [deleteAttachmentmodal, setDeleteAttachmentmodal] = useState(false)
    const [deleteItem, setDeleteItem] = useState('')
    const [previewDocs, setPreviewDocs] = useState('')
    useEffect(() => {
        setToggle(false)
    }, [])
    let assembly_swap_type = assemblyLeaseDetails?.assembly_swap?.value?.toString()
    let utilization_unit_type = assemblyLeaseDetails?.utilization_unit?.value?.toString()
    const previewDoc = (item) => {
        setPreviewDocs(item)
    }
    const handleDelete = (item) => {
        if (attachedDocList.length > 1) {
            setDeleteAttachmentmodal(!deleteAttachmentmodal); setDeleteItem(item)
        }
        else {
            toastFlashMessage('At least one document is required', 'error')
        }
    }
    const deleteAttachment = (item) => {
        setDeleteAttachmentmodal(false)
        globalDeleteService(`contracts/attachments/${deleteItem?.uuid}/`)
            .then(response => {
                if (response.data.statusCode == 200) {
                    toastFlashMessage(response.data.message, 'success')
                    getAssemblyLeaseAttachment()
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    return (
        <Fragment>
            { checkPermission('assembly_lease','assembly_lease_details','U') && assemblyLeaseDetails?.assembly_lease_expired ?
                <p className="assembly-past-mode" > Please unlink the major assembly as the assembly lease has been expired </p> : null
            }
            <div style={{ marginBottom: '10px' }}>
                <Link to={"/" + props?.params.type + "/" + props?.params.aircraft_slug + "/assembly-lease"} style={{ color: '#bd10e0', fontSize: '16px', fontWeight: '700' }}>
                    <img width="16" src={backArrowIcon} alt="Back Icon" style={{ marginRight: '3px' }} /> Back to Listing
                </Link>
            </div>

            <div style={{ float: 'right', cursor: 'pointer', padding: '10px' }} > 
                {editFlag ?
                    <div>
                        <Button color='primary' style={{ marginRight: "10px" }} onClick={() => { setEditFlag(false); getAssemblyLeaseDetailsFn() }} size='small'> Cancel</Button>
                        <Button color='primary' variant='contained' onClick={() => { editAssemblyLeaseDetailsFn() }} size='small' > Save</Button>
                    </div>
                : assemblyLeaseDetails?.lease_status?.value != 2 && checkPermission('assembly_lease','assembly_lease_details','U') ?
                    <Tooltip title="Edit" arrow placement='bottom'>
                        <Edit style={{ fontSize: '24px' }} color='primary' onClick={() => setEditFlag(true)} />
                    </Tooltip>
                : null
            }  </div>
            {
                editFlag == false ?
                    <Paper style={{ padding: '10px' }}>
                        <Grid container spacing='3' style={{ width: 'auto' }} >
                            <LabelValueCard md={3} label='Assignment MSN' value={assemblyLeaseDetails?.assignment_msn?.unique_name ? assemblyLeaseDetails?.assignment_msn?.unique_name + ' (' + assemblyLeaseDetails?.assignment_msn?.lessor_name?.name + ')' : '--'} />
                            <LabelValueCard md={3} label='Major Assembly' value={assemblyLeaseDetails?.major_assembly?.unique_name ? assemblyLeaseDetails?.major_assembly?.lessor?.name ? assemblyLeaseDetails?.major_assembly?.unique_name + ' (' + assemblyLeaseDetails?.major_assembly?.lessor?.name + ')' : assemblyLeaseDetails?.major_assembly?.unique_name : '--'} />
                            <LabelValueCard md={3} label='Position' value={assemblyLeaseDetails?.position?.label ? assemblyLeaseDetails?.position?.label : '--'} />
                            <LabelValueCard md={3} label='Removal Date' value={assemblyLeaseDetails?.removal_date ? assemblyLeaseDetails?.removal_date : '--'} />
                            <LabelValueCard md={3} label='Removal TSN' value={assemblyLeaseDetails?.removal_tsn ? assemblyLeaseDetails?.removal_tsn : '--'} />
                            <LabelValueCard md={3} label='Removal CSN' value={assemblyLeaseDetails?.removal_csn ? assemblyLeaseDetails?.removal_csn : '--'} />
                            <LabelValueCard md={3} label='Installation  Date' value={assemblyLeaseDetails?.installation_date ? assemblyLeaseDetails?.installation_date : '--'} />
                            <LabelValueCard md={3} label='Installation  TSN' value={assemblyLeaseDetails?.installation_tsn ? assemblyLeaseDetails?.installation_tsn : '--'} />
                            <LabelValueCard md={3} label='Installation  CSN' value={assemblyLeaseDetails?.installation_csn ? assemblyLeaseDetails?.installation_csn : '--'} />
                            <LabelValueCard md={3} label='Last Overhaul  Date' value={assemblyLeaseDetails?.last_overhaul_date ? assemblyLeaseDetails?.last_overhaul_date : '--'} />
                            <LabelValueCard md={3} label='Last Overhaul  TSN' value={assemblyLeaseDetails?.last_overhaul_tsn ? assemblyLeaseDetails?.last_overhaul_tsn : '--'} />
                            <LabelValueCard md={3} label='Last Overhaul  CSN' value={assemblyLeaseDetails?.last_overhaul_csn ? assemblyLeaseDetails?.last_overhaul_csn : '--'} />
                            <LabelValueCard md={12} label='Emails' value={assemblyLeaseDetails?.emails ? assemblyLeaseDetails?.emails?.split(',').map(item => <Chip variant="outlined" size="small" label={item} style={{ marginRight: '5px' }} />) : '--'} />
                            <Grid xs='12'>
                                <FormControl style={{ marginTop: '16px', marginLeft: '5px' }}>
                                    <FormLabel style={{ fontSize: '13px' }}>Lease Type</FormLabel>
                                    <RadioGroup row aria-label="assembly-type" name="radio-assembly-type" value={assembly_swap_type ? assembly_swap_type : null} >
                                        <FormControlLabel value={'1'} control={<Radio disabled />} label="Temporary Lease" />
                                        <FormControlLabel value={'0'} control={<Radio disabled />} label="Permanent Swap" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {
                                assemblyLeaseDetails?.assembly_swap?.value == '1' ?
                                    <>
                                        <LabelValueCard md={3} label='Lease Start Date' value={assemblyLeaseDetails?.lease_start_date ? assemblyLeaseDetails?.lease_start_date : '--'} />
                                        <LabelValueCard md={3} label='Lease End Date' value={assemblyLeaseDetails?.lease_end_date ? assemblyLeaseDetails?.lease_end_date : '--'} />
                                        <LabelValueCard md={2} label='Daily Rate' value={assemblyLeaseDetails?.daily_rate ? assemblyLeaseDetails?.daily_rate : '--'} />
                                        <LabelValueCard md={2} label='Utilization Rate' value={assemblyLeaseDetails?.utilization_rate ? assemblyLeaseDetails?.utilization_rate : '--'} />
                                        <Grid item sm='2' xs='12'>
                                            <FormControl>
                                                <FormLabel style={{ fontSize: '14px', color: '#a0a0a0' }}>Utilization Unit</FormLabel>
                                                <RadioGroup row aria-label="gender" name="gender1" value={utilization_unit_type ? utilization_unit_type : null} >
                                                    <FormControlLabel value="2" control={<Radio disabled />} label="FH" />
                                                    <FormControlLabel value="3" control={<Radio disabled />} label="FC" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} className='label-value-card'>
                                            <p >Lease Extension (Days)</p>
                                             <h6>{assemblyLeaseDetails?.lease_extension ?
                                             <>
                                              {assemblyLeaseDetails?.lease_extension }
                                              {assemblyLeaseDetails?.lease_extended_date ? <div style={{fontSize:"12px", marginTop:'5px'}}> Extended Lease End Date : { assemblyLeaseDetails?.lease_extended_date ? assemblyLeaseDetails?.lease_extended_date : '--'}</div> : null}</>
                                              :'--'}</h6>
                                        </Grid>
                                        <LabelValueCard md={3} label='Lease Termination ' value={assemblyLeaseDetails?.termination_date ? assemblyLeaseDetails?.termination_date : '--'} />
                                    </> :
                                    <>
                                        <LabelValueCard md={3} label='Sale Date' value={assemblyLeaseDetails?.sale_date ? assemblyLeaseDetails?.sale_date : '--'} />
                                        <LabelValueCard md={3} label='TSN' value={assemblyLeaseDetails?.sale_tsn ? assemblyLeaseDetails?.sale_tsn : '--'} />
                                        <LabelValueCard md={3} label='CSN' value={assemblyLeaseDetails?.sale_csn ? assemblyLeaseDetails?.sale_csn : '--'} />
                                        <LabelValueCard md={3} label='Sale Price' value={assemblyLeaseDetails?.sale_price ? assemblyLeaseDetails?.sale_price : '--'} />
                                        <LabelValueCard md={3} label='Sold To' value={assemblyLeaseDetails?.sold_to?.name ? assemblyLeaseDetails?.sold_to?.name : '--'} />
                                    </>}
                            <Grid item xs='12'>
                                <FormLabel> Attachments :</FormLabel><br />
                                {attachedDocList.map(i =>
                                    <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                                        <Chip
                                            style={{ marginRight: '4px', marginTop: '7px' }}
                                            variant="outlined"
                                            size="small"
                                            // onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                            label={i.name.substring(0, 14) + '...'}
                                            clickable
                                            color="primary"
                                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                                        />
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    :
                    <Paper style={{ padding: '15px' }} className='assembly-details-section'>
                        <Grid container spacing='3'>
                            <Grid item sm='9' >
                                <Grid container spacing='2'>
                                    <Grid item sm='4' xs='12'>
                                        <Autocomplete
                                            style={{ marginTop: '16px' }}
                                            options={assignmentmsnList}
                                            disabled={true}
                                            getOptionLabel={option => option?.lessor_name?.name ? option.unique_name + ' (' + option?.lessor_name?.name + ')' : option.unique_name}
                                            value={assemblyLeaseDetails?.assignment_msn ? assemblyLeaseDetails?.assignment_msn : null}
                                            id="assignment_msn"
                                            onChange={(e, value) => onLeaseDetailsChange('assignment_msn', value)}
                                            renderInput={params => <TextField required error={validationMsgs.assignment_msn ? true : false} helperText={validationMsgs?.assignment_msn ? validationMsgs?.assignment_msn : ''} onFocus={() => updateErrorField('assignment_msn')} style={{ padding: '0px' }} {...params} label="Assignment MSN" placeholder="Select Assignment MSN" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <Autocomplete
                                            style={{ marginTop: '16px' }}
                                            options={majorAssembliesList}
                                            disabled={true}
                                            getOptionLabel={option => option?.lessor?.name ? option.unique_name + ' (' + option?.lessor?.name + ')' : option.unique_name}
                                            value={assemblyLeaseDetails?.major_assembly}
                                            id="major_assembly"
                                            onChange={(e, value) => onLeaseDetailsChange('major_assembly', value)}
                                            renderInput={params => <TextField required {...params} error={validationMsgs.major_assembly ? true : false} helperText={validationMsgs?.major_assembly ? validationMsgs?.major_assembly : ''} onFocus={() => updateErrorField('major_assembly')} label="Major Assembly" placeholder="Select Major Assembly" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Position'
                                            id="position"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.position?.label ? assemblyLeaseDetails?.position?.label : ''}
                                            variant='outlined'
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                required
                                                variant='outlined'
                                                margin="none"
                                                id="date"
                                                maxDate={moment()}
                                                label="Removal Date"
                                                value={assemblyLeaseDetails?.removal_date ? assemblyLeaseDetails?.removal_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.removal_date ? true : false}
                                                helperText={validationMsgs.removal_date ? validationMsgs.removal_date : ''}
                                                onFocus={() => updateErrorField('removal_date')}
                                                onChange={(data, value) => { onLeaseDetailsChange('removal_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Removal TSN'
                                            id="removaltsn"
                                            fullWidth
                                            placeholder='Please Enter Removal TSN'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.removal_tsn ? assemblyLeaseDetails?.removal_tsn : null}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.removal_tsn ? true : false}
                                            helperText={validationMsgs.removal_tsn ? validationMsgs.removal_tsn : ''}
                                            onFocus={() => updateErrorField('removal_tsn')}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('removal_tsn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Removal CSN'
                                            id="removaltsn"
                                            placeholder='Please Enter Removal CSN'
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.removal_csn ? assemblyLeaseDetails?.removal_csn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.removal_csn ? true : false}
                                            helperText={validationMsgs.removal_csn ? validationMsgs.removal_csn : ''}
                                            onFocus={() => updateErrorField('removal_csn')}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('removal_csn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                required
                                                margin="none"
                                                id="date"
                                                maxDate={moment()}
                                                label="Installation Date"
                                                value={assemblyLeaseDetails?.installation_date ? assemblyLeaseDetails?.installation_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.installation_date ? true : false}
                                                onFocus={() => updateErrorField('installation_date')}
                                                helperText={validationMsgs.installation_date ? validationMsgs.installation_date : ''}
                                                onChange={(data, value) => { onLeaseDetailsChange('installation_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            placeholder='Please Enter Installation TSN'
                                            label='Installation TSN'
                                            id="removaltsn"
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.installation_tsn ? assemblyLeaseDetails?.installation_tsn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.installation_tsn ? true : false}
                                            onFocus={() => updateErrorField('installation_tsn')}
                                            helperText={validationMsgs.installation_tsn ? validationMsgs.installation_tsn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('installation_tsn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Installation CSN'
                                            id="removaltsn"
                                            placeholder='Please Enter Installation CSN'
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.installation_csn ? assemblyLeaseDetails?.installation_csn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.installation_csn ? true : false}
                                            onFocus={() => updateErrorField('installation_csn')}
                                            helperText={validationMsgs.installation_csn ? validationMsgs.installation_csn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('installation_csn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                required
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                margin="none"
                                                id="date"
                                                maxDate={moment()}
                                                label="Last Overhaul Date"
                                                value={assemblyLeaseDetails?.last_overhaul_date ? assemblyLeaseDetails?.last_overhaul_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.last_overhaul_date ? true : false}
                                                onFocus={() => updateErrorField('last_overhaul_date')}
                                                helperText={validationMsgs.last_overhaul_date ? validationMsgs.last_overhaul_date : ''}
                                                onChange={(data, value) => { onLeaseDetailsChange('last_overhaul_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Last Overhaul TSN'
                                            id="removaltsn"
                                            fullWidth
                                            placeholder='Please Enter Last Overhaul TSN'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.last_overhaul_tsn ? assemblyLeaseDetails?.last_overhaul_tsn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.last_overhaul_tsn ? true : false}
                                            onFocus={() => updateErrorField('last_overhaul_tsn')}
                                            helperText={validationMsgs.last_overhaul_tsn ? validationMsgs.last_overhaul_tsn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('last_overhaul_tsn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='4' xs='12'>
                                        <TextField
                                            required
                                            label='Last Overhaul CSN'
                                            id="removaltsn"
                                            fullWidth
                                            placeholder='Please Enter Last Overhaul CSN'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.last_overhaul_csn ? assemblyLeaseDetails?.last_overhaul_csn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.last_overhaul_csn ? true : false}
                                            onFocus={() => updateErrorField('last_overhaul_csn')}
                                            helperText={validationMsgs.last_overhaul_csn ? validationMsgs.last_overhaul_csn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('last_overhaul_csn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item xs='12'>
                                        <TextField
                                            label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                                Emails *
                                                <Tooltip title='To enter multiple Emails seperate them with comma (,) operator' placement='right' arrow>
                                                    <Info />
                                                </Tooltip>
                                            </div>}
                                            id="emails"
                                            placeholder='Please Enter Emails'
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.emails ? assemblyLeaseDetails?.emails : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                            error={validationMsgs.emails ? true : false}
                                            onFocus={() => updateErrorField('emails')}
                                            helperText={validationMsgs.emails ? validationMsgs.emails : ''}
                                            onChange={(e) => onLeaseDetailsChange('emails', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid xs='12'>
                                    <FormControl style={{ marginTop: '16px', marginLeft: '5px' }}>
                                        <FormLabel style={{ fontSize: '13px' }}>Lease Type</FormLabel>
                                        <RadioGroup row aria-label="assembly-type" name="radio-assembly-type" value={assembly_swap_type ? assembly_swap_type : null} >
                                            <FormControlLabel value={'1'} control={<Radio disabled />} label="Temporary Lease" />
                                            <FormControlLabel value={'0'} control={<Radio disabled />} label="Permanent Swap" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item sm='3' >
                                <div style={{ height: '500px', overflow: 'scroll' }}>
                                    <FormLabel style={{ fontSize: '13px', margin: '4px' }} error={validationMsgs?.attatchedDoc ? true : false} > Attachments *</FormLabel>
                                    <p className='error-msg'> {validationMsgs?.attatchedDoc}</p>
                                    <DropzoneArea
                                        filesLimit={10}
                                        acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        useChipsForPreview
                                        maxFileSize={5000000000000}
                                        dropzoneText={<p>Drag & Drop Documents<br /> OR <br /> Click Here</p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => setAttatchedDoc(files)}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                    >
                                    </DropzoneArea>
                                    {attachedDocList.map(i =>
                                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                                            <Chip
                                                style={{ marginTop: '4px', display: 'flex', justifyContent: 'space-between', width: '144px' }}
                                                variant="outlined"
                                                size="small"
                                                onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                                label={i.name.substring(0, 14) + '...'}
                                                clickable
                                                color="primary"
                                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            </Grid>
                            {assemblyLeaseDetails?.assembly_swap?.value == '1' ?
                                <>
                                    <Grid item sm='3' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                required
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                margin="none"
                                                id="date"
                                                label="Lease Start Date"
                                                value={assemblyLeaseDetails?.lease_start_date ? assemblyLeaseDetails?.lease_start_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.lease_start_date ? true : false}
                                                onFocus={() => updateErrorField('lease_start_date')}
                                                helperText={validationMsgs.lease_start_date ? validationMsgs.lease_start_date : ''}
                                                onChange={(data, value) => { onLeaseDetailsChange('lease_start_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                required
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                margin="none"
                                                id="date"
                                                label="Lease End Date"
                                                value={assemblyLeaseDetails?.lease_end_date ? assemblyLeaseDetails?.lease_end_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.lease_end_date ? true : false}
                                                onFocus={() => updateErrorField('lease_end_date')}
                                                helperText={validationMsgs.lease_end_date ? validationMsgs.lease_end_date : ''}
                                                onChange={(data, value) => { onLeaseDetailsChange('lease_end_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            required
                                            label='Daily Rate'
                                            placeholder='Enter Rates in USD'
                                            id="daily_rate"
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.daily_rate ? assemblyLeaseDetails?.daily_rate : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            error={validationMsgs.daily_rate ? true : false}
                                            onFocus={() => updateErrorField('daily_rate')}
                                            helperText={validationMsgs.daily_rate ? validationMsgs.daily_rate : ''}
                                            onChange={(e) => onLeaseDetailsChange('daily_rate', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm='3' xs='12'></Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            required
                                            placeholder='Enter Rates in USD'
                                            label='Utilization Rate'
                                            id="utilization_rate"
                                            fullWidth
                                            margin="normal"
                                            value={assemblyLeaseDetails?.utilization_rate ? assemblyLeaseDetails?.utilization_rate : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            error={validationMsgs.utilization_rate ? true : false}
                                            onFocus={() => updateErrorField('utilization_rate')}
                                            helperText={validationMsgs.utilization_rate ? validationMsgs.utilization_rate : ''}
                                            inputProps={{ maxLength: 255 }}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('utilization_rate', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='2' xs='12'>
                                        <FormControl style={{ marginTop: '16px', marginLeft: '5px' }}>
                                            <FormLabel style={{ fontSize: '13px' }}>Utilization Unit</FormLabel>
                                            <RadioGroup row aria-label="gender" name="gender1" value={utilization_unit_type ? utilization_unit_type : null} >
                                                <FormControlLabel value="2" control={<Radio disabled />} label="FH" />
                                                <FormControlLabel value="3" control={<Radio disabled />} label="FC" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            label='Lease Extension (Days)'
                                            id="lease_extension"
                                            fullWidth
                                            margin="normal"
                                            placeholder='Lease Extension (Days)'
                                            value={assemblyLeaseDetails?.lease_extension ? assemblyLeaseDetails?.lease_extension : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onLeaseDetailsChange('lease_extension', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                margin="none"
                                                id="date"
                                                maxDate={moment()}
                                                label="Lease Termination"
                                                value={assemblyLeaseDetails?.termination_date ? assemblyLeaseDetails?.termination_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(data, value) => { onLeaseDetailsChange('termination_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item sm='3' xs='12'>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder='DD-MM-YYYY'
                                                required
                                                style={{ position: "relative", top: '16px' }}
                                                inputVariant="outlined"
                                                margin="none"
                                                id="date"
                                                maxDate={moment()}
                                                label="Sale Date"
                                                value={assemblyLeaseDetails?.sale_date ? assemblyLeaseDetails?.sale_date : null}
                                                format={fieldDateFormat}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.sale_date ? true : false}
                                                onFocus={() => updateErrorField('sale_date')}
                                                helperText={validationMsgs.sale_date ? validationMsgs.sale_date : ''}
                                                inputProps={{ maxLength: 255 }}
                                                onChange={(data, value) => { onLeaseDetailsChange('sale_date', moment(data).format(backendDateFormat)) }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            required
                                            label=' TSN'
                                            id="tsn"
                                            fullWidth
                                            placeholder='Please Enter TSN'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.sale_tsn ? assemblyLeaseDetails?.sale_tsn : null}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.sale_tsn ? true : false}
                                            onFocus={() => updateErrorField('sale_tsn')}
                                            helperText={validationMsgs.sale_tsn ? validationMsgs.sale_tsn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_tsn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            required
                                            label=' CSN'
                                            id="csn"
                                            fullWidth
                                            placeholder='Please Enter CSN'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.sale_csn ? assemblyLeaseDetails?.sale_csn : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.sale_csn ? true : false}
                                            onFocus={() => updateErrorField('sale_csn')}
                                            helperText={validationMsgs.sale_csn ? validationMsgs.sale_csn : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_csn', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            required
                                            label=' Sale Price'
                                            id="sale_price"
                                            fullWidth
                                            placeholder='Enter Sale Price in USD'
                                            margin="normal"
                                            value={assemblyLeaseDetails?.sale_price ? assemblyLeaseDetails?.sale_price : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            error={validationMsgs.sale_price ? true : false}
                                            onFocus={() => updateErrorField('sale_price')}
                                            helperText={validationMsgs.sale_price ? validationMsgs.sale_price : ''}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_price', e.target.value) : e.preventDefault()}
                                        />
                                    </Grid>
                                    <Grid item sm='3' xs='12'>
                                        <TextField
                                            label='Sold To'
                                            id="sold_to"
                                            fullWidth
                                            margin="normal"
                                            disabled={true}
                                            value={assemblyLeaseDetails?.sold_to?.name ? assemblyLeaseDetails?.sold_to?.name : assemblyLeaseDetails?.sold_to ? assemblyLeaseDetails?.sold_to : ''}
                                            variant='outlined'
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    </Grid>

                                </>
                            }
                        </Grid>
                    </Paper>
            }
            {
                previewDocs ?
                    <Modal isOpen='open' style={{ width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight: '95vh', height: '95vh', marginTop: '0px' }}>
                        <ModalHeader>{previewDocs.name}</ModalHeader>
                        <ModalBody style={{ height: '91vh', maxHeight: '83vh' }}>
                            {previewDocs.extension == 'csv' ?
                                <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ height: '78vh', width: '-webkit-fill-available' }} >
                                </object>
                                :
                                ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ?
                                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(previewDocs.url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                                    :
                                    ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ?
                                        <div style={{ backgroundImage: "url(" + previewDocs.url + ")", height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroudPosition: 'center' }}> </div>
                                        :
                                        previewDocs.extension == 'pdf' ?
                                            <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                                                <param name="type" value="text/html" />
                                            </object> :
                                            null
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='contained' color='primary' onClick={(e) => window.open(previewDocs.url)}> Download</Button>
                            <Button variant='contained' color='primary' onClick={(e) => setPreviewDocs('')}>Okay</Button>
                        </ModalFooter>
                    </Modal> : null
            }
            {
                deleteAttachmentmodal ?
                    <DeleteModal isOpen={deleteAttachmentmodal} className="modal-dialog-centered"  >
                        <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this Attachment
                            <Tooltip title={deleteItem.name} arrow placement='top' style={{ marginTop: '3px' }}>
                                <div>
                                    <Chip variant="outlined" size="small" label={deleteItem.name.substring(0, 30) + '...'} color="primary" />
                                </div>
                            </Tooltip>
                        </p>
                        <ul className="list-inline" style={{ textAlign: 'center' }}>
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm add-new" onClick={deleteAttachment}>Yes</button>
                            </li>
                            <li className="list-inline-item">
                                <button className="btn btn-danger btn-sm add-new" onClick={() => setDeleteAttachmentmodal(false)} >No</button>
                            </li>
                        </ul>
                    </DeleteModal> :
                    null
            }
        </Fragment>
    )
}
