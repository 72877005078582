import React from 'react'
import { Grid, Paper } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
export default function CashflowTopCards({ cashflowWidget }) {
  let daily_rate = cashflowWidget?.daily_rate?.daily_credit_total + cashflowWidget?.daily_rate?.daily_debit_total
  let utilization = cashflowWidget?.utilization?.util_credit_total + cashflowWidget?.utilization?.util_debit_total
  return (
    <div className="assembly-cashflow-container">
      <Grid container spacing={2} style={{minHeight:'110px'}}>
        <Grid item xs={12} md={2}>
          <div className="assembly-cashflow-card">
            <h3>Daily Rate</h3>
            <p>
              <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{daily_rate ? daily_rate : 0}</NumericLabel>
            </p>
            {/* {cashflowWidget?.daily_rate?.mr_as_of_date ? <span className="date">As of {cashflowWidget.daily_rate.mr_as_of_date}</span>:null} */}
          </div>
        </Grid>

        <Grid item xs={12} md={2}>
          <div className="assembly-cashflow-card">
            <h3>Utilization </h3>
            <p>
              <NumericLabel params={getCurrencyFormat({ shortFormat: false })}>{utilization? utilization : 0}</NumericLabel>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>

  )
}
