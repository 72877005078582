import React, { Component, Fragment } from "react";
import config from '../../../config';
class Onboarding extends Component {
  constructor(props) {
    window.location.href = `${config.domain.subDomian}login?apps=lease`;
  }
  render() {
    return (
      <Fragment></Fragment>
    );
  }
}


export default Onboarding;
