import React, { useState, useEffect } from 'react'
import { checkApiStatus, toastFlashMessage, checkPermission } from '../../../../utils_v2'
import AddEditSupplement from './AddEditSupplement'
import moment from 'moment';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../../globalServices';
import { checkString, checkValidDate } from '../../../../formValidator';
import { amendmentHd, supplementHd } from '..';
import SupplementList from './SupplementList';
import { EmptyCollection, PageLoader, TableListComp } from '../../../../shared_elements';
import AddEditAmendment from './AddEditAmendment';
import AmendmentList from './AmendmentList';
import { assetId } from '../../../../constants';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Chip, Paper, Tooltip } from '@material-ui/core';
import { DeleteModal } from '../../../technicalInspection/Elements';
export default function SuplmntandAmndmnt({leaseInfo, props }) {

    const [modal, setModal] = useState(false)
    const [amendmentModal, setAmendmentModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [supplementDetails, setSupplementDetails] = useState({})
    const [amendmentDetails, setAmendmentDetails] = useState({})
    const [attatchedDoc, setAttatchedDoc] = useState({})
    const [amendmentDoc, setAmendmentDoc] = useState({})
    const [supplementList, setSupplementList] = useState({})
    const [amendmentList, setAmendmentList] = useState({})
    const [validationMsgs, setValidationMsgs] = useState({})
    const [previewDocs, setPreviewDocs] = useState('')
    const [pageLoader, setPageLoader] = useState(false)
    const [deleteAttachmentmodal, setDeleteAttachmentmodal] = useState(false)
    const [attachmentLoader, setAttachmentLoader] = useState(false)
    const [dropDownLoader, setDropDownLoader] = useState(false)
    const [deleteItem, setDeleteItem] = useState('')
    const [deleteDetailsmodal, setDeleteDetailsmodal] = useState(false)
    useEffect(() => {
        getSupplementListingFn()
        getAmendmentListingFn()
    }, [])

    const setEditModal = (data) => {
        setModal(true)
        setValidationMsgs({})
        setSupplementDetails(data)
    }
    const previewDoc = (data) => {
        setPreviewDocs(data)
    }
    const setEditAmendmentModal = (data) => {
        setAmendmentModal(true)
        setValidationMsgs({})
        setAmendmentDetails(data)
    }
    const handleDelete = (item) => {
            setDeleteAttachmentmodal(!deleteAttachmentmodal); 
            setDeleteItem(item)
    }
    const deleteAttachment = (item) => {
        setDeleteAttachmentmodal(false)
        setPageLoader(true)
        globalDeleteService(`contracts/attachments/${deleteItem?.uuid}/`)
        .then(response => {
                setPageLoader(false)
                setAmendmentDetails({...amendmentDetails, attachments:{}})
                setSupplementDetails({...supplementDetails, attachments:{}})
                getSupplementListingFn()
                getAmendmentListingFn()
                if (response.data.statusCode == 200) {
                    toastFlashMessage(response.data.message, 'success') 
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getAmendmentListingFn = () => {
        setPageLoader(true)
        globalGetService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/amendment/`)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setAmendmentList(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getSupplementListingFn = () => {
        setPageLoader(true)
        globalGetService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/supplement/`)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    setSupplementList(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }

    const updateErrorField = (key) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
    }
    const onChangeAmendmentDetails = (key, value) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
        setAmendmentDetails({ ...amendmentDetails, [key]: value })
    }
    const onChangeSupplementDetails = (key, value) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
        setSupplementDetails({ ...supplementDetails, [key]: value })
    }
    const deleteAmendmentDetailsFn =(data, type)=>{
        setDeleteDetailsmodal(false)
        setPageLoader(true)
        if(type== 'amendment'){
            globalDeleteService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/amendment/${data?.slug}/`)
            .then(response=>{
                getAmendmentListingFn()
                setPageLoader(false)
                if(checkApiStatus(response)){
                    toastFlashMessage(response.data.message, 'success')
                }
                else{
                    toastFlashMessage(response.data.message, 'error') 
                }
            })
        }
        else{
            globalDeleteService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/supplement/${data?.slug}/`)
            .then(response=>{
                getSupplementListingFn()
                setPageLoader(false)
                if(checkApiStatus(response)){
                    toastFlashMessage(response.data.message, 'success')
                }
                else{
                    toastFlashMessage(response.data.message, 'error') 
                }
            })
        }
    }
    const addAmdAttachment = (data, response) => {
        let formdata = new FormData()
        amendmentDoc.map(item => {
            formdata.append('files', item)
        })
        formdata.append('asset_type', assetId[props.params?.type])
        formdata.append('asset_slug', props.params.aircraft_slug)
        formdata.append('contract_slug', props.params.contract_slug)
        formdata.append('model_type', '5')
        formdata.append('object_id', data?.id)
        setAttachmentLoader(true)
        globalPostService(`contracts/attachments/`, formdata)
            .then(response => {
                getAmendmentListingFn()
                setAttachmentLoader(false)
                if (response.data.statusCode == 200) {
                    toastFlashMessage(`Amendment ${data?.id ?'updated ': 'added ' } and ${response.data.message}`, 'success')
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const addSplmtAttachment = (data, response) => {
        let formdata = new FormData()
        attatchedDoc.map(item => {
            formdata.append('files', item)
        })
        formdata.append('asset_type', assetId[props.params?.type])
        formdata.append('asset_slug', props.params.aircraft_slug)
        formdata.append('contract_slug', props.params.contract_slug)
        formdata.append('model_type', '6')
        formdata.append('object_id', data?.id)
        setAttachmentLoader(true)
        globalPostService(`contracts/attachments/`, formdata)
            .then(response => {
                getSupplementListingFn()
                setAttachmentLoader(false)
                if (response.data.statusCode == 200) {
                    toastFlashMessage(`Supplement ${data?.id ?'updated ': 'added ' } and ${response.data.message}`, 'success')
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const addEditAmendment = () => {
        let data = amendmentDetails
        let validateNewInput = {
            date: moment(data.date).diff(moment(leaseInfo?.date_of_manufacture).subtract(2,'year')) < 0 ?'Amendment Date cannot be before 2 years of Manufacturing Date': moment(data.date).diff(moment(leaseInfo.contractual_end_date),'days') > 0 ?'Amendment Date cannot be after Lease End Date'  : checkValidDate({ value: data.date, required: true, minLength: '', maxLength: '', message: 'Please Select Amendment Date' }),
            description: checkString({ value: data.description, required: true, minLength: '', maxLength: '', message: 'Please Enter Description' }),
            unique_identifier: checkString({ value: data.unique_identifier, required: true, minLength: '', maxLength: '', message: 'Please Enter Amendment Number' }),
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            setPageLoader(true)
            if (data?.id) {
                globalPutService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/amendment/${data?.slug}/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                          if(amendmentDoc.length && amendmentDetails?.attachments?.length ){
                            toastFlashMessage('Only one attachment could be attached', 'error')
                          }
                          else if(amendmentDoc.length && !amendmentDetails?.attachments?.length){
                            addAmdAttachment(data)
                            setAmendmentModal(false)
                          }
                          else{
                              toastFlashMessage(response.data.message, 'success')
                              setAmendmentModal(false)
                          }
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                        getAmendmentListingFn()
                    })
            }
            else {
                globalPostService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/amendment/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                            if (amendmentDoc.length) {
                                addAmdAttachment(response.data.data)
                            } else {
                                getAmendmentListingFn()
                                toastFlashMessage(response.data.message, 'success')
                            }
                            setAmendmentModal(false)
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                            getAmendmentListingFn()
                        }
                    })
            }
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    const addEditSupplement = () => {
        let data = supplementDetails
        let validateNewInput = {
            date: moment(data.date).diff(moment(leaseInfo?.date_of_manufacture).subtract(2,'year')) < 0 ?'Supplement Date cannot be 2 years before Manufacturing Date': moment(data.date).diff(moment(leaseInfo.contractual_end_date),'days') >0 ?'Supplement Date cannot be after Lease End Date' :checkValidDate({ value: data.date, required: true, minLength: '', maxLength: '', message: 'Please Select Supplement Date' }),
            executed_by: checkString({ value: data.executed_by, required: true, minLength: '', maxLength: '', message: 'Please Enter Executed By' }),
            unique_identifier: checkString({ value: data.unique_identifier, required: true, minLength: '', maxLength: '', message: 'Please Enter Supplement Number' }),
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            setPageLoader(true)
            if (data?.id) {
                globalPutService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/supplement/${data?.slug}/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                            if(attatchedDoc.length && supplementDetails?.attachments?.length ){
                                toastFlashMessage('Only one attachment could be attached', 'error')
                                getSupplementListingFn()
                              }
                              else if(attatchedDoc.length && !supplementDetails?.attachments?.length){
                                addSplmtAttachment(data)
                                setModal(false)
                              }
                              else{
                                  toastFlashMessage(response.data.message, 'success')
                                  setModal(false)
                              }
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                        }
                        getSupplementListingFn()
                    })
            }
            else {
                globalPostService(`contracts/${props.params.contract_slug}/${props.params.type}/${props.params.aircraft_slug}/supplement/`, data)
                    .then(response => {
                        setPageLoader(false)
                        if (checkApiStatus(response)) {
                            if (attatchedDoc.length) {
                                addSplmtAttachment(response.data.data)
                            } else {
                                getSupplementListingFn()
                                toastFlashMessage(response.data.message, 'success')
                            }
                            setModal(false)
                        }
                        else {
                            toastFlashMessage(response.data.message, 'error')
                            getSupplementListingFn()
                        }
                    })
            }
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    let minDateVal = moment(leaseInfo?.date_of_manufacture).subtract(2,'year')
    return (
        <div className="horizontal-card card-block">
            {
                attachmentLoader || pageLoader ? <PageLoader /> : null
            }
            <h5 className="para"> Supplement and Amendment </h5>
            <h6 style={{marginBottom:'10px', fontSize:'14px'}}> Lease Supplement</h6>
            <AddEditSupplement
                minDateVal={minDateVal}
                leaseInfo={leaseInfo}
                setValidationMsgs={setValidationMsgs}
                updateErrorField={updateErrorField}
                validationMsgs={validationMsgs}
                onChangeSupplementDetails={onChangeSupplementDetails}
                supplementDetails={supplementDetails}
                setModal={setModal}
                modal={modal}
                handleDelete={handleDelete}
                previewDoc={previewDoc}
                setAttatchedDoc={setAttatchedDoc}
                setEditModal={setEditModal}
                addEditSupplement={addEditSupplement}
            />
            
            <TableListComp
                heads={supplementHd}
                data={supplementList?.list?.map((item, index) =>
                    <SupplementList
                    setDeleteDetailsmodal={setDeleteDetailsmodal}
                    handleDelete={handleDelete}
                    previewDoc={previewDoc}
                        setDeleteModal={setDeleteModal}
                        setEditModal={setEditModal}
                        item={item}
                    />
                )}
                noRecord={supplementList?.list?.length ? null :
                    pageLoader ? null :
                        <EmptyCollection
                            title="No records found"
                        />
                }

            />
            <h6 style={{marginBottom:'10px', fontSize:'14px', marginTop:'30px'}}> Lease Amendment </h6>
            <AddEditAmendment
                minDateVal={minDateVal}
                leaseInfo={leaseInfo}
                addEditAmendment={addEditAmendment}
                setValidationMsgs={setValidationMsgs}
                updateErrorField={updateErrorField}
                validationMsgs={validationMsgs}
                onChangeAmendmentDetails={onChangeAmendmentDetails}
                amendmentDetails={amendmentDetails}
                setModal={setAmendmentModal}
                modal={amendmentModal}
                handleDelete={handleDelete}
                previewDoc={previewDoc}
                setAttatchedDoc={setAmendmentDoc}
                setEditModal={setEditAmendmentModal}
            />
            <TableListComp
                heads={amendmentHd}
                data={amendmentList?.list?.map((item, index) =>
                    <AmendmentList
                        setDeleteDetailsmodal={setDeleteDetailsmodal}
                        handleDelete={handleDelete}
                        previewDoc={previewDoc}
                        setDeleteModal={setDeleteModal}
                        setEditModal={setEditAmendmentModal}
                        item={item}
                    />
                )}
                noRecord={amendmentList?.list?.length ? null :
                    pageLoader ? null :
                        <EmptyCollection
                            title="No records found"
                        />
                }

            />
            {
                previewDocs ?
                    <Modal isOpen='open' style={{ width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight: '95vh', height: '95vh', marginTop: '0px' }}>
                        <ModalHeader >{previewDocs.name}</ModalHeader>
                        <ModalBody style={{ height: '91vh', maxHeight: '83vh' }}>
                            <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                                <param name="type" value="text/html" />
                            </object>
                        </ModalBody>
                        <ModalFooter >
                            <Button variant='contained' color='primary' onClick={(e) => window.open(previewDocs.url)}> Download</Button>
                            <Button variant='contained' color='primary' onClick={(e) => setPreviewDocs('')}>Okay</Button>
                        </ModalFooter>
                    </Modal> : null
            }
            {
                deleteAttachmentmodal ?
                    <DeleteModal isOpen={deleteAttachmentmodal} className="modal-dialog-centered"  >
                        <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this Attachment
                            <Tooltip title={deleteItem.name} arrow placement='top' style={{ marginTop: '3px' }}>
                                <div>
                                    <Chip variant="outlined" size="small" label={deleteItem.name.substring(0, 30) + '...'} color="primary" />
                                </div>
                            </Tooltip>
                        </p>
                        <ul className="list-inline" style={{ textAlign: 'center' }}>
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm add-new" onClick={deleteAttachment}>Yes</button>
                            </li>
                            <li className="list-inline-item">
                                <button className="btn btn-danger btn-sm add-new" onClick={() => setDeleteAttachmentmodal(false)} >No</button>
                            </li>
                        </ul>
                    </DeleteModal> :
                    null
            }
            {
                deleteDetailsmodal?.flag ?
                    <DeleteModal isOpen={deleteDetailsmodal?.flag} className="modal-tform-delete-pictures modal-dialog-centered" title={deleteDetailsmodal?.type == 'amendment' ? 'Delete Amendment':'Delete Supplement'} >
                        <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this ?</p>
                        <ul className="list-inline" style={{ textAlign: 'center' }}>
                            <li className="list-inline-item">
                                <button className="btn btn-primary " onClick={()=>deleteAmendmentDetailsFn(deleteDetailsmodal?.data, deleteDetailsmodal?.type)}>Yes</button>
                            </li>
                            <li className="list-inline-item">
                                <button className="btn btn-danger" onClick={() => setDeleteDetailsmodal(false)} >No</button>
                            </li>
                        </ul>
                    </DeleteModal> :
                    null
            }
        </div>
    )
}
