import React from 'react';
import { Grid } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils_v2';
const CashflowWidget = ({cashflowSummary}) => {
  return(
    <div className="cashflow-db-widget">
      <table>
        <tr>
          <td>
            <p>MR Total Funds</p>
            <h4>
              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowSummary.mr ? cashflowSummary.mr:0}</NumericLabel>
            </h4>
            { cashflowSummary.mr_detail_as_of_date ? <span className="date">As of {cashflowSummary.mr_detail_as_of_date}</span>:null }
          </td>
          <td>
            <p>ESP Total Funds</p>
            <h4>
              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowSummary.esp_summary && cashflowSummary.esp_summary.esp ? cashflowSummary.esp_summary.esp:0}</NumericLabel>
            </h4>
            { cashflowSummary.esp_summary && cashflowSummary.esp_summary.esp_as_of_date ? <span className="date">As of {cashflowSummary.esp_summary.esp_as_of_date}</span>:null }
          </td>
          <td>
            <p>LSP Total Funds</p>
            <h4>
              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowSummary.lsp_summary && cashflowSummary.lsp_summary.lsp ? cashflowSummary.lsp_summary.lsp:0}</NumericLabel>
            </h4>
            { cashflowSummary.lsp_summary && cashflowSummary.lsp_summary.lsp_as_of_date ? <span className="date">As of {cashflowSummary.lsp_summary.lsp_as_of_date}</span>:null }
          </td>
          <td>
            <p>JSP Total Funds</p>
            <h4>
              <NumericLabel params={getCurrencyFormat({shortFormat:false})}>{cashflowSummary.jsp_summary && cashflowSummary.jsp_summary.jsp ? cashflowSummary.jsp_summary.jsp:0}</NumericLabel>
            </h4>
            { cashflowSummary.jsp_summary && cashflowSummary.jsp_summary.jsp_as_of_date ? <span className="date">As of {cashflowSummary.jsp_summary.jsp_as_of_date}</span>:null }
          </td>
          <td>
            <p>Rental Total Funds</p>
            { cashflowSummary.rental_new.map((item) =>
              <h4>
                <NumericLabel params={getCurrencyFormat({currency: item.currency ,shortFormat:false})}>{item.total_amount ? item.total_amount:0}</NumericLabel>
              </h4>
            )}
            { cashflowSummary.rental_detail_as_of_date ? <span className="date">As of {cashflowSummary.rental_detail_as_of_date}</span>:null }
          </td>
        </tr>
      </table>
    </div>
  )
}
export default CashflowWidget;
