import React, { Component, Fragment } from 'react';
import { Tooltip, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, } from '@material-ui/core';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageLoader from './PageLoader';
import { getFaqsListApi } from '../apiServices';
class FAQsList extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      modal: false,
      faqsList:[]
    }
    this.getFaqsListApi = getFaqsListApi.bind(this);
  }
  render(){
    const { pageLoader, modal, faqsList } = this.state;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        <Tooltip title="FAQs" arrow>
          <IconButton size="small" color="primary" onClick={() => this.getFaqsListApi(this.props)}>
            <QuestionAnswerIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false,faqsList:[]})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              FAQs
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="faqs-list">
                    { faqsList.map((item, index) =>
                      item.answer ?
                      <ExpansionPanel className="working-groups-section" key={item.id}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={`faq${item.id}`}
                        >
                        {item.question}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div className="content" dangerouslySetInnerHTML={{ __html: item.answer}}></div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      :<h2 className="title">{item.question}</h2>
                    )}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false,faqsList:[]})}>OKAY</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default FAQsList;
