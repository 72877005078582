import React from 'react';
import moment from 'moment'
import { TableRow, TableCell } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { getCurrencyFormat } from '../../../utils_v2';
const InsuranceInvoiceItems = ({invoice_item, invoiceInfo, mode}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <h6 className="para-ui">
          {invoice_item && invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.name ? invoice_item.invoice_item_type_id.name : invoice_item && invoice_item.is_additional_rate ? 'Additional Rent' : 'Basic Rent'}
        </h6>
      </TableCell>
      <TableCell style={{maxWidth:'200px'}}>
        <h6 className="para-ui">
          {invoice_item.description}
        </h6>
      </TableCell>
      <TableCell>
        <h6 className="para-ui">
          {invoiceInfo.insurance_type && invoiceInfo.insurance_type.label ? invoiceInfo.insurance_type.label : '--'}
        </h6>
      </TableCell>
      <TableCell><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.billable_amount ? invoice_item.billable_amount:0}</NumericLabel></TableCell>
      <TableCell><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.sub_total ? invoice_item.sub_total:0}</NumericLabel></TableCell>
    </TableRow>
  )
}
export default InsuranceInvoiceItems;
