import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditMRUtilization from './EditMRUtilization';
const MRUtilList = ({item}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.month}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.hours}</TableCell>
      <TableCell>{item.cycles}</TableCell>
      <TableCell>{item.tsn}</TableCell>
      <TableCell>
        {item.csn}
        {item.editable ? <EditMRUtilization id={item.id} m="edit" />:null}
      </TableCell>
    </TableRow>
  )
}
export default MRUtilList;
