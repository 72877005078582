import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { CashTxCRU, NonCashTxCRU, CashTxList, NonCashTxList, SecurityDepositCRU, SecurityDepositHd } from '../components';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { assetsType } from '../../../../constants';
import { getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { getSecurityDepositListApi, securityDepositMLApi, deleteCashAndNonCashTxApi} from '../apiServices';
import { checkPermission } from '../../../../utils_v2';
import { sExportContractsAc } from '../../../actionCreators';
import { cashTxHd, nonCashTxHd } from '../';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { NoRecordFound } from '../../../../shared';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class SecurityDeposits extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list: [],
      deleteItem:{modal:false, data:null}
    }
    this.getSecurityDepositListApi = getSecurityDepositListApi.bind(this);
    this.securityDepositMLApi = securityDepositMLApi.bind(this);
    this.deleteCashAndNonCashTxApi = deleteCashAndNonCashTxApi.bind(this);
  }
  componentDidMount(){
    this.props.fetchContractInfo();
    this.getSecurityDepositListApi(this.props);
    this.securityDepositMLApi(this.props);
    this.props.getPlatformConstants();
    trackActivity('Page Visited', {
      page_title: 'Security Deposit'
    })
  }
 
  getResponseBack = () => {
    this.getSecurityDepositListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = `/${assetsType[evt.asset_type]}/${evt.slug}/contracts`;
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  deleteCashAndNonCash = () => {}
  render(){
    const { list, deleteItem, skeletonLoader, pageLoader } = this.state;
    const { pltConstants, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Induction Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='security-deposit' primaryTab='basic-betails' secondaryTab='securityDeposit' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctsecuritydeposits/'
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            buttonText={ checkPermission('contracts','security_deposit', 'C') ? <SecurityDepositCRU pltConstants={pltConstants} getResponseBack={this.getResponseBack} contractInfo={contractInfo}/> : null}
          >
            { skeletonLoader ? (<STableLoader count={6} />): list.map((securityDeposit,index) =>
              <div className="security-deposit-list" key={index}>
                <SecurityDepositHd item={securityDeposit} pltConstants={pltConstants} getResponseBack={this.getResponseBack} contractInfo={contractInfo} />
                <div className="">
                  <div className="">
                    <h5 className="flex-centered">
                      Cash Transactions
                      {checkPermission('contracts','security_deposit', 'C') ?<CashTxCRU securityDepositId={securityDeposit.id} getResponseBack={this.getResponseBack} contractInfo={contractInfo}/>: null }
                    </h5>
                    <TableListComp
                      heads={cashTxHd}
                      data={securityDeposit.security_deposit_transactions.filter(txn => txn.type_of_transaction === 1).map((item,index) =>
                        <CashTxList
                          key={index}
                          item={item}
                          getResponseBack={this.getResponseBack}
                          contractInfo={contractInfo}
                          deleteCashAndNonCash={() => this.setState({deleteItem:{modal:true, data:{id:item.id, securityDepositId:securityDeposit.id}}})}
                        />
                      )}
                      noRecord={securityDeposit.security_deposit_transactions.filter(txn => txn.type_of_transaction === 1).length?null:<h4 style={{fontSize:'16px'}}>No Cash Transaction</h4>}
                    />
                  </div>
                  <div className="">
                    <h5 className="flex-centered">Non-Cash Transactions
                    {checkPermission('contracts','security_deposit', 'C') ?<NonCashTxCRU securityDepositId={securityDeposit.id} getResponseBack={this.getResponseBack} contractInfo={contractInfo}/>: null}
                    </h5>
                    <TableListComp
                      heads={nonCashTxHd}
                      data={securityDeposit.security_deposit_transactions.filter(txn => txn.type_of_transaction === 2).map((item,index) =>
                        <NonCashTxList
                          key={index}
                          item={item}
                          getResponseBack={this.getResponseBack}
                          contractInfo={contractInfo}
                          deleteCashAndNonCash={() => this.setState({deleteItem:{modal:true, data:{id:item.id, securityDepositId:securityDeposit.id}}})}
                        />
                      )}
                      noRecord={securityDeposit.security_deposit_transactions.filter(txn => txn.type_of_transaction === 2).length?null:<h4 style={{fontSize:'16px'}}>No Non-Cash Transaction</h4>}
                    />
                  </div>
                </div>
                { !list.length ?<EmptyCollection title={'No records found'}/>:null}
              </div>
            )}
            { pageLoader || this.props.contractSummaryLoader  ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteCashAndNonCashTxApi(this.props, deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:["security_deposit_type"]})),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(SecurityDeposits,['contracts','security_deposit', 'R'])));
