export const leaseStatus = {
            //    jacLeaseStatus: [{label: 'Prospect', value:'11'},{label: 'In Storage', value: '10'}, {label: 'Lease Return', value: '9'}, {label: 'MOU Signed', value: '8'},{label: 'MOU Issued', value: '7'},{label: 'Lease Issued', value: '6'},{label: 'Archive', value: '5'},{label: 'Written Off/ Sold / Part out', value: '4'},
            //                       {label: 'Off Lease', value: '2'},{label: 'On Lease', value: '1'},],
               jacLeaseStatus: [ { label: 'On Lease', value: '1' },{ label: 'Off Lease', value: '2' },{ label: 'Sold', value: '17' }, { label: 'Written Off', value: '16' },{ label: 'Part out', value: '14' }, {label:'Installed Only',value:'15'}],
               thirdParty:[{label:'Installed Only',value:'15'}],
               defaultLeaseStatus:[{label: 'Archive', value:'5'},{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off/ Sold / Part out', value: '4'},],
               airAsiaLeaseStatus: [{label: 'Prospect', value:'11'}],
               jacStatus: [{ label: 'Off Lease', status: '2' },{ label: 'Sold', status: '17' }, { label: 'Written Off', status: '16' },{ label: 'Part out', status: '14' }, {label:'Installed Only',status:'15'}],
               defaultStatus:[{ status: 2, label: 'Off Lease' }, { status: 3, label: 'In Storage' }, { status: 4, label: 'Written Off/Sold/Part out' }],
               }
