
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from '@material-ui/core'
import React, { Fragment } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { backendDateFormat, fieldDateFormat } from '../../../../constants';
import { DropzoneArea } from 'material-ui-dropzone';
import { checkPermission } from '../../../../utils_v2';
export default function AddEditSupplement({ leaseInfo, validationMsgs, handleDelete, previewDoc, setAttatchedDoc, updateErrorField, setModal, modal, addEditSupplement, lesseeList, lessorList, supplementDetails, onChangeSupplementDetails, setEditModal, setValidationMsgs, minDateVal }) {
    return (
        <Fragment>
            { checkPermission('contracts','lease_details','C') ?
                <Button style={{ marginBottom: '13px' }} variant="outlined" color="primary" onClick={() => { setModal(true); setEditModal({}); setValidationMsgs({}) }}><AddIcon fontSize='small' />Add Supplement</Button>:null
            }
            {
                modal ?
                    <Dialog style={{zIndex:"300"}} open='true' onClose={() => setModal(false)} maxWidth={'md'} className='mou-dialog'>
                        <DialogTitle> {supplementDetails?.id ? ' Edit ' : 'Add '} Supplement Details </DialogTitle>
                        <DialogContent dividers style={{ width: "950px", }}>
                            <Grid container spacing='3'>
                                <Grid item sm='8'>
                                    <Grid container spacing='3'>
                                        <Grid item sm='6'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    minDate={minDateVal}
                                                    maxDate={moment(leaseInfo?.contractual_end_date)}
                                                    label="Lease Supplement Date"
                                                    value={supplementDetails?.date ? supplementDetails?.date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.date ? true : false}
                                                    helperText={validationMsgs.date ? validationMsgs.date : ''}
                                                    onFocus={() => updateErrorField('date')}
                                                    onChange={(data, value) => { onChangeSupplementDetails('date', data ? moment(data).format(backendDateFormat) : null) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='6'>
                                            <TextField
                                                required
                                                label='Supplement Number'
                                                id="supplement_no"
                                                fullWidth
                                                placeholder='Please Enter Supplement Number'
                                                margin="normal"
                                                value={supplementDetails.unique_identifier ? supplementDetails.unique_identifier : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 25 }}
                                                error={validationMsgs.unique_identifier ? true : false}
                                                onFocus={() => updateErrorField('unique_identifier')}
                                                helperText={validationMsgs.unique_identifier ? validationMsgs.unique_identifier : ''}
                                                onChange={(e, data) => onChangeSupplementDetails('unique_identifier', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item sm='6'>
                                            <TextField
                                                required
                                                label='Executed By'
                                                id="asset"
                                                fullWidth
                                                placeholder='Please Enter Executed By'
                                                margin="normal"
                                                value={supplementDetails.executed_by ? supplementDetails.executed_by : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 25 }}
                                                error={validationMsgs.executed_by ? true : false}
                                                onFocus={() => updateErrorField('executed_by')}
                                                helperText={validationMsgs.executed_by ? validationMsgs.executed_by : ''}
                                                onChange={(e, data) => onChangeSupplementDetails('executed_by', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm='4'>
                                    <DropzoneArea
                                        filesLimit={1}
                                        acceptedFiles={['.pdf']}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        maxFileSize={5000000000000}
                                        useChipsForPreview
                                        dropzoneText={<p>Drag & Drop Documents<br /> OR <br /> Click Here</p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => { setAttatchedDoc(files); updateErrorField('attatchedDoc') }}
                                        // onDrop={(files) => addAttachment('attatchedDoc', files)}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' }, style:{marginTop:'30px'} }}
                                    >
                                    </DropzoneArea>
                                    {
                                        supplementDetails?.id && supplementDetails?.attachments?.length ?
                                            supplementDetails?.attachments.map(i =>
                                                <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                                                    <Chip
                                                        style={{ marginRight: '4px', marginTop: '7px' }}
                                                        variant="outlined"
                                                        size="small"
                                                        onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                                        label={i.name.substring(0, 14) + '...'}
                                                        clickable
                                                        color="primary"
                                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                                                    />
                                                </Tooltip>) : null
                                    }
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions dividers>
                            <Button style={{ marginRight: '10px' }} color='primary' variant='outlined' size='small' onClick={() => setModal(false)} >
                                Cancel
                            </Button>
                            <Button color='primary' variant='contained' size='small' onClick={() => addEditSupplement()} >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null}
        </Fragment>
    )
}

