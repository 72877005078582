import React from 'react'
import { TableRow, TableCell, TextField, IconButton, Tooltip } from '@material-ui/core';
import { displayDateFormat } from '../../../constants';
import { showCurrencyFormat } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
export default function PartialPaymentList({ value }) {
    return (
        <TableRow hover tabIndex={-1}>
            <TableCell>
                <h6 className="para-ui"> {value.description ? value.description : '--'}</h6>
            </TableCell>
            <TableCell>
                <NumericLabel params={showCurrencyFormat(value.currency)}>
                    {value.amount ? value.amount : '--'}
                </NumericLabel>
            </TableCell>
            <TableCell>
                <h6 className="para-ui"> {value.payment_date ? moment(value.payment_date).format(displayDateFormat) : '--'}</h6>
            </TableCell>
            <TableCell>
                {
                    value.remaining_amount == 0 ?
                        <h6 className="para-ui">US$0.00</h6> :
                        <h6 className="para-ui">
                            <NumericLabel params={showCurrencyFormat(value.currency)}>
                                {value.remaining_amount ? value.remaining_amount : '--'}
                            </NumericLabel>
                        </h6>
                }
            </TableCell>
            <TableCell>
                <h6 className="para-ui">{value.created_by ? value.created_by.name : '--'}</h6>
            </TableCell>
            <TableCell>
                <h6 className="para-ui">{value.created_at ? moment(value.created_at).format(displayDateFormat) : '--'}</h6>
            </TableCell>
        </TableRow>
    )
}
