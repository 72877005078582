
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router';
import { Row, Col } from 'reactstrap'
import { NoRecordFound } from '../../../../shared';
import { EngineTab,AddEditEngine, EditEngineLLP, RemarksList, AddEditRemarks, TableHeadingTechSummary } from '../components';
import { techSummaryCrudAc, viewLLPInfoAc, addEditTechSummaryAc, addEditEngineLLPAc, EngineUpdateAc, EngineDetailAc, exportLLPsAc, importLLPsAc, editModuleAc, exportModuleLLpsAc, addEditModuleLLpAc, updateDisclaimerAc } from '../actionCreators';
import { TECH_SMRY_CRUD,TOGGLE_TECH_SMRY_VIEW, LLP_UPDATE_FIELD_TOGGLE, REMARKS_CRUD, UPDATE_ENGINE_DETAILS} from '../actions';
import { AddEditModule } from '../../../../User/components'
import { EditSidebar,FormModeType, CtrTableHd } from '../../../contracts/Elements'
import RemarksCont from './RemarksCont'
import { sManufacturerAc, sEngineTypeAc,sLesseeListAc, sRegionListAc, sOperatorListAc } from '../../../../shared/actionCreators';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { getLocalStorageInfo } from '../../../../utils';
class EngineDetails extends Component{
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			error: ''
		};
	}
	componentDidMount(){
		if(!this.props.engineManufacturerList.length){
			this.props.fetchManufacturer('engine', 'engineManufacturerList');
		}
		this.props.fetchEngineDeatils('engineDetails');
		this.props.fetchLessee();
		this.props.fetchEngines();
		this.props.fetchRegion();
		this.props.fetchOperators();
		this.props.getOwnerList()
	}

	saveRemarks = (evt) =>{
	  evt.preventDefault()
	  evt.stopPropagation()
	  if(this.props.remarksCrud.data.remarks.trim() == ''){
	    this.setState({error: 'Please enter Remark'})
	  }else{
	    this.props.saveRemarks(this.props.remarksCrud.data.remarks)
	  }
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	updateDisclaimer = (key, value) =>{
		let dataObject = this.props.engineDetails
		dataObject = {...dataObject, [key]: value}
		this.props.overviewTechUpdate(dataObject)
	}

	render(){
		const {remarksCrud, remarksList, engineDetails, engineCrud, sEngineTypes, sLessorList, sLesseeList, sOperatorList, sRegionList, engineManufacturerList, asset_slug, overviewTechInfo, techSpecsLoader,techConstants,llpCrud, llpToggleState, ownerList } = this.props;
      return(
				<div>
          {
            Object.keys(engineDetails).length ?
            <EngineTab
							techConstants={techConstants}
              llpToggleState = {llpToggleState}
              editLlpFn = {this.props.updateLlpFieldToggleFn}
              techSpecsLoader={techSpecsLoader}
              asset_slug={asset_slug}
							standAlone={true}
              viewLLPInfo={this.props.viewLLPInfo}
              viewEngine={this.props.techSummaryCrud}
							engineList={ this.props.engineDetails}
              addEditEngineLLP = {this.props.addEditEngineLLP}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: engineDetails, flag: true}, 'engineCrud')}
							assetType= "engine"
							exportLLPs = {(slug, activeTab) => this.props.exportLLPs(slug, activeTab)}
							importLLPs = {this.props.importLLPs}
							exportModuleLLps ={(module) => this.props.exportModuleLLps(module)}
							addEditModuleLLp = {this.props.addEditEngineLLP}
             />
             : null
        }
				<RemarksCont/>
				<h3 style={{fontSize: '16px', marginBottom: '10px'}}>Disclaimer</h3>
				<div className="horizontal-card card-block">
					<Row>
						<Col md="12" className="horizontal-inner-block">
							<div>
								<h6 style={{marginBottom: '10px'}}>Technical</h6>
								{
									getLocalStorageInfo().user.permission['technical_specs'] ?
									<textarea disabled={!getLocalStorageInfo().user.permission['technical_specs'] ? true : false} style={{border: '1px solid #d7d7d7', height: '115px', width: '100%', padding: '10px', resize: 'none', borderRadius: '4px'}} value={this.props.engineDetails.technical_disclaimer} onChange={(evt) => this.updateDisclaimer('technical_disclaimer', evt.target.value)}></textarea>
									:<p style={{border: '1px solid #d7d7d7', borderRadius: '4px', width: '100%', padding: '10px'}}>{this.props.engineDetails.technical_disclaimer && this.props.engineDetails.technical_disclaimer.length ? this.props.engineDetails.technical_disclaimer.length : '--'}</p>
								}
							</div>
						</Col>
						{
							getLocalStorageInfo().user.permission['technical_specs'] ?
							<Col md="12">
									<input type="button" style={{float: 'right', marginTop: '15px', marginBottom: '40px'}} className="primary-btn" onClick={() => engineDetails.esn.includes('Module') ? this.props.updateDisclaimer({technical_disclaimer: this.props.engineDetails.technical_disclaimer,slug: this.props.engineDetails.slug}) : this.props.EngineUpdate({technical_disclaimer: this.props.engineDetails.technical_disclaimer, slug: this.props.engineDetails.slug})} value="Save"/>
							</Col>
							:null
						}
					</Row>
				</div>
				<EditSidebar width="800px" right= "-800px" title={engineCrud.type == 'add' ? 'Add Engine' : Object.keys(engineCrud.data).length &&
					engineDetails.esn.includes('Module') ? `Edit ${engineDetails.esn}` : 'Edit Engine'} editSideBar={engineCrud.flag} toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'engineCrud')}>
					{Object.keys(engineCrud.data).length &&
						engineDetails.esn.includes('Module') ?
						<AddEditModule
							asset_slug={asset_slug}
							type={engineCrud.type}
							createEngine={this.props.editModule}
							engineObj={engineDetails}
							engineTypes={sEngineTypes}
							lessorList={sLessorList}
							clients={sLesseeList}
							standAlone={true}
							operators={sOperatorList}
							sRegionList={sRegionList}
							manufacturerList={engineManufacturerList}
							techConstants={techConstants}
							portfolioList={this.props.portfolioList}
							toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'engineCrud')}
							overview = {overviewTechInfo}
							assetType= "engine"
							toggleTransfer={() => this.props.toggleTransfer(true)}
							ownerList={ownerList}
						/>:
						Object.keys(engineCrud.data).length ?
						<AddEditEngine
							asset_slug = {asset_slug}
						 	type={engineCrud.type}
						 	addEditTechSummary={this.props.EngineUpdate}
						 	engineObj={engineCrud.data}
						 	sEngineTypes={sEngineTypes}
						 	sLessorList={sLessorList}
						 	sLesseeList={sLesseeList}
							standAlone={true}
						 	sOperatorList={sOperatorList}
						 	sRegionList={sRegionList}
							manufacturerList={engineManufacturerList}
							techConstants={techConstants}
							portfolioList={this.props.portfolioList}
						 	toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'engineCrud')}
							overview = {overviewTechInfo}
							assetType= "engine"
							toggleTransfer={() => this.props.toggleTransfer(true)}
							ownerList={ownerList}
				 		/> : null
			 		}
				</EditSidebar>
				<EditSidebar title={llpCrud.type != '' ?
                    (llpCrud.type == 'add') ? 'Add LLP':
                    (llpCrud.type == 'edit') ? 'Edit LLP': 'View LLP' : ''
                    }
                    editSideBar={llpCrud.flag}
                    toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false},'llpCrud')}>
                    <div>
						<FormModeType
							type={llpCrud.type}
							changeViewMode={()=>this.props.changeViewMode(llpCrud.type,'llpCrud')}
							editAble = {getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['engine'].indexOf('U') != -1 ? true:false}
						/>
						{  Object.keys(llpCrud.data).length && llpCrud.flag ?
							<EditEngineLLP
								asset_slug = {asset_slug}
								type={llpCrud.type}
								llpInfoObj={llpCrud.data}
								addEditEngineLLP = {this.props.addEditEngineLLP}
								engineId={llpCrud.engineId}
								toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'llpCrud')}
							/> : null
						}
					</div>
				</EditSidebar>
				{
					// !techSpecsLoader && getLocalStorageInfo().defaultLessor.lessor_type != 3 && (engineDetails.data_transfer == undefined || engineDetails.data_transfer == null || Object.keys(engineDetails.data_transfer).length < 1) ?
					// <div className="contr-table-blk">
					// 	<h5 style={{ borderBottom: '0'}}>
					// 	<Row>
					// 		<Col md="6">Transfer Asset</Col>
					// 		<Col md="6"><Link className="action-link" onClick={() => this.props.toggleTransfer(true)}>Initiate Transfer</Link></Col>
					// 	</Row>
					// 	</h5>
					// 	<div className="mobile-table-format">
					// 		<p className="detail-text-content">
					// 			You can transfer complete or partial asset data to other lessor account on SPARTA platform. Get started by downloading transfer key.
					// 		</p>
					// 	</div>
					// </div>
					// :null
				}

			</div>
		)
	}
}
const mapStateToProps = state => ({
	engineManufacturerList: state.sharedReducers.engineManufacturerList,
	sEngineTypes: state.sharedReducers.sEngineTypes,
	sLessorList: state.sharedReducers.sLessorList,
	sLesseeList: state.sharedReducers.sLesseeList,
	sOperatorList: state.sharedReducers.sOperatorList,
	sRegionList: state.sharedReducers.sRegionList,
	engineCrud: state.TechSummaryReducer.engineCrud,
	llpCrud: state.TechSummaryReducer.llpCrud,
	techConstants: state.sharedReducers.techConstants,
  llpToggleState: state.TechSummaryReducer.llpToggleState,
  engineDetails: state.TechSummaryReducer.engineDetails,
	portfolioList: state.FleetAnalysisReducer.portfolioList,
	ownerList: state.confReducer.ownerDropdownList,
	techSpecsLoader:state.TechSummaryReducer.techSpecsLoader
});
const mapDispatchToProps = (dispatch,ownProps) => {
	return {
		fetchLessee:()=>dispatch(sLesseeListAc()),
		getOwnerList:()=> dispatch(getOwnerDropdownListAc()),
		fetchEngineDeatils: (type) => dispatch(EngineDetailAc(ownProps.params, type)),
		EngineUpdate: (engineDetail) => dispatch(EngineUpdateAc(engineDetail)),
		editModule:(data) => dispatch(editModuleAc(ownProps.params, data)),
		updateDisclaimer:(data) => dispatch(updateDisclaimerAc(ownProps.params, data)),
		fetchEngines: () => dispatch(sEngineTypeAc()),
		fetchOperators: () => dispatch(sOperatorListAc()),
		fetchManufacturer: (group_type, reducerType) => dispatch(sManufacturerAc(group_type, reducerType)),
		addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
		addEditEngineLLP: (asset_slug, data, operation, type, viewType, llpType) => dispatch(addEditEngineLLPAc(asset_slug, data, 'engine', ownProps.params, operation, type, viewType, llpType)),
		addEditModuleLLp: (data) => dispatch(addEditModuleLLpAc(ownProps.params, data)),
		viewLLPInfo: (asset_slug, id, index, childIndex) => dispatch(viewLLPInfoAc(asset_slug, id,index, childIndex)),
		fetchRegion: () => dispatch(sRegionListAc()),
		changeViewMode: (type,actionType) => dispatch({
			type:TOGGLE_TECH_SMRY_VIEW,
			payload:{type},
			actionType
		}),
		toggleEditBar: (data, actionType) => dispatch({
			type:TECH_SMRY_CRUD,
			payload:data,
			actionType
		}),
		overviewTechUpdate: (dataObject) => dispatch({
			type: UPDATE_ENGINE_DETAILS,
			payload: dataObject
		}),
		updateLlpFieldToggleFn: (flag) => dispatch({
			type: LLP_UPDATE_FIELD_TOGGLE,
			payload: flag
		}),
		techSummaryCrud: (type, id, actionType,dataObject, assetType) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject, assetType)),
		exportLLPs: (engine_slug, llpType) => dispatch(exportLLPsAc(ownProps.params, engine_slug, llpType)),
		exportModuleLLps:(module) => dispatch(exportModuleLLpsAc(ownProps.params, module)),
		importLLPs: (file, engine_slug, llpType) => dispatch(importLLPsAc(ownProps.params, file, engine_slug, llpType)),
	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineDetails));
