import * as actions from '../actions';
import { globalGetService, globalPutService, globalExportService, globalStaticGetService } from '../../../globalServices';
import { downloadFileType, getLocalStorageInfo } from '../../../utils';
const applicationWidget = {
  technical: 'console/dashboard/technical-details/',
  contacts: 'console/dashboard/contracts-details/',
  maintenance: 'console/dashboard/maintenance-details/',
  records: 'console/dashboard/records-details/'
}
export const contractBasedMsnAc = () => {
  return(dispatch) => {
    globalGetService('/contracts/dashboard/asset-dropdown/',{})
    .then(response => {
      if(response.data?.statusCode == 200){
        dispatch({
          type: actions.CONTRACT_MSN,
          payload: response.data.data
        })
        if(response.data.data.length){
          const asset = response.data.data.filter(ass => ass.has_cashflows).length ? response.data.data.filter(ass => ass.has_cashflows)[0] : response.data.data[0];
          const utilAsset = response.data.data.filter(ass => ass.has_utilizations).length ? response.data.data.filter(ass => ass.has_utilizations)[0] : response.data.data[0];
          dispatch(cashflowSummarydbWidgetAc({slug: asset.slug, asset_type: asset.asset_type}));
          dispatch(utilizationSummaryWidgetAc({slug: utilAsset.slug, asset_type: utilAsset.asset_type}));
        }else{
          dispatch(changeDbLoaderStatus('dbfleetGraphLoader', false));
          dispatch(changeDbLoaderStatus('utilSummaryLoader', false));

        }
      }
    })
  }
}
export const fleetSummeryWidgetAc = () => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbFleetSummLoader', true));
    globalGetService('/console/dashboard/lessee-details/', {})
    .then(response => {
      dispatch(changeDbLoaderStatus('dbFleetSummLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FLEET_SUMM_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const getOperatorRatingAc = () => {
  return (dispatch) => {
    globalGetService('technical/operator-rating/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.OPR_RATING_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const aircraftDistributionWidgetAc = () => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbAircraftDisLoader', true));
    globalGetService('/console/dashboard/aircraft-type-details/', {})
    .then(response => {
      dispatch(changeDbLoaderStatus('dbAircraftDisLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.AIRCRAFT_DIS_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}


export const fetchAppsCardWidgetAc = (type) => {
  return (dispatch) => {
    dispatch({
      type: actions.APPS_MOD_WIDGET,
      payload: {},
      flag: false,
      appType: type
    })
    if(!type.includes('records-details')){
      globalGetService(applicationWidget[type], {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APPS_MOD_WIDGET,
            payload: response.data.data,
            flag: true,
            appType: type
          })
        }
      })
    }else{
      globalStaticGetService(applicationWidget[type], {})
      .then(response => {
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.APPS_MOD_WIDGET,
            payload: response.data.data,
            flag: true,
            appType: type
          })
        }
      })
    }
  }
}

export const assetsInfoWidgetAc = (queryParam={}) => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbAssetLoader', true));
    globalGetService('/console/dashboard/asset-details/', queryParam)
    .then(response => {
      dispatch(changeDbLoaderStatus('dbAssetLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ASSETS_INFO_WIDGET,
          payload: response.data.data.asset
        })
      }
    })
  }
}
export const operatorRatingWidgetAc = () => {
  return (dispatch) => {
    globalGetService('', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.OPR_RATING_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const contractCardWidgetAc = () => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbContractLoader', true));
    globalGetService('/contracts/dashboard/contract-detail/', {})
    .then(response => {
      dispatch(changeDbLoaderStatus('dbContractLoader', false));

      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTRACT_CARD_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const cashflowSummaryWidgetAc = (queryParam={}) => {

  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbfleetGraphLoader', true));
    dispatch({
      type: actions.MSN_UPDATE_FILTER,
      payload: {type: 'msnCashflowSlug', slug: queryParam.slug}
    })
    globalGetService('/contracts/dashboard/cash-flow-summary/', queryParam)
    .then(response => {
      dispatch(changeDbLoaderStatus('dbfleetGraphLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASHFLOW_SUMM_WIDGET,
          payload:{'response': response.data.data,
          queryParam:{slug: queryParam.slug}}
        })
      }
    })
  }
}
export const cashflowSummarydbWidgetAc = (queryParam={}) => {

  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbfleetGraphLoader', true));
    dispatch({
      type: actions.MSN_UPDATE_FILTER,
      payload: {type: 'msnCashflowSlug', slug: queryParam.slug}
    })
    globalGetService('/contracts/dashboard/cashflow-dashboard-summary/', queryParam)
    .then(response => {
      dispatch(changeDbLoaderStatus('dbfleetGraphLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASHFLOW_SUMM_WIDGET,
          payload:{'response': response.data.data,
          queryParam:{slug: queryParam.slug}}
        })
      }
    })
  }
}
export const cashflowPaymentAc = () => {
  return (dispatch) => {
    globalGetService('/contracts/dashboard/cash-flow-total/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CASHFLOW_PAYMENT_WIDGET,
          payload: response.data.data
        })
      }
    })

  }
}
export const invoiceSummaryWidgetAc = (queryParam={}) => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('dbInvoiceLoader', true));
    globalGetService('/contracts/dashboard/invoice-summary/', {})
    .then(response => {
      dispatch(changeDbLoaderStatus('dbInvoiceLoader', false));
      if(response?.data?.statusCode == 200){
        dispatch({
          type: actions.INVOICE_SUMM_WIDGET,
          payload:{'response': response.data.data, queryParam}
        })
      }
    })
  }
}
export const contractExpiryWidgetAc = () => {
  return (dispatch) => {
    globalGetService('/contracts/dashboard/contract-expire/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTRACT_EXPIRY_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const utilizationSummaryWidgetAc = (queryParam={}) => {
  return (dispatch) => {
    dispatch({
      type: actions.MSN_UPDATE_FILTER,
      payload: {type: 'msnUtilSlug', slug: queryParam.slug}
    })
    dispatch({
      type: actions.UTIL_SUM_LOADER,
      payload: true
    })
    dispatch(changeDbLoaderStatus('utilSummaryLoader', true));
    globalGetService('/contracts/dashboard/utilization-trend/', queryParam)
    .then(response => {
      dispatch({
        type: actions.UTIL_SUM_LOADER,
        payload: false
      })
      dispatch({
        type: actions.UTIL_DATA_UPDATE,
        payload: {
          msnUtilSlug: queryParam.slug,
          msnUtilType: queryParam.asset_type
        }
      })
      dispatch(changeDbLoaderStatus('utilSummaryLoader', false));
      if(response.data.statusCode == 200){
        if([2,6,7,8,9].includes(queryParam.asset_type)){
          dispatch({
            type: actions.UTIL_SUMMARY_WIDGET,
            payload:{'response': {
              end_date: response.data.data.end_date,
              start_date: response.data.data.start_date,
              engines: {
                1: response.data.data.engines
              }
            }, queryParam}
          })
        }else if(queryParam.asset_type === 4){
          dispatch({
            type: actions.UTIL_SUMMARY_WIDGET,
            payload:{'response': {
              end_date: response.data.data.end_date,
              start_date: response.data.data.start_date,
              landing_gears: {
                1: response.data.data.landing_gears
              }
            }, queryParam}
          })
        }else{
          dispatch({
            type: actions.UTIL_SUMMARY_WIDGET,
            payload:{'response': response.data.data, queryParam}
          })
        }
      }
    })
  }
}
export const claimTrackerWidgetAc = () => {
  return (dispatch) => {
    globalGetService('/maintenance/dashboard/claims-tracker/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CLAIM_TRACKER_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const majorAssemblyWidgetAc = () => {
  return (dispatch) => {
    dispatch(changeDbLoaderStatus('upcomingEventLoader', true));
    globalStaticGetService('/maintenance/dashboard/upcoming-events/', {})
    .then(response => {
      dispatch(changeDbLoaderStatus('upcomingEventLoader', false));
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MAJOR_ASSEMBLY_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const actionDockCardWidgetAc = () => {
  return (dispatch) => {
    dispatch({
      type: actions.FILE_COUNT_LOADER,
      payload: true
    })
    globalStaticGetService('/records/dashboard/file-details/', {})
    .then(response => {
      dispatch({
        type: actions.FILE_COUNT_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ACTION_DOCK_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const fetchActivitiesListAc = (aircraftId,type) => {
  return (dispatch) => {
    let url = '';
    if(aircraftId == 0){
      url = '/records/dashboard/activities/';
    }else {
      if(type==2){
        url = '/records/dashboard/activities/?engine_id=' +aircraftId;
      }else if(type==1){
        url = '/records/dashboard/activities/?aircraft_id=' +aircraftId;
      }else if(type==3){
        url = '/records/dashboard/activities/?apu_id=' +aircraftId;
      }else if(type==4){
        url = '/records/dashboard/activities/?lg_id=' +aircraftId;
      }else if(type==5){
        url = '/records/dashboard/activities/?propeller_id=' +aircraftId;
      }

    }
    dispatch({
      type: actions.FLEET_ACTIVITY_LIST,
      payload: {}
    })
    globalGetService(url, {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FLEET_ACTIVITY_LIST,
          payload: response.data
        })
      }
    })
  }
}
export const fetchActionDockActivitiesListAc = () => {
  return (dispatch) => {
    globalStaticGetService('records/dashboard/asset-action-dock/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ACTION_ACTIVITY_LIST,
          payload: response.data
        })
      }
    })
  }
}
export const sharedContentWidgetAc = () => {
  return (dispatch) => {
    globalGetService('/records/dashboard/shared-details/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.SHARED_CONTENT_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const lastActivityWidgetAc = () => {
  return (dispatch) => {
    globalGetService('', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LAST_ACTIVITY_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}

export const exportMntUpcomingEventsAc = () => {
  return (dispatch) => {

    dispatch({
      type: actions.EXPORT_DASH_LOADER,
      payload: true
    })
    globalExportService('/maintenance/dashboard/upcoming-events/?download=pdf', {})
    .then(response => {
      dispatch({
        type: actions.EXPORT_DASH_LOADER,
        payload: false
      })
      downloadFileType(response, 'upcoming_events.', 'pdf')
    })
  }
}

export const changeDbLoaderStatus = (loaderType, flag) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_DB_LOADER,
      loaderType: loaderType,
      payload: flag
    });
  }
}

export const technicalProjectsWidgetAc = (props) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalProjectLocationtAc = (props,queryParams={}) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/project-location/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_LOCATION,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalLocationsAc = (props) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/locations/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_LOCATION,
          payload: response.data.data
        })
      }
    })
  }
}


export const technicalInspectionTypeAc = (props) => {

  return (dispatch) => {
    globalGetService('technical/inspection-types/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_INSPECTION_TYPE,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalAircraftAc = (props) => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.TECHNICAL_AIRCRAFT,
          payload: response.data.data
        })
      }
    })
  }
}
export const technicalDbFindingsAc = (props, queryParams={}) => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/inspection-findings/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_INSPECTION_FINDINGS,
          payload: response.data.data
        })
      }
    })
  }
}
export const technicalLastAssetsAc = () => {
  return (dispatch) => {
    globalGetService('technical/project-dashboard/project-assets/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_PROJECT_ASSETS,
          payload: response.data.data
        })
      }
    })
  }
}

export const setDefaultUrlAc = (data) => {
  return (dispatch) => {
    globalPutService('console/user/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        let userInfo = getLocalStorageInfo();
        userInfo = {
          ...userInfo,
          user: {
            ...userInfo.user,
            default_dashboard_url: data.default_dashboard_url
          }
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      }
    })
  }
}
export const detailInvExportAc = (invoice) => {
  return (dispatch) => {
    globalExportService('/contracts/'+invoice.contract_slug+'/invoice/'+invoice.id+'/',{download: 'pdf'})
    .then(response => {
      downloadFileType(response, '#'+invoice.invoice_number+'_Invoice.', 'pdf')
    });
  }
}
