import { getLocalStorageInfo } from "../../utils";
import { jetStreamInstance } from "../Leases";
export const globalFilterTypes = [
  {label:'lessee',type:'lesseeOptions'},
  {label:'lessor',type:'lessorOptions'},
  {label:'owner',type:'ownerOptions'},
  {label:'asset_type',type:'assetTypeOptions'},
  {label:'asset_model',type:'assetModelOptions'},
  {label:'serial_number',type:'assetsOptions'},
]
export const addMRUtilsHd = [
  { label: 'Major Assembly', sortOption: false },
  { label: 'TSN as of Last Month', sortOption: false },
  { label: 'CSN as of Last Month', sortOption: false },
  { label: 'Hours Utilized in Selected Month', sortOption: false },
  { label: 'Cycles Utilized in Selected Month', sortOption: false },
  { label: 'TSN as of Selected Month', sortOption: false },
  { label: 'CSN as of Selected Month', sortOption: false },
  { label: 'Present Location of Original Assembly', sortOption: false },
  { label: 'Remarks', sortOption: false },
];
export const mrUtilsSummaryHd = [
  { label: 'Major Assembly', sortOption: false },
  { label: 'Avg. FH', sortOption: false },
  { label: 'Avg. FC', sortOption: false },
  { label: 'TSN(Hrs)', sortOption: false },
  { label: 'CSN(Cycles)', sortOption: false },
];
export const mrFleetUtilsHd = [
  { id:'asset_type', label: 'Asset(s)', sortOption: true },
  { id:'lessor_name', label: 'Lessor', sortOption: false },
  { id:'owner_name', label: 'Owner', sortOption: false },
  { id:'lessee', label: 'Lessee', sortOption: false },
  { id:'month_year',label: 'Utilization Month', sortOption: true },
  { id:'tsn',label: 'TSN', sortOption: true },
  { id:'csn',label: 'CSN', sortOption: true },
  { id:'hours',label: 'Hours', sortOption: true },
  { id:'cycles',label: 'Cycles', sortOption: true },
  { label: 'Remarks', sortOption: false },
  { label: 'Serviceability', sortOption: false },
];
export const mrUtilsHd = [
  { label: 'Month', sortOption: false },
  { label: 'Major Assembly', sortOption: false },
  { label: 'Hours', sortOption: false },
  { label: 'Cycles', sortOption: false },
  { label: 'TSN(Hrs)', sortOption: false },
  { label: 'CSN(Cycles)', sortOption: false },
];
export const rentalUtilsHd = [
  { label: 'Month', sortOption: false },
  { label: 'Hours', sortOption: false },
  { label: 'Cycles', sortOption: false },
  { label: 'Block Hours', sortOption: false },
  { label: 'Remarks', sortOption: false },
];
export const addRentalUtils = {
  year:'',
  month:'',
  hours: '',
  cycles: '',
  block_hour: '',
  remarks:''
}
export const errorCode = {
  year:{
    0:'',
    1: 'Please select the Year'
  },
  yearObj: {
    required: true
  },
  month: {
    0:'',
    1: 'Please select the Month',
  },
  monthObj: {
    required: true
  },
  hours: {
    0:'',
    1: 'Please enter Hours'
  },
  hoursObj: {
    required: true
  },
  cycles: {
    0:'',
    1: 'Please enter Cycles'
  },
  cyclesObj: {
    required: true
  }
}
export const mrFilterOption = {
  unique_asset: {
    inputType: 'dropdown',
    placeholder: 'Select Major Assemblies',
    options: [],
    labelKey: 'name',
    valueKey: 'unique_asset',
    title:'Major Assemblies',
    showDropdown:true,
    multiple:true
  },
  utilization_date: {
    inputType: 'date',
    title: 'Utilization Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  }
};
export const rentalFilterOption = {
  utilization_date: {
    inputType: 'date',
    title: 'Utilization Date',
    firstKey:'startDate',
    lastKey:'endDate',
    range:true
  }
};

export const fleetUtilsFilterOps = {
  asset_type : {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    title: 'Asset Type',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  asset_model: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Model',
    title: 'Asset Model',
    options: [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  serial_number: {
    inputType: 'dropdown',
    placeholder: 'Select Serial No.',
    title: 'Serial No.',
    options: [],
    labelKey: 'serial_number',
    valueKey: 'id',
    multiple: true
  },
  lessee_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Lessee',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  lessor_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    title: `${jetStreamInstance?'Lessor/Holdco':'Lessor'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  owner_id : {
    inputType: 'dropdown',
    placeholder: `Select ${jetStreamInstance?'Owner/SPV':'Owner'}`,
    title: `${jetStreamInstance?'Owner/SPV':'Owner'}`,
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  tsn_from: {
    inputType: 'number',
    title: 'TSN From',
    md:6
  },
  tsn_till: {
    inputType: 'number',
    title: 'TSN Till',
    md:6
  },
  csn_from: {
    inputType: 'number',
    title: 'CSN From',
    md:6
  },
  csn_till: {
    inputType: 'number',
    title: 'CSN Till',
    md:6
  },
  hours_from: {
    inputType: 'number',
    title: 'Hours From',
    md:6
  },
  hours_till: {
    inputType: 'number',
    title: 'Hours Till',
    md:6
  },
  cycles_from: {
    inputType: 'number',
    title: 'Cycles From',
    md:6
  },
  cycles_till: {
    inputType: 'number',
    title: 'Cycles Till',
    md:6
  },
  serviceability: {
    inputType: 'text',
    title: 'Serviceability',
  },
  utils_from: {
    inputType: 'date',
    title: 'Utilization From',
    disableFuture:true
  },
  utils_till: {
    inputType: 'date',
    title: 'Utilization Till',
    disableFuture:true
  }
}

export const utilsGraphFilterOps = {
  utils_from: {
    inputType: 'date',
    title: 'Utilization From',
    disableFuture:true
  },
  utils_till: {
    inputType: 'date',
    title: 'Utilization Till',
    disableFuture:true
  }
}



