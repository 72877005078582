import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';
import { displayDateFormatShort, invoiceStatusList } from '../../../constants';
import { getLocalStorageInfo, showCurrencyFormat, dateTransform } from '../../../utils';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import { checkPermission } from '../../../utils_v2';
export default function InvoiceList({ item, redirectToDetails,leaseType, diff, invoiceCreatedDetails, changeStatus, onInvoiceStatusChangeApi,statusChangeList,  changeStatusModal, setStatusChangeList }) {
    return (
        <TableRow  hover tabIndex={-1} style={{ padding: '4px 0px' }}  >
            <TableCell  onClick={() => redirectToDetails(item)} className="" style={{ cursor: 'pointer' }} >
                {item?.invoice_number ? item?.invoice_number : '--'}
            </TableCell>
            <TableCell  onClick={() => redirectToDetails(item)} className="" style={{ cursor: 'pointer' }} >
                {item?.invoice_type?.label ? item?.invoice_type?.label : '--'}
            </TableCell>
            <TableCell  onClick={() => redirectToDetails(item)} className="" style={{ cursor: 'pointer' }} >
                {
                    leaseType == 0 ? 'N/A' : <>
            {item?.start_date ? item?.start_date : '--'} to {item?.end_date ? item?.end_date : '--'} </>
                }
            </TableCell>
            <TableCell  onClick={() => redirectToDetails(item)} className="" style={{ cursor: 'pointer' }} >
                {item?.date ? item?.date : '--'}
            </TableCell>
            <TableCell onClick={() => redirectToDetails(item)}  className="" style={{ cursor: 'pointer' }} >
                {item?.due_date ? item?.due_date : '--'}
            </TableCell>
            <TableCell onClick={() => redirectToDetails(item)} className="" style={{ cursor: 'pointer' }} >
                {item?.total_amount ? 
                <NumericLabel params={showCurrencyFormat(item.currency)}>{item?.total_amount}</NumericLabel> 
                : '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                <span 
                    style={{display:"block", width:'132px'}}
                    className={item.status.value && invoiceStatusList[item.status.value] + ' invoice-status'}
                >
                    {item?.status?.label ? item?.status?.label : '-- '}
                    {(item?.status?.value == 1 || item?.status?.value == 2 || item.status?.value == 5) && checkPermission('assembly_lease','invoice','U') ?
                        <i  className="fa fa-caret-down" style={{float:'right'}} 
                            onClick={(e) => setStatusChangeList({data:item, modal: true})}
                        >
                        </i> : null
                    }
                </span>
                <span className="overdue-text" style={{ display: 'block', textAlign: 'left', marginTop: '4px', color:"red" }}>
                      {((item.status.value == 2) && item.in_due) ? 'Overdue By ' + item.overdue_days_count : ''}
                    </span>
                    {
                      item.status.value == 5 && item.partial_paid_date ?
                        <span style={{ display: 'block', marginTop: '4px', textAlign: 'left', textTransform: 'none' }}>
                          Payment Date: {moment(item.partial_paid_date).format('MMM DD,YYYY')}
                          {moment(item.partial_paid_date).diff(moment(item.due_date), 'days') > 0 ? <span style={{ display: 'block', textAlign: 'left', marginTop: '4px', color: '#ff0000', fontSize: '9px', textTransform: 'none' }} >{"Payment late by " + dateTransform(item.due_date)}</span> : ''}
                        </span> : null
                    }
                    {
                      item.status.value == 3 && item.paid_date ?
                        <span style={{ display: 'block', marginTop: '4px', textAlign: 'left', textTransform: 'none' }}>
                          Payment Date: {moment(item.paid_date).format(displayDateFormatShort)}
                          {moment(item.paid_date).diff(moment(item.due_date), 'days') > 0 ? <span style={{ display: 'block', textAlign: 'left', marginTop: '4px', color: '#ff0000', fontSize: '9px', textTransform: 'none' }}>{"Payment late by " + dateTransform(item.due_date, item.paid_date)}</span> : ''}
                        </span> :
                        null
                    }
            </TableCell>
        </TableRow>
    )
}
