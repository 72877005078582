import { globalGetService, globalPutService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
export function getFianceDetailApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/financial-details/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        financial: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function editFianceDetailApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      globalPutService(`contracts/${props.params.contract_slug}/financial-details/${data.id}/`, data)
      .then(response => {
        resolve(response);
      })
    }
  )
}
