import React, { Component } from 'react';
import { UserManageSideBar, UserList, AddUser } from '../components';
import { browserHistory } from 'react-router';
import { DeleteModal } from '../../applications/technicalInspection/Elements';
import { NoRecordFound, ListLoader ,ExportFiles, LicenceModal } from '../../shared';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as actions from '../actions';
import Pagination from "react-js-pagination";
import { checkString, checkNumber, checkEmail } from '../../formValidator';
import { getLocalStorageInfo } from '../../utils';
import { getUsersListAc,activateUserAc, createUserAc, getUserEmailAc, getGroupsListAc,downLoadUserFormAc, getUsersUsageAc } from '../actionCreators';
import { EditFormBar } from '../../applications/technicalInspection/Elements';
import '../../applications/technicalInspection/assets/styles/_common.scss'
import { imgStoragePath } from '../../constants';
import { BUY_LICENCE } from '../../shared/actions'
import config from '../../config';
import DeploymentMessage from '../../shared_elements/components/DeploymentMessage';

class UsersList extends Component {
  constructor(props){
    super(props);
    this.state = {
      page: 1,
      search:'',
      searchText:'',
      activeUser: 'active',
      userId:'',
      activateModal:false,
      error:{
        first_name: '',
        designation: '',
        email: '',
        contact_no: ''
      },
      filterParam:{
        sort: '',
        sort_by: ''
      },
      userType: ''
    }
  }
  filterUserManagement = (type) =>{
    this.setState(prevState => ({
      ...prevState,
      filterParam:{
        sort: type,
        sort_by: prevState.filterParam.sort == type ? prevState.filterParam.sort_by == 'asc' ? 'desc' : 'asc' : 'asc'
      },
      page: 1
    }));
    this.props.getUsersList( 1 , this.state.searchText , { sort: type,sort_by: this.state.filterParam.sort == type ? this.state.filterParam.sort_by == 'asc' ? 'desc' : 'asc' : 'asc' }, this.state.activeUser);
  }

  addEditUser = () =>{
    this.setState({
      error:{
        first_name: '',
        designation: '',
        email: '',
        contact_no: ''
      }
    });
    this.props.addEditUser();
  }

  addNewUser = () =>{
    if(getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 1){
      if(this.props.usersUsage.available == this.props.usersUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }else if(getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type == 1 && getLocalStorageInfo().user.permission.console.is_saas){
      if(this.props.usersUsage.available == this.props.usersUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }
    this.props.addEditUser();
  }

  updateError = (key) =>{
    this.setState(prevState => ({
      ...prevState,
        error:{
          ...prevState.error,
          [key]:''
        }
    }));
  }

  toggleactivateModal=(userId)=>{
    this.setState(prevState=>({
      ...prevState,
      userId:userId,
      activateModal:!this.state.activateModal,
    }));
  }

  createUser = (userData) => {
    const  { first_name, email, contact_no, designation } = userData;
    const validateNewInput = {
      first_name: checkString({value: first_name, required: true, minLength: '', maxLength: '', message: 'Please Enter First Name'}),
      email: checkEmail({value: email, required: true, minLength: '', maxLength: '', message: 'Please Enter Email'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.createUser(this.props.userData);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  handlePageChange = (page) => {
    let location = browserHistory.getCurrentLocation();
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    location = {
      ...location,
      query: {
        page: page
      }
    }
    browserHistory.push(location);
    this.props.getUsersList(page, this.state.searchText, this.state.filterParam, this.state.activeUser);
  }

  componentDidMount(){
    if(getLocalStorageInfo().user.permission.console.is_saas){
      this.props.getUsersUsage();
    }
    var location = browserHistory.getCurrentLocation();
    this.props.getUserEmail();
    this.props.getGroupsList();
    let page = location.query.page ? location.query.page:1
    this.props.getUsersList(page,'', '', 'active');
  }

  onChange = (value) => {
    this.setState(prevState => ({
      ...prevState,
      searchText: value,
    }), () => {
      if(this.state.searchText == ''){
        this.props.getUsersList(1,'', this.state.filterParam, this.state.activeUser)
      }
    })
  }

  filterUsers = (value) => {
    this.setState(prevState => ({
      ...prevState,
      activeUser: value,
      searchText: ''
    }), () => this.props.getUsersList(1,this.state.searchText, this.state.filterParam, this.state.activeUser))
  }

  globalSearch = (evt) => {
    evt.preventDefault();
    this.props.getUsersList(1, this.state.searchText, this.state.filterParam, this.state.activeUser)
  }


  render(){
    const userInfo = getLocalStorageInfo();
    const { usersList, downloadLoader, userCrudBar, userData, userEmails, groupListAll,userExportLoader } = this.props;
    const { passInfo, error  } = this.state
    var location = browserHistory.getCurrentLocation();
    var showContractor = userInfo.user.type && userInfo.user.type.value == 1 ? true : false
    return(
      <div className="contract-container user-profile-blk">
        <UserManageSideBar/>
      <h4 className="gama" style={{marginLeft: '60px'}}>
        <DeploymentMessage/>
        <div className="title-block">
            <h1>
              Users
              <ExportFiles style={{display: 'inline',marginLeft: '10px',color: '#4051b5'}} exportFile={(fileType) => this.props.userExport(fileType,userInfo.defaultLessor?userInfo.defaultLessor.name+'_Lease_Management_Users':'Lease_Management_Users',{filter_type: this.state.activeUser, search: this.state.searchText})} files={[{img:'xls_icon.png', label: 'XLS', type:'xls'}]} />
            </h1>
            <p className="block-subs">Contains the list of users</p>
        </div>
        <div id="user-search-form">
         {userInfo.user.permission.console.manage_user.indexOf('C') == -1 ? null:
         <button type="button" className="btn btn-primary btn-new btn-sm" onClick={() => window.open(`${config.domain.subDomian}users/manage?addUser=true`, '_blank')} style={{marginLeft: '10px', backgroundColor:'#3f51b5', borderColor:'#3f51b5'}}>Add User</button>
          }
        <form onSubmit={(evt) => this.globalSearch(evt)} style={{display: 'inline'}}>
          <span className="profile-filter">
            <Select
              name="form-field-name"
              placeholder="User Status"
              className="custom-select-block"
              clearable={false}
              value={this.state.activeUser}
              options = {[{label: usersList && usersList.active_users_count ? `All (${usersList.active_users_count+usersList.inactive_users_count+usersList.invitation_sent_users_count})` : 'All (0)', value:''},{label: usersList && usersList.active_users_count ? `Active (${usersList.active_users_count})` : 'Active (0)', value:'active'},{label: usersList && usersList.inactive_users_count ? `Deactivated (${usersList.inactive_users_count})` : 'Deactivated (0)', value:'inactive'},{label: usersList && usersList.invitation_sent_users_count ? `Invitation Sent (${usersList.invitation_sent_users_count})` : 'Invitation Sent (0)', value:'invitation-sent'}]}
              labelKey='label'
              valueKey='value'
              onChange={(target) => this.filterUsers(target.value)}
            />
          </span>
          <span className="profile-search">
            <input type="text"  id="userSearch" value={this.state.searchText} onChange={(evt) => this.onChange(evt.target.value)} onKeyDown={(evt) => {
                if(evt.keyCode === 13){
                  this.props.getUsersList(1,this.state.searchText, this.state.filterParam, '')
                }
              }} placeholder="Search User..." autoComplete="off"/>
            <img src={imgStoragePath+"search_icn.png"} onClick={() => this.props.getUsersList(1,this.state.searchText, this.state.filterParam, '')} alt="icon"/>
            </span>
        </form>
        </div>
      </h4>
      <div className="users-list-wrapper">
        <table className="users-list">
            <thead>
              <tr style={{width:'30%'}}>
                <td className="td-userList">
                <h6>
                  <span onClick={() => this.filterUserManagement('name')}>
                    Name
                    <sup className="asc-sort" style={(this.state.filterParam.sort == 'name' && this.state.filterParam.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                    <sub className="desc-sort" style={(this.state.filterParam.sort == 'name' && this.state.filterParam.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                  </span>
                  </h6>
                </td>
                <td className="td-userList">
                  <h6>
                  <span onClick={() => this.filterUserManagement('email')}>
                    Email
                    <sup className="asc-sort" style={(this.state.filterParam.sort == 'email' && this.state.filterParam.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                    <sub className="desc-sort" style={(this.state.filterParam.sort == 'email' && this.state.filterParam.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                  </span>
                  </h6>
                  </td>
                <td>
                <h6>
                  <span >
                    Designation
                  </span>
                  </h6>
                </td>
                <td>
                <h6>
                  <span >
                    Status
                  </span>
                  </h6>
                </td>
              </tr>
            </thead>
            <tbody>
              { usersList && usersList.list && usersList.list.length && !downloadLoader ?
                usersList.list.map((user, index) => <UserList user={user} key={index} activateUser={this.toggleactivateModal} superAdmin={userInfo.user.permission.console.manage_user.indexOf('U') != -1 ?true:false}/>):null
              }
            </tbody>
          </table>
          { usersList && usersList.pagination && usersList.pagination.total == 0 ? <NoRecordFound/> : null }
      </div>
          { usersList && usersList.pagination && usersList.list.length && !downloadLoader?
            <Pagination
            activePage={parseInt(usersList.pagination.page)}
            itemsCountPerPage={20}
            totalItemsCount={usersList.pagination.total ? usersList.pagination.total:0}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />:null
        }

      {userExportLoader ? <ListLoader/>:null}
      {downloadLoader ? <ListLoader/>:null}
        <EditFormBar
         titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}> Add User </h3></div>}
         toggleEditSideBar={this.addEditUser} isOpen={userCrudBar} style={userCrudBar ? {width: '580px'} : {width: '0px'}}>
          { userCrudBar ?
            <AddUser
              type={'new'}
              inviteUser={this.props.createUser}
              toggleEditSideBar={this.addEditUser}
              user={userData}
              userEmails={userEmails}
              groupList={groupListAll}
              downloadLoader={downloadLoader}
              showContractor={showContractor}
            /> : null
          }
        </EditFormBar>
        <DeleteModal title={"Reactivate User"} isOpen={this.state.activateModal} className="modal-dialog-centered" toggle={() => this.toggleactivateModal('')}>
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to reacivate user ?</p>
            <ul className="list-inline" style={{textAlign: 'center'}}>
              <li className="list-inline-item">
                <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.activateUser(this.state.userId); this.toggleactivateModal('')}} >Yes</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleactivateModal('')}>No</button>
              </li>
            </ul>
        </DeleteModal>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="1"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  usersList:state.userAssetReducer.usersList,
  downloadLoader:state.userAssetReducer.downloadLoader,
  userData:state.userAssetReducer.userData,
  userCrudBar:state.userAssetReducer.userAddCrudBar,
  userEmails: state.userAssetReducer.userEmails,
  groupListAll:state.userAssetReducer.groupListAll,
  userExportLoader:state.userAssetReducer.userExportLoader,
  usersUsage:state.userAssetReducer.usersUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
})
const mapDispatchToProps = dispatch => {
  return {
    getUsersList: (page, name, filter, type) => dispatch(getUsersListAc(page, name, filter, type)),
    createUser: (data) => dispatch(createUserAc(data)),
    activateUser: (userID) => dispatch(activateUserAc(userID)),
    getUserEmail: () => dispatch(getUserEmailAc()),
    userExport: (type,name, filters) =>dispatch(downLoadUserFormAc(type, name, filters)),
    getGroupsList: () => dispatch(getGroupsListAc()),
    addEditUser: () => dispatch({
      type: actions.USER_ADD_CRUD_BAR,
      payload: {}
    }),
    onBlur: (value, type) => dispatch({
      type: actions.SET_USER_DATA,
      payload:{
        type,
        value
      }
    }),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getUsersUsage: () => dispatch(getUsersUsageAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
