import React, { Component } from 'react';
import { Col, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import NumericLabel from 'react-pretty-numbers';
import { showCurrencyFormat } from '../../../utils';
import { prettyNumberParamsDB, imgStoragePath } from '../../../constants';
import { ToolTipHover, InfoIcon } from '../../../shared';

export default class InvoiceCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    const { invoiceSummaryWidget, dbInvoiceLoader, col } = this.props;
    return (
      <Col md={col ? col : "3"} className="invoice-info-card">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              MR
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Rental
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>

          <TabPane tabId="1">
            {!dbInvoiceLoader ?
              <div>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h6>Paid</h6>
                    <p>{invoiceSummaryWidget.mr ? invoiceSummaryWidget.mr.paid : '--'}</p>
                  </li>
                  <li className="list-inline-item">
                    <h6>Overdue</h6>
                    <p>{invoiceSummaryWidget.mr ? invoiceSummaryWidget.mr.overdue : '--'}</p>
                  </li>
                </ul>
                <div className="payment-info">
                  <h6>Invoices in default (Total Amt.)

                    <InfoIcon id="Invoices-default-1" url={imgStoragePath + "db_info_tl.png"} />
                  </h6>
                  <ToolTipHover placement="top" tagetId="Invoices-default-1">
                    Sum of MR invoices yet to be paid and is overdue
                  </ToolTipHover>
                  <p>
                    <NumericLabel params={showCurrencyFormat(invoiceSummaryWidget.currency)}>
                      {invoiceSummaryWidget.mr ? invoiceSummaryWidget.mr.overduePayment : 0}
                    </NumericLabel>
                  </p>
                </div>
                <div className="payment-info">
                  <h6>Invoice awaiting payments (Total Amt.)

                    <InfoIcon id="Invoices-await-1" url={imgStoragePath + "db_info_tl.png"} />
                  </h6>
                  <ToolTipHover placement="top" tagetId="Invoices-await-1">
                    Sum of MR invoices sent and yet to be paid
                  </ToolTipHover>
                  <p>
                    <NumericLabel params={showCurrencyFormat(invoiceSummaryWidget.currency)}>
                      {invoiceSummaryWidget.mr ? invoiceSummaryWidget.mr.awaitingPayment : 0}
                    </NumericLabel>
                  </p>
                </div>
              </div>
              :
              <span style={{ color: '#fff', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%', display: 'block', padding: ' 80px 0' }}>Loading...</span>
            }

          </TabPane>
          <TabPane tabId="2">
            {!dbInvoiceLoader ?
              <div>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h6>Paid</h6>
                    <p>{invoiceSummaryWidget.rental ? invoiceSummaryWidget.rental.paid : '--'}</p>
                  </li>
                  <li className="list-inline-item">
                    <h6>Overdue</h6>
                    <p>

                      {invoiceSummaryWidget.rental ? invoiceSummaryWidget.rental.overdue : '--'}
                    </p>
                  </li>
                </ul>
                <div className="payment-info">
                  <h6>Invoices in default (Total Amt.)
                    <InfoIcon id="Invoices-default-2" url={imgStoragePath + "db_info_tl.png"} />
                  </h6>
                  <ToolTipHover placement="top" tagetId="Invoices-default-2">
                    Sum of Rental invoices yet to be paid and is overdue
                  </ToolTipHover>
                  <p>
                    {invoiceSummaryWidget.rental ?
                      invoiceSummaryWidget.rental.overduePayment_new ?
                        invoiceSummaryWidget.rental.overduePayment_new.length ?
                          invoiceSummaryWidget.rental.overduePayment_new.map((rental, index) =>
                            <div>
                              <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                            </div>
                          ) : '$0.00' : '$0.00' : '$0.00'
                    }
                  </p>
                </div>
                <div className="payment-info">
                  <h6>Invoice awaiting payments (Total Amt.)
                    <InfoIcon id="Invoices-pending-2" url={imgStoragePath + "db_info_tl.png"} />
                  </h6>
                  <ToolTipHover placement="top" tagetId="Invoices-pending-2">
                    Sum of Rental invoices sent and yet to be paid
                  </ToolTipHover>
                  <p>
                    {invoiceSummaryWidget.rental ?
                      invoiceSummaryWidget.rental.awaitingPayment_new ?
                        invoiceSummaryWidget.rental.awaitingPayment_new.length ?
                          invoiceSummaryWidget.rental.awaitingPayment_new.map((rental, index) =>
                            <div>
                              <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                            </div>
                          ) : '$0.00' : '$0.00' : '$0.00'
                    }
                  </p>
                </div>
              </div>
              :
              <span style={{ color: '#fff', justifyContent: 'center', dispaly: 'block', textAlign: 'center', width: '100%', display: 'block', padding: ' 80px 0' }}>Loading...</span>
            }
          </TabPane>
        </TabContent>
      </Col>
    )
  }
}
