import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory, Link } from 'react-router';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Header, ContractHeadertext, EditSidebar, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import AddEditReturnCond from '../components/AddEditReturnCond';
import ReturnCondRow from '../components/ReturnCondRow';
import { returnCondListAc, retuenConditionCrudAc, addEditReturnCondAc } from '../actionCreators';
import {  contractCondnAc } from '../../InductionConditions/actionCreators';
import { sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { contrDbFlaggedCondAc, exportReturnCondnAc } from '../../Dashboards/actionCreators';
import ReturnVsDeliveryPop from '../../Dashboards/components/ReturnVsDeliveryPop';
import { TOGGLE_VIEW_EDIT, RETURN_COND_CRUD } from '../actions';
import { CLEAR_RETURN_COND } from '../../Dashboards/actions';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { imgStoragePath, assetsType } from '../../../../constants';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class ReturnCondition extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){
    if(this.props.contractCondn.length == 0){
      this.props.fetchcontractCondnList();
    }
    this.props.fetchReturnCondList();
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  toggle = () => {
    this.props.closeReturnCondModal();
  }
  render() {
    const { returnCondList, contractCondn, returnCondLoader, returnCondCrud, returnCondCompare, auditLogView, assetViewId, params } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} pageTitle="Return Conditions" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`Return Vs Delivery Condition`} type="brief" params= {this.props.params} />
        <div className="contract-content-blk">
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          <ContractHeadertext
            title={<span>Return Conditions <span className="return-cond-text hidden-xs"  onClick={(evt) => this.props.compareReturnCond(this.props.params.contract_slug,{})}><img width="30" src={imgStoragePath+"compare_conditions.png"} alt="icon" /> Return Vs Delivery</span></span>}
            desc="Section  holds all Return / Re-delivery Conditions agreed in the Lease"
            addNew={getLocalStorageInfo().user.permission['contracts']['return_conditions'].indexOf('C') != -1 ? '+ Add Return Condition':''}
            toggleAddEvent={() => this.props.retuenConditionCrud('add', {})}
            addAudit= {auditLogView ?  "Revision History" : null}
            toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctreturnconditionitems', 0, null, 'add',this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Return Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Return Condition'})}}
          />
          <div className="list-container">
            { returnCondLoader ? <ListLoader />:null }
            <div className="table-container">
              <table>
                <CtrTableHd theads={['Clause', 'Terms', 'Flag']}/>
                <tbody>
                  {returnCondList.map((returnCond,index) =>
                    <ReturnCondRow key={index} returnCond={returnCond} viewReturnCond={() => this.props.retuenConditionCrud('view', returnCond.id)} />)
                  }
                </tbody>
              </table>
              {!returnCondLoader && !returnCondList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Return Condition' button above" />:null
              }
            </div>
          </div>
        </div>
        <EditSidebar
          title={
            returnCondCrud.type != '' ?
            (returnCondCrud.type == 'add') ? 'Add Return Condition':
            (returnCondCrud.type == 'edit') ? 'Edit Return Condition': 'View Return Condition' : ''
            }
          editSideBar={returnCondCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(returnCondCrud.data).length && returnCondCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>{this.props.toggleAuditlogFn('contracts', 'ctreturnconditionitems', 1, returnCondCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Return Condition', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Return Condition', item_id:returnCondCrud.data.id})}}
                type={returnCondCrud.type}
                changeViewMode={()=>this.props.changeViewMode(returnCondCrud.type)}
                editAble = {getLocalStorageInfo().user.permission['contracts']['return_conditions'].indexOf('U') != -1 ? true:false}
              />
              <AddEditReturnCond
                type={returnCondCrud.type}
                contractCondn={contractCondn}
                returnCondObj={returnCondCrud.data}
                addEditReturnCond={(data, type)=>this.props.addEditReturnCond(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                returnCondLoader={returnCondLoader}
              />
            </div>:null
          }
        </EditSidebar>

         <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>

        <Modal isOpen={returnCondCompare.flag} size="lg" toggle={this.toggle} className="return-condition-modal">
          <ModalHeader toggle={this.toggle}>Return Vs Delivery Conditions
          {
            returnCondList.length ?
          	<span onClick={() => this.props.exportReturnCondn(assetViewId)} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px'}}><img style={{marginRight: '4px'}} width="18" src={exportIcon} alt="export" />EXPORT</span>
            : null
          }

          </ModalHeader>
          <ModalBody>
            <ReturnVsDeliveryPop returnCondCompare={returnCondCompare} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  returnCondList: state.ContrReturnCondReducer.returnCondList,
  returnCondLoader: state.ContrReturnCondReducer.returnCondLoader,
  returnCondCrud: state.ContrReturnCondReducer.returnCondCrud,
  contractCondn: state.ContrInducCondReducer.contractCondn,
  returnCondCompare: state.ContrDashboardReducer.returnCondCompare,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchReturnCondList: () => dispatch(returnCondListAc(ownProps.params)),
    addEditReturnCond: (data, type, assetViewId) => dispatch(addEditReturnCondAc(ownProps.params, data, type, assetViewId)),
    fetchcontractCondnList : () => dispatch(contractCondnAc(ownProps.params)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    retuenConditionCrud: (type, id) => dispatch(retuenConditionCrudAc(ownProps.params, type, id)),
    compareReturnCond:(contract_slug, queryParams) => dispatch(contrDbFlaggedCondAc(contract_slug, queryParams)),
    toggleAuditlogFn: (application, content_type, action, objectId, type,paramKey=null,paramValue=null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramKey, paramValue)),
    toggleEditBar: (data) => dispatch({
      type: RETURN_COND_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    closeReturnCondModal: () => dispatch({
      type: CLEAR_RETURN_COND,
      payload: ''
    }),
    exportReturnCondn: (contract_slug) => dispatch(exportReturnCondnAc(contract_slug)),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ReturnCondition, ['contracts', 'return_conditions', 'R'])));
