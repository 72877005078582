import React from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, imgStoragePath } from '../../../../constants';
import { trackActivity } from '../../../../utils/mixpanel';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
const AfViewCard = (props) => {
  return (
    <div className="card-body">
      {
        props.shopVisit.status.value == 3 || props.shopVisit.status.value == 4 ?
        null:<span className="edit-icon" onClick={props.editAssembly} style={{float: 'right'}}><img src={editIcon} alt="icon" /></span>
      }
      <div className="shop-visit-status">
        <ul className="list-inline">
          {
            props.shopVisit.status.value == 0 || props.shopVisit.status.value == 1 ?
            <li className="list-inline-item">
              <span>
              {props.assemblyType=='airframe'?
                'Event Details Submitted': 'Shop Visit Details Submitted'}
              </span>
              <button disabled={props.loader} onClick={() => {props.changeSVStatus({status: 2}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Event', item_id:props.shopVisit.id, status:'Move to Review' })}} className="btn-primary add-new">{props.loader ? 'Processing...':'Move to Review'}</button>
            </li>:null
          }
          {
            props.shopVisit.status.value == 2 ?
            <li>
              <span>
                {props.assemblyType=='airframe'?
                'Event is In-Review': 'Shop Visit is In-Review'}
              </span>
              <button disabled={props.loader} onClick={() => {props.changeSVStatus({status: 3}); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Event', item_id:props.shopVisit.id, status:'Approve' })}} className="btn-primary add-new">{props.loader ? 'Processing':'Approve' }</button>
              <button onClick={()=>{ props.toggle(); trackActivity('Item Status Changed', { page_title: 'Major Assemblies Airframe', application_module: 'Maintenance',  item_type:'Claim', item_id:props.shopVisit.id, status:'Reject' })}} className="btn-primary add-new">Reject</button>
            </li>:null
          }
        </ul>
      </div>
      <Row>
        <Col md="3" className="label-card">
          <h6 className="label-title">{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Number </h6>
          <span className="label-value">{props.shopVisit.shop_visit_number}</span>
        </Col>
        <Col md="3" className="label-card">
          <h6 className="label-title">{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Facility </h6>
          <span className="label-value">{props.shopVisit.shop_visit_facility ? props.shopVisit.shop_visit_facility: '--'}</span>
        </Col>
        <Col md="3" className="label-card">
          <h6 className="label-title">Facility Location </h6>
          <span className="label-value">{props.shopVisit.facility_location ? props.shopVisit.facility_location:'--'}</span>
        </Col>
        <Col md="3" className="label-card">
          <h6 className="label-title">{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Cost </h6>
          <span className="label-value">
            <NumericLabel params={prettyNumberParamsL}>
              {props.shopVisit.cost}
            </NumericLabel>
          </span>
        </Col>
        <Col md="6" className="label-card">
          <h6 className="label-title">{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Reason</h6>
          <span className="label-value">{props.shopVisit.removal_reason}</span>
        </Col>
        <Col md="6" className="label-card">
          <h6 className="label-title">{props.assemblyType=='airframe'? 'Event': 'Shop Visit'} Workscope / Details</h6>
          <span className="label-value">
            {props.shopVisit.workscope_details ? props.shopVisit.workscope_details:'--'}
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="label-card">
          <h6 className="label-title">TSN at {props.assemblyType=='airframe'? 'Event': 'Shop Visit'}</h6>
          <span className="label-value">{props.shopVisit.tsn}</span>
        </Col>
        <Col className="label-card">
          <h6 className="label-title">CSN at {props.assemblyType=='airframe'? 'Event': 'Shop Visit'}</h6>
          <span className="label-value">{props.shopVisit.csn}</span>
        </Col>
        <Col className="label-card">
          <h6 className="label-title">Status</h6>
          <span className="label-value">{props.shopVisit.status.label}</span>
        </Col>
        {
          props.shopVisit.status.label == 'Rejected' ?
            <Col className="label-card">
              <h6 className="label-title">Rejection Reason</h6>
              <span className="label-value">{props.shopVisit.reason_for_rejection}</span>
            </Col>:<Col></Col>
        }
      </Row>
    </div>
  )
}
export default AfViewCard;
