import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { browserHistory, Link } from 'react-router';
import { TextField } from '@material-ui/core';
import  GetInputField  from '../../../applications/technicalInspection/Elements/GetInputField';
import { globalPostService} from '../../../globalServices';
import { toastFlashMessage } from '../../../utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
class OTPVerification extends Component {
  constructor(props){
    super(props);
    this.state = {
        userInfo: {
            otp:'',
        },
        error: {},
        loader: false,
    };
  }

  handleUserChange = (key, value) =>{
    value = value.trim();
    this.setState(prevState => ({
        ...prevState,
        userInfo: {
            ...prevState.userInfo,
            [key]: value
        },
        error: {
            ...prevState.error,
            [key]: '',
        }
    }))
  }

  forgotFormSubmit = (e) => {
    e.preventDefault();
    const { userInfo } = this.state;
    if(userInfo.otp.trim() == ''){
      this.setState({error: {otp: 'OTP is required'}})
      return
    }else{
        this.setState(prevState => ({
            ...prevState,
            loader: true,
        }));
        globalPostService('api/pass-verify-otp/',{
                        "token": this.props.params.params.token,
                        "otp": userInfo.otp
                    })
        .then( response => {
            this.setState(prevState => ({
                ...prevState,
                loader: false,
            }));
            if(response.data.statusCode == 200){
                  toastFlashMessage('Account verified.', 'success');
                  localStorage.clear()
                  browserHistory.push('/login');
            }else if(response.data.statusCode == 1008){
              this.setState({
                error: {
                  emailMsg : response.data.validation_error.email ? response.data.validation_error.email : "",
                }
            });
            }
        });
    }
  }

  resendOtp = () =>{
    globalPostService('api/pass-resend-otp/',{
                    email: localStorage.getItem('userEmail'),
                    "token": this.props.params.params.token,
                })
    .then( response => {
        this.setState(prevState => ({
            ...prevState,
            loader: false,
        }));
        if(response.data.statusCode == 200){
              toastFlashMessage(response.data.message, 'success');
        }else if(response.data.statusCode == 1008){
          this.setState({
            error: {
              emailMsg : response.data.validation_error.email ? response.data.validation_error.email : "",
            }
        });
        }
    });
  }

  render(){
    const { userInfo, error } = this.state;
    return(
      <div className="login-form">
            <Link to="/login" className="back-to-login"><ArrowBackIcon/> Back to Login</Link>
            <h2 className="form-title">2-Step Verification</h2>
            <p className="form-subtitle">A one time password has been sent to your email address </p>
            <div>
                <form onSubmit={this.forgotFormSubmit}>
                    <Row>
                      <Col md="12">
                        <TextField
                          type="add"
                          id="email"
                          fullWidth
                          margin="normal"
                          value={userInfo.otp}
                          error={error.otp ? true:false }
                          helperText={error.otp}
                          InputLabelProps={{shrink: true}}
                          onChange={(e) => {this.handleUserChange('otp', e.target.value)}}
                        />
                        </Col>
                        <Col md="12" className="text-center">
                            <p className="terms-and-conditions">OTP not received yet? <a onClick={() => this.resendOtp()}>Resend OTP</a></p>
                        </Col>
                        <Col md="12" className="text-center">
                            <input type="submit" style={ this.state.loader ? { pointerEvents: 'none' } : {pointerEvents: 'unset'}}   value={ this.state.loader ? "Processing..." : "Submit OTP"  } />
                        </Col>
                    </Row>
                </form>
            </div>
      </div>
    )
  }
}

export default  OTPVerification
