import React from 'react';
import { TableRow, TableCell, TextField, IconButton, Tooltip } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { regexConstants } from '../../../constants/regEx';
import { getCurrencyFormat } from '../../../utils_v2';
const EditCreditDebitList = ({invoice_item, mode, onFieldChange}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { mode === 'view' ? invoice_item.description:
          <TextField
            id="description"
            fullWidth
            margin="normal"
            value={invoice_item.description}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 255 }}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? <NumericLabel params={getCurrencyFormat()}>{invoice_item.rate_per_unit}</NumericLabel>:
          <TextField
            id="rate_per_unit"
            fullWidth
            margin="normal"
            value={invoice_item.rate_per_unit}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'rate_per_unit', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.tax_rate:
          <TextField
            id="tax_rate"
            fullWidth
            margin="normal"
            value={invoice_item.tax_rate}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 6 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'tax_rate', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.tax_amount ? invoice_item.tax_amount:0}</NumericLabel></TableCell>
      <TableCell><NumericLabel params={getCurrencyFormat({shortFormat:false})}>{invoice_item.sub_total ? invoice_item.sub_total:0}</NumericLabel></TableCell>
    </TableRow>
  )
}
export default EditCreditDebitList;
