import React, { Component} from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {  checkValidDate} from '../../../formValidator';
import { fieldDateFormat } from '../../../constants';


class ContractDateFilter extends Component{
    constructor(props){
        super(props);
        this.state = {
          error: {},
          slug: '',
          asset_type: 1
        }
    }

    handleFocus = (type) => {
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            [type] : ''
          }
        }))
      }
      onBlur = (type) => {
        this.setState(prevState => ({
          ...prevState,
          error: {
            ...prevState.error,
            [type] : ''
          }
        }))
      }

      changeFilter = (value, type, objValue = {}) => {

        let validateNewInput = {};
        if(type == 'msnCashflowSlug'){
          this.props.fetchCashflow({'slug':value.slug, asset_type: value.asset_type});
        }else if(type == 'start_date' && objValue == 'fleetSummaryWidget'){
         const fleetstartDateErr = checkValidDate({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter Start Date'});
         if(fleetstartDateErr.length ){
            this.setState(prevState => ({
              ...prevState,
                error: {
                  ...prevState.error,
                  'fleetstartDateErr': fleetstartDateErr
              }
            }));
          }else{
            this.props.fetchCashflow({'slug':this.props.assetSlug, asset_type: this.props.asset_type, 'end_date': this.props.data.end_date ,'start_date': value});
          }
        }else if(type == 'end_date' && objValue == 'fleetSummaryWidget'){
          const fleetEndDateErr = checkValidDate({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter End Date'});
          if(fleetEndDateErr.length  ){
            this.setState(prevState => ({
              ...prevState,
                error: {
                  ...prevState.error,
                  'fleetEndDateErr': fleetEndDateErr
              }
            }));
          }else{
            this.props.fetchCashflow({'slug':this.props.assetSlug, asset_type: this.props.asset_type, 'end_date': value ,'start_date': this.props.data.start_date });
          }
        }else if(type == 'msnUtilSlug'){
          this.props.fetchUtilisationSummary({'slug':value.slug, asset_type: value.asset_type});
        }else if(type == 'start_date' && objValue == 'utilsSummaryWidget'){
          const utilStartDateErr = checkValidDate({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter Start Date'});
          if(utilStartDateErr.length ){
            this.setState(prevState => ({
              ...prevState,
                error: {
                  ...prevState.error,
                  'utilStartDateErr': utilStartDateErr
              }
            }));
          }else{
            this.props.fetchUtilisationSummary({'slug':this.props.assetSlug, asset_type: this.props.asset_type, 'end_date': this.props.data.end_date ,'start_date': value});
          }

        }else if(type == 'end_date' && objValue == 'utilsSummaryWidget'){
          const utilEndDateErr = checkValidDate({value: value, required: true, minLength: '', maxLength: '', message: 'Please Enter End Date'});
          if(utilEndDateErr.length ){
            this.setState(prevState => ({
              ...prevState,
                error: {
                  ...prevState.error,
                  'utilEndDateErr': utilEndDateErr
              }
            }));
          }else{
            this.props.fetchUtilisationSummary({'slug':this.props.assetSlug, asset_type: this.props.asset_type, 'end_date': value ,'start_date': this.props.data.start_date });
          }
        }
      }


    render() {
        const { data, contractMsn, startDateParam, endDateParam, filterCallParam, disabledAttr, assetSlug, slugType, text } = this.props;
        const { error } = this.state;
        return(
            <div className="msn-sw">
            { (data.start_date && data.end_date) ?
              <div className="date-range-block flex-centered">
                <div className="date-block date-from flex-not-centered">
                  <label>From</label>
                  <DatePicker
                    dateFormat = {fieldDateFormat}
                    selected={moment(data.start_date)}
                    selectsStart
                    startDate={moment(data.start_date)}
                    endDate={moment(data.end_date)}
                    onChange={(e) =>this.changeFilter(moment(e).format('YYYY-MM-DD'), 'start_date', filterCallParam)}
                    onFocus={() => this.handleFocus(startDateParam)}
                    onBlur={(evt) => this.onBlur(startDateParam)}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    maxDate={moment(data.end_date)}
                    disabled = {disabledAttr}
                  />
                  <h6 className="error-msg">{error.startDateParam}</h6>
                </div>
                <div className="date-block date-to flex-not-centered">
                  <label>To</label>
                  <DatePicker
                    dateFormat = {fieldDateFormat}
                    selected={moment(data.end_date)}
                    selectsEnd
                    startDate={moment(data.start_date)}
                    endDate={moment(data.end_date  )}
                    onChange={(e) => this.changeFilter(moment(e).format('YYYY-MM-DD'), 'end_date', filterCallParam)}
                    onFocus={() => this.handleFocus(endDateParam)}
                    onBlur={(evt) => this.onBlur(endDateParam)}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    minDate= {moment(data.start_date)}
                    disabled = {disabledAttr}
                 />
                <h6 className="error-msg">{error[endDateParam]}</h6>
               </div>
             </div>:null
            }
            <div className="select-contract-block flex-centered">
              <label className="cashflow-title">{text} summary of</label>
              <Select
                name="form-field-name"
                placeholder="Select MSN"
                value={assetSlug}
                labelKey='serial_number'
                valueKey='slug'
                options = {contractMsn}
                onChange = {(evt) => this.changeFilter(evt, slugType)}
                disabled = {disabledAttr}
                clearable={false}
                />
            </div>
          </div>


        )
    }
}

export default ContractDateFilter;
