import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, Nav, NavItem, NavLink, TabPane, TabContent, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldInput} from '../../../applications/contracts/Elements';
import { ListLoader, ToolTipHover, InfoIcon, NoRecordFound } from '../../../shared'
import { sLesseeListAc } from '../../../shared/actionCreators'
import { globalGetService } from '../../../globalServices';
import { TYPE_LESSOR_UPDATE,LESSOR_FIELD_UPDATE} from '../actions';
import { EditFormBar, DeleteModal} from '../../../applications/technicalInspection/Elements';
import classnames from 'classnames';
import CKeditor from "../../../shared/CKEditor";
import Select , { Creatable } from 'react-select';
import { toastFlashMessage, getLocalStorageInfo } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel'
import {  OwnerCard, BankingDetail }  from '../components';
import { imgStoragePath, emailRegx } from '../../../constants';
import { checkString } from '../../../formValidator';
import editIcon from '../../../shared/assets/img/edit_icon.svg';
import { editLessorAc, downLoadLessorTempAC, uploadLessorTempAc, updateAutoPublishAc, getOwnerAc, addEditOwnerAc, deleteOwnerAc, deleteBankingDetailAc, addEditBankingInfoAc, getBankingDetailsAc, getBankingDetailsDropdownAc, getOwnerDropdownListAc } from '../actionCreators';
const numericWithHyphen = /^[\d\-]+$/
class ConfIndex extends Component{
  constructor(props){
    super(props);
    this.state = {
      updateImageFlag:false,
      error:{},
      bankingDropdownName: null,
      rejection_reason: '',
      rejection_reason_error: '',
      activeTab: 1,
      subTab: 1,
      addEmail: false,
      editMode: false,
      viewMode: false,
      deleteEmail: false,
      deleteOwnerModal:false,
      deleteBankingModal: false,
      addModal: false,
      addOwner: false,
      addBankingDetails: false,
      typeBanking:true,
      rejectModal: false,
      activeBank: {},
      viewDetails:false,
      distributionEmail: {
        lessee_id: '',
        email: []
      },
      esistinglessess: [],
      owners:{},
      ownersAndLessors:{},
      bankingDetail:{},
      defaultLessor:{},
      ownerError:{},
      bankingError:{},
      bankingDropdownList:[],
      bankingDropdownValue:'',
      bankingStatus:null,
      baseOwner:{}
    }
  }
  componentDidMount(){
    this.props.getLesseeList()
    this.props.getOwnerList()
    this.props.getBankingDetails()
    this.props.getBankingDetailsDropdown()
    this.props.getOwnerDropdownList()
    // this.getBankingDetailsDropdown()
    // this.getOwnerDropdownList()
    this.setState({
      owners: getLocalStorageInfo().defaultLessor.owners[0],
      defaultLessor:getLocalStorageInfo().defaultLessor,
      baseOwner:{
        name:getLocalStorageInfo().defaultLessor.name,
        contact:getLocalStorageInfo().defaultLessor.email,
        jurisdiction:getLocalStorageInfo().defaultLessor.jurisdiction,
        address:getLocalStorageInfo().defaultLessor.contact_address
      }
    })
    trackActivity('Page Visited', {
      application_module: 'Configuration',
      page_title: 'Configure',
    })
  }

  getOwnerDropdownList = () => {
    globalGetService(`console/owner/?dropdown=true`)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          ownerDropdownList:response.data.data,
        }))
        this.setState({
          ownerDropdownList:response.data.data,

        })
      }
    })
  }
  editUserInfo = (e) => {
    e.preventDefault();
    let objError = {
        name: this.props.lessorObj.name && this.props.lessorObj.name.trim()!=""? '':'This Field is Required',
       }
     if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
     var formData = new FormData();
     if(this.state.updateImageFlag){
      formData.append("logo", this.state.imageFile);
      }
      formData.append("contact_address", this.props.lessorObj.contact_address?this.props.lessorObj.contact_address:'');
      formData.append("country", this.props.lessorObj.country?this.props.lessorObj.country:'');
      formData.append("name", this.props.lessorObj.name ? this.props.lessorObj.name : '');
      this.props.lessorInfoUpdate(formData);
      }else{
        this.setState({
          error: objError,
        });
       }
      }
  editBankInfo = (e) => {
    e.preventDefault();
    let objError = {
          bank_name: this.props.lessorObj.bank_name && this.props.lessorObj.bank_name.trim()!=""? '':'Please enter Bank Name',
          bank_account_name: this.props.lessorObj.bank_account_name && this.props.lessorObj.bank_account_name.trim()!=""? '':"Please enter Account Holder Name",
          bank_account_number: this.props.lessorObj.bank_account_number && this.props.lessorObj.bank_account_number.trim()!=""? '':'Please enter Account Number',
        }
    if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
      var formData = new FormData();
      if(this.state.updateImageFlag){
        formData.append("logo", this.state.imageFile);
      }
      formData.append("contact_address", this.props.lessorObj.contact_address?this.props.lessorObj.contact_address:'');
      formData.append("country", this.props.lessorObj.country?this.props.lessorObj.country:'');
      formData.append("name", this.props.lessorObj.name ? this.props.lessorObj.name : '');
      formData.append("bank_name", this.props.lessorObj.bank_name ? this.props.lessorObj.bank_name : '');
      formData.append("bank_account_name", this.props.lessorObj.bank_account_name ? this.props.lessorObj.bank_account_name : '');
      formData.append("bank_account_number", this.props.lessorObj.bank_account_number ? this.props.lessorObj.bank_account_number : '');
      formData.append("bank_code", this.props.lessorObj.bank_code ? this.props.lessorObj.bank_code : '');
      formData.append("bank_iban", this.props.lessorObj.bank_iban ? this.props.lessorObj.bank_iban : '');
      formData.append("bank_sort_code", this.props.lessorObj.bank_sort_code ? this.props.lessorObj.bank_sort_code : '');
      formData.append("bank_swift_code", this.props.lessorObj.bank_swift_code ? this.props.lessorObj.bank_swift_code : '');
      formData.append("bank_reference", this.props.lessorObj.bank_reference ? this.props.lessorObj.bank_reference : '');
      formData.append("bank_note", this.props.lessorObj.bank_note ? this.props.lessorObj.bank_note : '');
      formData.append("footer_note", this.props.lessorObj.footer_note ? this.props.lessorObj.footer_note : '');
      formData.append("spv", this.props.lessorObj.spv ? this.props.lessorObj.spv : '');
      formData.append("jurisdiction", this.props.lessorObj.jurisdiction ? this.props.lessorObj.jurisdiction : '');
      formData.append('hub_number', this.props.lessorObj.hub_number ? this.props.lessorObj.hub_number : '');
      formData.append('vat_number', this.props.lessorObj.vat_number ? this.props.lessorObj.vat_number : '');
      this.props.lessorInfoUpdate(formData);
      }else{
        this.setState({
          error: objError,
        });
       }
      }
    editIntroInfo = (e) => {
        e.preventDefault();
        let phoneRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,'ig')
        let objError = {
            phone_number: this.props.lessorObj.phone_number && this.props.lessorObj.phone_number != '' && this.props.lessorObj.phone_number != null && !phoneRegex.test(this.props.lessorObj.phone_number) ? 'Please enter valid contact number' : '',
            email: this.props.lessorObj.email && this.props.lessorObj.email != '' && this.props.lessorObj.email != null && !emailRegx.test(this.props.lessorObj.email)? 'Please enter valid email id' : '',
            hub_number: this.props.lessorObj.hub_number != '' && this.props.lessorObj.hub_number !=null && !numericWithHyphen.test(this.props.lessorObj.hub_number) ? 'Please enter proper Hub account number' : '',
           }
         if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
         var formData = new FormData();
         if(this.state.updateImageFlag){
          formData.append("logo", this.state.imageFile);
          }
          formData.append("contact_address", this.props.lessorObj.contact_address?this.props.lessorObj.contact_address:'');
          formData.append("country", this.props.lessorObj.country?this.props.lessorObj.country:'');
          formData.append("name", this.props.lessorObj.name ? this.props.lessorObj.name : '');
          formData.append("introduction", this.props.lessorObj.introduction ? this.props.lessorObj.introduction : '');
          formData.append("disclaimer", this.props.lessorObj.disclaimer ? this.props.lessorObj.disclaimer : '');
          formData.append("confidentiality", this.props.lessorObj.confidentiality ? this.props.lessorObj.confidentiality : '');
          formData.append("email", this.props.lessorObj.email ? this.props.lessorObj.email : '');
          formData.append("phone_number", this.props.lessorObj.phone_number ? this.props.lessorObj.phone_number : '');
          formData.append("summary_tnc", this.props.lessorObj.summary_tnc ? this.props.lessorObj.summary_tnc : '');

        this.props.lessorInfoUpdate(formData);
      }else{
        this.setState({
          error: objError,
        });
       }
      }
      editOwnerInfo = (e) => {
        e.preventDefault()
        const { owners } = this.state
        let data = []
        let formData = new FormData()
        formData.append('owner_name', owners.owner_name ? owners.owner_name : ''  )
        formData.append('owner_address', owners.owner_address ? owners.owner_address: '')
        formData.append('owner_contact', owners.owner_contact ?owners.owner_contact :' ')
        formData.append('owner_jurisdiction', owners.owner_jurisdiction ? owners.owner_jurisdiction:'' )

        let validateNewInput = {
          owner_name: checkString({value: owners.owner_name, required:true, minLength:'', maxLength:'', message:'Please enter Name' })
        }
        if(Object.keys(validateNewInput).every((k) => {return validateNewInput[k] == ""})){
          this.props.lessorInfoUpdate(formData)
        }else {
          this.setState({
            error: validateNewInput
          })
        }
      }

      addEmails = () =>{
        let lesssees = []
        this.props.lessorObj.distribution_emails.map(email => {
          lesssees.push(email.lessee_id)
        })
        this.setState({
          esistinglessess: lesssees
        })
      }

      editEmail = (obj) =>{
        let emails = []
        obj.emails.map(email =>{
          emails.push({label: email})
        })
        this.setState({
            distributionEmail: {
              lessee_id: obj.lessee_id,
              emails: emails
            },
            esistinglessess: [],
            editMode: true,
            addEmail: true
          })
      }

    deleteEmail = () => {
      let emails = this.props.lessorObj.distribution_emails.filter(email => email.lessee_id !== this.state.distributionEmail.lessee_id)
      let formData = new FormData()
      formData.append('distribution_emails', JSON.stringify(emails))
      let lesseeName = this.props.sLesseeList.filter(lessee => lessee.id === this.state.distributionEmail.lessee_id).length ? this.props.sLesseeList.filter(lessee => lessee.id === this.state.distributionEmail.lessee_id)[0].name : ''
      this.props.lessorInfoUpdate(formData, `Distribution emails deleted  for ${lesseeName}.`, 'success');
      this.setState({
        deleteEmail: false,
        distributionEmail: {
          lessee_id: '',
          emails: []
        }
      })
    }

    handleEmailChange = (emails) =>{
      let mails = []
      this.setState({
        distributionEmail: {
          ...this.state.distributionEmail,
          emails: emails
        }
      })
    }

    saveEmails = (evt) =>{
      evt.preventDefault()
      evt.stopPropagation()
      let data  = this.state.distributionEmail
      let emails = []
      let validateNewInput = {
        lessee_id: data.lessee_id === '' ? 'Please select Lessee' :'',
      }
      if(data.emails.length === 0){
        validateNewInput = {
          ...validateNewInput,
          emails: 'Please enter proper emails'
        }
      }else {
        let emailsError = ''
        this.state.distributionEmail.emails.map(email => {
          if(!emailRegx.test(email.label.trim())){
            validateNewInput = {
              ...validateNewInput,
              emails: `Please enter valid email.`
            }
          }else{
            emails.push(email.label)
          }
        })
      }
      setTimeout(() => {
        if ((validateNewInput.emails && validateNewInput.emails !== '') || (validateNewInput.lessee_id && validateNewInput.lessee_id !== '')){
          this.setState({error: validateNewInput})
        }else {
          let formData = new FormData()
          data.emails = emails
          let distribution_emails = this.props.lessorObj.distribution_emails.filter(lessor => lessor.lessee_id === data.lessee_id).length ? this.props.lessorObj.distribution_emails.map(lessor => lessor.lessee_id === data.lessee_id ? {...lessor, emails: data.emails} : lessor) : [...this.props.lessorObj.distribution_emails, data]
          formData.append('distribution_emails', JSON.stringify(distribution_emails))
          let lesseeName = this.props.sLesseeList.filter(lessee => lessee.id === this.state.distributionEmail.lessee_id).length ? this.props.sLesseeList.filter(lessee => lessee.id === this.state.distributionEmail.lessee_id)[0].name : ''
          let emaiDiff = false
          let existingEmailsCount = 0
          existingEmailsCount = this.props.lessorObj.distribution_emails.filter(lessor => lessor.lessee_id === data.lessee_id).length ? this.props.lessorObj.distribution_emails.filter(lessor => lessor.lessee_id === data.lessee_id)[0].emails.length : 0
          if(existingEmailsCount > data.emails.length){
            this.props.lessorInfoUpdate(formData, `Distribution emails updated for ${lesseeName}.`, 'success');
          }else{
            this.props.lessorInfoUpdate(formData, `Distribution emails saved successfully for ${lesseeName}.`, 'success');
          }
          this.setState({
            addEmail: false,
            editMode: false,
            distributionEmail: {
              lessee_id: '',
              emails: []
            }
          })
        }
      }, 500)
    }

    updateForm = (type, value, id) => {
        if(type === 'distribution_emails'){
          let data = this.props.lessorObj.distribution_emails
          this.props.lessorObj.distribution_emails.map(email => {
            if(email.lessee_id === id){
              data = [...this.props.lessorObj.distribution_emails, {lessee_id: id, emails: value}]
            }else{
              data = [...this.props.lessorObj.distribution_emails, {lessee_id: id, emails: value}]
            }
          })
          this.props.updateForm({type:type,value:data})
          let error = this.state.error;
          delete error[type]
          this.setState({
            error
          })
        }else{
          this.props.updateForm({type:type,value:value})
          let error = this.state.error;
          delete error[type]
          this.setState({
            error
          })
        }
      }

      updateCkEditer = (evt, key) =>{
    		var newContent = evt.editor.getData();
        this.props.updateForm({type: key, value: newContent})
      }

    updateImage=(image)=>{

        if(image.type=='image/jpeg'||image.type=='image/png'){
          this.setState(prevState => ({
            ...prevState,
            updateImageFlag:true,
            imageFile:image
          }));
        this.props.updateForm({type:'logo',value:URL.createObjectURL(image)})
       }else{
        toastFlashMessage('Please add proper image', 'error');
       }
      }

      uploadTemp=(file,asstetype)=>{
        if(file.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        var formData = new FormData();
        formData.append("template_sheet",file );
        formData.append("asset_type", asstetype);
        this.props.lessorUpload(formData);
       }else{
        toastFlashMessage('Please add proper file', 'error');
       }
      }
      updateOwner = (type, value, id) => {
        this.setState(prevState => ({
          ...prevState,
          owners:{
            ...prevState.owners,
            [type]:value
          }
        }))
      }
      ownersAndLessors = (type, value, id) => {
        this.setState(prevState => ({
          ...prevState,
          ownersAndLessors:{
            ...prevState.ownersAndLessors,
            [type]:value
          },
          ownerError:{
            ...prevState.ownerError,
            [type]:''
          }
        }))
      }
      addEditOwner = (e) => {
        e.preventDefault()
        let data = this.props.ownerList
        const {ownersAndLessors} = this.state
        let validateNewInput = {
            name: checkString({value: ownersAndLessors.name, required:true, minLength:'', maxLength:'', message:'Please enter Name' })
          }
          if(Object.keys(validateNewInput).every((k) => {return validateNewInput[k] == ""})){
            this.props.addEditOwner(ownersAndLessors)
            this.setState({
              addOwner:false,
              ownersAndLessors:{}
            })
          }else {
            this.setState({
              ownerError: validateNewInput
            })
          }

      }
      editOwnerMode = (owner) => {
        this.setState({
          ownersAndLessors:owner,
          editMode:true,
          addOwner:true
        })
      }
      deleteOwnerMode = (owner) => {
        this.setState({
          deleteOwnerModal:true,
          ownersAndLessors:owner

        })
      }

      deleteOwner = () => {
        const { ownersAndLessors} = this.state
        this.props.deleteOwner(ownersAndLessors.id)
        this.setState({
          deleteOwnerModal:false,
          ownersAndLessors:{}
        })
      }
      filterByLessor = (value) => {
        const {bankingDropdownId, bankinglessorLevel, bankingDropdownName } = this.state
        this.setState({
          bankingDropdownId:value.id,
          bankinglessorLevel:value.lessor_level,
          bankingDropdownValue:value.name,
        })
        this.props.getBankingDetails(value.id, value.lessor_level, this.state.bankingDropdownName,this.state.bankingStatus)
      }

      filterByBank = (value) => {
        const {bankingDropdownId,bankinglessorLevel, bankingDropdownName} = this.state
          this.setState({
            bankingDropdownName:value.name,
          })
          this.props.getBankingDetails(this.state.bankingDropdownId,this.state.bankinglessorLevel,value.name, this.state.bankingStatus )
      }

      filterByStatus = (value) => {
          this.setState({
            bankingStatus:value.value,
          })
          this.props.getBankingDetails(this.state.bankingDropdownId,this.state.bankinglessorLevel, this.state.bankingDropdownName, value.value !== '' ? value.value : undefined )
      }
      addBanking = () => {
        let dropdown=this.props.ownerDropdownList.length  ?  this.props.ownerDropdownList.filter(owner => owner.lessor_level) : ''
        this.setState(prevState => ({
          ...prevState,
          addBankingDetails:true,
          editMode:false,
          bankingDetail:{
            lessor_level:dropdown[0].lessor_level,
            lessor:dropdown[0].id
          },
          error:{},

        }))
      }
      deleteBankingMode = (bank) => {
        this.setState({
          deleteBankingModal:true,
          bankingDetail:bank
        })
      }
      deleteBankingDetail = () => {
        const { bankingDetail } = this.state
        this.props.deleteBankingDetail(bankingDetail.id)
        this.setState({
          deleteBankingModal:false,
          bankingDetail:{}
        })


      }
      editBankingMode = (bank) => {
        this.setState({
          bankingDetail:{
            ...bank,
            lessor:bank.lessor ? bank.lessor.id:'',
            lessor_level : bank.lessor ? bank.lessor.lessor_level : 2 ,
            owner:bank.owner ? bank.owner.id : ''
          },
          editMode:true,
          addBankingDetails:true
        })
      }
      viewBankingMode=(bank)=>{
        this.setState({
          viewDetails:true,
          bankingDetail: bank,
        })
      }
      updateBankingDetails = (type, value, id) => {
        if(type == "lessor"){
          this.setState(prevState =>({
            ...prevState,
            bankingDetail:{
              ...prevState.bankingDetail,
              lessor:value.id,
              lessor_level:value.lessor_level
            }
          }))
        }else {
          this.setState(prevState => ({
            ...prevState,
            bankingDetail:{
              ...prevState.bankingDetail,
              [type]:value
            },
            bankingError:{
              ...prevState.bankingError,
              [type]:''
            }
          }))
        }

      }

      updateRejectStatus = () =>{
        if(this.state.rejection_reason.trim() === ''){
          this.setState({
            rejection_reason_error: 'Please enter reason for rejection'
          })
        }else{
          this.setState({
            rejection_reason_error: '',
            rejection_reason: '',
            activeBank: {},
            rejectModal:false
          })
          this.props.addEditBankingInfo({...this.state.activeBank, rejection_reason: this.state.rejection_reason, status: 2, lessor: this.state.activeBank.lessor.id, lessor_level: this.state.activeBank.lessor.lessor_level})
        }
      }

      submitBanking = () =>{
        const { bankingDetail } = this.state
        let validateNewInput = {
          lessor:checkString({value:bankingDetail.lessor, required:true, minLength:'', maxLength:'', message:'Please select Lessor'}),
          name:checkString({value:bankingDetail.name, required:true, minLength:'', maxLength:'', message:'Please enter Bank Name'}),
          account_name:checkString({value:bankingDetail.account_name, required:true, minLength:'', maxLength:'', message:'Please enter Account Holder Name'}),
          account_number:checkString({value:bankingDetail.account_number, required:true, minLength:'', maxLength:'', message:'Please enter Account Number'}),
        }
        if(Object.keys(validateNewInput).every((k) => { return (validateNewInput[k] === "" || validateNewInput[k] === false)})){
        this.props.addEditBankingInfo({...bankingDetail, status: 0})
        this.setState({
          addBankingDetails: false,
          bankingDetail:{}
        })
        }else {
        this.setState({
          bankingError:validateNewInput
        })
      }}

      addEditBankingInfo = (e) => {

        e.preventDefault()
        const { bankingDetail } = this.state
        let validateNewInput = {
          lessor:checkString({value:bankingDetail.lessor, required:true, minLength:'', maxLength:'', message:'Please select Lessor'}),
          name:checkString({value:bankingDetail.name, required:true, minLength:'', maxLength:'', message:'Please enter Bank Name'}),
          account_name:checkString({value:bankingDetail.account_name, required:true, minLength:'', maxLength:'', message:'Please enter Account Holder Name'}),
          account_number:checkString({value:bankingDetail.account_number, required:true, minLength:'', maxLength:'', message:'Please enter Account Number'}),
        }
        if(Object.keys(validateNewInput).every((k) => { return (validateNewInput[k] === "" || validateNewInput[k] === false)})){
        this.props.addEditBankingInfo(bankingDetail)
        this.setState({
          addBankingDetails: false,
          bankingDetail:{}
        })
        }else {
        this.setState({
          bankingError:validateNewInput
        })
      }

      }


    render(){
      const { type,lessorObj,processing,lessorLoader, sLesseeList, defaultLessor, ownerList, bankingDetailList, bankingDropdownList, ownerDropdownList, } = this.props
      const { error, distributionEmail, owners, ownersAndLessors, bankingDetail, ownerError, bankingError, typeBanking, viewDetails, bankingDropdownValue, bankingStatus  } = this.state;
      const bankingDropdown = Array.from(new Set(bankingDropdownList.map(a => a.id))).map(id => {
        return bankingDropdownList.find(a => a.id === id)
      })

        return(
          <Fragment>
            <div className="lessor-configure">
            { <h4 className="lessor-title">Lessor Configuration</h4> }
            <p className="block-subs">Allows users to configure lessor related settings</p>
            {lessorLoader?<ListLoader/>:null}
            <div className="lessor-profile">
              <form onSubmit={(e) => this.editUserInfo(e)}>
                <span className="edit-profile-icon">
                {
                  type == 'edit' ?
                     <img width="15" onClick={() => {this.props.typeUpdate('view')}}  src={imgStoragePath+"toast_cross.png"} alt="img"/>

                  : <img width="15" onClick={() => {this.props.typeUpdate('edit')}} src={editIcon} alt="img"/>
                }</span>
                <Row className="lessor-detail">
                    <FieldCol md="2">
                      <div className="user-profile-pic">
                        <div className="update-img-block">
                          {lessorObj.logo?<img className="profile-pic" src={lessorObj.logo} alt="img"/>:

                            <img  className="profile-pic"  src={imgStoragePath+"phase-2/lessor_logo_placeholder.png"} alt="img"/>
                          }
                          { type == 'edit' ?
                           <h4 style={{color:'#3f51b5'}}>Edit<input type="file" accept="image/*" onChange={(e)=>this.updateImage(e.target.files[0])}/></h4> : null }

                        </div>
                      </div>
                    </FieldCol>
                    <Col md="5">
                    <FieldCol md="8">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">
                        Name
                         {
                           'view' == 'edit' ?
                           <sup className="reqStar"> * </sup>
                          : null
                         }
                        </FieldLabel>
                         <FieldInput
                          value={lessorObj.name?lessorObj.name:''}
                          type={'view'}
                          keyParam="name"
                          updateField={this.updateForm}
                        />
                       <h6 className="error-msg">{error.name}</h6>
                      </FieldGroup>
                    </FieldCol>
                    <FieldCol md="8">
                        <FieldGroup className="form-group">
                            <FieldLabel className="label">
                            Contact Address
                            </FieldLabel>
                            <FieldTextarea
                            value={lessorObj.contact_address?lessorObj.contact_address:''}
                            type={type}
                            keyParam="contact_address"
                            updateField={this.updateForm}
                            />
                        <h6 className="error-msg">{''}</h6>
                        </FieldGroup>
                </FieldCol>
                <FieldCol md="8">
                        <FieldGroup className="form-group">
                            <FieldLabel className="label">
                             Country
                            </FieldLabel>
                            <FieldInput
                            value={lessorObj.country?lessorObj.country:''}
                            type={type}
                            keyParam="country"
                            updateField={this.updateForm}
                            />
                        <h6 className="error-msg">{''}</h6>
                        </FieldGroup>
                </FieldCol>
                    </Col>
            </Row>
            {
              type == 'edit' ?
              <div className="submit-block">
                <input type="submit" className="primary-btn" disabled={processing ? 'disabled' : ''}  value={processing ? 'Processing...' :"Save"}/>
                <Link style={{color:'#3f51b5'}} onClick={() => {this.props.typeUpdate('view')}}>CANCEL</Link>
              </div>:null
            }
          </form>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={1}>
            <Nav tabs className="commons-tabs" style={{ position: 'relative'}}>
                <NavItem>
                  <NavLink
                    className={
                      classnames({  active: this.state.subTab === 1 })
                    }
                    onClick={() => this.setState({subTab: 1})}
                   >
                    Distribution Emails
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      classnames({  active: this.state.subTab === 2 })
                    }
                    onClick={() => this.setState({subTab: 2})}
                   >
                    Introduction
                  </NavLink>
                </NavItem>
                {
                  (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('C') != -1) || (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('R') != -1)  ?
                  <NavItem>
                    <NavLink
                      className={
                        classnames({  active: this.state.subTab === 3 })
                      }
                      onClick={() => this.setState({subTab: 3})}
                     >
                      Owner and Lessor
                    </NavLink>
                  </NavItem> : null
                }
                {
                  (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('C') != -1) || (getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['banking_details'] && getLocalStorageInfo().user.permission['console']['banking_details'].indexOf('R') != -1) ?
                  <NavItem>
                    <NavLink
                      className={
                        classnames({ active: this.state.subTab === 4 })
                      }
                        onClick={() => this.setState({ subTab: 4 })}
                    >
                      Banking Details
                    </NavLink>
                  </NavItem> : null
                }

            </Nav>
              <TabContent activeTab={this.state.subTab}>
                <TabPane tabId={1}>
                  <div className="contr-table-blk" style={{marginTop:"30px"}}>
                    <div style={{position:'relative'}}>
                    <h5>Distribution Emails </h5>
                      <ul className="list-inline export-import-list">
                        <li className="list-inline-item">
                          <button className="btn btn-primary btn-sm" style={{backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} onClick={()=> this.setState({addEmail:true, editMode: false, error: {}, distributionEmail: {lessee_id: '', emails: []}}, () => this.addEmails())}>Add</button>
                        </li>
                      </ul>
                    </div>
                    <EditFormBar
                      titleContent={this.state.editMode ? 'Edit Email' : "Add Email"}
                      toggleEditSideBar={()=> this.setState({addEmail: false})}
                      style={this.state.addEmail ? {width: '800px'} : {width: '0px'}}
                      isOpen={this.state.addEmail}
                      >
                      <form onSubmit={this.saveEmails}>
                        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
                        <Row style={{margin:"10px"}}>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">Lessee <span className="reqStar">*</span></FieldLabel>
                                <Select
                                  value={distributionEmail.lessee_id ? distributionEmail.lessee_id : ''}
                                  onChange={(e) => this.setState({
                                    distributionEmail: {
                                      ...this.state.distributionEmail,
                                      lessee_id: e.id
                                    }
                                  })}
                                  className="custom-select-block"
                                  valueKey="id"
                                  labelKey="name"
                                  disabled={this.state.editMode}
                                  options= {sLesseeList.filter(lessee => this.state.esistinglessess.indexOf(lessee.id) === -1)}
                                  multi={false}
                               />
                             <h6 className="error-msg">{error.lessee_id}</h6>
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">Emails<span className="reqStar">*</span></FieldLabel>
                                <Creatable
                                  value={distributionEmail.emails}
                                  isClearable={true}
                                  onChange={(emails) => this.handleEmailChange(emails)}
                                  className="custom-select-block"
                                  placeholder="Type email id to create"
                                  valueKey="label"
                                  labelKey="label"
                                  multi={true}
                               />
                             <h6 className="error-msg" dangerouslySetInnerHTML={{__html: error.emails}}></h6>
                            </FieldGroup>
                          </FieldCol>
                        </Row>
                      </div>
                        <Row>
                          <div className="submit-block" style={{position:"relative", top:'-41px', marginLeft: '15px'}} >
                            <input type="submit" className="primary-btn" value="Save"/>
                            <Link style={{color:'#3f51b5'}} onClick={() => this.setState({addEmail: false, editMode: false, error: {}})} >CANCEL</Link>
                          </div>
                        </Row>
                      </form>
                    </EditFormBar>
                    <div className="table-container" style={{marginBottom:"0px"}}>
                      {
                        lessorObj && lessorObj.distribution_emails.length ?
                        <form style={{overflow:"auto"}} onSubmit={(e) => this.editLLP(e)}>
                          <table className="common-table">
                            <thead>
                              <tr>
                                <th style={{width:'180px'}}>Lessee</th>
                                <th>Emails</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                lessorObj.distribution_emails.map( emails =>
                                  <tr>
                                    <td>
                                      {
                                        sLesseeList.filter(lessee => emails.lessee_id === lessee.id).length ? sLesseeList.filter(lessee => emails.lessee_id === lessee.id)[0].name : '---'
                                      }
                                    </td>
                                    <td>
                                      {emails.emails.map((item, itemIndex) => <span>&nbsp;{item}{emails.emails.length > 0 && emails.emails.length - 1 != itemIndex ? ',' : ''}</span>)}
                                    </td>
                                    <td>
                                      <img onClick={() => this.editEmail(emails)} style={{width: '15px', marginLeft: '5px'}} src={editIcon} alt=""></img>
                                      <img onClick={() => this.setState({deleteEmail: true, distributionEmail: emails, esistinglessess: []})} style={{width: '15px', marginLeft: '5px'}} src={imgStoragePath + 'icon_trash.png'} alt=""></img>
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                        </form>
                        :<NoRecordFound/>
                      }
                      <DeleteModal isOpen={this.state.deleteEmail} className="modal-dialog-centered" toggle={() => this.setState({deleteEmail: false})} >
                        <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete </p>
                          <ul className="list-inline" style={{textAlign: 'center'}}>
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm add-new" onClick={() => this.deleteEmail()}>Yes</button>
                            </li>
                            <li className="list-inline-item">
                              <button className="btn btn-danger btn-sm add-new" onClick={()=> this.setState({deleteEmail:false }) } >No</button>
                            </li>
                          </ul>
                      </DeleteModal>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={2}>
                <div className="lessor-profile" >
                  <form onSubmit={(e) => this.editIntroInfo(e)}>
                    <Row className="lessor-detail">
                      <FieldCol md="12">
                          <p className="warning-info-text" style={{fontSize: '12px', marginBottom: '20px'}}>
                            Note : Information entered in the below sections will be available in exported Marketing Summary and Technical Specification for each asset.
                          </p>
                      </FieldCol>
                      <FieldCol md="6">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                             Introduction
                          </FieldLabel>
                          <CKeditor
                            events={{"change": (evt) => this.updateCkEditer(evt, 'introduction')}}
                            content={lessorObj && lessorObj.introduction ? lessorObj.introduction : ''}
                          />
                              <h6 className="error-msg">{''}</h6>
                          </FieldGroup>
                      </FieldCol>
                      <FieldCol md="6">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                             Confidentiality
                          </FieldLabel>
                          <CKeditor
                            events={{"change": (evt) => this.updateCkEditer(evt, 'confidentiality')}}
                            content={lessorObj && lessorObj.confidentiality ? lessorObj.confidentiality : ''}
                          />
                              <h6 className="error-msg">{''}</h6>
                          </FieldGroup>
                      </FieldCol>
                      <FieldCol md="6">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                             Disclaimer
                          </FieldLabel>
                          <CKeditor
                            events={{"change": (evt) => this.updateCkEditer(evt, 'disclaimer')}}
                            content={lessorObj && lessorObj.disclaimer ? lessorObj.disclaimer : ''}
                          />
                              <h6 className="error-msg">{''}</h6>
                          </FieldGroup>
                      </FieldCol>
                      <FieldCol md="6">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                             Summary of Terms and Conditions
                          </FieldLabel>
                          <CKeditor
                            events={{"change": (evt) => this.updateCkEditer(evt, 'summary_tnc')}}
                            content={lessorObj && lessorObj.summary_tnc ? lessorObj.summary_tnc : ''}
                          />
                              <h6 className="error-msg">{''}</h6>
                          </FieldGroup>
                      </FieldCol>
                      <FieldCol md="12">
                        <p className="warning-info-text" style={{marginBottom: '10px', fontSize: '12px'}}>
                          Entered email and contact number will be reflecting in export of both Marketing summary and Technical Specification.
                        </p>
                      </FieldCol>
                      <FieldCol md="8">
                          <FieldGroup className="form-group">
                              <FieldLabel className="label">
                              Contact Number
                              </FieldLabel>
                              <FieldInput
                              value={lessorObj.phone_number?lessorObj.phone_number:''}
                              type={'edit'}
                              keyParam="phone_number"
                              updateField={this.updateForm}
                              />
                          <h6 className="error-msg">{error.phone_number}</h6>
                          </FieldGroup>
                      </FieldCol>
                      <FieldCol md="8">
                          <FieldGroup className="form-group">
                              <FieldLabel className="label">
                              Email <InfoIcon id={"email_info"} />
                              <ToolTipHover placement="top" tagetId={"email_info"}>
                                Official email id of Company
                              </ToolTipHover>
                              </FieldLabel>
                              <FieldInput
                              value={lessorObj.email?lessorObj.email:''}
                              type={'edit'}
                              keyParam="email"
                              updateField={this.updateForm}
                              />
                          <h6 className="error-msg">{error.email}</h6>
                          </FieldGroup>
                  </FieldCol>
                    <Col md={8}>
                      <input type="submit" className="primary-btn" disabled={processing ? 'disabled' : ''}  value={processing ? 'Processing...' :"Save Changes"}/>
                    </Col>
                </Row>
                </form>
              </div>
                </TabPane>

                <TabPane tabId={3}>
                  <div className="top-header">
                    <Row>
                      <Col md={4}>
                      </Col>
                      <Col md="8">
                        <div style={{ textAlign: 'right', position: 'relative', right: '20px', top: '10px', float:'right' }}>
                          {
                            getLocalStorageInfo().user.permission['console'] && getLocalStorageInfo().user.permission['console']['owner_details'] && getLocalStorageInfo().user.permission['console']['owner_details'].indexOf('C') != -1 ?
                            <Button color="primary" size="sm" style={{ textAlign: 'center', backgroundColor: '#3f51b5', borderColor: '#3f51b5'}} onClick={() => this.setState({ addOwner: true, ownersAndLessors: { name:'',contact:'', address:'',jurisdiction:''}, editMode: false, error: {} })}>Add Owner and Lessor</Button>
                            :null
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="lessor-profile" >
                    <Row className="lessor-detail">
                      { ownerList.length ? ownerList.map((owner, index) =>
                        <OwnerCard
                          owner={owner}
                          key={index}
                          id={owner.id}
                          editOwnerMode={this.editOwnerMode}
                          deleteOwnerMode={this.deleteOwnerMode}
                        />
                    ) : <Col md="12"><NoRecordFound /></Col>}

                    </Row>
                    <form>
                      <EditFormBar
                        titleContent={this.state.editMode ? 'Edit Owner and Lessor' : "Add Owner and Lessor"}
                        toggleEditSideBar={()=> this.setState({addOwner: false})}
                        style={this.state.addOwner ? { width: '600px' } : { width: '0px' }}
                        isOpen={this.state.addOwner}
                      >
                      <form onSubmit={(e) => this.addEditOwner(e)} >
                        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
                        <Row style={{margin:"10px"}}>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">
                                Name<sup class="reqStar"> * </sup>
                              </FieldLabel>
                              <FieldInput
                                value={ownersAndLessors ? ownersAndLessors.name : ''}
                                keyParam="name"
                                updateField={this.ownersAndLessors}
                                maxLength={255}
                              />
                            <h6 className="error-msg">{ownerError.name}</h6>
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">
                                Address
                              </FieldLabel>
                              <FieldInput
                                value={ownersAndLessors ?  ownersAndLessors.address : ''}
                                keyParam="address"
                                updateField={this.ownersAndLessors}
                              />
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">
                                Contact
                              </FieldLabel>
                              <FieldInput
                                value={ownersAndLessors ? ownersAndLessors.contact : ''}
                                keyParam="contact"
                                updateField={this.ownersAndLessors}
                              />
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="12">
                            <FieldGroup className="form-group">
                              <FieldLabel className="label">
                                Jurisdiction
                              </FieldLabel>
                              <FieldInput
                                value={ownersAndLessors ?  ownersAndLessors.jurisdiction : ''}
                                keyParam="jurisdiction"
                                updateField={this.ownersAndLessors}
                              />
                            </FieldGroup>
                          </FieldCol>
                        </Row>
                      </div>
                        <Row>
                          <div className="submit-block" style={{position:"relative", top:'-41px', marginLeft: '15px'}} >
                            <input type="submit" className="primary-btn" value="Save"/>
                            <Link style={{color:'#3f51b5'}} onClick={() => this.setState({addOwner: false, editMode: false, error: {}})} >CANCEL</Link>
                          </div>
                        </Row>
                      </form>
                    </EditFormBar>
                    </form>
                    <DeleteModal isOpen={this.state.deleteOwnerModal} className="modal-dialog-centered" toggle={() => this.setState({deleteOwnerModal: false})} >
                        <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure to delete <strong>{ownersAndLessors.name}</strong> ? </p>
                        <span className="notice-info config-card-info" style={{position: "relative",left: "77px",top: "-10px"}}>Note: Bank Details associated with Lessor/Owner as would be deleted.</span>
                        <ul className="list-inline" style={{textAlign: 'center'}}>
                          <li className="list-inline-item">
                              <button className="btn btn-primary btn-sm add-new" onClick={() => this.deleteOwner()}>Yes</button>
                          </li>
                          <li className="list-inline-item">
                            <button className="btn btn-danger btn-sm add-new" onClick={()=> this.setState({deleteOwnerModal:false }) } >No</button>
                          </li>
                        </ul>
                    </DeleteModal>
                  </div>
                </TabPane>
                    <TabPane tabId={4}>
                      <div className="top-header">
                        <Row>
                          <FieldCol md="3">
                            <FieldGroup className="form-group" style={{marginLeft:'16px'}}>
                              <FieldLabel className="label">
                                Lessor
                              </FieldLabel>
                              <Select
                                className="custom-select-block"
                                placeholder="Select a Lessor"
                                valueKey="id"
                                labelKey="name"
                                options={ownerDropdownList}
                                value={this.state.bankingDropdownId}
                                onChange={(value) => this.filterByLessor(value ? value :'' )}
                                multi={false}
                              />
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="3">
                            <FieldGroup className="form-group" style={{marginLeft:'16px'}}>
                              <FieldLabel className="label">
                                Bank
                              </FieldLabel>
                              <Select
                                className="custom-select-block"
                                placeholder="Select a Bank"
                                valueKey="name"
                                labelKey="name"
                                options={bankingDropdownList}
                                value={this.state.bankingDropdownName}
                                onChange={(value) => this.filterByBank(value ? value :'' )}
                                multi={false}
                              />
                            </FieldGroup>
                          </FieldCol>
                          <FieldCol md="4">
                            <FieldGroup className="form-group" style={{marginLeft:'16px'}}>
                              <FieldLabel className="label">
                                Status
                              </FieldLabel>
                              <Select
                                className="custom-select-block"
                                placeholder="Select Status"
                                valueKey="value"
                                labelKey="name"
                                options={[{name: 'All', value: ''},{name:'Pending', value:0}, {name: 'Approved', value: 1},{name: 'Rejected', value: 2}]}
                                value={this.state.bankingStatus}
                                onChange={(value) => this.filterByStatus(value ? value :'' )}
                                multi={false}
                              />
                            </FieldGroup>
                          </FieldCol>
                          <Col md="2">
                            <div style={{ textAlign: 'right', position: 'relative', right: '20px', top: '10px' }}>

                              <Button color="primary" size="sm" style={{ textAlign: 'center', backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} onClick={this.addBanking} >Add Banking Details</Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lessor-profile" >
                        <Row className="lessor-detail">
                          { bankingDetailList.length ? bankingDetailList.map((bank, index) =>
                            getLocalStorageInfo().user.is_root_user || getLocalStorageInfo().user.is_super_admin ?
                            <BankingDetail
                              bank={bank}
                              key={index}
                              editBankingMode={this.editBankingMode}
                              deleteBankingMode={this.deleteBankingMode}
                              viewBankingMode={this.viewBankingMode}
                              rejectBanking={() => this.setState({rejectModal: true, activeBank: bank})}
                              approveBanking={() => this.props.addEditBankingInfo({...bank, status: 1, lessor: bank.lessor.id})}
                            />
                            :[0,1].includes(bank.status)?
                            <BankingDetail
                              bank={bank}
                              key={index}
                              editBankingMode={this.editBankingMode}
                              deleteBankingMode={this.deleteBankingMode}
                              viewBankingMode={this.viewBankingMode}
                              rejectBanking={() => this.setState({rejectModal: true, activeBank: bank})}
                              approveBanking={() => this.props.addEditBankingInfo({...bank, status: 1, lessor: bank.lessor.id, lessor_level: bank.lessor.lessor_level})}
                            />
                          :(bank.status === 2 && getLocalStorageInfo().user.id === bank.created_by)?
                          <BankingDetail
                            bank={bank}
                            key={index}
                            editBankingMode={this.editBankingMode}
                            deleteBankingMode={this.deleteBankingMode}
                            viewBankingMode={this.viewBankingMode}
                            rejectBanking={() => this.setState({rejectModal: true, activeBank: bank})}
                            approveBanking={() => this.props.addEditBankingInfo({...bank, status: 1, lessor: bank.lessor.id})}
                          />:null) : <NoRecordFound style={{ width: '100%' }} />}

                        </Row>
                        <form>
                          <EditFormBar
                            titleContent={this.state.editMode ? 'Edit Banking Details' : "Add Banking Details"}
                            toggleEditSideBar={() => this.setState({ addBankingDetails: false })}
                            style={this.state.addBankingDetails ? { width: '600px' } : { width: '0px' }}
                            isOpen={this.state.addBankingDetails}
                          >
                            <form onSubmit={(e) => this.addEditBankingInfo(e)} >
                              <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
                                <Row style={{ margin: "10px" }}>
                                    <FieldCol md="12">
                                        <FieldGroup className="form-group">
                                          <FieldLabel className="label">Lessor <span className="reqStar">*</span></FieldLabel>
                                          <Select
                                            className="custom-select-block"
                                            valueKey="id"
                                            labelKey='name'
                                            options={ownerDropdownList}
                                            value={bankingDetail.lessor}
                                            onChange={(value) => this.updateBankingDetails('lessor', value ? value :'' )}
                                            multi={false}
                                          />
                                        <h6 className="error-msg">{bankingError.lessor}</h6>
                                        </FieldGroup>
                                    </FieldCol>
                                    <FieldCol md="12">
                                      <FieldGroup className="form-group">
                                        <FieldLabel className="label">
                                          Bank Name<sup class="reqStar"> * </sup>
                                        </FieldLabel>
                                        <FieldInput
                                          value={bankingDetail.name ? bankingDetail.name : ''}
                                          type={'edit'}
                                          keyParam="name"
                                          updateField={this.updateBankingDetails}
                                          maxLength={200}
                                        />
                                      <h6 className="error-msg">{bankingError.name}</h6>
                                      </FieldGroup>
                                    </FieldCol>
                                    <FieldCol md="12">
                                      <FieldGroup className="form-group">
                                        <FieldLabel className="label">
                                          Account Holder Name<sup class="reqStar"> * </sup>
                                        </FieldLabel>
                                        <FieldInput
                                          value={bankingDetail.account_name ? bankingDetail.account_name : ''}
                                          type={'edit'}
                                          keyParam="account_name"
                                          updateField={this.updateBankingDetails}
                                          maxLength={200}
                                        />
                                      <h6 className="error-msg">{bankingError.account_name}</h6>
                                      </FieldGroup>
                                    </FieldCol>
                                    <FieldCol md="12">
                                      <FieldGroup className="form-group">
                                        <FieldLabel className="label">
                                          Account Number<sup class="reqStar"> * </sup>
                                        </FieldLabel>
                                        <FieldInput
                                          value={bankingDetail.account_number ? bankingDetail.account_number : ''}
                                          type={'edit'}
                                          keyParam="account_number"
                                          updateField={this.updateBankingDetails}
                                          maxLength={100}
                                        />
                                      <h6 className="error-msg">{bankingError.account_number}</h6>
                                      </FieldGroup>
                                    </FieldCol>
                                    <FieldCol md="12">
                                      <FieldGroup className="form-group">
                                        <FieldLabel className="label">
                                          Bank Code
                                        </FieldLabel>
                                        <FieldInput
                                          value={bankingDetail.code ? bankingDetail.code : ''}
                                          type={'edit'}
                                          keyParam="code"
                                          updateField={this.updateBankingDetails}
                                          maxLength={50}
                                        />
                                        <h6 className="error-msg">{''}</h6>
                                      </FieldGroup>
                                    </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        Hub Number
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.hub_number ? bankingDetail.hub_number : ''}
                                        type={'edit'}
                                        keyParam="hub_number"
                                        updateField={this.updateBankingDetails}
                                        maxLength={20}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        VAT Number
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.vat_number ? bankingDetail.vat_number : ''}
                                        type={'edit'}
                                        keyParam="vat_number"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        IBAN
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.iban ? bankingDetail.iban : ''}
                                        type={'edit'}
                                        keyParam="iban"
                                        updateField={this.updateBankingDetails}
                                        maxLength={100}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        ABA Number
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.aba_number ? bankingDetail.aba_number : ''}
                                        type={'edit'}
                                        keyParam="aba_number"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        SORT Code
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.sort_code ? bankingDetail.sort_code : ''}
                                        type={'edit'}
                                        keyParam="sort_code"
                                        updateField={this.updateBankingDetails}
                                        maxLength={20}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        SWIFT Code
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.swift_code ? bankingDetail.swift_code : ''}
                                        type={'edit'}
                                        keyParam="swift_code"
                                        updateField={this.updateBankingDetails}
                                        maxLength={100}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        Reference
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.reference ? bankingDetail.reference : ''}
                                        type={'edit'}
                                        keyParam="reference"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        Note
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.note ? bankingDetail.note : ''}
                                        type={'edit'}
                                        keyParam="note"
                                        updateField={this.updateBankingDetails}
                                        maxLength={100}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        Footer Note
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.footer_note ? bankingDetail.footer_note : ''}
                                        type={'edit'}
                                        keyParam="footer_note"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        Jurisdiction
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.jurisdiction ? bankingDetail.jurisdiction : ''}
                                        type={'edit'}
                                        keyParam="jurisdiction"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                  <FieldCol md="12">
                                    <FieldGroup className="form-group">
                                      <FieldLabel className="label">
                                        SPV
                                      </FieldLabel>
                                      <FieldInput
                                        value={bankingDetail.spv ? bankingDetail.spv : ''}
                                        type={'edit'}
                                        keyParam="spv"
                                        updateField={this.updateBankingDetails}
                                        maxLength={50}
                                      />
                                      <h6 className="error-msg">{''}</h6>
                                    </FieldGroup>
                                  </FieldCol>
                                </Row>
                              </div>
                              <Row>
                                <div className="submit-block" style={{ position: "relative", top: '-41px', marginLeft: '15px' }} >
                                  <input type="submit" className="primary-btn" value="Save" />
                                  {
                                    this.state.bankingDetail.status === 2 ?
                                      <input type="button" onClick={() => this.submitBanking()} className="primary-btn" value="Submit" />
                                    :null
                                  }
                                  <Link style={{color:'#3f51b5'}} onClick={() => this.setState({ addBankingDetails: false, editMode: false, error: {} })} >CANCEL</Link>
                                </div>
                              </Row>
                            </form>
                          </EditFormBar>

                          <EditFormBar
                            titleContent="View Banking Details"
                            toggleEditSideBar={() => this.setState({ viewDetails: false })}
                            style={this.state.viewDetails ? { width: '600px' } : { width: '0px' }}
                            isOpen={this.state.viewDetails}
                          >
                            <form  >
                              <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
                                <Row style={{ margin: "10px" }}>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Lessor</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.lessor && bankingDetail.lessor.name ? bankingDetail.lessor.name  : '--'}</span>
                                    </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Bank Name</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.name ? bankingDetail.name : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Account Holder Name</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.account_name ? bankingDetail.account_name : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Account Number</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.account_number ? bankingDetail.account_number : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Bank Code</h6>
                                    <span style={{ fontSize: '14px'}}>{bankingDetail.code ? bankingDetail.code : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Hub Number</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.hub_number ? bankingDetail.hub_number : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>VAT Number</h6>
                                    <span style={{ fontSize: '14px'}}>{bankingDetail.vat_number ? bankingDetail.vat_number : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>IBAN</h6>
                                    <span style={{ fontSize: '14px'}}>{bankingDetail.iban ? bankingDetail.iban : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>SORT Code</h6>
                                    <span style={{ fontSize: '14px'}}>{bankingDetail.sort_code ? bankingDetail.sort_code : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>SWIFT Code</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.swift_code ? bankingDetail.swift_code : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px' }}>Reference</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.reference ? bankingDetail.reference : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Note</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.note ? bankingDetail.note: '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Footer Note</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.footer_note ? bankingDetail.footer_note : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>Jurisdiction</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.jurisdiction ? bankingDetail.jurisdiction : '--'}</span>
                                  </FieldCol>
                                  <FieldCol md={6} style={{ marginBottom: '10px' }}>
                                    <h6 style={{ fontSize: '13px', color: '#a0a0a0' }}>SPV</h6>
                                    <span style={{ fontSize: '14px' }}>{bankingDetail.spv ? bankingDetail.spv : '--'}</span>
                                  </FieldCol>
                                </Row>
                              </div>
                            </form>
                          </EditFormBar>
                        </form>
                        <DeleteModal isOpen={this.state.deleteBankingModal} className="modal-dialog-centered" toggle={() => this.setState({ deleteBankingModal: false })} >
                          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete </p>
                          <ul className="list-inline" style={{ textAlign: 'center' }}>
                            <li className="list-inline-item">
                              <button className="btn btn-primary btn-sm add-new" onClick={() => this.deleteBankingDetail()}>Yes</button>
                            </li>
                            <li className="list-inline-item">
                              <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({ deleteBankingModal: false })} >No</button>
                            </li>
                          </ul>
                        </DeleteModal>
                      </div>
                    </TabPane>
              </TabContent>
            </TabPane>
          </TabContent>
        {
          processing ?
            <ListLoader/>
          :null
        }
          </div>
          <Modal isOpen={this.state.rejectModal} toggle={() => this.setState({rejectModal: false, activeBank: {}})} >
            <ModalHeader toggle={this.toggleModel}>Reason of Rejection</ModalHeader>
            <ModalBody>
              <textarea autofocus style={{border: '1px solid #d7d7d7', width: '100%', height: '100px', borderRadius: '4px', padding: '5px 10px'}} onChange={(evt) => this.setState({rejection_reason: evt.target.value})}  value={this.state.rejection_reason}>
              </textarea>
              <h6 className="error-msg">{this.state.rejection_reason_error}</h6>
              <p>
                <button onClick={() => this.updateRejectStatus()} style={{float: 'right'}} className="primary-btn">SAVE</button>
                <a onClick={() =>this.setState({rejectModal: false, activeBank: {}, rejection_reason: '', rejection_reason_error: ''})} style={{color: '#3f51b5', position: 'absolute', right: '110px', paddingTop: '5px'}}>CANCEL</a>
              </p>
            </ModalBody>
          </Modal>
        </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    type:state.confReducer.type,
    lessorObj:state.confReducer.lessorObj,
    processing:state.confReducer.processing,
    lessorLoader:state.confReducer.lessorLoader,
    sLesseeList:state.sharedReducers.sLesseeList,
    ownerList:state.confReducer.ownerList,
    bankingDetailList:state.confReducer.bankingDetailList,
    bankingDropdownList: state.confReducer.bankingDropdownList,
    ownerDropdownList: state.confReducer.ownerDropdownList,

});
const mapDispatchToProps  = (dispatch, ownProps) => {
    return {
        lessorInfoUpdate:(formdata, message, messageType) =>  dispatch(editLessorAc(formdata, message, messageType)),
        lessorUpload:(formdata) =>  dispatch(uploadLessorTempAc(formdata)),
        addEditOwner:(data) => dispatch(addEditOwnerAc(data)),
        getOwnerList:() => dispatch(getOwnerAc()),
        downloadresources:(assetType) =>  dispatch(downLoadLessorTempAC(assetType)),
        getBankingDetailsDropdown:(value) =>  dispatch(getBankingDetailsDropdownAc(value)),
        getOwnerDropdownList:() =>  dispatch(getOwnerDropdownListAc()),
        typeUpdate: (value) => dispatch({
            type:TYPE_LESSOR_UPDATE,
            payload:value
         }),
         updateForm: (value) => dispatch({
            type:LESSOR_FIELD_UPDATE,
            payload:value
         }),
         updateAutoPublish: (flag) => dispatch(updateAutoPublishAc(flag)),
         getLesseeList: () => dispatch(sLesseeListAc()),
         deleteOwner:(id) =>  dispatch(deleteOwnerAc(id)),
         deleteBankingDetail: (id) => dispatch(deleteBankingDetailAc(id)),
         addEditBankingInfo:(data) => dispatch(addEditBankingInfoAc(data)),
         getBankingDetails:(id, level, name, status ) => dispatch(getBankingDetailsAc(id, level, name, status))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfIndex);
