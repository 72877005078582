import React, { Component } from 'react';
import { withRouter } from 'react-router';
import  NoRecordFound  from './NoRecordFound';
import WhatsNew from './WhatsNew';
import '../assets/styles/timeline.scss';
import { globalGetService } from '../../globalServices'
import ListLoader from './ListLoader';
import moment from 'moment';

 class FeaturesList extends Component{
  constructor(props){
    super(props);
    this.state={
      featuresList:[],
      loader: false,
    }
  }
  componentDidMount(){
    this.getAllFeatures()
  }

  getAllFeatures = () => {
    this.setState({
      loader: true
    })
    globalGetService('/api/features/',{application: 3})
    .then(response => {
      if(response.data.statusCode == 200){
      this.setState({
        featuresList:response.data.data
      })
      }
      this.setState({
        loader: false
      })
    })
  }
  render(){
    let customHeight=document.body.contains(document.getElementById('sidebar-header')) ?document.getElementById('sidebar-header').clientHeight : 0;
    return(
      <div className="application-edit-side-bar">
        <div className="edit-bar-content-blk technical-edit-sidebar" style={{width: 'auto', position:'unset', marginLeft:'55px'}}>
          <div className="top-content">
            <div md="12">
                <h3 style={{textTransform: 'capitalize'}}> What's New </h3>
            </div>
          </div>
          <div className="body-content">
            <div class="timeline sidebar-form" style={{ height: (window.innerHeight - customHeight-40) + 'px'}}onDrop={(evt) => this.onDrop(evt) } onDragOver={(evt) => this.onDragOver(evt)} onDragLeave={() => this.onFocusOut()} >
            {this.state.loader ? <ListLoader/> :this.state.featuresList && this.state.featuresList.length ?
            <ul class="timeline-body">
            {this.state.featuresList.map((feature,index)=>{
              return(
                <WhatsNew  key={index} feature={feature}/>
              )
            })}
            </ul>:<NoRecordFound /> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(FeaturesList);
