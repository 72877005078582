import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldTextarea} from '../../Elements';
import { checkString, checkMobileNumber, checkEmail } from '../../../../formValidator';
 const phoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
export default class AddGrpMember extends Component {
  constructor(props){
    super(props);
    this.state = {
      addMember: {},
      error: {}
    }
  }
  componentDidMount(){
    this.setState({
      addMember: this.props.addMemberObj
    });
  }
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      addMember: {
        ...prevState.addMember,
        [type]: value
      },
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }
  addEditGrpMember = (e) => {
    e.preventDefault();
    let data = this.state.addMember;
    if(this.props.type == 'add'){
      data = {
        ...data,
        working_group_id: this.props.workingGroupId,
      }
    }
    const validateNewInput = {
      first_name: checkString({value: data.first_name, required: true, minLength: '', maxLength: '', message: 'Please enter First Name'}),
      last_name: checkString({value: data.last_name, required: true, minLength: '', maxLength: '', message: 'Please enter Last Name'}),
      email: checkEmail({value: data.email, required: true, minLength: '', maxLength: '', message: 'Please enter valid Email Address'}),
      mobile: phoneNumber.test(data.mobile) ? '' :'Please enter Valid Mobile Number'
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.props.type == 'add'){
        this.props.toggleEditSideBar();
      }
      this.props.addEditGrpMember(data, this.props.type,  this.props.workingGroupId);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type } = this.props;
    const { addMember, error } = this.state;
    if(!Object.keys(addMember).length){
      return null
    }
    return(
      <form onSubmit={(e) => this.addEditGrpMember(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">First Name <sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={addMember.first_name}
                  type={type}
                  keyParam="first_name"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.first_name}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Last Name <sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={addMember.last_name}
                  type={type}
                  keyParam="last_name"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.last_name}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Email Address<sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={addMember.email}
                  type={type}
                  keyParam="email"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.email}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Mobile Number</FieldLabel>
                <FieldInput
                  value={addMember.mobile}
                  type={type}
                  keyParam="mobile"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.mobile}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Designation</FieldLabel>
                <FieldInput
                  value={addMember.designation}
                  type={type}
                  keyParam="designation"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Remarks</FieldLabel>
                <FieldTextarea
                  value={addMember.remarks}
                  type={type}
                  keyParam="remarks"
                  updateField={this.updateForm}
                />
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        {
          type != 'view' ?
          <div className="submit-block">
            <input type="submit" className="primary-btn" value="Save Changes"/>
            <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
          </div>:null
        }
      </form>
    )
  }
}
