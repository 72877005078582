import React, { Fragment } from 'react';
import { TableRow, TableCell, TextField, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { getCurrencyFormat } from '../../../utils_v2';
const EditRentalInvoiceList = ({invoice_item, invoiceInfo, mode, onFieldChange}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        {invoice_item.invoice_item_type_id && invoice_item.invoice_item_type_id.name ? invoice_item.invoice_item_type_id.name : invoice_item.is_additional_rate ? 'Additional Rent' : 'Basic Rent'}
        { invoice_item.is_additional_rate ?
          <Fragment>
            <br/>
            { mode === 'view' ? invoice_item.libor_value ? invoice_item.libor_value:'--':
              <TextField
                id="libor_value"
                label="Libor(%)"
                fullWidth
                margin="normal"
                value={invoice_item.libor_value}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 6 }}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'libor_value', e.target.value): e.preventDefault()}}
              />
            }
            { mode === 'view' ? invoice_item.libor_date ? moment(invoice_item.libor_date).format(displayDateFormatShort):'--':
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                   required
                   margin="normal"
                   id="libor_date"
                   label="Libor Rate as of date"
                   format={fieldDateFormat}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                   inputProps={{readOnly: true}}
                   clearable={true}
                   value={invoice_item.libor_date ? invoice_item.libor_date:null}
                   onChange={(data, value) => onFieldChange(value, 'libor_date', data)}
                 />
              </MuiPickersUtilsProvider>
            }
          </Fragment>:null
        }
      </TableCell>
      <TableCell>
        { invoice_item.is_additional_rate && invoice_item.payment_schedule ?
          <Fragment>
            Dollar margin applied: {invoice_item.payment_schedule.margin ? invoice_item.payment_schedule.margin:0}%,<br/>
            Rental Period: {invoice_item.payment_schedule.period ? invoice_item.payment_schedule.period:'--'}, <br/>
            Dollar Reference Value:
            {invoice_item.payment_schedule.reference_value ? <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency, shortFormat:false})}>{invoice_item.payment_schedule.reference_value ? invoice_item.payment_schedule.reference_value:0}</NumericLabel> :'0'}
          </Fragment>:
            mode === 'view' ? (invoice_item.description ? invoice_item.description:'--'):
          <TextField
            id="description"
            fullWidth
            margin="normal"
            value={invoice_item.description}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => onFieldChange(e, 'description', e.target.value)}
          />
        }
      </TableCell>
      <TableCell>
        { invoice_item.unit_type }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.no_of_unit:
          <TextField
            id="no_of_unit"
            fullWidth
            margin="normal"
            value={invoice_item.no_of_unit}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'no_of_unit', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell>
        { invoice_item.is_additional_rate ?
          <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency,shortFormat:false})}>{invoice_item.rate_per_unit?invoice_item.rate_per_unit:0}</NumericLabel> :
          mode === 'view' ?
          <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency,shortFormat:false})}>{invoice_item.rate_per_unit?invoice_item.rate_per_unit:0}</NumericLabel>:
          <TextField
            id="rate_per_unit"
            fullWidth
            margin="normal"
            value={invoice_item.rate_per_unit}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'rate_per_unit', e.target.value): e.preventDefault()}}
          />
        }
      </TableCell>
      <TableCell>
        { mode === 'view' ? invoice_item.tax_rate:
          <TextField
            id="tax_rate"
            fullWidth
            margin="normal"
            value={invoice_item.tax_rate}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'tax_rate', e.target.value): e.preventDefault()}}
          />
        }

      </TableCell>
      <TableCell>
        <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency, shortFormat:false})}>{invoice_item.tax_amount?invoice_item.tax_amount:0}</NumericLabel>
      </TableCell>
      <TableCell>
        { mode === 'view' ? <NumericLabel params={getCurrencyFormat({currency:invoiceInfo.currency,shortFormat:false})}>{invoice_item.sub_total?invoice_item.sub_total:0}</NumericLabel> :
          <TextField
            id="sub_total"
            fullWidth
            margin="normal"
            value={invoice_item.sub_total}
            InputLabelProps={{shrink: true}}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'sub_total', e.target.value): e.preventDefault()}}
          />
        }

      </TableCell>
    </TableRow>
  )
}
export default EditRentalInvoiceList;
