import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { PageLoader } from '../../../../shared_elements';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus } from '../../../../utils_v2';
import { errorCode } from '../';
import { changeObligationStatusApi } from '../apiServices';
import { globalGetService, globalPostService } from '../../../../globalServices';
class ChangeStatusModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader: false,
      approval: {
        status: '',
        suggestion:''
      },
      options:[],
      error: {}
    }
    this.changeObligationStatusApi = changeObligationStatusApi.bind(this);
    
  }
  componentDidMount(){
    this.getOptionDropdownList()
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      approval: {
        ...prevState.approval,
        [keyParam]: value
      },
      error: {
        ...prevState.error,
        [keyParam]: ''
      }
    }))
  }
   getOptionDropdownList = () => {
    globalPostService(`console/get-constants/`,{constant_types:['obligation_approval_status']})
        .then(response => {
          this.setState({options:response.data.data})
        })

}
  changeObligationStatus = (props) => {
    const { approval } = this.state;
    let validationInputs = {
      status: approval.status ? '':'Please select Status',
      // status: approval.status && approval.status.value == '' ?'Please select Status' : typeof (approval.status.value )== 'number' ? null: 'Please select Status',
      suggestion: approval.status && approval.status?.value && approval.status.value === 2 && approval.suggestion.trim() === '' ? 'Please enter Reason':'',
    };
    let payload = approval
    payload = {
      ...approval,
      status:approval.status && approval.status?.value ?approval.status.value : ''
    }
    if(approval.status && approval.status.value ===2){
      payload ={
        ...payload,
        suggestion: approval.suggestion && approval.suggestion.length ? approval.suggestion : null
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      if(approval.status.value == 0){
        this.setState({modal:false, error:{}})
      }
      else{
       this.setState({pageLoader:true})
      this.changeObligationStatusApi(this.props, this.props.id, payload)
      .then(response => {
        this.setState({pageLoader:false});
        if(checkApiStatus(response)) {
          this.setState({ approval: response.data.data, modal:false, error:{} })
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else {
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }
    }else{
      this.setState({error:validationInputs});
    }
  }

  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }

  render(){
    const { modal, approval, error, formSubmitLoader, pageLoader, options } = this.state;
    const { id } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        {
          id ?
          <Tooltip title="Change Obligation Status" arrow>
            <BorderColorIcon fontSize="small" color="primary" onClick={() => this.setState({modal:true , approval:{status:{label:'Pending', value: 0},suggestion:'' }})}/>
          </Tooltip> : null
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, error: {}, approval:{}})}
            size="md"
            fullWidth
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Obligation Change Status
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    options = {options}
                    // options = {[{label:'Approve', value: '1'}, {label: 'Reject', value: '2'},{label: 'Closed', value: '3'}]}
                    getOptionLabel={option => option.label}
                    id="status"
                    value={options.find(item => item?.value == approval.status?.value ) || null }
                    onChange={(e, value) => this.onFieldChange('status', value)}
                    renderInput={params => <TextField required error={error.status ? true:false} helperText={error.status ? error.status : ''} onFocus={(e) => this.updateErrorField('status')}  {...params} label="Status" placeholder="Select Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                { approval.status && approval.status.value == 2 ?
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="suggestion"
                      label="Reason of Rejection"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      inputProps={{ maxLength: 255 }}
                      value={approval.suggestion ? approval.suggestion:''}
                      error={error.suggestion ? true:false}
                      helperText={error.suggestion ? error.suggestion : ''}
                      onChange={(e, value) => this.onFieldChange('suggestion', e.target.value)}
                      onFocus={(e) => this.updateErrorField('suggestion')}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid> : null
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, error:{}})} color="primary">Cancel</Button>
              <Button onClick={() => this.changeObligationStatus(this.props)} variant="contained" color="primary" disabled={formSubmitLoader}>
                { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Change' }
              </Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ChangeStatusModal));
