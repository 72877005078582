import { globalGetService, globalPostService, globalPutService} from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { globalDeleteService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils';
export function getSecurityDepositListApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/security-deposits/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data
      })
    }
    this.setState({skeletonLoader: false});
  })
}
export function getSecurityDepositApi(props={}, id, mode){
  this.setState({pageLoader: true});
  globalGetService(`contracts/${props.params.contract_slug}/security-deposits/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ securityDeposit:response.data.data, error:{}, modal:true, mode: mode === 'V'?'view':'edit'})
    }
    this.setState({pageLoader: false});
  })
}
export function addEditSecurityDepositApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/security-deposits/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/security-deposits/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}

export function getTxnDetailApi(props={}, securityDepositId, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/security-deposit/${securityDepositId}/transactions/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ transaction: response.data.data, error:{}, modal:true, mode:mode === 'V'?'view':'edit'})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditSecurityDepositTxnApi(props={}, securityDepositId, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/security-deposit/${securityDepositId}/transactions/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/security-deposit/${securityDepositId}/transactions/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}
export function deleteCashAndNonCashTxApi(props={}, deleteObj){
  globalDeleteService(`contracts/${props.params.contract_slug}/security-deposit/${deleteObj.data.securityDepositId}/transactions/${deleteObj.data.id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      toastFlashMessage(response?.data?.message, 'success');
      this.setState({deleteItem:{modal:false, data:null}})
      this.getSecurityDepositListApi(props, {});
    }
  })
}
export function securityDepositMLApi(props={}, query={}){
  globalGetService(`ml/contract/${props.params.contract_slug}/data-points/`,{feature:'ctsecuritydeposits'})
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}


