import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Paper, Card, CardContent, Grid, IconButton } from '@material-ui/core';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import NumericLabel from 'react-pretty-numbers';
import EditIcon from '@material-ui/icons/Edit';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { LabelValueCard, PageLoader } from '../../../../shared_elements';
import { OtherFees, DebtEquity } from '../components';
import { assetsType } from '../../../../constants';
import { getFianceDetailApi } from '../apiServices';
import { getCurrencyFormat, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import { sExportContractsAc } from '../../../actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class FinancingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: true,
      financial: {},
    }
    this.getFianceDetailApi = getFianceDetailApi.bind(this);
  }
  componentDidMount() {
    this.getFianceDetailApi(this.props);
    this.props.fetchContractInfo();
    trackActivity('Page Visited', {
      page_title: 'Financing Details'
    })
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  getResponseBack = () => {
    this.getFianceDetailApi(this.props);
  }
  render() {
    const { financial, skeletonLoader } = this.state;
    const {contractInfo, contractSummaryLoader}= this.props
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Financing Details" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='finance-detail' primaryTab='basic-betails' secondaryTab='financingDetails' />
          <ContractWrapper
            revision={false}
          >
            <Grid container spacing={2} alignItems="stretch" direction="row">
              <Grid item xs={12} md={6}>
                <Card className="contract-view-card">
                  <CardContent>
                    <div className="card-title flex-centered">
                      <h4>Debt / Equity Mix</h4>
                      { checkPermission('contracts','financing_details', 'U') && contractInfo?.hdlineInfo?.archived_asset == false ? <span>
                        <DebtEquity financial={financial} getResponseBack={this.getResponseBack} />
                      </span> : null}
                    </div>
                    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Purchase value of asset' value={financial.purchase_value ? <NumericLabel params={getCurrencyFormat()}>{financial.purchase_value}</NumericLabel> : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Tax Rate' value={financial.tax_rate ? financial.tax_rate + '%' : '--'} />
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Debt / Equity Ratio' value={`${financial.debit_ratio ? financial.debit_ratio : '--'}:${financial.equity_ratio ? financial.equity_ratio : '--'}`} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Debt Value' value={financial.debit_value ? <NumericLabel params={getCurrencyFormat()}>{financial.debit_value}</NumericLabel> : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Equity Value' value={financial.equity_value ? <NumericLabel params={getCurrencyFormat()}>{financial.equity_value}</NumericLabel> : '--'} />
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Interest Rate on Debt p.a' value={financial.interest_rate ? financial.interest_rate + '%' : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Expected Return on Equity p.a' value={financial.return_equity ? financial.return_equity + '%' : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={4} label='Weighted Average Cost of Capital' value={financial.weighted_average_capital ? financial.weighted_average_capital + '%' : '--'} />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="contract-view-card" style={{ height: '295px' }}>
                  <CardContent>
                    <div className="card-title flex-centered">
                      <h4>Other Fees / Costs</h4>
                      {checkPermission('contracts','financing_details', 'U') && contractInfo?.hdlineInfo?.archived_asset == false ? <span><OtherFees financial={financial} getResponseBack={this.getResponseBack} /></span> : null}
                    </div>
                    <Grid container spacing={2}>
                      <LabelValueCard skeletonLoader={skeletonLoader} md={6} label='Lease Servicer Fee p.a' value={financial.lease_service_fee ? <NumericLabel params={getCurrencyFormat()}>{financial.lease_service_fee}</NumericLabel> : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={6} label='Administrator Fee p.a' value={financial.administrator_fee ? <NumericLabel params={getCurrencyFormat()}>{financial.administrator_fee}</NumericLabel> : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={6} label='Remarketing Cost' value={financial.re_marketing_cost ? <NumericLabel params={getCurrencyFormat()}>{financial.re_marketing_cost}</NumericLabel> : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} md={6} label='Asset Management Cost p.a' value={financial.asset_management_cost ? <NumericLabel params={getCurrencyFormat()}>{financial.asset_management_cost}</NumericLabel> : '--'} />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ContractWrapper>
        </div>
        { contractSummaryLoader ? <PageLoader/>:null }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FinancingDetails,['contracts','financing_details', 'R']))));
