import React, { Component } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { regexConstants } from '../../../constants/regEx';
import { showCurrencyFormat } from '../../../utils';
const numberWithDot = /^[0-9]*(\.\d{0,2})?$/
export default class FieldInput extends Component{
  constructor(props){
    super(props);
    this.state = {
      fieldFocus : false,
    }
  }
  handleFocus = () => {
    this.setState({
      fieldFocus: true,
    });
    if(this.props.handleOnFocus){
      this.props.handleOnFocus();
    }
  }
  handleBlur = (e) => {
    this.setState({
      fieldFocus: false,
    });
    this.props.updateField(this.props.keyParam, e.target.value != '' ? e.target.value : null);
    if(this.props.handleOnBlur){
      this.props.handleOnBlur(this.props.keyParam, e.target.value != '' ? e.target.value : null)
    }
  }
  render(){
    const { type, value, keyParam, currency, currencyType, percentage,disable, maxLength,accept } = this.props;
    if(type != 'view'){
      return (
        <input type="text"
          value={ (value != '' || value != null || value != undefined) ? value : currency ? 0:''}
          onChange={(e) => accept=='number'? regexConstants.numberWithDot.test(e.target.value) ? this.props.updateField(keyParam, e.target.value): e.preventDefault():  this.props.updateField(keyParam, e.target.value)}
          onFocus={() => this.handleFocus()}
          onBlur={(e) => this.handleBlur(e)}
          disabled={disable? disable :false}
          style={{...this.props.style, borderBottom:'1px solid #828282'}}
          maxLength={maxLength}
        />
      )
    }else{
      if(currency){
        return (<h6 style={{wordWrap:'break-word'}}>{value ? <NumericLabel params={showCurrencyFormat(currencyType)}>{value}</NumericLabel>:'0'} </h6>)
      }else{
        return (<h6>{value ?  value : '--'} { value && percentage ? '%' : null} </h6>)
      }
    }
  }
}
