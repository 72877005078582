import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Tooltip, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PageLoader from './PageLoader';
import { displayDateFormatShort } from '../../constants';
import { getWhatsNewFeaturesListApi } from '../apiServices';
class WhatsNewCompleteList extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      modal:false,
      whtsNewList:[]
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
  }
  render(){
    const { pageLoader, modal, whtsNewList } = this.state;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        <Tooltip title="What's New" arrow>
          <IconButton size="small" color="primary" >
            <RecordVoiceOverIcon onClick={() => {this.getWhatsNewFeaturesListApi()}} color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, whtsNewList:[]})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              What's New
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="whats-new-timeline">
                    <ul className="list-unstyled">
                      {whtsNewList.map((item, index) =>
                        <li key={index}>
                          <div>
                            <span>{moment(item.release_date).format(displayDateFormatShort)}</span>
                            <h4>{item.title}</h4>
                            <div className="content" dangerouslySetInnerHTML={{ __html: item.description}}></div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false, whtsNewList:[]})}>Okay</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default WhatsNewCompleteList;
