import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalStorageInfo } from '../../utils';
const AssetSwitcher = ({match, history, assetsSwicher, onAssetChange}) => {
  return(
    <div style={{width:'200px'}}>
      <Autocomplete
        options = {assetsSwicher}
        getOptionLabel={option => option.unique_name}
        value={null}
        id="asset"
        disableClearable={true}
        filterSelectedOptions={true}
        renderOption={(option) => (
          <Fragment>
            {option.unique_name}
          </Fragment>
        )}
        onChange={(e, asset) => onAssetChange(asset)}
        renderInput={params => <TextField {...params} placeholder="Select Asset" margin="none" fullWidth InputLabelProps={{shrink: true}} />}
      />
    </div>
  )
}
export default withRouter(AssetSwitcher);
