import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus } from '../../../utils_v2';
import { getMRUtilizationApi, editMRUtilizationApi } from '../apiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { errorCode } from '../';
class EditMRUtilization extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      mode:'',
      mrUtilsCrud: {},
      error:{}
    }
    this.getMRUtilizationApi = getMRUtilizationApi.bind(this);
    this.editMRUtilizationApi = editMRUtilizationApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    const { rentalUtilis } = this.props;
    if(keyParam === 'asset_location' || keyParam === 'remarks'){
      this.setState(prevState => ({
        ...prevState,
        mrUtilsCrud: {
          ...prevState.mrUtilsCrud,
          [keyParam]:data
        }
      }));
    }else{
      switch (keyParam) {
        case 'hours':
          this.setState(prevState => ({
            ...prevState,
            mrUtilsCrud: {
              ...prevState.mrUtilsCrud,
              hours:parseFloat(data),
              tsn:parseFloat(prevState.mrUtilsCrud.tsn_as_of_last_month)+parseFloat(data.trim() ? data:0)
            }
          }))
          break;
        case 'cycles':
          this.setState(prevState => ({
            ...prevState,
            mrUtilsCrud: {
              ...prevState.mrUtilsCrud,
              hours:parseFloat(data),
              tsn:parseFloat(prevState.mrUtilsCrud.csn_as_of_last_month)+parseFloat(data.trim() ? data:0)
            }
          }))
          break;
        case 'tsn':
          this.setState(prevState => ({
            ...prevState,
            mrUtilsCrud: {
              ...prevState.mrUtilsCrud,
              hours: parseFloat(data.trim() ? data:0) - parseFloat(prevState.mrUtilsCrud.tsn_as_of_last_month),
              tsn:parseFloat(data)
            }
          }))
          break;
        case 'csn':
          this.setState(prevState => ({
            ...prevState,
            mrUtilsCrud: {
              ...prevState.mrUtilsCrud,
              cycles: parseFloat(data.trim() ? data:0) - parseFloat(prevState.mrUtilsCrud.csn_as_of_last_month),
              csn:parseFloat(data)
            }
          }))
          break;
        default:

      }
    }

  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  checkTSNValid = (asset) => {
    if(asset.tsn_as_of_last_month >= 0 && ( asset.tsn === undefined || asset.tsn <= 0 )){
      return 'Please enter TSN'
    }else if (asset.tsn < asset.tsn_as_of_last_month) {
      return 'Can not be lesser than previous TSN'
    }else{
      return ''
    }
  }
  checkCSNValid = (asset) => {
    if(asset.csn_as_of_last_month >= 0 && ( asset.csn === undefined || asset.csn <= 0 )){
      return 'Please enter CSN'
    }else if (asset.csn < asset.csn_as_of_last_month) {
      return 'Can not be lesser than previous CSN'
    }else{
      return ''
    }
  }
  editMrUtilsFn = () => {
    const { mrUtilsCrud } = this.state;
    let validationInputs = {
      hours:mrUtilsCrud.hours > 0 ? '':'Please enter Hours',
      cycles:mrUtilsCrud.cycles > 0 ? '':'Please enter Cycles',
      tsn:this.checkTSNValid(mrUtilsCrud),
      csn:this.checkCSNValid(mrUtilsCrud),
    };
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      this.editMRUtilizationApi(this.props, mrUtilsCrud)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', mrUtilsCrud:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { formSubmitLoader, modal, mode, mrUtilsCrud, error } = this.state;
    const { id, m } = this.props;
    return(
      <Fragment>
        { id ?
          <EditIcon style={{float:'right', cursor:'pointer'}} fontSize="small" onClick={() => this.getMRUtilizationApi(this.props, id)} color="primary" />:null
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, mode:'', mrUtilsCrud:{}, error:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {capitalizeFirstLetter(mode)} MR Utilization
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <LabelValueCard md={12} label='Name' value={mrUtilsCrud.name ? mrUtilsCrud.name:'--'} />
                <LabelValueCard md={6} label='TSN of Last Month' value={mrUtilsCrud.tsn_as_of_last_month ? parseFloat(mrUtilsCrud.tsn_as_of_last_month).toFixed(2):'--'} />
                <LabelValueCard md={6} label='CSN of Last Month' value={mrUtilsCrud.csn_as_of_last_month ? parseFloat(mrUtilsCrud.csn_as_of_last_month).toFixed(2):'--'} />
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Hours' value={mrUtilsCrud.hours ? mrUtilsCrud.hours:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="hours"
                      label="Hours"
                      fullWidth
                      margin="normal"
                      value={mrUtilsCrud.hours ? mrUtilsCrud.hours:''}
                      error={error.hours ? true:false }
                      InputLabelProps={{shrink: true}}
                      helperText={error.hours ? error.hours: ''}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'hours', e.target.value):e.preventDefault()}}
                      onFocus={(e) => this.updateErrorField('hours', '')}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Cycles' value={mrUtilsCrud.cycles ? mrUtilsCrud.cycles:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="cycles"
                      label="Cycles"
                      fullWidth
                      margin="normal"
                      value={mrUtilsCrud.cycles ? mrUtilsCrud.cycles:''}
                      error={error.cycles ? true:false }
                      helperText={error.cycles ? error.cycles: ''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'cycles', e.target.value):e.preventDefault()}}
                      onFocus={(e) => this.updateErrorField('cycles', '')}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='TSN' value={mrUtilsCrud.tsn ? mrUtilsCrud.tsn:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="tsn"
                      label="TSN"
                      fullWidth
                      margin="normal"
                      value={mrUtilsCrud.tsn ? mrUtilsCrud.tsn:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'tsn', e.target.value):e.preventDefault()}}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='CSN' value={mrUtilsCrud.csn ? mrUtilsCrud.csn:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="csn"
                      label="CSN"
                      fullWidth
                      margin="normal"
                      value={mrUtilsCrud.csn ? mrUtilsCrud.csn:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'csn', e.target.value):e.preventDefault()}}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Present Location of Original Assembly' value={mrUtilsCrud.asset_location ? mrUtilsCrud.asset_location:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="asset_location"
                      label="Present Location of Original Assembly"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      inputProps={{ maxLength: 255 }}
                      value={mrUtilsCrud.asset_location ? mrUtilsCrud.asset_location:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'asset_location', e.target.value)}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={mrUtilsCrud.remarks ? mrUtilsCrud.remarks:'--' } /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      inputProps={{ maxLength: 255 }}
                      value={mrUtilsCrud.remarks ? mrUtilsCrud.remarks:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                    />
                  </Grid>
                }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({modal:false, mode:'', mrUtilsCrud:{}, error:{}})} color="primary">Cancel</Button>
              { mode !== 'view' ?
                <Button onClick={this.editMrUtilsFn} variant="contained" color="primary" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save' }
                </Button> :null
              }
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(EditMRUtilization));
