import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, browserHistory } from 'react-router';
import { Grid, Paper } from '@material-ui/core';
import { TableListComp, LabelValueCard, EmptyCollection, PageLoader, FilterUIComp, ExportManu } from '../../../shared_elements';
import { ReserveAccountFund } from '../../Elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { Cashflow, AccountFunds } from '../components';
import { sExportContractsAc, getReserveAccountsAc } from '../../actionCreators';
import { getLocalStorageInfo, removeEmptyKey, convertFilterObject } from '../../../utils_v2';
import { getCashflowsApi, getCashflowsWidgetApi, exportCashflowsApi } from '../apiServices';
import { assetsType } from '../../../constants';
import { cashflowHd, cashflowFilterOps } from '../';
class CashFlows extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      filter: {},
      applyingFilter:{},
      cashflowWidget:{},
      cashflows:{
        cashflow_list:[],
        pagination:{}
      }
    }
    this.getCashflowsApi = getCashflowsApi.bind(this);
    this.getCashflowsWidgetApi = getCashflowsWidgetApi.bind(this);
    this.exportCashflowsApi = exportCashflowsApi.bind(this);
  }
  componentDidMount(){
    this.getCashflowsApi(this.props, {}, 'skeletonLoader');
    this.props.getReserveAccountsFn();
    this.getCashflowsWidgetApi(this.props, {}, 'pageLoader');
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, cashflows } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getCashflowsApi(this.props, {...query, per_page:cashflows.pagination.per_page}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { skeletonLoader, pageLoader, cashflows, cashflowWidget, filter, applyingFilter } = this.state;
    const { reserveAccounts } = this.props;
    let filterOptions = cashflowFilterOps;
    filterOptions = {
      ...filterOptions,
      transaction_reference_id:{
        ...filterOptions.transaction_reference_id,
        options:reserveAccounts
      }

    }
    return(
      <section className="lease-management-section">
        <div className="mr-rate-wrapper  mr-rate-spacing">
          <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          <ContractSideBar application={`Cashflow`} type="brief" params={this.props.params} />
          <div className="view-block" style={{background:'none'}}>
            { Object.keys(cashflowWidget).length ?
              <Fragment>
                <Paper className="fund-acc-card">
                  <AccountFunds
                    cashflowWidget={cashflowWidget}
                  />
                </Paper>
                <Paper className="fund-acc-card">
                  <h3>Reserve fund status</h3>
                  <ReserveAccountFund
                    cashflowWidget={cashflowWidget}
                  />
                </Paper>
              </Fragment>:null
            }

            <div>
              { skeletonLoader ?
                (<STableLoader count={4} />):
                (
                  <Fragment>
                    <div style={{float:'right',marginBottom:'15px'}}>
                      <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'Doc', extension: 'doc', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportCashflowsApi(this.props, {...filter, download:file.extension})} />
                    </div>
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => this.getCashflowsApi(this.props, removeEmptyKey({ per_page: cashflows.pagination.per_page }), 'pageLoader')}
                      submitFilter={() => this.getCashflowsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions) }, 'pageLoader')}
                      applyFilter={this.applyFilter}
                      filterMenu={filterOptions}
                    />
                    <TableListComp
                      heads={cashflowHd}
                      data={cashflows.cashflow_list.map((item,index) =>
                        <Cashflow
                          cashflows={cashflows.cashflow_list}
                          index={index}
                          item={item} />
                        )
                      }
                      pagination={cashflows.pagination}
                      noRecord={ cashflows.cashflow_list.length ? null:
                        <EmptyCollection
                          title="No records found"
                        />
                      }
                    />
                  </Fragment>
                )
              }
            </div>
          </div>
        </div>
        { pageLoader ? <PageLoader /> :null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  reserveAccounts: state.contractReducer.reserveAccounts,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    getReserveAccountsFn:() => dispatch(getReserveAccountsAc(ownProps))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CashFlows));
