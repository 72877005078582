export const addQfdMntEvent = {
  event: '',
  qualified_items: '',
  start_date: null,
  end_date: null,
  remarks:'',
  reserve_account: ''
}
export const  qfdMntEventHd = [
  { label: 'Maintenance Reserve Account', sortOption: false },
  { label: 'Event', sortOption: false },
  { label: 'Qualified items', sortOption: false },
  { label: 'Validity', sortOption: false },
  { label: 'Action', sortOption: false }
];
export const errorCode = {
  reserve_account:{
    0:'',
    1: 'Please select Reserve Account'
  },
  reserve_accountObj: {
    required: true
  },
  qualified_items: {
    0:'',
    1: 'Please enter Qualified Items'
  },
  qualified_itemsObj: {
    required: true
  }
}
