import React from 'react';
import { Chip } from "@material-ui/core"
import moment from 'moment';
import { displayDateFormatShort } from '../../constants';
const FilterOutput = ({filter, filterMenu, onRemoveFilter}) => {
    const plotFilterValue = (key) => {
        switch (filterMenu[key].inputType) {
            case 'text':
                return <Chip size='small' 
                label={<span> <span style={{ fontWeight: '500' ,fontFamily:'Conv_IBMPlexSans-Medium'}}>{filterMenu[key].title}</span>: {filter[key]} </span>} onDelete={() => onRemoveFilter(key)} />
            case 'number':
                return <Chip size='small' 
                label={<span> <span style={{ fontWeight: '500' ,fontFamily:'Conv_IBMPlexSans-Medium'}}>{filterMenu[key].title}</span>: {filter[key]} </span>} onDelete={() => onRemoveFilter(key)} />
            case 'dropdown':
                const options = filterMenu[key].options;
                const valueKey = filterMenu[key].valueKey;
                const labelKey = filterMenu[key].labelKey;
                if (filterMenu[key].multiple) {
                    let value = '';
                    if(options && options.length && valueKey && labelKey && filter[key]){
                        let selectOptions = options.filter(item => filter[key].toString().split(',').includes(item[valueKey].toString()));
                        value = selectOptions.map(item => item[labelKey]).join(',');
                    }
                    return value ? <Chip size='small' label={<span> <span style={{ fontWeight: '500' ,fontFamily:'Conv_IBMPlexSans-Medium'}}>{filterMenu[key].title}</span>: {value} </span>} onDelete={() => onRemoveFilter(key)} />:null
                }else{
                    let value =  options && options.length && valueKey && labelKey && filter[key] ? options.find(item => item[valueKey] === filter[key])[labelKey]:'';
                    return value ? <Chip size='small' 
                     label={<span> <span style={{ fontWeight: '500' ,fontFamily:'Conv_IBMPlexSans-Medium'}}>{filterMenu[key].title}</span>: {value} </span>} onDelete={() => onRemoveFilter(key)} />:null
                }
            case 'date': 
                return <Chip 
                 size='small'
                 label={<span> <span style={{ fontWeight: '500' ,fontFamily:'Conv_IBMPlexSans-Medium'}}>{filterMenu[key].title}</span>: {moment(filter[key]).format(filterMenu[key].format ? filterMenu[key].format:displayDateFormatShort)} </span>} onDelete={() => onRemoveFilter(key)} />
             }
    }
    const setOrderForFilter = () => {
        let newFilter = {};
        Object.keys(filterMenu).forEach(key => {
            if(filter[key]){
                newFilter = {
                    ...newFilter,
                    [key]: filter[key]
                }
            }
        });
        return newFilter;
    }
    return (
        <div className='filter-result'>
            <ul className='list-inline'>
                {Object.keys(setOrderForFilter(filter)).map((key,index) => 
                    <li className='list-inline-item'>{plotFilterValue(key)}</li>
                )}
                {filter && Object.keys(filter).length && Object.keys(filter).length > 1 ?
                    <li className='list-inline-item'>
                        <Chip color='primary' size='small' label='Clear All' onDelete={() => onRemoveFilter('all')} />
                    </li>:null
                }
            </ul>
        </div>
    );
}
export default FilterOutput;