import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadMrAccuralAc, accrualsCashFowAC, mrAccuralexportAc } from '../actionCreators';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { CashFlowList } from '../../../maintenance/MRFinanceGraph/components'
import { Header, ContractSideBar, ContractBarLoader } from '../../Elements';
import { Link } from 'react-router';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { imgStoragePath, assetsType } from '../../../../constants';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import{RESET_SIMULATION} from '../../../maintenance/MRFinanceGraph/actions';
class MrAccrualCashFlow extends Component{
    constructor(props) {
        super(props);
        this.state={
          filter: null
        }
    }
    componentDidMount(){
        this.props.listCashFlow('mr-accrual-cash');
        this.props.fetchAssetInfo('mr-accrual-cash');
    }
    updateFilter = (value, type) => {
      this.setState(prevState => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          [type]: value
        }
      }))
    }
    changeMsn =(evt) =>{
      if(evt.contracts == false){
        let url = '/assets-listing'
        browserHistory.push(url)
      }else{ // (evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == '')
        let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
        browserHistory.push(url);
      }
    }
    render(){
        const  { cashflowMrAccrual, ctrShortInfo, mrAccuralLoader } = this.props;
        return(
            <div className="contract-container">
             {mrAccuralLoader?<ListLoader />:null}
                <div>
                    <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
                </div>
                <ContractSideBar application={`MR Accrual Cash Flow`} type="brief" params= {this.props.params} />

                <div className="cash-flow-wrapper">
                <h5 className="para invoice-heading" style={{position:'relative',marginBottom:'20px',marginLeft:'60px'}}>

                  <p style={{marginTop:'0px'}}><Link style={{color:'#bd10e0', fontSize:'15px'}} to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${this.props.params.contract_slug}/mr-accrual`}>Fund Accrual</Link><span style={{margin: '0 3px'}}>/</span><span style={{color:'#a0a0a0', fontSize:'15px'}}>Fund Accrual Cash Flow</span></p>
                  <span onClick={() => this.props.exportMrAccrualflow(this.state.filter)}style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px', position: 'absolute', right:'0px', top:'0px', color:'#3f51b5'}}><img width="18" src={exportIcon} alt="export" /> EXPORT</span>
                  </h5>
                    { cashflowMrAccrual.length ?
                      <CashFlowList
                        cashFlow={cashflowMrAccrual}
                        paddingSpace = {60}
                        updateFilter={this.updateFilter}
                      />:null
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ctrShortInfo: state.ContractListReducer.ctrShortInfo,
    cashflowMrAccrual: state.MRAccuralReducer.cashflowMrAccrual,
    mrAccuralLoader: state.MRAccuralReducer.mrAccuralLoader,
  });

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchAssetInfo: (type) => dispatch(loadMrAccuralAc(ownProps.params,type)),
        listCashFlow: (type) => dispatch(accrualsCashFowAC(ownProps,type)),
        exportMrAccrualflow:(filter)=>dispatch(mrAccuralexportAc(ownProps, filter)),
        resetSimlute: (type) => dispatch({
          type: RESET_SIMULATION,
          payload: type
        }),
        exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MrAccrualCashFlow)
