import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { Link, withRouter } from 'react-router';
import ShareInvoice from './ShareInvoice';
export const invoiceTypes = [
  {label: 'Reserve Invoice', value: 1},
  {label: 'Rental Invoice', value: 2},
  {label: 'Penalty Invoice', value: 3},
  {label: 'Debit Note Invoice', value: 4},
  {label: 'Credit Note Invoice', value: 5},
  {label: 'Security Despoit Invoice', value: 6},
  {label: 'Insurance Invoice', value: 7},
  {label: 'Insurance Claim Invoice', value: 8},
  {label: 'Adhoc Invoice', value: 9}
];
const InvoiceBackNav = ({params, mode, invoiceInfo, exportInvoiceFn}) => {
  return(
    <div className="back-invoice-view">
      <Grid container spacing={0}>
        <Grid item xs={12} md={5}>
          <Link to={`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/invoice/`}><ArrowBackIcon style={{float:'left'}} /></Link>
          <div>
            <p>{invoiceInfo && invoiceInfo.invoice_type ? invoiceTypes.find(item => item.value === invoiceInfo.invoice_type).label:''}</p>
            <h3>Invoice {invoiceInfo && invoiceInfo.invoice_number ? ' #'+invoiceInfo.invoice_number:''}</h3>
          </div>
        </Grid>
        { mode === 'view'  ?
          <Grid item xs={12} md={7}>
            <ul className="list-inline flex-centered">
              { invoiceInfo.status === 1 || invoiceInfo.status === 2 ?
                <Fragment>
                  <li className="list-inline-item">
                    <Link to={`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/invoice/edit/${params.id}`}>Edit Invoice</Link>
                  </li>
                  <li className="list-inline-item">
                    <ShareInvoice />
                  </li>
                </Fragment>
                :null
              }


              <li className="list-inline-item">
                <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'Doc', extension: 'doc', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => exportInvoiceFn(file)} />
              </li>
            </ul>
          </Grid>:null
        }
      </Grid>
    </div>
  )
}
export default withRouter(InvoiceBackNav);
