import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
export default class UtilsListInfo extends Component{
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="mobile-asset-list">

      <div className="asset-listing-wrap utilization-history" style={{marginLeft:'0px'}} >
        <div className="asset-list-filter-sort">
          <Row className="filter-block">
            <Col><h6 style={{width:'130px'}}>Month</h6></Col>
            <Col><h6>Major Assembly</h6></Col>
            <Col><h6>Hours</h6></Col>
            <Col><h6>Cycles</h6></Col>
            <Col><h6>TSN ( Hrs )</h6></Col>
            <Col><h6>CSN ( Cycles )</h6></Col>
            <Col><h6>Present Location</h6></Col>
            <Col><h6>Serviceability</h6></Col>
            <Col sm={1}><h6 style={{  float: 'right' }}>Action</h6></Col>
          </Row>
        </div>
        {this.props.children}
      </div>
      </div>
    )
  }
}
