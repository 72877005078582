import React, { useEffect, useState } from 'react'
import { Header } from '../../../applications/contracts/Elements';
import { assetId, assetsType, backendDateFormat } from '../../../constants';
import { browserHistory } from 'react-router';
import { ContractSideBar } from '../../../applications/contracts/Elements';
import { AssemblySideBar } from '../../../applications/maintenance/Elements';
import AssemblyLeaseDetails from '../components/AssemblyLeaseDetails';
import { globalGetService, globalPutService, globalPostService } from '../../../globalServices';
import { checkDateDiffText, checkString, checkValidDate } from '../../../formValidator';
import { checkApiStatus, toastFlashMessage } from '../../../utils_v2';
import moment from 'moment';
import { PageLoader } from '../../../shared_elements';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const AssemblyDetails = (props) => {
    const [assemblyLeaseDetails, setAssemblyLeaseDetails] = useState([])
    const [assemblyList, setAssemblyList] = useState([])
    const [assignmentmsnList, setAssignmentmsnList] = useState([])
    const [majorAssembliesList, setMajorAssembliesList] = useState([])
    const [attatchedDoc, setAttatchedDoc] = useState([])
    const [attachedDocList, setAttatchedDocList] = useState([])
    const [soldToList, setSoldToList] = useState([])
    const [validationMsgs, setValidationMsgs] = useState({})
    const [editFlag, setEditFlag] = useState(false)
    const [pageLoader, setPageLoader] = useState(false)
    const [listLoader, setListLoader] = useState(false)

    useEffect(() => {
        getAssignmentMsnListFn()
        getmajorAssembliesListFn()
        getAssemblyLeaseDetailsFn()
        getAssemblyLeaseAttachment()
    }, [])
    const getmajorAssembliesListFn = () => {
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease-assets/major-assemblies/`)
            .then(response => {
                setMajorAssembliesList(response.data.data.list)
            })
    }
    const getAssemblyLeaseDetailsFn = (data) => {
        setListLoader(true)
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/${props.params?.slug}`)
            .then(response => {
                if (checkApiStatus(response)) {

                    setListLoader(false)
                    setAssemblyLeaseDetails(response.data.data)
                }
                else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const getAssemblyLeaseAttachment = () => {
        globalGetService(`/contracts/attachments/?assembly_lease=${props?.params?.slug}`)
            .then(response => {
                if (response.data.statusCode == 200) {
                    setAttatchedDocList(response.data?.list?.data)
                } else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const onLeaseDetailsChange = (key, leaseData) => {
        setAssemblyLeaseDetails({ ...assemblyLeaseDetails, [key]: leaseData })

    }
    const updateErrorField = (key) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
    }
    const getAssignmentMsnListFn = () => {
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease-assets/assignment-msn/`)
            .then(response => {
                setAssignmentmsnList(response.data.data.list)
            })
    }
    const editAssemblyLeaseDetailsFn = () => {
        let data = assemblyLeaseDetails
        let validateNewInput = {
            assignment_msn: checkString({ value: data.assignment_msn, required: true, minLength: '', maxLength: '', message: 'Please Select Assignment MSN' }),
            major_assembly: checkString({ value: data.major_assembly, required: true, minLength: '', maxLength: '', message: 'Please Select Major Assembly ' }),
            removal_tsn: checkString({ value: data.removal_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Removal TSN ' }),
            installation_tsn: checkString({ value: data.installation_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Installation TSN ' }),
            removal_csn: checkString({ value: data.removal_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Removal CSN ' }),
            installation_csn: checkString({ value: data.installation_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Installation CSN ' }),
            removal_date: checkValidDate({ value: data.removal_date, required: true, minLength: '', maxLength: '', message: 'Please Select Removal Date ' }) ?  'Please Select Removal Date ' : checkDateDiffText({ value: data.removal_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Removal Date', "Today's date"),
            installation_date: checkValidDate({ value: data.installation_date, required: true, minLength: '', maxLength: '', message: 'Please Select Installation Date ' }) ? 'Please Select Installation Date ' : checkDateDiffText({ value: data.installation_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Installation Date', "Today's date"),
            last_overhaul_date: checkValidDate({ value: data.last_overhaul_date, required: true, minLength: '', maxLength: '', message: 'Please Select Last Overhaul Date ' }) ? 'Please Select Last Overhaul Date ' : checkDateDiffText({ value: data.last_overhaul_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Last Overhaul Date', "Today's date"),
            last_overhaul_csn: checkString({ value: data.last_overhaul_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Last Overhaul CSN ' }),
            last_overhaul_tsn: checkString({ value: data.last_overhaul_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Last Overhaul TSN ' }),
            emails: checkString({ value: data.emails, required: true, minLength: '', maxLength: '', message: 'Please Enter the Email Ids' }),
        }
        if (data?.assembly_swap?.value == '1') {
            validateNewInput = {
                ...validateNewInput,
                daily_rate: checkString({ value: data.daily_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Daily Rate ' }),
                utilization_rate: checkString({ value: data.utilization_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Utilization Rate ' }),
                lease_start_date: checkValidDate({ value: data.lease_start_date, required: true, minLength: '', maxLength: '', message: 'Please Select Lease Start Date ' }),
                lease_end_date: checkValidDate({ value: data.lease_end_date, required: true, minLength: '', maxLength: '', message: 'Please Select Lease End Date ' }),

            }
        }
        if (data?.assembly_swap?.value == '0') {
            validateNewInput = {
                ...validateNewInput,
                sale_tsn: checkString({ value: data.sale_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale TSN ' }),
                sale_csn: checkString({ value: data.sale_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale CSN ' }),
                sale_price: checkString({ value: data.sale_price, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale Price ' }),
                sold_to: checkString({ value: data.sold_to, required: true, minLength: '', maxLength: '', message: 'Please Select Sold To ' }),
                sale_date: checkValidDate({ value: data.sale_date, required: true, minLength: '', maxLength: '', message: 'Please Select Sale Date ' }) ? 'Please Select Sale Date ' : checkDateDiffText({ value: data.sale_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Sale Date', "Today's date"),
            }
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            let payload = {
                ...data,
                installation_date: moment(data?.installation_date).format(backendDateFormat),
                last_overhaul_date: moment(data?.last_overhaul_date).format(backendDateFormat),
                removal_date: moment(data?.removal_date).format(backendDateFormat), 
                lease_start_date: data?.lease_start_date ? moment(data?.lease_start_date).format(backendDateFormat) : null,
                lease_end_date: data?.lease_end_date ?moment(data?.lease_end_date).format(backendDateFormat) : null,
                termination_date: data?.termination_date ? moment(data?.termination_date).format(backendDateFormat) : null,
                sale_date: data?.sale_date ?moment(data?.sale_date).format(backendDateFormat) : null,
                lease_extended_date: data?.lease_extended_date ?moment(data?.lease_extended_date).format(backendDateFormat) : null,
            }
            setPageLoader(true)
            globalPutService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/${data?.slug}/`, payload)
                .then(response => {
                    getAssemblyLeaseDetailsFn()
                    setPageLoader(false)
                    setEditFlag(false)
                    if (attatchedDoc.length) {
                        setPageLoader(true)
                        let formdata = new FormData()
                        attatchedDoc.map(item => {
                            formdata.append('files', item)
                        })
                        formdata.append('asset_type', assetId[props.params?.type])
                        formdata.append('asset_slug', props.params.aircraft_slug)
                        formdata.append('assembly_lease_slug', props?.params?.slug)
                        formdata.append('model_type', '4')
                        globalPostService(`contracts/attachments/`, formdata)
                            .then(response => {
                                setPageLoader(false)
                                getAssemblyLeaseAttachment()
                                if (response.data.statusCode == 200) {
                                    toastFlashMessage(`Lease updated and ${response.data.message}`, 'success')
                                }
                                else {
                                    toastFlashMessage(response.data.message, 'error')
                                }
                            })
                    }
                    if (checkApiStatus(response)) {
                        if(!attatchedDoc.length ){
                            toastFlashMessage(response.data.message, 'success')
                        } 
                    }
                    else {
                        toastFlashMessage(response.data.message, 'error')
                    }
                })
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }
    return (
        <div className="contract-container">
              {
                pageLoader || listLoader  ? <PageLoader /> : null
            }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} assemblytype={'assemblytype'} params={props.params} />
            <div className="contract-content-blk">
                <AssemblyLeaseDetails
                    getAssemblyLeaseAttachment={getAssemblyLeaseAttachment}
                    attachedDocList={attachedDocList.filter(item => item.assembly_lease ==  assemblyLeaseDetails?.id )}
                    getAssemblyLeaseDetailsFn={getAssemblyLeaseDetailsFn}
                    setEditFlag={setEditFlag}
                    editFlag={editFlag}
                    onLeaseDetailsChange={onLeaseDetailsChange}
                    setAttatchedDoc={setAttatchedDoc}
                    updateErrorField={updateErrorField}
                    validationMsgs={validationMsgs}
                    soldToList={soldToList}
                    majorAssembliesList={majorAssembliesList}
                    editAssemblyLeaseDetailsFn={editAssemblyLeaseDetailsFn}
                    assignmentmsnList={assignmentmsnList}
                    assemblyLeaseDetails={assemblyLeaseDetails}
                    props={props}
                />
            </div>
        </div>
    )
}

export default RoleBasedAccess(AssemblyDetails, ['assembly_lease','assembly_lease_details','R'])