import * as actions from '../actions';
import moment from 'moment'
export default function( state = {
  loginLoader: false,
  listLoader: true,
  userProfileLoader:false,
  userExportLoader:false,
  downloadLoader:false,
  processing:false,
  groupLoader:false,
  assetLoader:false,
  lessorLoader:false,
  deleteExpLoader:false,
  type:'view',
  assetLists: null,
  assetView: {},
  assetViewId: {},
  clients: [],
  portfolioList:[],
  operators: [],
  filteredLesseeList:[],
  filteredAircraftTypeList:[],
  filteredEngTypeList:[],
  filteredApuTypeList:[],
  aircraftTypes: [],
  application: [],
  engineTypes: [],
  overviewTechInfo: {},
  engineList: [],
  apuList: [],
  apuTypesList: [],
  assetFilter: {},
  assetCounts: {},
  paginationInfo: {},
  recordInit: {},
  userProfileInfo:{},
  usersList: {},
  inviteList: {},
  lessorList: {},
  groupList: [],
  groupListFiltering: [],
  groupListAll: [],
  assetList: [],
  assetListFiltering: [],
  userData:{
    first_name:'',
    last_name:'',
    email:'',
    contact_no:'',
    designation: '',
    description: '',
    reason_for_creation:'',
    user_role: 1
  },
  userAddData:{
    first_name:'',
    last_name:'',
    email:'',
    contact_no:'',
    designation: '',
    description: '',
    user_role: 1
  },
  userEmails: [],
  userCrudBar: false,
  userAddCrudBar:false,
  userContract:{
    type: 'add',
    flag: false,
    data:{
      start_date: '',
      end_date: '',
      contract_file: '',
      fee_rate: ''
    }
  },
  experienceData:{
    type: 'add',
    flag: false,
    data:{
      aircraft_type: '',
      year_of_experience: '',
      remarks: ''
    }
  },
  moduleTypes : [
    {label:'Fan', value:6},
    {label:'HPC', value:7},
    {label:'HPT', value:8},
    {label:'LPT', value:9}
  ],
  userContracts: [],
  userSkills: [],
  usersUsage:{},
  lessorList: [],
  userAvailability: {
    flag: false,
    type: 'add',
    data: {
      from_date: moment(),
      to_date: '',
      remarks: '',
      status: 1
    }
  },
  userAvailabilityList: {
    list: [],
    pagination: {}
  },
  assemblyLeaseDetails:{}
}, action){
  switch (action.type) {
    case actions.LIST_LOADER:
      return {
        ...state,
        listLoader: action.payload
      }
    case actions.USER_AVAILABILITY_CRUD:
      return {
        ...state,
        userAvailability: action.payload
      }
    case actions.USER_AVAILABILITY_LIST:
      return {
        ...state,
        userAvailabilityList: action.payload
      }
    case actions.APU_TYPES_LIST:
      return {
        ...state,
        apuTypesList: action.payload
      }
    case actions.LESSOR_LIST:
      return {
        ...state,
        lessorList: action.payload
      }
    case actions.ASSEMBLY_LEASE_DETAILS:
      return {
        ...state,
        assemblyLeaseDetails: action.payload
      }
    case actions.USERS_USAGE:
      return {
        ...state,
        usersUsage: action.payload
      }
    case actions.USER_CONTRACTS:
      return {
        ...state,
        userContracts: action.payload
      }
    case actions.USERS_SKILL:
      return {
        ...state,
        userSkills: action.payload
      }
    case actions.USER_CONTRACT_CRUD:
      return {
        ...state,
        userContract: action.payload
      }
    case actions.ASSET_LIST:
      if(action.payload.allTypeFlag){
        return {
          ...state,
          assetLists: action.payload.assetsInfo.list,
          paginationInfo: action.payload.assetsInfo.pagination,
          clients: action.payload.clients,
          operators: action.payload.operators,
          aircraftTypes: action.payload.aircraftTypes,
          engineTypes: action.payload.engineTypes,
          assetFilter: action.payload.queryFilter,
          assetCounts: action.payload.assetsInfo.asset_count,
          listLoader: false
         }
      }else{
        return {
          ...state,
          assetLists: action.payload.assetsInfo.list,
          paginationInfo: action.payload.assetsInfo.pagination,
          assetCounts: action.payload.assetsInfo.asset_count,
          listLoader: false
         }
      }

    case actions.ASSET_DETAIL:
      return {
        ...state,
        assetView: action.payload
      }
    case actions.PORTFOLIO_LIST:
    return {
      ...state,
      portfolioList:action.payload
    }
    case actions.RECORDS_INIT:
      return {
        ...state,
        recordInit: action.payload
      }
    case actions.ASSET_DETAIL_ID:
      return {
        ...state,
        assetViewId: action.payload
      }
    case actions.ASSET_FILTER_UPDATE:
      return {
        ...state,
        assetFilter: {
          ...state.assetFilter,
          [action.payload.type]: action.payload.value
        }
      }
    case actions.ASSET_FILTER_EMPTY:
      return {
        ...state,
        assetFilter: {}
      }
    case actions.ASSET_SORT_UPDATE:
      let sortBy = 'asc';
      if(action.payload == state.assetFilter.sort){
        sortBy = (state.assetFilter.sort_by == 'asc') ? 'desc': 'asc';
      }
      return {
        ...state,
        assetFilter: {
          ...state.assetFilter,
          sort: action.payload,
          sort_by: sortBy
        }
      }
    case actions.FORM_SUBMIT_LOADER:
      return {
        ...state,
        loginLoader: action.payload
      }
    case actions.ADD_ASSET:
      return {
        ...state,
        assetLists:  [action.payload, ...state.assetLists]
      }
     case actions.USER_PROFILE_INFO:
      return {
        ...state,
        userProfileInfo: action.payload

      }
     case actions.UPDATE_USER_FIELD:
      return {
        ...state,
        userProfileInfo: action.payload

      }
     case actions.USER_PROFILE_LOADER:
      return {
        ...state,
        userProfileLoader: action.payload
      }
     case actions.USER_LIST_EXPORT_LOADER:
      return {
        ...state,
        userExportLoader: action.payload
      }
      case actions.PROFILE_DOWNLOAD_LOADER:
      return {
        ...state,
        downloadLoader: action.payload
      }

      case actions.PROFILE_PROCESSING:
      return {
        ...state,
        processing: action.payload
      }
      case actions.TYPE_UPDATE:
      return {
        ...state,
        type: action.payload
      }
      case actions.GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      }
      case actions.GET_INVITE_LIST:
      return {
        ...state,
        inviteList: action.payload
      }
      case actions.GET_LESSOR_LIST:
      return {
        ...state,
        lessorList: action.payload,
        lessorLoader: !state.lessorLoader
      }
      case actions.GET_GROUPS_LIST:
        if(action.flag == 'all'){
          return {
            ...state,
            groupListAll: action.payload
          }
        }else{
          return {
            ...state,
            groupList: action.payload,
            groupListFiltering: action.payload,
            groupLoader: true
          }
        }
      case actions.FILTER_SECURITY_GRP_LIST:
        return {
          ...state,
          groupList: state.groupListFiltering.filter(group => group.name.toLowerCase().includes(action.payload.toLowerCase()))
        }
      case actions.GET_ASSETS_LIST:
        return {
          ...state,
          assetList: action.payload,
          assetListFiltering: action.payload,
          assetLoader: true
        }
      case actions.FILTER_ASSET_LIST:
        return {
          ...state,
          assetList: state.assetListFiltering.filter(asset => asset.asset_name.toLowerCase().includes(action.payload.toLowerCase()))
        }
      case actions.GROUP_LOADER_UPDATE:
      return {
        ...state,
        groupLoader: !state.groupLoader
      }

      case actions.LESSOR_LOADER_UPDATE:
      return {
        ...state,
        lessorLoader: !state.lessorLoader
      }

      case actions.ASSET_LOADER_UPDATE:
      return {
        ...state,
        assetLoader: !state.assetLoader
      }

      case actions.REJECT_INVITE_REQUEST:
      return {
        ...state,
        inviteList: {
          ...state.inviteList,
          list: state.inviteList.list.filter(item => item.slug != action.payload)}
      }
      case actions.ADD_USER_GROUP:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, sparta_group:[...state.userProfileInfo.sparta_group, ...action.payload] }
      }
      case actions.ADD_USER_LESSOR:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, lessor_list:[...state.userProfileInfo.lessor_list, ...action.payload] }
      }

      case actions.ADD_USER_ASSETS:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, asset:[...state.userProfileInfo.asset, ...action.payload] }
      }
      case actions.DELETE_USER_LESSOR:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, lessor_list: state.userProfileInfo.lessor_list.filter(lessor => action.payload.indexOf(lessor.id) == -1)}
      }
      case actions.DELETE_USER_ASSET:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, asset: state.userProfileInfo.asset.filter(asset => action.payload.indexOf(asset.id) == -1)}
      }
      case actions.DELETE_USER_GROUP:
      return {
        ...state,
        userProfileInfo: {...state.userProfileInfo, sparta_group: state.userProfileInfo.sparta_group.filter(group => action.payload.indexOf(group.id) == -1)}
      }
      case actions.USER_CRUD_BAR:
        return{
          ...state,
          userCrudBar: !state.userCrudBar,
          userData:{
            first_name:'',
            last_name:'',
            email:'',
            contact_no:'',
            designation: '',
            description: '',
            user_role: 1
          }
        }
        case actions.USER_ADD_CRUD_BAR:
        return{
          ...state,
          userAddCrudBar: !state.userAddCrudBar,
          userData:{
            first_name:'',
            last_name:'',
            email:'',
            contact_no:'',
            designation: '',
            description: '',
            user_role: 1
          }
        }
      case actions.GET_USER_CRUD:
        return{
          ...state,
          userData:{
            first_name:'',
            last_name:'',
            email:'',
            contact_no:'',
            designation: '',
            description: '',
            user_role: 1,
            security_groups:[]
          }
        }
      case actions.SET_USER_DATA:
        return{
          ...state,
          userData:{...state.userData, [action.payload.type]:action.payload.value}
        }
      case actions.USER_EMAIL_LIST:
        return {
          ...state,
          userEmails: action.payload
        }
      case actions.USER_PROJECT_RATING:
        return {
          ...state,
          userProjectRating: action.payload
        }
        case actions.ADD_UPDATE_EXPEREIENCE:
        return{
          ...state,
          experienceData:action.payload
        }

        case actions.ADD_EXPERIENCE:
        return{
          ...state,
          userProfileInfo: {...state.userProfileInfo, experience_of_aircraft_type:[...state.userProfileInfo.experience_of_aircraft_type, ...action.payload] }
        }

        case actions.UPDATE_EXPERIENCE:
        return{
          ...state,
          userProfileInfo: {...state.userProfileInfo,
            experience_of_aircraft_type: state.userProfileInfo.experience_of_aircraft_type.map(exp => action.payload.id==exp.id ?
              action.payload
              :exp)}
        }
        case actions.DELETE_EXPERIENCE:
        return{
          ...state,
          userProfileInfo: {...state.userProfileInfo, experience_of_aircraft_type: state.userProfileInfo.experience_of_aircraft_type.filter(exp => action.payload!=exp.id)}
        }
        case actions.DELETE_EXP_LOADER:
        return{
          ...state,
          deleteExpLoader: action.payload
        }
        case actions.FILTERED_LESSEE_LIST:
        return{
          ...state,
          filteredLesseeList: action.payload
        }
        case actions.FILTERED_AIRCRAFT_TYPE_LIST:
        return{
          ...state,
          filteredAircraftTypeList: action.payload
        }
        case actions.FILTERED_ENGINE_TYPE_LIST:
        return{
          ...state,
          filteredEngTypeList: action.payload
        }
        case actions.FILTERED_APU_TYPE_LIST:
        return{
          ...state,
          filteredApuTypeList: action.payload
        }
      default:
        return state;
  }
}
