import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import ContractSideLink from './ContractSideLink';
import { assetViewIdAc, getAssemblyLeaseDetailsAc } from '../../../User/actionCreators';
import { getLocalStorageInfo, getCookie } from '../../../utils';
import { checkPermission } from '../../../utils_v2';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import { ToolTipHover } from '../../../shared'
import { imgStoragePath } from '../../../constants';
const leaseDetailUrls = ['contract/list', 'working-group', 'parties', 'cape-town-convention', 'sub-lease', 'obligations', 'security-deposit', 'insurance', 'finance-detail', 'lease-option', 'induction-condition', 'delivery-condition', 'return-condition', 'qualified-maintenance', 'assembly-details']
class ContractSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contMblSidebar: false
    }
  }
  componentDidMount() {
    if (this.props.type == 'shorter') {
      this.props.fetchAssetView(this.props.params.aircraft_slug);
    }
    if (this.props?.assemblytype == 'assemblytype') {
      this.props.fetchAssemblyLeaseDetails(this.props);
    }
    this.props.fixedPositionFn('closeMaintenance', this.props.closeMaintenance);

  }
  checkForActiveUrl = () => {
    return leaseDetailUrls.some(element => {
      if (this.props.location.pathname.includes(element)) {
        return true;
      }
      return false;
    });
  }
  ContractSideBarToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      contMblSidebar: !this.state.contMblSidebar
    }));
  }

  closeMblSidebar = () => {
    this.setState(prevState => ({
      ...prevState,
      contMblSidebar: !this.state.contMblSidebar
    }));
  }

  render() {
    const { basicInfo, assetViewId, params, assemblyLeaseDetails } = this.props;
    const pathname = this.props.location.pathname;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    if (this.props.type == "shorter") {
      return (
        <div className="">
          <div className="mobile-bread-crumb hidden-lg">
            <Link className="bread-crumb" id="ham" onClick={() => this.ContractSideBarToggle()}>
              <span></span>
              <span className="cont-breadcrumb-bar"></span>
              <span></span>
            </Link>
          </div>
          <div style={this.props.style} className={this.state.contMblSidebar ? "mr-calc-sidebar contract-side-bar sidebar-active " : "mr-calc-sidebar contract-side-bar"}>
            <div className="hidden-lg">
              <Link className="mbl-cross" onClick={this.closeMblSidebar}>
                <img src={imgStoragePath + "cross_w_icn.png"} alt="img" />
              </Link>
            </div>
            {
              Object.keys(assetViewId).length && Object.keys(assetViewId.contract).length ?
                <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0', paddingTop: '25px' }}>
                  <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contracts"} title="Lease List" />
                  {
                    [1,2,3,4,5].includes(assetViewId?.asset_type) && checkPermission('contracts','mou_list','R') ?
                      <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/mou"} title="MOU List" />
                      : null
                  }
                  { assetViewId?.asset_type == 1 && checkPermission('assembly_lease', 'assembly_lease_details','R') ?
                      <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-lease"} title="Assembly Lease" />
                      : null
                  }
                </ul> : 
                <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0', paddingTop: '25px' }}> 
                  <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contracts"} title="Lease List" />  
                  { [1,2,3,4,5].includes(assetViewId?.asset_type) && checkPermission('contracts','mou_list','R') ?
                      <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/mou"} title="MOU List" />
                      : null
                  }
                  {assetViewId?.asset_type == 1 && checkPermission('assembly_lease', 'assembly_lease_details','R') ? 
                    <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-lease"} title="Assembly Lease" /> : null
                  }
                </ul>
            }
          </div>
        </div>

      )
    } else {
      if (this.props?.assemblytype == 'assemblytype') {
        return (
          <div style={this.props.style}>
            <div style={this.props.style} className={this.state.contMblSidebar ? "mr-calc-sidebar contract-side-bar sidebar-active " : "mr-calc-sidebar contract-side-bar"}>
              <div className="mr-calc-heading">
                <Link className="ct-back-icon" to={"/" + params.type + "/" + params.aircraft_slug + "/contracts"} onClick={this.closeMblSidebar}>
                  <img className="back-icon" src={imgStoragePath + "contract-back.png"} alt="img" />
                </Link>
              </div>
              <div>
                <ul className="list-unstyled">
                  { checkPermission('assembly_lease', 'assembly_lease_details','R') ?
                    <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-details" + "/" + params.slug} title=" Assembly Lease Details" />:null
                  }
                  { assemblyLeaseDetails?.assembly_swap?.value == 0 ? null :
                      checkPermission('assembly_lease', 'utilization','R') ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-utilization" + "/" + params.slug} title="Utilization" />: null
                  }
                  {checkPermission('assembly_lease', 'invoice','R') ?
                    <li onClick={() => setTimeout(() => { window.location.reload()}, 100)}>
                      <Link activeClassName="active" to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-invoices" + "/" + params.slug}>
                        <span>Invoices</span>
                      </Link>
                    </li>:null
                  }
                  {checkPermission('assembly_lease', 'cashflow','R') ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/assembly-cashflow" + "/" + params.slug} title="Cashflow" />:null}
                </ul>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <div style={this.props.style}>
            <div className="mobile-bread-crumb hidden-lg ">
              <Link className="bread-crumb" id="ham" onClick={() => this.ContractSideBarToggle()}>
                <span></span>
                <span className="cont-breadcrumb-bar"></span>
                <span></span>
              </Link>
            </div>
            <div style={this.props.style} className={this.state.contMblSidebar ? "mr-calc-sidebar contract-side-bar sidebar-active " : "mr-calc-sidebar contract-side-bar"}>
              <div className="hidden-lg">
                <Link className="mbl-cross" onClick={this.closeMblSidebar}>
                  <img src={imgStoragePath + "cross_w_icn.png"} alt="img" />
                </Link>
              </div>
              <div className="mr-calc-heading">
                <Link className="ct-back-icon" to={"/" + params.type + "/" + params.aircraft_slug + "/contracts"} onClick={this.closeMblSidebar}>
                  <img className="back-icon" src={imgStoragePath + "contract-back.png"} alt="img" />
                </Link>
              </div>
              <div>
                <ul className="list-unstyled">
                  {checkPermission('contracts','statistics','R') && basicInfo && basicInfo.lease_status && basicInfo.lease_status.value !== 0 ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/dashboard"} title="Statistics" />:null}
                  {getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id !== 3 ?<ContractSideLink classProp={this.checkForActiveUrl() ? 'active' : ''} to={"/" + params.type + "/" + params.aircraft_slug + "/contract/list/" + params.contract_slug} title="Lease Details" />:null}
                  {checkPermission('contracts','lease_rental_rate','R') ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/lease-rental-condition"} title="Lease Rental Rates" />:null}
                  {checkPermission('contracts','reserve_rates','R') ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/mr-rate"} title="Reserve Rates" />:null}
                  {basicInfo && basicInfo.lease_status && basicInfo.lease_status.value !== 0 &&
                    <Fragment>
                      { checkPermission('contracts','utilization','R') ? <ContractSideLink classProp={this.props.location.pathname.includes('utilization') ? 'active' : ''} to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/utilization"} title="Utilization" />:null}
                      {checkPermission('contracts','invoices','R') ? <ContractSideLink classProp={this.props.location.pathname.includes('invoice') ? 'active' : ''} to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/invoice"} title="Invoices" />:null}
                      {checkPermission('contracts','cashflow','R') ? <ContractSideLink to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/cashflow"} title="Cashflow" />:null}
                      {checkPermission('contracts','mr_accruals','R') &&  (getLocalStorageInfo()?.defaultLessor?.id == 13) ? <ContractSideLink classProp={this.props.location.pathname.includes('mr-accrual') ? 'active' : ''} to={"/" + params.type + "/" + params.aircraft_slug + "/contract/" + params.contract_slug + "/mr-accrual"} title="Fund Accrual" /> : null}
                    </Fragment>
                  }
                </ul>
              </div>
            </div>
          </div>
        )
      }
    }
  }
}
const mapStateToProps = state => ({
  basicInfo: state.ContractListReducer.basicMetaInfo,
  assetViewId: state.userAssetReducer.assetViewId,
  closeMaintenance: state.sharedReducers.closeMaintenance,
  assemblyLeaseDetails: state.userAssetReducer.assemblyLeaseDetails,

});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetView: (id, application) => dispatch(assetViewIdAc(id, ownProps.params)),
    fetchAssemblyLeaseDetails: (id, application) => dispatch(getAssemblyLeaseDetailsAc(ownProps)),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: { type: type, position: position }
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractSideBar))
