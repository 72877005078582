import React from 'react';
import { Grid } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AvgUtilsCard = ({avgUtilization}) => {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth <= 767.98 ? 1:avgUtilization.length <=2 ? avgUtilization.length:4,
    slidesToScroll: 1,
  };
  return(
    <div className="avergare-utils-section">
      <h2>Average Monthly Utilization</h2>
      <span>As of : {avgUtilization[0].min_period}-{avgUtilization[0].max_period}</span>
      <Slider {...settings} className="airframe-cards" trackClassName="slick-track" thumbClassName="slick-track"
      >
      { avgUtilization.map((item) =>
        <div className="avergare-utils-card">
          <div className="text-center">
            <h3>{item.name}</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p>Avg. Monthly FH</p>
              <h5>{item.hours}</h5>
            </Grid>
            <Grid item xs={12} md={6}>
              <p>Avg. Monthly FC</p>
              <h5>{item.cycles}</h5>
            </Grid>
          </Grid>
        </div>
      )}
      </Slider>
    </div>
  )
}
export default AvgUtilsCard;
