import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { SnackbarProvider } from 'notistack';
import { PrySidebarExp, TopMainHeader, MobileHeader, MaintenanceWarning } from './shared';
import { SIDEBAR_TOGGLE, FIXED_POSITIONS_VALUE, NOTIFICATION_FLAG, NOTIFICATIONS_COUNT } from './shared/actions';
import { imgStoragePath } from './constants';
import ToastNotify from './shared/containers/ToastNotify';
import SnackbarCloseButton from './shared_elements/components/SnackbarCloseButton'
import './shared/assets/styles/sidebar.scss';
import './assets/styles/font.scss';
import './assets/styles/application.scss';
import './assets/styles/lease_details_v2.scss';
import './assets/styles/fleetinvoice.scss';
import './assets/styles/drawer_v2.scss';
import './phase2/assets/styles/_common.scss'
import { browserHistory } from 'react-router'
import { setCookie, getCookie, toastFlashMessage, getLocalStorageInfo, setMaintenanceCookie, getGlobalCookie } from './utils';
import closeIcon from './User/assets/img/black_close.png'
import ErrorPage from './shared/components/ErrorPage'
import { globalPostService } from './globalServices'
import { trackLogOut } from './utils/mixpanel'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarStatus: false,
      noticeOpen: false,
      errorPage: false
    }
  }

  componentDidCatch(error, errorStack) {
    const errorInfo = new FormData()
    errorInfo.append('title', "Sparta.aero Crashed : " + error.message)
    errorInfo.append('description', error.stack + errorStack.componentStack)
    errorInfo.append('severity', 1)
    errorInfo.append('current_page_url', window.location.href)
    errorInfo.append('classification', 2)
    errorInfo.append('is_error_log', true)
    // globalPostService('console/report-bug/?is_error_log=true', errorInfo)
    // .then(response => {
    //   this.setState({errorPage : true})
    // })
  }

  componentDidMount() {
    let cookieVal = getCookie();
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0;
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0;
    if (cookieVal) {
      this.props.fixedPositionFn('closeMaintenance', false);
      this.props.fixedPositionFn('maintenanceBarHeight', 0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
    } else {
      this.props.fixedPositionFn('closeMaintenance', true);
      this.props.fixedPositionFn('maintenanceBarHeight', maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop', maintenanceHeight + headerHeight);
    }
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
  }

  closeNotifications = () => {
    this.setState(prevState => ({
      ...prevState,
      noticeOpen: false
    }));
  }

  sideBarToggle = () => {
    this.setState(prevState => ({
      ...prevState,
      sidebarStatus: !this.state.sidebarStatus
    }));
  }
  sideBarToggleMbl = () => {
    this.setState(prevState => ({
      ...prevState,
      sidebarStatus: !this.state.sidebarStatus
    }));
  }

  closeMaintenance = () => {
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0;
    this.props.fixedPositionFn('closeMaintenance', false);
    this.props.fixedPositionFn('maintenanceBarHeight', 0);
    this.props.fixedPositionFn('subHeaderTop', headerHeight);
    this.props.clearHeaderbar();
    setCookie();
  }
  render() {
    const { children, sidebarFlag, closeMaintenance, maintenanceBarHeight, notifications, notificationOpen } = this.props;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    if (userDetail != null && userDetail.defaultLessor != null) {
      if (userDetail.defaultLessor.lessor_type == null) {
        trackLogOut(getLocalStorageInfo())
        localStorage.clear();
        sessionStorage.removeItem('initial_pop');
        browserHistory.push('/login');
        this.props.toggleNotification(false);
        return null;
      }
    }
    const userInfo = getLocalStorageInfo()
    let checkAsset = false
    if (userInfo && userInfo.user && userInfo.user.permission && !this.props.location.pathname.includes('login') && !this.props.location.pathname.includes('select-lessor')) {
      Object.keys(userInfo.user.permission).map(key => {
        if (userInfo.user.permission[key].is_saas) {
          checkAsset = true
        }
        return key
      })
    }
    return (
      <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} maxSnack={3}>
        <div className="container-fluid" style={{ background: '#fafafa' }}>
          {
            // closeMaintenance && !userDetail ?
            //  <MaintenanceWarning maintenaceMsg={this.closeMaintenance  }/>
            //  : false
          }
          {
            // closeMaintenance && userInfo && userInfo.user && userInfo.user.permission && checkAsset && !this.props.location.pathname.includes('login') && !this.props.location.pathname.includes('select-lessor') ?
            // <div className="maintenance-bar" id="maintenance">
            //    <p>New features are available in SPARTA click <Link style={{color: '#1579f1'}} onClick={() => {browserHistory.push("/notifications");this.closeMaintenance();}}>here</Link> to know more
            //    <span onClick={() => this.closeMaintenance()}><img src={closeIcon} alt="img"/></span>
            //    </p>
            //  </div>
            // :null
          }
          {
            this.state.errorPage ?
              <ErrorPage />
              : <React.Fragment>
                {userDetail && userDetail.defaultLessor && userDetail.defaultLessor != '' && this.props.location.pathname != '/pricing' ? <TopMainHeader noticeOpen={this.state.noticeOpen} closeNotification={() => this.closeNotifications()} style={{ top: maintenanceBarHeight }} /> : null}
                {
                  userDetail && userDetail.defaultLessor && userDetail.defaultLessor != '' && this.props.location.pathname != '/pricing' ? <div style={{ height: '45px', width: '100%', marginTop: 0, height: userDetail.defaultLessor.on_trail ? '45px' : '45px' }}></div> : null
                }
                {userDetail && userDetail.defaultLessor && userDetail.defaultLessor != '' && this.props.location.pathname != '/pricing' ? <MobileHeader sidebarStatus={sidebarFlag} mobileSideBarFn={() => this.props.sidebarToggleFn(!sidebarFlag)} /> : null}
                {this.props.location.pathname != '/pricing' && userDetail ?
                  <div className={"sideBar-block flex-centered"} style={closeMaintenance && !userDetail ? { paddingTop: '30px', marginTop: maintenanceBarHeight } : { marginTop: maintenanceBarHeight }}>
                    {userDetail && userDetail.defaultLessor && userDetail.defaultLessor != '' ? <PrySidebarExp topNotice={!checkAsset} sideBarToggle={() => this.props.sidebarToggleFn(!sidebarFlag)} sideBarStatus={sidebarFlag} pathName={this.props.location.pathname} /> : null}
                  </div>
                  : null}
                {children}
                <ToastNotify />
              </React.Fragment>
          }
          <Modal isOpen={this.state.noticeOpen} toggle={() => this.setState({ noticeOpen: false })} >
            <ModalHeader toggle={() => this.setState({ noticeOpen: false })}>Information
            </ModalHeader>
            <ModalBody>
              <div className="asset-listing-wrap utilization-history" style={{ marginLeft: '0px' }} >
                Dear User,
                <br /><br />
                SPARTA will be under routine maintenance and feature upgrade release from Saturday 19 Feb 2022 2330 Hrs UTC to Sunday 20 Feb 2022 2330 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
                <br /><br />
                If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
                <br /><br />
                Regards,
                <br />
                The SPARTA Team
              </div>
            </ModalBody>
          </Modal>
        </div>
      </SnackbarProvider>
    )
  }
}
const mapStateToProps = state => ({
  sidebarFlag: state.sharedReducers.sidebarFlag,
  closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
  notifications: state.sharedReducers.notifications,
  notificationOpen: state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sidebarToggleFn: (statusFlag) => dispatch({
      type: SIDEBAR_TOGGLE,
      payload: statusFlag
    }),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: { type: type, position: position }
    }),
    toggleNotification: (flag) => dispatch({
      type: NOTIFICATION_FLAG,
      payload: flag
    }),
    clearHeaderbar: () => dispatch({
      type: NOTIFICATIONS_COUNT,
      payload: {
        overdue: 1,
        card_count: 0,
        notification_count: 0
      }
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
