import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { monthsObj } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
require('highcharts/modules/exporting')(Highcharts);

export default class AvgAnnualUtilsGraph extends Component{
  constructor(props){
    super(props);
  }
  
  render(){
    var colors = [ '#84b2e8', '#ee7c26','#E6B333', '#d7d7d7', '#999966']
    const { hour_plots, cycle_plots, ctrShortInfo } = this.props;
    let categories = [], hours = [], cycles = [];
    Object.keys(hour_plots).map((year,index) => {
      categories.push(year);
      hours.push(hour_plots[year]);
      cycles.push(cycle_plots[year]);
    });
    const options = {
      chart: {
        type: 'line',
        marginTop: 60,
        events: {
          load: function() {
            if(ctrShortInfo && ctrShortInfo.lessor_name && ctrShortInfo.lessor_name.name){
              var label = this.renderer.label((ctrShortInfo.msn ? "MSN: " + ctrShortInfo.msn +" | " + ctrShortInfo.aircraft_type.name : ctrShortInfo.esn ? "ESN: " + ctrShortInfo.esn : '') + "<br/>Lessor: " +  ctrShortInfo.lessor_name.name )
            }else{
              var label = this.renderer.label((ctrShortInfo.msn ? "MSN: " + ctrShortInfo.msn +" | " + ctrShortInfo.aircraft_type.name : ctrShortInfo.esn ? "ESN: " + ctrShortInfo.esn : '') + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
            }
              label.css({
                width: '400px',
                fontSize: '9px'
              })
              .attr({
                'stroke': 'silver',
                'stroke-width': 1,
                'r': 2,
                'padding': 5
              })
              .add();

            label.align(Highcharts.extend(label.getBBox(), {
              align: 'left',
              x: 0, // offset
              verticalAlign: 'bottom',
              y: -350 // offset
            }), null, 'spacingBox');

          }
        }
      },
      colors:colors,
      credits: {
        enabled: false,
        text: ctrShortInfo.msn ? "MSN: " + ctrShortInfo.msn +" | " + ctrShortInfo.aircraft_type.name : ctrShortInfo.esn ? "ESN: " + ctrShortInfo.esn : '',
        style:{
          fontSize: 12
        },
        position: {
          align: 'left',
          x:20,
          y:-380
        }
      },
      title: {
        text: ' '
      },
      xAxis: {
        categories: categories,
        title: {
          text: 'Years'
      },
      },
      yAxis: {
        title: {
            text: 'Utilization (FH & FC)'
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      series:[
        {
          name: 'Hours',
          data: hours
        },
        {
          name: 'Cycles',
          data: cycles
        },
      ],
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    }
    return(
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    )
  }
}
