import React, { Component, Fragment } from 'react';
import { Row, Table, UncontrolledTooltip ,Col} from 'reactstrap';
import GenTabField from './GenTabField';
import OverViewUtilization from './OverViewUtilization';

import { Link, withRouter } from 'react-router'
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import { globalPostService } from '../../../../globalServices'
class GeneralTab extends Component{
  constructor(props){
    super(props)
    this.state = {
      imagePreview: false,
      overview: {}
    }
  }

  componentDidMount(){
    let data = Object.assign({}, this.props.overview);
    let maintanenceList = [];
    let maintanenceData =  data.maintenance_event_program_group;
    if(maintanenceData){
      Object.keys(maintanenceData).map((item,index) => {
        maintanenceList.push({label: maintanenceData[item].replace(/,/g, '/') , value: item})
      });
    }
    delete data['manufacturer'];
    delete data['maintenance_event_program_group'];
    delete data['lessor'];
    data = {
      ...data,
      manufacturer: this.props.overview.manufacturer && this.props.overview.manufacturer.id ? this.props.overview.manufacturer.id  : null,
      manufacturer_label: this.props.overview.manufacturer && this.props.overview.manufacturer.name ? this.props.overview.manufacturer.name  : null,
      maintenance_event_program_group: maintanenceList && maintanenceList[0] && maintanenceList[0].value ? maintanenceList[0].value : null,
      weight_unit : this.props.overview.manufacturer && this.props.overview.weight_unit.value ? this.props.overview.weight_unit.value  : null,
      weight_unit_name : this.props.overview.manufacturer && this.props.overview.weight_unit.label ? this.props.overview.weight_unit.label  : null,
      aircraft_operation : this.props.overview.aircraft_operation && this.props.overview.aircraft_operation.value ? this.props.overview.aircraft_operation.value  : null,
      aircraft_type:  this.props.overview.aircraft_type && this.props.overview.aircraft_type.id ? this.props.overview.aircraft_type.id  : null,
      aircraft_type_label:  this.props.overview.aircraft_type && this.props.overview.aircraft_type.name ? this.props.overview.aircraft_type.name  : null,
      credit_classification: this.props.overview.credit_classification && this.props.overview.credit_classification.value ? this.props.overview.credit_classification.value  : null,
      creditTypeName: this.props.overview.credit_classification && this.props.overview.credit_classification.label ? this.props.overview.credit_classification.label  : null,
      lessee: this.props.overview.lessee && this.props.overview.lessee.id ? this.props.overview.lessee.id   : null,
      lesseeName: this.props.overview.lessee && this.props.overview.lessee.value ? this.props.overview.lessee.value  : null,
      operator: this.props.overview.operator && this.props.overview.operator.id ? this.props.overview.operator.id   : null,
      operatorName: this.props.overview.operator && this.props.overview.operator.value ? this.props.overview.operator.value  : null,
      ownership_type: this.props.overview.ownership_type && this.props.overview.ownership_type.value ? this.props.overview.ownership_type.value  : 1,
      portfolio: this.props.overview.portfolio ? this.props.overview.portfolio : {}
    }
    this.setState({
      overview: data
    });
  }

  uploadCoverImage = (image) =>{
		let formData = new FormData()
		formData.append('file', image)
    this.props.toggleLoader()
		globalPostService(`console/upload-asset-cover/aircraft/${this.props.params.aircraft_slug}/`, formData)
		.then(response => {
			if(response.data.statusCode == 200){
        let data = Object.assign({}, this.props.overview);
        let maintanenceList = [];
        let maintanenceData =  data.maintenance_event_program_group;
        if(maintanenceData){
          Object.keys(maintanenceData).map((item,index) => {
            maintanenceList.push({label: maintanenceData[item].replace(/,/g, '/') , value: item})
          });
        }
        delete data['manufacturer'];
        delete data['maintenance_event_program_group'];
        delete data['lessor'];
        delete data['lessor_name']
        delete data['owner']
        data = {
          ...data,
          manufacturer: this.props.overview.manufacturer && this.props.overview.manufacturer.id ? this.props.overview.manufacturer.id  : null,
          maintenance_event_program_group: maintanenceList && maintanenceList[0] && maintanenceList[0].value ? maintanenceList[0].value : null,
          weight_unit : this.props.overview.manufacturer && this.props.overview.weight_unit.value ? this.props.overview.weight_unit.value  : null,
          weight_unit_name : this.props.overview.manufacturer && this.props.overview.weight_unit.label ? this.props.overview.weight_unit.label  : null,
          aircraft_operation : this.props.overview.aircraft_operation && this.props.overview.aircraft_operation.value ? this.props.overview.aircraft_operation.value  : null,
          aircraft_type:  this.props.overview.aircraft_type && this.props.overview.aircraft_type.id ? this.props.overview.aircraft_type.id  : null,
          credit_classification: this.props.overview.credit_classification && this.props.overview.credit_classification.value ? this.props.overview.credit_classification.value  : null,
          creditTypeName: this.props.overview.credit_classification && this.props.overview.credit_classification.label ? this.props.overview.credit_classification.label  : null,
          lessee: this.props.overview.lessee && this.props.overview.lessee.id ? this.props.overview.lessee.id   : null,
          lesseeName: this.props.overview.lessee && this.props.overview.lessee.value ? this.props.overview.lessee.value  : null,
          operator: this.props.overview.operator && this.props.overview.operator.id ? this.props.overview.operator.id   : null,
          operatorName: this.props.overview.operator && this.props.overview.operator.value ? this.props.overview.operator.value  : null,
          ownership_type: this.props.overview.ownership_type && this.props.overview.ownership_type.value ? this.props.overview.ownership_type.value  : 1,
          portfolio: this.props.overview.portfolio ? this.props.overview.portfolio : {},
          time_unit: this.props.overview.time_unit ? this.props.overview.time_unit : 1,
          off_lease_status: this.props.overview.off_lease_status && this.props.overview.off_lease_status ? this.props.overview.off_lease_status.value : null,
          lessor_name_id: this.props.overview.lessor_name && this.props.overview.lessor_name.id ? this.props.overview.lessor_name.id  : getLocalStorageInfo().defaultLessor.id,
          lessor_level: this.props.overview.lessor_name && this.props.overview.lessor_name.lessor_level ?this.props.overview.lessor_name.lessor_level : 1 ,
          owner_id:this.props.overview.owner && this.props.overview.owner.id ? this.props.overview.owner.id : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.id : '',
          owner_level: this.props.overview.owner && this.props.overview.owner.lessor_level? this.props.overview.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
          delivered_basic_empty_weight_kgs: this.props.overview.delivered_basic_empty_weight_kgs ?  this.props.overview.delivered_basic_empty_weight_kgs : this.props.overview.delivered_basic_empty_weight ? parseInt(this.props.overview.delivered_basic_empty_weight/2.205) : null,
          delivered_max_zero_fuel_weight_kgs: this.props.overview.delivered_max_zero_fuel_weight_kgs ?  this.props.overview.delivered_max_zero_fuel_weight_kgs : this.props.overview.delivered_max_zero_fuel_weight ? parseInt(this.props.overview.delivered_max_zero_fuel_weight/2.205) : null,
          delivered_max_take_off_weight_kgs: this.props.overview.delivered_max_take_off_weight_kgs ?  this.props.overview.delivered_max_take_off_weight_kgs : this.props.overview.delivered_max_take_off_weight ? parseInt(this.props.overview.delivered_max_take_off_weight/2.205) : null,
          delivered_max_landing_weight_kgs: this.props.overview.delivered_max_landing_weight_kgs ?  this.props.overview.delivered_max_landing_weight_kgs : this.props.overview.delivered_max_landing_weight ? parseInt(this.props.overview.delivered_max_landing_weight/2.205) : null,
          delivered_max_taxi_weight_kgs: this.props.overview.delivered_max_taxi_weight_kgs ?  this.props.overview.delivered_max_taxi_weight_kgs : this.props.overview.delivered_max_taxi_weight ? parseInt(this.props.overview.delivered_max_taxi_weight/2.205) : null,
          purchase_basic_empty_weight_kgs: this.props.overview.purchase_basic_empty_weight_kgs ?  this.props.overview.purchase_basic_empty_weight_kgs : this.props.overview.purchase_basic_empty_weight ? parseInt(this.props.overview.purchase_basic_empty_weight/2.205) : null,
          purchase_max_zero_fuel_weight_kgs: this.props.overview.purchase_max_zero_fuel_weight_kgs ?  this.props.overview.purchase_max_zero_fuel_weight_kgs : this.props.overview.purchase_max_zero_fuel_weight ? parseInt(this.props.overview.purchase_max_zero_fuel_weight/2.205) : null,
          purchase_max_take_off_weight_kgs: this.props.overview.purchase_max_take_off_weight_kgs ?  this.props.overview.purchase_max_take_off_weight_kgs : this.props.overview.purchase_max_take_off_weight ? parseInt(this.props.overview.purchase_max_take_off_weight/2.205) : null,
          purchase_max_landing_weight_kgs: this.props.overview.purchase_max_landing_weight_kgs ?  this.props.overview.purchase_max_landing_weight_kgs : this.props.overview.purchase_max_landing_weight ? parseInt(this.props.overview.purchase_max_landing_weight/2.205) : null,
          purchase_max_taxi_weight_kgs: this.props.overview.purchase_max_taxi_weight_kgs ?  this.props.overview.purchase_max_taxi_weight_kgs : this.props.overview.purchase_max_taxi_weight ? parseInt(this.props.overview.purchase_max_taxi_weight/2.205) : null,
          operating_basic_empty_weight_kgs: this.props.overview.operating_basic_empty_weight_kgs ?  this.props.overview.operating_basic_empty_weight_kgs : this.props.overview.operating_basic_empty_weight ? parseInt(this.props.overview.operating_basic_empty_weight/2.205) : null,
          operating_max_zero_fuel_weight_kgs: this.props.overview.operating_max_zero_fuel_weight_kgs ?  this.props.overview.operating_max_zero_fuel_weight_kgs : this.props.overview.operating_max_zero_fuel_weight ? parseInt(this.props.overview.operating_max_zero_fuel_weight/2.205) : null,
          operating_max_take_off_weight_kgs: this.props.overview.operating_max_take_off_weight_kgs ?  this.props.overview.operating_max_take_off_weight_kgs : this.props.overview.operating_max_take_off_weight ? parseInt(this.props.overview.operating_max_take_off_weight/2.205) : null,
          operating_max_landing_weight_kgs: this.props.overview.operating_max_landing_weight_kgs ?  this.props.overview.operating_max_landing_weight_kgs : this.props.overview.operating_max_landing_weight ? parseInt(this.props.overview.operating_max_landing_weight/2.205) : null,
          operating_max_taxi_weight_kgs: this.props.overview.operating_max_taxi_weight_kgs ?  this.props.overview.operating_max_taxi_weight_kgs : this.props.overview.operating_max_taxi_weight ? parseInt(this.props.overview.operating_max_taxi_weight/2.205) : null,

        }
				setTimeout(() => {
					this.props.editOverview({...data, cover_photo: response.data.data.path});
				}, 500)
			}
		})
	}

 render(){
  const { techConstants, sWeightUnit, creditClassification, aircraftOperation} = this.props;
  const { overview } = this.state;
  let maintanenceList = {};
  let maintanenceData =  this.props.overview.maintenance_event_program_group;
  if(maintanenceData){
    Object.keys(maintanenceData).map((item,index) => {
      maintanenceList = {label: maintanenceData[item], value: this.props.overview.maintenance_event_program_group[item]}
    });
  }
  return(
  <div >
    <div className="horizontal-card card-block">
    {
      getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['overview'].indexOf('U') != -1 ?
      <img src={imgStoragePath + "icon_editblock.png"} alt="img" onClick = {() => this.props.viewOverview('overviewStatus')}  className="blue-img" width="16" />:null
    }

    <Row>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Serial Number (MSN)" text={overview.msn}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Aircraft Type" text={overview.aircraft_type ? overview.aircraft_type_label :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Aircraft Model" text={overview.model ? overview.model :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Manufacturer" text={overview.manufacturer  ? overview.manufacturer_label : '--' }/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Maintenance Program Group" text={maintanenceList.value ? maintanenceList.value :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Date of Manufacture" text={overview.date_of_manufacture ? moment(overview.date_of_manufacture).format(displayDateFormat):'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Date of Purchase" text={overview.purchase_date ? moment(overview.purchase_date).format(displayDateFormat):'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Registration Number" text={overview.current_registration_number? overview.current_registration_number : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Aircraft Operation" text={overview.weight_unit && aircraftOperation && aircraftOperation.filter(unit => unit.value == overview.aircraft_operation).length ? aircraftOperation.filter(unit => unit.value == overview.aircraft_operation)[0].label : ''}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Line Number" text={overview.line_number ? overview.line_number : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Variable Number" text={overview.variable_number ? overview.variable_number : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="AOC Regulation" text={overview.aoc_regulation ? overview.aoc_regulation : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Credit Classification" text={overview.credit_classification && creditClassification && creditClassification.filter(unit => unit.value == overview.credit_classification).length ? creditClassification.filter(unit => unit.value == overview.credit_classification)[0].label : ''}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Country" text={overview.country ? overview.country : '--'}/>
          {
            overview.status !== 4?
            <GenTabField grid="3" classText = "horizontal-inner-block" label="Lessor Name" text={overview.lessor_name && overview.lessor_name.name ? overview.lessor_name.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
            :null
          }
        {
          overview.status !== 4 ?
          <GenTabField grid="3" classText = "horizontal-inner-block" label="Owner" text={overview.owner && overview.owner.name ? overview.owner.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
          :null
        }
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Lease Status" text={overview.status && techConstants && techConstants.length ? getLocalStorageInfo().defaultLessor.id === 348 ? overview.status === 1 ? 'Operational' : 'AOG' : techConstants.filter(item => item.value==overview.status)[0].label: '--'}/>
         {
           overview.status == 2 || overview.status && overview.status.value == 2 ?
          <GenTabField grid="3" classText = "horizontal-inner-block" label="Off Lease Status" text={overview.off_lease_status && overview.off_lease_status.label ? overview.off_lease_status.label   : '--'}/>
          : null
         }
        <GenTabField grid="3" classText = "horizontal-inner-block" label="First Delivery Date" text={overview.first_delivery_date ? moment(overview.first_delivery_date).format(displayDateFormat) : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="First Flight Date" text={overview.first_flight_date ? moment(overview.first_flight_date).format(displayDateFormat) : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Delivery Date to Current Operator" text={overview.delivery_date_to_current_operator ? moment(overview.delivery_date_to_current_operator).format(displayDateFormat) : '--'}/>
        <div className="horizontal-inner-block col-md-3">
          <h6>Cover Photo
            {
              getLocalStorageInfo().user.permission['technical_specs'] && getLocalStorageInfo().user.permission['technical_specs']['overview'].indexOf('U') != -1 ?
                <img style={{cursor: 'pointer'}} src={imgStoragePath + "icon_editblock.png"} alt="img" onClick={() => document.querySelector('#cover_image').click()} width="10" />
              :null
            }
            </h6>
          <span>
            {
              overview.cover_photo ? <Link style={{color: '#2f8cfd'}} onClick={() => this.setState({imagePreview: true})} target="_blank">View</Link> : "--"
            }
          </span>
        </div>
        <input
          id="cover_image"
          type="file"
          style={{display: 'none'}}
          accept="image/*"
          onChange={(evt) => this.uploadCoverImage(evt.target.files[0])}
        />
        </Row>
    </div>
    <OverViewUtilization utilization={overview} importAllowed={false} assembly="aircraft" slug={this.props.params.aircraft_slug} refresh={() => this.componentDidMount()} />
    <div className="horizontal-card card-block" style={{marginTop:"10px"}}>
      <Row>

        <GenTabField
          grid="3"
          classText = "horizontal-inner-block "
          label={
            <h6>
              ETOPS (Extended Range Twin Engine Operations)
              <i class="fa fa-info-circle" aria-hidden="true" id="airframe-field2" style={{paddingLeft: '3px'}}></i>
              <UncontrolledTooltip placement="right" target="airframe-field2">
                Specify "Yes" or "No"
              </UncontrolledTooltip>
            </h6>
          }
          text={overview.etops? overview.etops : '--'}
        />
         <GenTabField
          grid="3"
          classText = "horizontal-inner-block "
          label={
            <h6>
              ETOPS Aircraft Model Certification
              <i class="fa fa-info-circle" aria-hidden="true" id="airframe-field3" style={{paddingLeft: '3px'}}></i>
              <UncontrolledTooltip placement="right" target="airframe-field3">
                Specify the Certification Standard For eg 60 Min or 120 Min or 180 min
              </UncontrolledTooltip>
            </h6>
          }
          text={overview.etops_aircraft_model_certification ? overview.etops_aircraft_model_certification : '--'}
        />
        <GenTabField
          grid="3"
          classText = "horizontal-inner-block "
          label={
            <h6>
              ETOPS Current Config Capacity
              <i class="fa fa-info-circle" aria-hidden="true" id="airframe-field4" style={{paddingLeft: '3px'}}></i>
              <UncontrolledTooltip placement="right" target="airframe-field4">
                Specify the Current Operation Standard For eg 60 Min or 120 Min or 180 min<br/>
                Note: Certification and current configuration can be different.
              </UncontrolledTooltip>
            </h6>
          }
          text={overview.etops_current_config_capacity_cmp_revision ? overview.etops_current_config_capacity_cmp_revision : '--'}
        />
        <GenTabField grid="3" classText = "horizontal-inner-block" label="ETOPS Operational Standard" text={overview.etops_operational_standard ? overview.etops_operational_standard : '--'}/>

        <GenTabField grid="3" classText = "horizontal-inner-block" label="Fuel Capacity (Ltr)" text={overview.fuel_capacity  ? overview.fuel_capacity : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Fuel Capacity (US Gal)" text={overview.fuel_capacity_gal  ? overview.fuel_capacity_gal : overview.fuel_capacity ? parseFloat(overview.fuel_capacity * 0.2642).toFixed(3) : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block margin-bottom-0" label="Fuel Tank Inerting System" text={overview.ftis  ? overview.ftis : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block margin-bottom-0" label="Fuel Density" text={overview.fuel_capacity_density  ? overview.fuel_capacity_density : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Additional Center Tanks" text={overview.additional_center_tanks ? overview.additional_center_tanks: '--'}/>
        <GenTabField
          grid="3"
          classText = "horizontal-inner-block"
          label="ACT Provisions"
          label={
            <h6>
              ACT Provisions
              <i class="fa fa-info-circle" aria-hidden="true" id="airframe-field5" style={{paddingLeft: '3px'}}></i>
              <UncontrolledTooltip placement="right" target="airframe-field5">
                Is asset equipped with ACT Provisions - Yes / No
              </UncontrolledTooltip>
            </h6>
          }
          text={overview.act_provisions ? overview.act_provisions: '--'}
        />
        <GenTabField
          grid="3"
          classText = "horizontal-inner-block "
          label={
            <h6>
              RVSM (Reduced Vertical Separation Minimum)
              <i class="fa fa-info-circle" aria-hidden="true" id="airframe-field1" style={{paddingLeft: '3px'}}></i>
              <UncontrolledTooltip placement="right" target="airframe-field1">
                Specify "Complied" or "Not Complied"
              </UncontrolledTooltip>
            </h6>
          }
          text={overview.rvsm ? overview.rvsm : '--'}
        />
        <GenTabField grid="3" classText = "horizontal-inner-block " label="Landing Capability" text={overview.landing_capability ? overview.landing_capability :'--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Cockpit Door Surveillance System" text={overview.cdss ? overview.cdss: '--'}/>
      </Row>

    </div>

    <div className="table-block overview-table horizontal-card card-block">
      <Row>
        {/* <GenTabField grid="4" classText = "horizontal-inner-block" label="Weight Unit" text={overview.weight_unit_name ? overview.weight_unit_name:'' }/> */}
        <Col md="4" className="horizontal-inner-block">
            <h6>Weight Variant</h6>
            <span>{overview.weight_variant ? overview.weight_variant:'--' }</span>
        </Col>
        <Col md="2">
        </Col>
        <Col md="4" className="horizontal-inner-block" >
            <h6>Date Last Weighing</h6>
            <span style={{display: "flex", position:"relative" }} >{overview.date_last_weighing ? moment(overview.date_last_weighing).format(displayDateFormat):'--' }</span>
        </Col>
        {/* <GenTabField grid="3" classText = "horizontal-inner-block" label="Weight Variant" text={overview.weight_variant  ? overview.weight_variant : '--'}/>
        <GenTabField grid="3" classText = "horizontal-inner-block" label="Date Last Weighing" text={overview.date_last_weighing  ? overview.date_last_weighing : '--'} style={{float: "right"}}  /> */}

      </Row>
      <Table >
          <thead>
            <tr>
              <th style={{width: '16%'}} colSpan="1"></th>
              <th style={{width: '28%'}}>Purchased
                <table>
                  <thead>
                    <tr>
                      <th style={{width: '50%'}}>Lbs</th>
                      <th>Kgs</th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th style={{width: '28%'}}>Delivered
              <table>
                <thead>
                  <tr>
                    <th style={{width: '50%'}}>Lbs</th>
                    <th>Kgs</th>
                  </tr>
                </thead>
              </table>
              </th>
              <th style={{width: '28%'}}>Current
              <table>
                <thead>
                  <tr>
                    <th style={{width: '50%'}}>Lbs</th>
                    <th>Kgs</th>
                  </tr>
                </thead>
              </table>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="1" scope="row"> Basic Empty Weight</th>
              <td style={{paddingTop: '0px'}}>
                <table style={{margin: '0px'}}>
                  <tbody>
                    <tr>
                      <td style={{width: '50%'}}>{overview.purchase_basic_empty_weight ?  overview.purchase_basic_empty_weight : '--'}</td>
                      <td>{overview.purchase_basic_empty_weight_kgs ?  overview.purchase_basic_empty_weight_kgs : overview.purchase_basic_empty_weight ? parseInt(overview.purchase_basic_empty_weight/2.205) : '--'}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.delivered_basic_empty_weight ?  overview.delivered_basic_empty_weight : '--'}</td>
                    <td>{overview.delivered_basic_empty_weight_kgs ?  overview.delivered_basic_empty_weight_kgs : overview.delivered_basic_empty_weight ? parseInt(overview.delivered_basic_empty_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.operating_basic_empty_weight ?  overview.operating_basic_empty_weight : '--'}</td>
                    <td>{overview.operating_basic_empty_weight_kgs ?  overview.operating_basic_empty_weight_kgs : overview.operating_basic_empty_weight ? parseInt(overview.operating_basic_empty_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
            </tr>
            <tr>
              <th colSpan="1" scope="row"> Max Landing Weight	</th>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.purchase_max_landing_weight ?  overview.purchase_max_landing_weight : '--'}</td>
                    <td>{overview.purchase_max_landing_weight_kgs ?  overview.purchase_max_landing_weight_kgs : overview.purchase_max_landing_weight ? parseInt(overview.purchase_max_landing_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.delivered_max_landing_weight ?  overview.delivered_max_landing_weight : '--'}</td>
                    <td>{overview.delivered_max_landing_weight_kgs ?  overview.delivered_max_landing_weight_kgs : overview.delivered_max_landing_weight ? parseInt(overview.delivered_max_landing_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.operating_max_landing_weight ?  overview.operating_max_landing_weight : '--'}</td>
                    <td>{overview.operating_max_landing_weight_kgs ?  overview.operating_max_landing_weight_kgs : overview.operating_max_landing_weight ? parseInt(overview.operating_max_landing_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
            </tr>
            <tr>
              <th colSpan="1" scope="row"> Max Take Off Weight</th>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.purchase_max_take_off_weight ?  overview.purchase_max_take_off_weight : '--'}</td>
                    <td>{overview.purchase_max_take_off_weight_kgs?  overview.purchase_max_take_off_weight_kgs : overview.purchase_max_take_off_weight ? parseInt(overview.purchase_max_take_off_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.delivered_max_take_off_weight ?  overview.delivered_max_take_off_weight : '--'}</td>
                    <td>{overview.delivered_max_take_off_weight_kgs ?  overview.delivered_max_take_off_weight_kgs : overview.delivered_max_take_off_weight ? parseInt(overview.delivered_max_take_off_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.operating_max_take_off_weight ?  overview.operating_max_take_off_weight : '--'}</td>
                    <td>{overview.operating_max_take_off_weight_kgs ?  overview.operating_max_take_off_weight_kgs : overview.operating_max_take_off_weight ? parseInt(overview.operating_max_take_off_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
            </tr>
            <tr>
              <th colSpan="1" scope="row">Max Taxi Weight</th>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.purchase_max_taxi_weight ?  overview.purchase_max_taxi_weight : '--'}</td>
                    <td>{overview.purchase_max_taxi_weight_kgs?  overview.purchase_max_taxi_weight_kgs : overview.purchase_max_taxi_weight ? parseInt(overview.purchase_max_taxi_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.delivered_max_taxi_weight ?  overview.delivered_max_taxi_weight : '--'}</td>
                    <td>{overview.delivered_max_taxi_weight_kgs ?  overview.delivered_max_taxi_weight_kgs : overview.delivered_max_taxi_weight ? parseInt(overview.delivered_max_taxi_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.operating_max_taxi_weight ?  overview.operating_max_taxi_weight : '--'}</td>
                    <td>{overview.operating_max_taxi_weight_kgs ?  overview.operating_max_taxi_weight_kgs : overview.operating_max_taxi_weight ? parseInt(overview.operating_max_taxi_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
            </tr>
            <tr>
              <th colSpan="1" scope="row">Max Zero Fuel Weight</th>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.purchase_max_zero_fuel_weight ?  overview.purchase_max_zero_fuel_weight : '--'}</td>
                    <td>{overview.purchase_max_zero_fuel_weight_kgs?  overview.purchase_max_zero_fuel_weight_kgs : overview.purchase_max_zero_fuel_weight ? parseInt(overview.purchase_max_zero_fuel_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.delivered_max_zero_fuel_weight ?  overview.delivered_max_zero_fuel_weight : '--'}</td>
                    <td>{overview.delivered_max_zero_fuel_weight_kgs ?  overview.delivered_max_zero_fuel_weight_kgs : overview.delivered_max_zero_fuel_weight ? parseInt(overview.delivered_max_zero_fuel_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
              <td style={{paddingTop: '0px'}}>
              <table style={{margin: '0px'}}>
                <tbody>
                  <tr>
                    <td style={{width: '50%'}}>{overview.operating_max_zero_fuel_weight ?  overview.operating_max_zero_fuel_weight : '--'}</td>
                    <td>{overview.operating_max_zero_fuel_weight_kgs ?  overview.operating_max_zero_fuel_weight_kgs : overview.operating_max_zero_fuel_weight ? parseInt(overview.operating_max_zero_fuel_weight/2.205) : '--'}</td>
                  </tr>
                </tbody>
              </table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Viewer
        visible={this.state.imagePreview}
        onClose={() => { this.setState({ imagePreview: false }); } }
        images={[{src: `${overview.cover_photo}`, alt: ''}]}
        rotatable={false}
        scalable={false}
        attribute={false}
        changeable={false}
        noNavbar={true}
        zoomSpeed={0.50}
        />
   </div>
  )
  }
}

export default withRouter(GeneralTab)
