import moment from 'moment';
import React, { Component } from 'react'
import { browserHistory } from 'react-router';
import { displayDateFormat } from '../../../constants';
import config from '../../../config';

export default class ExpirationofCertificates extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    onclick = () => {
        const { item } = this.props;
        let assetTypeNames = {
            1: 'aircraft',
            2: 'engine',
            3: 'apu',
            4: 'lg',
            5: 'propeller',
            6: 'fan',
            7: 'hpc',
            8: 'hpt',
            9: 'lpt'
        }
        item.application == '3' ?
            window.open(`/${assetTypeNames[item.asset_type]}/${item.asset_slug}/contract/${item.lease_slug}/insurance`, '_blank')
            : window.open(`${config.domain.subDomian}technical-specification/${assetTypeNames[item.asset_type]}/${item.asset_slug}`, '_blank')
    }
    render() {
        const { item, index } = this.props;
        return (
            <>
                <tr className='certificate_row' style={index % 2 == '0' ? { backgroundColor: '#fbfbfb' } : null} onClick={() => this.onclick()}>
                    <td style={{ width: '12%' }}>{item.serial_number ? item.serial_number : '--'}</td>
                    <td>{item.asset_model ? item.asset_model : '--'}</td>
                    <td style={{ color: '#008abc', textDecoration: 'underline' }}> <span style={{ cursor: 'pointer' }}>{item.type ? item.type : '--'}</span></td>
                    <td > {item.expiry_date ? item.overdue ? <b style={{ color: 'red' }} >{moment(item.expiry_date).format(displayDateFormat)} (Overdue)</b> : moment(item.expiry_date).format(displayDateFormat) : '--'} </td>
                    <td>{item.lessor ? item.lessor : '--'}</td>
                    <td>{item.lessee && item.lessee.name ? item.lessee.name : '--'}</td>
                </tr>
                <hr />
            </>
        )
    }
}
