import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControlLabel, Checkbox, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { capitalizeFirstLetter, checkApiStatus, getCurrencyFormat, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditSecurityDepositApi, getSecurityDepositApi } from '../apiServices';
import { errorCode, addSecurityDeposit } from '../';
import { regexConstants } from '../../../../constants/regEx';
class SecurityDepositCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      pageLoader:false,
      mode:'',
      securityDeposit: {},
      error:{}
    }
    this.addEditSecurityDepositApi = addEditSecurityDepositApi.bind(this);
    this.getSecurityDepositApi = getSecurityDepositApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      securityDeposit: {
        ...prevState.securityDeposit,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditsecurityDepositFn = () => {
    const { securityDeposit } = this.state;
    let validationInputs = {
      security_deposit_type:errorCode['security_deposit_type'][fieldValidation({...errorCode['security_deposit_typeObj'], fieldval: securityDeposit.security_deposit_type})],
      security_against:errorCode['security_against'][fieldValidation({...errorCode['security_againstObj'], fieldval: securityDeposit.security_against})],
      amount:errorCode['amount'][fieldValidation({...errorCode['amountObj'], fieldval: securityDeposit.amount})],
    };
    if(securityDeposit.interest_rate > 100){
      validationInputs = {
        ...validationInputs,
        interest_rate:'Interest Rate cannot be more then 100'
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true})
      let payload = Object.assign({}, securityDeposit);
      payload = {
        ...payload,
        security_deposit_type: securityDeposit.security_deposit_type.value,
      }
      this.addEditSecurityDepositApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false})
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', securityDeposit:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }
  render(){
    const { modal, formSubmitLoader, mode, securityDeposit, error, pageLoader } = this.state;
    const { id, m, pltConstants, contractInfo } = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader /> : null }
        { id ?
          <span onClick={() => this.getSecurityDepositApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Button onClick={() => this.setState({modal:true, securityDeposit:addSecurityDeposit, mode:'add'})} variant="contained" color="primary">Add Security Deposit</Button>
          :null
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', securityDeposit:{}, error:{}})}
          >
            <div className="drawer-container" style={{width:'670px'}}>
              <div className="drawer-header">
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Security Deposit`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctsecuritydeposits/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','security_deposit', 'U') && mode === 'view' ? <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Security Deposit Type' value={securityDeposit.security_deposit_type && securityDeposit.security_deposit_type.label ? securityDeposit.security_deposit_type.label:'--'} /> :
                    <Grid item xs={12}>
                      <Autocomplete
                        options = {pltConstants.filter(item => item.type === 'security_deposit_type')}
                        getOptionLabel={option => option.label}
                        id="security_deposit_type"
                        value={securityDeposit.security_deposit_type ? securityDeposit.security_deposit_type:null}
                        onChange={(e, value) => this.onFieldChange(e, 'security_deposit_type', value)}
                        renderInput={params => <TextField required error={error.security_deposit_type ? true:false} helperText={error.security_deposit_type?error.security_deposit_type:''} onFocus={() => this.updateErrorField('security_deposit_type','')} {...params} label="Security Deposit Type" placeholder="Select Security Deposit Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Security Against' value={securityDeposit.security_against ? securityDeposit.security_against:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="security_against"
                        label="Security Against"
                        fullWidth
                        margin="normal"
                        value={securityDeposit.security_against ? securityDeposit.security_against:''}
                        onChange={(e, value) => this.onFieldChange(e, 'security_against', e.target.value)}
                        error={error.security_against ? true:false }
                        helperText={error.security_against ? error.security_against:'' }
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 30}}
                        onFocus={() => this.updateErrorField('security_against','')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Amount (USD)' value={securityDeposit.amount ? <NumericLabel params={getCurrencyFormat()}>{securityDeposit.amount}</NumericLabel>:'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="amount"
                        label="Amount (USD)"
                        fullWidth
                        margin="normal"
                        value={securityDeposit.amount ? securityDeposit.amount:''}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'amount', e.target.value):e.preventDefault()}}
                        error={error.amount ? true:false }
                        helperText={error.amount ? error.amount:'' }
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 10}}
                        onFocus={() => this.updateErrorField('amount','')}
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Is Security Deposit Refundable?' value={securityDeposit.is_refundable ? 'YES':'NO'} /> :
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={securityDeposit.is_refundable} onChange={(e) => this.onFieldChange(e, 'is_refundable', e.target.checked)} value="Flag this Return Condition" />
                        }
                        label="Is Security Deposit Refundable?"
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Is Interest Refundable?' value={securityDeposit.is_interest_refundable ? 'YES': 'NO'} /> :
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={securityDeposit.is_interest_refundable} onChange={(e) => this.onFieldChange(e, 'is_interest_refundable', e.target.checked)} value="Flag this Return Condition" />
                        }
                        label="Is Interest Refundable?"
                      />
                    </Grid>
                  }

                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Interest Rate' value={securityDeposit.interest_rate ? securityDeposit.interest_rate+'%':'--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="interest_rate"
                        label="Interest Rate(%)"
                        fullWidth
                        margin="normal"
                        value={securityDeposit.interest_rate ? securityDeposit.interest_rate:''}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? this.onFieldChange(e, 'interest_rate', e.target.value):e.preventDefault()}}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={securityDeposit.remarks ? securityDeposit.remarks:'--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        multiline
                        rows="4"
                        margin="normal"
                        value={securityDeposit.remarks}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        error={error.remarks ? true:false }
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditsecurityDepositFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', securityDeposit:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(SecurityDepositCRU));
