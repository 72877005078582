import React from 'react';
import { Grid, TextField, Paper } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
const BankDetail = ({bankDetail, mode, onFieldChange}) => {
  return(
    <Paper className={`invoice-bank-detail ${mode === 'view' ? 'bank-detail-view':'bank-detail-edit'}`}>
      <div>
        <h4>Bank Detail {bankDetail.invoice_type === 2 ? 'For '+bankDetail.currency+ ' Rental Receivable':''}</h4>
        <Grid container spacing={2}>
          { mode === 'view' ?
            <LabelValueCard md={4} label='Name' value={bankDetail.bank_name ? bankDetail.bank_name:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_name"
                label="Name"
                fullWidth
                margin="normal"
                value={bankDetail.bank_name}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_name', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='Account Name' value={bankDetail.bank_account_name ? bankDetail.bank_account_name:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_account_name"
                label="Account Name"
                fullWidth
                margin="normal"
                value={bankDetail.bank_account_name}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_account_name', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='Account Number' value={bankDetail.bank_account_number ? bankDetail.bank_account_number:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_account_number"
                label="Account Number"
                fullWidth
                margin="normal"
                value={bankDetail.bank_account_number}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_account_number', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='IBAN Code' value={bankDetail.bank_iban ? bankDetail.bank_iban:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_iban"
                label="IBAN Code"
                fullWidth
                margin="normal"
                value={bankDetail.bank_iban}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_iban', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='ABA Code' value={bankDetail.aba_number ? bankDetail.aba_number:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="aba_number"
                label="ABA Code"
                fullWidth
                margin="normal"
                value={bankDetail.aba_number}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'aba_number', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='SWIFT Code' value={bankDetail.bank_swift_code ? bankDetail.bank_swift_code:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_swift_code"
                label="SWIFT Code"
                fullWidth
                margin="normal"
                value={bankDetail.bank_swift_code}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_swift_code', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='Sort Code' value={bankDetail.bank_sort_code ? bankDetail.bank_sort_code:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="bank_sort_code"
                label="Sort Code"
                fullWidth
                margin="normal"
                value={bankDetail.bank_sort_code}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'bank_sort_code', e.target.value)}
              />
            </Grid>
          }
          { mode === 'view' ?
            <LabelValueCard md={4} label='Footer Note' value={bankDetail.footer_note ? bankDetail.footer_note:'--'} />
            :
            <Grid item xs={12} md={4}>
              <TextField
                id="footer_note"
                label="Footer Note"
                fullWidth
                margin="normal"
                value={bankDetail.footer_note}
                InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => onFieldChange(e, 'footer_note', e.target.value)}
              />
            </Grid>
          }
        </Grid>
      </div>
    </Paper>
  )
}
export default BankDetail;
