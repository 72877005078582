import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate, CtrTableHd } from '../../Elements';
import { checkString, checkValidDate, checkDateDiffText, checkNumber } from '../../../../formValidator';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { mrRangeCondition, imgStoragePath, pbhRangeCondition, backendDateFormat } from '../../../../constants';
import { getAccountTypeApi } from '../apiServices';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import moment from 'moment'
import { toastFlashMessage } from '../../../../utils';
import { Info, InfoOutlined } from '@material-ui/icons';
import { read, utils } from 'xlsx';
import { Button, Tooltip } from '@material-ui/core';
import { regexConstants } from '../../../../constants/regEx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const ratioRange = { min_ratio: 0, ratio_range: '', max_ratio: 0, mr_rate: 0, remarks: '' };
const adjustment = { ratio: 0, adjustment_factor: 0 };
const numberWithDot = /^[0-9]*(\.\d{0,2})?$/
const intRegx = /^[0-9]?\d+$/;
export default class EditMRRateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mrRate: {},
      inflationList: [],
      ratioRateErrors:[],
      validationInflationArray: [],
      validationIntervalArray: [],
      pbh_condition_error: [],
      mrRationRange: [],
      error: {},
      accType: [],
      updateSheetFlag: false,
      fileSheet: '',
    }
    this.getAccountTypeApi = getAccountTypeApi.bind(this)
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.mrRatesView);
    let dataSheet = this.props.sheetInfo && typeof this.props.sheetInfo === 'object' ? JSON.parse(JSON.stringify(this.props.sheetInfo)) : {};
      let pbh_condition = []
    if (this.props.mrRatesView.pbh_condition && this.props.mrRatesView.pbh_condition.length) {
      this.props.mrRatesView.pbh_condition.map(condition => {
        if (this.props.mrRatesView.interval && this.props.mrRatesView.interval.value === 2) {
          pbh_condition.push({
            type: condition.type,
            min: condition.min,
            max: condition.max,
            ratio_range: condition.ratio_range,
            rate: condition.rate,
            rental_rate_type: condition.rental_rate_type,
            remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        } else if (this.props.mrRatesView.interval && (this.props.mrRatesView.interval.value === 3 || this.props.mrRatesView.interval.value === 4)) {
          pbh_condition.push({
            type: condition.type,
            min: condition.min,
            max: condition.max,
            ratio_range: condition.ratio_range,
            rate: condition.rate,
            rental_rate_type: condition.rental_rate_type,
            remarks: parseInt(condition.ratio_range) === 1 ? 'max' : parseInt(condition.ratio_range) === 6 ? 'min' : 'min,max'
          })
        } 
      })
    }
    let conditions = this.props.techConstants.filter(item => item.type == "mr_rate_range_condition")

    let majorAssembly = this.props.utilsMajorAssembly.find(assembly => assembly.asset_id == data.asset_id);
    data = {
      ...data,
      pbh_condition: pbh_condition,
      pbh_range_type: this.props.mrRatesView.pbh_range_type == true ? true : false,
      interval_condition: this.props.mrRatesView.interval_condition.length ? this.props.mrRatesView.interval_condition.map(item => {
        let innerItem = item
        innerItem = {
          ...innerItem,
          remarks: conditions.filter(condition => condition.value === item.ratio_range).length ? conditions.filter(condition => condition.value === item.ratio_range)[0].remarks : ''
        }
        return innerItem
      }) : [{ min_ratio: 0, ratio_range: '', max_ratio: 0, mr_rate: 0 }],
      adjustment_factor: this.props.mrRatesView.adjustment_factor ? this.props.mrRatesView.adjustment_factor.length ? this.props.mrRatesView.adjustment_factor : [{ ratio: 0, adjustment_factor: 0 }] : [{ ratio: 0, adjustment_factor: 0 }],
      interval: this.props.mrRatesView.interval && this.props.mrRatesView.interval.value ? this.props.mrRatesView.interval.value : '',
      interval_name: this.props.mrRatesView.interval && this.props.mrRatesView.interval.label ? this.props.mrRatesView.interval.label : '',
      rate_escalation_interval: this.props.mrRatesView.rate_escalation_interval && this.props.mrRatesView.rate_escalation_interval.value ? this.props.mrRatesView.rate_escalation_interval.value : '',
      rate_escalation_interval_name: this.props.mrRatesView.rate_escalation_interval && this.props.mrRatesView.rate_escalation_interval.label ? this.props.mrRatesView.rate_escalation_interval.label : '',
      major_assembly: majorAssembly && majorAssembly.id ? majorAssembly.id : null,
      reserve_ratio_rate:this.props.mrRatesView?.reserve_ratio_rate || 1 
    }
    if (data.account_type) {
      if (data.account_type.value == 2) {
        this.setState({ assetList: this.props.utilsMajorAssembly.filter(obj => obj.asset_type == '2') })
      } else {
        this.setState({ assetList: this.props.utilsMajorAssembly })
      }

    }
    this.setState({
      mrRate: data,
      // sheetInfo: dataSheet
    });
    this.getAccountTypeApi()
  }
  updateRangeConditon = (key, value, index) => {
    if (key === 'ratio_range') {
      let condition = {
        1: 'max',
        6: 'min',
        4: 'min,max'
      }
      let dynamicValue = ''
      if (value === 1) {
        this.setState(prevState => ({
          ...prevState,
          mrRate: {
            ...prevState.mrRate,
            pbh_condition: prevState.mrRate.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? { ...range, [key]: value, remarks: condition[value], max: 0, min: 0 } : range)
          },
        }))
      } else if (value === 6) {
        this.setState(prevState => ({
          ...prevState,
          mrRate: {
            ...prevState.mrRate,
            pbh_condition: prevState.mrRate.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? { ...range, [key]: value, remarks: condition[value], min: 0, max: 0 } : range)
          },
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          mrRate: {
            ...prevState.mrRate,
            pbh_condition: prevState.mrRate.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? { ...range, [key]: value, remarks: condition[value], min: 0, max: 0 } : range)
          },
        }))
      }
      setTimeout(() => {
        if (this.state.mrRate.rental_type === 4 || this.state.mrRate.rental_type === 5) {
          if (this.state.mrRate.pbh_condition.filter(item => item.ratio_range === 1).length) {
            this.setState({
              showLess: false
            })
          }
          if (this.state.mrRate.pbh_condition.filter(item => item.ratio_range === 6).length) {
            this.setState({
              showGreat: false
            })
          }
        }
      })
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          pbh_condition: prevState.mrRate.pbh_condition.map((range, rangeIndex) => rangeIndex === index ? { ...range, [key]: value } : range)
        },
      }))
    }
    this.setState(prevState => ({
      ...prevState,
      pbh_condition_error: prevState.pbh_condition_error.map(conError => {
        if (conError.index === index) {
          return { ...conError, [key]: '' }
        } else {
          return conError
        }
      })
    }))
  }

  addFlexiRatio = (unit_of_measure, type) => {
    this.setState(prevState => ({
      ...prevState,
      pbh_condition_error: [...prevState.pbh_condition_error, { min: '', max: '', index: prevState.pbh_condition_error.length }],
      mrRate: {
        ...prevState.mrRate,
        pbh_condition: [
          ...prevState.mrRate.pbh_condition,
          {
            type: unit_of_measure,
            min: 0,
            max: 0,
            ratio_range: type === 'range' ? '4' : type === 'min' ? '6' : '1',
            rate: 0,
            rental_rate_type: type !== 'range' ? 1 : 2,
            remarks: type !== 'range' ? type : 'min,max'
          }
        ]
      },
    }))
  }
  updateForm = (type, value) => {
    if (type == 'account_type' && value) {
      if (value.value === 2) {
        this.setState({ assetList: this.props.utilsMajorAssembly.filter(obj => obj.asset_type == '2') })
      } else {
        this.setState({ assetList: this.props.utilsMajorAssembly })
      }

    }
    if (type == 'rate_escalation_start_date' && value == 'Invalid date') {
      value = null;
    }
    if(type === 'reserve_ratio_rate'){
      this.setState(prevState => ({
        ...prevState,
        mrRate:{
          ...prevState.mrRate,
          interval_condition:[]
        }
      }))
    }
    if(type === 'interval' && value == 1){
      this.setState(prevState => ({
        ...prevState,
        mrRate:{
          ...prevState.mrRate,
          reserve_ratio_rate:[]
        }
      }))
    }
    if (type == 'rate_escalation_interval' && value == 2) {
      this.setState(prevState=>({
        ...prevState ,mrRate:{
          ...prevState.mrRate,
          rate_escalation_start_date:moment(this.props?.rentalInfo?.contract_rental_details?.contractual_start_date).add(1,'years').format(backendDateFormat)
        }
      }))
    }
    if (type == 'cost_escalation_percentage' && value == null) {
      value = 0;
    }
    if (type == 'rate_escalation_percentage' && value == null) {
      value = 0;
    }
    if (type === 'interval' || type === 'interval_condition_status' ) {
      if (value !== this.state.mrRate.interval) {
        this.setState(prevState => ({
          ...prevState,
          mrRate: {
            ...prevState.mrRate,
            interval_condition: []
          }
        }))
      }
    }
    if( type== 'interval'){
      if(this.state.mrRate?.pbh_status ==true && [2,3].includes(value)){
        this.setState(prevState=>({
          ...prevState,
          mrRate:{
            ...prevState.mrRate,
            reserve_rate_per_interval: 0,
          }
        }))
      }
      this.setState(prevState=>({
          ...prevState,
          mrRate:{
            ...prevState.mrRate,
            pbh_condition:[]
          }
        }))
    }
    if( type == 'pbh_status' && value == true && [2,3].includes(this.state.mrRate?.interval) ){
      this.setState(prevState=>({
        ...prevState,
        mrRate:{
          ...prevState.mrRate,
          reserve_rate_per_interval: 0,
        }
      }))
    }
    if( type == 'reserve_ratio_rate' && value == 2  && [2,3].includes(this.state.mrRate?.interval)){
      this.setState(prevState=>({
        ...prevState,
        mrRate:{
          ...prevState.mrRate,
          reserve_rate_per_interval: 0,
        }
      }))
    }
    if (type == 'major_assembly') {
      let asset_id = '';
      let asset_type = '';
      if (value) {
        let asset = this.props.utilsMajorAssembly.filter(assembly => assembly.id == value);
        asset_id = asset[0].asset_id;
        asset_type = asset[0].asset_type;
      }
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          asset_id: asset_id,
          asset_type: asset_type,
          major_assembly: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    }
  }

  addRatio = (type) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        interval_condition: [...prevState.mrRate.interval_condition, { ...ratioRange, type }]
      }
    }));
  }
  removePbhRatio = (index) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        pbh_condition: prevState.mrRate.pbh_condition.filter((range, rangeIndex) => index !== rangeIndex)
      },
    }))
  }
  removeRatio = (ratioIndex) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        interval_condition: prevState.mrRate.interval_condition.filter((ratio, index) => ratioIndex != index)
      }
    }));
  }
  addAdjustment = () => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: [...prevState.mrRate.adjustment_factor, adjustment]
      }
    }));
  }
  removeAdjustment = (adjustmentIndex) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: prevState.mrRate.adjustment_factor.filter((factor, index) => adjustmentIndex != index)
      }
    }));
  }
  updateFieldRatio = (ratioIndex, value, type) => {
    if (type === 'ratio_range') {
      let conditions = this.props.techConstants.filter(item => item.type == "mr_rate_range_condition")
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition: prevState.mrRate.interval_condition.map((ratio, index) => ratioIndex == index ? { ...ratio, min_ratio: '', max_ratio: '', [type]: value, remarks: conditions.filter(condition => condition.value === value).length ? conditions.filter(condition => condition.value === value)[0].remarks : '' } : ratio)
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition: prevState.mrRate.interval_condition.map((ratio, index) => ratioIndex == index ? { ...ratio, [type]: value } : ratio)
        }
      }))
    }
  }
  updateFieldAdjust = (adjustIndex, value, type) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: prevState.mrRate.adjustment_factor.map((factor, index) => adjustIndex == index ? { ...factor, [type]: value } : factor)
      }
    }))
  }



  checkChildIntervalMap = () => {
    let vaildArray = [];
    this.state.mrRate.interval_condition.map((interval, index) => {
      let obj = {};
      if (interval.remarks && interval.remarks.includes('min')) {
        if ((checkNumber({ value: interval.min_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio' })) == '') {
          obj.min_ratio = true;
        } else {
          obj.min_ratio = false;
        }
      } else {
        obj.min_ratio = true
      }
      if ((checkString({ value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range' })) == '') {
        obj.ratio_range = true;
      } else {
        obj.ratio_range = false;
      }
      if (interval.remarks && interval.remarks.includes('max')) {
        if ((checkNumber({ value: interval.max_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio' })) == '') {
          obj.max_ratio = true;
        } else {
          obj.max_ratio = false;
        }
      } else {
        obj.max_ratio = true;
      }
      if ((checkNumber({ value: interval.mr_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter MR Rate' })) == '') {
        obj.mr_rate = true;
      } else {
        obj.mr_rate = false;
      }
      if ((checkNumber({ value: interval.min_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio' })) == '' &&
        (checkNumber({ value: interval.max_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio' })) == '' &&
        (checkString({ value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range' })) == '') {
        if (this.checkValidationRatio(interval.min_ratio, interval.max_ratio, interval.ratio_range)) {
          obj.ratio_range_validation = true;
        } else {
          obj.ratio_range_validation = false;
        }
      } else {
        obj.ratio_range_validation = true;
      }
      vaildArray.push(obj)
    })
    return vaildArray;
  }


  checkValidationRatio = (min, max, type) => {
    switch (type) {


      case 4:
        if (min < max) {
          return true;
        } else {
          return false;
        }

      case 5:
        if (min < max) {
          return true;
        } else {
          return false;
        }

      default:
        return true;

    }

  }

  checkChildInflationMap = () => {
    let vaildArray = [];
    this.state.mrRate.adjustment_factor.map((adjustment, index) => {
      let obj = {};
      if ((checkNumber({ value: adjustment.adjustment_factor, required: true, minLength: '', maxLength: '', message: 'Please Enter Adjustment Factor' })) == '') {
        obj.adjustment_factor = true;
      } else {
        obj.adjustment_factor = false;
      }
      if ((checkNumber({ value: adjustment.ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter FC/FH Ratio' })) == '') {
        obj.ratio = true;
      } else {
        obj.ratio = false;
      }
      vaildArray.push(obj)
    })
    return vaildArray;
  }

  updateMrRate = (e) => {
    let validInflationArray = [];
    let validIntervalArray = [];
    e.preventDefault();
    let validateNewInput = {
      max_cap_amount:(checkNumber({ value: this.state.mrRate.max_cap_amount, required:this.state.mrRate?.max_cap_applicable === true?true:false, minLength: '', maxLength: '', message: 'Please Enter Max Cap Amount' })),
      name: checkString({ value: this.state.mrRate.name, required: true, minLength: '', maxLength: '', message: 'Please Enter Reserve Account Name' }),
      major_assembly: checkString({ value: this.state.mrRate.reserve_rate_per_interval, required: true, minLength: '', maxLength: '', message: 'Please Select Asset' }),
      interval: checkString({ value: this.state.mrRate.interval, required: true, minLength: '', maxLength: '', message: 'Please Enter The Interval' }),
      reserve_rate_per_interval: checkString({ value: this.state.mrRate.reserve_rate_per_interval, required:  this.state.mrRate.reserve_ratio_rate !== 2 ? true : false, minLength: '', maxLength: '', message: this.state.mrRate?.pbh_status == true && [2,3].includes(this.state.mrRate?.interval) ? '': 'Please Enter Reserve Rate Per Interval' }),
      rate_start_date: checkValidDate({ value: this.state.mrRate.rate_start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Reserve Rate Start Date' }),
      rate_escalation_interval: checkString({ value: this.state.mrRate.rate_escalation_interval, required: this.state.mrRate.is_escalation_mucip ? false : false, minLength: '', maxLength: '', message: 'Please Enter Rate Escalation Interval' }),
      rate_escalation_percentage: checkNumber({ value: this.state.mrRate.rate_escalation_percentage, required: this.state.mrRate.is_escalation_mucip ? false : false, minLength: '', maxLength: '', message: 'Please Enter Rate Escalation Percentage' }),
      starting_fund_date: checkValidDate({ value: this.state.mrRate.starting_fund_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Starting Fund Date' }),
      starting_fund_amount: checkNumber({ value: this.state.mrRate.starting_fund_amount, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Fund Amount' }),
      contractual_factor: checkNumber({ value: this.state.mrRate.contractual_factor, required: this.state.mrRate.is_adjustment_factor ? true : false, minLength: '', maxLength: '', message: 'Please Enter Lease FH/FC Ratio' }),
    };
    if(this.props.rentalInfo?.contract_rental_details?.lease_duration_type?.value !=3 ){
      validateNewInput={
        ...validateNewInput, 
      rate_end_date: checkValidDate({ value: this.state.mrRate.rate_end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Reserve Rate End Date' }),
    }
  }
    if(this.state.mrRate.rate_end_date){
      validateNewInput={
        ...validateNewInput,
        rate_end_date: checkDateDiffText({ value: this.state.mrRate.rate_start_date, required: true }, { value: this.state.mrRate.rate_end_date, required: true }, 'Rate Start Date', 'Rate End Date'),
      }
    }
    if (this.props.type !== 'edit') {
      validateNewInput = {
        ...validateNewInput,
        major_assembly: checkString({ value: this.state.mrRate.major_assembly, required: true, minLength: '', maxLength: '', message: 'Please Select Asset' }),
      }
    }
    let isIntervalError = true;
    if (this.state.mrRate.interval_condition_status) {
      if(this.state.mrRate.reserve_ratio_rate === 1){
        validIntervalArray = this.checkChildIntervalMap();
      }else{
        if (this.state.mrRate?.interval_condition?.length === 0) {
            toastFlashMessage("Please import valid file.", 'error');
            return;
        } else {
          let intervalError = [];
          intervalError = this.state.mrRate.interval_condition.map((ratioRate, index) => {
            return {
              ratio: ratioRate?.ratio ? '' : `Please enter ratio`,
              rate: ratioRate?.rate ? '' : `Please enter rate`,
            };
          });
          intervalError.map(item => {
            if (!Object.keys(item).every((k) => { return item[k] === '' })) {
              isIntervalError = false;
            }
          });
          if(!isIntervalError){
            this.setState({ratioRateErrors: intervalError});
            return;
          }
          let ratioValues = this.state.mrRate.interval_condition.map(item => item.ratio);
          let ratioMap = new Map();
          let duplicates = [];

          ratioValues.forEach((ratio, index) => {
            if (ratioMap.has(ratio)) {
              duplicates.push({ ratio, index1: ratioMap.get(ratio), index2: index });
            } else {
              ratioMap.set(ratio, index);
            }
          });
          
          if (duplicates.length > 0) {
            let duplicateMessage = "Duplicate ratios found at the following rows: ";
            duplicates.forEach((duplicate, index) => {
              duplicateMessage += `Row ${duplicate.index1 + 1} and Row ${duplicate.index2 + 1} (Ratio: ${duplicate.ratio}), `;
            });
            duplicateMessage = duplicateMessage.slice(0, -2) + '.'; 
            toastFlashMessage(duplicateMessage, 'error');
            return;
          }          
          
        }
      }
    }
    if (this.state.mrRate.is_adjustment_factor) {
      validInflationArray = this.checkChildInflationMap();
    }
    if(this.state.mrRate.rate_start_date){
      validateNewInput = {
        ...validateNewInput,
        rate_start_date: this.state.mrRate.rate_start_date < this.props.mrRateInvoiceInfo.data.contractual_start_date ? 'Reserve Rate Start Date should be more than Lease Start Date': this.state.mrRate.rate_start_date > this.props.mrRateInvoiceInfo.data.contractual_end_date ? 'Reserve Rate Start Date should be less than Lease End Date' : ''
    }}
    if(this.state.mrRate.rate_end_date){
      validateNewInput = {
        ...validateNewInput,
        rate_end_date: this.state.mrRate.rate_end_date < this.props.mrRateInvoiceInfo.data.contractual_start_date ? 'Reserve Rate End Date should be more than Lease Start Date': this.state.mrRate.rate_end_date > this.props.mrRateInvoiceInfo.data.contractual_end_date ? 'Reserve Rate End Date should be less than Lease End Date' : ''
    }}
    
    let pbhMinMaxErrFlag = false
    if([2,3,4].includes(this.state.mrRate?.interval) && this.state.mrRate?.pbh_status && Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if([4].includes(this.state.mrRate?.interval)){
        let pbhFilterarr =this.state.mrRate.pbh_condition.filter(item=> {return ( (item?.type ==1 && (item?.ratio_range == '1' ||  item?.ratio_range == '6')) || (item?.type ==2 &&(item?.ratio_range == '1' ||  item?.ratio_range == '6')) )})
        if(pbhFilterarr.length < 4 ){
          pbhMinMaxErrFlag =true
          toastFlashMessage("Please input Min and Max Range Conditions to save.", 'error');
        }
      }
      else{
        let pbhFilterarr =this.state.mrRate.pbh_condition.filter(item=> {return (item?.ratio_range == '1' ||  item?.ratio_range == '6')})
        if(pbhFilterarr.length < 2 ){
          pbhMinMaxErrFlag =true
          toastFlashMessage("Please input Min and Max Range Conditions to save.", 'error');
        }
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' }) && !pbhMinMaxErrFlag) {
      if (this.state.mrRate.interval_condition_status) {
        if(this.state.mrRate.reserve_ratio_rate === 1){
          if ((validIntervalArray.filter(item => !(item.min_ratio && item.max_ratio && item.ratio_range && item.mr_rate && item.ratio_range_validation))).length == 0) {
            if (this.props.type == 'add') {
              this.props.toggleEditSideBar();
            }
            this.props.updateMrRate(this.state.mrRate, this.props.type);
          } else {
            this.setState({
              validationIntervalArray: validIntervalArray
            });
          }
        }else{
          if (this.props.type == 'add') {
            this.props.toggleEditSideBar();
          }
          this.props.updateMrRate(this.state.mrRate, this.props.type);
        }
      } else if (this.state.mrRate.is_adjustment_factor) {
        if ((validInflationArray.filter(item => !(item.adjustment_factor && item.ratio))).length == 0) {
          if (this.props.type == 'add') {
            this.props.toggleEditSideBar();
          }
          this.props.updateMrRate(this.state.mrRate, this.props.type);
        } else {
          this.setState({
            validationInflationArray: validInflationArray
          });
        }

      } else {
        if (this.props.type == 'add') {
          this.props.toggleEditSideBar();
        }
        let data= Object.assign({},this.state.mrRate)
        data={
          ...data,
          additional_amount:data.is_additional_amount ? data.additional_amount : 0
        }
        if (this.state.mrRate.max_cap_applicable) {
          data.max_cap_amount = parseFloat(this.state.mrRate.max_cap_amount || null);
        } else {
          data.max_cap_amount = null; 
        }
        this.props.updateMrRate(data, this.props.type);
      }
    } else {
      this.setState({
        error: validateNewInput
      });
    }
  }

  updateErrorCode = (type) => {
    this.setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [type]: ''
      }
    }));
  }
updateXlsx = (file) => {
    if (file?.name?.endsWith('.xlsx'||'.xls')) {
        const expectedFileHeaders = ['Ratio', 'Rate'];
        
        let sheetData = [];
        if (file instanceof Blob) {
            const reader = new FileReader();
            let $that = this;
            reader.onload = function (e) {
                const buffer = e.target.result;
                const wb = read(new Uint8Array(buffer)); // Parse the file
                const ws = wb.Sheets[wb.SheetNames[0]]; // Get the first sheet
                const data = utils.sheet_to_json(ws, { blankRows: false }); // Convert sheet to JSON

                if (data.length === 0) {
                    toastFlashMessage('Sheet is empty or has invalid content.', 'error');
                    return;
                }

                const headers = Object.keys(data[0] || {});
                const validFile = expectedFileHeaders.every(header => headers.includes(header));
                
                if (!validFile) {
                    toastFlashMessage('Unable to Import the file. Please check for exact column names ("Ratio" and "Rate").', 'error');
                    return;
                }

                const ratioValues = data.map(item => item.Ratio);
                const uniqueRatios = new Set(ratioValues);
               
                if (uniqueRatios.size !== ratioValues.length) {
                    toastFlashMessage("Duplicate ratios found in the file. Please ensure all ratios are unique.", 'error');
                    return;
                }
                const isValidDecimal = (value) => /^[+-]?\d+(\.\d+)?$/.test(value);
                for (let row of data) {
                    if ((row.Ratio && !isValidDecimal(row.Ratio)) || (row.Rate && !isValidDecimal(row.Rate))) {
                        toastFlashMessage("Invalid entries in Ratio or Rate. Please check the data in the sheet.", 'error');
                        return;
                    }
                }
                sheetData = data.map(item => ({
                    ratio: item.Ratio?parseFloat(item.Ratio).toFixed(2):null, 
                    rate: item.Rate ? parseFloat(item.Rate).toFixed(2) : null,                   
                    remarks: 'fix', 
                }));
                $that.setState((prevState) => ({
                    ...prevState,
                    mrRate: {
                        ...prevState.mrRate,
                        interval_condition: sheetData,
                    },
                }));
            };
            reader.readAsArrayBuffer(file); // Read the file
        } else {
            // toastFlashMessage('Invalid file type. Expected a Blob.', 'error');
        }
    } else {
        toastFlashMessage('File is not an Excel file. Please upload a valid Excel file.', 'error');
    }
};


  handleRatioRateChange = (changeType, key, index, value) => {
    if(changeType === 'error'){
      this.setState(prevState => ({
        ...prevState,
        ratioRateErrors:prevState.ratioRateErrors.map((item, itemIndex) => itemIndex === index ? {...item,[key]:''}:item)
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition:prevState.mrRate.interval_condition.map((item, itemIndex) => itemIndex === index ? {...item, [key]:value}:item)  
        }
      }))
    }
  }
  handleAddRemoveRatioRate = (type, index) => {
    if(type === 'add' || type === 'edit'){
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition: [...prevState.mrRate.interval_condition, {ratio:'', rate:'', remarks:'fix'}]
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition:prevState.mrRate.interval_condition.filter((item, itemIndex) => itemIndex !== index)
        }
      }))
    }
  }
  
  render() {
    const { type, techConstants, rentalInfo } = this.props;
    const { mrRate, error, accType, assetList, ratioRateErrors } = this.state;
    return (
      <form onSubmit={(e) => this.updateMrRate(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
          <Row>
            {
              ['view'].includes(type) ?
                <Col md="6">
                  <div className="form-group">
                    <label className="label">Reserve Account</label>
                    <h6>{mrRate.name}</h6>
                  </div>
                </Col> : null
            }
            {
              ['add', 'edit'].includes(type) ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Reserve Account Name
                      {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                    </FieldLabel>
                    <FieldInput
                      value={mrRate.name}
                      type={type}
                      keyParam="name"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.name}</h6>
                  </FieldGroup>
                </FieldCol> : null
            }
            <FieldCol md="6">
              {
                type == 'view' ?
                  <div className="form-group">
                    <label className="label">Is Notional Reserve</label>
                    <h6>{mrRate.is_notional_mr ? 'YES' : 'NO'}</h6>
                  </div>
                  :
                  <div className="form-group">
                    <label className="label checkbox-block">
                      <input
                        type="checkbox"
                        value={mrRate.is_notional_mr}
                        checked={mrRate.is_notional_mr}
                        onChange={(e) => this.updateForm('is_notional_mr', e.target.checked)}
                        style={{ marginRight: '4px' }}
                      />
                      <span>Is Notional Reserve</span>
                    </label>
                  </div>
              }
            </FieldCol>

            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Reserve Rate Interval
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Interval'
                  keyParam="interval"
                  value={mrRate.interval}
                  type={type}
                  options={techConstants.filter(item => item.type == "interval" && item.value !== 5)}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={mrRate.interval_name}
                />
                <h6 className="error-msg">{error.interval}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Account Type
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Account Type'
                  keyParam="account_type"
                  value={mrRate.account_type}
                  type={type}
                  options={this.props.utilsMajorAssembly && this.props.utilsMajorAssembly[0]?.asset_type == 1 || this.props.utilsMajorAssembly[0]?.asset_type == 2 && this.props.utilsMajorAssembly.filter(i => i.asset_type == 2).length ? accType : accType.filter(i => i.value !== 2)}
                  updateField={(type, value) => this.updateForm(type, accType.find(acItem => acItem.value == value))}
                  labelKey='label'
                  valueKey='value'
                  name={mrRate.account_type && mrRate.account_type.label ? mrRate.account_type.label : ''}
                />
                <h6 className="error-msg">{error.account_type}</h6>

              </FieldGroup>
            </FieldCol>
            {
              ['add', 'edit',].includes(type) ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Asset
                      {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Asset'
                      keyParam="major_assembly"
                      value={mrRate.major_assembly}
                      type={type}
                      options={assetList}
                      updateField={this.updateForm}
                      labelKey='name'
                      valueKey='id'
                    />
                    <h6 className="error-msg">{error.major_assembly}</h6>
                  </FieldGroup>
                </FieldCol> : null
            }
            <FieldCol md={type === 'view' ? 6 : 4}>
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Basic Reserve Rate ({mrRate.currency ? mrRate.currency : 'USD'})
                  {type != 'view'&& mrRate.reserve_ratio_rate !== 2  ? <sup className="reqStar"> * </sup> : null}
                  <InfoIcon id="mr-rate-info" />
                  <ToolTipHover placement="top" tagetId="mr-rate-info">
                    Note: Base reserve rate value should not include MUICP inflation rates and Adjustment factors
                  </ToolTipHover>

                </FieldLabel>
                <FieldInput
                  value={mrRate.reserve_rate_per_interval}
                  type={type}
                  accept='number'
                  keyParam="reserve_rate_per_interval"
                  updateField={this.updateForm}
                  numbersWithDot={true}
                  disable={mrRate.pbh_status == true && [2, 3].includes(mrRate?.interval)}
                  currency={true}
                  currencyType={mrRate.currency}
                  style={{ width: mrRate.is_additional_amount ? '210px' : '350px' }}
                />
                <h6 className="error-msg">{error.reserve_rate_per_interval}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md={type === 'view' ? 6 : 2} style={{ right: type != 'view' ? '65px' : '' }}>
              <FieldGroup className="form-group">
                <FieldLabel className="label" style={{ width: "130px" }} >
                  {type === 'view' ? null : <input
                    type="checkbox"
                    value={mrRate.is_additional_amount}
                    checked={mrRate.is_additional_amount}
                    onChange={(e) => this.updateForm('is_additional_amount', e.target.checked)}
                    style={{ marginLeft: '3px', verticalAlign: 'sub' }}
                  />}
                  Additional Amount
                </FieldLabel>
                {mrRate.is_additional_amount ? <FieldInput
                  value={mrRate.is_additional_amount ? mrRate.additional_amount : ''}
                  type={type}
                  accept='number'
                  keyParam="additional_amount"
                  updateField={this.updateForm}
                  numbersWithDot={true}
                  disable={!mrRate.is_additional_amount}
                  currency={true}
                  currencyType={mrRate.currency}
                  style={{ width: '120px', marginLeft: "10px" }}
                /> : null}
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Reserve Rate Start Date
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldDate
                  value={mrRate.rate_start_date}
                  type={type}
                  keyParam="rate_start_date"
                  updateField={this.updateForm}
                  minDate={this.props.rentalInfo?.contract_rental_details?.contractual_start_date ? moment(this.props.rentalInfo?.contract_rental_details?.contractual_start_date): null}
                />
                <h6 className="error-msg">{error.rate_start_date}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Reserve Rate End Date
                {type != 'view' ? this.props.rentalInfo?.contract_rental_details?.lease_duration_type?.value !=3 ? <sup className="reqStar"> * </sup> : null : null}
                </FieldLabel>
                <FieldDate
                  value={mrRate.rate_end_date}
                  type={type}
                  keyParam="rate_end_date"
                   minDate={moment(mrRate.rate_start_date)}
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.rate_end_date}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Starting Fund Date
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}

                </FieldLabel>
                <FieldDate
                  value={mrRate.starting_fund_date}
                  type={type}
                  keyParam="starting_fund_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.starting_fund_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Starting Fund Amount (USD)
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={mrRate.starting_fund_amount}
                  type={type}
                  accept='number'
                  keyParam="starting_fund_amount"
                  updateField={this.updateForm}
                  currency={true}

                />
                <h6 className="error-msg">{error.starting_fund_amount}</h6>
              </FieldGroup>
            </FieldCol>
            <Col md="12" >
              {
                type != 'view' && mrRate.interval !== 4 ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate.interval_condition_status} onChange={(e) => {this.updateForm('interval_condition_status', e.target.checked);this.updateForm('is_adjustment_factor', false); this.updateForm('pbh_status', false)}}/>
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Reserve Rate Based on FH/FC Ratio</span>
                    </label>
                  </div>
                  :  mrRate.interval_condition_status ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">Reserve Rate Based on FH/FC Ratio</h5> :null
              }
            </Col>
            {mrRate.interval_condition_status && [2, 3].includes(mrRate.interval) && ['add', 'edit'].includes(type)  ?
              <div style={{ width: '100%' }}>
                  <FieldCol md="6">

              <div className="form-group">
                <label className="label" style={{display:'inline-flex'}}>
                  <input
                    type="radio"
                    name="rateOption"
                    value="option1"
                    checked={mrRate.reserve_ratio_rate === 1}
                    onChange={() => this.updateForm('reserve_ratio_rate', 1)}
                    style={{marginRight:'4px'}}
                  />
                  Range Based Ratio
                </label>
                <label className="label"style={{display:'inline-flex', marginLeft: '20px'}} >
                  <input
                    type="radio"
                    name="rateOption"
                    value="option2"
                    checked={mrRate.reserve_ratio_rate === 2}
                    onChange={() => this.updateForm('reserve_ratio_rate', 2)}
                    style={{marginRight:'4px'}}
                  />
                  Fixed Rate Ratio
                </label>
              </div> 
              </FieldCol></div>: null}
            {mrRate.interval_condition_status && mrRate.reserve_ratio_rate === 2 && [2, 3].includes(mrRate.interval) ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                {['add', 'edit'].includes(type) ?
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item">
                  <span className="file_attachment">
                    <img className="img_attachment" src={imgStoragePath + "attachment.png"} alt="img" />
                    {['add', 'edit'].includes(type) ? 'Import Fixed Rate Ratio':null} {['add', 'edit'].includes(type) ? <input type="file" onChange={(e) => this.updateXlsx(e.target.files[0])} /> : null}
                  </span>
                  </li>
                  <li className="list-inline-item" style={{marginLeft:'6px',position:'relative'}} >
                    <Tooltip title='Kindly note that importing the file during Add or Edit will replace the existing data with the new values.' >
                      <Info  style={{ fontSize: '14px',color:'#a0a0a0' }} />
                    </Tooltip>                
                  </li>
                  </ul>
                  :null}
                {['add', 'edit',].includes(type) ?
                  <div>
                    <span style={{ fontSize: '10px', color: '#909192' }}> {'Sample attachment : '}
                      <Link target="_blank" to={'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media/Sample_Template/Fixed_Ratio_Rate_Sample_File.xlsx'}>Sample of a Fixed Rate Ratio for Import.</Link>
                    </span>
                    <p style={{fontSize:'11px',background:'#FFF8B9',width:'fit-content'}}>Note: The Ratio value is rounded to two significant decimal places.</p>
                  </div>:null}
                  <div>
                  {mrRate?.interval_condition?.length ?
                    <div className="contr-table-blk" style={{border:'none'}}>
                      <div className="table-container" style={{borderRadius:'3px'}}>
                        <form>
                          <div className="table-responsive table-height">
                            <table className="table-sticky common-table" >
                           { type != 'view'?
                              <CtrTableHd theads={['Ratio', 'Rate','Action']} />:
                              <CtrTableHd theads={['Ratio', 'Rate']} />
                           } 
                              <tbody>        
                                {mrRate?.interval_condition?.length && mrRate.interval_condition.map((item, ratioRateIndex) => (
                                  <tr key={ratioRateIndex}>
                                    <td align='left'>
                                      <div>
                                        <FieldInput
                                          value={item?.ratio || ''}
                                          type={type}
                                          keyParam="ratio"
                                          accept='number'
                                          disabled={type == 'view'}
                                          updateField={(type, value) => this.handleRatioRateChange('change', type, ratioRateIndex, value)}
                                          handleOnFocus={() => this.handleRatioRateChange('error', 'ratio', ratioRateIndex)}
                                        />
                                      </div>
                                      { ratioRateErrors[ratioRateIndex]?.ratio ? <h6 className="error-msg">{ratioRateErrors[ratioRateIndex]?.ratio}</h6>:null}
                                    </td>
                                    <td align='left'>
                                      <div>
                                        <FieldInput
                                          value={item?.rate || ''}
                                          type={type}
                                          keyParam="rate"
                                          accept='number'
                                          disabled={type == 'view'}
                                          updateField={(type, value) => this.handleRatioRateChange('change', type, ratioRateIndex, value)}
                                          handleOnFocus={() => this.handleRatioRateChange('error', 'rate', ratioRateIndex)}
                                        />
                                      </div>
                                      { ratioRateErrors[ratioRateIndex]?.rate ? <h6 className="error-msg">{ratioRateErrors[ratioRateIndex]?.rate}</h6>:null}
                                    </td>
                                    {type != 'view' ?
                                    <td>
                                      <span onClick={() => this.handleAddRemoveRatioRate('remove', ratioRateIndex)}><HighlightOffIcon color='secondary' fontSize='small'/></span>
                                    </td>:null}
                                  </tr> 
                                ))}
                              </tbody>
                            </table>
                            {type != 'view' ?
                            <Button style={{margin:'4px 0px 4px 4px'}} size='small' color='primary' variant='outlined' onClick={() => this.handleAddRemoveRatioRate('add')}>+ Add Ratio Rate</Button>
                            :null}
                          </div>
                        </form>
                      </div>
                    </div>:null}
                  </div>
                </FieldGroup>
              </FieldCol> : null}
            {
              mrRate.interval_condition_status && (mrRate.interval === 1) ?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom: '20px' }}>Monthly</h5>
                </Col>
                : null
            }

            {
              mrRate.interval_condition_status && (mrRate.interval === 1 ) ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                            ratioRange.type === 1 ?
                              <tr key={index}>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                        :
                                        <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].min_ratio == false ?
                                        <h6 className="error-msg">Please Enter Min Ratio</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <Select
                                          name="form-field-name"
                                          placeholder="Select Range"
                                          className="custom-select-block"
                                          value={ratioRange.ratio_range}
                                          options={techConstants.filter(item => item.type == "mr_rate_range_condition")}
                                          labelKey="label"
                                          valueKey="value"
                                          type={type}
                                          onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                        /> :
                                        <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range == false ?
                                        <h6 className="error-msg">Please Enter Ratio Range</h6> : null : null}
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                        <h6 className="error-msg">Invalid Ratio Range</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                        :
                                        <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].max_ratio == false ?
                                        <h6 className="error-msg">Please Enter Max Ratio</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                        : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].mr_rate == false ?
                                        <h6 className="error-msg">Please Enter Reserve Rate</h6> : null : null}
                                  </div>
                                </td>
                                {
                                  type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath + "del_icon.png"} /></td> : null
                                }
                              </tr>
                              : null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 1).length < 6 ? <span className="add-mr-int" onClick={() => this.addRatio(1)}>+ Add Reserve Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 2 ) && mrRate.reserve_ratio_rate === 1  ?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom: '20px' }}>Flight Hours</h5>
                </Col>
                : null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 2) && mrRate.reserve_ratio_rate === 1 ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                            ratioRange.type === 2 ?
                              <tr key={index}>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                        :
                                        <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].min_ratio == false ?
                                        <h6 className="error-msg">Please Enter Min Ratio</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <Select
                                          name="form-field-name"
                                          placeholder="Select Range"
                                          className="custom-select-block"
                                          value={ratioRange.ratio_range}
                                          options={techConstants.filter(item => item.type == "mr_rate_range_condition")}
                                          labelKey="label"
                                          valueKey="value"
                                          type={type}
                                          onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                        /> :
                                        <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range == false ?
                                        <h6 className="error-msg">Please Enter Ratio Range</h6> : null : null}
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                        <h6 className="error-msg">Invalid Ratio Range</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                        :
                                        <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].max_ratio == false ?
                                        <h6 className="error-msg">Please Enter Max Ratio</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                        : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].mr_rate == false ?
                                        <h6 className="error-msg">Please Enter Reserve Rate</h6> : null : null}
                                  </div>
                                </td>
                                {
                                  type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath + "del_icon.png"} /></td> : null
                                }
                              </tr>
                              : null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 2).length < 10000 ? <span className="add-mr-int" onClick={() => this.addRatio(2)}>+ Add Reserve Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 3 ) && mrRate.reserve_ratio_rate === 1 ?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom: '20px' }}>Flight Cycle</h5>
                </Col>
                : null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 3 ) && mrRate.reserve_ratio_rate === 1 ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                            ratioRange.type === 3 ?
                              <tr key={index}>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                        :
                                        <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].min_ratio == false ?
                                        <h6 className="error-msg">Please Enter Min Ratio</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <Select
                                          name="form-field-name"
                                          placeholder="Select Range"
                                          className="custom-select-block"
                                          value={ratioRange.ratio_range}
                                          options={techConstants.filter(item => item.type == "mr_rate_range_condition")}
                                          labelKey="label"
                                          valueKey="value"
                                          type={type}
                                          onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                        /> :
                                        <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                    }
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range == false ?
                                        <h6 className="error-msg">Please Enter Ratio Range</h6> : null : null}
                                    {this.state.validationIntervalArray.length == mrRate.interval_condition.length ?
                                      this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                        <h6 className="error-msg">Invalid Ratio Range</h6> : null : null}
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                        :
                                        <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                }
                                {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].max_ratio == false ?
                                 <h6 className="error-msg">Please Enter Max Ratio</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                    : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                }
                                  {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].mr_rate == false ?
                                 <h6 className="error-msg">Please Enter Reserve Rate</h6>:null:null}
                              </div>
                            </td>
                            {
                              type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                            }
                          </tr>
                          :null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 3).length < 10000 ? <span className="add-mr-int" onClick={() => this.addRatio(3)}>+ Add Reserve Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }

              <Col md="12" >
              {
                type != 'view'  ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate.pbh_status} onChange={(e) => {this.updateForm('pbh_status', e.target.checked);this.updateForm('is_adjustment_factor', false);  this.updateForm('interval_condition_status', false)}}/>
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Reserve Rate Based on Utilization</span>
                    </label>
                  </div>
                  :  mrRate.pbh_status ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">Reserve Rate Based on Utilization</h5> :null
              }
            </Col>

            {
              mrRate.interval === 3 || mrRate.interval === 2 || mrRate.interval === 4 ?
              mrRate.pbh_status == true?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" required={true}>Range Condition To Be Considered</FieldLabel>
                    <div className="label" style={{ display: 'block' }}>
                      <span><input onClick={(e) => { type !== 'view' && this.updateForm('pbh_range_type', false) }} disabled={type === 'view' ? true : false} checked={mrRate.pbh_range_type === false} type="radio" /> <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); type !== 'view' && this.updateForm('pbh_range_type', false) }} style={{ cursor: 'pointer' }}>Either Off</span></span>
                      <span style={{ marginLeft: '20px' }}><input onClick={(e) => {type !== 'view' && this.updateForm('pbh_range_type', true) }} disabled={type === 'view' ? true : false} checked={mrRate.pbh_range_type === true} type="radio" /> <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); type !== 'view' && this.updateForm('pbh_range_type', true) }} style={{ cursor: 'pointer' }}>All</span></span>
                    </div>
                  </FieldGroup>
                </FieldCol>
                : null
                : null
            }
            {
              mrRate.interval === 2 || mrRate.interval === 4 ?
              mrRate.pbh_status == true?
                  <Col md="12"  >
                    <h3 style={{ borderBottom: 'none', marginBottom: '0px', marginTop:'0px'  }}>Flight Hour <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_hour_pbh' onClick={() => this.setState({ flight_hour_pbh: !this.state.flight_hour_pbh })} style={{ width: '15px', position: 'reset' }} /></h3>
                    {
                      this.type !== 'view' ?
                        <Popover placement="top" isOpen={this.state.flight_hour_pbh} target="flight_hour_pbh" toggle={() => this.setState({ flight_hour_pbh: !this.state.flight_hour_pbh })}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
                            <table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{ padding: '5px' }}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>Less Than Equal To</td>
                                <td>
                                  0
                                </td>
                                <td>
                                  50
                                </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        : null
                    }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            mrRate.pbh_condition.map((rCondition, rIndex) =>
                              rCondition.type === 1 || rCondition.type === 4 ?
                                <tr>
                                  <td>
                                    {
                                      <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                    }
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ? this.updateRangeConditon('min', evt.target.value, rIndex) : evt.preventDefault()} />
                                        : <h6>{rCondition.min}</h6>
                                    }
                                    {/* {
                                      !mrRateLoader && pbh_condition_error.filter(conError => conError.index === rIndex).length && pbh_condition_error.filter(conError => conError.index === rIndex)[0].min !== '' ? <h6 className="error-msg">{pbh_condition_error.filter(conError => conError.index === rIndex)[0].min}</h6> : null
                                    } */}
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => numberWithDot.test(evt.target.value) && evt.target.value >= 0 ? this.updateRangeConditon('max', evt.target.value, rIndex) : evt.preventDefault()} />
                                        : <h6>{rCondition.max}</h6>
                                    }
                                    {/* {
                                      !mrRateLoader && pbh_condition_error.filter(conError => conError.index === rIndex).length && pbh_condition_error.filter(conError => conError.index === rIndex)[0].max !== '' ? <h6 className="error-msg">{pbh_condition_error.filter(conError => conError.index === rIndex)[0].max}</h6> : null
                                    } */}
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <Select
                                          name="form-field-name"
                                          placeholder="Select Rate Type"
                                          className="custom-select-block"
                                          value={rCondition.rental_rate_type}
                                          options={[
                                            {
                                              id: 1,
                                              label: 'Fixed'
                                            },
                                            {
                                              id: 2,
                                              label: 'Per Unit'
                                            },
                                          ]}
                                          labelKey="label"
                                          valueKey="id"
                                          type={type}
                                          onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                        />
                                        : <h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                    }
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <Fragment>
                                          <input
                                            value={rCondition.rate}
                                            onChange={(evt) => numberWithDot.test(evt.target.value) ? this.updateRangeConditon('rate', evt.target.value, rIndex) : evt.preventDefault()}
                                            type="text"
                                            onBlur={() => parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? this.setState({ error: { ...error, rate: "Please enter Rate greater than 0" } }) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({ error: { ...error, rate: "" } }) : null}
                                          />
                                          {parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null}

                                        </Fragment>
                                        : <h6>{rCondition.rate}</h6>
                                    }
                                  </td>
                                  {
                                    type != 'view' ? <td onClick={() => this.removePbhRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath + "del_icon.png"} /></td> : null
                                  }
                                </tr> : null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view' && mrRate.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'max')}>+ Add Min Rental Rate</span>
                          : null
                      }
                      {
                        type !== 'view' && mrRate.pbh_condition.filter(item => parseInt(item.type) === 1 && item.remarks === 'min').length === 0 ?
                          <span style={{ marginLeft: '20px' }} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'min')}>+ Add Max Rental Rate</span>
                          : null
                      }
                      {
                        type !== 'view' ?
                          <span style={{ marginLeft: '20px' }} className="add-mr-int" onClick={() => this.addFlexiRatio(1, 'range')}>+ Add Range Rental Rate</span>
                          : null
                      }
                    </div>
                    {
                      mrRate.rental_type !== 5 ?
                        <div style={{ marginLeft: '25px' }}><h6 className="error-msg">{error.pbh_condition}</h6></div>
                        : null
                    }
                  </Col>
                  : null
                : null
            }
          {
              mrRate.interval === 3 || mrRate.interval === 4 ?
                mrRate.pbh_status == true?
                  <Col md="12"  >
                    <h3 style={{ borderBottom: 'none', marginBottom: '0px', marginTop:'0px' }}>Flight Cycles <img src={imgStoragePath + 'inv_info_icon.png'} id='flight_cycle_pbh' onClick={() => this.setState({ flight_cycle_pbh: !this.state.flight_cycle_pbh })} style={{ width: '15px', position: 'reset' }} /></h3>
                    {
                      type !== 'view' ?
                        <Popover placement="top" isOpen={this.state.flight_cycle_pbh} target="flight_cycle_pbh" toggle={() => this.setState({ flight_cycle_pbh: !this.state.flight_cycle_pbh })}>
                          <PopoverBody>
                            <h6>Example of Range Condition in continuity:</h6>
                            <table style={{ width: '100%' }}>
                              <tr style={{ border: '1px solid black' }}>
                                <th style={{ padding: '5px' }}>Range Condition</th>
                                <th>Min</th>
                                <th>Max</th>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>Less Than Equal To</td>
                                <td>
                                  0
                                </td>
                                <td>
                                  50
                                </td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>In Between Equal To</td>
                                <td>50.01</td>
                                <td>100</td>
                              </tr>
                              <tr style={{ fontSize: '12px', border: '1px solid black' }}>
                                <td style={{ padding: '5px' }}>Greater Than Equal To</td>
                                <td>100.01</td>
                                <td>0</td>
                              </tr>
                            </table>
                          </PopoverBody>
                        </Popover>
                        : null
                    }
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">Range Condition</th>
                            <th className="label">Min</th>
                            <th className="label">Max</th>
                            <th className="label">Rate Type</th>
                            <th className="label">Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            mrRate.pbh_condition.map((rCondition, rIndex) =>
                              rCondition.type === 2 ?
                                <tr>
                                  <td>
                                    {
                                      <h6>{pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(rCondition.ratio_range))[0].label : '---'}</h6>
                                    }
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <input value={rCondition.min} type="text" disabled={rCondition.remarks === 'max'} onChange={(evt) => (intRegx.test(evt.target.value) && evt.target.value >= 0) || evt.target.value == '' ? this.updateRangeConditon('min', evt.target.value, rIndex) : evt.preventDefault()} />
                                        : <h6>{rCondition.min}</h6>
                                    }
                                    {/* {
                                      !mrRateLoader && pbh_condition_error.filter(conError => conError.index === rIndex).length && pbh_condition_error.filter(conError => conError.index === rIndex)[0].min !== '' ? <h6 className="error-msg">{pbh_condition_error.filter(conError => conError.index === rIndex)[0].min}</h6> : null
                                    } */}
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <input value={rCondition.max} type="text" disabled={rCondition.remarks === 'min'} onChange={(evt) => (intRegx.test(evt.target.value) && evt.target.value >= 0) || evt.target.value == '' ? this.updateRangeConditon('max', evt.target.value, rIndex) : evt.preventDefault()} />
                                        : <h6>{rCondition.max}</h6>
                                    }
                                    {/* {
                                      !mrRateLoader && pbh_condition_error.filter(conError => conError.index === rIndex).length && pbh_condition_error.filter(conError => conError.index === rIndex)[0].max !== '' ? <h6 className="error-msg">{pbh_condition_error.filter(conError => conError.index === rIndex)[0].max}</h6> : null
                                    } */}
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <Select
                                          name="form-field-name"
                                          placeholder="Select Rate Type"
                                          className="custom-select-block"
                                          value={rCondition.rental_rate_type}
                                          options={[
                                            {
                                              id: 1,
                                              label: 'Fixed'
                                            },
                                            {
                                              id: 2,
                                              label: 'Per Unit'
                                            },
                                          ]}
                                          labelKey="label"
                                          valueKey="id"
                                          type={type}
                                          onChange={(target) => this.updateRangeConditon('rental_rate_type', target.id, rIndex)}
                                        />
                                        : <h6>{rCondition.rental_rate_type === 1 ? 'Fixed' : 'Per Unit'}</h6>
                                    }
                                  </td>
                                  <td>
                                    {
                                      type != 'view' ?
                                        <Fragment>
                                          <input
                                            value={rCondition.rate}
                                            onChange={(evt) => numberWithDot.test(evt.target.value) ? this.updateRangeConditon('rate', evt.target.value, rIndex) : evt.preventDefault()}
                                            type="text"
                                            onBlur={() => rCondition.rate <= 0 || rCondition.rate == "" ? this.setState({ error: { ...error, rate: "Please enter Rate greater than 0" } }) : parseInt(rCondition.rate) > 0 || rCondition.rate != "" ? this.setState({ error: { ...error, rate: "" } }) : null}
                                          />
                                          {parseFloat(rCondition.rate) <= 0 || rCondition.rate == "" ? <h6 className="error-msg">{error.rate}</h6> : null}
                                        </Fragment>
                                        : <h6>{rCondition.rate}</h6>
                                    }
                                  </td>
                                  {
                                    type != 'view' ? <td onClick={() => this.removePbhRatio(rIndex)} className="delete-mr-ut"><img width="12" src={imgStoragePath + "del_icon.png"} /></td> : null
                                  }
                                </tr> : null
                            )
                          }
                        </tbody>
                      </table>
                      {
                        type !== 'view' && mrRate.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'max').length === 0 ?
                          <span className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'max')}>+ Add Min Rental Rate</span>
                          : null
                      }
                      {
                        type !== 'view' && mrRate.pbh_condition.filter(item => parseInt(item.type) === 2 && item.remarks === 'min').length === 0 ?
                          <span style={{ marginLeft: '20px' }} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'min')}>+ Add Max Rental Rate</span>
                          : null
                      }
                      {
                        type !== 'view' ?
                          <span style={{ marginLeft: '20px' }} className="add-mr-int" onClick={() => this.addFlexiRatio(2, 'range')}>+ Add Range Rental Rate</span>
                          : null
                      }
                    </div>
                    <div style={{ marginLeft: '25px' }}><h6 className="error-msg">{error.pbh_status}</h6></div>
                  </Col>
                  : null
                : null
            }



            <Col md="12">
              {
                type != 'view' ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate.is_adjustment_factor} onChange={(e) => {this.updateForm('is_adjustment_factor', e.target.checked);this.updateForm('interval_condition_status', false); this.updateForm('pbh_status', false)}} />
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Reserve Rate has Adjustment based on FC/FH Ratio</span>
                    </label>
                  </div>

                  :  mrRate.is_adjustment_factor ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">Reserve Rate has Adjustment based on FC/FH Ratio</h5>:null
              }
            </Col>
            {
              mrRate.is_adjustment_factor ?
                <div style={{ width: '100%' }}>
                  <FieldCol md="6">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Contractual FH/FC Ratio
                        {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                      </FieldLabel>
                      <FieldInput
                        value={mrRate.contractual_factor}
                        type={type}
                        keyParam="contractual_factor"
                        updateField={this.updateForm}
                      />
                      <h6 className="error-msg">{error.contractual_factor}</h6>

                    </FieldGroup>
                  </FieldCol>
                  <Col md="12"  >
                    <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                      {type != 'view' ?
                        <span className="add-mr-int" onClick={this.addAdjustment}>+ Add Adjustment Factor</span> : null
                      }
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="label">FC/FH Ratio</th>
                            <th className="label">Adjustment Factor (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            mrRate.adjustment_factor.map((adujustmentfactor, index) =>
                              <tr key={index}>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" value={adujustmentfactor.ratio} onChange={(e) => this.updateFieldAdjust(index, e.target.value, 'ratio')} />
                                        :
                                        <h6 style={{ fontSize: '14px' }}>{adujustmentfactor.ratio ? adujustmentfactor.ratio : '--'}</h6>

                                    }
                                    {this.state.validationInflationArray.length == mrRate.adjustment_factor.length ?
                                      this.state.validationInflationArray[index].ratio == false ?
                                        <h6 className="error-msg">Please Enter FC/FH Ratio</h6> : null : null}

                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    {
                                      type != 'view' ?
                                        <input type="text" value={adujustmentfactor.adjustment_factor} onChange={(e) => this.updateFieldAdjust(index, e.target.value, 'adjustment_factor')} />
                                        :
                                        <h6 style={{ fontSize: '14px' }}>{adujustmentfactor.adjustment_factor ? adujustmentfactor.adjustment_factor : '--'}</h6>
                                    }
                                    {this.state.validationInflationArray.length == mrRate.adjustment_factor.length ?
                                      this.state.validationInflationArray[index].adjustment_factor == false ?
                                        <h6 className="error-msg">Please Enter Adjustment Factor</h6> : null : null}
                                  </div>
                                </td>
                                {
                                  type != 'view' ? <td onClick={() => this.removeAdjustment(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath + "del_icon.png"} /></td> : null
                                }
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </div> : null
            }
            <Col md="12">
              {
                type != 'view' ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate?.is_adjustment} onChange={(e) => {this.updateForm('is_adjustment', e.target.checked);this.updateForm('interval_condition_status', false); this.updateForm('pbh_status', false)}} />
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Ratio based Reserve Rate</span>
                    </label>
                  </div>

                  :  mrRate?.is_adjustment ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">Ratio based Reserve Rate</h5>:null
              }
            </Col>
            <Col md="12" >
              <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom: '20px' }}>Escalation details</h5>
            </Col>
            <Col md="12">
              {type == 'view' && !mrRate.is_escalation_mucip ? null :
                <div className="form-group">
                  <label>
                    <input type="checkbox" checked={mrRate.is_escalation_mucip} onChange={(e) => type != 'view' ? this.updateForm('is_escalation_mucip', e.target.checked) : ''} />
                    <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Escalation to be picked up from MUICP</span>
                  </label>
                </div>
              }
            </Col>
            <FieldCol md="6">
            {type == 'view' && mrRate.is_escalation_mucip ? null :
              <FieldGroup className="form-group" style={{opacity:type != 'view' && mrRate.is_escalation_mucip ?'0.5':'1'}}>
                <FieldLabel className="label">
                Reserve Escalation Percentage
                {/*type != 'view' ? mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                </FieldLabel>
                <FieldInput
                  value={mrRate.rate_escalation_percentage}
                  type={type}
                  keyParam="rate_escalation_percentage"
                  updateField={this.updateForm}
                  percentage={true}
                  disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}

                  />
                  <h6 className="error-msg">{error.rate_escalation_percentage}</h6>

                </FieldGroup>
              }
            </FieldCol>
            <FieldCol md="6">
              {type == 'view' && mrRate.is_escalation_mucip ? null :
                <FieldGroup className="form-group" style={{ opacity: type != 'view' && mrRate.is_escalation_mucip ? '0.5' : '1' }}>
                  <FieldLabel className="label">
                    Shop Visit Cost Escalation Percentage
                  </FieldLabel>
                  <FieldInput
                    value={mrRate.cost_escalation_percentage}
                    type={type}
                    keyParam="cost_escalation_percentage"
                    updateField={this.updateForm}
                    percentage={true}
                    disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}
                  />
                  <h6 className="error-msg">{error.cost_escalation_percentage}</h6>
                </FieldGroup>
              }
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group"  >
                <FieldLabel className="label">
                  Escalation Interval
                  {/*type != 'view' ? mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Escalation Interval'
                  keyParam="rate_escalation_interval"
                  value={mrRate.rate_escalation_interval}
                  type={type}
                  options={techConstants.filter(item => item.type == "escalation_interval")}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={mrRate.rate_escalation_interval_name}

                />
                <h6 className="error-msg">{error.rate_escalation_interval}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              {type == 'view' && mrRate.is_escalation_mucip ? null :
                <FieldGroup className="form-group" style={{ opacity: type != 'view' && mrRate.is_escalation_mucip ? '0.5' : '1' }} >
                  <FieldLabel className="label">
                    Escalation Start Date
                    {/*type != 'view' ?mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                  </FieldLabel>
                  <FieldDate
                    value={mrRate.rate_escalation_start_date}
                    type={type}
                    keyParam="rate_escalation_start_date"
                    updateField={this.updateForm}
                    disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}
                  />
                  <h6 className="error-msg">{error.rate_escalation_start_date}</h6>
                </FieldGroup>
              }
            </FieldCol>
            <Col md="12">
              <div className="form-group">
                <label className="label">
                  {
                    type != 'view' ?
                      <input type="checkbox" checked={mrRate.status == 1 ? true : false} onChange={(e) => this.updateForm('status', e.target.checked ? 1 : 0)} /> : null
                  }
                  <span >
                    Activate Reserve Account: {type == 'view' ? mrRate.status == 1 ? 'YES' : 'NO' : ''} </span>
                </label>
              </div>
            </Col>
            <Col md="12" className='flex-centered'>
              <div className="form-group">
                <label className="label">
                  {
                    type != 'view' ?
                      <input type="checkbox" checked={mrRate?.max_cap_applicable == true?true:false} onChange={(e) => this.updateForm('max_cap_applicable', e.target.checked ? true : false)} /> : null
                  }
                  <span >
                    Enable Max Cap Amount<Tooltip title='Click here to enable max cap amount.Even Escalated amout will be resticted to this amount' placement='bottom-start'><Info style={{ fontSize: '14px' }} /></Tooltip> { }
                  </span>
                </label>
              </div>
            </Col>
            {mrRate?.max_cap_applicable == true ?
              <FieldCol md="4">
                  <FieldGroup className="form-group" style={{ opacity: type != 'view' && mrRate.is_escalation_mucip ? '0.5' : '1' }}>
                    <FieldLabel className="label">
                      Enter Max Cap Amount
                    </FieldLabel>
                    <FieldInput
                      value={/^[0-9]*\.?[0-9]*$/.test(mrRate?.max_cap_amount)? mrRate?.max_cap_amount :null}
                      type={type}
                      keyParam="max_cap_amount"
                      updateField={this.updateForm}
                      maxLength={10}
                    />
                    <h6 className="error-msg">{error.max_cap_amount}</h6>
                  </FieldGroup>
              </FieldCol> : null}
          </Row>
        </div>
        {
          type != 'view' ?
            <div className="submit-block">
              <input type="submit" className="primary-btn" value="Save Changes" />
              <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
            </div> : null
        }
      </form>
    )
  }
}
