import React from 'react';
import {Tabs,Tab} from '@material-ui/core';
const UtilsTrendsTab = ({primaryTab, secondaryTab, onTabChange, utilsTrends}) => {
  return(
    <div>
      <Tabs
        value={primaryTab}
        onChange={(event, newValue) => onTabChange(event, newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant={'fullWidth'}
      >
        { utilsTrends.airframe && <Tab label="Airframe" value="airframe" /> }
        { utilsTrends.apu && <Tab label="APU" value="apu" /> }
        { utilsTrends.landing_gears && <Tab label="Landing Gears" value="landing_gears" /> }
        { utilsTrends.engines && <Tab label="Engines" value="engines" /> }
        { utilsTrends.propeller && <Tab label="Propeller" value="propeller" /> }
        { utilsTrends.fan && <Tab label="Fan" value="fan" /> }
        { utilsTrends.hpc && <Tab label="HPC" value="hpc" /> }
        { utilsTrends.hpt && <Tab label="HPT" value="hpt" /> }
        { utilsTrends.lpt && <Tab label="LPT" value="lpt" /> }
      </Tabs>
    </div>
  )
}
export default UtilsTrendsTab;
