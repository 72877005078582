import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, browserHistory } from 'react-router';
import {Button} from '@material-ui/core';
import { TableListComp, LabelValueCard, PageLoader, ExportManu, FilterUIComp, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Invoice, InvoiceWidget, GenerateInvoice } from '../components';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { sExportContractsAc } from '../../actionCreators';
import { convertFilterObject, removeEmptyKey, getLocalStorageInfo } from '../../../utils_v2';
import { getInvoiceWidgetApi, getInvoicesListApi, exportInvoicesListApi } from '../apiServices';
import { assetsType } from '../../../constants';
import { invoiceListHd, invoiceFilterOps } from '../'
class InvoiceList extends Component{
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      invoiceWidget:{},
      invoices: {
        invoice_list:[],
        pagination:{}
      },
      filter: {},
      applyingFilter:{},
    };
    this.getInvoiceWidgetApi = getInvoiceWidgetApi.bind(this);
    this.getInvoicesListApi = getInvoicesListApi.bind(this);
    this.exportInvoicesListApi = exportInvoicesListApi.bind(this);
  }
  componentDidMount(){
    this.getInvoiceWidgetApi(this.props);
    this.getInvoicesListApi(this.props, {}, 'skeletonLoader');
  }
  applyFilter = (keyParam, value, rangeKey) => {
    if(rangeKey){
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: {
            ...prevState.applyingFilter[keyParam],
            [rangeKey]:value
          }
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        applyingFilter: {
          ...prevState.applyingFilter,
          [keyParam]: value
        }
      }))
    }
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter, invoices } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getInvoiceListApi(this.props, {...query, per_page:invoices.pagination.per_page}, 'pageLoader');
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { pageLoader, skeletonLoader, filter, applyingFilter, invoices, invoiceWidget } = this.state;
    let filterOptions = Object.assign({}, invoiceFilterOps);
    return(
      <section className="lease-management-section">
        <div className="mr-rate-wrapper  mr-rate-spacing">
          <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          <ContractSideBar application={`Invoice`} type="brief" params={this.props.params} />
          <div className="view-block" style={{background:'none'}}>
            { Object.keys(invoiceWidget).length ?
              <div className="invoice-widget" style={{marginBottom:'15px'}}>
                <InvoiceWidget invoiceWidget={invoiceWidget} />
              </div>:null
            }
            <div>
              { skeletonLoader ? (<STableLoader count={4} />):
                (
                  <Fragment>
                    <div style={{float:'right',marginBottom:'15px'}}>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <GenerateInvoice />
                        </li>
                        <li className="list-inline-item">
                          <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'Doc', extension: 'doc', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportCashflowsApi(this.props, {...filter, download:file.extension})} />
                        </li>
                      </ul>
                    </div>
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => this.getInvoicesListApi(this.props, removeEmptyKey({ per_page: invoices.pagination.per_page }), 'pageLoader')}
                      submitFilter={() => this.getInvoicesListApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions) }, 'pageLoader')}
                      applyFilter={this.applyFilter}
                      filterMenu={filterOptions}
                    />
                    <TableListComp
                      heads={invoiceListHd}
                      data={invoices.invoice_list.map((item,index) =>
                        <Invoice
                          index={index}
                          item={item} />
                        )
                      }
                      pagination={invoices.pagination}
                      noRecord={ invoices.invoice_list.length ? null:
                        <EmptyCollection
                          title="No records found"
                        />
                      }
                    />
                  </Fragment>
                )
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceExportLoader:state.InvoiceReducer.invoiceExportLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(InvoiceList));
