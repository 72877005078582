import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import HistoryIcon from '@material-ui/icons/History';
import InductionCondCRU from './InductionCondCRU';
export default function InductionCndList({item, previewDetail, contractClauseList, getResponseBack, contractInfo,deleteInductionCond}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>{item.terms}</TableCell>
      <TableCell style={{maxWidth:'200px'}}>{item.remarks}</TableCell>
      <TableCell>
        <InductionCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','induction_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteInductionCond(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
