import React from 'react';
import { Col } from 'reactstrap';
const InvCol = (props) => {
  return(
    <Col md={props.size} xs={props.sixeXs} className={props.customClass ? props.customClass : null } style={props.style ? props.style : {}}>
      <h6 className="theta" style={props.childStyle ? props.childStyle : {}} >{props.value}</h6>
    </Col>
  )
}
export default InvCol;
