import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, browserHistory, Link} from 'react-router';
import { Header, ContractHeadertext, EditSidebar, CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared'
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';
import '../../assets/styles/MRRate.scss';
import WorkingGrpWrapper from '../components/WorkingGrpWrapper';
import AddWorkingGrpTeam from '../components/AddWorkingGrpTeam';
import WorkingGrpRow from '../components/WorkingGrpRow';
import AddGrpMember from '../components/AddGrpMember';
import { workingGroupListAc, addEditWorkingGrpAc, addEditGrpMemberAc, workingGroupCrudFnAc, workingGroupMemCrudFnAc } from '../actionCreators';
import { CLOSE_TEAM_SIDE_BAR, CLOSE_MEMBAR_SIDE_BAR, WORKING_GRP_CRUD, TOGGLE_VIEW_EDIT, WORKING_GRP_MEM_CRUD } from '../actions';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import { sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class WorkingGroup extends Component {
  constructor(props){
    super(props);
    this.state = {
      editworkOrder: false,
      addTeam: false,
      editTeam: false,
      addMemberStatus: false,
      workingGroupId: '',
    }
  }
  componentDidMount(){
    this.props.fetchWorkingGrpList();
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  updateWGId = (workingGroupId, workingGroupIndex, type, memberId, memberIndex ) => {
    this.setState(prevState => ({
      ...prevState,
      workingGroupId: workingGroupId,
    }));
    if(type== 'add'){
      this.props.workingGroupMemCrudFn('add', workingGroupId);
    }else{
      this.props.workingGroupMemCrudFn('view', workingGroupId, memberId);
    }
  }

  render() {
    const { workingGroupList, assetViewId, teamView, memberView,  workingGrpLoader, workingGroupCrud, workingGroupMemCrud, auditLogView, params } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="working-groups-blk">
        <div className="contract-container">
          <Header type="brief" headingText="Lease Management" pageTitle="Working Groups" params = {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          <ContractSideBar application={`Working Groups`} type="brief" params = {this.props.params} />
          <div className="contract-content-blk">
            <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
             <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
            </Link>
            <ContractHeadertext title="Working Groups"
              addNew ={getLocalStorageInfo().user.permission['contracts']['working_group'].indexOf('C') != -1 ? '+ Add Working Group': ''}
              desc="Section holds information of different groups and members involved in the Lease"
              toggleAddEvent = {() => this.props.workingGroupCrudFn('add', {})}
              addAudit= {auditLogView ?  "Revision History" : null}
              toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctworkinggroups', '0,1', null, 'add',this.props.params.contract_slug,'contract'); trackActivity('Revision History', { page_title: 'Headlines', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Working Group'})}}
           />
            <div className="list-container">
              { workingGrpLoader ? <ListLoader />:null }
              { workingGroupList.map((workingGroup, index) => {
                  return(
                    <WorkingGrpWrapper workingGroup={workingGroup} key={index} index={index} viewTeamInfo={() => this.props.workingGroupCrudFn('view', workingGroup.id, index)} toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctworkinggroupsmembers', '0,1', null, 'add',null,null,workingGroup.id);trackActivity('Revision History', { page_title: 'Headlines', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.contract_slug}`, item_type: 'Working Group', item_id: workingGroup.id})}} addMember={() => this.updateWGId(workingGroup.id, index, 'add')}>
                      <div className="table-container">
                        <table>
                          <CtrTableHd theads={['Name ( First & Last )', 'Designation', 'Email', 'Mobile', 'Remarks']}/>
                          <tbody>
                            { workingGroup.working_group_member && workingGroup.working_group_member.length  ?
                              workingGroup.working_group_member.map((member,childIndex) => <WorkingGrpRow workingGroupId={workingGroup.id} parentIndex={index} viewMemberInfo={() =>  this.updateWGId(workingGroup.id, index, 'view', member.id, childIndex )} member={member} key={childIndex} childIndex={childIndex} />)
                              :
                              <tr>
                                <td colSpan="5" className="text-center">
                                  <NoRecordFound description="Get Started by clicking '+' button above" />
                                </td>
                              </tr>
                             }
                          </tbody>
                        </table>
                      </div>
                    </WorkingGrpWrapper>
                  )
                })
              }
              { !workingGrpLoader && !workingGroupList.length ?
                <NoRecordFound description="Get Started by clicking '+ Add Working Group' button above" />:null
              }
            </div>
          </div>
        </div>

        <EditSidebar
          title={
            workingGroupCrud.type != '' ?
            (workingGroupCrud.type == 'add') ? 'Add Working Group':
            (workingGroupCrud.type == 'edit') ? 'Edit Working Group': 'View Working Group' : ''
            }
          editSideBar={workingGroupCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'workingGroupCrud', WORKING_GRP_CRUD)}
          >
          {
            Object.keys(workingGroupCrud.data).length && workingGroupCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>this.props.toggleAuditlogFn('contracts', 'ctworkinggroups', 1, workingGroupCrud.data.id, 'update')}
                type={workingGroupCrud.type}
                changeViewMode={()=>this.props.changeViewMode(workingGroupCrud.type, 'workingGroupCrud')}
                editAble = {getLocalStorageInfo().user.permission['contracts']['working_group'].indexOf('U') != -1 ? true:false}
              />
              <AddWorkingGrpTeam
                type={workingGroupCrud.type}
                workingGrpTeamObj={workingGroupCrud.data}
                addEditWorkingGrp={(data, type)=>this.props.addEditWorkingGrp(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'workingGroupCrud',WORKING_GRP_CRUD)}
                workingGrpLoader={workingGrpLoader}
              />
            </div>:null
          }
        </EditSidebar>
        <EditSidebar
          width="560px"
          right="-560px"
          title={
            workingGroupMemCrud.type != '' ?
            (workingGroupMemCrud.type == 'add') ? 'Add Member':
            (workingGroupMemCrud.type == 'edit') ? 'Edit Member': 'View Member' : ''
            }
          editSideBar={workingGroupMemCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'workingGroupMemCrud', WORKING_GRP_MEM_CRUD)}
          >
          {
            Object.keys(workingGroupMemCrud.data).length && workingGroupMemCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode= {()=>this.props.toggleAuditlogFn('contracts', 'ctworkinggroupsmembers', 1, workingGroupMemCrud.data.id, 'update')}
                type={workingGroupMemCrud.type}
                changeViewMode={()=>this.props.changeViewMode(workingGroupMemCrud.type, 'workingGroupMemCrud')}
                editAble = {getLocalStorageInfo().user.permission['contracts']['working_group'].indexOf('U') != -1 ? true:false}
              />
              <AddGrpMember
                type={workingGroupMemCrud.type}
                addMemberObj={workingGroupMemCrud.data}
                workingGroupId={this.state.workingGroupId}
                addEditGrpMember={(data, type, id)=>this.props.addEditGrpMember(data, type, id, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'workingGroupMemCrud',WORKING_GRP_MEM_CRUD)}
                workingGrpLoader={workingGrpLoader}
              />
            </div>:null
          }
        </EditSidebar>

       <EditSidebar
         width="560px"
         right="-560px"
         title="Revision History"
         editSideBar={auditLogView.flag}
         toggleEditSideBar={() => {this.props.closeAuditSidebar({type: 'add', data: {}, flag: false}); trackActivity('Revision History', { page_title: 'Headlines', application_module: 'Contracts', asset:assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Working Group'})}}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>


     </div>
    )
  }
}
const mapStateToProps = state => ({
  workingGroupList: state.ContrWorkingGrpReducer.workingGroupList,
  teamView: state.ContrWorkingGrpReducer.teamView,
  memberView: state.ContrWorkingGrpReducer.memberView,
  workingGrpLoader: state.ContrWorkingGrpReducer.workingGrpLoader,
  workingGroupCrud: state.ContrWorkingGrpReducer.workingGroupCrud,
  workingGroupMemCrud: state.ContrWorkingGrpReducer.workingGroupMemCrud,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchWorkingGrpList: () => dispatch(workingGroupListAc(ownProps.params)),
    addEditWorkingGrp: (data, type, assetViewId) => dispatch(addEditWorkingGrpAc(ownProps.params, data, type, assetViewId)),
    workingGroupCrudFn: (type,id) => dispatch(workingGroupCrudFnAc(ownProps.params,type, id)),
    workingGroupMemCrudFn : (type, workingGroupId, id) => dispatch(workingGroupMemCrudFnAc(ownProps.params, type, workingGroupId, id)),
    addEditGrpMember: (data, type , workingGroupId, assetViewId) => dispatch(addEditGrpMemberAc(ownProps.params,data, type ,workingGroupId, assetViewId)),
    closeTeamSideBar: () => dispatch({
      type: CLOSE_TEAM_SIDE_BAR,
      payload: ''
    }),
    closeMemberSideBar: () => dispatch({
      type: CLOSE_MEMBAR_SIDE_BAR,
      payload: ''
    }),
    changeViewMode: (type, actionType) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      actionType: actionType,
      payload: type
    }),
    toggleEditBar: (data,actionType, constType) => dispatch({
      type: constType,
      actionType:actionType,
      payload: data
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    toggleAuditlogFn: (application, content_type, action, objectId, type,pramKey=null,paramValue=null,workingGroup=null) => dispatch(sAuditLogAc(application, content_type, action, objectId, type,pramKey,paramValue,workingGroup)),

  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(WorkingGroup, ['contracts', 'working_group' ,'R'])));
