import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import { Grid, TextField, Button } from '@material-ui/core';
import { TableListComp, LabelValueCard, PageLoader, DeletePopUp } from '../../../shared_elements';
import { BasicDetail, LessorInfo, LesseeInfo,  BankDetail, EditMRInvoiceList, EditRentalInvoiceList, EditCreditDebitList, PenaltyInvoiceList, SecurityDespoitInvoiceItems, InsuranceInvoiceItems, InvoiceGross, InvoiceBackNav, EditInvoiceCTA, AdhocInvoiceItem } from '../components';
import { ContractSideBar, Header } from '../../../applications/contracts/Elements';
import { sExportContractsAc } from '../../actionCreators';
import { getLocalStorageInfo } from '../../../utils_v2';
import { getInvoiceApi, deleteInvoiceApi, editInvoiceApi } from '../apiServices';
import { assetsType } from '../../../constants';
import { creditDebitHd, penaltyHd, mntRentalHd } from '../';
class InvoiceEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      invoiceInfo:{},
      deleteModal:false,
      error:{},
      errorItems:[]
    }
    this.getInvoiceApi = getInvoiceApi.bind(this);
    this.deleteInvoiceApi = deleteInvoiceApi.bind(this);
  }
  componentDidMount(){
    this.getInvoiceApi(this.props);
  }

  onFieldChange = (e, keyParam, value, tableType, index) => {
    const { invoiceInfo } = this.state;
    if(tableType === 'invoice_items'){
      let invoiceItem = invoiceInfo.invoice_items[index];
      this.updateInvoiceItem(keyParam, value, index);
      if(keyParam === 'no_of_unit'){
        this.updateInvoiceItem('tax_amount', this.getTaxAmount(value, invoiceItem.rate_per_unit, invoiceItem.tax_rate), index);
        this.updateInvoiceItem('sub_total', this.getSubTotalAmount(value, invoiceItem.rate_per_unit, invoiceItem.tax_rate), index);
        this.updateInvoiceItem('billable_amount', this.getBillableAmount(value, invoiceItem.rate_per_unit), index);
      }
      if(keyParam === 'rate_per_unit'){
        this.updateInvoiceItem('tax_amount', this.getTaxAmount(invoiceItem.no_of_unit, value, invoiceItem.tax_rate), index);
        this.updateInvoiceItem('sub_total', this.getSubTotalAmount(invoiceItem.no_of_unit, value, invoiceItem.tax_rate), index);
        this.updateInvoiceItem('billable_amount', this.getBillableAmount(invoiceItem.no_of_unit, value), index);
      }
      if(keyParam === 'tax_rate'){
        this.updateInvoiceItem('tax_amount', this.getTaxAmount(invoiceItem.no_of_unit, invoiceItem.rate_per_unit, value), index);
        this.updateInvoiceItem('sub_total', this.getSubTotalAmount(invoiceItem.no_of_unit, invoiceItem.rate_per_unit, value), index);
      }
      if(keyParam === 'libor_value'){
        let margin = invoiceInfo.invoice_items[index].payment_schedule.margin ? parseFloat(invoiceInfo.invoice_items[index].payment_schedule.margin):0;
        let referenceValue = invoiceInfo.invoice_items[index].payment_schedule.referenceValue ? parseFloat(invoiceInfo.invoice_items[index].payment_schedule.referenceValue):0;
        let period = invoiceInfo.invoice_items[index].payment_schedule.period ? parseFloat(invoiceInfo.invoice_items[index].payment_schedule.period):0;
        let totalAmount = (referenceValue*period*((margin+parseFloat(value ? value:0))/100)/360);
        let billableAmount =  (parseFloat(totalAmount)*parseFloat(invoiceInfo.invoice_items[index].no_of_unit));
        let taxAmount = (parseFloat(billableAmount)*(parseFloat(invoiceInfo.invoice_items[index].tax_rate)/100));
        let subTotal = parseFloat(billableAmount) + parseFloat(taxAmount);
        this.setState(prevState => ({
          ...prevState,
          invoiceInfo:{
            ...prevState.invoiceInfo,
            invoice_items: prevState.invoiceInfo.invoice_items.map((item, itemIndex) => itemIndex === index ? {
              ...item,
              rate_per_unit:totalAmount.toFixed(2),
              sub_total:subTotal,
              billable_amount:billableAmount,
              tax_amount:taxAmount
            } :item)
          }
        }))
      }
    }else if(tableType === 'penalty'){
      if(keyParam === 'description'){
        this.setState(prevState => ({
          ...prevState,
          invoiceInfo:{
            ...prevState.invoiceInfo,
            [keyParam]:value
          }
        }));
      }else{
        let penalty = null;
        let diffDays = 0;
        switch(keyParam){
          case 'additional_rate':
            diffDays = moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days'):0;
            penalty = this.getPenaltyAmounts(invoiceInfo.libor_value, value, diffDays);
            break;
          case 'libor_value':
            diffDays = moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(invoiceInfo.parent_paid_date).diff(moment(invoiceInfo.penalty_due_date),'days'):0;
            penalty = this.getPenaltyAmounts(value, invoiceInfo.additional_rate, diffDays);
            break;
          case 'penalty_due_date':
            diffDays = moment(invoiceInfo.parent_paid_date).diff(moment(e),'days') > 0 ? moment(invoiceInfo.parent_paid_date).diff(moment(value),'days'):0
            penalty = this.getPenaltyAmounts(invoiceInfo.libor_value, invoiceInfo.additional_rate, diffDays);
            break;
          case 'parent_paid_date':
            diffDays = moment(value).diff(moment(invoiceInfo.penalty_due_date),'days')>0 ? moment(value).diff(moment(invoiceInfo.penalty_due_date),'days'):0
            penalty = this.getPenaltyAmounts(invoiceInfo.libor_value, invoiceInfo.additional_rate, diffDays);
            break;
        }
        this.setState(prevState => ({
          ...prevState,
          invoiceInfo:{
            ...prevState.invoiceInfo,
            [keyParam]:value,
            penalty_interest_rate:penalty.penalty_interest_rate,
            total_amount: penalty.total_amount,
            over_due_days:diffDays
          }
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        invoiceInfo:{
          ...prevState.invoiceInfo,
          [keyParam]:value
        }
      }))
    }
  }
  getPenaltyAmounts = (liborValue, additionalRate, diffDays) => {
    const { invoiceInfo } = this.state;
    let penaltyInterestRate = invoiceInfo.penalty_interest_rate ? invoiceInfo.penalty_interest_rate:0;
    let overdueInterestAmount = invoiceInfo.overdue_interest_amount ? invoiceInfo.overdue_interest_amount:0;
    penaltyInterestRate = parseFloat(liborValue ? liborValue:0)+parseFloat(additionalRate ? additionalRate:0);
    overdueInterestAmount = ((penaltyInterestRate/100)/(moment().isLeapYear() ? 366:365)*parseFloat(invoiceInfo.parent_amount))*diffDays;
    return {penalty_interest_rate:penaltyInterestRate, total_amount:overdueInterestAmount.toFixed(2)}
  }
  getTaxAmount = (noOfUnits, ratePerUnit, taxRate) => {
    if(noOfUnits && ratePerUnit && taxRate) {
      return ((noOfUnits*ratePerUnit)*(taxRate/100)).toFixed(2);
    }else{
      return 0;
    }
  }
  getSubTotalAmount = (noOfUnits, ratePerUnit, taxRate) => {
    let taxAomunt = 0;
    let amount = 0;
    if(noOfUnits && ratePerUnit && taxRate){
      taxAomunt = ((parseFloat(noOfUnits)*parseFloat(ratePerUnit))*(parseFloat(taxRate)/100))
    }
    if(noOfUnits && ratePerUnit) {
      amount =  (parseFloat(noOfUnits)*parseFloat(ratePerUnit));
    }
    return (taxAomunt+amount).toFixed(2);
  }
  getBillableAmount = (noOfUnits, ratePerUnit) => {
    if(noOfUnits && ratePerUnit){
      return noOfUnits*ratePerUnit
    }else{
      return 0;
    }
  }
  updateInvoiceItem = (key, value, index) => {
    this.setState(prevState => ({
      ...prevState,
      invoiceInfo:{
        ...prevState.invoiceInfo,
        invoice_items: prevState.invoiceInfo.invoice_items.map((item, itemIndex) => itemIndex === index ? {
          ...item, [key]:value
        } :item)
      }
    }))
  }

  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { invoiceInfo, error, errorItems, deleteModal, pageLoader } = this.state;
    return(
      <section className="invoice-detail-section">
        <div className="mr-rate-wrapper  mr-rate-spacing">
          <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
          {
            invoiceInfo && Object.keys(invoiceInfo).length ?
              <ContractSideBar application={`Invoice View - ${invoiceInfo.invoice_number}`} type="brief" params={this.props.params} />
            :null
          }
          { Object.keys(invoiceInfo).length ?
            <div className="view-block" style={{background:'none'}}>
              <InvoiceBackNav mode="edit" invoiceInfo={invoiceInfo} exportInvoiceFn={(file) => this.exportInvoiceApi(this.props, invoiceInfo.invoice_number, {download:file.extension})} />
              <div style={{float:'right', marginTop:'-60px'}}>
                <EditInvoiceCTA
                  onDelete={() => this.setState({deleteModal:true})}
                  onSave={() => this.editInvoiceApi(this.props, invoiceInfo)}
                />
              </div>
              <div>
                <BasicDetail
                  invoiceInfo={invoiceInfo}
                  error={error}
                  mode="edit"
                  onFieldChange={this.onFieldChange}
                  updateErrorField={this.updateErrorField}
                />
                <Grid container spacing={3} style={{marginBottom:'10px'}}>
                  <LessorInfo
                    mode="edit"
                    invoiceInfo={invoiceInfo}
                    onFieldChange={this.onFieldChange}
                    updateErrorField={this.updateErrorField}
                    error={error}
                  />
                  <LesseeInfo
                    mode="edit"
                    invoiceInfo={invoiceInfo}
                    onFieldChange={this.onFieldChange}
                    updateErrorField={this.updateErrorField}
                    error={error}
                  />
                </Grid>
                { invoiceInfo.invoice_type === 2 && invoiceInfo.rental_description && invoiceInfo.rental_description !=='' ?
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="rental_description"
                        label="Description"
                        fullWidth
                        margin="normal"
                        value={invoiceInfo.rental_description}
                        InputLabelProps={{shrink: true}}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => this.onFieldChange(e, 'rental_description', e.target.value)}
                      />
                    </Grid>
                  </Grid>:null
                }
              </div>
              <div>
                { invoiceInfo.invoice_type === 1 ?
                  <div className="">
                    <TableListComp
                      heads={mntRentalHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <EditMRInvoiceList
                          mode="edit"
                          invoice_item={item}
                          onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'invoice_items', index)}
                        />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 2 ?
                  <div className="">
                    { invoiceInfo.invoice_type === 2 && <h5>{invoiceInfo.currency} Rental Receivable</h5> }
                    <div className="">
                      <TableListComp
                        heads={mntRentalHd}
                        data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                          <EditRentalInvoiceList
                            invoiceInfo={invoiceInfo}
                            mode="edit"
                            invoice_item={item}
                            onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'invoice_items', index)}
                          />
                        )}
                      />
                    </div>
                  </div>:null
                }
                { Object.keys(invoiceInfo).length && invoiceInfo.invoice_type === 3 ?
                  <div style={{marginBottom:'15px'}}>
                    <TableListComp
                      heads={penaltyHd}
                      data={
                        <PenaltyInvoiceList
                          mode="edit"
                          error={error}
                          invoiceInfo={invoiceInfo}
                          updateErrorField={this.updateErrorField}
                          onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'penalty')}
                        />
                      }
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 4 || invoiceInfo.invoice_type === 5 ?
                  <div>
                    <TableListComp
                      heads={creditDebitHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <EditCreditDebitList
                          mode="edit"
                          onFieldChange={(e, keyParam, value) => this.onFieldChange(e, keyParam, value, 'invoice_items', index)}
                        />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 6 ?
                  <div>
                    <TableListComp
                      heads={[
                        {label:'Item', sortOption:false},
                        {label:'Security Against', sortOption:false},
                        {label:'Security Deposit Type', sortOption:false},
                        {label:'Security Deposit Amount', sortOption:false},
                        {label:'Sub Total', sortOption:false},
                      ]}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <SecurityDespoitInvoiceItems invoiceInfo={invoiceInfo} mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }
                { invoiceInfo.invoice_type === 7 || invoiceInfo.invoice_type === 8 ?
                  <div>
                    <TableListComp
                      heads={[
                        {label:'Item', sortOption:false},
                        {label:'Description', sortOption:false},
                        {label:'Insurance Type', sortOption:false},
                        {label:'Insurance Amount', sortOption:false},
                        {label:'Sub Total', sortOption:false},
                      ]}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <InsuranceInvoiceItems invoiceInfo={invoiceInfo} mode="view" invoice_item={item} />
                      )}
                    />
                  </div>:null
                }

                { invoiceInfo.invoice_type === 9 ?
                  <div className="">
                    <TableListComp
                      heads={mntRentalHd}
                      data={invoiceInfo.invoice_items && invoiceInfo.invoice_items.map((item, index) =>
                        <AdhocInvoiceItem mode="Edit" invoice_item={item} />
                      )}
                    />
                  </div>
                  :null
                }

                { Object.keys(invoiceInfo).length && invoiceInfo.invoice_type !== 3 ?
                  <InvoiceGross invoiceInfo={invoiceInfo} />:null
                }
              </div>
              <div>
                <BankDetail mode="view" bankDetail={invoiceInfo} />
              </div>
              { invoiceInfo.invoice_type === 2 && invoiceInfo.children && invoiceInfo.children.length ?
                <div className="">
                  { invoiceInfo.children.map((children, childIndex) =>
                    <div className="">
                      <h5 className="para currency-break">{children.currency} Rental Receivable</h5>
                      <TableListComp
                        heads={mntRentalHd}
                        data={children.invoice_items.map((item, index) =>
                          <EditRentalInvoiceList mode="view" invoice_item={item} />
                        )}
                      />
                      <InvoiceGross invoiceInfo={children} />
                      <BankDetail mode="view" bankDetail={children} />
                    </div>
                  )}
                </div>:null
              }
            </div>:null
          }
          <EditInvoiceCTA
            onDelete={() => this.setState({deleteModal:true})}
            onSave={() => this.editInvoiceApi(this.props, invoiceInfo)}
          />
        </div>
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => this.setState({deleteModal:false})}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => this.deleteInvoiceApi(this.props)}
        />
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  invoiceExportLoader:state.InvoiceReducer.invoiceExportLoader
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(InvoiceEdit));
