import { globalGetService, globalPostService, globalPutService ,globalDeleteService} from '../../../utils_v2/globalApiServices';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { toastFlashMessage } from '../../../utils';
import { checkApiStatus } from '../../../utils_v2';
export function getQfdMntEventsListApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/qualified-maintenance-events/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}
export function getQfdMntEventsApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/qualified-maintenance-events/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({qfdMntEvent:response.data.data, error:{}, modal:true, mode: mode === 'V' ? 'view':'edit'})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditQfdMntEventsApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/qualified-maintenance-events/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/qualified-maintenance-events/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}

export function deleteQfdMntEventsApi(props ,deleteItem) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/qualified-maintenance-events/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          list: prevState.list.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false, data:null}
        }))
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
      this.setState({pageLoader:false});
    })
}

