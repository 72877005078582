import React from 'react'
import { TableRow, TableCell } from '@material-ui/core';
import moment from 'moment';
import { Delete, Edit } from '@material-ui/icons';
import { checkPermission } from '../../../utils_v2';

export default function MouList({ item, setEditModal, setDeleteModal }) {
    return (
        <TableRow style={{ padding: '4px 0px' }}  >
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.mou_date ? moment(item?.mou_date).format('MMM DD, YYYY') : '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.lessee?.name || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.lessor_name?.name || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.managed || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }}>
                {item?.tied_asset || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }}>
                {item?.created_by?.name || '--'} {' '} ({item?.created_at || ''})
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }}>
                {checkPermission('contracts', 'mou_list', 'U') ? <Edit color='primary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setEditModal(item)} />: null}
                { checkPermission('contracts', 'mou_list', 'D') ? <Delete color='secondary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setDeleteModal({ flag: true, data: item })} /> : null}
            </TableCell>
        </TableRow>
    )
}