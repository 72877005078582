import React from 'react';
import { browserHistory } from 'react-router';
import { TableRow, TableCell } from '@material-ui/core';
const FleetUtil = ({item, getResponseBack}) => {
  let assetTypeNames = {
    1: 'aircraft',
    2: 'engine',
    3: 'apu',
    4: 'lg',
    5: 'propeller',
    6: 'fan',
    7: 'hpc',
    8: 'hpt',
    9: 'lpt'
  }
  const redirectUrl = () =>{
    if(item.asset.linked_aircraft_slug){
      window.open(`/aircraft/${item.asset.linked_aircraft_slug}/contract/${item.lease_slug}/utilization`,'_blank')
    }else{
      window.open(`/${assetTypeNames[item.asset.asset_type]}/${item.asset.slug}/contract/${item.lease_slug}/utilization`,'_blank')
    }
  }
  return(
    <TableRow hover tabIndex={-1} style={{cursor:'pointer'}} onClick = {()=> redirectUrl()}>
      <TableCell className="asset-info">
        <div className="flex-centered asset-wrapper" style={{flexWrap:"nowrap"}}>
            <div className="assetIcon">
                <img style={{ height: '25px', objectFit: 'contain' }} src={"images/" + (item.asset.asset_type == 1 ? "icon_Aircraft.png" : (item.asset.asset_type == 2) ? "icon_engine.png" : item.asset.asset_type == 3 ? "icon_apu.svg" : item.asset.asset_type == 4 ? 'icon_lg.svg' : item.asset.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
            </div>
            <div className="asset">
              {
                (item.asset.asset_type === 1 || item.asset.asset_type === 2  || item.asset.asset_type === 3 || item.asset.asset_type === 5) ?
                <p>
                    {item.asset.asset_type === 1 ? item.asset.name ? item.asset.name : item.asset.name : ''}{item.asset.asset_type === 1 ? item.asset.model && ' / ' + item.asset.model: ''}{item.asset.asset_type === 2 ? item.asset.name : ''}{item.asset.asset_type === 3 ? item.asset.name : ''} {item.asset.asset_type === 5 ? item.asset?.model ?item.asset.model: null : null}
                </p> : null
              }
                <h6>{item.asset.unique_name}</h6>
            </div>
        </div>
      </TableCell>
      <TableCell>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name:'--'}</TableCell>
      <TableCell>{item.owner && item.owner.name ? item.owner.name:'--'}</TableCell>
      <TableCell>{item.lessee && item.lessee.logo ? <img style={{width:"60px"}} src={item.lessee && item.lessee.logo} alt="Icon"/> : <span>{item.lessee && item.lessee.name ? item.lessee.name :'--'}</span>}</TableCell>
      <TableCell>{item.month_year ? item.month_year : '--'}</TableCell>
      <TableCell>{item.tsn ? item.tsn : '--'}</TableCell>
      <TableCell>{item.csn ? item.csn : '--'}</TableCell>
      <TableCell>{item.hours ? item.hours : '--'}</TableCell>
      <TableCell>{item.cycles ? item.cycles : '--'}</TableCell>
      <TableCell>{item.remarks ? item.remarks:'--'}</TableCell>
      <TableCell>{item.serviceability ? item.serviceability:'--'}</TableCell>
    </TableRow>
  )
}
export default FleetUtil;
