import { globalGetService, globalPostService, globalPutService,globalDeleteService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { toastFlashMessage } from '../../../utils';
import { fieldValidation } from '../../../utils_v2/formValidation';
export function getLeaseOptionListApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/lease-options/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data,
      });
    }
    this.setState({skeletonLoader:false});
  })
}
export function getLeaseOptionApi(props={}, id, mode){
  this.setState({pageLoader:true});
  globalGetService(`contracts/${props.params.contract_slug}/lease-options/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({ modal:true, mode: mode === 'V'?'view':'edit', error:{}, leaseOption:response.data.data})
    }
    this.setState({pageLoader:false});
  })
}
export function addEditLeaseOptionApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`contracts/${props.params.contract_slug}/lease-options/${data.id}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`contracts/${props.params.contract_slug}/lease-options/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}
export function deleteEolListApi(props ,deleteItem=null) {
  this.setState({pageLoader:true});
  globalDeleteService(`contracts/${props.params.contract_slug}/lease-options/${deleteItem.data.id}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          list: prevState.list.filter((item, index) => item.id != deleteItem.data.id),
          deleteItem:{modal:false,data:null}
        }))
        toastFlashMessage(response?.data?.message, 'success')
      } else {
        toastFlashMessage(response?.data?.message, 'error')
      }
      this.setState({pageLoader:false});
    })
}
