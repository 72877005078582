import React, { Component } from 'react';
import { imgStoragePath } from '../../../../constants';
import addIcon from '../../../../shared/assets/img/add_icon.svg';
export default class TableHeadingTechSummary extends Component{
  constructor(props) {
    super(props);
  }

  render(){
    const {title,crud,dataObject, addButton, newFeature} = this.props;
    return(
      <h5>{title}
      {
        newFeature && <sup style={{fontWeight: 'bold'}}> NEW</sup>
      }
        {
          addButton ?
          <span className="flex-centered" onClick={this.props.techSummaryCrud}>
            <img src={addIcon} alt="icon" />
            <span className="add">Add</span>
          </span>:null
        }
      </h5>
    )
  }
}
